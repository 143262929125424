"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceFields = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const FormField_1 = require("../../form/FormField");
const react_hook_form_1 = require("react-hook-form");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const InvoiceFieldsWatcher_1 = require("./InvoiceFieldsWatcher");
const AddressFields_1 = require("../../form/address-fields/AddressFields");
const AddressUtil_1 = require("../../../util/AddressUtil");
const invoiceFields_utils_1 = require("./invoiceFields.utils");
const react_1 = require("react");
const addressFields_utils_1 = require("../address-fields/addressFields.utils");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const InvoiceFields = ({ country, fullVatFormEnabled, showPreview, prefix = 'invoice.', }) => {
    const [showDeliveryInvoice, setShowDeliveryInvoice] = (0, react_1.useState)(false);
    const fieldName = fullVatFormEnabled ? 'needInvoice' : 'alternateInvoiceAddress';
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfig = (0, common_components_1.useAemConfigurationContextWrapper)();
    const placeholderVat = (0, ConfigurationUtil_1.getPlaceholderVat)(aemConfig);
    const values = (0, react_hook_form_1.useFormContext)().getValues();
    const [needInvoice] = (0, react_hook_form_1.useWatch)({ name: [fieldName] });
    const [vatNumberField, companyNameField, companyDepartmentField, addressField] = (0, ArrayUtil_1.mapWithAssert)([
        invoiceFields_utils_1.InvoiceFieldName.VAT_NUMBER,
        invoiceFields_utils_1.InvoiceFieldName.COMPANY_NAME,
        invoiceFields_utils_1.InvoiceFieldName.COMPANY_DEPARTMENT,
        invoiceFields_utils_1.InvoiceFieldName.ADDRESS,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const isValidInvoice = yield (0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country }).isValid(Object.assign(Object.assign({}, values), { [fieldName]: needInvoice }));
            if (!isValidInvoice) {
                setShowDeliveryInvoice(true);
            }
        });
        needInvoice && validateForm();
    }, [needInvoice]);
    const handleEditClick = () => {
        setShowDeliveryInvoice(true);
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: fieldName, children: (_a) => {
                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]);
                    return ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { id: fieldName, label: t('buy.invoice.invoiceneeded'), checked: fieldProps.value })));
                } }), !showDeliveryInvoice && needInvoice && showPreview && ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { backgroundColor: "quiet", padding: "4", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", contentAlign: "between", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "neutral", strong: true, children: values.invoice.companyName }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('checkout:checkout.delivery.change.location'), onClick: handleEditClick }) })] }), fullVatFormEnabled && ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.vatnumber`), ": ", values.invoice.vatNumber] })), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: (0, AddressUtil_1.getCustomerAddress)(values.invoice.address, country, t) })] })), showDeliveryInvoice && needInvoice && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [fullVatFormEnabled && ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, fullWidth: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { md: 6, xs: 12 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: vatNumberField, required: true, children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: vatNumberField, placeholder: `${t('checkout:checkout.example')} ${placeholderVat}`, maxLength: 50, required: true, label: t('buy:buy.invoice.vatnumber') })));
                                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { md: 6, xs: 12 }, rowOffset: { md: 2 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: companyNameField, required: true, children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: companyNameField, maxLength: 50, required: true, label: t('buy:buy.invoice.companyname') })));
                                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { md: 6, xs: 12 }, rowOffset: { md: 2 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: companyDepartmentField, required: true, children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: companyDepartmentField, maxLength: 50, label: t('buy:buy.invoice.departmentname') })));
                                    } }) }), (0, jsx_runtime_1.jsx)(InvoiceFieldsWatcher_1.InvoiceFieldsWatcher, { country: country })] })), (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { country: country, prefix: `${addressField}.`, isRequired: needInvoice, hasInitialAddress: showDeliveryInvoice })] }))] }));
};
exports.InvoiceFields = InvoiceFields;
