"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mimicDelay = exports.isEqual = exports.compareArraysOfObjectsAndReturnUniques = exports.compareArraysOfObjectsAndReturnDuplicates = exports.removeAttributeFromObject = void 0;
const tslib_1 = require("tslib");
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const removeAttributeFromObject = (sourceObject, attributeToRemove) => Object.keys(sourceObject)
    .filter(attribute => attribute !== attributeToRemove)
    .reduce((result, current) => {
    result[current] = sourceObject[current];
    return result;
}, {});
exports.removeAttributeFromObject = removeAttributeFromObject;
const compareArraysOfObjectsAndReturnDuplicates = (arrayA, arrayB, keyToCompare) => {
    let newArray = [];
    if (!(0, common_utils_1.isNullOrUndefined)(arrayA) && !(0, common_utils_1.isNullOrUndefined)(arrayB) && !(0, common_utils_1.isNullOrUndefined)(keyToCompare)) {
        const keys = arrayA.map(obj => obj[keyToCompare]);
        newArray = arrayB.filter(obj => keys.indexOf(obj[keyToCompare]) > -1);
    }
    return newArray;
};
exports.compareArraysOfObjectsAndReturnDuplicates = compareArraysOfObjectsAndReturnDuplicates;
const compareArraysOfObjectsAndReturnUniques = (arrayA, arrayB, keyToCompare) => {
    let newArray = [];
    if (!(0, common_utils_1.isNullOrUndefined)(arrayA) && !(0, common_utils_1.isNullOrUndefined)(arrayB) && !(0, common_utils_1.isNullOrUndefined)(keyToCompare)) {
        const keys = arrayA.map(obj => obj[keyToCompare]);
        newArray = arrayB.filter(obj => keys.indexOf(obj[keyToCompare]) === -1);
    }
    return newArray;
};
exports.compareArraysOfObjectsAndReturnUniques = compareArraysOfObjectsAndReturnUniques;
const isEqual = function (value, other) {
    // Get the value type
    const type = Object.prototype.toString.call(value);
    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other))
        return false;
    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0)
        return false;
    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen)
        return false;
    // Compare two items
    const compare = (item1, item2) => {
        // Get the object type
        const itemType = Object.prototype.toString.call(item1);
        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!(0, exports.isEqual)(item1, item2)) {
                return false;
            }
        }
        else {
            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) {
                return false;
            }
            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString())
                    return false;
            }
            else {
                if (item1 !== item2)
                    return false;
            }
        }
        return true;
    };
    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false)
                return false;
        }
    }
    else {
        for (const key in value) {
            if (Object.hasOwn(value, key)) {
                if (compare(value[key], other[key]) === false)
                    return false;
            }
        }
    }
    // If nothing failed, return true
    return true;
};
exports.isEqual = isEqual;
const mimicDelay = (...args_1) => tslib_1.__awaiter(void 0, [...args_1], void 0, function* (delay = GeneralConstants_1.DELAY.DELAY_500) { return yield new Promise(r => setTimeout(r, delay)); });
exports.mimicDelay = mimicDelay;
