"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityInAllStoresTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const AvailabilityInAllStoresTable = ({ allStoresData, }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Table, { striped: true, fixedLayout: true, variant: cotopaxi_1.TableVariant.STOCK_AVAILABILITY, align: cotopaxi_1.TableAlign.CENTER, firstColumnAlign: cotopaxi_1.TableAlign.LEFT, children: (0, jsx_runtime_1.jsx)("tbody", { children: allStoresData.map((store) => ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { colSpan: 2, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { emphasized: true, inline: true, children: [store.shopNumber, " - ", store.shopName] }) }) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: {
                            spacing: {
                                marginLeft: 2,
                                marginRight: 16,
                            },
                        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: String(store.availableStock) }) }) })] }, `${store.shopNumber}_${store.shopName}`))) }) }));
exports.AvailabilityInAllStoresTable = AvailabilityInAllStoresTable;
