"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductBundleAlternative = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductActions_1 = require("../../redux/product/actions/ProductActions");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductBundleAlternativeItem_1 = require("./ProductBundleAlternativeItem");
const ProductBundleAlternative = ({ productSlot, onSubmit, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const dispatch = (0, react_redux_1.useDispatch)();
    const selectedBundleAlternativeTab = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedBundleAlternativeTab)());
    const { bundleChildren: alternatives, displayedProduct, slotNumber } = productSlot;
    const [selectedAlternative, setSelectedAlternative] = (0, react_1.useState)([alternatives[0].productCode]);
    const [formState, setFormState] = (0, react_1.useState)({ submitCount: 0 });
    const [productState, setProductState] = (0, react_1.useState)(() => alternatives.reduce((prev, curr) => {
        const currColour = curr.colours[0];
        return Object.assign(Object.assign({}, prev), { [curr.productCode]: {
                productCode: curr.productCode,
                productId: curr.productId,
                colourId: curr.colourId || currColour.colourId,
                skuId: (0, common_utils_1.determineSelectedSku)(currColour),
            } });
    }, {}));
    const resetSelection = () => {
        setFormState({ submitCount: 0 });
        if (!displayedProduct) {
            setSelectedAlternative([alternatives[0].productCode]);
            return;
        }
        setSelectedAlternative([displayedProduct.productCode]);
    };
    const onPopoverClose = () => {
        dispatch(ProductActions_1.productActions.bundleSetSelectedAlternativeTab(null));
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close: onPopoverClose });
    (0, react_1.useEffect)(() => {
        resetSelection();
        if (displayedProduct) {
            setProductState(prevState => (Object.assign(Object.assign({}, prevState), { [displayedProduct.productCode]: Object.assign({}, displayedProduct) })));
        }
    }, [displayedProduct]);
    (0, react_1.useEffect)(() => {
        if (`bundle-alternatives-slot-${slotNumber}` === selectedBundleAlternativeTab) {
            handlePopoverOpen(null, null, true);
        }
    }, [selectedBundleAlternativeTab]);
    const closePopoverAndDispatchChosenProduct = (product) => {
        if (product.colourId && product.skuId) {
            handlePopoverClose();
            onSubmit(product);
        }
        else {
            setFormState(prev => ({ submitCount: prev.submitCount + 1, error: selectedAlternative[0] }));
        }
    };
    const handlePopoverSubmit = () => closePopoverAndDispatchChosenProduct(productState[selectedAlternative[0]]);
    const handleCloseAndReset = () => {
        handlePopoverClose();
        resetSelection();
    };
    const handleProductChange = (productCode, product, shouldClosePopup = false) => {
        setSelectedAlternative([productCode]);
        if (product) {
            setProductState(prevState => (Object.assign(Object.assign({}, prevState), { [productCode]: product })));
        }
        if (shouldClosePopup) {
            closePopoverAndDispatchChosenProduct(Object.assign({ productCode }, product));
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handleCloseAndReset, size: cotopaxi_1.PopoverSize.DRAWER_LARGE, title: t('product.bundleDeals.alternative.title_plural'), type: cotopaxi_1.PopoverType.DRAWER, noFooterShadow: true, footerElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: t('product.bundleDeals.alternative.cta.save'), onClick: handlePopoverSubmit }) }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.TileList, { tileListName: "alternatives", selectable: true, selectableType: "radio", selectedItems: selectedAlternative, onSelectionChange: setSelectedAlternative, boxProps: {
                mobile: { spacing: { marginLeft: 2, marginRight: 2 } },
                tablet: { spacing: { marginLeft: 4, marginRight: 4 } },
            }, children: alternatives.map((alternative, index) => ((0, jsx_runtime_1.jsx)(ProductBundleAlternativeItem_1.ProductBundleAlternativeItem, { product: alternative, displayed: productState === null || productState === void 0 ? void 0 : productState[alternative.productCode], formState: formState, onProductChange: handleProductChange }, index))) }) }));
};
exports.ProductBundleAlternative = ProductBundleAlternative;
