"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const common_utils_1 = require("@as-react/common-utils");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const react_query_1 = require("@tanstack/react-query");
const ConfigurationUtil_1 = require("../../../../../../util/ConfigurationUtil");
const AddressFields_1 = require("../../../../forms/address-fields/AddressFields");
const i18nConstants_1 = require("../../../../../../constants/i18nConstants");
const RegistrationField_1 = require("../../../../../account/registration/RegistrationField");
const CountryChangeHandler_1 = require("../../../../../account/profile-edit/CountryChangeHandler");
const CountryUtil_1 = require("../../../../../../util/CountryUtil");
const useFeatureToggle_1 = require("../../../../../../hooks/useFeatureToggle");
const COUNTRY_FIELD_NAME = 'address.idCountry';
const AddressBlock = ({ country, required, addresses, onCountryChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [activeCountry, setActiveCountry] = (0, react_1.useState)(country);
    const { errors, touched, values, setFieldValue } = (0, formik_1.useFormikContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const fictiveWebshop = (0, ConfigurationUtil_1.getFictiveWebShop)(aemConfiguration);
    const defaultCountryCode = (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebshop);
    const dataListEnabled = (0, useFeatureToggle_1.useFeatureToggle)('account_datalist_element');
    const { getUSStates } = (0, common_queries_1.generalQueries)({ servicesEndpoint, defaultRequestParams });
    const { getCountries } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: usStates = [] } = (0, react_query_1.useQuery)(getUSStates({ countryId: activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.idCountry }, { enabled: Boolean((activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.idCountry) === common_types_1.CountryId.US) }));
    const { data: countriesData } = (0, react_query_1.useQuery)(getCountries({
        select: data => ({
            countries: data,
            countriesOptions: (0, common_utils_1.sortAlphabetically)(data.map(country => ({
                value: country.idCountry,
                label: country.countryDescriptionTranslations[lang.toUpperCase()],
            })), 'label'),
        }),
    }));
    const { countries = [], countriesOptions = [] } = countriesData || {};
    const handleCountryChange = (country) => {
        setActiveCountry(country);
        onCountryChange(country);
    };
    (0, react_1.useEffect)(() => {
        const countryValue = (0, formik_1.getIn)(values, COUNTRY_FIELD_NAME);
        if (!defaultCountryCode || !!countryValue || !countriesData) {
            return;
        }
        const countryByCode = countries.find(country => country.countryCode === defaultCountryCode.toUpperCase());
        setFieldValue(COUNTRY_FIELD_NAME, countryByCode === null || countryByCode === void 0 ? void 0 : countryByCode.idCountry);
    }, [countriesData]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: isMobile, tablet: 6, desktop: 6, children: [(0, jsx_runtime_1.jsx)(RegistrationField_1.RegistrationField, { label: t('account.details.country'), placeholder: t('account.details.country.placeholder'), name: COUNTRY_FIELD_NAME, id: "country", state: (0, formik_1.getIn)(errors, COUNTRY_FIELD_NAME) && (0, formik_1.getIn)(touched, COUNTRY_FIELD_NAME) && cotopaxi_1.InputState.ERROR, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select, fullWidth: true, options: countriesOptions, dataQA: "country" }), (0, jsx_runtime_1.jsx)(CountryChangeHandler_1.CountryChangeHandler, { countries: countries, setActiveCountry: handleCountryChange })] }) }), (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { country: activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.countryCode, errors: errors, touched: touched, addresses: addresses, dataListEnabled: dataListEnabled, isRequired: required, states: usStates, prefix: "address." })] }));
};
exports.AddressBlock = AddressBlock;
