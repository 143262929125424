"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ZendeskWidgetUtil_1 = require("../../../util/ZendeskWidgetUtil");
const StoreFinderButton = ({ text, selectedSku, isStickyBar, openCC, sizeForgotten, variant = cotopaxi_1.ButtonVariant.OUTLINED, icon = {
    name: cotopaxi_1.IconName.STORE,
    size: cotopaxi_1.IconSize.MAJOR,
}, }) => {
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const hideText = !isDesktop && isStickyBar;
    const openClickAndCollect = () => {
        openCC();
        ZendeskWidgetUtil_1.default.updateZIndex(1);
    };
    const handleClick = () => (!selectedSku ? sizeForgotten() : openClickAndCollect());
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { icon: hideText ? icon : undefined, iconLeft: !hideText ? icon : undefined, fill: !isStickyBar, size: !isStickyBar ? cotopaxi_1.ButtonSize.LARGE : undefined, onClick: handleClick, variant: variant, text: !hideText && text, dataQA: "view_stock" }));
};
exports.default = StoreFinderButton;
