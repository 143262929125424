"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleChangeHandler = void 0;
const formik_1 = require("formik");
const react_1 = require("react");
function TitleChangeHandler({ titleFieldKey = 'title', genderFieldKey = 'gender', titles, genders, }) {
    const { values, setFieldValue } = (0, formik_1.useFormikContext)();
    const titleValue = (0, formik_1.getIn)(values, titleFieldKey);
    (0, react_1.useEffect)(() => {
        if (!titleValue) {
            return;
        }
        const selectedTitle = titles.find(title => String(title.id) === titleValue);
        const selectedGender = genders.find(gender => String(gender.id) === (selectedTitle === null || selectedTitle === void 0 ? void 0 : selectedTitle.idGender));
        if (selectedGender) {
            setFieldValue(genderFieldKey, selectedGender.peopleCode);
        }
    }, [titleValue]);
    return null;
}
exports.TitleChangeHandler = TitleChangeHandler;
