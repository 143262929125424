"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const GlossaryNavigation_1 = require("../components/glossary/glossary-navigation/GlossaryNavigation");
const GlossaryList_1 = require("../components/glossary/glossary-list/GlossaryList");
const GlossaryContainer = ({ glossaryGrouped }) => {
    const [activeGlossaries, setActiveGlossaries] = (0, react_1.useState)(glossaryGrouped);
    const [activeGlossaryItem, setActiveGlossaryItem] = (0, react_1.useState)();
    const handleClick = (filterKey) => {
        setActiveGlossaryItem(filterKey);
        setActiveGlossaries({ [filterKey]: glossaryGrouped[filterKey] });
    };
    const showAll = () => {
        setActiveGlossaryItem(undefined);
        setActiveGlossaries(glossaryGrouped);
    };
    if (!glossaryGrouped) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(GlossaryNavigation_1.GlossaryNavigation, { glossaries: glossaryGrouped, onClick: handleClick, showAll: showAll, activeGlossaryItem: activeGlossaryItem }), (0, jsx_runtime_1.jsx)(GlossaryList_1.GlossaryList, { glossaries: activeGlossaries })] }));
};
exports.default = GlossaryContainer;
