"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const URLParamUtil_1 = require("../util/URLParamUtil");
const common_types_1 = require("@as-react/common-types");
const AnnouncedReturns_1 = require("../components/return-items/AnnouncedReturns");
const ReturnItems_1 = require("../components/return-items/ReturnItems");
const ReturnItemsContainer = ({ configuredReturnOptions }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [activeTab, setActiveTab] = (0, react_1.useState)(common_types_1.ReturnTabPanes.RETURN_ITEMS);
    const handleTabsChange = (_, newActiveTab) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('tab', newActiveTab === common_types_1.ReturnTabPanes.REGISTERED_RETURNS
            ? common_types_1.ReturnRoutePathnames.REGISTERED_RETURNS
            : common_types_1.ReturnRoutePathnames.RETURN_ITEMS);
        const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
        history.pushState(null, '', newRelativePathQuery);
        setActiveTab(newActiveTab);
    };
    (0, react_1.useEffect)(() => {
        const activeTabValue = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'tab');
        if (activeTabValue && activeTabValue === common_types_1.ReturnRoutePathnames.REGISTERED_RETURNS) {
            setActiveTab(common_types_1.ReturnTabPanes.REGISTERED_RETURNS);
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 6 } }, desktop: { spacing: { marginBottom: 7 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: t('returnItems.heading') }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, desktop: { spacing: { marginBottom: 4 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Tabs, { activeTab: activeTab, onChange: handleTabsChange, hideIconMobile: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Tab, { activeTab: activeTab, index: common_types_1.ReturnTabPanes.RETURN_ITEMS, iconName: cotopaxi_1.IconName.REDO_SOLID, label: t('returnItems.tab.returnItems') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Tab, { activeTab: activeTab, index: common_types_1.ReturnTabPanes.REGISTERED_RETURNS, iconName: cotopaxi_1.IconName.CHECK, label: t('returnItems.tab.registeredReturns') })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.TabPanel, { activePanel: activeTab, index: common_types_1.ReturnTabPanes.RETURN_ITEMS, withOverflow: true, children: (0, jsx_runtime_1.jsx)(ReturnItems_1.ReturnItems, { configuredReturnOptions: configuredReturnOptions }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.TabPanel, { activePanel: activeTab, index: common_types_1.ReturnTabPanes.REGISTERED_RETURNS, withOverflow: true, children: (0, jsx_runtime_1.jsx)(AnnouncedReturns_1.AnnouncedReturns, { configuredReturnOptions: configuredReturnOptions }) })] }));
};
exports.default = ReturnItemsContainer;
