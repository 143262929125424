"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const ReviewConstants_1 = require("../constants/ReviewConstants");
class ReviewsAPI {
    constructor() {
        this.getReviewsById = (servicesApi, productId, defaultRequestParameters, selectedLanguage, size) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/review/product/${productId}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
                params: Object.assign(Object.assign({}, defaultRequestParameters), { ignoreLang: selectedLanguage === ReviewConstants_1.ALL_LANGUAGES_REVIEWS_KEY, language: selectedLanguage === ReviewConstants_1.ALL_LANGUAGES_REVIEWS_KEY ? defaultRequestParameters.language : selectedLanguage, size }),
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data || [];
            }
            catch (_a) {
                return [];
            }
        });
        this.getReviewsAverage = (servicesApi, productId, defaultRequestParameters, selectedLanguage) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/review/average/v2/${productId}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
                params: Object.assign(Object.assign({}, defaultRequestParameters), { ignoreLang: selectedLanguage === ReviewConstants_1.ALL_LANGUAGES_REVIEWS_KEY, language: selectedLanguage === ReviewConstants_1.ALL_LANGUAGES_REVIEWS_KEY ? defaultRequestParameters.language : selectedLanguage }),
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data.numberOfScores;
            }
            catch (_b) {
                return null;
            }
        });
        this.postReview = (review, defaultRequestParameters, servicesEndpoint) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesEndpoint}api/review/add`,
                params: defaultRequestParameters,
                data: review,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new ReviewsAPI();
