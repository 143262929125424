"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const dpeEndpoints = {
    get: 'services/public/dpe/partners/',
    vitality: {
        check: 'api/dpe/vitality/check',
    },
};
class DpeApi {
    constructor() {
        this.checkVitality = (eligibilityRequest, apiPrefix, defaultRequestParams) => {
            const axiosConfig = {
                data: eligibilityRequest,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiPrefix}${dpeEndpoints.vitality.check}`,
                withCredentials: true,
            };
            return axios_1.default.request(axiosConfig);
        };
    }
    getPartner(apiPrefix, partner, path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${dpeEndpoints.get}${partner}`,
                withCredentials: true,
                params: { path },
            };
            const dpeEndpointRequest = yield axios_1.default.request(axiosConfig);
            return dpeEndpointRequest.data;
        });
    }
    getPartners(apiPrefix, path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${dpeEndpoints.get}`,
                withCredentials: true,
                params: { path },
            };
            const dpeEndpointRequest = yield axios_1.default.request(axiosConfig);
            return dpeEndpointRequest.data;
        });
    }
}
exports.default = new DpeApi();
