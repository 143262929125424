"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_utils_1 = require("@as-react/common-utils");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const PaymentNotification = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const [payerMessage, setPayerMessage] = (0, react_1.useState)();
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const [{ info }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const showPendingPaymentMessage = Boolean(pricing.totalPendingPaymentAmount) && !info.fullyPaid;
    (0, react_1.useEffect)(() => {
        const messageQueryParam = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'payerMessage');
        setPayerMessage((0, common_utils_1.isEmptyString)(messageQueryParam) ? t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.form.error`) : messageQueryParam);
    }, []);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [Boolean(payerMessage) && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: payerMessage, dataTestId: "saferpay-message" }), showPendingPaymentMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.pending_payment.message`), dataTestId: "pending-payment-message" }))] }));
};
exports.PaymentNotification = PaymentNotification;
