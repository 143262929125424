"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartItemSizeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const SizeDropdown_1 = require("../../size-dropdown/SizeDropdown");
const common_types_1 = require("@as-react/common-types");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const CART_ITEM_SIZE_SELECT_WIDTH = 190;
const getSizeOptions = (product) => {
    var _a;
    if ((product.productType === common_types_1.ProductTypes.PRODUCT || product.productType === common_types_1.ProductTypes.GIFT_CARD) &&
        !!((_a = product.sizes) === null || _a === void 0 ? void 0 : _a.length)) {
        return product.sizes.sort((sizeA, sizeB) => sizeA.order - sizeB.order);
    }
    return [];
};
const CartItemSizeSelect = ({ product, onSizeChange, disabled }) => {
    var _a, _b;
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const oneSizeProductCode = (0, ConfigurationUtil_1.getOneSizeProductCode)(aemConfiguration);
    const sizeOptions = getSizeOptions(product);
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(product.sizes, oneSizeProductCode);
    const { filteredSizes, options } = (0, ProductUtil_1.getSizeSelectOptions)(sizeOptions, true, product.sku);
    if (oneSizeProduct || !(options === null || options === void 0 ? void 0 : options.length)) {
        return null;
    }
    const handleSelectedItemChange = (option) => {
        onSizeChange(option.value.toString());
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginBottom: 2.5 } }, mobile: { width: CART_ITEM_SIZE_SELECT_WIDTH }, children: (0, jsx_runtime_1.jsx)(SizeDropdown_1.SizeDropdown, { id: `basket_overview_item_size_select_${product.productId}_${product.colorId}`, handleSelectedItemChange: handleSelectedItemChange, selectedSku: (_a = product.sku) === null || _a === void 0 ? void 0 : _a.toString(), options: options, appendToBody: true, sizes: filteredSizes, disabled: disabled, toggleElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { name: `cart_item_size_select_${product.productId}`, options: options, selectedValue: (_b = product.sku) === null || _b === void 0 ? void 0 : _b.toString(), dataQA: "size_select", state: disabled ? cotopaxi_1.SelectState.DISABLED : undefined, disabled: true }) }) }));
};
exports.CartItemSizeSelect = CartItemSizeSelect;
