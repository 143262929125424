"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Field = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const Field = ({ id, label, hasLabelBox, required, children }) => {
    const renderLabel = label ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { htmlFor: id, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, required: required, children: label })) : null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [label && hasLabelBox ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: renderLabel }) : renderLabel, children({
                fieldId: id,
                isRequired: required,
            })] }));
};
exports.Field = Field;
