"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceAmount = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const INPUT_ID = 'price-amount-input';
const PriceAmount = ({ error, amount, currency, minAmount, maxAmount, onInputBlur, onAmountChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.checkout]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { htmlFor: INPUT_ID, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('general:gift.card.custom.amount', {
                            currency,
                            min: minAmount,
                            max: maxAmount,
                        }) }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingRight: 9 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Input, { required: true, id: INPUT_ID, name: INPUT_ID, type: cotopaxi_1.InputType.NUMBER, value: amount, min: Number(minAmount), max: Number(maxAmount), state: error && cotopaxi_1.InputState.ERROR, onKeyDown: cotopaxi_1.allowOnlyNumbers, onBlur: onInputBlur, onChange: onAmountChange, placeholder: `${currency}${minAmount} - ${currency}${maxAmount}` }) }), error && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, size: cotopaxi_1.TextSize.SMALL, inline: true, children: t(error, {
                        currency,
                        min: minAmount,
                        max: maxAmount,
                    }) }) }))] }));
};
exports.PriceAmount = PriceAmount;
