"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const qs_1 = require("qs");
class ContentApi {
    constructor() {
        this.getContentFromElasticSearch = (defaultRequestParams_1, servicesApi_1, elasticsearchIndex_1, ...args_1) => tslib_1.__awaiter(this, [defaultRequestParams_1, servicesApi_1, elasticsearchIndex_1, ...args_1], void 0, function* (defaultRequestParams, servicesApi, elasticsearchIndex, page = 0, size = common_types_1.CONTENT_TILES_DEFAULT_COUNT, path, q, tags) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/content/search`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { index: elasticsearchIndex, q,
                    tags,
                    size, from: page * size, path, author: false }),
                withCredentials: true,
                paramsSerializer: params => (0, qs_1.stringify)(params, { arrayFormat: 'repeat' }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getFilters = (servicesApi, mainWebshop, path, category, type) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/services/public/tag/filters`,
                params: {
                    mainwebshop: mainWebshop,
                    path,
                    category,
                    type,
                },
                withCredentials: true,
                paramsSerializer: params => (0, qs_1.stringify)(params, { arrayFormat: 'repeat' }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new ContentApi();
