"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const dayjs = require("dayjs");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const CurrencyUtil_1 = require("../../../util/CurrencyUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CartTotalTable_1 = require("../../../components/cart/cart-total-table/CartTotalTable");
const ProductTileCompact_1 = require("../../product-tile/compact/ProductTileCompact");
const AccountOrder = ({ order, isFirstOrder, loggedIn }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImages = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: isFirstOrder ? 0 : 3, marginBottom: 2 } }, tablet: { spacing: { marginTop: isFirstOrder ? 0 : 2, marginBottom: 3 } }, desktop: { spacing: { marginTop: isFirstOrder ? 0 : 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, tabletBleed: true, mobile: { spacing: { paddingTop: 1, paddingBottom: 1, paddingLeft: 3 }, theme: cotopaxi_1.BoxTheme.PRIMARY }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, children: [`${dayjs(order.saleDate).format(common_types_1.DateFormat.DD_MM_YYYY_DASH)} `, t('account.order.list.header.order.number'), " #", order.orderId, order.shopName && ` - ${order.shopName}`] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1, marginBottom: 3 } }, tablet: { spacing: { marginLeft: 0 } }, children: order.saleLines.map(product => {
                    const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(product.productCode, product.picture, productImages);
                    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 3, paddingBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, Object.assign({ asStatic: true, withoutInnerSpacing: true, brand: product.brand, title: product.productDescription, quantity: product.quantity, image: imageUrl[common_types_1.ProductImageSizesPortraitEnum.thumb], currency: (0, CurrencyUtil_1.convertToCurrencySymbol)(order.currencySignHtml), priceActual: {
                                        formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalSellPrice),
                                    }, pricePrevious: product.totalSellPrice !== product.totalStandardPrice
                                        ? {
                                            formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalStandardPrice),
                                        }
                                        : undefined, type: "extended", deliveryStatus: product.orderStatusDescription, followOrderLink: loggedIn && product.trackingCode ? product.trackingUrl : undefined }, (product.productType === common_types_1.ProductTypes.PRODUCT
                                    ? {
                                        sizeDescription: product.productSize,
                                        colorDescription: product.productColour,
                                    }
                                    : {}))) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.NONE })] }, product.saleLineId));
                }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: { span: 5, push: 7 }, desktop: { span: 5, push: 7 }, gutter: false, children: (0, jsx_runtime_1.jsx)(CartTotalTable_1.CartTotalTable, { textEnlarged: true, currency: (0, CurrencyUtil_1.convertToCurrencySymbol)(order.currencySignHtml), totalBasketDiscountAmount: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(order.totalDiscount), totalPriceSell: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(order.totalStandardPrice), priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(order.totalSellPrice), priceDelivery: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(order.deliveryPrice), totalQuantity: order.totalItems, vouchers: [] }) }) })] }));
};
exports.default = AccountOrder;
