"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNewMobileFilterDesignEnabled = exports.isActiveFilter = exports.getSelectedFilterAmount = exports.getBucketFilter = exports.getRangeFilter = exports.getMultiFilter = exports.getFilterQueryParameter = void 0;
const common_types_1 = require("@as-react/common-types");
const getFilterQueryParameter = (filterValue) => `&filter=${encodeURIComponent(filterValue)}`;
exports.getFilterQueryParameter = getFilterQueryParameter;
// Example: &filter=brand:(171_be;118_be)
const getMultiFilter = (filter, values) => (0, exports.getFilterQueryParameter)(`${filter}:(${values.join(';')})`);
exports.getMultiFilter = getMultiFilter;
// Example: &filter=product_colour_selling_price0902:[500;1000]
const getRangeFilter = (filter, min, max) => (0, exports.getFilterQueryParameter)(`${filter}:[${min};${max}]`);
exports.getRangeFilter = getRangeFilter;
// Example: &filter=company_v_in_web:102<company_v_in_web<103
const getBucketFilter = (filter, value) => (0, exports.getFilterQueryParameter)(`${filter}:${value}`);
exports.getBucketFilter = getBucketFilter;
const getSelectedFilterAmount = (filter, currentFilters) => {
    if (!currentFilters || !currentFilters[filter.filterOn]) {
        return undefined;
    }
    if (filter.type === common_types_1.MULTISELECT_FILTER) {
        return filter.values.filter(({ active }) => active).length;
    }
    return 1;
};
exports.getSelectedFilterAmount = getSelectedFilterAmount;
const isActiveFilter = (filter, activeFilters) => {
    if (activeFilters && activeFilters[filter.filterOn]) {
        if (filter.type === common_types_1.MULTISELECT_FILTER || filter.type === common_types_1.BUCKET_FILTER) {
            return Boolean(filter.values.some(({ active }) => active));
        }
        return true;
    }
    return false;
};
exports.isActiveFilter = isActiveFilter;
const isNewMobileFilterDesignEnabled = (featureToggle, abTestCookie) => {
    if (featureToggle === 'enabled-with-ab-test') {
        return abTestCookie !== null && abTestCookie !== void 0 ? abTestCookie : false;
    }
    return featureToggle === 'enabled';
};
exports.isNewMobileFilterDesignEnabled = isNewMobileFilterDesignEnabled;
