"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ZendeskWidgetUtil {
    static updateZIndex(zIndex) {
        if (this.isEnabled()) {
            window[this.zendeskInterfaceName]('messenger:set', 'zIndex', zIndex);
        }
    }
    static resetZIndex() {
        if (this.isEnabled()) {
            window[this.zendeskInterfaceName]('messenger:set', 'zIndex', this.defaultZIndex);
        }
    }
    static isEnabled() {
        return !!window[this.zendeskInterfaceName];
    }
}
ZendeskWidgetUtil.zendeskInterfaceName = 'zE';
ZendeskWidgetUtil.defaultZIndex = 899;
exports.default = ZendeskWidgetUtil;
