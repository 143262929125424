"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlElementUtil_1 = require("../../../util/HtmlElementUtil");
const initialState = { animate: false };
const AddToWishlistButton = ({ isProductInWishList, onToggle, wishListProduct, isStickyBar, buttonProps, }) => {
    const [animate, setAnimate] = (0, react_1.useState)(initialState.animate);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const toggleWishListProduct = () => {
        onToggle(wishListProduct);
        setAnimate(!isProductInWishList);
        !isDesktop && (0, HtmlElementUtil_1.showMobileHeader)();
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, Object.assign({ noBubble: true, dataQA: "add_to_wishlist", onClick: toggleWishListProduct, variant: cotopaxi_1.ButtonVariant.OUTLINED_ALT, size: !isStickyBar ? cotopaxi_1.ButtonSize.LARGE : undefined, icon: {
            name: !isClient ? cotopaxi_1.IconName.HEART_EMPTY : isProductInWishList ? cotopaxi_1.IconName.HEART : cotopaxi_1.IconName.HEART_EMPTY,
            size: !isStickyBar ? cotopaxi_1.IconSize.MAJOR : cotopaxi_1.IconSize.MEDIUM,
            animation: animate ? cotopaxi_1.IconAnimation.HEART_PULSE : undefined,
        } }, buttonProps)));
};
exports.default = AddToWishlistButton;
