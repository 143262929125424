"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const YonderlandMediatorTypes_1 = require("./types/YonderlandMediatorTypes");
class YonderlandMediator extends Array {
    constructor() {
        super();
        this.events = {};
        this.subscribers = {};
        this.events = {};
        this.subscribers = {};
        if (typeof window !== 'undefined') {
            window.YonderlandMediator = window.YonderlandMediator || [];
            Object.assign(window.YonderlandMediator, this);
            window.YonderlandMediator.forEach(item => this.push(item));
        }
    }
    push(...items) {
        items.forEach(item => {
            switch (item.action) {
                case YonderlandMediatorTypes_1.YonderlandMediatorAction.PUBLISH:
                    this.publish(item);
                    break;
                case YonderlandMediatorTypes_1.YonderlandMediatorAction.SUBSCRIBE:
                    this.subscribe(item);
                    break;
                case YonderlandMediatorTypes_1.YonderlandMediatorAction.UNSUBSCRIBE:
                    this.unsubscribe(item);
                    break;
            }
        });
        return this.length;
    }
    publish(data) {
        var _a, _b;
        data.action = YonderlandMediatorTypes_1.YonderlandMediatorAction.PUBLISH;
        const { group } = data;
        this.events[group] = this.events[group] || [];
        this.subscribers[group] = this.subscribers[group] || [];
        const subscribers = this.subscribers[group];
        subscribers === null || subscribers === void 0 ? void 0 : subscribers.forEach(subscriber => {
            if (this.isSubscribed(data, subscriber)) {
                subscriber.callback(data);
            }
        });
        (_b = (_a = this.events) === null || _a === void 0 ? void 0 : _a[group]) === null || _b === void 0 ? void 0 : _b.push(data);
    }
    subscribe(subscriber) {
        var _a, _b, _c;
        if (subscriber.action !== YonderlandMediatorTypes_1.YonderlandMediatorAction.SUBSCRIBE) {
            return;
        }
        if (!((_a = this.subscribers) === null || _a === void 0 ? void 0 : _a[subscriber.group])) {
            this.subscribers[subscriber.group] = [];
        }
        (_b = this.subscribers[subscriber.group]) === null || _b === void 0 ? void 0 : _b.push(subscriber);
        (_c = this.events[subscriber.group]) === null || _c === void 0 ? void 0 : _c.forEach(event => {
            if (this.isSubscribed(event, subscriber)) {
                subscriber.callback(event);
            }
        });
    }
    unsubscribe(data) {
        var _a;
        if (data.action !== 'unsubscribe') {
            return;
        }
        (_a = this.subscribers[data.group]) === null || _a === void 0 ? void 0 : _a.forEach((subscriber, index, original) => {
            if (data.on === subscriber.on) {
                original.splice(index, 1);
            }
        });
    }
    isSubscribed(event, subscriber) {
        return event.event === subscriber.on && typeof subscriber.callback === 'function';
    }
}
const yonderlandMediator = new YonderlandMediator();
exports.default = yonderlandMediator;
