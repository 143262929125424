"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtonsFull = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const SizeSelectButtonsFull = ({ id, selectedSku, selectSize, isNotifyMeSizeError, options, filteredSizes, basketValidationState, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const selectedSize = selectedSku && filteredSizes.find(option => option.sku === selectedSku);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)();
    const chipSize = `${Math.max(...options.map(s => s.label.toString().length))}ch + ${ProductConstants_1.PRODUCT_SIZE_CHIP_INNER_PADDING}px`;
    (0, react_1.useEffect)(() => {
        if (basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT ||
            basketValidationState === common_types_1.BasketButtonError.SKU ||
            isNotifyMeSizeError) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(id), isDesktop, () => {
                setErrorMessage((0, ProductUtil_1.getSizeButtonsError)(basketValidationState));
            });
        }
    }, [basketValidationState]);
    const getButtonState = (option) => {
        if (option.disabled)
            return 'disabled';
        if (errorMessage && (!option.isSoldOut || (option.isSoldOut && isNotifyMeSizeError)))
            return 'error';
        if (option.value === selectedSku)
            return 'activated';
        return 'enabled';
    };
    const handleButtonClick = (option) => () => {
        if (option.disabled)
            return;
        setErrorMessage(undefined);
        selectSize({ sku: option.value.toString(), isSoldOut: option.isSoldOut });
    };
    const buttonOptions = (0, react_1.useMemo)(() => options.map(option => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Chip, { state: getButtonState(option), text: option.isSoldOut ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, strike: true, size: cotopaxi_1.TextSize.INHERIT, children: option.label })) : (option.label), onClick: option.disabled ? undefined : handleButtonClick(option), noInlinePadding: true }, option.label))), [options]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { id: id, dataAttributes: { 'data-qa': id }, children: [selectedSize &&
                selectedSize.fullCommercialSize &&
                !options.find(size => size.label === selectedSize.fullCommercialSize) ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutterX: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size.buttons.label') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: selectedSize.fullCommercialSize })] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size.buttons.label') })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1, marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { scalable: {
                        min: `calc(${chipSize})`,
                        maxCols: options.length <= 2 ? { mobile: 2, desktop: 3 } : { mobile: 4, desktop: 6 },
                        repeatPattern: options.length <= 2 ? 'auto-fill' : undefined,
                    }, gap: "s", dataQA: "size-select-buttons", children: buttonOptions }) }), errorMessage && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.INFORMATION_OUTLINE, color: cotopaxi_1.IconColor.NEGATIVE }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.NEGATIVE, size: cotopaxi_1.TextSize.SMALL, children: t(errorMessage) })] }))] }));
};
exports.SizeSelectButtonsFull = SizeSelectButtonsFull;
