"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSustainabilityFeatures = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSustainabilityFeatures = ({ features, contentSpacing, }) => {
    const defaultTableContentSpacing = {
        mobile: {
            spacing: {
                paddingTop: 1.5,
                paddingBottom: 1.5,
                paddingLeft: 2,
                paddingRight: 2,
            },
        },
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: { marginTop: 2.5 },
        }, tablet: {
            spacing: { marginTop: 2.5 },
        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Table, { striped: true, wrapText: true, align: cotopaxi_1.TableAlign.LEFT, valign: cotopaxi_1.TableVerticalAlign.TOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Table.Body, { children: features.map(feature => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Table.Row, { children: [(0, jsx_runtime_1.jsx)("td", { style: { width: '40%' }, colSpan: feature.featureType === 'BOOLEAN' ? 2 : undefined, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((contentSpacing === null || contentSpacing === void 0 ? void 0 : contentSpacing.firstColumn) ? contentSpacing.firstColumn : {}), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, withGlossary: true, children: feature.featureName }) })) }), feature.featureType !== 'BOOLEAN' && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Table.Cell, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((contentSpacing === null || contentSpacing === void 0 ? void 0 : contentSpacing.secondColumn) ? contentSpacing.secondColumn : {}), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, withGlossary: true, children: feature.featureValue }) })) }))] }, feature.featureId))) }) }) }));
};
exports.ProductSustainabilityFeatures = ProductSustainabilityFeatures;
