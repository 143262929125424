"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productPreviewActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.productPreviewActions = {
    disableImageTouchZoomHint: () => ({
        type: ActionEnums_1.ProductPreviewActionTypes.DISABLE_IMAGE_TOUCH_ZOOM_HINT,
    }),
    updateImageTouchZoomHint: (payload) => ({
        type: ActionEnums_1.ProductPreviewActionTypes.UPDATE_IMAGE_TOUCH_ZOOM_HINT,
        payload,
    }),
};
