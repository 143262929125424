"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const MenuSearch_1 = require("../components/menu-search/MenuSearch");
const i18nConstants_1 = require("../constants/i18nConstants");
const SearchSelector_1 = require("../redux/search/selectors/SearchSelector");
const SearchActions_creators_1 = require("../redux/search/actions/SearchActions.creators");
const SearchConstants_1 = require("../constants/SearchConstants");
const SearchUtil_1 = require("../util/SearchUtil");
const MenuSearchContainer = ({ createAdobeAnalyticsSetSearchTermAction, queryValue, searchBrandAsCategories, searchBrands, searchCategories, searchContent, searchFetchWithoutProductsAction, searchKeywords, suggestionsParentLink, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const [searchValue, setSearchValue] = (0, react_1.useState)(queryValue);
    const [maxAmountEnhancedItems, setMaxAmountEnhancedItems] = (0, react_1.useState)(SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_1_SEARCH_CHARACTER);
    const setSearchTerm = (searchTerm) => {
        if (searchTerm) {
            searchFetchWithoutProductsAction(searchTerm);
            switch (searchTerm.trim().length) {
                case 1:
                    setMaxAmountEnhancedItems(SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_1_SEARCH_CHARACTER);
                    break;
                case 2:
                    setMaxAmountEnhancedItems(SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_2_SEARCH_CHARACTER);
                    break;
                default:
                    setMaxAmountEnhancedItems(SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_MORE_THEN_2_SEARCH_CHARACTER);
                    break;
            }
        }
        else {
            setMaxAmountEnhancedItems(SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_1_SEARCH_CHARACTER);
        }
        setSearchValue(searchTerm);
    };
    const menuSearchItemGrouping = (0, SearchUtil_1.getSearchItemGrouping)({
        keywords: searchKeywords,
        categories: searchCategories,
        brands: searchBrands,
        brandAsCategories: searchBrandAsCategories,
        content: searchContent,
    }, maxAmountEnhancedItems, t);
    return ((0, jsx_runtime_1.jsx)(MenuSearch_1.MenuSearch, { createAdobeAnalyticsSetSearchTermAction: createAdobeAnalyticsSetSearchTermAction, menuSearchItemGrouping: menuSearchItemGrouping, setSearchValue: setSearchTerm, suggestionsParentLink: suggestionsParentLink, searchValue: searchValue }));
};
const makeMapStateToProps = () => {
    const getSearchContentState = (0, SearchSelector_1.makeGetSearchContentState)();
    const getSearchKeywordsSuggestionsState = (0, SearchSelector_1.makeGetSearchSuggestionsState)();
    const getSearchBrandsSuggestionsState = (0, SearchSelector_1.makeGetSearchBrandsState)();
    const getSearchCategoriesSuggestionsState = (0, SearchSelector_1.makeGetSearchCategoriesState)();
    const getSearchBrandAsCategoriesState = (0, SearchSelector_1.makeGetSearchBrandAsCategoriesState)();
    const mapStateToProps = (state, ownProps) => ({
        searchKeywords: getSearchKeywordsSuggestionsState(state, ownProps.suggestionsParentLink),
        searchBrands: (!!ownProps.brandsParentLink && getSearchBrandsSuggestionsState(state, ownProps.brandsParentLink)) || [],
        searchCategories: (!!ownProps.categoriesParentLink && getSearchCategoriesSuggestionsState(state, ownProps.categoriesParentLink)) ||
            [],
        searchBrandAsCategories: (!!ownProps.categoriesParentLink && getSearchBrandAsCategoriesState(state, ownProps.categoriesParentLink)) || [],
        searchContent: getSearchContentState(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    searchFetchWithoutProductsAction: SearchActions_creators_1.searchActions.createFetchWithoutProductsAction,
    createAdobeAnalyticsSuccessProductAction: SearchActions_creators_1.searchActions.createAdobeAnalyticsSuccessProductAction,
    createAdobeAnalyticsSetSearchTermAction: SearchActions_creators_1.searchActions.createAdobeAnalyticsSetSearchTermAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(MenuSearchContainer);
