"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductGroupFredhopper_module_1 = require("./modules/ProductGroupFredhopper.module");
const WishList_module_1 = require("./modules/WishList.module");
const Compare_module_1 = require("./modules/Compare.module");
const Product_module_1 = require("./modules/Product.module");
const loadProductGroupPdpComparisonFredhopperRootModule = (initialData) => {
    return {
        hasRouter: false,
        modules: [
            (0, ProductGroupFredhopper_module_1.default)(initialData),
            (0, Compare_module_1.default)(),
            (0, WishList_module_1.default)(),
            (0, Product_module_1.default)(),
        ],
    };
};
exports.default = loadProductGroupPdpComparisonFredhopperRootModule;
