"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const OrdersSelectors_1 = require("../redux/orders/selectors/OrdersSelectors");
const OrdersActions_creators_1 = require("../redux/orders/actions/OrdersActions.creators");
const OrderOverview_1 = require("../components/order-overview/OrderOverview");
const i18nConstants_1 = require("../constants/i18nConstants");
const MAX_NUMBER_OF_ITEMS_IN_LIMITED_VIEW = 5;
const OrderOverviewContainer = ({ returnItemsPath, title }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [showLimitedItems, setShowLimitedItems] = (0, react_1.useState)(true);
    const dispatch = (0, react_redux_1.useDispatch)();
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const userUUID = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUUID)());
    const orderedItems = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetOrderedItems)());
    const ordersStatus = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetOrderedItemsState)());
    const visibleOrders = showLimitedItems
        ? (orderedItems || []).slice(0, MAX_NUMBER_OF_ITEMS_IN_LIMITED_VIEW)
        : orderedItems;
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = sessionState.authorities) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        loggedIn
            ? dispatch(OrdersActions_creators_1.ordersActions.getOrderedItemsForAuthenticatedUser())
            : dispatch(OrdersActions_creators_1.ordersActions.getOrderedItemsForGuestUser());
    }, [sessionState.authorities, loggedIn]);
    const handleOnClick = () => setShowLimitedItems(!showLimitedItems);
    const renderCTAButton = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleOnClick, fill: isMobile, text: showLimitedItems ? t('account.order.list.show.more.button') : t('account.order.list.show.less.button') }) }) }));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_TITLE, priority: cotopaxi_1.HeadingPriority.H2, children: title ? title : t('account.sales.history.title') }) }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: 4 } }, mobileBleed: true, children: [ordersStatus === common_types_1.FetchStatus.RESOLVED && !visibleOrders.length && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('account.sales.history.message.noSales') })), ((0, common_utils_1.isNullOrUndefined)(ordersStatus) || ordersStatus === common_types_1.FetchStatus.PENDING) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Spinner, { size: cotopaxi_1.SpinnerSize.LARGE }) })), ordersStatus === common_types_1.FetchStatus.RESOLVED && Boolean(visibleOrders.length) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [visibleOrders.map((order) => ((0, jsx_runtime_1.jsx)(OrderOverview_1.OrderOverview, { loggedIn: loggedIn, order: order, returnItemsPath: returnItemsPath, userUUID: userUUID }, order.orderId))), orderedItems.length > MAX_NUMBER_OF_ITEMS_IN_LIMITED_VIEW && renderCTAButton] }))] })] }));
};
exports.default = OrderOverviewContainer;
