"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useElementIsVisible = void 0;
const react_1 = require("react");
const useElementIsVisible = (target, options = { threshold: 0.01 }) => {
    const [isVisible, setIsVisible] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!(target === null || target === void 0 ? void 0 : target.current))
            return undefined;
        const callback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(target.current);
                }
            });
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target.current);
        return () => observer.disconnect();
    }, []);
    return isVisible;
};
exports.useElementIsVisible = useElementIsVisible;
