"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const react_1 = require("react");
const HtmlInclude_1 = require("../html-include/HtmlInclude");
const axios_1 = require("axios");
const logger_1 = require("@as-react/logger");
const EsiInclude = props => {
    const [mockEsiData, setMockEsiData] = (0, react_1.useState)();
    const setEsiTag = () => {
        return `
            <esi:try> 
                <esi:attempt>
                    <esi:include src="${props.url}"/> 
                </esi:attempt>
                <esi:except> 
                    <esi:comment text="Just write some HTML instead"/> 
                </esi:except> 
            </esi:try>`;
    };
    const setEsiScript = () => {
        return `SITE.data.${props.dataKey} = SITE.dataImporter(<esi:include src="${props.url}"/>);`;
    };
    (0, react_1.useEffect)(() => {
        if (common_utils_1.Environment.isDevelopment()) {
            const axiosConfig = {
                method: 'GET',
                url: `${props.url}`,
            };
            axios_1.default
                .request(axiosConfig)
                .then(response => {
                if (props.dataKey) {
                    SITE.data[props.dataKey] = response.data;
                    props.throwAction(response.data);
                }
                else {
                    setMockEsiData(response.data);
                }
            })
                .catch(logger_1.Logger.logError);
        }
    }, [props.url]);
    (0, react_1.useEffect)(() => {
        if (common_utils_1.Environment.isDevelopment() && mockEsiData) {
            typeof props.onDataLoaded === 'function' && props.onDataLoaded();
        }
    }, [props.onDataLoaded, mockEsiData]);
    if (common_utils_1.Environment.isProduction() && props.dataKey) {
        return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { scriptFragment: setEsiScript() });
    }
    else if (common_utils_1.Environment.isProduction()) {
        return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: setEsiTag() });
    }
    else if (props.dataKey) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: mockEsiData });
};
exports.default = (0, react_1.memo)(EsiInclude);
