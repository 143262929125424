"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyGuestLoginForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const EmailChangeHandler_1 = require("../../../components/email/EmailChangeHandler");
const EmailError_1 = require("../../../components/email/EmailError");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_redux_1 = require("react-redux");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const EmailValidationUtil_1 = require("../../../components/email/EmailValidationUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const AuthActions_creators_1 = require("../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../redux/authentication/selectors/AuthSelectors");
const Yup = require("yup");
const common_types_1 = require("@as-react/common-types");
const LoginGuestForm_1 = require("../forms/login-guest-form/LoginGuestForm");
const BuyGuestLoginForm = ({ enableLoginForm }) => {
    const [guestEmail, setGuestEmail] = (0, react_1.useState)(SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.GUEST_EMAIL) || '');
    const [suggestedEmail, setSuggestedEmail] = (0, react_1.useState)();
    const [skipGuestEmailValidation, setSkipGuestEmailValidation] = (0, react_1.useState)(false);
    const [emailStatus, setEmailStatus] = (0, react_1.useState)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const guestError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetErrorGuestState)());
    const loadingGuest = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginGuestLoading)());
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const validateGuestEmail = (0, react_1.useCallback)((0, EmailValidationUtil_1.validateEmail)(servicesEndpoint, skipGuestEmailValidation, emailStatus, setSuggestedEmail, setEmailStatus), [skipGuestEmailValidation, emailStatus]);
    const guestEmailValidationSchema = Yup.object().shape({
        guestEmail: Yup.string().test(validateGuestEmail),
    });
    const onGuestEmailChange = (value) => {
        setGuestEmail(value);
        skipGuestEmailValidation && setSkipGuestEmailValidation(false);
    };
    const handleErrorGuest = (errors) => {
        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[for="${errors[0]}"]`));
    };
    const handleSubmitGuest = (values) => {
        dispatch(AuthActions_creators_1.authActions.createLoginGuestAction(values.guestEmail));
    };
    const handleAcceptClick = () => {
        setEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
        setGuestEmail(suggestedEmail);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.guest.title`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: { marginTop: 2, marginBottom: 3 },
                }, tablet: {
                    spacing: { marginBottom: 0 },
                }, children: (0, jsx_runtime_1.jsx)(LoginGuestForm_1.LoginGuestForm, { onSubmit: handleSubmitGuest, onSubmitError: handleErrorGuest, validationSchema: guestEmailValidationSchema, errorFeedback: guestError ? t(guestError) : undefined, loading: loadingGuest, initialEmail: guestEmail, emailErrorRender: msg => ((0, jsx_runtime_1.jsx)(EmailError_1.EmailError, { emailStatus: msg, suggestedEmail: suggestedEmail, requiredErrorLabel: t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`), onAcceptClick: handleAcceptClick, onIgnoreClick: () => setSkipGuestEmailValidation(true) })), hideGuestLoginInfo: enableLoginForm, children: (0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "guestEmail", emailValue: guestEmail, skipEmailValidation: skipGuestEmailValidation, onEmailChange: onGuestEmailChange, setEmailStatus: setEmailStatus }) }) })] }));
};
exports.BuyGuestLoginForm = BuyGuestLoginForm;
