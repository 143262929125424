"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPreviewLightBox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const LightBoxMainSlider_1 = require("../light-box-main-slider/LightBoxMainSlider");
const LightBoxThumbSlider_1 = require("../light-box-thumb-slider/LightBoxThumbSlider");
const ProductPreviewLightBox = ({ activeSlideIndex, mediaCollection, onActiveSlideIndexChange, alt, popoverHandlers, }) => {
    const { anchorEl, handlePopoverClose } = popoverHandlers;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { disableClickOutside: true, anchorEl: anchorEl, type: cotopaxi_1.PopoverType.LIGHT_BOX, onClose: handlePopoverClose, withHeader: false, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ModalCloseBtn, { isFloat: true, close: handlePopoverClose }), (0, jsx_runtime_1.jsx)(LightBoxMainSlider_1.LightBoxMainSlider, { alt: alt, activeSlideIndex: activeSlideIndex, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(LightBoxThumbSlider_1.LightBoxThumbSlider, { alt: alt, activeSlideIndex: activeSlideIndex, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange }) }) })] }));
};
exports.ProductPreviewLightBox = ProductPreviewLightBox;
