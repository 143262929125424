"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVirtualKeyboard = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const VIEWPORT_HEIGHT_ADJUSTMENT = 100;
// this only works when meta viewport has default value(resizes-visual) for "interactive-widget" property
const useVirtualKeyboard = () => {
    const [virtualKeyboardHeight, setVirtualKeyboardHeight] = (0, react_1.useState)(null);
    const handleVisualViewportResize = (0, cotopaxi_1.useDebounce)((event) => {
        const visualViewportHeight = event.target.height;
        const windowHeight = window.innerHeight;
        // visualViewportHeight may initially be slightly smaller than windowHeight
        if (visualViewportHeight + VIEWPORT_HEIGHT_ADJUSTMENT < windowHeight) {
            setVirtualKeyboardHeight(windowHeight - visualViewportHeight);
        }
        else {
            setVirtualKeyboardHeight(null);
        }
    }, GeneralConstants_1.DELAY.DELAY_20);
    (0, react_1.useEffect)(() => {
        window.visualViewport && window.visualViewport.addEventListener('resize', handleVisualViewportResize);
        return () => {
            window.visualViewport && window.visualViewport.removeEventListener('resize', handleVisualViewportResize);
        };
    }, []);
    return virtualKeyboardHeight;
};
exports.useVirtualKeyboard = useVirtualKeyboard;
