"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnouncedReturns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const dayjs = require("dayjs");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const OrdersSelectors_1 = require("../../redux/orders/selectors/OrdersSelectors");
const OrdersActions_creators_1 = require("../../redux/orders/actions/OrdersActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const AnnouncedReturn_1 = require("./AnnouncedReturn");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const ReturnItemsEmptyState_1 = require("./ReturnItemsEmptyState");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const AnnouncedReturns = ({ configuredReturnOptions }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const dispatch = (0, react_redux_1.useDispatch)();
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const returnedSales = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetReturnedSales)());
    (0, react_1.useEffect)(() => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.returnPortalAnnouncedOverviewOpened());
    }, []);
    (0, react_1.useEffect)(() => {
        if (!sessionState.authorities) {
            return;
        }
        dispatch(OrdersActions_creators_1.ordersActions.getReturnedSales());
        dispatch(OrdersActions_creators_1.ordersActions.getReturnReasons());
    }, [sessionState, loggedIn]);
    return (returnedSales === null || returnedSales === void 0 ? void 0 : returnedSales.length) ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: returnedSales.map((returnedSale, index) => {
            let readableDate = '';
            if (returnedSale.date) {
                readableDate = `${dayjs(returnedSale.date).format(common_types_1.DateFormat.DD_MM_YYYY_DASH)} `;
            }
            const collapseTitle = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
                    spacing: { paddingRight: 5 },
                }, tablet: {
                    spacing: { paddingRight: 3.5 },
                }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.XS, priority: cotopaxi_1.HeadingPriority.H4, children: [readableDate, t('returnItems.registeredReturns.return'), " #", returnedSale.announcementReference ? returnedSale.announcementReference : returnedSale.saleId] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, align: cotopaxi_1.TextAlign.RIGHT, children: returnedSale.statusMessage === common_types_1.ReturnedSaleStatusTypes.COMPLETED
                                ? t('returnItems.registeredReturns.status.completed')
                                : t('returnItems.registeredReturns.status.inProcess') })] }) }));
            return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [index === 0 && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: true, margin: cotopaxi_1.DividerMargin.NONE }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: `announced-returns-collapse-${index}`, title: collapseTitle, withOverflow: true, mobileFullwidth: true, open: returnedSale.statusMessage !== common_types_1.ReturnedSaleStatusTypes.COMPLETED, hasDivider: false, labelFullWidth: true, children: (0, jsx_runtime_1.jsx)(AnnouncedReturn_1.AnnouncedReturn, { configuredReturnOptions: configuredReturnOptions, returnedSale: returnedSale }) }, index), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: true, margin: cotopaxi_1.DividerMargin.NONE })] }, index));
        }) })) : ((0, jsx_runtime_1.jsx)(ReturnItemsEmptyState_1.ReturnItemsEmptyState, { message: t('returnItems.registeredReturns.emptyState.message') }));
};
exports.AnnouncedReturns = AnnouncedReturns;
