"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlternativeProductTile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const AlternativeProductTile = ({ product, onWishListToggle, updateCompareList, }) => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const shopLandingEnabledFlag = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const productImageCdnDomains = (0, ConfigurationUtil_1.getProductImageCdnDomains)(aemConfiguration);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const selectedColor = (0, common_utils_1.findColor)(product.colours, product.colourId);
    const productUrl = (0, ProductUtil_1.getProductDetailUrl)(product, root);
    const compareCheckboxId = `product-compare-alternative-${product.productCode}-${product.colourId}`;
    const { priceActual, pricePrevious } = (0, common_utils_1.generateCotopaxiPriceViewModel)({
        prices: selectedColor.prices,
        hasDiscount: selectedColor.hasDiscount,
    }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency);
    const image = (0, ImageUtil_1.getPortraitImageSizeUrlByProductCode)(product.productCode, selectedColor.image, productImageCdnDomains, common_types_1.ProductImageSizesPortraitEnum.basket);
    const handleWishListClick = () => onWishListToggle({
        productCode: product.productCode,
        productId: product.productId,
        colorId: selectedColor.colourId,
    });
    const handleCompareClick = () => updateCompareList({
        brand: product.brand,
        color: selectedColor.colourId,
        image: selectedColor.image,
        productCode: product.productCode,
        selected: false,
        id: product.productId,
        title: product.title,
        gender: product.genderCode,
        url: productUrl,
        colorDescription: selectedColor.description,
        collapsedComparePopup: true,
    });
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile, { variant: "horizontal", product: {
            image,
            alt: product.title,
            title: product.title,
            brand: product.brand,
            review: {
                amount: (_a = product.productReviews) !== null && _a !== void 0 ? _a : 0,
                score: (_b = product.productRating) !== null && _b !== void 0 ? _b : 0,
            },
            currency,
            priceActual,
            pricePrevious,
        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "primary", href: productUrl, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Image, { lazyLoad: true, width: cotopaxi_1.ProductImageWidth.ALTERNATIVE_TILE, height: cotopaxi_1.ProductImageHeight.ALTERNATIVE_TILE }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "secondary", href: productUrl, containerOptions: { mobile: { spacing: { paddingLeft: 1.5 } } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Title, { brandOnTheSameLine: true, reserveLine: 2, lineClamp: 2, size: cotopaxi_1.TextSize.MEDIUM, containerOptions: { mobile: { spacing: { marginRight: 3 } } } }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Rating, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Price, {}), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: [product.colours.length, " ", t('lister.swatch.counter', { count: product.colours.length })] }), featureToggles.product_compare && ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: compareCheckboxId, name: compareCheckboxId, value: compareCheckboxId, labelChildren: t('productCompare.pdp.checkbox'), checked: selectedColor.isCompareProduct || false, onChange: handleCompareClick }))] }) }), !shopLandingEnabledFlag && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Overlay, { noPointerEvents: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { fullHeight: true, gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.RIGHT, valign: cotopaxi_1.GroupVerticalAlign.TOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleWishListClick, variant: cotopaxi_1.ButtonVariant.INVERSE_ALT, size: cotopaxi_1.ButtonSize.XSMALL, icon: {
                            name: selectedColor.inWishList ? cotopaxi_1.IconName.HEART : cotopaxi_1.IconName.HEART_EMPTY,
                            size: cotopaxi_1.IconSize.MEDIUM,
                            animation: selectedColor.inWishList ? cotopaxi_1.IconAnimation.HEART_PULSE : undefined,
                        } }) }) }))] }));
};
exports.AlternativeProductTile = AlternativeProductTile;
