"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMonetateProductBundle = exports.getBundleChildrenPrice = exports.getBundlePrice = exports.calculateProductsPrice = void 0;
const FIRST_CHILDREN_INDEX = 2;
const calculateProductsPrice = (prices) => {
    const { std, sell } = prices.reduce((acc, price) => {
        var _a, _b;
        return ({
            sell: acc.sell + Math.round(price.SELL.original * 100),
            std: acc.std + Math.round((((_a = price === null || price === void 0 ? void 0 : price.STD) === null || _a === void 0 ? void 0 : _a.original) || ((_b = price === null || price === void 0 ? void 0 : price.RRP) === null || _b === void 0 ? void 0 : _b.original) || price.SELL.original) * 100),
        });
    }, { std: 0, sell: 0 });
    return {
        STD: { original: std / 100 },
        SELL: { original: sell / 100 },
        discountPercentage: std ? ((std - sell) / std) * 100 : 0,
        discountAmount: (std - sell) / 100,
    };
};
exports.calculateProductsPrice = calculateProductsPrice;
const getBundlePrice = (parentPrice, childrenPrice) => {
    const childrenTotalPrice = (0, exports.calculateProductsPrice)(childrenPrice);
    const totalPrice = (0, exports.calculateProductsPrice)([parentPrice, childrenTotalPrice]);
    return {
        totalPrices: {
            STD: totalPrice.STD,
            SELL: totalPrice.SELL,
        },
        bundleChildrenDiscountPct: Math.floor(childrenTotalPrice.discountPercentage),
        totalDiscountPercentage: -Math.round(-totalPrice.discountPercentage), // half round down
        totalDiscountAmount: totalPrice.discountAmount,
    };
};
exports.getBundlePrice = getBundlePrice;
const getBundleChildrenPrice = (bundle) => bundle.bundleSlots
    .filter(({ isSelected }) => isSelected)
    .map(({ bundleChildren, displayedProduct }) => (Object.assign({}, bundleChildren
    .find(children => children.productId === displayedProduct.productId)
    .colours.find(childrenColour => childrenColour.colourId === displayedProduct.colourId)
    .sizes.find(childrenSize => childrenSize.sku === displayedProduct.skuId).prices)));
exports.getBundleChildrenPrice = getBundleChildrenPrice;
const getMonetateProductBundle = (parent, children, monetateId, childrenLimit = 5) => {
    const selectedColor = parent.productColorVariations.find(color => color.colorId === parent.selectedColorId);
    const childrenProducts = children.slice(0, childrenLimit);
    return {
        isMonetateBundle: true,
        bundleId: monetateId,
        colourCode: selectedColor.colorCode,
        colourId: parent.selectedColorId,
        description: selectedColor.translations.description,
        image: selectedColor.images[0].picture,
        prices: selectedColor.prices,
        productCode: parent.productCode,
        productId: Number(parent.productId),
        sizes: selectedColor.sizes,
        swatch: selectedColor.swatch,
        bundleSlots: childrenProducts.map((product, index) => ({
            slotNumber: index + FIRST_CHILDREN_INDEX,
            bundleChildren: [
                {
                    slotNumber: index + FIRST_CHILDREN_INDEX,
                    availability: product.availability,
                    brand: product.brand,
                    brandId: product.brandId,
                    colourId: product.colours.length > 1 ? null : product.colourId,
                    currency: product.currency,
                    featureValueGenderId: product.featureValueGenderId,
                    genderCode: product.genderCode,
                    productCode: product.productCode,
                    productId: product.productId,
                    productRating: product.productRating,
                    productReviews: product.productReviews,
                    seoUrl: product.seoUrl,
                    title: product.title,
                    type: product.type,
                    colours: product.colours.map((colour, cindex) => (Object.assign(Object.assign({}, colour), { bundleDiscountPresent: false, sizes: colour.sizes.map(size => (Object.assign(Object.assign({}, size), { price: {
                                discountAmount: size.productDiscountAmount,
                                discountPercentage: size.productDiscountPercentage,
                            } }))), bundleId: monetateId, sortOrder: cindex + 1, promoDiscountPresent: colour.promoDiscountPresent, price: {
                            discountAmount: colour.price.productDiscountAmount,
                            discountPercentage: colour.price.productDiscountPercentage,
                        } }))),
                },
            ],
        })),
    };
};
exports.getMonetateProductBundle = getMonetateProductBundle;
