"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productComparisonActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CompareConstants_1 = require("../../../constants/CompareConstants");
exports.productComparisonActions = {
    getInitialComparisonProducts: () => ({
        type: ActionEnums_1.ProductComparisonActionTypes.INIT,
    }),
    fetchProducts: (payload) => ({
        type: ActionEnums_1.ProductComparisonActionTypes.FETCH,
        payload,
    }),
    removeProduct: (index) => ({
        type: ActionEnums_1.ProductComparisonActionTypes.REMOVE,
        payload: {
            index,
            sourceComponent: CompareConstants_1.COMPARE_TRIGGERED_COMPONENT.comparePage,
        },
    }),
    getDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductComparisonActionTypes.GET_DELIVERY_PROMISE,
        payload,
    }),
    setDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductComparisonActionTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
    createSelectSizeAction: (payload) => ({
        type: ActionEnums_1.ProductComparisonActionTypes.COMPARE_ITEM_SELECT_SIZE,
        payload,
    }),
};
