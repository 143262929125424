"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointSelected = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const PickupPointUtil_1 = require("../../../../util/PickupPointUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const DeliverySelectedLocation_1 = require("../DeliverySelectedLocation");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointSelected = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [{ pickupPoint }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { selectedPickupPoint } = pickupPoint;
    if (!selectedPickupPoint)
        return null;
    const locationName = selectedPickupPoint.locationName;
    const address = selectedPickupPoint.address;
    const houseNumber = selectedPickupPoint.houseNumber;
    const postcode = selectedPickupPoint.postcode;
    const city = selectedPickupPoint.city;
    const organisationName = `${selectedPickupPoint.organisationName} ${PickupPointUtil_1.default.getTranslation(selectedPickupPoint.sanitizedLocationType, selectedPickupPoint.locationType, t)}`;
    const isAlwaysOpen = PickupPointUtil_1.default.isAlwaysOpen(selectedPickupPoint.sanitizedLocationType || selectedPickupPoint.locationType);
    const handleEditPickupPoint = () => {
        dispatch({ type: 'pickup_point_set_is_open', payload: true });
    };
    const getAddress = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [organisationName, (0, jsx_runtime_1.jsx)("br", {}), address, " ", houseNumber, (0, jsx_runtime_1.jsx)("br", {}), postcode, " ", city] }));
    const getOpeningHours = () => isAlwaysOpen ? (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t('buy.delivery.pickuppoint.open.24') }) : undefined;
    return ((0, jsx_runtime_1.jsx)(DeliverySelectedLocation_1.DeliverySelectedLocation, { locationName: locationName, addressContent: getAddress(), openingHoursContent: getOpeningHours(), handleEdit: handleEditPickupPoint }));
};
exports.PickupPointSelected = PickupPointSelected;
