"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const MonetateSelectors_1 = require("../../redux/monetate/selectors/MonetateSelectors");
const MonetateActions_creators_1 = require("../../redux/monetate/actions/MonetateActions.creators");
const ProductGroupPdpComparison_1 = require("../../components/product-group-comparison/ProductGroupPdpComparison");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const getAllProductComparisonFeatures = (productsFeatures, initialProductsFeaturesRecord) => {
    const shouldSetProductsFeaturesRecord = productsFeatures.some(productFeature => productFeature && productFeature.features.length > 0);
    return shouldSetProductsFeaturesRecord
        ? productsFeatures.reduce((acc, productFeatures) => {
            if (productFeatures) {
                acc[productFeatures.productId] = productFeatures.features;
            }
            return acc;
        }, initialProductsFeaturesRecord)
        : initialProductsFeaturesRecord;
};
const ProductGroupPdpComparisonMonetateContainer = ({ actionId, productId, title, maxProductsSize, productsFeaturesRecord: initialProductsFeaturesRecord, currentProductHasFeatures, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const fictiveWebShop = (0, ConfigurationUtil_1.getFictiveWebShop)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const recommendations = (0, react_redux_1.useSelector)((0, MonetateSelectors_1.makeGetMonetateRecommendations)(actionId));
    const products = (0, react_redux_1.useSelector)((0, MonetateSelectors_1.makeGetMonetateProducts)(actionId));
    const { getComparisonFeatures } = (0, common_queries_1.productQueries)({
        servicesEndpoint,
        defaultRequestParams: (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration),
    });
    const { isPending, data: productsFeatures } = (0, react_query_1.useSuspenseQueries)({
        queries: currentProductHasFeatures
            ? products.map(product => getComparisonFeatures({ fictiveWebShop, productId: product.productId }))
            : [],
        combine: results => ({
            data: results.map(result => result.data),
            isPending: results.some(result => result.isPending),
        }),
    });
    (0, react_1.useEffect)(() => {
        if (recommendations === null || recommendations === void 0 ? void 0 : recommendations.length) {
            dispatch(MonetateActions_creators_1.monetateActions.fetchMonetateProducts({
                actionId,
                size: maxProductsSize,
                filterProductId: productId,
            }));
        }
    }, [recommendations]);
    if (!(products === null || products === void 0 ? void 0 : products.length) || isPending || !productsFeatures) {
        return null;
    }
    const productsFeaturesRecord = getAllProductComparisonFeatures(productsFeatures, initialProductsFeaturesRecord);
    return ((0, jsx_runtime_1.jsx)(ProductGroupPdpComparison_1.ProductGroupPdpComparison, { analyticsAreaName: "product-group-pdp-comparison-monetate", productGroupId: `product-group-pdp-comparison-monetate-${actionId}`, products: products, productsFeaturesRecord: productsFeaturesRecord, title: title, analyticsProductGroupName: `mon_${actionId}`, currentProductHasFeatures: currentProductHasFeatures }));
};
exports.default = ProductGroupPdpComparisonMonetateContainer;
