"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Content_module_1 = require("./modules/Content.module");
const Url_module_1 = require("./modules/Url.module");
const FilterModule_1 = require("./modules/FilterModule");
const loadContentTileListerModule = (initialData) => ({
    hasRouter: true,
    modules: [(0, Content_module_1.default)(initialData), (0, Url_module_1.default)(initialData), (0, FilterModule_1.default)(initialData)],
});
exports.default = loadContentTileListerModule;
