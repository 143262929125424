"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutReducer = exports.initialState = exports.initDeliveryErrors = void 0;
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initDeliveryErrors = {
    COLLECT_STORE: '',
    COLLECT_HQ: '',
    HOME_DELIVERY: '',
    NEXT_DAY: '',
    NAMED_DAY: '',
    PICKUP_POINT: '',
};
exports.initialState = {
    state: common_types_1.CheckoutStatus.UNKNOWN,
    deliveryCountries: [],
    deliveryErrors: exports.initDeliveryErrors,
    paymentOptionError: null,
    deliveryInfo: {
        chosenDeliveryOption: null,
        country: '',
        deliveryAddress: { addToProfile: false, deliveryAddressId: null, isActive: false, defaultAddress: true },
        deliveryAddresses: null,
        deliveryDate: null,
        deliveryOptions: [],
        deliveryTimeslot: {
            end: null,
            start: null,
        },
        idCountry: null,
        orderReference: '',
        priceDelivery: null,
        requiresDelivery: null,
        store: null,
        storeNumber: null,
        zipCode: null,
        locationDTO: null,
    },
    deliveryLoading: false,
    deliverySaving: false,
    deliveryDates: [],
    customerTitles: [],
    paymentInfo: null,
    selectedPaymentOption: {
        name: null,
        id: null,
    },
    storeDetails: null,
    states: null,
    isSetPaymentOptionSuccess: false,
    klarna: {
        klarnaToken: null,
    },
    saferpayFieldsReady: false,
    collectionPoint: {
        points: [],
        activePointId: null,
        showAllPoints: false,
        deliveryInfoView: false,
        locationError: null,
        locationFieldError: null,
    },
    confirmationError: null,
    confirmationOrder: null,
    confirmationIsCollectStore: false,
    newsletterPreferences: null,
    collectStore: {
        stores: [],
        activeStoreId: null,
        showAllStores: false,
        deliveryInfoView: false,
        locationError: null,
        searchByLocation: null,
        onlyInStock: false,
    },
    fastCheckoutOverview: null,
    customerDetails: null,
    isPaymentOptionUpdating: false,
};
const checkoutReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.CheckoutActionTypes.INIT:
            return Object.assign(Object.assign({}, state), { state: common_types_1.CheckoutStatus.INITIALIZING });
        case ActionEnums_1.CheckoutActionTypes.ERROR:
            return Object.assign(Object.assign({}, state), { state: common_types_1.CheckoutStatus.ERROR });
        case ActionEnums_1.CheckoutActionTypes.INIT_SET:
            return Object.assign(Object.assign({}, state), { state: common_types_1.CheckoutStatus.INITIALIZED, initResponse: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_COUNTRIES:
            return Object.assign(Object.assign({}, state), { deliveryCountries: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_INFO_ADDRESS:
            return Object.assign(Object.assign({}, state), { deliveryInfo: Object.assign(Object.assign({}, state.deliveryInfo), { deliveryAddress: Object.assign(Object.assign({}, state.deliveryInfo.deliveryAddress), action.payload) }) });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_US_STATES:
            return Object.assign(Object.assign({}, state), { states: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_DATES:
            return Object.assign(Object.assign({}, state), { deliveryDates: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_ADDRESS_STATE:
            return Object.assign(Object.assign({}, state), { deliveryInfo: Object.assign(Object.assign({}, state.deliveryInfo), { deliveryAddress: Object.assign(Object.assign({}, state.deliveryInfo.deliveryAddress), { state: action.payload.name, stateIso: action.payload.abbreviation }) }) });
        case ActionEnums_1.CheckoutActionTypes.GET_DELIVERY_INFO:
            return Object.assign(Object.assign({}, state), { deliveryLoading: true });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_INFO:
            return Object.assign(Object.assign({}, state), { deliveryInfo: action.payload, deliveryLoading: false, collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { deliveryInfoView: !!action.payload.pickupInformation &&
                        action.payload.country === action.payload.pickupInformation.properties.customerCountry }), collectStore: Object.assign(Object.assign({}, state.collectStore), { deliveryInfoView: !!action.payload.store, activeStoreId: action.payload.store }) });
        case ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_COUNTRY:
            return Object.assign(Object.assign({}, state), { deliveryInfo: Object.assign(Object.assign({}, state.deliveryInfo), { idCountry: action.payload }), deliveryErrors: exports.initDeliveryErrors });
        case ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_OPTION_SUCCESS:
            return Object.assign(Object.assign({}, state), { isSetPaymentOptionSuccess: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_OPTION_ERROR:
            return Object.assign(Object.assign({}, state), { paymentOptionError: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO:
            return Object.assign(Object.assign({}, state), { deliverySaving: true, deliveryErrors: Object.assign(Object.assign({}, state.deliveryErrors), { [state.deliveryInfo.chosenDeliveryOption]: '' }) });
        case ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO_SUCCESS:
            return Object.assign(Object.assign({}, state), { deliveryInfo: action.payload, deliverySaving: false });
        case ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO_FAIL:
            return Object.assign(Object.assign({}, state), { deliverySaving: false, deliveryErrors: Object.assign(Object.assign({}, state.deliveryErrors), { [state.deliveryInfo.chosenDeliveryOption]: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_CUSTOMER_TITLES:
            return Object.assign(Object.assign({}, state), { customerTitles: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_OPTION:
            return Object.assign(Object.assign({}, state), { deliveryInfo: Object.assign(Object.assign({}, state.deliveryInfo), { chosenDeliveryOption: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_STORE:
            const { storeId, storeNumber } = action.payload;
            return Object.assign(Object.assign({}, state), { deliveryInfo: Object.assign(Object.assign({}, state.deliveryInfo), { chosenClickAndCollectStoreId: storeId, store: storeId, storeNumber }) });
        case ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_INFO:
            return Object.assign(Object.assign({}, state), { paymentInfo: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_SELECTED_PAYMENT_OPTION:
            return Object.assign(Object.assign({}, state), { selectedPaymentOption: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_ADDRESS_STATE:
            return Object.assign(Object.assign({}, state), { paymentInfo: Object.assign(Object.assign({}, state.paymentInfo), { deliveryAddress: Object.assign(Object.assign({}, state.paymentInfo.deliveryAddress), { state: action.payload.name, stateIso: action.payload.abbreviation }) }) });
        case ActionEnums_1.CheckoutActionTypes.SET_STORE_DETAILS:
            return Object.assign(Object.assign({}, state), { storeDetails: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_KLARNA:
            return Object.assign(Object.assign({}, state), { klarna: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_SAFERPAY_FIELDS_READY:
            return Object.assign(Object.assign({}, state), { saferpayFieldsReady: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_PICKUP_POINTS:
            const { locationError } = state.collectionPoint;
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { points: action.payload, activePointId: null, showAllPoints: false, locationError: locationError === common_types_1.ErrorMessage.LOCATION_BLOCKED ? locationError : '' }) });
        case ActionEnums_1.CheckoutActionTypes.RESET_PICKUP_POINTS:
            return Object.assign(Object.assign({}, state), { collectionPoint: exports.initialState.collectionPoint });
        case ActionEnums_1.CheckoutActionTypes.SET_ACTIVE_PICKUP_POINT_ID:
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { activePointId: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_SHOW_ALL_PICKUP_POINTS:
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { showAllPoints: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_PICK_UP_POINT_LOCATION_FIELD_ERROR:
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { locationFieldError: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECTION_POINT_VIEW:
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { deliveryInfoView: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_LOCATION_ERROR_COLLECTION_POINT:
            return Object.assign(Object.assign({}, state), { collectionPoint: Object.assign(Object.assign({}, state.collectionPoint), { locationError: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_ORDER:
            return Object.assign(Object.assign({}, state), { confirmationOrder: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_IS_COLLECT_STORE:
            return Object.assign(Object.assign({}, state), { confirmationIsCollectStore: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_ORDER_ERROR:
            return Object.assign(Object.assign({}, state), { confirmationError: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_NEWSLETTER_PREFERENCES:
            return Object.assign(Object.assign({}, state), { newsletterPreferences: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_STORES:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { stores: action.payload.stores, searchByLocation: action.payload.location }) });
        case ActionEnums_1.CheckoutActionTypes.GET_STORES_FAIL:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { stores: [] }) });
        case ActionEnums_1.CheckoutActionTypes.SET_ACTIVE_STORE_ID:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { activeStoreId: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.SET_SHOW_ALL_STORES:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { showAllStores: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECT_STORE_VIEW:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { deliveryInfoView: action.payload }) });
        case ActionEnums_1.CheckoutActionTypes.RESET_PAYMENT_STATE:
            return Object.assign(Object.assign({}, state), { paymentInfo: exports.initialState.paymentInfo, selectedPaymentOption: exports.initialState.selectedPaymentOption, klarna: exports.initialState.klarna, saferpayFieldsReady: exports.initialState.saferpayFieldsReady, isSetPaymentOptionSuccess: exports.initialState.isSetPaymentOptionSuccess, paymentOptionError: exports.initialState.paymentOptionError });
        case ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECT_STORE_ONLY_IN_STOCK:
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { onlyInStock: !state.collectStore.onlyInStock }) });
        case ActionEnums_1.CheckoutActionTypes.SET_FAST_CHECKOUT_OVERVIEW:
            return Object.assign(Object.assign({}, state), { fastCheckoutOverview: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_CUSTOMER_DETAILS:
            return Object.assign(Object.assign({}, state), { customerDetails: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_HAS_REGISTRATION:
            return Object.assign(Object.assign({}, state), { hasRegistration: action.payload });
        case ActionEnums_1.CheckoutActionTypes.SET_IS_PAYMENT_OPTION_UPDATING:
            return Object.assign(Object.assign({}, state), { isPaymentOptionUpdating: action.payload });
        default:
            return state;
    }
};
exports.checkoutReducer = checkoutReducer;
