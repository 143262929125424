"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentOverviewActions = void 0;
const ContentActionTypes_1 = require("../types/ContentActionTypes");
exports.contentOverviewActions = {
    findContentAction: (payload) => ({
        type: ContentActionTypes_1.ContentOverviewActionTypes.FIND_CONTENT,
        payload,
    }),
    showPlaceholderTiles: () => ({
        type: ContentActionTypes_1.ContentOverviewActionTypes.SHOW_PLACEHOLDER_TILES,
    }),
    updaterContentAction: (payload) => ({
        type: ContentActionTypes_1.ContentOverviewActionTypes.UPDATE_CONTENT,
        payload,
    }),
    updaterFiltersAction: (payload) => ({
        type: ContentActionTypes_1.ContentOverviewActionTypes.UPDATE_FILTERS,
        payload,
    }),
};
