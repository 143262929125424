"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const PromoBasketPopupActions_creators_1 = require("../redux/promo-basket-popup/actions/PromoBasketPopupActions.creators");
const PromoBasketPopupSelector_1 = require("../redux/promo-basket-popup/selectors/PromoBasketPopupSelector");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const PromoBasketPopupContentContainer_1 = require("./PromoBasketPopupContentContainer");
const PromoBasketPopupCTAContainer_1 = require("./PromoBasketPopupCTAContainer");
const PromoBasketPopupContainer = ({ dispatchInitializeAction, closePopup, popupState, productReady, }) => {
    const close = () => setTimeout(() => closePopup(), cotopaxi_1.POPOVER_ANIMATION_DURATION);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close });
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    (0, react_1.useEffect)(() => {
        dispatchInitializeAction();
    }, []);
    (0, react_1.useEffect)(() => {
        if (!popupState.collapsed) {
            handlePopoverOpen(null, null, true);
        }
    }, [popupState.collapsed]);
    if (!productReady || popupState.collapsed)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { disableClickOutside: true, anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, title: popupState.message, footerElement: isMobile && (0, jsx_runtime_1.jsx)(PromoBasketPopupCTAContainer_1.default, { closePopup: handlePopoverClose }), children: (0, jsx_runtime_1.jsx)(PromoBasketPopupContentContainer_1.default, { closePopup: handlePopoverClose }) }));
};
const makeMapStateToProps = () => {
    const getPromoBasketPopupState = (0, PromoBasketPopupSelector_1.makeGetPromoBasketPopupState)();
    const getProductDefaults = (0, ProductSelector_1.makeGetProductDefaults)();
    const mapStateToProps = state => ({
        popupState: getPromoBasketPopupState(state),
        productReady: !!getProductDefaults(state).productCode,
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    closePopup: PromoBasketPopupActions_creators_1.promoBasketPopupActions.createPopupCloseAction,
    dispatchInitializeAction: PromoBasketPopupActions_creators_1.promoBasketPopupActions.createInitializeAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(PromoBasketPopupContainer);
