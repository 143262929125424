"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const PromoBasketPopupCTAContainer_1 = require("./PromoBasketPopupCTAContainer");
const PromoBasketPopupSizeGuide_1 = require("../components/promo-basket-popup/PromoBasketPopupSizeGuide");
const PromoBasketPopupInformation_1 = require("../components/promo-basket-popup/PromoBasketPopupInformation");
const PromoBasketPopupSizeSelect_1 = require("../components/promo-basket-popup/PromoBasketPopupSizeSelect");
const PromoBasketPopupPreview_1 = require("../components/promo-basket-popup/PromoBasketPopupPreview");
const PromoBasketPopupColorSwatches_1 = require("../components/promo-basket-popup/PromoBasketPopupColorSwatches");
const PromoBasketPopupPrice_1 = require("../components/promo-basket-popup/PromoBasketPopupPrice");
const PromoBasketPopupDeliveryPromise_1 = require("../components/promo-basket-popup/PromoBasketPopupDeliveryPromise");
const PromoBasketPopupContentContainer = ({ image, productCode, colors, selectedColorId, priceInformation, sizes, selectedSku, selectSize, sizeRecommendationData, basketValidationState, genderFeatureId, productDescription, deliveryPromise, sizeGuideInformation, setProductColor, setBasketValidationState, getDeliveryPromise, closePopup, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    (0, react_1.useEffect)(() => {
        getDeliveryPromise();
    }, []);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: isMobile ? cotopaxi_1.ScrollableListVariant.SHADOW : undefined, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } }, tablet: {
                spacing: {
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingBottom: 4,
                },
            }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.PDPLayout, { variant: cotopaxi_1.PDPLayoutVariant.PromoBasketPopup, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.PDPLayout.Item, { area: cotopaxi_1.PDPLayoutItemArea.HEADER, children: (0, jsx_runtime_1.jsx)(PromoBasketPopupInformation_1.PromoBasketPopupInformation, { brand: productDescription.brand, description: productDescription.description, title: productDescription.title, aemConfiguration: aemConfiguration, genderFeatureId: genderFeatureId }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.PDPLayout.Item, { area: cotopaxi_1.PDPLayoutItemArea.PREVIEW, children: (0, jsx_runtime_1.jsx)(PromoBasketPopupPreview_1.PromoBasketPopupPreview, { image: image, productCode: productCode, aemConfiguration: aemConfiguration }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.PDPLayout.Item, { area: cotopaxi_1.PDPLayoutItemArea.INTERACTION_BLOCK, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, tablet: {
                                spacing: { marginRight: 9, paddingBottom: 0 },
                            }, children: [(0, jsx_runtime_1.jsx)(PromoBasketPopupColorSwatches_1.PromoBasketPopupColorSwatches, { colors: colors, productCode: productCode, selectedColorId: selectedColorId, aemConfiguration: aemConfiguration, setProductColor: setProductColor }), (0, jsx_runtime_1.jsx)(PromoBasketPopupSizeSelect_1.PromoBasketPopupSizeSelect, { sizes: sizes, selectedSku: selectedSku, selectSize: selectSize, sizeRecommendation: sizeRecommendationData.colorBased, basketValidationState: basketValidationState, aemConfiguration: aemConfiguration }), (0, jsx_runtime_1.jsx)(PromoBasketPopupSizeGuide_1.PromoBasketPopupSizeGuide, { aemConfiguration: aemConfiguration, sizeGuideInformation: sizeGuideInformation, setBasketValidationState: setBasketValidationState, basketValidationState: basketValidationState }), (0, jsx_runtime_1.jsx)(PromoBasketPopupPrice_1.PromoBasketPopupPrice, { aemConfiguration: aemConfiguration, priceInformation: priceInformation }), (0, jsx_runtime_1.jsx)(PromoBasketPopupDeliveryPromise_1.PromoBasketPopupDeliveryPromise, { aemConfiguration: aemConfiguration, deliveryPromise: deliveryPromise }), !isMobile && (0, jsx_runtime_1.jsx)(PromoBasketPopupCTAContainer_1.default, { closePopup: closePopup })] }) })] }) }) }));
};
const makeMapStateToProps = () => {
    const getFirstImageForSelectedProductColor = (0, ProductSelector_1.makeGetFirstImageForSelectedProductColor)();
    const getProductDefaults = (0, ProductSelector_1.makeGetProductDefaults)();
    const getSelectedColorId = (0, ProductSelector_1.makeGetSelectedColorId)();
    const getColors = (0, ProductSelector_1.makeGetAvailableColors)();
    const getPriceInformation = (0, ProductSelector_1.makeGetPriceInformation)();
    const getBasketValidationState = (0, BasketSelector_1.makeGetBasketValidationState)();
    const getProductSizes = (0, ProductSelector_1.makeGetProductSizes)();
    const getSelectedSku = (0, ProductSelector_1.makeGetSelectedSku)();
    const getProductSizeRecommendation = (0, ProductSelector_1.makeGetSizeRecommendation)();
    const getProductDescription = (0, ProductSelector_1.makeGetProductDescription)();
    const getSizeGuideInformation = (0, ProductSelector_1.makeGetSizeGuideInformation)();
    const getDeliveryPromises = (0, ProductSelector_1.makeGetDeliveryPromises)();
    const mapStateToProps = (state) => ({
        image: getFirstImageForSelectedProductColor(state),
        productCode: getProductDefaults(state).productCode,
        colors: getColors(state),
        selectedColorId: getSelectedColorId(state),
        priceInformation: getPriceInformation(state),
        basketValidationState: getBasketValidationState(state),
        sizes: getProductSizes(state),
        selectedSku: getSelectedSku(state),
        sizeRecommendationData: getProductSizeRecommendation(state),
        genderFeatureId: getProductDefaults(state).genderFeatureId,
        productDescription: getProductDescription(state),
        sizeGuideInformation: getSizeGuideInformation(state),
        deliveryPromise: getDeliveryPromises(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    setProductColor: ProductActions_1.productActions.createSetColorAction,
    selectSize: ProductActions_1.productActions.createSelectSizeAction,
    getDeliveryPromise: ProductActions_1.productActions.createGetDeliveryPromise,
    setBasketValidationState: BasketActions_creators_1.basketActions.createErrorAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(PromoBasketPopupContentContainer);
