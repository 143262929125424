"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductDetailAPI_1 = require("../api/ProductDetailAPI");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const StockAvailabilityPopup_1 = require("../components/stock-availability/StockAvailabilityPopup");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const common_utils_1 = require("@as-react/common-utils");
const StockAvailabilityContainer = ({ productId, productCode, productBrand, productTitle, aemConfiguration, colors, }) => {
    const [currentStoreData, setCurrentStoreData] = (0, react_1.useState)();
    const [productSizes, setProductSizes] = (0, react_1.useState)();
    const [allStoresData, setAllStoresData] = (0, react_1.useState)([]);
    const [selectedSku, setSelectedSku] = (0, react_1.useState)();
    const oneSizeProductCode = (0, ConfigurationUtil_1.getOneSizeProductCode)(aemConfiguration);
    const productImageCdnDomains = (0, ConfigurationUtil_1.getProductImageCdnDomains)(aemConfiguration);
    const { apiPrefix: servicesApi, defaultRequestParameters: { shopId, market, mainWebshop, language, anaLang }, } = (0, ConfigurationUtil_1.getDpeApiConfig)(aemConfiguration);
    const getProductSizes = (colorVariations) => {
        const sizes = new Map();
        colorVariations.forEach((productColor) => {
            if (productColor.sizes) {
                productColor.sizes.forEach((size) => {
                    if (size.active && size.code) {
                        sizes.set(size.code, size);
                    }
                });
            }
        });
        return Array.from(sizes.values()).sort((size, nextSize) => size.order - nextSize.order);
    };
    const parseStoreData = (storeStockData, productColors) => {
        var _a;
        const availability = [];
        (_a = storeStockData.colours) === null || _a === void 0 ? void 0 : _a.forEach((color) => { var _a; return (_a = color.sizes) === null || _a === void 0 ? void 0 : _a.forEach((size) => availability.push(size)); });
        return productColors.map((productColor) => {
            const color = {
                colorId: productColor.colorId,
                colorCode: productColor.colorCode,
                description: productColor.translations.description,
                swatch: productColor.swatch,
            };
            if (productColor.sizes) {
                const sizes = {};
                productColor.sizes.forEach((size) => {
                    if (size.active) {
                        const availabilityBySku = availability.filter((avail) => String(avail.idSku) === String(size.sku))[0];
                        if (availabilityBySku && availabilityBySku.idSize) {
                            sizes[size.code] = availabilityBySku;
                        }
                    }
                });
                color.sizes = sizes;
            }
            return color;
        });
    };
    const fetchProductAvailabilityForCurrentStore = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const storeNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
        const storeAvailabilityData = yield ProductDetailAPI_1.default.fetchProductAvailabilityForCurrentStore(servicesApi, { shopId, market, mainWebshop, language, anaLang }, productId, { storeNumber });
        if (storeAvailabilityData) {
            setCurrentStoreData(parseStoreData(storeAvailabilityData, colors));
        }
    });
    const fetchProductAvailabilityForAllStores = (sku) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!sku || sku === selectedSku)
            return;
        setSelectedSku(sku);
        const storesAvailabilityData = yield ProductDetailAPI_1.default.fetchProductAvailabilityForAllStores(sku, mainWebshop, shopId, market, anaLang, language, servicesApi);
        setAllStoresData(storesAvailabilityData);
    });
    (0, react_1.useEffect)(() => {
        fetchProductAvailabilityForCurrentStore();
    }, []);
    (0, react_1.useEffect)(() => {
        setProductSizes(getProductSizes(colors));
    }, [colors]);
    if (!productSizes) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(StockAvailabilityPopup_1.StockAvailabilityPopup, { productCode: productCode, productSizes: productSizes, selectedSku: selectedSku, currentStoreData: currentStoreData, allStoresData: allStoresData, isOneSizeProduct: (0, common_utils_1.isOneSizeProduct)(productSizes, oneSizeProductCode), productImageCdnDomains: productImageCdnDomains, productBrand: productBrand, productTitle: productTitle, fetchProductAvailabilityForAllStores: fetchProductAvailabilityForAllStores }));
};
const makeMapStateToProps = () => {
    const getColors = (0, ProductSelector_1.makeGetAvailableColors)();
    const mapStateToProps = (state) => ({
        colors: getColors(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(StockAvailabilityContainer);
