"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailRecognitionForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const EmailValidationWrapper_1 = require("../../../../email/EmailValidationWrapper");
const EmailRecognitionForm = ({ onSubmit, initialEmail = '', loading, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.M, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step1.title`) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step1.message`) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { initialValues: { email: initialEmail }, onSubmit: onSubmit, name: "login-flows-email-check-form", children: ({ errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { emailFieldKey: "email", errorMessages: { invalidEmail: t(common_types_1.ErrorMessage.INVALID_EMAIL) }, children: formFieldProps => ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, Object.assign({ type: cotopaxi_1.InputType.EMAIL, id: "email", name: "email", placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`), required: true, state: errors.email && touched.email ? cotopaxi_1.InputState.ERROR : undefined, adornmentEnd: errors.email &&
                                    touched.email && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input }, formFieldProps))) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, desktop: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, state: loading ? cotopaxi_1.ButtonState.LOADING : cotopaxi_1.ButtonState.INTERACTIVE, type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step1.submit`) }) })] })) })] }));
};
exports.EmailRecognitionForm = EmailRecognitionForm;
