"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
class ProductRecentlyViewedAPI {
    constructor() {
        this.getProductsFromLocalStorage = () => LocalStorageUtil_1.default.getJsonItem(this.localStorageKey);
        this.tryToAddProduct = (product) => {
            const products = this.getProductsFromLocalStorage();
            if (!products) {
                LocalStorageUtil_1.default.setItem(this.localStorageKey, [product]);
            }
            if (this.isAlreadyInRecentlyViewedProducts(product)) {
                this.moveUpInRecentlyViewedProducts(product);
                LocalStorageUtil_1.default.setItem(this.localStorageKey, this.getProductsFromLocalStorage());
            }
            else {
                if (products.length === this.MAX_PRODUCT_SIZE) {
                    products.pop();
                }
                products.unshift(product);
                LocalStorageUtil_1.default.setItem(this.localStorageKey, products);
            }
        };
        this.moveUpInRecentlyViewedProducts = (product) => {
            const products = this.getProductsFromLocalStorage();
            const index = this.getIndexOfProduct(product);
            products.splice(index, 1);
            products.unshift(product);
            LocalStorageUtil_1.default.setItem(this.localStorageKey, products);
        };
        this.isAlreadyInRecentlyViewedProducts = (product) => this.getIndexOfProduct(product) !== -1;
        this.getIndexOfProduct = (product) => this.getProductsFromLocalStorage().findIndex((p) => product.productCode === p.productCode && product.colorId === p.colorId);
        this.localStorageKey = 'recentlyViewedProducts';
        this.MAX_PRODUCT_SIZE = 6;
    }
}
exports.default = new ProductRecentlyViewedAPI();
