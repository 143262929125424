"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMultiSelectFilter = exports.getListerFilters = exports.getActiveFilters = exports.updatePageAndFetchProductsSaga = void 0;
const effects_1 = require("redux-saga/effects");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const axios_1 = require("axios");
const FilterActions_creators_1 = require("../actions/FilterActions.creators");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const ListerActions_creators_1 = require("../../lister/actions/ListerActions.creators");
const logger_1 = require("@as-react/logger");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* updatePageAndFetchProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.UrlActionTypes.UPDATE_FILTERS, updateMultiSelectFilter);
}
exports.updatePageAndFetchProductsSaga = updatePageAndFetchProductsSaga;
const getActiveFilters = (state) => state.filters.activeFilters;
exports.getActiveFilters = getActiveFilters;
const getListerFilters = (state) => state.lister.filters;
exports.getListerFilters = getListerFilters;
const checkIfFilterOnExists = (currentActiveFilters, filterOn) => !(0, common_utils_1.isNullOrUndefined)(currentActiveFilters) && !(0, common_utils_1.isNullOrUndefined)(currentActiveFilters[filterOn]);
const shouldRemoveLinkFilter = (currentActiveFilters, setFilterObject) => currentActiveFilters[setFilterObject.filterOn].values[0].filterKey === setFilterObject.filteredItems[0].filterKey;
function* updateMultiSelectFilter(action) {
    try {
        yield (0, effects_1.put)(ListerActions_creators_1.listerActions.showPlaceholderTiles());
        let filteredItems = action.payload.filteredItems;
        let existingFilterKeys = [];
        if (action.payload.filterType === common_types_1.RANGE_FILTER) {
            const listerFilters = yield (0, effects_1.select)(exports.getListerFilters);
            const { rangeMin, rangeMax, boundaryMax, boundaryMin } = listerFilters.find(filter => filter.type === action.payload.filterType && filter.filterOn === action.payload.filterOn);
            if (Math.ceil(rangeMax) === boundaryMax && Math.floor(rangeMin) === boundaryMin) {
                yield (0, effects_1.put)(FilterActions_creators_1.filterActions.removeFilterObject({
                    filterOn: action.payload.filterOn,
                }));
                return;
            }
        }
        if ((0, common_utils_1.isMultiSelectFilter)(action.payload) || (0, common_utils_1.isBucketFilter)(action.payload)) {
            const currentActiveFilters = yield (0, effects_1.select)(exports.getActiveFilters);
            if (action.payload.multiUpdate) {
                filteredItems = filteredItems.filter((item) => item.isActive);
            }
            else if (checkIfFilterOnExists(currentActiveFilters, action.payload.filterOn)) {
                const currentFilterKeys = currentActiveFilters[action.payload.filterOn].values;
                existingFilterKeys = ((0, GeneralUtil_1.compareArraysOfObjectsAndReturnDuplicates)(currentFilterKeys, filteredItems, 'filterKey'));
                filteredItems = [
                    ...((0, GeneralUtil_1.compareArraysOfObjectsAndReturnUniques)(existingFilterKeys, currentFilterKeys, 'filterKey')),
                    ...((0, GeneralUtil_1.compareArraysOfObjectsAndReturnUniques)(existingFilterKeys, filteredItems, 'filterKey')),
                ];
            }
        }
        else if ((0, common_utils_1.isRangeFilter)(action.payload)) {
            if (action.payload.isReset) {
                filteredItems = [];
            }
        }
        else if ((0, common_utils_1.isLinkFilter)(action.payload)) {
            const activeFilters = yield (0, effects_1.select)(exports.getActiveFilters);
            if (checkIfFilterOnExists(activeFilters, action.payload.filterOn) &&
                shouldRemoveLinkFilter(activeFilters, action.payload)) {
                filteredItems = [];
            }
        }
        if (filteredItems.length > 0) {
            yield (0, effects_1.put)(FilterActions_creators_1.filterActions.setFilterObject({
                filteredItems,
                filterOn: action.payload.filterOn,
                filterOnName: action.payload.filterOnName,
                filterType: action.payload.filterType,
                filterDisplayHint: action.payload.filterDisplayHint,
                filterPrefix: action.payload.filterPrefix,
                filterSuffix: action.payload.filterSuffix,
                filterContentType: action.payload.filterContentType,
                queryParamKey: action.payload.queryParamKey,
            }));
        }
        else {
            yield (0, effects_1.put)(FilterActions_creators_1.filterActions.removeFilterObject({
                filterOn: action.payload.filterOn,
                queryParamKey: action.payload.queryParamKey,
            }));
        }
    }
    catch (error) {
        if (!(0, axios_1.isAxiosError)(error)) {
            logger_1.Logger.logError(error);
        }
    }
}
exports.updateMultiSelectFilter = updateMultiSelectFilter;
function* filterSaga() {
    yield (0, effects_1.all)([updatePageAndFetchProductsSaga()]);
}
exports.default = filterSaga;
