"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productGroupFredhopperReducer = exports.loadProductGroupFredhopperReducer = exports.initialProductGroupFredhopperState = void 0;
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ProductGroupUtil_1 = require("../../../util/ProductGroupUtil");
exports.initialProductGroupFredhopperState = {};
const loadProductGroupFredhopperReducer = (initialData = null) => {
    if (initialData === null || initialData === void 0 ? void 0 : initialData.productGroupFredhopperId) {
        exports.initialProductGroupFredhopperState[initialData.productGroupFredhopperId] = initialData.products;
    }
    return (state = exports.initialProductGroupFredhopperState, action) => {
        (0, ProductGroupUtil_1.normalizeProductGroupState)(state, exports.initialProductGroupFredhopperState);
        switch (action.type) {
            case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
                return (0, ProductGroupUtil_1.updateProductGroupProducts)(state, WishListReducer_1.updateWishListProducts, action, true);
            case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
                return (0, ProductGroupUtil_1.updateProductGroupProducts)(state, WishListReducer_1.updateWishListProducts, action, false);
            case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
                return (0, ProductGroupUtil_1.updateProductGroupProducts)(state, CompareReducer_1.toggleComparedStateForItems, action.payload);
            case ActionEnums_1.ProductGroupFredhopperTypes.SET_DELIVERY_PROMISE:
                return Object.assign(Object.assign({}, state), { [action.payload.productGroupId]: (0, ProductUtil_1.setDeliveryPromiseToProducts)(state[action.payload.productGroupId], action.payload) });
            case ActionEnums_1.ProductGroupFredhopperTypes.SET_FREDHOPPER_DATA:
                return Object.assign(Object.assign({}, state), action.payload);
            default:
                return state;
        }
    };
};
exports.loadProductGroupFredhopperReducer = loadProductGroupFredhopperReducer;
exports.productGroupFredhopperReducer = (0, exports.loadProductGroupFredhopperReducer)();
