"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupInformation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ProductNames_1 = require("../product-detail/product-names/ProductNames");
const PromoBasketPopupInformation = ({ brand, description, genderFeatureId, title, aemConfiguration, }) => {
    const { product_gender_icon } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, tablet: { spacing: { marginBottom: 1 } }, children: isMobile ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: brand.description }), ` ${title}`] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 8, children: (0, jsx_runtime_1.jsx)(ProductNames_1.ProductNames, { brand: brand.description, genderFeatureId: genderFeatureId, showGenderIcon: product_gender_icon, headerPriority: 1, title: title, headingSize: cotopaxi_1.HeadingSize.S }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginLeft: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.RIGHT, gutter: cotopaxi_1.GroupGutter.NONE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.BrandLogo, { relativePath: brand.relativePath, logoSrc: `${brand.logo}?timestamp=${brand.logoTimestamp}`, description: brand.description }) }) }) })] }), description && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: description }) }) }) }))] })) }));
};
exports.PromoBasketPopupInformation = PromoBasketPopupInformation;
