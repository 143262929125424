"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupBancontact = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const SaferpayUtil_1 = require("../../../../util/SaferpayUtil");
const EventUtil_1 = require("../../../../util/EventUtil");
const EventEnum_1 = require("../../../../enums/EventEnum");
const BuyForm_1 = require("../../forms/BuyForm");
const BancontactForm_1 = require("./BancontactForm");
const asNumeral_1 = require("../../../../vendors/asNumeral");
const MAX_BASKET_TOTAL_FOR_APP = 500;
const BANCONTACT_PAYMENT_METHODS = ['bancontact'];
let timers = [];
const PaymentGroupBancontact = ({ saferpayFieldsReady, totalPrice, isSetPaymentOptionSuccess, onPreSubmit, onSubmit, onInitAlternative, onPollingAlternative, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const { fields } = (0, ConfigurationUtil_1.getSaferpayConfig)(aemConfiguration);
    const [isTimerRun, setIsTimerRun] = (0, react_1.useState)(false);
    const [hasAppOptions, setHasAppOptions] = (0, react_1.useState)(false);
    const [appOptionsState, setAppOptionsState] = (0, react_1.useState)(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
    const [inited, setInited] = (0, react_1.useState)(false);
    const [qrCodeImage, setQrCodeImage] = (0, react_1.useState)('');
    const [intentUrl, setIntentUrl] = (0, react_1.useState)('');
    const [validation, setValidation] = (0, react_1.useState)();
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [showError, setShowError] = (0, react_1.useState)(false);
    const endTimers = () => {
        timers.forEach(timer => timer.end());
        timers = [];
    };
    const startAppPaymentTimer = (expiration) => {
        const expirationDate = new Date(expiration).getTime();
        const paymentTimer = new common_utils_1.Poller(() => {
            const currentTime = Date.now();
            if (currentTime > expirationDate) {
                return true;
            }
            return null;
        }, GeneralConstants_1.DELAY.DELAY_1000);
        paymentTimer.run().then(() => {
            if (paymentTimer && paymentTimer.active) {
                setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
            }
        });
        timers.push(paymentTimer);
        setIsTimerRun(true);
    };
    const startPollingForAppPayment = (requestId) => {
        const paymentPoller = new common_utils_1.Poller(() => tslib_1.__awaiter(void 0, void 0, void 0, function* () { return onPollingAlternative(requestId); }), GeneralConstants_1.DELAY.DELAY_3000);
        paymentPoller.run().then((redirectUrl) => {
            if (redirectUrl) {
                location.href = redirectUrl;
            }
        });
        timers.push(paymentPoller);
        setIsTimerRun(true);
    };
    const checkSaferpayStatusAndInit = () => {
        try {
            window.SaferpayFields.init({
                accessToken: fields.accessToken,
                url: fields.apiUrl,
                paymentMethods: BANCONTACT_PAYMENT_METHODS,
                placeholders: {
                    cardnumber: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CARDNUMBER}.placeholder`),
                    holdername: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.HOLDERNAME}.placeholder`),
                    expiration: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.EXPIRATION}.placeholder`),
                    cvc: '',
                },
                style: SaferpayUtil_1.saferpayFieldStyles,
                onError: () => setShowError(true),
                onValidated: (e) => setValidation(prev => (Object.assign(Object.assign({}, prev), { [e.fieldType]: e }))),
            });
        }
        catch (e) {
            setShowError(true);
        }
    };
    const initBancontactPayment = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        endTimers();
        setQrCodeImage('');
        setIntentUrl('');
        setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
        try {
            const result = yield onInitAlternative();
            endTimers();
            startAppPaymentTimer(result.expiration);
            startPollingForAppPayment(result.requestId);
            setQrCodeImage(result.qrCodeImage);
            setIntentUrl(result.intentUrl);
            setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.ENABLED);
        }
        catch (_a) {
            setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
        }
    });
    const submitButtonState = () => !isSetPaymentOptionSuccess ? cotopaxi_1.ButtonState.DISABLED : isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined;
    const handleSubmit = () => {
        setIsSubmitting(true);
        setShowError(false);
        onPreSubmit && onPreSubmit();
        window.SaferpayFields.submit({
            onSuccess: ({ token }) => {
                try {
                    onSubmit(token);
                }
                catch (err) {
                    setIsSubmitting(false);
                    setShowError(true);
                }
            },
            onError: ({ message }) => {
                setIsSubmitting(false);
            },
        });
    };
    const onVoucherChange = () => {
        isSetPaymentOptionSuccess && inited && hasAppOptions && initBancontactPayment();
    };
    (0, react_1.useEffect)(() => {
        if (isSetPaymentOptionSuccess && !inited && hasAppOptions) {
            setInited(true);
            initBancontactPayment();
        }
        else {
            endTimers();
        }
        EventUtil_1.default.listen(EventEnum_1.EventEnum.VOUCHER_CHANGED, onVoucherChange);
        return () => {
            EventUtil_1.default.remove(EventEnum_1.EventEnum.VOUCHER_CHANGED, onVoucherChange);
        };
    }, [isSetPaymentOptionSuccess, inited, hasAppOptions]);
    (0, react_1.useEffect)(() => {
        if (saferpayFieldsReady && isSetPaymentOptionSuccess) {
            checkSaferpayStatusAndInit();
        }
    }, [saferpayFieldsReady, isSetPaymentOptionSuccess]);
    (0, react_1.useEffect)(() => {
        return () => {
            isTimerRun && endTimers();
        };
    }, [isTimerRun]);
    (0, react_1.useEffect)(() => {
        setHasAppOptions(totalPrice <= MAX_BASKET_TOTAL_FOR_APP);
    }, [totalPrice]);
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: handleSubmit, buttonState: submitButtonState(), checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: showError ? t('buy.payment.form.error') : null, children: (0, jsx_runtime_1.jsx)(BancontactForm_1.default, { appOptionsState: appOptionsState, currency: currency, hasAppOptions: hasAppOptions, intentUrl: intentUrl, paymentMethods: BANCONTACT_PAYMENT_METHODS, priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPrice), qrCodeImage: qrCodeImage, validation: validation }) }));
};
exports.PaymentGroupBancontact = PaymentGroupBancontact;
