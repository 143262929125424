"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPARE_TRIGGERED_COMPONENT = void 0;
var COMPARE_TRIGGERED_COMPONENT;
(function (COMPARE_TRIGGERED_COMPONENT) {
    COMPARE_TRIGGERED_COMPONENT["productDetail"] = "productDetail";
    COMPARE_TRIGGERED_COMPONENT["productLister"] = "productLister";
    COMPARE_TRIGGERED_COMPONENT["partialsSearchLister"] = "partialsSearchLister";
    COMPARE_TRIGGERED_COMPONENT["productCompareBox"] = "productCompareBox";
    COMPARE_TRIGGERED_COMPONENT["comparePage"] = "comparePage";
})(COMPARE_TRIGGERED_COMPONENT || (exports.COMPARE_TRIGGERED_COMPONENT = COMPARE_TRIGGERED_COMPONENT = {}));
