"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupTillContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const PaymentGroupTillContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const dispatch = (0, react_redux_1.useDispatch)();
    const onSubmit = () => {
        const storeNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
        dispatch(CheckoutActions_creators_1.default.createShoplandingPaymentAction(storeNumber));
    };
    return (0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: onSubmit, checkoutStep: common_types_1.RoutePathname.PAYMENT, buttonText: t('buy.payment.confirm') });
};
exports.PaymentGroupTillContainer = PaymentGroupTillContainer;
