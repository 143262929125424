"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
class CustomerUtil {
    static isUpdateNamesAndTitleForCustomerDetails(delivery) {
        return !this.isLoggedInUser(delivery) || !this.isDeliveredToDeliveryAddress(delivery.chosenDeliveryOption);
    }
    static isDeliveredToDeliveryAddress(deliveryName) {
        return [common_types_1.DeliveryOptionsEnum.HOME_DELIVERY, common_types_1.DeliveryOptionsEnum.NAMED_DAY, common_types_1.DeliveryOptionsEnum.NEXT_DAY].includes(deliveryName);
    }
    static isInvoiceEnabled(hideInvoice, delivery) {
        return !hideInvoice && this.isLoggedInUser(delivery);
    }
    static isLoggedInUser(delivery) {
        if (!delivery.customerDetails) {
            return false;
        }
        return delivery.customerDetails.emailOnly === false;
    }
    static getCustomerNames(delivery, optionName) {
        if (optionName &&
            this.isDeliveredToDeliveryAddress(optionName) &&
            delivery.deliveryAddress.firstName &&
            delivery.deliveryAddress.lastName) {
            return this.createCustomerNames(delivery.deliveryAddress);
        }
        else {
            return this.createCustomerNames(delivery.customerDetails);
        }
    }
    static createCustomerNames(customer) {
        return {
            idTitle: customer.idTitle,
            firstName: customer.firstName,
            middleName: customer.middleName,
            lastName: customer.lastName,
        };
    }
    static sortSocialMediaConnections(socialMediaConnections, enabledSocialPlatforms) {
        const platforms = enabledSocialPlatforms.map(platform => {
            const connections = socialMediaConnections.filter(connection => platform === connection.type);
            return {
                title: platform,
                hasConnections: !!connections,
                connections: connections,
                errors: {
                    alreadyConnected: false,
                    connectedToAnother: false,
                },
            };
        });
        return platforms;
    }
    static convertPhoneNumberPrefix(phoneNumber, from, to) {
        if (!phoneNumber) {
            return phoneNumber;
        }
        return (phoneNumber.startsWith(from) ? phoneNumber.replace(from, to) : phoneNumber);
    }
}
exports.default = CustomerUtil;
