"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPlacementCopyBox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const client_side_renderer_1 = require("@as-react/client-side-renderer");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const index_1 = require("../../../index");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const ProductPlacementCopyAPI_1 = require("../../../api/ProductPlacementCopyAPI");
const EventUtil_1 = require("../../../util/EventUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const logger_1 = require("@as-react/logger");
const PPCUtil_1 = require("../../../util/PPCUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const YOUTUBE_SELECTOR = '.youtube-video';
const ProductPlacementCopyBox = ({ ppc }) => {
    const config = (0, common_components_1.useAemConfigurationContextWrapper)();
    const market = (0, ConfigurationUtil_1.getMarket)(config);
    const [content, setContent] = (0, react_1.useState)(null);
    const renderedContainerElement = (0, react_1.useRef)(null);
    const { i18n } = (0, react_i18next_1.useTranslation)();
    const isExternal = () => ppc.text && ppc.text.indexOf('/') === 0;
    const isPPCHasVideo = () => { var _a; return (_a = renderedContainerElement === null || renderedContainerElement === void 0 ? void 0 : renderedContainerElement.current) === null || _a === void 0 ? void 0 : _a.querySelector(YOUTUBE_SELECTOR); };
    const PPCPositionTypes = (0, PPCUtil_1.getPPCPositionTypesByMarket)(market);
    const getContent = () => {
        if (isExternal()) {
            ProductPlacementCopyAPI_1.default.fetchPPC(ppc.text, config.isInAuthorMode ? `${config.rootWithLanguageSuffix}/` : config.root)
                .then(data => setContent(data))
                .catch(logger_1.Logger.logError);
        }
        else {
            setContent(ppc.text);
        }
    };
    const hydratePPCContentIfNeeded = () => {
        (0, client_side_renderer_1.renderReactComponentsInsideElementsIfNeeded)(index_1.availableComponents, renderedContainerElement.current, index_1.renderingTools, i18n)
            .then(() => isPPCHasVideo() && EventUtil_1.default.dispatch(EventEnum_1.EventEnum.NEW_VIDEO_IFRAME_ADDED))
            .catch(logger_1.Logger.logError);
    };
    (0, react_1.useEffect)(() => {
        getContent();
    }, [ppc]);
    (0, react_1.useEffect)(() => {
        if (content && renderedContainerElement && isExternal()) {
            hydratePPCContentIfNeeded();
        }
    }, [content, renderedContainerElement]);
    if ((0, common_utils_1.isNullOrUndefined)(content) || content.length === 0)
        return null;
    const getSpacings = () => {
        if (isExternal()) {
            if (ppc.placementPositionId === common_types_1.PPCPositionTypesUK.DIGITAL_PARTNER_MESSAGING) {
                return {
                    mobile: {
                        spacing: {
                            marginBottom: 2,
                        },
                    },
                };
            }
            return {};
        }
        return {
            mobile: {
                spacing: {
                    marginTop: ppc.placementPositionId === PPCPositionTypes.ABOVE_BUY_BUTTON
                        ? 1
                        : ppc.placementPositionId === PPCPositionTypes.BELOW_BUY_BUTTON
                            ? 2
                            : 0,
                    marginBottom: ppc.placementPositionId === PPCPositionTypes.ABOVE_DESCRIPTION ? 3 : 2,
                    paddingTop: 1,
                    paddingRight: 2,
                    paddingBottom: 1,
                    paddingLeft: 2,
                },
                theme: cotopaxi_1.BoxTheme.NOTICE,
            },
            tablet: {
                spacing: {
                    paddingRight: 1,
                    paddingLeft: 1,
                },
            },
        };
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({ mobileBleed: !isExternal() }, getSpacings(), { children: (0, jsx_runtime_1.jsx)("div", { ref: renderedContainerElement, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: content }) }) })));
};
exports.ProductPlacementCopyBox = ProductPlacementCopyBox;
