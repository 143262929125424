"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productPreviewReducer = exports.initialProductPreviewState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialProductPreviewState = {
    imageTouchZoomHint: true,
};
const productPreviewReducer = (state = exports.initialProductPreviewState, action) => {
    switch (action.type) {
        case ActionEnums_1.ProductPreviewActionTypes.UPDATE_IMAGE_TOUCH_ZOOM_HINT:
            return Object.assign(Object.assign({}, state), { imageTouchZoomHint: action.payload });
        default:
            return state;
    }
};
exports.productPreviewReducer = productPreviewReducer;
