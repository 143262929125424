"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountMenuPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AccountMenuPopover = ({ items, logout, anchorEl, onClose, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const accountMenuTranslations = {
        logout: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.logout`),
    };
    const mappedItems = items ? items.map(item => ({ href: item.link, label: item.label })) : [];
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { dataQA: "account_popover", anchorEl: anchorEl, hasScrollLock: !isDesktop, onClose: onClose, title: t('account.account.popover'), rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: onClose, icon: {
                name: cotopaxi_1.IconName.CLOSE,
                color: cotopaxi_1.IconColor.ACTION,
                size: cotopaxi_1.IconSize.MAJOR,
            } }), size: cotopaxi_1.PopoverSize.MEDIUM, showTitleOnDesktop: true, children: (0, jsx_runtime_1.jsx)("div", { "data-analytics-bannerid": "header-account-flyout", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.AccountMenu, { items: mappedItems, onLogout: logout, translations: accountMenuTranslations }) }) }));
};
exports.AccountMenuPopover = AccountMenuPopover;
