"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryOptions = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const SagaContextSelectors_1 = require("../../../../redux/SagaContextSelectors");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const DeliveryPromiseMessage_1 = require("../../../../components/product-detail/delivery-promise/DeliveryPromiseMessage");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const DeliveryOption_1 = require("./DeliveryOption");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const react_redux_1 = require("react-redux");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const AnalyticsEnums_1 = require("../../../../enums/AnalyticsEnums");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const DeliveryOptions = ({ deliveryOptions }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, SagaContextSelectors_1.getRoot)(aemConfiguration);
    const servicesEndpoint = (0, SagaContextSelectors_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.buy]);
    const { getInformation } = (0, common_queries_1.productQueries)({ servicesEndpoint, defaultRequestParams });
    const { collectInStorePromiseMsg } = (0, CheckoutContext_1.useCheckoutConfig)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const [{ activeDeliveryOption }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const dispatchRedux = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        dispatchRedux(AnalyticsActions_creators_1.analyticsActions.checkoutType(isLoggedIn ? AnalyticsEnums_1.CheckoutType.LOGGED_IN : AnalyticsEnums_1.CheckoutType.GUEST));
        if (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) {
            dispatchRedux(AnalyticsActions_creators_1.analyticsActions.deliveryStep(activeDeliveryOption.name));
        }
    }, [activeDeliveryOption]);
    const codes = [];
    deliveryOptions.forEach(option => option.productCodesCausingExclusion && codes.push(...option.productCodesCausingExclusion));
    const uniqueCodes = [...new Set(codes)];
    const exclusionProducts = (0, react_query_1.useQueries)({
        queries: uniqueCodes.map(code => getInformation({ productCode: code })),
        combine: results => results.map(result => result.data),
    });
    const setExclusionTitle = (optionName) => optionName === common_types_1.DeliveryOptionsEnum.COLLECT_STORE
        ? t('buy.delivery.option.exclusion.collectstore')
        : t('buy.delivery.option.exclusion.homedelivery');
    const setExclusionText = (optionName) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [setExclusionTitle(optionName), " ", exclusionProducts === null || exclusionProducts === void 0 ? void 0 : exclusionProducts.map(product => product === null || product === void 0 ? void 0 : product.title).join(', '), ".", (0, jsx_runtime_1.jsx)("br", {}), t('buy.delivery.option.exclusion.suggestion.part1'), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Link, { href: `${root}checkout/basket-overview.html`, children: [t('buy.delivery.option.exclusion.suggestion.part2'), " ", t('buy.delivery.option.exclusion.suggestion.part3')] })] }));
    const isDisabled = (option) => option.exclusionCausedByProduct;
    const showSubtitle = (option) => !isDisabled(option) &&
        (CustomerUtil_1.default.isDeliveredToDeliveryAddress(option.name)
            ? (0, BuyFormUtil_1.isAllDaysOptions)(deliveryOptions) || !(0, BuyFormUtil_1.isNamedDayOrHomeDeliveryOptions)(deliveryOptions)
            : !isDisabled(option));
    const getSubtitle = (option) => option.name === common_types_1.DeliveryOptionsEnum.COLLECT_STORE ? (collectInStorePromiseMsg) : option.cutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: option })) : (option.deliveryMessage);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { gap: "3", children: (0, BuyFormUtil_1.filterDeliveryOptions)(deliveryOptions).map(option => ((0, jsx_runtime_1.jsx)(DeliveryOption_1.DeliveryOption, { option: option, subtitle: showSubtitle(option) ? getSubtitle(option) : undefined, exclusionText: isDisabled(option) && setExclusionText(option.name), disabled: option.exclusionCausedByProduct, checked: (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) === option.name }, option.name))) }));
};
exports.DeliveryOptions = DeliveryOptions;
