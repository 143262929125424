"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetCategoryHierarchyTree = exports.makeGetParentPage = exports.makeGetEnableJsonLd = exports.makeGetIsLoaded = exports.makeGetListerItems = exports.makeGetIsCSR = exports.makeGetFredhopperSegment = exports.makeGetInStoreFilterState = exports.makeGetFilterWizardState = exports.makeGetfredhopperGlossaryState = exports.makeGetTotalPages = exports.makeGetLoadingState = exports.makeGetSingleActiveSizeFilterKeyName = exports.makeGetFlattenedActiveFiltersState = exports.makeGetContentBlocks = exports.makeGetQuickLinks = exports.makeGetActiveFiltersState = exports.makeGetEnabledFiltersState = exports.makeGetPartialSearchResults = exports.makeGetProductGroupUniqueIds = exports.makeGetAlternativeQueryResults = exports.makeGetExecutionStatus = exports.makeGetFiltersState = exports.makeGetHideTitle = exports.makeGetSeoTextInformation = exports.makeGetItemCount = exports.makeGetListerHeadingInformation = exports.makeGetProductSearchInformationState = void 0;
const ActionEnums_1 = require("./../../../enums/ActionEnums");
const common_types_1 = require("@as-react/common-types");
const reselect_1 = require("reselect");
const FILTER_ON_BRAND = 'brand';
const getProductSearchInformation = (state) => state.lister;
const getTotalPages = (state) => state.lister.totalPages;
const makeGetProductSearchInformationState = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister);
exports.makeGetProductSearchInformationState = makeGetProductSearchInformationState;
const makeGetListerHeadingInformation = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => ({
    itemCount: lister.itemCount,
    title: lister.title,
}));
exports.makeGetListerHeadingInformation = makeGetListerHeadingInformation;
const makeGetItemCount = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.itemCount);
exports.makeGetItemCount = makeGetItemCount;
const makeGetSeoTextInformation = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.seoTextInclude);
exports.makeGetSeoTextInformation = makeGetSeoTextInformation;
const makeGetHideTitle = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.hideTitle);
exports.makeGetHideTitle = makeGetHideTitle;
const getFilters = (state) => {
    return state.lister.filters;
};
const getExecutionStatus = (state) => {
    return state.lister.executionStatus;
};
const getAlternativeQueryResults = (state) => {
    return state.lister.alternativeQueryResults;
};
const getProductGroupUniqueIds = (state) => {
    return state.lister.productGroupUniqueIds;
};
const getPartialSearchResults = (state) => {
    return state.lister.partialSearchResults;
};
const makeGetFiltersState = (isBrandPage) => (0, reselect_1.createSelector)([getFilters], (filters) => isBrandPage ? filters.filter((filter) => filter.filterOn !== FILTER_ON_BRAND) : filters);
exports.makeGetFiltersState = makeGetFiltersState;
const makeGetExecutionStatus = () => (0, reselect_1.createSelector)([getExecutionStatus], (executionStatus) => executionStatus);
exports.makeGetExecutionStatus = makeGetExecutionStatus;
const makeGetAlternativeQueryResults = () => (0, reselect_1.createSelector)([getAlternativeQueryResults], (alternativeQueryResults) => alternativeQueryResults);
exports.makeGetAlternativeQueryResults = makeGetAlternativeQueryResults;
const makeGetProductGroupUniqueIds = () => (0, reselect_1.createSelector)([getProductGroupUniqueIds], (productGroupUniqueIds) => productGroupUniqueIds);
exports.makeGetProductGroupUniqueIds = makeGetProductGroupUniqueIds;
const makeGetPartialSearchResults = () => (0, reselect_1.createSelector)([getPartialSearchResults], (partialSearchResults) => partialSearchResults);
exports.makeGetPartialSearchResults = makeGetPartialSearchResults;
const makeGetEnabledFiltersState = (isBrandPage) => (0, reselect_1.createSelector)([getFilters], (filters) => filters.filter((filter) => isBrandPage
    ? !filter.disabled && filter.filterOn !== FILTER_ON_BRAND && filter.type !== ActionEnums_1.FilterActionTypes.UNHANDLED_FILTER
    : !filter.disabled && filter.type !== ActionEnums_1.FilterActionTypes.UNHANDLED_FILTER));
exports.makeGetEnabledFiltersState = makeGetEnabledFiltersState;
const getActiveFilters = (state) => state.filters.activeFilters;
const getQuickLinks = (state) => state.lister.quickLinks;
const getContentBlocks = (state) => state.lister.contentBlocks;
const makeGetActiveFiltersState = () => (0, reselect_1.createSelector)([getActiveFilters], (activeFilters) => activeFilters);
exports.makeGetActiveFiltersState = makeGetActiveFiltersState;
const makeGetQuickLinks = () => (0, reselect_1.createSelector)([getQuickLinks], (quickLinks) => quickLinks.filter(quickLink => !!quickLink.linkPath && !!quickLink.linkName));
exports.makeGetQuickLinks = makeGetQuickLinks;
const makeGetContentBlocks = () => (0, reselect_1.createSelector)([getContentBlocks], (contentBlocks) => contentBlocks.filter(contentBlock => !!contentBlock.linkPath && !!contentBlock.visibleInRow));
exports.makeGetContentBlocks = makeGetContentBlocks;
const makeGetFlattenedActiveFiltersState = () => (0, reselect_1.createSelector)([getActiveFilters], (activeFilters) => {
    return (!!activeFilters &&
        Object.keys(activeFilters).reduce((arr, filterOn) => {
            const activeFilter = activeFilters[filterOn];
            let result = [];
            if ((activeFilter === null || activeFilter === void 0 ? void 0 : activeFilter.filterType) === ActionEnums_1.FilterActionTypes.RANGE_FILTER) {
                result = [
                    {
                        filterContentType: activeFilter.filterContentType,
                        filterOn: filterOn,
                        filterOnName: activeFilter.filterOnName,
                        filterType: activeFilter.filterType,
                        filterPrefix: activeFilter.filterPrefix,
                        filterSuffix: activeFilter.filterSuffix,
                        rangeMin: activeFilter.rangeMin,
                        rangeMax: activeFilter.rangeMax,
                    },
                ];
            }
            else {
                if (activeFilter === null || activeFilter === void 0 ? void 0 : activeFilter.values) {
                    result = activeFilter.values.map(activeFilterValue => ({
                        filterContentType: activeFilter.filterContentType,
                        filterKey: activeFilterValue.filterKey,
                        filterKeyName: activeFilterValue.filterKeyName,
                        filterOn: filterOn,
                        filterType: activeFilter.filterType,
                        filterOnName: activeFilter.filterOnName,
                    }));
                }
            }
            return arr.concat(result);
        }, [])) || [];
});
exports.makeGetFlattenedActiveFiltersState = makeGetFlattenedActiveFiltersState;
const makeGetSingleActiveSizeFilterKeyName = () => (0, reselect_1.createSelector)([getActiveFilters], (activeFilters) => {
    var _a, _b, _c;
    if (!activeFilters) {
        return null;
    }
    const activeSizeFiltersKey = Object.keys(activeFilters).filter(activeFilterKey => {
        var _a, _b, _c;
        return ((_a = activeFilters[activeFilterKey]) === null || _a === void 0 ? void 0 : _a.filterContentType) === common_types_1.FilterContentTypes.SIZE &&
            ((_c = (_b = activeFilters[activeFilterKey]) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.length);
    });
    return activeSizeFiltersKey[0] ? ((_c = (_b = (_a = activeFilters[activeSizeFiltersKey[0]]) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.filterKeyName) || null : null;
});
exports.makeGetSingleActiveSizeFilterKeyName = makeGetSingleActiveSizeFilterKeyName;
const getLoadingState = (state) => state.lister.isLoading;
const getCategoryHierarchyTree = (state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.lister) === null || _a === void 0 ? void 0 : _a.categoryHierarchyTree; };
const makeGetLoadingState = () => (0, reselect_1.createSelector)([getLoadingState], isLoading => isLoading);
exports.makeGetLoadingState = makeGetLoadingState;
const makeGetTotalPages = () => (0, reselect_1.createSelector)([getTotalPages], totalPages => totalPages);
exports.makeGetTotalPages = makeGetTotalPages;
const makeGetfredhopperGlossaryState = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => {
    var _a;
    const fredhopperGlossary = {};
    (_a = lister.fredhopperGlossary) === null || _a === void 0 ? void 0 : _a.forEach(item => {
        item.triggers.forEach(trigger => {
            trigger.indexOf('fh_') !== -1 &&
                (fredhopperGlossary[trigger] = {
                    description: item.description,
                    title: item.title,
                });
        });
    });
    return fredhopperGlossary;
});
exports.makeGetfredhopperGlossaryState = makeGetfredhopperGlossaryState;
const makeGetFilterWizardState = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.filterWizard);
exports.makeGetFilterWizardState = makeGetFilterWizardState;
const makeGetInStoreFilterState = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.inStoreFilter);
exports.makeGetInStoreFilterState = makeGetInStoreFilterState;
const makeGetFredhopperSegment = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.segment);
exports.makeGetFredhopperSegment = makeGetFredhopperSegment;
const makeGetIsCSR = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.isCSR);
exports.makeGetIsCSR = makeGetIsCSR;
const makeGetListerItems = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.items);
exports.makeGetListerItems = makeGetListerItems;
const makeGetIsLoaded = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.isLoaded);
exports.makeGetIsLoaded = makeGetIsLoaded;
const makeGetEnableJsonLd = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.enableJsonLd);
exports.makeGetEnableJsonLd = makeGetEnableJsonLd;
const makeGetParentPage = () => (0, reselect_1.createSelector)([getProductSearchInformation], (lister) => lister.parentPage);
exports.makeGetParentPage = makeGetParentPage;
const makeGetCategoryHierarchyTree = () => (0, reselect_1.createSelector)([getCategoryHierarchyTree], categoryHierarchyTree => categoryHierarchyTree);
exports.makeGetCategoryHierarchyTree = makeGetCategoryHierarchyTree;
