"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsBlock = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../../util/ProductUtil");
const SizeSelect_1 = require("./SizeSelect");
const DetailsBlock = (_a) => {
    var { isProductOutOfStock, isColourOutOfStock, showSSEMessage } = _a, sizeSelectProps = tslib_1.__rest(_a, ["isProductOutOfStock", "isColourOutOfStock", "showSSEMessage"]);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { priceActual, pricePrevious, currency, discount } = (0, cotopaxi_1.useProductTileContext)();
    const hasDiscount = Boolean(discount);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isProductOutOfStock ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: t('product.temporarily.out.of.stock') }) }) })) : isColourOutOfStock ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: t('product.out.of.stock') }) })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(SizeSelect_1.SizeSelect, Object.assign({}, sizeSelectProps)) }), priceActual && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, { currency: currency, priceActual: priceActual, pricePrevious: pricePrevious, hasDiscount: hasDiscount, productDiscount: hasDiscount
                            ? (0, ProductUtil_1.getProductDiscount)(discount.discountAmount, discount.discountPercentage, currency)
                            : undefined, size: cotopaxi_1.PriceSize.SMALL, gutter: cotopaxi_1.GroupGutter.MEDIUM, priceSpacing: cotopaxi_1.PriceSpacing.XSMAll, translations: {
                            savings: t('pdp.dynamic.savings'),
                            discount: t('pdp.bundles.discount.info'),
                        } }) })), showSSEMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: t('product.store.source.excluded') }) }))] })) }));
};
exports.DetailsBlock = DetailsBlock;
