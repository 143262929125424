"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FormValidationUtil {
    static isValidEmailPattern(email) {
        return this.emailPatternRegex.test(email);
    }
    static isValidEmail(email) {
        return /.+@.+/.test(email);
    }
    static isOfCorrectLength(value, length) {
        return value.length === length;
    }
    static containsOnlyNumbers(value) {
        return /^\d+$/.test(value);
    }
    static processFieldError(errorClass, error) {
        if (error) {
            return errorClass;
        }
        else {
            return '';
        }
    }
    static isValidExploreMoreCardFormat(exploreMoreCard, checkoutLoyaltyCardPrefix) {
        const defaultCustomerNumber = 'cus75757575';
        const loyaltyCardPrefix = checkoutLoyaltyCardPrefix ? checkoutLoyaltyCardPrefix.toLowerCase() : undefined;
        const loyaltyCardPrefixLength = loyaltyCardPrefix ? loyaltyCardPrefix.length : 3;
        const validationResult = {
            isValidFormat: false,
            isDefaultCardNumber: false,
        };
        if (exploreMoreCard) {
            if (exploreMoreCard.length > 6) {
                const exploreMoreCardPrefix = exploreMoreCard.toLowerCase().substring(0, loyaltyCardPrefixLength);
                validationResult.isValidFormat = exploreMoreCardPrefix === loyaltyCardPrefix;
            }
            validationResult.isDefaultCardNumber = exploreMoreCard.toLowerCase() === defaultCustomerNumber;
        }
        return validationResult;
    }
}
FormValidationUtil.emailPatternRegex = /^(([^<>()\[\]\\.,;:\s@"](\.[^<>()\[\]\\.,;:\s@"])*)|(".")){1,64}@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.default = FormValidationUtil;
