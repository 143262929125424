"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BrandListerReducer_1 = require("../../brandLister/reducers/BrandListerReducer");
const BrandListerSagas_1 = require("../../brandLister/sagas/BrandListerSagas");
const loadBrandListerSubModule = (initialData) => ({
    id: 'BrandLister',
    reducerMap: {
        brandLister: (0, BrandListerReducer_1.loadBrandListerReducer)(initialData ? initialData.brandLister : null),
    },
    sagas: [BrandListerSagas_1.default],
});
exports.default = loadBrandListerSubModule;
