"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monetateReducer = exports.initialState = void 0;
const EventEnum_1 = require("../../../enums/EventEnum");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const GeneralEnums_1 = require("../../../enums/GeneralEnums");
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const ProductGroupUtil_1 = require("../../../util/ProductGroupUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialState = {
    scriptState: GeneralEnums_1.ScriptLoadingState.NOT_LOADED,
    recommendations: [],
    products: {},
};
const customEventReducer = (state, eventPayload) => {
    switch (eventPayload.eventType) {
        case EventEnum_1.EventEnum.MONETATE_RECOMMENDATIONS:
            return Object.assign(Object.assign({}, state), { recommendations: [...state.recommendations, Object.assign({}, eventPayload.data)] });
        default:
            return state;
    }
};
const monetateReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT:
            return customEventReducer(state, action.payload);
        case ActionEnums_1.MonetateActionTypes.LOAD:
            return Object.assign(Object.assign({}, state), { scriptState: GeneralEnums_1.ScriptLoadingState.LOADING });
        case ActionEnums_1.MonetateActionTypes.ON_HOLD:
            return Object.assign(Object.assign({}, state), { scriptState: GeneralEnums_1.ScriptLoadingState.ON_HOLD });
        case ActionEnums_1.MonetateActionTypes.LOADED:
            return Object.assign(Object.assign({}, state), { scriptState: GeneralEnums_1.ScriptLoadingState.LOADED });
        case ActionEnums_1.MonetateActionTypes.SET_PRODUCTS:
            return Object.assign(Object.assign({}, state), { products: Object.assign(Object.assign({}, state.products), action.payload) });
        case ActionEnums_1.MonetateActionTypes.SET_DELIVERY_PROMISE:
            return Object.assign(Object.assign({}, state), { products: Object.assign(Object.assign({}, state.products), { [action.payload.productGroupId]: (0, ProductUtil_1.setDeliveryPromiseToProducts)(state.products[action.payload.productGroupId], action.payload) }) });
        case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
            return Object.assign(Object.assign({}, state), { products: (0, ProductGroupUtil_1.updateProductGroupProducts)(state.products, CompareReducer_1.toggleComparedStateForItems, action.payload) });
        case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
            return Object.assign(Object.assign({}, state), { products: (0, ProductGroupUtil_1.updateProductGroupProducts)(state.products, WishListReducer_1.initializeWishListProducts, action) });
        case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
            return Object.assign(Object.assign({}, state), { products: (0, ProductGroupUtil_1.updateProductGroupProducts)(state.products, WishListReducer_1.updateWishListProducts, action, true) });
        case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
            return Object.assign(Object.assign({}, state), { products: (0, ProductGroupUtil_1.updateProductGroupProducts)(state.products, WishListReducer_1.updateWishListProducts, action, false) });
        default:
            return state;
    }
};
exports.monetateReducer = monetateReducer;
