"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileNavigation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const MobileNavigationItemContent_1 = require("../../../components/navigation/mobile-navigation/MobileNavigationItemContent");
const NavigationBasketBadge_1 = require("./NavigationBasketBadge");
const NavigationWishlistBadge_1 = require("./NavigationWishlistBadge");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const MobileNavigationSearch_1 = require("./MobileNavigationSearch");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MobileNavigationAccount_1 = require("./MobileNavigationAccount");
const HEADER_HEIGHT = '64px';
const MENU_MAX_WIDTH = '525px';
const getMenuItem = ({ item, idPostfix, isShopLanding, parentLevelId, backBtnLabel, parentAnalyticsBannerId, }) => {
    var _a;
    if (item.type === 'wishlist' && isShopLanding) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { dataTestId: `mobile-navigation-item-${idPostfix}`, levelId: ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.length) ? `${parentLevelId}/${item.id}` : undefined, subLevel: getMenuLevel({ item, backBtnLabel, parentLevelId, isShopLanding, parentAnalyticsBannerId }), spacing: item.spacing, href: item.href, children: (0, jsx_runtime_1.jsxs)(MobileNavigationItemContent_1.MobileNavigationItemContent, { icon: item.icon, active: item.active, emphasized: item.emphasized, image: item.image, children: [item.label, item.type === 'basket' && (0, jsx_runtime_1.jsx)(NavigationBasketBadge_1.NavigationBasketBadge, {}), item.type === 'wishlist' && (0, jsx_runtime_1.jsx)(NavigationWishlistBadge_1.NavigationWishlistBadge, {})] }) }, item.id));
};
const getMenuLevel = ({ item, backBtnLabel, parentLevelId, isRootLevel, isShopLanding, searchConfig, accountConfig, languageItem, currencyItem, parentAnalyticsBannerId, }) => {
    var _a, _b;
    const levelId = `${parentLevelId ? `${parentLevelId}/` : ''}${item.id}`;
    const analyticsBannerId = isRootLevel
        ? ''
        : `${parentAnalyticsBannerId ? `${parentAnalyticsBannerId}-` : ''}${item.title.replace(' ', '-').toLowerCase()}`;
    return ((_a = item === null || item === void 0 ? void 0 : item.subItems) === null || _a === void 0 ? void 0 : _a.length) ? ((0, jsx_runtime_1.jsxs)(andes_react_1.Menu.Level, { id: levelId, backBtnLabel: backBtnLabel, rootLevel: isRootLevel, dataTestId: "mobile-navigation-level", analyticsBannerId: isRootLevel ? undefined : `header-navigation-flyout-${analyticsBannerId}`, children: [!isRootLevel && ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { href: item.href, dataTestId: "mobile-navigation-title", children: (0, jsx_runtime_1.jsx)(MobileNavigationItemContent_1.MobileNavigationItemContent, { emphasized: true, children: item.title }) })), isRootLevel && (searchConfig === null || searchConfig === void 0 ? void 0 : searchConfig.enabled) && ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { spacing: "l", verticalInnerSpacing: false, dataTestId: "mobile-navigation-search", children: (0, jsx_runtime_1.jsx)(MobileNavigationSearch_1.MobileNavigationSearch, Object.assign({}, searchConfig)) })), (_b = item.subItems) === null || _b === void 0 ? void 0 : _b.map((subItem, index) => getMenuItem({
                isShopLanding,
                item: subItem,
                idPostfix: index,
                parentLevelId: levelId,
                backBtnLabel: item.title,
                parentAnalyticsBannerId: analyticsBannerId,
            })), isRootLevel && (accountConfig === null || accountConfig === void 0 ? void 0 : accountConfig.enabled) && ((0, jsx_runtime_1.jsx)(MobileNavigationAccount_1.MobileNavigationAccount, { dataTestId: "mobile-navigation-item-account", spacing: languageItem || currencyItem ? 'l' : 's', parentLevelId: levelId, backBtnLabel: item.title, login: accountConfig.login, registration: accountConfig.registration, accountMenu: accountConfig.accountMenu })), isRootLevel &&
                languageItem &&
                getMenuItem({
                    item: languageItem,
                    idPostfix: 'language',
                    parentLevelId: levelId,
                    backBtnLabel: item.title,
                }), isRootLevel &&
                currencyItem &&
                getMenuItem({
                    item: currencyItem,
                    idPostfix: 'currency',
                    parentLevelId: levelId,
                    backBtnLabel: item.title,
                })] }, item.id)) : null;
};
const MobileNavigation = ({ menuItems, defaultLevel, isABTestEnabled, searchConfig, accountConfig, currencies, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const alternateUrl = (0, ConfigurationUtil_1.getAlternateUrl)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const languages = Object.keys(alternateUrl);
    const [isOpen, setIsOpen] = (0, react_1.useState)(undefined); // undefined to avoid playing animation on init
    const [shouldRenderMenu, setShouldRenderMenu] = (0, react_1.useState)(false);
    const [isMenuClicked, setIsMenuClicked] = (0, react_1.useState)(false);
    const menuWrapperRef = (0, cotopaxi_1.useHandleClickOutside)(Boolean(isOpen), setIsOpen);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const { isShopLanding } = (0, common_components_1.useAemConfigurationContextWrapper)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleTriggerClick = (e) => {
        e.stopPropagation(); // to avoid click outside triggering
        setIsOpen(prev => !prev);
        !isOpen && setShouldRenderMenu(true);
        if (!isMenuClicked && !isABTestEnabled) {
            // disable analytics in case of A/B test to avoid event duplication, since in this case the event will be triggered from AEM code
            setIsMenuClicked(true);
            dispatch(AnalyticsActions_creators_1.analyticsActions.mobileNavigationClick());
        }
    };
    const getLangItemLabel = (lang) => `${t(`${i18nConstants_1.I18N_NAMESPACES.general}:language.${lang}`)} (${lang.toUpperCase()})`;
    const getEditLabel = (prefix) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [prefix, (0, jsx_runtime_1.jsx)(andes_react_1.Text, { color: "secondary", variant: "bodyM", fontWeight: "bold", children: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.modify.label`) })] }));
    const getCurrencyItemLabel = (currency) => {
        return `${currency.name} (${currency.currency})`;
    };
    const languageItem = languages.length && languages.length > 1
        ? {
            id: 'language_level',
            title: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.bottom.language`),
            spacing: 's',
            label: getEditLabel(getLangItemLabel(lang)),
            icon: cotopaxi_1.IconName.GLOBE,
            type: 'common',
            subItems: languages
                .map(item => ({
                id: `lang_menu_item_${item}`,
                title: item,
                href: alternateUrl[item],
                label: getLangItemLabel(item),
                active: lang === item,
                type: 'common',
            }))
                .sort((lang1, lang2) => Number(lang2.active) - Number(lang1.active)),
        }
        : undefined;
    const currencyItem = (() => {
        if (!(currencies === null || currencies === void 0 ? void 0 : currencies.length) || currencies.length < 1)
            return undefined;
        const activeCurrency = currencies.find(item => item.active) || currencies[0];
        return {
            id: 'currency_level',
            title: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.bottom.currency`),
            spacing: 's',
            label: getEditLabel(getCurrencyItemLabel(activeCurrency)),
            image: activeCurrency.flag,
            type: 'common',
            subItems: currencies
                .map(item => ({
                id: `currency_menu_item_${item.currency}`,
                title: item.currency,
                href: item.homepage,
                label: getCurrencyItemLabel(item),
                active: item.active,
                image: item.flag,
                type: 'common',
            }))
                .sort((currency1, currency2) => Number(currency2.active) - Number(currency1.active)),
        };
    })();
    if ((languageItem || currencyItem || accountConfig.enabled) && ((_a = menuItems === null || menuItems === void 0 ? void 0 : menuItems.subItems) === null || _a === void 0 ? void 0 : _a.length)) {
        menuItems.subItems.at(-1).spacing = 'l';
    }
    (0, cotopaxi_1.useLockBodyScroll)(Boolean(isOpen));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Button, { dataTestId: "mobile-avigation-trigger", onClick: handleTriggerClick, variant: "link", iconRight: {
                    size: 'lg',
                    color: 'current',
                    name: isClient ? 'animated-burger-close' : 'menu',
                    animatedIconConfig: {
                        loop: false,
                        play: typeof isOpen === 'boolean',
                        direction: isOpen ? 1 : -1,
                    },
                } }), shouldRenderMenu &&
                (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { innerRef: menuWrapperRef, children: (0, jsx_runtime_1.jsx)(andes_react_1.Menu, { dataTestId: "mobile-navigation", isOpen: isOpen, onClose: () => {
                                setIsOpen(false);
                                setShouldRenderMenu(false);
                            }, offsetTop: HEADER_HEIGHT, defaultLevel: defaultLevel, width: MENU_MAX_WIDTH, children: getMenuLevel({
                                item: menuItems,
                                backBtnLabel: menuItems.title,
                                isRootLevel: true,
                                isShopLanding,
                                searchConfig,
                                accountConfig,
                                currencyItem,
                                languageItem,
                            }) }) }) }), document.body)] }));
};
exports.MobileNavigation = MobileNavigation;
