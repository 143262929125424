"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProductGroupProducts = exports.normalizeProductGroupState = void 0;
const normalizeProductGroupState = (state, initialState) => {
    Object.entries(initialState).forEach(([key, value]) => {
        if (!state[key]) {
            state[key] = value;
        }
    });
};
exports.normalizeProductGroupState = normalizeProductGroupState;
const updateProductGroupProducts = (productGroupById, handler, ...additionalHandlerParams) => {
    const newProductGroupItemsList = {};
    Object.keys(productGroupById).map(key => {
        newProductGroupItemsList[key] = handler(productGroupById[key], ...additionalHandlerParams);
    });
    return Object.assign(Object.assign({}, productGroupById), newProductGroupItemsList);
};
exports.updateProductGroupProducts = updateProductGroupProducts;
