"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyVatFields = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BuyVatFields = ({ errors, touched, placeholderVat = '', companyNameFieldLocked, hideCompanyName, disableVatNumberField, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, gutter: false, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, htmlFor: "buy-invoiceVatNumber", size: cotopaxi_1.LabelSize.MINOR, children: t('buy:buy.invoice.vatnumber') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-invoiceVatNumber", placeholder: `${t('checkout:checkout.example')} ${placeholderVat}`, name: "invoiceVatNumber", maxLength: 50, disabled: companyNameFieldLocked, state: errors.invoiceVatNumber && touched.invoiceVatNumber
                                    ? cotopaxi_1.InputState.ERROR
                                    : disableVatNumberField
                                        ? cotopaxi_1.InputState.DISABLED
                                        : undefined, Component: cotopaxi_1.Input })] }) }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [!hideCompanyName && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 6, desktop: 6, grid: 0, noGutterLeft: true, noGutterRight: isMobile, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, required: true, htmlFor: "buy-invoiceCompanyName", size: cotopaxi_1.LabelSize.MINOR, children: t('buy:buy.invoice.companyname') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-invoiceCompanyName", name: "invoiceCompanyName", maxLength: 100, required: true, state: errors.invoiceCompanyName && touched.invoiceCompanyName
                                        ? cotopaxi_1.InputState.ERROR
                                        : companyNameFieldLocked
                                            ? cotopaxi_1.InputState.LOADING
                                            : undefined, Component: cotopaxi_1.Input })] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 6, desktop: 6, grid: 0, noGutterLeft: isMobile || hideCompanyName, noGutterRight: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, htmlFor: "buy-invoiceCompanyDepartment", size: cotopaxi_1.LabelSize.MINOR, children: t('buy:buy.invoice.departmentname') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-invoiceCompanyDepartment", name: "invoiceCompanyDepartment", maxLength: 100, state: errors.invoiceCompanyDepartment && touched.invoiceCompanyDepartment && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input })] }) })] })] }));
};
exports.BuyVatFields = BuyVatFields;
