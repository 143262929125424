"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const SizeDropdown_1 = require("../../../size-dropdown/SizeDropdown");
const ProductUtil_1 = require("../../../../util/ProductUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const SizeSelect = ({ onChange, sizeSelectError }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const id = (0, react_1.useId)();
    const { colors, activeColorId, productId, selectedSku } = (0, cotopaxi_1.useProductTileContext)();
    const selectedColor = (0, common_utils_1.findColor)(colors, activeColorId);
    const oneSizeProduct = (0, common_utils_1.isOnlyOneSizeProduct)(selectedColor.sizes);
    const { filteredSizes, options } = (0, ProductUtil_1.mapSizeSelectOptions)(selectedColor.sizes, true);
    const handleSelectedItemChange = (option) => {
        onChange(option.value.toString());
    };
    return !oneSizeProduct ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SizeDropdown_1.SizeDropdown, { id: `mini_pdp_product_tile_size_select_${id}`, appendToBody: true, selectedSku: selectedSku, handleSelectedItemChange: handleSelectedItemChange, options: options, sizes: filteredSizes, toggleElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { fullWidth: true, placeholder: t('pdp.sizeSmall'), name: `size_select_look_${productId}`, selectedValue: selectedSku, options: options, state: sizeSelectError ? cotopaxi_1.SelectState.ERROR : undefined, onChange: onChange, disabled: true }) }), sizeSelectError && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, size: cotopaxi_1.TextSize.SMALL, children: t('pdp.forgotSize') }))] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, emphasized: true, children: t('pdp.size') }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: (_a = options[0]) === null || _a === void 0 ? void 0 : _a.label })] }));
};
exports.SizeSelect = SizeSelect;
