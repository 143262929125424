"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetWishlistFetchStatus = exports.makeGetWishlistTotalPages = exports.makeGetWishlistCounter = exports.makeGetWishlistListerState = exports.makeGetProductColorIsInWishList = exports.makeIsProductInWishList = exports.makeGetWishListProducts = exports.getWishListProductsState = exports.wishListActiveSelector = void 0;
const reselect_1 = require("reselect");
const ProductSelector_1 = require("../../product/selectors/ProductSelector");
const wishListActiveSelector = (state) => !!state.wishList;
exports.wishListActiveSelector = wishListActiveSelector;
const wishListProductsSelector = (state) => state.wishList && state.wishList.products;
const getWishlistTotalPages = (state) => state.wishList.totalPages;
const getWishlistFetchStatus = (state) => state.wishList && state.wishList.fetchStatus;
const getWishlistListerState = (state) => state.wishList;
exports.getWishListProductsState = (0, reselect_1.createSelector)([wishListProductsSelector], (wishListProducts) => wishListProducts);
const makeGetWishListProducts = () => (0, reselect_1.createSelector)([exports.getWishListProductsState], (wishListProducts) => wishListProducts);
exports.makeGetWishListProducts = makeGetWishListProducts;
const makeIsProductInWishList = (productCode, colorId) => (0, reselect_1.createSelector)(wishListProductsSelector, (wishListProducts) => !!(wishListProducts === null || wishListProducts === void 0 ? void 0 : wishListProducts.find((wishListProduct) => wishListProduct.productCode === productCode && wishListProduct.colorId === colorId)));
exports.makeIsProductInWishList = makeIsProductInWishList;
const makeGetProductColorIsInWishList = () => (0, reselect_1.createSelector)(ProductSelector_1.getProductCode, ProductSelector_1.getSelectedColorId, wishListProductsSelector, (productCode, colorId, wishListProducts) => !!wishListProducts.find((wishListProduct) => wishListProduct.productCode === productCode && wishListProduct.colorId === colorId));
exports.makeGetProductColorIsInWishList = makeGetProductColorIsInWishList;
const makeGetWishlistListerState = () => (0, reselect_1.createSelector)(getWishlistListerState, (state) => ({
    items: state.items || [],
    itemCount: state.itemCount || 0,
}));
exports.makeGetWishlistListerState = makeGetWishlistListerState;
const makeGetWishlistCounter = () => (0, reselect_1.createSelector)(wishListProductsSelector, (wishListProducts) => wishListProducts.length);
exports.makeGetWishlistCounter = makeGetWishlistCounter;
const makeGetWishlistTotalPages = () => (0, reselect_1.createSelector)(getWishlistTotalPages, (totalPages) => totalPages);
exports.makeGetWishlistTotalPages = makeGetWishlistTotalPages;
const makeGetWishlistFetchStatus = () => (0, reselect_1.createSelector)(getWishlistFetchStatus, status => status);
exports.makeGetWishlistFetchStatus = makeGetWishlistFetchStatus;
