"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ScreenWidthConstants_1 = require("../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../constants/SliderConstants");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const Slider = ({ children, height, fullWidth, id, properties: { autoplay, displayCroppedSlides, hideOnViewports, infiniteSliding, innerSpacing, showArrowsForDesktop, showIndicators, slidesToScrollDesktop, slidesToScrollDesktopLarge, slidesToScrollMobile, slidesToScrollMobileLarge, slidesToScrollTablet, slidesToScrollTabletSmall, slidesToShowDesktop, slidesToShowDesktopLarge, slidesToShowMobile, slidesToShowMobileLarge, slidesToShowTablet, slidesToShowTabletSmall, }, }) => {
    const slidesSpacing = innerSpacing ? SliderConstants_1.SLIDE_SPACING.MOBILE : 0;
    const breakpoints = {
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE]: {
            slidesPerView: displayCroppedSlides ? slidesToShowMobile + SliderConstants_1.SlideCropped.SMALL : slidesToShowMobile,
            slidesPerGroup: slidesToScrollMobile,
        },
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE_LARGE]: {
            slidesPerView: displayCroppedSlides ? slidesToShowMobileLarge + SliderConstants_1.SlideCropped.SMALL : slidesToShowMobileLarge,
            slidesPerGroup: slidesToScrollMobileLarge,
        },
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL]: {
            slidesPerView: displayCroppedSlides ? slidesToShowTabletSmall + SliderConstants_1.SlideCropped.SMALL : slidesToShowTabletSmall,
            slidesPerGroup: slidesToScrollTabletSmall,
        },
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
            slidesPerView: displayCroppedSlides ? slidesToShowTablet + SliderConstants_1.SlideCropped.LARGE : slidesToShowTablet,
            slidesPerGroup: slidesToScrollTablet,
        },
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
            slidesPerView: displayCroppedSlides ? slidesToShowDesktop + SliderConstants_1.SlideCropped.LARGE : slidesToShowDesktop,
            slidesPerGroup: slidesToScrollDesktop,
        },
        [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
            slidesPerView: displayCroppedSlides ? slidesToShowDesktopLarge + SliderConstants_1.SlideCropped.LARGE : slidesToShowDesktopLarge,
            slidesPerGroup: slidesToScrollDesktopLarge,
        },
    };
    const sliderCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.SLIDER,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
            color: cotopaxi_1.SliderNavColor.BASE,
            iconSize: cotopaxi_1.IconSize.MINOR,
        },
        showPagination: showIndicators,
        paginationVariant: cotopaxi_1.CarouselPaginationVariant.MAIN_CLICKABLE,
        hideMobile: hideOnViewports,
        configurationOverrides: {
            autoplay,
            preventClicks: true,
            spaceBetween: slidesSpacing,
            shouldSwiperUpdate: true,
            watchOverflow: true,
            loop: infiniteSliding,
            breakpoints,
        },
    };
    if (!showArrowsForDesktop) {
        sliderCarouselConfig.configurationOverrides.navigation = false;
    }
    const getSlideWidth = (slidesPerView, spacing) => `calc((100% - ${slidesPerView - 1} * ${spacing}px) / ${slidesPerView})`;
    const getStyleFragment = () => `
        #${id}.as-m-carousel--loading .swiper-slide {
            margin-right: ${slidesSpacing}px;
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE].slidesPerView, slidesSpacing)};
            }
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE_LARGE}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE_LARGE].slidesPerView, slidesSpacing)};
            }
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL].slidesPerView, slidesSpacing)};
            }
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET].slidesPerView, slidesSpacing)};
            }
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP].slidesPerView, slidesSpacing)};
            }
        }
        @media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE}px) {
            #${id}.as-m-carousel--loading .swiper-slide {
                width: ${getSlideWidth(breakpoints[ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE].slidesPerView, slidesSpacing)};
            }
        }
        ${(height === null || height === void 0 ? void 0 : height.mobile)
        ? `@media (max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_TABLET_SMALL}px) {
                      #${id} .swiper-container { height: ${height.mobile}px; }
                    }`
        : ''}
        ${(height === null || height === void 0 ? void 0 : height.tablet)
        ? `@media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET}px) and (max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_TABLET}px) {
                      #${id} .swiper-container { height: ${height.tablet}px; }
                    }`
        : ''}
        ${(height === null || height === void 0 ? void 0 : height.desktop)
        ? `@media (min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP}px) {
                      #${id} .swiper-container { height: ${height.desktop}px; }
                    }`
        : ''}
    `;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { styleFragment: getStyleFragment() }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, sliderCarouselConfig, { id: id, fullWidth: fullWidth, children: children }))] }));
};
exports.Slider = Slider;
