"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoginErrorMessage = void 0;
const common_types_1 = require("@as-react/common-types");
const getLoginErrorMessagesByLocation = (location) => {
    switch (location) {
        case common_types_1.AuthFormLocation.CHECKOUT:
            return {
                [common_types_1.AuthStatusCode.INVALID_PASSWORD]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_WRONG_CREDENTIALS,
                [common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT]: common_types_1.ErrorMessage.CHECKOUT_USER_UNKNOWN,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_SAME_FASCIA_NO_PASSWORD,
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_OTHER_FASCIA,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_OTHER_FASCIA,
            };
        case common_types_1.AuthFormLocation.LOGIN:
            return {
                [common_types_1.AuthStatusCode.INVALID_PASSWORD]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_WRONG_CREDENTIALS,
                [common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT]: common_types_1.ErrorMessage.LOGIN_USER_UNKNOWN,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_SAME_FASCIA_NO_PASSWORD,
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_OTHER_FASCIA,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_OTHER_FASCIA,
            };
        case common_types_1.AuthFormLocation.RETURN_PORTAL:
            return {
                [common_types_1.AuthStatusCode.INVALID_PASSWORD]: common_types_1.ErrorMessage.RETURN_PORTAL_LOGIN_USER_KNOWN_WRONG_CREDENTIALS,
                [common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT]: common_types_1.ErrorMessage.RETURN_PORTAL_USER_UNKNOWN,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: common_types_1.ErrorMessage.RETURN_PORTAL_LOGIN_USER_KNOWN_SAME_FASCIA_NO_PASSWORD,
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.RETURN_PORTAL_LOGIN_USER_KNOWN_OTHER_FASCIA,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.RETURN_PORTAL_LOGIN_USER_KNOWN_OTHER_FASCIA_NO_PASSWORD,
            };
        case common_types_1.AuthFormLocation.LOGIN_FLOWS:
            return {
                [common_types_1.AuthStatusCode.INVALID_PASSWORD]: common_types_1.ErrorMessage.CHECKOUT_LOGIN_USER_KNOWN_WRONG_CREDENTIALS,
                [common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT]: common_types_1.ErrorMessage.CHECKOUT_USER_UNKNOWN,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: common_types_1.ErrorMessage.LOGIN_FLOWS_USER_KNOWN_SAME_FASCIA_NO_PASSWORD,
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.LOGIN_FLOWS_USER_KNOWN_OTHER_FASCIA,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.LOGIN_FLOWS_USER_KNOWN_OTHER_FASCIA,
            };
        case common_types_1.AuthFormLocation.POPOVER:
        default:
            return {
                [common_types_1.AuthStatusCode.INVALID_PASSWORD]: common_types_1.ErrorMessage.FLYOUT_USER_KNOWN_WRONG_CREDENTIALS,
                [common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT]: common_types_1.ErrorMessage.FLYOUT_USER_UNKNOWN,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: common_types_1.ErrorMessage.FLYOUT_USER_KNOWN_SAME_FASCIA_NO_PASSWORD,
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.FLYOUT_USER_KNOWN_OTHER_FASCIA,
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: common_types_1.ErrorMessage.FLYOUT_USER_KNOWN_OTHER_FASCIA_NO_PASSWORD,
            };
    }
};
const getLoginErrorMessage = (errorCode, location) => {
    if (!errorCode) {
        return common_types_1.ErrorMessage.GENERAL_ACCOUNT;
    }
    return getLoginErrorMessagesByLocation(location)[errorCode];
};
exports.getLoginErrorMessage = getLoginErrorMessage;
