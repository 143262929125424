"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const AuthenticationPopover_1 = require("../components/account/authentication/AuthenticationPopover");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const AuthenticationContainer = ({ actionUri, anchorEl, onClose, registrationLink, }) => {
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const resetForgotPasswordState = () => {
        (resetPasswordState.success || resetPasswordState.error) &&
            dispatch(AuthActions_creators_1.authActions.createResetCredentialsSuccessAction(null));
    };
    const toggleView = () => {
        setForgotPasswordActive(prev => !prev);
        resetForgotPasswordState();
    };
    const handleLoginSubmit = (values) => dispatch(AuthActions_creators_1.authActions.login({
        username: values.email,
        password: values.password,
        keepSignedIn: values.keepSignedIn,
        reloadOnSuccess: true,
        location: common_types_1.AuthFormLocation.POPOVER,
    }));
    const handleClose = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setTimeout(() => setForgotPasswordActive(false), cotopaxi_1.POPOVER_ANIMATION_DURATION);
    };
    (0, react_1.useEffect)(() => {
        dispatch(AuthActions_creators_1.authActions.createInitSocialAction());
    }, []);
    return ((0, jsx_runtime_1.jsx)(AuthenticationPopover_1.AuthenticationPopover, { login: {
            action: actionUri,
            onSubmit: handleLoginSubmit,
            errorFeedback: loginError,
            loading: loading,
        }, forgotPassword: {
            onSubmit: values => dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(values.email)),
            errorFeedback: resetPasswordState.error,
            successFeedback: resetPasswordState.success,
        }, facebook: {}, anchorEl: anchorEl, onClose: handleClose, registrationLink: registrationLink, socialLogin: payload => dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(payload)), onAuthResetForm: () => dispatch(AuthActions_creators_1.authActions.resetError()), forgotPasswordActive: forgotPasswordActive, toggleView: toggleView, onEmailChange: resetForgotPasswordState, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE }));
};
exports.AuthenticationContainer = AuthenticationContainer;
