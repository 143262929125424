"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetActiveFilters = exports.makeGetFlattenedActiveFiltersState = void 0;
const common_types_1 = require("@as-react/common-types");
const reselect_1 = require("reselect");
const getActiveFilters = (state) => state.activeFilters;
const makeGetFlattenedActiveFiltersState = () => (0, reselect_1.createSelector)([getActiveFilters], (activeFilters) => {
    return (!!activeFilters &&
        Object.keys(activeFilters).reduce((arr, filterOn) => {
            const activeFilter = activeFilters[filterOn];
            let result = [];
            if (activeFilter.filterType === common_types_1.RANGE_FILTER) {
                result = [
                    {
                        filterContentType: activeFilter.filterContentType,
                        filterOn: filterOn,
                        filterOnName: activeFilter.filterOnName,
                        filterType: activeFilter.filterType,
                        filterPrefix: activeFilter.filterPrefix,
                        filterSuffix: activeFilter.filterSuffix,
                        rangeMin: activeFilter.rangeMin,
                        rangeMax: activeFilter.rangeMax,
                    },
                ];
            }
            else {
                if (activeFilter.values) {
                    result = activeFilter.values.map(activeFilterValue => ({
                        filterContentType: activeFilter.filterContentType,
                        filterKey: activeFilterValue.filterKey,
                        filterKeyName: activeFilterValue.filterKeyName,
                        filterOn: filterOn,
                        filterType: activeFilter.filterType,
                        filterOnName: activeFilter.filterOnName,
                        queryParamKey: activeFilter.queryParamKey,
                    }));
                }
            }
            return arr.concat(result);
        }, [])) || [];
});
exports.makeGetFlattenedActiveFiltersState = makeGetFlattenedActiveFiltersState;
const makeGetActiveFilters = () => (0, reselect_1.createSelector)([getActiveFilters], (activeFilters) => activeFilters);
exports.makeGetActiveFilters = makeGetActiveFilters;
