"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductVariantButtons_1 = require("../../components/product-detail/product-variant-buttons/ProductVariantButtons");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ProductUtil_1 = require("../../util/ProductUtil");
const ProductVariantsContainer = () => {
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(configuration);
    const productVariants = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductVariants)());
    const { productCode } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleVariantClick = (variant) => () => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.productVariantClick());
        window.location.href = (0, ProductUtil_1.getProductDetailUrl)({ seoUrl: variant.productSeoUrl, colourId: variant.colorId }, root);
    };
    if ((productVariants === null || productVariants === void 0 ? void 0 : productVariants.length) === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(ProductVariantButtons_1.ProductVariantButtons, { productVariants: productVariants, productCode: productCode, handleVariantClick: handleVariantClick }));
};
exports.default = ProductVariantsContainer;
