"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerEmptyResult = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const ListerEmptyResult = ({ parentPageHref, parentPageTitle, mainWebShop, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const getContent = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('product.lister.empty.result.heading') }), mainWebShop !== common_types_1.MainWebShop.BEVER && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, desktop: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, type: cotopaxi_1.TextType.CONTENT, children: t('product.lister.empty.result.subheading') }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: ["\u2022 ", t('product.lister.empty.result.action'), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: parentPageHref, title: parentPageTitle, children: parentPageTitle })] }) })] }));
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, desktop: { spacing: { marginTop: 8, marginBottom: 8 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: getContent() }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.TOP, children: getContent() }) })] }));
};
exports.ListerEmptyResult = ListerEmptyResult;
