"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
class BasketUtil {
    static isBasketNotInitialized(error) {
        var _a;
        return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.code) === common_types_1.CheckoutErrorMessage.CHECKOUT_NOT_INITIATED;
    }
    static isBasketNotFound(error) {
        var _a;
        return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.code) === common_types_1.CheckoutErrorMessage.BASKET_NOT_FOUND;
    }
    static isCheckoutStepInvalid(error) {
        var _a;
        return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.code) === common_types_1.CheckoutErrorMessage.CHECKOUT_STEP_INVALID;
    }
}
BasketUtil.mergeProductAvailabilitiesToBasketData = (basketProducts, productAvailabilities) => {
    return basketProducts.map(product => {
        const availabilitiesByProductId = productAvailabilities.find(availability => product.productId === availability.productId.toString());
        const availabilitiesByProductIdAndByColorId = availabilitiesByProductId
            ? availabilitiesByProductId.colorAvailabilities.find(colorAvailability => colorAvailability.colorId === product.colorId)
            : null;
        if (availabilitiesByProductIdAndByColorId) {
            product.sizes.map(basketProductSize => {
                const availabilitiesByProductIdAndByColorIdBySizeId = availabilitiesByProductIdAndByColorId.skuAvailabilities.find(size => size.skuCode === basketProductSize.sku);
                if (availabilitiesByProductIdAndByColorIdBySizeId) {
                    basketProductSize.availability = availabilitiesByProductIdAndByColorIdBySizeId.availability;
                    return basketProductSize;
                }
                return basketProductSize;
            });
            return product;
        }
        return product;
    });
};
exports.default = BasketUtil;
