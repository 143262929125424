"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Session_module_1 = require("./modules/Session.module");
const Orders_module_1 = require("./modules/Orders.module");
const Customer_module_1 = require("./modules/Customer.module");
const AccountOrderHistoryModule = () => ({
    hasRouter: false,
    modules: [(0, Session_module_1.default)(), (0, Customer_module_1.default)(), (0, Orders_module_1.default)()],
});
exports.default = AccountOrderHistoryModule;
