"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const ProductGroupReducer_1 = require("../../productGroup/reducers/ProductGroupReducer");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const loadProductGroupModule = (initialData) => ({
    id: (initialData === null || initialData === void 0 ? void 0 : initialData.productGroupId)
        ? `${common_types_1.AEMScenes.PRODUCT_GROUP}_${initialData.productGroupId}`
        : common_types_1.AEMScenes.PRODUCT_GROUP,
    reducerMap: {
        productGroup: (0, ProductGroupReducer_1.loadProductGroupReducer)(initialData),
    },
    sagas: [DeliveryPromiseSaga_1.default],
});
exports.default = loadProductGroupModule;
