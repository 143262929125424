"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const AccountPreferences_1 = require("../../components/account/account-preferences/AccountPreferences");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const common_types_1 = require("@as-react/common-types");
const AccountUtil_1 = require("../../util/AccountUtil");
const AccountPreferencesContainer = ({ items, topText, bottomText, pathPrivacy, pathTerms, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const [preferences, setPreferences] = (0, react_1.useState)();
    const [initialPreferences, setInitialPreferences] = (0, react_1.useState)();
    const [multiFasciaPreferences, setMultiFasciaPreferences] = (0, react_1.useState)();
    const [initialMultiFasciaPreferences, setInitialMultiFasciaPreferences] = (0, react_1.useState)();
    const [preferenceList, setPreferenceList] = (0, react_1.useState)();
    const [submitButtonState, setSubmitButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)('');
    const isUKMarket = defaultRequestParams.market === 'uk';
    const fetchPreferences = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            if (isUKMarket) {
                const result = yield CustomerApi_1.default.getMultiFasciaPreferences(servicesEndpoint, defaultRequestParams);
                result.fascias.sort(({ sortOrder: a }, { sortOrder: b }) => a - b);
                setMultiFasciaPreferences(result);
                setInitialMultiFasciaPreferences((0, common_utils_1.cloneDeep)(result));
            }
            else {
                const result = yield CustomerApi_1.default.getPreferences(servicesEndpoint, defaultRequestParams);
                const preferencesTmp = result.reduce((acc, cur) => (Object.assign(Object.assign({}, acc), { [cur.code]: cur.isSubscribed })), {});
                setPreferenceList(result);
                setPreferences(preferencesTmp);
                setInitialPreferences((0, common_utils_1.cloneDeep)(preferencesTmp));
            }
        }
        catch (_a) { }
    });
    const onUpdatePreferencesSuccess = () => {
        setSubmitButtonState(cotopaxi_1.ButtonState.FINISHED);
        setTimeout(() => setSubmitButtonState(cotopaxi_1.ButtonState.INTERACTIVE), GeneralConstants_1.DELAY.DELAY_3000);
    };
    const analytics = (currentPreferences, updatedPreferences) => {
        let subscriptions = {};
        let unsubscriptions = {};
        Object.keys(updatedPreferences).map(updatedKey => {
            Object.keys(currentPreferences).map(currentKey => {
                if (currentKey === updatedKey &&
                    updatedPreferences[updatedKey] !==
                        currentPreferences[currentKey]) {
                    if (currentPreferences[currentKey] === false &&
                        updatedPreferences[updatedKey] === true) {
                        subscriptions = Object.assign(Object.assign({}, subscriptions), { [updatedKey]: updatedPreferences[updatedKey] });
                    }
                    else {
                        unsubscriptions = Object.assign(Object.assign({}, unsubscriptions), { [updatedKey]: updatedPreferences[updatedKey] });
                    }
                }
            });
        });
        const hasSubscriptions = Boolean(Object.keys(subscriptions).length);
        const hasUnsubscriptions = Boolean(Object.keys(unsubscriptions).length);
        if (hasSubscriptions && hasUnsubscriptions) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.newsletterPreferencesSubscriptionUpdate({ subscriptions, unsubscriptions }));
        }
        else if (hasSubscriptions) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.newsletterPreferencesSubscribe(subscriptions));
        }
        else if (hasUnsubscriptions) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.newsletterPreferencesUnsubscribe(unsubscriptions));
        }
        dispatch(AnalyticsActions_creators_1.analyticsActions.newsletterPreferencesUpdate(updatedPreferences));
    };
    const updatePreferences = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setSubmitButtonState(cotopaxi_1.ButtonState.LOADING);
        setErrorFeedback('');
        try {
            if (isUKMarket) {
                if (!multiFasciaPreferences)
                    return;
                yield CustomerApi_1.default.updateMultiFasciaPreferences(servicesEndpoint, defaultRequestParams, multiFasciaPreferences);
                onUpdatePreferencesSuccess();
                if (initialMultiFasciaPreferences) {
                    analytics(initialMultiFasciaPreferences, multiFasciaPreferences);
                }
                setInitialMultiFasciaPreferences((0, common_utils_1.cloneDeep)(multiFasciaPreferences));
            }
            else {
                if (!preferences || !preferenceList)
                    return;
                yield CustomerApi_1.default.updatePreferences(servicesEndpoint, defaultRequestParams, (0, AccountUtil_1.populatePreferencesWithSubCategories)(preferenceList));
                onUpdatePreferencesSuccess();
                if (initialPreferences) {
                    analytics(initialPreferences, preferences);
                }
                setInitialPreferences((0, common_utils_1.cloneDeep)(preferences));
            }
        }
        catch (_b) {
            setSubmitButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            setErrorFeedback(t('account.fail'));
        }
    });
    const onPreferenceChange = (preference, value, fasciaId) => {
        if (isUKMarket) {
            if (!multiFasciaPreferences)
                return;
            if (preference === common_types_1.PreferenceTypes.ETICKET) {
                multiFasciaPreferences.eticket = value;
            }
            else {
                const fascia = multiFasciaPreferences.fascias.find(item => item.fasciaId === fasciaId);
                if (fascia) {
                    fascia.preferences[preference] = value;
                }
            }
            setMultiFasciaPreferences(Object.assign({}, multiFasciaPreferences));
        }
        else {
            if (!preferenceList || !preferences)
                return;
            const preferenceItem = preferenceList.find(item => item.code === preference);
            preferenceList
                .filter(pref => pref.parentPreferenceFlagCode === (preferenceItem === null || preferenceItem === void 0 ? void 0 : preferenceItem.code))
                .forEach(pref => (preferences[pref.code] = value));
            if (preferenceItem)
                preferenceItem.isSubscribed = value;
            preferences[preference] = value;
            const preferenceSubCats = preferenceList.filter(pref => pref.parentPreferenceFlagCode && pref.parentPreferenceFlagCode === (preferenceItem === null || preferenceItem === void 0 ? void 0 : preferenceItem.parentPreferenceFlagCode));
            const allInOneState = preferenceSubCats.every(pref => preferences[pref.code] === value);
            const atLeastOneSubscribed = preferenceSubCats.some(pref => preferences[pref.code] === true);
            const parentPreferenceItem = preferenceList.find(item => item.code === (preferenceItem === null || preferenceItem === void 0 ? void 0 : preferenceItem.parentPreferenceFlagCode));
            if (allInOneState && parentPreferenceItem)
                preferences[parentPreferenceItem.code] = value;
            if (atLeastOneSubscribed && parentPreferenceItem)
                preferences[parentPreferenceItem.code] = true;
            const preferenceListUpdated = preferenceList.map(item => (Object.assign(Object.assign({}, item), { isSubscribed: preferences[item.code] })));
            setPreferenceList(preferenceListUpdated);
            setPreferences(preferences);
        }
    };
    (0, react_1.useEffect)(() => {
        fetchPreferences();
    }, []);
    return multiFasciaPreferences || preferences ? ((0, jsx_runtime_1.jsx)(AccountPreferences_1.AccountPreferences, { isUKMarket: isUKMarket, preferenceItemsConfig: items, preferences: preferences, multiFasciaPreferences: multiFasciaPreferences, onPreferenceChange: onPreferenceChange, updatePreferences: updatePreferences, submitButtonState: submitButtonState, topText: topText, bottomText: bottomText, errorFeedback: errorFeedback, pathPrivacy: pathPrivacy, pathTerms: pathTerms, title: title })) : null;
};
exports.default = AccountPreferencesContainer;
