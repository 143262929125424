"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DpeValidator_1 = require("../dpe-validator/DpeValidator");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const cotopaxi_1 = require("@as/cotopaxi");
const DpeTile = ({ isPopup, isAuthenticated, data, digitalPartner, onConfirmationClick, onActiveStepChange, onFormValidationError, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.dpe);
    const [active, setActive] = (0, react_1.useState)(isAuthenticated ? common_types_1.DpeTileStep.VALIDATION : common_types_1.DpeTileStep.INTRO);
    const [isAuth, setIsAuth] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (isAuthenticated && isAuthenticated !== isAuth) {
            setActive(common_types_1.DpeTileStep.VALIDATION);
            setIsAuth(true);
        }
    }, [isAuthenticated]);
    const setActiveStep = (stepToSet) => {
        if (stepToSet !== active) {
            onActiveStepChange({
                from: active,
                to: stepToSet,
            });
            setActive(stepToSet);
        }
    };
    const handleIntroButtonClick = () => {
        setActiveStep(common_types_1.DpeTileStep.VALIDATION);
    };
    const renderIntro = () => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: {
                paddingTop: 0,
                paddingBottom: 4,
                paddingLeft: 2,
                paddingRight: 2,
            },
        }, tablet: {
            spacing: {
                paddingLeft: 4,
                paddingRight: 4,
            },
        }, children: isPopup ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: data.introductionMessage }) })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: data.shortIntroductionMessage }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('dpe.activateNow'), onClick: handleIntroButtonClick })] })) }));
    const renderValidation = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingBottom: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                    },
                }, tablet: {
                    spacing: {
                        paddingBottom: 3,
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: data.validationMessage }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingBottom: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                    },
                }, tablet: {
                    spacing: {
                        paddingBottom: 4,
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }, children: (0, jsx_runtime_1.jsx)(DpeValidator_1.default, { digitalPartner: digitalPartner, onValidationSuccess: () => setActiveStep(common_types_1.DpeTileStep.CONFIRMATION), onValidationError: errorMessage => {
                        onFormValidationError(Object.assign({}, errorMessage));
                    } }) })] }));
    const renderConfirmation = () => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
            spacing: { paddingBottom: 2, paddingLeft: 2, paddingRight: 2 },
        }, tablet: {
            spacing: { paddingBottom: 4, paddingLeft: 4, paddingRight: 4 },
        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: data.confirmationMessage }) }), isPopup && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: { paddingTop: 2 },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('dpe.continue'), onClick: onConfirmationClick }) }))] }));
    const renderStep = () => {
        switch (active) {
            case common_types_1.DpeTileStep.INTRO:
                return renderIntro();
            case common_types_1.DpeTileStep.VALIDATION:
                return renderValidation();
            case common_types_1.DpeTileStep.CONFIRMATION:
                return renderConfirmation();
            default:
                return null;
        }
    };
    return renderStep();
};
exports.default = DpeTile;
