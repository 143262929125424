"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const MiniPDPMultiContainer_1 = require("./MiniPDPMultiContainer");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ImageUtil_1 = require("../util/ImageUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const MAX_PRODUCTS = 4;
const ShopTheLookPDPContainer = ({ defaultTitle, flyoutTitle, linkLabel, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImages = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const [isOpenMiniPDP, setIsOpenMiniPDP] = (0, react_1.useState)(false);
    const { images } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPreviewInformation)());
    const { productCode } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const linkedProducts = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetLinkedProducts)());
    const mainWebshopId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetMainWebshopId)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const defaultPicture = !(0, common_utils_1.isNullOrUndefined)(images[1]) ? images[1].picture : images[0].picture;
    const openMiniPDP = () => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.shopTheLookOpened(defaultPicture));
        setIsOpenMiniPDP(true);
    };
    const onMiniPDPClose = () => {
        setIsOpenMiniPDP(false);
    };
    const getImage = () => (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, defaultPicture, productImages)[common_types_1.ProductImageSizesPortraitEnum.detail];
    const updatedProducts = (0, react_1.useMemo)(() => {
        let shopTheLookProducts = [];
        if (linkedProducts.length <= MAX_PRODUCTS) {
            shopTheLookProducts = linkedProducts;
        }
        else {
            const productsWithAvailability = linkedProducts.map(product => (Object.assign(Object.assign({}, product), { isLookColorAvailable: product.colours.find(colour => colour.colourId === product.colourId).availability > 0 })));
            const availableProducts = productsWithAvailability.filter(product => product.isLookColorAvailable);
            const availableProductsAmount = availableProducts.length;
            if (availableProductsAmount >= MAX_PRODUCTS) {
                shopTheLookProducts = availableProducts.slice(0, MAX_PRODUCTS);
            }
            else {
                const maxOOSProductsToShow = MAX_PRODUCTS - availableProductsAmount;
                let addedOOSProductsAmount = 0;
                shopTheLookProducts = productsWithAvailability.reduce((products, product) => {
                    if (products.length < MAX_PRODUCTS) {
                        if (product.isLookColorAvailable) {
                            products.push(product);
                        }
                        else if (maxOOSProductsToShow !== addedOOSProductsAmount) {
                            addedOOSProductsAmount++;
                            products.push(product);
                        }
                    }
                    return products;
                }, []);
            }
        }
        return shopTheLookProducts.map(product => (Object.assign(Object.assign({}, product), { colours: product.colours.sort(color => (color.colourId === product.colourId ? -1 : 1)) })));
    }, [linkedProducts]);
    const getTitleTranslation = () => mainWebShop === common_types_1.MainWebShop.JUTTU ? t('product.look.pdp.title.specific') : t('product.look.pdp.title');
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: defaultTitle || getTitleTranslation() }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, mobile: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ImageLabel, { image: { src: getImage(), lazy: true }, label: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 0.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.INHERIT, children: linkLabel || getTitleTranslation() }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MEDIUM })] }) }), onClick: openMiniPDP }) })] }) }), (0, jsx_runtime_1.jsx)(MiniPDPMultiContainer_1.default, { isOpen: isOpenMiniPDP, onClose: onMiniPDPClose, productsInfo: updatedProducts, popoverTitle: flyoutTitle, title: defaultTitle, mainWebshopId: mainWebshopId, isPDP: true })] }));
};
exports.default = ShopTheLookPDPContainer;
