"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const AvailabilityDescriptionContainer = ({ unavailableProduct, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const fulfillment = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const hdExcluded = Boolean(fulfillment === null || fulfillment === void 0 ? void 0 : fulfillment.hdExcluded);
    const ccExcluded = Boolean(fulfillment === null || fulfillment === void 0 ? void 0 : fulfillment.ccExcluded);
    const storeSourceExcluded = Boolean(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded);
    const fulfillmentCopyDescription = (fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded)
        ? ((_a = fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.fulfillmentCopy) === null || _a === void 0 ? void 0 : _a[lang.toUpperCase()]) || ''
        : (fulfillment === null || fulfillment === void 0 ? void 0 : fulfillment.fulfillmentCopyDescription) || '';
    const getKeyForText = (0, react_1.useCallback)(() => {
        if (unavailableProduct) {
            return t('pdp.productAvailability.notAvailable.title');
        }
        if ((hdExcluded || storeSourceExcluded) && !ccExcluded && !!fulfillmentCopyDescription) {
            return fulfillmentCopyDescription;
        }
        return '';
    }, [ccExcluded, hdExcluded, unavailableProduct, fulfillmentCopyDescription, storeSourceExcluded]);
    if (!getKeyForText()) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, priority: cotopaxi_1.HeadingPriority.H3, children: getKeyForText() }) }));
};
exports.default = AvailabilityDescriptionContainer;
