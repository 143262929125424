"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductGroupAnalytics = void 0;
const react_1 = require("react");
const useElementIsVisible_1 = require("./useElementIsVisible");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const useProductGroupAnalytics = (isEnabled, target, componentName, componentType, products = []) => {
    const type = componentType || 'none';
    const isVisible = (0, useElementIsVisible_1.useElementIsVisible)(target);
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        if (isEnabled && isVisible) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.productGroupViewed({ componentName, componentType: type, products }));
        }
    }, [isVisible]);
    const handleItemClickAnalytics = (productTilePosition, product) => {
        if (isEnabled) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.productGroupItemClick({
                componentName,
                componentType: type,
                productTilePosition,
                product,
            }));
        }
    };
    const getProductGroupAnalyticsData = (productTilePosition) => isEnabled
        ? {
            componentName,
            componentType: type,
            productTilePosition,
        }
        : undefined;
    return { handleItemClickAnalytics, getProductGroupAnalyticsData };
};
exports.useProductGroupAnalytics = useProductGroupAnalytics;
