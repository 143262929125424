"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandLister = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const BrandLister = ({ items }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.brand);
    const aemConfig = (0, common_components_1.useAemConfigurationContextWrapper)();
    const itemKeys = (0, common_utils_1.sortAlphabetically)(Object.keys(items));
    const getRelativePath = (relativePath) => `${aemConfig.isSingleLanguageSite ? '' : '/' + aemConfig.lang}/${relativePath}.html`;
    const getLogo = (item) => `${aemConfig.brandImageCdnUrl}${item.logo}?timestamp=${item.logoTimestamp}`;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: itemKeys.map((itemKey) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: { marginTop: 4, marginBottom: 3 },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, size: cotopaxi_1.HeadingSize.S, children: itemKey === common_types_1.BrandListerGroupedFilterTypes.POPULAR
                            ? t('brandlister.overview.popular.brands.title')
                            : itemKey }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: 4 } }, desktop: { spacing: { marginBottom: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.BrandGrid, { children: items[itemKey].map((item) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.BrandTile, { logo: getLogo(item), name: item.description, pagePath: getRelativePath(item.relativePath) }, item.id))) }) })] }, itemKey))) }));
};
exports.BrandLister = BrandLister;
