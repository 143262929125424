"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18N_NAMESPACE_FALLBACK = exports.I18N_NAMESPACES = exports.I18N_LANGUAGE_FALLBACK = exports.I18N_LANGUAGE_AVAILABLE = exports.I18N_TRANSLATIONS_BASE_PATH = void 0;
exports.I18N_TRANSLATIONS_BASE_PATH = '/etc.clientlibs/platform-asadventure/i18n/resources/components/{ns}/{lng}.json';
exports.I18N_LANGUAGE_AVAILABLE = ['nl', 'fr', 'en', 'de', 'nl_nl'];
exports.I18N_LANGUAGE_FALLBACK = 'nl';
exports.I18N_NAMESPACES = {
    general: 'general',
    product: 'product',
    store: 'store',
    form: 'form',
    dpe: 'dpe',
    checkout: 'checkout',
    buy: 'buy',
    account: 'account',
    navigation: 'navigation',
    rental: 'rental',
    seo: 'seo',
    brand: 'brand',
};
exports.I18N_NAMESPACE_FALLBACK = 'general';
