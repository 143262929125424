"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationProductsList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const asNumeral_1 = require("../../../vendors/asNumeral");
const BasketProductItem_1 = require("../../basket/basket-product-item/BasketProductItem");
const VoucherUtil_1 = require("../../../util/VoucherUtil");
const CartTotalTable_1 = require("../../../components/cart/cart-total-table/CartTotalTable");
const BuyConfirmationProductsList = ({ products, pricing, vouchers, currency, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, desktop: { spacing: { marginBottom: 3 } }, children: [products.map((product, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BasketProductItem_1.BasketProductItem, { basketProductData: product, tileType: "simple", dataQA: "product_tile", withOriginalSize: true }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }, `${product.productCode}_${index}`))), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(CartTotalTable_1.CartTotalTable, { currency: currency, totalBasketDiscountAmount: pricing.totalBasketDiscountAmount && pricing.totalPriceSellBeforeDiscount
                        ? asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.totalBasketDiscountAmount)
                        : '0', totalPriceSell: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.totalPriceSellBeforeDiscount || pricing.totalPriceSell), priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.priceTotalBasket), priceDelivery: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.priceDelivery), vouchers: VoucherUtil_1.default.mapBasketAppliedVouchersToAppliedVouchers(vouchers, currency, t) }) })] }));
};
exports.BuyConfirmationProductsList = BuyConfirmationProductsList;
