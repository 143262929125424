"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_dom_1 = require("react-dom");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const MobileNavigation_1 = require("../components/navigation/mobile-navigation/MobileNavigation");
const useCategoryHierarchyTree_1 = require("../hooks/useCategoryHierarchyTree");
const MobileNavigation_util_1 = require("../util/MobileNavigation.util");
const useABTest_1 = require("../hooks/useABTest");
const EventEnum_1 = require("../enums/EventEnum");
const useForceUpdate_1 = require("../hooks/useForceUpdate");
const MobileNavigationContainer = ({ menuConfiguration, currencies, enableABTest, searchConfig, accountConfig, }) => {
    var _a;
    const forceUpdate = (0, useForceUpdate_1.useForceUpdate)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const { categoryIds, categoryHierarchyTree } = (0, useCategoryHierarchyTree_1.useCategoryHierarchyTree)();
    const mobileNavigationABTest = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.MOBILE_NAVIGATION);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const navigationData = (0, react_1.useMemo)(() => (0, MobileNavigation_util_1.getMobileNavigationData)(menuConfiguration, t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.home`)), [menuConfiguration]);
    (0, react_1.useEffect)(() => {
        if (!isClient && mobileNavigationABTest) {
            return;
        }
        window.addEventListener(EventEnum_1.EventEnum.TARGET_MOBILE_NAV_DEPLOYED, forceUpdate);
        () => window.removeEventListener(EventEnum_1.EventEnum.TARGET_MOBILE_NAV_DEPLOYED, forceUpdate);
    }, [isClient, mobileNavigationABTest]);
    if (!navigationData)
        return null;
    if (enableABTest) {
        if (mobileNavigationABTest && isClient) {
            const navigationTrigger = document.querySelector('.as-o-menu--state-toggle__backdrop');
            if (navigationTrigger) {
                const navigationCheckbox = document.getElementById('main-menu-toggle');
                const menuIcon = navigationTrigger.querySelector('.as-a-icon');
                navigationTrigger.removeAttribute('for');
                menuIcon.style.display = 'none';
                if (navigationCheckbox === null || navigationCheckbox === void 0 ? void 0 : navigationCheckbox.checked) {
                    (_a = window.navigationMobileMenuEnhancerToggleOff) === null || _a === void 0 ? void 0 : _a.call(window);
                    navigationCheckbox.checked = false;
                }
                return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(MobileNavigation_1.MobileNavigation, { isABTestEnabled: true, accountConfig: accountConfig, searchConfig: searchConfig, menuItems: navigationData, currencies: currencies, defaultLevel: (0, MobileNavigation_util_1.getDefaultNavigationLevel)(categoryHierarchyTree, categoryIds, lang, navigationData) }), navigationTrigger);
            }
        }
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(MobileNavigation_1.MobileNavigation, { searchConfig: searchConfig, accountConfig: accountConfig, menuItems: navigationData, currencies: currencies, defaultLevel: (0, MobileNavigation_util_1.getDefaultNavigationLevel)(categoryHierarchyTree, categoryIds, lang, navigationData) }));
};
exports.default = MobileNavigationContainer;
