"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationStickyBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const cotopaxi_1 = require("@as/cotopaxi");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const NavigationStickyBar = ({ children, isHidden }) => {
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)(null);
    const setIsStickyNavPresent = (state) => {
        SITE.data = SITE.data || {};
        SITE.data.isStickyNavPresent = state;
    };
    const getIsStickyNavPresent = () => {
        var _a;
        return !!((_a = SITE === null || SITE === void 0 ? void 0 : SITE.data) === null || _a === void 0 ? void 0 : _a.isStickyNavPresent);
    };
    (0, react_1.useEffect)(() => {
        const stickyNavExists = getIsStickyNavPresent();
        if (!stickyNavExists) {
            setIsStickyNavPresent(true);
            const navigation = document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL);
            setNavigationEl(navigation);
            // This needed because the navigation menu gets hidden after the page loads with a delay
            setTimeout(() => setShouldRender(true), GeneralConstants_1.DELAY.DELAY_100);
        }
        return () => {
            setIsStickyNavPresent(false);
        };
    }, []);
    const stickyRef = (0, react_1.useCallback)((0, cotopaxi_1.useDebounce)((node) => {
        if (!node) {
            return;
        }
        const mainContent = document.querySelector(DOMElementsConstants_1.MAIN_CONTENT_CONTAINER);
        const headerContainer = document.querySelector(DOMElementsConstants_1.HEADER_CONTAINER);
        if (isHidden) {
            headerContainer === null || headerContainer === void 0 ? void 0 : headerContainer.style.removeProperty('margin-top');
            mainContent === null || mainContent === void 0 ? void 0 : mainContent.style.removeProperty('padding-top');
            return;
        }
        if (headerContainer) {
            headerContainer.style.marginTop = `${node.offsetHeight}px`;
        }
        else if (mainContent) {
            mainContent.style.paddingTop = `${node.offsetHeight}px`;
        }
    }, GeneralConstants_1.DELAY.DELAY_100), [isHidden]);
    if (!shouldRender || !navigationEl) {
        return null;
    }
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { innerRef: stickyRef, isHidden: isHidden, variant: cotopaxi_1.StickyBarVariant.NAVIGATION, withNavBar: true, children: children }), navigationEl);
};
exports.NavigationStickyBar = NavigationStickyBar;
