"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EventUtil {
    static dispatch(eventType, params) {
        if (!!document && !!document.body) {
            const event = new CustomEvent(eventType, { detail: params });
            document.body.dispatchEvent(event);
        }
    }
    static listen(eventType, callBack, bubbles) {
        try {
            if (!!document && !!document.body) {
                document.body.addEventListener(eventType, callBack, bubbles);
            }
        }
        catch (e) { }
    }
    static remove(eventType, callBack) {
        try {
            if (!!document && !!document.body) {
                document.body.removeEventListener(eventType, callBack);
            }
        }
        catch (e) { }
    }
}
exports.default = EventUtil;
