"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const ContentSelectors_1 = require("../../redux/content/selectors/ContentSelectors");
const FilterSelectors_1 = require("../../redux/filter/selectors/FilterSelectors");
const FilterActions_creators_1 = require("../../redux/filter/actions/FilterActions.creators");
const i18nConstants_1 = require("../../constants/i18nConstants");
const Filter_1 = require("../../components/lister/filters/Filter");
const FilterContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const dispatch = (0, react_redux_1.useDispatch)();
    const filters = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentFilters)());
    const activeFilters = (0, react_redux_1.useSelector)((0, FilterSelectors_1.makeGetActiveFilters)());
    const totalItems = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentTotalElements)());
    const getContentFilters = (contentFilters) => contentFilters.map((filter) => (Object.assign(Object.assign({}, filter), { name: t(filter.name) })));
    const onFilterItemChange = (toggledFilter) => dispatch(FilterActions_creators_1.filterActions.updateFilters(toggledFilter));
    const removeAllFilters = () => dispatch(FilterActions_creators_1.filterActions.removeAllFilters());
    const contentFilters = (0, react_1.useMemo)(() => getContentFilters(filters), [filters]);
    return ((0, jsx_runtime_1.jsx)(Filter_1.Filter, { isContentTileLister: true, filters: contentFilters, activeFilters: activeFilters, removeAllFilters: removeAllFilters, onFilterItemChange: onFilterItemChange, itemCount: totalItems }));
};
exports.FilterContainer = FilterContainer;
