"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ComparePopup_1 = require("../components/product-detail/compare-popup/ComparePopup");
const CompareSelectors_1 = require("../redux/compare/selectors/CompareSelectors");
const CompareActions_creators_1 = require("../redux/compare/actions/CompareActions.creators");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const ComparePopupContainer = ({ compareState, isStickyBar, toggleComparePopup, openComparePage, updateCompareList, toggleLimitReached, }) => {
    const configuration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const root = (0, ConfigurationUtil_1.getRoot)(configuration);
    const isClient = (0, cotopaxi_1.useIsClient)();
    if (!isClient || compareState.items.length === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(ComparePopup_1.default, { compareState: compareState, root: root, isStickyBar: isStickyBar, toggleComparePopup: toggleComparePopup, openComparePage: openComparePage, updateCompareList: updateCompareList, toggleLimitReached: toggleLimitReached }));
};
const makeMapStateToProps = () => {
    const getCompareState = (0, CompareSelectors_1.makeGetCompareState)();
    return (state) => ({
        compareState: getCompareState(state),
    });
};
const mapDispatchToProps = {
    updateCompareList: CompareActions_creators_1.default.createUpdateInProductCompareBoxAction,
    toggleComparePopup: CompareActions_creators_1.default.createPopupToggleAction,
    openComparePage: CompareActions_creators_1.default.createPageOpenAction,
    toggleLimitReached: CompareActions_creators_1.default.createToggleLimitReachedAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(ComparePopupContainer);
