"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthReducer_1 = require("../../authentication/reducers/AuthReducer");
const AuthSaga_1 = require("../../authentication/sagas/AuthSaga");
const AuthFormReducer_1 = require("../../authForm/reducers/AuthFormReducer");
const AuthFormSaga_1 = require("../../authForm/sagas/AuthFormSaga");
const BasketReducer_1 = require("../../basket/reducers/BasketReducer");
const BasketSagas_1 = require("../../basket/sagas/BasketSagas");
const loadGiftVoucherDynamicModule = () => ({
    id: 'GiftVoucher',
    reducerMap: {
        authentication: AuthReducer_1.authReducer,
        authForm: AuthFormReducer_1.authFormReducer,
        basket: BasketReducer_1.basketReducer,
    },
    sagas: [AuthSaga_1.default, AuthFormSaga_1.default, BasketSagas_1.default],
});
exports.default = loadGiftVoucherDynamicModule;
