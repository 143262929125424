"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18nConfigurationGeneral = void 0;
const i18nConstants_1 = require("../../constants/i18nConstants");
exports.i18nConfigurationGeneral = {
    fallbackLng: i18nConstants_1.I18N_LANGUAGE_FALLBACK,
    supportedLngs: i18nConstants_1.I18N_LANGUAGE_AVAILABLE,
    load: 'all',
    // have a common namespace used around the full app
    // ns: ['account', 'brand', 'buy', 'checkout', 'form', 'general', 'navigation', 'product', 'social-sharing-bar', 'store'],
    ns: Object.values(i18nConstants_1.I18N_NAMESPACES),
    defaultNS: i18nConstants_1.I18N_NAMESPACE_FALLBACK,
    debug: false,
    compatibilityJSON: 'v1',
    keySeparator: '***',
    interpolation: {
        prefix: '{',
        suffix: '}',
        escapeValue: false, // not needed for react!!
    },
    react: {
        // TODO: remove deprecated wait prop
        // @ts-ignore
        wait: true,
        bindI18n: 'languageChanged loaded',
        nsMode: 'default',
        useSuspense: false,
    },
    backend: {
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: i18nConstants_1.I18N_TRANSLATIONS_BASE_PATH,
        // path to post missing resources
        addPath: i18nConstants_1.I18N_TRANSLATIONS_BASE_PATH,
        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
        // allow cross domain requests
        crossDomain: true,
        // allow credentials on cross domain requests
        withCredentials: false,
        // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
        queryStringParams: {},
    },
    cache: {
        // turn on or off
        enabled: false,
        // prefix for stored languages
        prefix: 'i18next_res_',
        // expiration
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        // language versions
        versions: {},
    },
};
