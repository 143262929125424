"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsBasic = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const common_types_1 = require("@as-react/common-types");
const addressFields_utils_1 = require("../addressFields.utils");
const andes_react_1 = require("@yonderland/andes-react");
const FormField_1 = require("../../FormField");
const AddressFieldsBasic = ({ prefix, isRequired = true }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [addressName, addressLine2Name, postCodeName, cityName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: addressName, maxLength: 50, required: isRequired, label: t('address.line1'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "address_line1" })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressLine2Name, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: addressLine2Name, maxLength: 500, label: t('address.line2'), optionalText: t('form.field.optional'), dataTestId: "address_line2" })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: postCodeName, maxLength: 50, required: isRequired, label: t('address.postCode'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "postcode" })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: cityName, maxLength: 50, required: isRequired, label: t('address.city'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "town_city" })));
                    } }) })] }));
};
exports.AddressFieldsBasic = AddressFieldsBasic;
