"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialSearchResultsTitles = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const INITIAL_QUERY_PLACEHOLDER = '{{{INITIAL QUERY}}}';
const ALTERNATIVE_BRAND_PLACEHOLDER = '{{{ALTERNATIVE BRAND}}}';
const PartialSearchResultsTitles = ({ alternativeBrand, maybeMeant, maybeLike, executionStatus, alternativeQueryResults, initialSearchTerm, }) => {
    const getSuggestedSearchTerm = () => alternativeQueryResults[0].alternativeQuery;
    const getTitles = () => {
        let titles = [];
        switch (executionStatus) {
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_BRAND:
                titles = alternativeBrand.title.split(ALTERNATIVE_BRAND_PLACEHOLDER);
                titles[0] = titles[0].replace(INITIAL_QUERY_PLACEHOLDER, initialSearchTerm);
                titles[1] = getSuggestedSearchTerm();
                return titles;
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_QUERIES:
                titles = maybeMeant.title.split(INITIAL_QUERY_PLACEHOLDER);
                titles.splice(1, 0, initialSearchTerm);
                return titles;
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_SPELL_UNCHANGED:
                titles = maybeLike.title.split(INITIAL_QUERY_PLACEHOLDER);
                titles.splice(1, 0, initialSearchTerm);
                return titles;
        }
        return titles;
    };
    const getDescription = () => {
        switch (executionStatus) {
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_BRAND:
                return alternativeBrand.description.replace(ALTERNATIVE_BRAND_PLACEHOLDER, getSuggestedSearchTerm());
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_QUERIES:
                return maybeMeant.description;
            case common_types_1.SearchResultsExecutionStatusEnums.ALT_SPELL_UNCHANGED:
                return maybeLike.description;
            default:
                return '';
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginTop: 0, marginBottom: 2 } }, tablet: { spacing: { marginTop: 2, marginBottom: 2 } }, mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: [getTitles()[0], getTitles()[1] && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, children: ["\u201C", getTitles()[1], "\u201D"] })), getTitles()[2]] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, size: cotopaxi_1.TextSize.MEDIUM, children: getDescription() })] }));
};
exports.PartialSearchResultsTitles = PartialSearchResultsTitles;
