"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumber = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PhoneNumber = ({ required, defaultCountry, errors, touched, values, initialPhoneNumber, showSavePhoneNumberCheckbox, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 6, gutter: false, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, tablet: { spacing: { marginRight: 1 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: required, emphasized: true, htmlFor: "buy-address-field-phone-number", size: cotopaxi_1.LabelSize.MINOR, children: t('account:account.details.mobilephone') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: required, id: "buy-address-field-phone-number", name: "phoneNumber", defaultCountry: defaultCountry, maxLength: 50, state: (errors === null || errors === void 0 ? void 0 : errors.phoneNumber) && (touched === null || touched === void 0 ? void 0 : touched.phoneNumber) && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.InputPhoneNumber, componentType: cotopaxi_1.FormFieldType.PHONE })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: "N700", children: t('checkout:checkout.phone.info') })] }), showSavePhoneNumberCheckbox &&
                (values === null || values === void 0 ? void 0 : values.phoneNumber) &&
                !(errors === null || errors === void 0 ? void 0 : errors.phoneNumber) &&
                initialPhoneNumber !== (values === null || values === void 0 ? void 0 : values.phoneNumber) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 6, gutter: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, tablet: { spacing: { marginTop: 4, marginLeft: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-savePhoneNumber", name: "savePhoneNumber", labelChildren: t('checkout:checkout.phone.save'), componentType: cotopaxi_1.FormFieldType.CHECKBOX, Component: cotopaxi_1.CheckboxWithLabel }) }) }))] }));
};
exports.PhoneNumber = PhoneNumber;
