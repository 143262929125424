"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const AddToBasketButton_1 = require("../components/product-detail/add-to-basket-button/AddToBasketButton");
const AddToWishlistButton_1 = require("../components/product-detail/add-to-wishlist-button/AddToWishlistButton");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const PriceAmount_1 = require("../components/product-detail/price-amount/PriceAmount");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const _PriceAmountWithCTAContainer = ({ isShopLanding, isBasketPopupCollapsed, productColorIsInWishList, productDefaults: { productCode, productId }, addGiftCardToBasket, selectedColorId, toggleWishList, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const { values: { min: minAmount, max: maxAmount, productSize: giftCardSizeCode }, } = (0, ConfigurationUtil_1.getGiftCardConfig)(aemConfiguration);
    const [error, setError] = (0, react_1.useState)();
    const [amount, setAmount] = (0, react_1.useState)('');
    const [isAmountInputTouched, setIsAmountInputTouched] = (0, react_1.useState)(false);
    const wishListProduct = {
        colorId: selectedColorId,
        productCode: productCode,
        productId: parseInt(productId, 10),
    };
    const validateAmount = (amountValidate) => {
        let errorValidate = '';
        if (amountValidate > Number(maxAmount) || amountValidate < Number(minAmount)) {
            errorValidate = 'general:giftcard.charge';
        }
        else if (!amount) {
            errorValidate = 'checkout:checkout.required';
        }
        return errorValidate;
    };
    const onAmountInputBlur = () => {
        setIsAmountInputTouched(true);
        setError(validateAmount(parseFloat(amount)));
    };
    const onAmountChange = (e) => {
        const amountOf = e.target.value;
        setAmount(amountOf);
        if (isAmountInputTouched) {
            setError(validateAmount(parseFloat(amountOf)));
        }
    };
    const addToBasket = (quantity) => {
        const erroBasket = validateAmount(parseFloat(amount));
        setError(erroBasket);
        if (!erroBasket) {
            addGiftCardToBasket({
                giftCardSizeCode,
                quantity,
                amount: parseFloat(amount),
            });
        }
    };
    (0, react_1.useEffect)(() => {
        if (isBasketPopupCollapsed) {
            setAmount('');
            setIsAmountInputTouched(false);
        }
    }, [isBasketPopupCollapsed]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(PriceAmount_1.PriceAmount, { error: error, amount: amount, currency: currency, minAmount: minAmount, maxAmount: maxAmount, onInputBlur: onAmountInputBlur, onAmountChange: onAmountChange }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, children: [(0, jsx_runtime_1.jsx)(AddToBasketButton_1.AddToBasketButton, { onClick: addToBasket }), !isShopLanding && ((0, jsx_runtime_1.jsx)(AddToWishlistButton_1.default, { isProductInWishList: productColorIsInWishList, onToggle: toggleWishList, wishListProduct: wishListProduct }))] })] }));
};
const mapDispatchToProps = {
    addGiftCardToBasket: BasketActions_creators_1.basketActions.createAddGiftCardAction,
    toggleWishList: WishListActions_creators_1.wishListActions.toggleProduct,
};
const makeMapStateToProps = () => {
    const getBasketPopupCollapseState = (0, BasketSelector_1.makeGetBasketPopupCollapseState)();
    const getProductColorIsInWishList = (0, WishListSelectors_1.makeGetProductColorIsInWishList)();
    const getProductDefaults = (0, ProductSelector_1.makeGetProductDefaults)();
    const getSelectedColorId = (0, ProductSelector_1.makeGetSelectedColorId)();
    return (state) => ({
        isBasketPopupCollapsed: getBasketPopupCollapseState(state),
        productColorIsInWishList: getProductColorIsInWishList(state),
        productDefaults: getProductDefaults(state),
        selectedColorId: getSelectedColorId(state),
    });
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(_PriceAmountWithCTAContainer);
