"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const ProceedCheckoutCTA = ({ checkoutInit, scrollToStoreSourceExcludedProduct, isOpenedCollapseBlock, productsAmount, isCheckoutFlowDisabled, isApplePayAvailable, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(configuration);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [isHidden, setHiddenStickyBar] = (0, react_1.useState)(true);
    const [buttonPosition, setButtonPosition] = (0, react_1.useState)(0);
    const [clientHeight, setClientHeight] = (0, react_1.useState)(0);
    const [proceedButton, setProceedButton] = (0, react_1.useState)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const getUrl = () => `${root}buy.html`;
    const updatePosition = () => {
        if (proceedButton) {
            const top = proceedButton.getBoundingClientRect().top + proceedButton.getBoundingClientRect().height + window.pageYOffset;
            setButtonPosition(top);
        }
    };
    const handleSetHidden = () => {
        const isHiddenStickyBar = proceedButton && proceedButton.getBoundingClientRect().bottom < window.innerHeight;
        setHiddenStickyBar(Boolean(isHiddenStickyBar));
    };
    const proceedButtonRef = (0, react_1.useCallback)((node) => {
        if (node !== null) {
            setProceedButton(node);
        }
    }, []);
    const updateWithDelay = (0, cotopaxi_1.useDebounce)(() => {
        setClientHeight(window.innerHeight);
        updatePosition();
    }, GeneralConstants_1.DELAY.DELAY_20);
    (0, react_1.useEffect)(() => {
        updatePosition();
    }, [proceedButton, productsAmount, isMobile, isDesktop, isOpenedCollapseBlock]);
    (0, react_1.useEffect)(() => {
        updatePosition();
        window.addEventListener('resize', updateWithDelay);
        return () => window.removeEventListener('resize', updatePosition);
    }, []);
    (0, react_1.useEffect)(() => {
        handleSetHidden();
    }, [buttonPosition, clientHeight]);
    (0, cotopaxi_1.useScrollPosition)(handleSetHidden, [buttonPosition]);
    const onClickCheckout = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        checkoutInit({
            hrefCheckout: getUrl(),
        });
    };
    const getStoreSourceExcludedMessage = (withoutLink, textSize = cotopaxi_1.TextSize.SMALL) => {
        if (withoutLink) {
            return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, size: textSize, children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.general`), t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.edit.part2`), t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.edit.part3`)] }));
        }
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, size: textSize, children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.general`), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: scrollToStoreSourceExcludedProduct, text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.edit.part2`), variant: cotopaxi_1.ButtonVariant.LINK }), t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.edit.part3`)] }));
    };
    if (!isClient) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 12, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                paddingBottom: isApplePayAvailable ? 1 : 2,
                                paddingTop: 2,
                            },
                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "checkout_button", innerRef: proceedButtonRef, fill: true, href: isCheckoutFlowDisabled ? undefined : getUrl(), onClick: onClickCheckout, variant: cotopaxi_1.ButtonVariant.BRANDED, state: isCheckoutFlowDisabled ? cotopaxi_1.ButtonState.DISABLED : undefined, text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.order`), iconRight: {
                                    name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                    size: cotopaxi_1.IconSize.MAJOR,
                                } }), isCheckoutFlowDisabled && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, children: getStoreSourceExcludedMessage(isDesktop) }))] }) }) }), !isDesktop && ((0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { isHidden: isHidden, color: cotopaxi_1.StickyBarColor.LIGHT_GRAY, variant: cotopaxi_1.StickyBarVariant.SIMPLE_BLOCK, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: {
                        spacing: {
                            paddingLeft: 1.5,
                            paddingRight: 1.5,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 12, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: {
                                        paddingBottom: 1,
                                        paddingTop: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                    },
                                }, tablet: {
                                    spacing: {
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, href: isCheckoutFlowDisabled ? undefined : getUrl(), onClick: onClickCheckout, variant: cotopaxi_1.ButtonVariant.BRANDED, text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.order`), state: isCheckoutFlowDisabled ? cotopaxi_1.ButtonState.DISABLED : undefined, iconRight: {
                                        name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                        size: cotopaxi_1.IconSize.MAJOR,
                                    } }) }) }) }) }) }))] }));
};
exports.default = ProceedCheckoutCTA;
