"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Product_module_1 = require("./modules/Product.module");
const ProductDetail_module_1 = require("./modules/ProductDetail.module");
const ProductContentVideo_module_1 = require("./modules/ProductContentVideo.module");
const Customer_module_1 = require("./modules/Customer.module");
const Monetate_module_1 = require("./modules/Monetate.module");
const loadProductDetailModule = initialData => ({
    hasRouter: true,
    modules: [
        (0, Product_module_1.default)(initialData),
        (0, ProductDetail_module_1.default)(),
        (0, ProductContentVideo_module_1.default)(),
        (0, Customer_module_1.default)(),
        (0, Monetate_module_1.default)(),
    ],
});
exports.default = loadProductDetailModule;
