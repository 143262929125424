"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickFilterRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const QuickFilterItem_1 = require("./QuickFilterItem");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const QuickFilterRow = ({ quickLinks }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, children: t('product.lister.quickFilters.title') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { direction: cotopaxi_1.ScrollDirection.HORIZONTAL, variant: cotopaxi_1.ScrollableListVariant.GRADIENT, touchOnly: true, dataAnalyticsBannerid: "product-lister-quicklinks", children: quickLinks.map((quickLink, i, { length }) => {
                        const lastItem = i + 1 === length;
                        return ((0, jsx_runtime_1.jsx)(QuickFilterItem_1.QuickFilterItem, { quickLink: quickLink, showSpace: !lastItem }, `quickLink_${quickLink.linkName}`));
                    }) }) })] }));
};
exports.QuickFilterRow = QuickFilterRow;
