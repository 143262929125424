"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWishListListerProductsSaga = exports.wishlistLogoutSaga = exports.wishlistLoginSaga = exports.removeBasketItemFromWishlist = exports.updateWishListPage = exports.getWishListListerProducts = exports.handleWishListLogout = exports.handleWishListLogin = exports.removeProductFromWishList = exports.addProductToWishList = exports.toggleWishListProduct = exports.toggleWishListProductSaga = exports.getWishListProducts = exports.getWishListProductsFromStore = exports.isTimeStampLastUpdatedValid = exports.getWishListProductsSaga = exports.getCurrentPage = void 0;
const effects_1 = require("redux-saga/effects");
const WishListActions_creators_1 = require("../actions/WishListActions.creators");
const WishListApi_1 = require("../../../api/wish-list/WishListApi");
const WishListUtil_1 = require("../../../util/WishListUtil");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const EventUtil_1 = require("../../../util/EventUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const dayjs = require("dayjs");
const common_utils_1 = require("@as-react/common-utils");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const common_types_1 = require("@as-react/common-types");
const WishListSelectors_1 = require("../selectors/WishListSelectors");
const ProductUtil_1 = require("../../../util/ProductUtil");
const CategoryAPI_1 = require("../../../api/CategoryAPI");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const common_queries_1 = require("@as-react/common-queries");
const QueryClientUtil_1 = require("../../../util/QueryClientUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const WISHLIST_TILES_DEFAULT_COUNT = 30;
const getCurrentPage = (state) => {
    var _a, _b;
    // @ts-expect-error: "query" parameter is missing in type definitions of connected-react-router package.
    return parseInt((_b = (_a = state.router.location) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.page, 10) || 0;
};
exports.getCurrentPage = getCurrentPage;
function* getWishListProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductRecentlyViewedActionTypes.FETCH, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ListerActionTypes.LISTER_INIT, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.INIT, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.SingleProductTileActionTypes.SINGLE_PRODUCT_TILE_INIT, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductGroupFredhopperTypes.SET_FREDHOPPER_DATA, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.GET_PRODUCTS, getWishListProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LISTER_INIT, getWishListListerProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LISTER_UPDATE_PAGE, updateWishListPage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.MonetateActionTypes.SET_PRODUCTS, getWishListProducts);
}
exports.getWishListProductsSaga = getWishListProductsSaga;
const isTimeStampLastUpdatedValid = (date) => {
    const TIME_UNIT = 'minute';
    const LOCAL_WISH_LIST_EXPIRATION_TIME = 15;
    return dayjs(date).isValid() && dayjs(date).add(LOCAL_WISH_LIST_EXPIRATION_TIME, TIME_UNIT).isAfter(dayjs());
};
exports.isTimeStampLastUpdatedValid = isTimeStampLastUpdatedValid;
const getWishListProductsFromStore = (state) => state.wishList.products;
exports.getWishListProductsFromStore = getWishListProductsFromStore;
function* getWishListProducts() {
    var _a, _b, _c, _d, _e;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const isShopLanding = yield (0, effects_1.call)(ConfigurationUtil_1.getShoplandingEnabledFlag, aemConfigurationContext);
    if (isShopLanding) {
        return;
    }
    try {
        if (((_a = aemConfigurationContext.pageInfo) === null || _a === void 0 ? void 0 : _a.isWishlistPage) ||
            ((_b = aemConfigurationContext.pageInfo) === null || _b === void 0 ? void 0 : _b.isSearchPage) ||
            ((_c = aemConfigurationContext.pageInfo) === null || _c === void 0 ? void 0 : _c.isCategoryPage) ||
            ((_d = aemConfigurationContext.pageInfo) === null || _d === void 0 ? void 0 : _d.isPDPPage) ||
            localStorage.getItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY) === null ||
            !(0, exports.isTimeStampLastUpdatedValid)(JSON.parse(localStorage.getItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY)).timeStampLastUpdated)) {
            const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
            const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
            const isShopLanding = yield (0, effects_1.call)(ConfigurationUtil_1.getShoplandingEnabledFlag, aemConfigurationContext);
            const { getWishlistProducts } = (0, common_queries_1.wishlistQueries)({
                servicesEndpoint,
                defaultRequestParams,
            });
            const wishListProducts = yield QueryClientUtil_1.default.fetchQuery(getWishlistProducts({
                showOutOfStock: !isShopLanding,
            }, { staleTime: 1000 }));
            const localStorageWishList = {
                products: wishListProducts !== null && wishListProducts !== void 0 ? wishListProducts : [],
                timeStampLastUpdated: new Date(),
            };
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY, localStorageWishList);
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.updateWishListProducts(wishListProducts));
        }
        else {
            const wishListProducts = ((_e = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY)) === null || _e === void 0 ? void 0 : _e.products) || [];
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.updateWishListProducts(wishListProducts));
        }
    }
    catch (_f) { }
}
exports.getWishListProducts = getWishListProducts;
function* toggleWishListProductSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.TOGGLE_PRODUCT, toggleWishListProduct);
}
exports.toggleWishListProductSaga = toggleWishListProductSaga;
function* toggleWishListProduct(action) {
    var _a, _b;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const wishListProducts = yield (0, effects_1.select)(exports.getWishListProductsFromStore);
        const productToSet = action.payload;
        if ((0, WishListUtil_1.isProductInWishList)(wishListProducts, productToSet.productId, productToSet.colorId)) {
            yield (0, effects_1.fork)(updateWishListAnalytics, productToSet, ActionEnums_1.WishListActionTypes.ANALYTICS_REMOVE);
            yield (0, effects_1.fork)(removeProductFromWishList, WishListActions_creators_1.wishListActions.removeProduct(productToSet));
            if (!((_a = aemConfigurationContext.pageInfo) === null || _a === void 0 ? void 0 : _a.isWishlistPage)) {
                yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.removeProduct(productToSet));
            }
        }
        else {
            yield (0, effects_1.fork)(updateWishListAnalytics, productToSet, ActionEnums_1.WishListActionTypes.ANALYTICS_ADD);
            yield (0, effects_1.fork)(addProductToWishList, WishListActions_creators_1.wishListActions.addProduct(productToSet));
            if (!((_b = aemConfigurationContext.pageInfo) === null || _b === void 0 ? void 0 : _b.isWishlistPage)) {
                yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.addProduct(productToSet));
            }
        }
    }
    catch (_c) { }
}
exports.toggleWishListProduct = toggleWishListProduct;
function* updateWishListAnalytics(product, updateType) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const payload = {
            isPDP: aemConfigurationContext.pageInfo.isPDPPage,
            isWishlistPage: aemConfigurationContext.pageInfo.isWishlistPage,
            product,
        };
        yield (0, effects_1.put)({
            type: updateType,
            payload,
        });
    }
    catch (_a) { }
}
function* addProductToWishList(action) {
    var _a, _b;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const authenticationConfig = yield (0, effects_1.call)(SagaContextSelectors_1.getAuthenticationConfig, aemConfigurationContext);
        yield (0, effects_1.call)(WishListApi_1.default.addProductToWishList, action.payload, servicesEndpoint, authenticationConfig);
        const localStorageWishList = {
            products: ((_a = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY)) === null || _a === void 0 ? void 0 : _a.products) || [],
            timeStampLastUpdated: new Date(),
        };
        localStorageWishList.products.push(action.payload);
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY, localStorageWishList);
        if ((_b = aemConfigurationContext.pageInfo) === null || _b === void 0 ? void 0 : _b.isWishlistPage) {
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.addProduct(action.payload));
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.WISHLIST_CHANGED);
        }
    }
    catch (_c) { }
}
exports.addProductToWishList = addProductToWishList;
function* removeProductFromWishList(action) {
    var _a, _b;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const authenticationConfig = yield (0, effects_1.call)(SagaContextSelectors_1.getAuthenticationConfig, aemConfigurationContext);
        yield (0, effects_1.call)(WishListApi_1.default.removeProductFromWishList, action.payload, servicesEndpoint, authenticationConfig);
        const localStorageWishList = {
            products: ((_a = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY)) === null || _a === void 0 ? void 0 : _a.products) || [],
            timeStampLastUpdated: new Date(),
        };
        localStorageWishList.products = localStorageWishList.products.filter((product) => product.productId !== action.payload.productId || product.colorId !== action.payload.colorId);
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY, localStorageWishList);
        if ((_b = aemConfigurationContext.pageInfo) === null || _b === void 0 ? void 0 : _b.isWishlistPage) {
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.removeProduct(action.payload));
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.WISHLIST_CHANGED);
        }
    }
    catch (_c) { }
}
exports.removeProductFromWishList = removeProductFromWishList;
function* handleWishListLogin() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const authenticationConfig = yield (0, effects_1.call)(SagaContextSelectors_1.getAuthenticationConfig, aemConfigurationContext);
        yield (0, effects_1.call)(WishListApi_1.default.login, servicesEndpoint, authenticationConfig);
        yield (0, effects_1.call)(LocalStorageUtil_1.default.removeItem, StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY);
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.loggedIn());
    }
    catch (_a) { }
}
exports.handleWishListLogin = handleWishListLogin;
function* handleWishListLogout() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const authenticationConfig = yield (0, effects_1.call)(SagaContextSelectors_1.getAuthenticationConfig, aemConfigurationContext);
        yield (0, effects_1.call)(WishListApi_1.default.logout, servicesEndpoint, authenticationConfig);
        yield (0, effects_1.call)(LocalStorageUtil_1.default.removeItem, StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY);
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.loggedOut());
    }
    catch (_a) { }
}
exports.handleWishListLogout = handleWishListLogout;
function* getWishListCategory(wishListItem) {
    if (!(0, ProductUtil_1.isProductOutOfStock)(wishListItem) || !wishListItem.defaultCategoryId) {
        return wishListItem;
    }
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const category = yield (0, effects_1.call)(CategoryAPI_1.default.getById, servicesEndpoint, defaultRequestParams, String(wishListItem.defaultCategoryId));
    return Object.assign(Object.assign({}, wishListItem), { category });
}
function* getWishListAvailability(wishListItems) {
    try {
        const productIds = wishListItems.map(item => item.productId);
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        if (productIds.length > 0) {
            const availabilities = yield (0, effects_1.call)(ProductDetailAPI_1.default.getProductAvailabilitiesByProductId, servicesEndpoint, defaultRequestParams.mainWebshop, productIds.join(','));
            if (availabilities) {
                return (0, common_utils_1.mergeProductAvailabilitiesToProductLister)(wishListItems, availabilities);
            }
        }
    }
    catch (_a) { }
    return wishListItems;
}
function* getWishListListerProducts() {
    try {
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.setFetchStatus(common_types_1.FetchStatus.PENDING));
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const isShopLanding = yield (0, effects_1.call)(ConfigurationUtil_1.getShoplandingEnabledFlag, aemConfigurationContext);
        const { isWishlistPage } = yield (0, effects_1.call)(SagaContextSelectors_1.getPageInfo, aemConfigurationContext);
        const currentPage = yield (0, effects_1.select)(exports.getCurrentPage);
        const wishlistListerProducts = yield (0, effects_1.call)(WishListApi_1.default.getWishlistListerProducts, servicesEndpoint, defaultRequestParams, !isShopLanding ? WISHLIST_TILES_DEFAULT_COUNT : undefined, !isShopLanding ? currentPage : undefined, !isShopLanding);
        const mappedItems = yield (0, effects_1.all)(wishlistListerProducts.items.map(wishlistItem => getWishListCategory(wishlistItem)));
        const itemsWithAvailability = yield (0, effects_1.call)(getWishListAvailability, mappedItems);
        const prices = yield ProductDetailAPI_1.default.getAllProductDetailPriceByProductId(mappedItems.map(product => product.productId), servicesEndpoint, defaultRequestParams);
        const itemsWithPrices = itemsWithAvailability.map(product => {
            const productPrice = prices.find(price => product.productId === price.productId);
            return (0, common_utils_1.mergeProductPricesToProductLister)(product, productPrice);
        });
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.setWishListerListerProducts({
            totalPages: wishlistListerProducts.totalPages,
            itemCount: wishlistListerProducts.itemCount,
            items: itemsWithPrices,
        }));
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.setFetchStatus(common_types_1.FetchStatus.RESOLVED));
        if (isWishlistPage && (itemsWithPrices === null || itemsWithPrices === void 0 ? void 0 : itemsWithPrices.length)) {
            MonetateUtil_1.default.pushListerEvent(itemsWithPrices);
        }
    }
    catch (_a) {
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.setFetchStatus(common_types_1.FetchStatus.REJECTED));
        const currentPage = yield (0, effects_1.select)(exports.getCurrentPage);
        if (currentPage > 0) {
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.updateWishListPage({ page: 0, shouldReplaceHistory: true }));
        }
    }
}
exports.getWishListListerProducts = getWishListListerProducts;
function* updateWishListPage(action) {
    const { page, shouldReplaceHistory } = action.payload;
    const pushingRouterStateTask = yield (0, effects_1.fork)(shouldReplaceHistory ? RouterSagas_1.replaceRouterState : RouterSagas_1.pushRouterState, {
        page,
    });
    yield (0, effects_1.join)(pushingRouterStateTask);
    yield (0, effects_1.call)(getWishListListerProducts);
    yield (0, effects_1.call)(CompareSagas_1.updateCompareProducts);
    const partialRootState = yield (0, effects_1.select)(RouterSagas_1.getWishlistStoreData);
    yield (0, effects_1.fork)(RouterSagas_1.replaceRouterState, { page }, Object.assign({}, partialRootState));
}
exports.updateWishListPage = updateWishListPage;
function* removeBasketItemFromWishlist(action) {
    const wishListActive = yield (0, effects_1.select)(WishListSelectors_1.wishListActiveSelector);
    if (wishListActive) {
        const wishListProducts = yield (0, effects_1.select)(exports.getWishListProductsFromStore);
        const { product } = action.payload;
        const productToSet = {
            colorId: product.colorId,
            productCode: product.productCode,
            productId: parseInt(product.productId, 10),
            sku: product.sku,
        };
        if ((0, WishListUtil_1.isProductInWishList)(wishListProducts, productToSet.productId, productToSet.colorId)) {
            yield (0, effects_1.fork)(removeProductFromWishList, WishListActions_creators_1.wishListActions.removeProduct(productToSet));
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.removeProduct(productToSet));
        }
    }
}
exports.removeBasketItemFromWishlist = removeBasketItemFromWishlist;
function* wishlistLoginSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LOGIN, handleWishListLogin);
}
exports.wishlistLoginSaga = wishlistLoginSaga;
function* wishlistLogoutSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LOGOUT, handleWishListLogout);
}
exports.wishlistLogoutSaga = wishlistLogoutSaga;
function* getWishListListerProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LISTER_GET_PRODUCTS, getWishListListerProducts);
}
exports.getWishListListerProductsSaga = getWishListListerProductsSaga;
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
function* handleCustomEvent(action) {
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.WISHLIST_CHANGED:
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.getWishListListerProducts());
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.getWishListProducts());
            break;
        default:
            break;
    }
}
function* wishListSaga() {
    yield (0, effects_1.all)([
        getWishListProductsSaga(),
        toggleWishListProductSaga(),
        wishlistLoginSaga(),
        wishlistLogoutSaga(),
        getWishListListerProductsSaga(),
        customEventSaga(),
    ]);
}
exports.default = wishListSaga;
