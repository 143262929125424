"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketSaveForm = exports.BasketSaveFormState = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
var BasketSaveFormState;
(function (BasketSaveFormState) {
    BasketSaveFormState["INPUT"] = "input";
    BasketSaveFormState["SUBMITTING"] = "submitting";
    BasketSaveFormState["COMPLETE"] = "complete";
})(BasketSaveFormState || (exports.BasketSaveFormState = BasketSaveFormState = {}));
const initialValues = {
    email: '',
};
const BasketSaveForm = ({ onSubmit, state = BasketSaveFormState.INPUT, isHorizontal, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.form]);
    const handleValidation = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.required`);
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(values.email)) {
            errors.email = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.patternMail`);
        }
        return errors;
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { initialValues: initialValues, validate: handleValidation, validateOnBlur: false, onSubmit: onSubmit, name: "basket-save-form", children: ({ errors, touched }) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: !isHorizontal, grid: isHorizontal ? 8 : 12, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: "basket-save-email", children: t(`${i18nConstants_1.I18N_NAMESPACES.form}:form.email`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.EMAIL, id: "basket-save-email", name: "email", required: true, state: errors.email && touched.email && cotopaxi_1.InputState.ERROR, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.form}:form.email`), adornmentEnd: errors.email &&
                                touched.email && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, readOnly: state !== BasketSaveFormState.INPUT })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterRight: true, noGutterLeft: !isHorizontal, grid: isHorizontal ? 4 : 12, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: !isHorizontal ? { spacing: { marginTop: 2 } } : { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t(`${i18nConstants_1.I18N_NAMESPACES.form}:form.send`), iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT }, type: cotopaxi_1.ButtonType.SUBMIT, state: state === BasketSaveFormState.SUBMITTING
                                ? cotopaxi_1.ButtonState.LOADING
                                : state === BasketSaveFormState.COMPLETE
                                    ? cotopaxi_1.ButtonState.FINISHED
                                    : null, finisher: {
                                icon: cotopaxi_1.IconName.CHECK,
                                text: t(`${i18nConstants_1.I18N_NAMESPACES.form}:form.sent`),
                            }, fill: true }) }) })] })) }));
};
exports.BasketSaveForm = BasketSaveForm;
