"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authFormActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.authFormActions = {
    updateStep: (payload) => ({
        type: ActionEnums_1.AuthFormActionsTypes.UPDATE_STEP_TYPE,
        payload,
    }),
};
