"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const AccountVouchers_1 = require("../../components/account/account-vouchers/AccountVouchers");
const AccountVouchersContainer = ({ title }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const [vouchers, setVouchers] = (0, react_1.useState)([]);
    const fetchVouchers = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const answer = yield CustomerApi_1.default.getVouchers(servicesEndpoint, defaultRequestParameters);
            if (answer.status !== common_types_1.HTTPStatusCode.NoContent) {
                setVouchers(answer.data);
            }
            setShouldRender(true);
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        fetchVouchers();
    }, []);
    return shouldRender ? (0, jsx_runtime_1.jsx)(AccountVouchers_1.AccountVouchers, { vouchers: vouchers, title: title }) : null;
};
exports.default = AccountVouchersContainer;
