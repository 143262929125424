"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VimeoVideo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const VideoUtil_1 = require("../../util/VideoUtil");
const VimeoVideo = ({ url, iframe, pause, stop, play, idPostfix = '', allowFullScreen, withOverlay, description, overlayTheme, poster, }) => {
    const player = (0, react_1.useRef)();
    const [showOverlay, setShowOverlay] = (0, react_1.useState)(withOverlay);
    const videoId = (0, VideoUtil_1.getVimeoId)(url);
    const iframeId = `video_iframe_${videoId}`;
    const onVimeoPlayerAPIReady = () => {
        const options = {
            id: videoId,
            dnt: true,
        };
        if (window.Vimeo) {
            player.current = new window.Vimeo.Player(`${iframeId}${idPostfix}`, options);
        }
    };
    const playVideo = () => {
        var _a;
        ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.play) && player.current.play();
        showOverlay && setShowOverlay(false);
    };
    const pauseVideo = () => {
        var _a;
        ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.pause) && player.current.pause();
    };
    const stopVideo = () => {
        var _a;
        ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.unload) && player.current.unload();
    };
    (0, react_1.useEffect)(() => {
        (0, VideoUtil_1.injectVimeoAPI)();
        (0, VideoUtil_1.addVimeoReadyEvent)(onVimeoPlayerAPIReady);
    }, []);
    (0, react_1.useEffect)(() => {
        pause && pauseVideo();
    }, [pause]);
    (0, react_1.useEffect)(() => {
        play && playVideo();
    }, [play]);
    (0, react_1.useEffect)(() => {
        stop && stopVideo();
    }, [stop]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Video, { url: url, iframe: iframe, iframeId: iframeId, idPostfix: idPostfix, allowFullScreen: allowFullScreen, withOverlay: showOverlay, description: description, onOverlayClick: playVideo, overlayTheme: overlayTheme, poster: poster }));
};
exports.VimeoVideo = VimeoVideo;
