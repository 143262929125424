"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const addressFields_utils_1 = require("./addressFields.utils");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AddressCard = ({ children, addressFields, onSaveClick, prefix, country, }) => {
    const allAddressFields = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.ADDRESS_LINE_3,
        common_types_1.AddressFieldName.ADDRESS_LINE_4,
        common_types_1.AddressFieldName.COUNTY,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [isEditEnabled, setIsEditEnabled] = (0, react_1.useState)(false);
    const { setValue, formState } = (0, react_hook_form_1.useFormContext)();
    const addressValues = (0, react_hook_form_1.useWatch)({ name: addressFields });
    const [postCode, houseNumber, houseNumberAddition, city, address, address2, address3, address4, county] = (0, react_hook_form_1.useWatch)({
        name: allAddressFields,
    });
    const defaultValues = (0, react_1.useMemo)(() => addressFields.map((name, i) => ({ field: name, value: addressValues[i] })), [isEditEnabled]);
    const restoreDefaultValues = () => defaultValues.map(({ field, value }) => setValue(field, value, { shouldValidate: true }));
    const hasInvalidFields = () => addressFields.some(field => Boolean((0, react_hook_form_1.get)(formState.errors, field)));
    const handleCancelClick = () => {
        restoreDefaultValues();
        setTimeout(() => !hasInvalidFields() && setIsEditEnabled(false), GeneralConstants_1.DELAY.DELAY_0);
    };
    const handleSaveClick = () => {
        if (hasInvalidFields())
            return;
        setIsEditEnabled(false);
        onSaveClick === null || onSaveClick === void 0 ? void 0 : onSaveClick(!addressValues.some(Boolean));
    };
    const getAddressLinesByCountry = () => {
        switch (country) {
            case 'UK':
                return [
                    [address, address2]
                        .filter(Boolean)
                        .join(', ')
                        .concat((address || address2) && (address3 || address4) ? ',' : ''),
                    [address3, address4].filter(Boolean).join(', '),
                    [postCode, city]
                        .filter(Boolean)
                        .join(' ')
                        .concat(county ? `${postCode || city ? ', ' : ''}${county}` : ''),
                ].filter(Boolean);
            case 'NL':
                return [
                    [address, houseNumber]
                        .filter(Boolean)
                        .join(' ')
                        .concat(houseNumberAddition ? `${address || houseNumber ? ', ' : ''}${houseNumberAddition}` : ''),
                    [postCode, city].filter(Boolean).join(' '),
                ].filter(Boolean);
            default:
                return [];
        }
    };
    (0, react_1.useEffect)(() => {
        hasInvalidFields() && setIsEditEnabled(true);
    }, [formState.isSubmitting]);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "4", backgroundColor: "quiet", children: isEditEnabled ? ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [children, (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { theme: "branded", text: t('address.card.save'), onClick: handleSaveClick }), (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { theme: "branded", text: t('address.card.cancel'), onClick: handleCancelClick })] })] })) : ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "4", contentAlign: "between", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: getAddressLinesByCountry().map((line, index, arr) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [line, arr.length - 1 === index ? '' : (0, jsx_runtime_1.jsx)("br", {})] }, `${line}_${index}`))) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('address.card.edit'), onClick: () => setIsEditEnabled(true) }) })] })) }));
};
exports.AddressCard = AddressCard;
