"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListItemContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const list_utils_1 = require("./list.utils");
const AEMUtil_1 = require("../../util/AEMUtil");
const ListItemContent = ({ item }) => {
    const textSize = item.displayAsHeading ? cotopaxi_1.TextSize.LARGE : list_utils_1.mapListItemTextSize[item.textSize];
    const isEmphasized = item.displayAsHeading || item.textWeight === 'bold';
    const textColor = list_utils_1.aemColorToCotopaxiColor[item.textColor];
    const text = item.displayAsHeading || item.textTransform === 'uppercase'
        ? item.text.toUpperCase()
        : item.textTransform === 'lowercase'
            ? item.text.toLowerCase()
            : item.text;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(item.paddings), { className: `${Object.values(item.borders).filter(Boolean).join(' ')}`, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.ConditionalWrapper, { condition: !!item.link, wrapper: children => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { variant: cotopaxi_1.LinkVariant.SUBTLE_ALT, href: item.link, isObfuscated: item.isObfuscated, target: item.openInNewWindow ? cotopaxi_1.LinkTarget.BLANK : null, children: children })), children: [!!item.icon && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { color: textColor, inline: true, name: item.icon, size: item.iconSize }), ' '] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: textColor, emphasized: isEmphasized, size: textSize, inline: true, children: text })] }) })));
};
exports.ListItemContent = ListItemContent;
