"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Video = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const YouTubeVideo_1 = require("./YouTubeVideo");
const VimeoVideo_1 = require("./VimeoVideo");
const NativeVideo_1 = require("./NativeVideo");
const Video = props => {
    switch (props.playerType) {
        case common_types_1.VideoPlayerTypes.VIMEO:
            return (0, jsx_runtime_1.jsx)(VimeoVideo_1.VimeoVideo, Object.assign({}, props));
        case common_types_1.VideoPlayerTypes.YOUTUBE:
            return (0, jsx_runtime_1.jsx)(YouTubeVideo_1.YouTubeVideo, Object.assign({}, props));
        case common_types_1.VideoPlayerTypes.NATIVE:
            return (0, jsx_runtime_1.jsx)(NativeVideo_1.NativeVideo, Object.assign({}, props));
        default:
            return null;
    }
};
exports.Video = Video;
