"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const EmailChangeHandler_1 = require("../../../components/email/EmailChangeHandler");
const ForgotPasswordForm = ({ onSubmit, onEmailChange, successFeedback, errorFeedback, validate, action, initialEmail = '', }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "forgot-password-form", initialValues: {
            email: initialEmail,
        }, validate: validate, onSubmit: onSubmit, method: "post", action: action, children: ({ values, errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [onEmailChange && ((0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "email", onEmailChange: onEmailChange, emailValue: values.email, skipEmailValidation: false })), successFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: successFeedback, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE, id: DOMElementsConstants_1.FORGOT_PASSWORD_SUCCESS_FEEDBACK_ID }) })), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, inline: true, children: t('account.passwordForgottenText') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "email", children: t('account.flyout.email.placeholder') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.EMAIL, name: "email", value: values.email, required: true, state: errors.email && touched.email && cotopaxi_1.InputState.ERROR, placeholder: t('account.flyout.email.placeholder'), adornmentEnd: errors.email &&
                        touched.email && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "email" }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('account.login.action.password.forgotten'), fill: true, type: cotopaxi_1.ButtonType.SUBMIT, dataQA: "forgot_pwd_btn" }) })] })) }));
};
exports.ForgotPasswordForm = ForgotPasswordForm;
