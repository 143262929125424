"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFinderForm = exports.initialValuesStoreFinder = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_hook_form_1 = require("react-hook-form");
const FormField_1 = require("../../form/FormField");
const andes_react_1 = require("@yonderland/andes-react");
exports.initialValuesStoreFinder = {
    location: '',
};
const StoreFinderForm = ({ initialValues, onSubmit, handleResetValues, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: initialValues,
        mode: 'all',
    });
    const handleSubmit = (values) => {
        onSubmit(values, formProps.setError);
    };
    (0, react_1.useEffect)(() => {
        !!initialValues.location && formProps.setValue('location', initialValues.location);
    }, [initialValues]);
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), noValidate: true, name: "store-finder-form-v2", autoComplete: "off", children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "location", children: (_a) => {
                    var _b = _a.fieldProps, { ref, onChange } = _b, options = tslib_1.__rest(_b, ["ref", "onChange"]), { error } = _a;
                    return ((0, jsx_runtime_1.jsx)(andes_react_1.SearchFieldAlpha, Object.assign({}, options, { input: {
                            id: 'store-finder-location',
                            name: 'location',
                            autoComplete: 'location',
                            placeholder: t('storeFinder.popup.placeholder'),
                            error,
                        }, onChange: (e, byEnterKey) => {
                            onChange(e);
                            !byEnterKey && formProps.handleSubmit(handleSubmit)();
                        }, onClear: () => {
                            formProps.setValue('location', '');
                            handleResetValues === null || handleResetValues === void 0 ? void 0 : handleResetValues();
                        } })));
                } }) }) })));
};
exports.StoreFinderForm = StoreFinderForm;
