// This function fetches all Offers applicable to this page, also the offers that couldn't get displayed on the original page load
// due to "presolden" not being available at that time.
// based on:
// https://experienceleague.adobe.com/docs/target-dev/developer/client-side/at-js-implementation/functions-overview/adobe-target-getoffers-atjs-2.html?lang=en
import { AdobeTargetBasketData } from '@as-react/common-types';
import { Adobe, Satellite } from '../mediatorTypes';
import { isEqual } from '@as-react/as-react/dist/util/GeneralUtil';

let lastGlobalParameters = {};
const reTriggerAdobeTarget = async (
  adobe: Adobe,
  _satellite: Satellite,
  globalParameters = {},
  profileParameters = {}
): Promise<void> => {
  try {
    if (isEqual(globalParameters, lastGlobalParameters)) {
      console.debug('[presoldenSubscriber] Skipping Adobe Target re-trigger due to identical global parameters');
      return;
    }

    const response = await adobe?.target?.getOffers({
      request: {
        execute: {
          pageLoad: { parameters: globalParameters, profileParameters },
        },
        experienceCloud: {
          analytics: {
            supplementalDataId: _satellite.getVar('sdid'), // Data element containing last SDID value
          },
        },
      },
    });

    console.debug('%c[presoldenSubscriber] Trying to apply offer ro adobe target :', 'color: #fc2c03;', response);
    response && adobe?.target?.applyOffers({ response });
    lastGlobalParameters = globalParameters;
  } catch (error) {
    console.debug('[presoldenSubscriber] Error re-triggering Adobe Target:', error);
  }
};

export const presoldenSubscriber = (
  yonderlandMediator: Array<Record<string, unknown>>,
  adobe: Adobe,
  _satellite: Satellite
) => {
  console.debug('[presoldenSubscriber] Subscribing to presolden event');
  yonderlandMediator.push({
    action: 'subscribe',
    group: 'basket',
    on: 'presolden',
    callback: function ({ data }: { data: AdobeTargetBasketData }) {
      const { hasPreSoldenProduct, isPreSoldenFulfilled, hasMultipleProducts, origin } = data;

      const globalParameters = {
        presolden_has1PresoldenProductButNoOtherProducts:
          hasPreSoldenProduct && !hasMultipleProducts && !isPreSoldenFulfilled, // Basket contains 1 presolden item and no other products (hence, no presolden discount is awarded yet)
        presolden_hasMultipleProductsOfWhichAtLeast1IsPresolden: hasMultipleProducts && isPreSoldenFulfilled, // Basket contains 1 or multiple products of which none is a presolden product
        presolden_hasNoPresoldenProducts: !hasPreSoldenProduct, // Basket contains 1 or multiple products of which none is a presolden product
      };

      console.debug(
        '[presoldenSubscriber] Retriggering Adobe Target with the following parameters:',
        globalParameters,
        origin
      );
      void reTriggerAdobeTarget(adobe, _satellite, globalParameters);
    },
  });
};
