"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CookieConsentReducer_1 = require("../../cookieConsent/reducers/CookieConsentReducer");
const loadCookieConsentModule = (initialData) => ({
    id: 'CookieConsent',
    reducerMap: {
        cookieConsent: (0, CookieConsentReducer_1.cookieConsentReducer)(initialData ? initialData.cookieConsent : []),
    },
    sagas: [],
});
exports.default = loadCookieConsentModule;
