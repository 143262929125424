"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetIsCompareProduct = exports.makeGetCompareItems = exports.makeGetCompareState = exports.getCompareState = void 0;
const reselect_1 = require("reselect");
const getCompareState = (state) => state.compare;
exports.getCompareState = getCompareState;
const makeGetCompareState = () => (0, reselect_1.createSelector)([exports.getCompareState], (compareState) => compareState);
exports.makeGetCompareState = makeGetCompareState;
const makeGetCompareItems = () => (0, reselect_1.createSelector)([exports.getCompareState], (compareState) => compareState === null || compareState === void 0 ? void 0 : compareState.items);
exports.makeGetCompareItems = makeGetCompareItems;
const makeGetIsCompareProduct = (productCode, colorId) => (0, reselect_1.createSelector)([exports.getCompareState], (compareState) => !!(compareState === null || compareState === void 0 ? void 0 : compareState.items.find(item => item.productCode === productCode && item.color === colorId)));
exports.makeGetIsCompareProduct = makeGetIsCompareProduct;
