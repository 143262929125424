"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetSearchContentState = exports.makeGetSearchBrandAsCategoriesState = exports.makeGetSearchCategoriesState = exports.makeGetSearchBrandsState = exports.makeGetSearchSuggestionsState = exports.makeGetResultsLimitState = void 0;
const SearchUtil_1 = require("../../../util/SearchUtil");
const reselect_1 = require("reselect");
const searchStateSelector = (state) => state.search;
const customBasePathProp = (_, customBasePath) => customBasePath;
const makeGetResultsLimitState = () => (0, reselect_1.createSelector)(searchStateSelector, (searchState) => searchState.resultsLimit);
exports.makeGetResultsLimitState = makeGetResultsLimitState;
const makeGetSearchSuggestionsState = () => (0, reselect_1.createSelector)(searchStateSelector, customBasePathProp, (searchState, suggestionsParentLink) => (0, SearchUtil_1.getSearchKeywords)(searchState === null || searchState === void 0 ? void 0 : searchState.searchKeywords, suggestionsParentLink));
exports.makeGetSearchSuggestionsState = makeGetSearchSuggestionsState;
const makeGetSearchBrandsState = () => (0, reselect_1.createSelector)(searchStateSelector, customBasePathProp, (searchState, brandsParentLink) => (0, SearchUtil_1.getSearchBrands)(searchState === null || searchState === void 0 ? void 0 : searchState.searchBrands, brandsParentLink));
exports.makeGetSearchBrandsState = makeGetSearchBrandsState;
const makeGetSearchCategoriesState = () => (0, reselect_1.createSelector)(searchStateSelector, customBasePathProp, (searchState, categoriesParentLink) => (0, SearchUtil_1.getSearchCategories)(searchState === null || searchState === void 0 ? void 0 : searchState.searchCategories, categoriesParentLink));
exports.makeGetSearchCategoriesState = makeGetSearchCategoriesState;
const makeGetSearchBrandAsCategoriesState = () => (0, reselect_1.createSelector)(searchStateSelector, customBasePathProp, (searchState, categoriesParentLink) => (0, SearchUtil_1.getSearchBrandAsCategories)(searchState === null || searchState === void 0 ? void 0 : searchState.searchBrandAsCategories, categoriesParentLink));
exports.makeGetSearchBrandAsCategoriesState = makeGetSearchBrandAsCategoriesState;
const makeGetSearchContentState = () => (0, reselect_1.createSelector)(searchStateSelector, (searchState) => (0, SearchUtil_1.getSearchContent)(searchState === null || searchState === void 0 ? void 0 : searchState.searchContent));
exports.makeGetSearchContentState = makeGetSearchContentState;
