"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const RelatedCategories_1 = require("../components/related-categories/RelatedCategories");
const RelatedCategoriesContainer = ({ crumbs = [], title, analyticsBannerId, }) => {
    const brand = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductBrand)());
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: Boolean(analyticsBannerId), wrapper: children => (0, jsx_runtime_1.jsx)("div", { "data-analytics-bannerid": analyticsBannerId, children: children }), children: (0, jsx_runtime_1.jsx)(RelatedCategories_1.default, { categories: crumbs, brand: brand, title: title }) }));
};
exports.default = RelatedCategoriesContainer;
