"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const SearchReducer_1 = require("../../search/reducers/SearchReducer");
const SearchSagas_1 = require("../../search/sagas/SearchSagas");
const loadSearchModule = () => ({
    id: common_types_1.AEMScenes.SEARCH_MENU_ITEM,
    reducerMap: {
        search: SearchReducer_1.searchReducer,
    },
    sagas: [SearchSagas_1.default],
});
exports.default = loadSearchModule;
