"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReevoo = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ScriptLoaderUtil_1 = require("../util/ScriptLoaderUtil");
/* eslint-enable @typescript-eslint/no-namespace */
const SCRIPT_ID = 'reevoo-loader';
const useReevoo = (shouldLoad = true) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const reevooEnabled = (0, ConfigurationUtil_1.getReevooEnabled)(aemConfiguration);
    const reevooAccountCode = (0, ConfigurationUtil_1.getReevooAccountCode)(aemConfiguration);
    const cookieConsentSettings = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettings)());
    const [isLoaded, setLoaded] = (0, react_1.useState)(false);
    const loadReevooScript = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        let attributes = {};
        if (!cookieConsentSettings) {
            attributes = { 'disable-cookie': 'all' };
        }
        else if (cookieConsentSettings[common_types_1.CookieConsentGroups.FUNCTIONAL] &&
            !cookieConsentSettings[common_types_1.CookieConsentGroups.ANALYTICS]) {
            attributes = { 'disable-cookie': 'performance' };
        }
        try {
            yield ScriptLoaderUtil_1.default.loadScript(`https://widgets.reevoo.com/loader/${reevooAccountCode}.js`, SCRIPT_ID, false, attributes, document.head);
            setLoaded(true);
            window.isReevooScriptLoaded = true;
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        if (reevooEnabled && shouldLoad) {
            loadReevooScript();
        }
    }, [shouldLoad]);
    return isLoaded;
};
exports.useReevoo = useReevoo;
