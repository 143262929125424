"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const SingleProductTileActions_creators_1 = require("../redux/single-product-tile/actions/SingleProductTileActions.creators");
const SingleProductTileSelectors_1 = require("../redux/single-product-tile/selectors/SingleProductTileSelectors");
const SingleProductTile_1 = require("../components/single-product-tile/SingleProductTile");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const CompareActions_creators_1 = require("../redux/compare/actions/CompareActions.creators");
const ActionEnums_1 = require("../enums/ActionEnums");
const SingleProductTileContainer = ({ analyticsBannerId, initializationProduct, tileFormat, deliveryPromiseExplanations, withoutSpacing, shouldInitProduct = true, isFallbackProduct, shortDescription, pros, cons, adjustTileDescriptionHeight, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const product = (0, react_redux_1.useSelector)((state) => (0, SingleProductTileSelectors_1.makeGetSingleProductTileProduct)()(state, { initializationProduct }));
    (0, react_1.useEffect)(() => {
        if (shouldInitProduct) {
            dispatch(SingleProductTileActions_creators_1.singleProductTileActions.addProductToList(initializationProduct));
        }
    }, []);
    if (!product && !initializationProduct) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(SingleProductTile_1.default, { analyticsBannerId: analyticsBannerId, product: product || initializationProduct, tileFormat: tileFormat, deliveryPromiseExplanations: deliveryPromiseExplanations, withoutSpacing: withoutSpacing, onWishListToggle: wishListProduct => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct)), getDeliveryPromise: payload => dispatch(SingleProductTileActions_creators_1.singleProductTileActions.getDeliveryPromise(payload)), addToBasket: addToBasketData => dispatch(BasketActions_creators_1.basketActions.createAddExtensiveTileProductAction(addToBasketData, ActionEnums_1.ProductAddToBasketLocations.PRODUCT_TILE_SMALL)), updateCompareList: compareProduct => dispatch(CompareActions_creators_1.default.createUpdateOnListerAction(compareProduct)), isFallbackProduct: isFallbackProduct, shortDescription: shortDescription, pros: pros, cons: cons, adjustTileDescriptionHeight: adjustTileDescriptionHeight }));
};
exports.default = SingleProductTileContainer;
