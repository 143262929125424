"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreLocatorFilter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const getOpenAfterTime = (openAfterTime, storeTimeNotation) => {
    const hours = Math.floor(openAfterTime / 60);
    const minutes = openAfterTime % 60;
    const amPmNotation = hours >= 12 ? 'PM' : 'AM';
    return {
        hours: hours % storeTimeNotation,
        minutes: minutes !== 0 ? minutes : null,
        notation: storeTimeNotation === 12 ? amPmNotation : null,
    };
};
const defaultFiltersState = {
    openNow: false,
    openLate: false,
    openSunday: false,
    newShop: false,
};
const StoreLocatorFilter = ({ defaultSearch = '', onFiltersChange, onSearchSubmit, openAfterTime, storeTimeNotation, errorMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    const [filters, setFilters] = (0, react_1.useState)(defaultFiltersState);
    const [searchTerm, setSearchTerm] = (0, react_1.useState)(defaultSearch);
    const handleFilterChange = (filterKey) => (e) => {
        const newFilters = Object.assign(Object.assign({}, filters), { [filterKey]: e.target.checked });
        setFilters(newFilters);
        setSearchTerm('');
        onFiltersChange(newFilters);
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleSearchSubmit = () => {
        setFilters(defaultFiltersState);
        onSearchSubmit(searchTerm);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearchSubmit();
        }
    };
    const filtersConfig = [
        {
            name: 'openNow',
            translationKey: 'store.opennow.txt',
        },
        {
            name: 'openLate',
            translationKey: 'store.openafter.txt',
            translationParams: getOpenAfterTime(openAfterTime, storeTimeNotation),
        },
        {
            name: 'openSunday',
            translationKey: 'store.sunday.txt',
        },
        {
            name: 'newShop',
            translationKey: 'store.openrecently.txt',
        },
    ];
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 6, desktop: 4, desktopLarge: 4, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Input, { id: "store_filter", name: "store_filter", value: searchTerm, onKeyDown: handleKeyDown, onChange: handleSearchChange, type: cotopaxi_1.InputType.SEARCH, placeholder: t('store.location'), autoComplete: cotopaxi_1.AutoComplete.OFF, adornmentEnd: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "store_locator_search_submit", onClick: handleSearchSubmit, type: cotopaxi_1.ButtonType.SUBMIT, icon: { name: cotopaxi_1.IconName.MAGNIFY, size: cotopaxi_1.IconSize.MAJOR } }) }), errorMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, children: t(errorMessage) }) }))] }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, children: filtersConfig.map(filter => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: filter.name, name: filter.name, value: filter.name, labelChildren: t(filter.translationKey, filter.translationParams), checked: filters[filter.name], onChange: handleFilterChange(filter.name) }) }, filter.name))) })] }));
};
exports.StoreLocatorFilter = StoreLocatorFilter;
