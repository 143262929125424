"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerticalStep = exports.VerticalStepStatus = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
var VerticalStepStatus;
(function (VerticalStepStatus) {
    VerticalStepStatus["CURRENT"] = "current";
    VerticalStepStatus["PASSED"] = "passed";
})(VerticalStepStatus || (exports.VerticalStepStatus = VerticalStepStatus = {}));
const VerticalStep = ({ index, title, status, children }) => {
    const renderTitle = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, valign: cotopaxi_1.GroupVerticalAlign.CENTER, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { count: index + 1, size: cotopaxi_1.BadgeSize.LARGE, color: !status ? cotopaxi_1.BadgeColor.SUBTLE : undefined }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { grow: 1 }, children: title }), status === VerticalStepStatus.PASSED && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginLeft: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHECK, color: cotopaxi_1.IconColor.POSITIVE, size: cotopaxi_1.IconSize.MEDIUM }) }))] }) }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: `vertical-step-collapse-${index}`, title: renderTitle, isChecked: status === VerticalStepStatus.CURRENT, asStatic: true, withOverflow: status === VerticalStepStatus.CURRENT, mobileFullwidth: true, iconVertical: null, hasDivider: false, labelFullWidth: true, children: children }));
};
exports.VerticalStep = VerticalStep;
