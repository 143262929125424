"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CheckoutReducer_1 = require("../../checkout/reducers/CheckoutReducer");
const CheckoutSagas_1 = require("../../checkout/sagas/CheckoutSagas");
const loadCheckoutModule = () => ({
    id: 'Checkout',
    reducerMap: {
        checkout: CheckoutReducer_1.checkoutReducer,
    },
    sagas: [CheckoutSagas_1.default],
});
exports.default = loadCheckoutModule;
