"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewListActions = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ReviewConstants_1 = require("../../constants/ReviewConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const ReviewListActions = ({ showMore, showLess, restOfReviews, loading, shownReviewsSize, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, desktop: { spacing: { marginTop: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.LARGE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: `${t('product.review.list.button.show.more')} (${restOfReviews})`, variant: cotopaxi_1.ButtonVariant.LINK, state: loading || restOfReviews === 0 ? cotopaxi_1.ButtonState.DISABLED : cotopaxi_1.ButtonState.INTERACTIVE, iconRight: {
                        name: cotopaxi_1.IconName.CHEVRON_DOWN,
                    }, onClick: showMore }), shownReviewsSize > ReviewConstants_1.START_NUMBER_REVIEWS && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('product.review.list.button.show.less'), variant: cotopaxi_1.ButtonVariant.LINK, state: loading ? cotopaxi_1.ButtonState.DISABLED : cotopaxi_1.ButtonState.INTERACTIVE, iconRight: {
                        name: cotopaxi_1.IconName.CHEVRON_UP,
                    }, onClick: showLess }))] }) }));
};
exports.ReviewListActions = ReviewListActions;
