"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const HtmlInclude_1 = require("../../components/common/html-include/HtmlInclude");
const useABTest_1 = require("../../hooks/useABTest");
const ABTestingContainer = ({ abTestingCookieName, componentA, componentB, }) => {
    const ABTestEnabled = (0, useABTest_1.useABTest)(abTestingCookieName);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: !ABTestEnabled ? ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: componentA.renderedHTML })) : ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: componentB.renderedHTML })) }));
};
exports.default = ABTestingContainer;
