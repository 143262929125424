"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const SizeSelectV2_1 = require("../components/product-detail/size-select-v2/SizeSelectV2");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const ActionEnums_1 = require("../enums/ActionEnums");
const SizeSelectButtons_1 = require("../components/product-detail/size-select-buttons/SizeSelectButtons");
const common_utils_1 = require("@as-react/common-utils");
const ProductDetailV2Container_1 = require("./product/ProductDetailV2Container");
const SizeSelectContainerV2 = ({ quantity, setQuantity, sizeButtonsBlock: { enableSizeButtonsABTest, buyingGroups } = {
    enableSizeButtonsABTest: true,
    buyingGroups: [],
}, showSizeSelector, showQuantityField, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const oneSizeProductCode = (0, ConfigurationUtil_1.getOneSizeProductCode)(aemConfiguration);
    const [addToBasketOnSizeSelect, setAddToBasketOnSizeSelect] = (0, react_1.useState)(false);
    const [hasABTestSizeButtonsCookie, setHasABTestSizeButtonsCookie] = (0, react_1.useState)(null);
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const basketValidationState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidationState)());
    const sizes = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductSizes)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const sizeRecommendationData = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeRecommendation)());
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    const isOneSizeProductInline = (0, common_utils_1.isOnlyOneSizeProduct)(sizes);
    const isSelectedSizeSoldOut = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetIsSelectedSizeSoldOut)());
    const notifyMeError = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetNotifyMeError)());
    const { productCode } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const revalidateErrors = () => {
        if (basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT || !sizeCombinationNotExist) {
            dispatch(BasketActions_creators_1.basketActions.createErrorAction(undefined));
        }
        if (notifyMeError === common_types_1.NotifyMeError.SELECT_SIZE) {
            dispatch(ProductActions_1.productActions.setNotifyMeError(null));
        }
    };
    const selectSize = ({ sku, isSoldOut, isDisabled, }) => {
        dispatch(ProductActions_1.productActions.createSetSizeCombinationNotExistAction(Boolean(isDisabled)));
        dispatch(ProductActions_1.productActions.createSelectSizeAction({ sku, isSoldOut, isDisabled }));
        if (addToBasketOnSizeSelect) {
            setAddToBasketOnSizeSelect(false);
            if (!isSoldOut) {
                dispatch(selectedAdditionalProducts.length > 0
                    ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, ActionEnums_1.ProductAddToBasketLocations.PDP)
                    : BasketActions_creators_1.basketActions.createAddAction(quantity, ActionEnums_1.ProductAddToBasketLocations.PDP, common_types_1.PDPVersion.V2));
            }
        }
        revalidateErrors();
    };
    const onSizeSelectClose = () => {
        if (addToBasketOnSizeSelect) {
            setAddToBasketOnSizeSelect(false);
        }
        revalidateErrors();
    };
    (0, react_1.useEffect)(() => {
        var _a;
        setHasABTestSizeButtonsCookie((_a = !!common_utils_1.CookieUtil.get(common_types_1.ABTestCookie.SIZE_BUTTONS)) !== null && _a !== void 0 ? _a : false);
    }, []);
    (0, react_1.useEffect)(() => {
        dispatch(BasketActions_creators_1.basketActions.createErrorAction(sizeCombinationNotExist ? common_types_1.BasketButtonError.SIZE_COMBINATION_NOT_EXIST : undefined));
        revalidateErrors();
        setAddToBasketOnSizeSelect(false);
    }, [sizeCombinationNotExist, selectedColorId]);
    (0, react_1.useEffect)(() => {
        if (basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT) {
            setAddToBasketOnSizeSelect(true);
        }
    }, [basketValidationState]);
    const showSizeSelectorForThisProduct = buyingGroups === null || buyingGroups === void 0 ? void 0 : buyingGroups.some(buyingGroup => productCode.startsWith(buyingGroup));
    const sizeContainerClientFallback = !showSizeSelectorForThisProduct && enableSizeButtonsABTest && (0, common_utils_1.isNullOrUndefined)(hasABTestSizeButtonsCookie);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [sizeContainerClientFallback && (0, jsx_runtime_1.jsx)("div", { className: "hide", children: String(sizeContainerClientFallback) }), (0, jsx_runtime_1.jsx)("div", { style: { visibility: sizeContainerClientFallback ? 'hidden' : 'visible' }, children: showSizeSelector ? ((0, jsx_runtime_1.jsx)(SizeSelectV2_1.SizeSelectV2, { id: "product_detail_size_select", sizes: sizes, selectSize: selectSize, setQuantity: setQuantity, selectedSku: selectedSku, sizeRecommendation: sizeRecommendationData.colorBased, basketValidationState: basketValidationState, isOneSizeProduct: oneSizeProduct, isOneSizeProductInline: isOneSizeProductInline, showQuantityField: showQuantityField, quantity: quantity, openSizeSelectOnError: true, onClose: onSizeSelectClose, isSelectedSizeSoldOut: isSelectedSizeSoldOut, notifyMeError: notifyMeError })) : ((0, jsx_runtime_1.jsx)(SizeSelectButtons_1.SizeSelectButtons, { id: "product_detail_size_select", sizes: sizes, selectedSku: selectedSku, selectSize: selectSize, basketValidationState: basketValidationState, isOneSizeProduct: oneSizeProduct, isOneSizeProductInline: isOneSizeProductInline, sizeRecommendation: sizeRecommendationData.colorBased, notifyMeError: notifyMeError, selectedColorId: selectedColorId, sizeCombinationNotExist: sizeCombinationNotExist })) })] }));
};
exports.default = SizeSelectContainerV2;
