"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecurityLevelsPopup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const EventEnum_1 = require("../../enums/EventEnum");
const EventUtil_1 = require("../../util/EventUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
require("../../middleware/axios.response.interceptors");
const SecurityLevelsPopup = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const [buttonState, setButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const handlePopoverLogout = () => {
        setButtonState(cotopaxi_1.ButtonState.LOADING);
        dispatch(SessionActions_creators_1.default.createLogoutAction());
        window.location.href = `${root}account.html`;
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const handleShowSecurityLevelsPopup = () => handlePopoverOpen(null, null, true);
    (0, react_1.useEffect)(() => {
        EventUtil_1.default.listen(EventEnum_1.EventEnum.USER_SECURITY_LEVEL_TOO_LOW, handleShowSecurityLevelsPopup);
        return () => EventUtil_1.default.remove(EventEnum_1.EventEnum.USER_SECURITY_LEVEL_TOO_LOW, handleShowSecurityLevelsPopup);
    }, []);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, noFooterShadow: true, footerElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginLeft: 2, marginRight: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, text: t('account.security.levels.popup.button'), onClick: handlePopoverLogout, state: buttonState }) }) }), dataQA: "security_level_popup", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0, marginBottom: 1, marginLeft: 2, marginRight: 2 } }, tablet: { spacing: { marginBottom: 2, marginLeft: 4, marginRight: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t('account.security.levels.popup.info') }) }) }));
};
exports.SecurityLevelsPopup = SecurityLevelsPopup;
