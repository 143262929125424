"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGroupAlign = exports.mapListItemTextSize = exports.mapTextColorToDividerLineColor = exports.aemColorToCotopaxiColor = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
exports.aemColorToCotopaxiColor = {
    contrast: 'N300',
    'high-contrast': 'N700',
    accent: cotopaxi_1.ColorsFasciaGroups.DIVIDER_LIST,
    neutral: 'N100',
    'neutral-accent': 'N200',
    'accent-primary': cotopaxi_1.ColorsFasciaGroups.DIVIDER_LIST,
    dark: 'N700',
    'medium-lighter': 'N200',
    'medium-darker': 'N300',
    light: 'N100',
};
exports.mapTextColorToDividerLineColor = {
    'accent-primary': cotopaxi_1.ColorsFasciaGroups.DIVIDER_LIST,
    dark: 'N700',
    'medium-lighter': 'N200',
    'medium-darker': 'N300',
    light: 'N100',
};
exports.mapListItemTextSize = {
    small: cotopaxi_1.TextSize.SMALL,
    medium: cotopaxi_1.TextSize.MEDIUM,
    large: cotopaxi_1.TextSize.LARGE,
};
exports.mapGroupAlign = {
    left: undefined,
    center: cotopaxi_1.GroupAlign.CENTER,
    right: cotopaxi_1.GroupAlign.RIGHT,
};
