"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailError = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const EmailError = ({ emailStatus, suggestedEmail, requiredErrorLabel, onAcceptClick, onIgnoreClick, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    if (emailStatus === null || emailStatus === void 0 ? void 0 : emailStatus.startsWith(common_types_1.EmailValidationResponseStatus.SUGGESTION)) {
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: t('email.error.suggested.part1', { suggestedEmail }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: onAcceptClick, text: t('email.error.suggested.accept') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: t('email.error.suggested.part2') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: onIgnoreClick, text: t('email.error.suggested.ignore') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: t('email.error.suggested.part3') })] }));
    }
    if (emailStatus === null || emailStatus === void 0 ? void 0 : emailStatus.startsWith(common_types_1.EmailValidationResponseStatus.INVALID_CONDITIONALLY)) {
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: t('email.error.invalid') }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: onIgnoreClick, text: t('email.error.invalid.ignore') })] }));
    }
    switch (emailStatus) {
        case common_types_1.EmailValidationResponseStatus.REQUIRED:
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: requiredErrorLabel }));
        case common_types_1.EmailValidationResponseStatus.INVALID:
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: t('email.error.invalid') }));
        default:
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.NEGATIVE, children: emailStatus }));
    }
};
exports.EmailError = EmailError;
