"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedFilterItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const AppliedFilterItem = ({ activeFilterItem, onToggleFilter, size }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const mapActiveFilterItemToSetFilterObject = (activeFilter) => activeFilter.filterType === common_types_1.RANGE_FILTER
        ? {
            filterOn: activeFilter.filterOn,
            filterOnName: activeFilter.filterOnName,
            filteredItems: [
                { filterKey: activeFilter.rangeMin.toString() },
                { filterKey: activeFilter.rangeMax.toString() },
            ],
            filterType: activeFilter.filterType,
            filterContentType: activeFilter.filterContentType,
            isReset: true,
        }
        : {
            filterOn: activeFilter.filterOn,
            filterOnName: activeFilter.filterOnName,
            filteredItems: [
                {
                    filterType: activeFilter.filterType,
                    filterKey: activeFilter.filterKey,
                    filterKeyName: activeFilter.filterKeyName,
                    filterOn: activeFilter.filterOn,
                    filterOnName: activeFilter.filterOnName,
                },
            ],
            filterType: activeFilter.filterType,
            filterContentType: activeFilter.filterContentType,
            queryParamKey: activeFilter.queryParamKey,
        };
    const getTextRangeValue = (value) => [activeFilterItem.filterPrefix, value.toString(), activeFilterItem.filterSuffix]
        .filter(item => !(0, common_utils_1.isNullOrUndefined)(item))
        .join('');
    const getTextSelect = () => activeFilterItem.filterType === common_types_1.BUCKET_FILTER
        ? `${activeFilterItem.filterOnName}: ${(0, common_utils_1.capitalizeOnly)(activeFilterItem.filterKeyName)}`
        : (0, common_utils_1.capitalizeOnly)(activeFilterItem.filterKeyName.split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[0]);
    const getTextRange = () => t('product.filter.range', {
        name: activeFilterItem.filterOnName,
        min: getTextRangeValue(activeFilterItem.rangeMin),
        max: getTextRangeValue(activeFilterItem.rangeMax),
    });
    const getText = () => (activeFilterItem.filterType === common_types_1.RANGE_FILTER ? getTextRange() : getTextSelect());
    const getTitle = () => activeFilterItem.filterType === common_types_1.RANGE_FILTER
        ? `${activeFilterItem.filterOnName}: ${getTextRangeValue(activeFilterItem.rangeMin)} - ${getTextRangeValue(activeFilterItem.rangeMax)}`
        : `${activeFilterItem.filterOnName} - ${(0, common_utils_1.capitalizeOnly)(activeFilterItem.filterKeyName).split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[0]}`;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.FILTER, shape: cotopaxi_1.ButtonShape.PILL, iconRight: { name: cotopaxi_1.IconName.CLOSE }, title: getTitle(), size: size, onClick: () => onToggleFilter(mapActiveFilterItemToSetFilterObject(activeFilterItem)), text: getText() }));
};
exports.AppliedFilterItem = AppliedFilterItem;
