"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatePathUrl = exports.removeQuerystringParameter = exports.setQuerystringParameter = exports.getQuerystringByName = exports.generateAbsoluteUrl = void 0;
const common_utils_1 = require("@as-react/common-utils");
const ProductConstants_1 = require("../constants/ProductConstants");
const generateAbsoluteUrl = (canonicalUrl, page, size) => {
    let params = [];
    if (!(0, common_utils_1.isNullOrUndefined)(page) && page > 0) {
        params = [...params, `page=${page}`];
    }
    if (!(0, common_utils_1.isNullOrUndefined)(size) && size !== ProductConstants_1.MAX_NUMBER_OF_ARTICLES_IN_LISTER_PAGE_DEFAULT) {
        params = [...params, `size=${size}`];
    }
    if (params.length > 0) {
        return `${canonicalUrl}?${params.join('&')}`;
    }
    return canonicalUrl;
};
exports.generateAbsoluteUrl = generateAbsoluteUrl;
const getQuerystringByName = (queryString, name) => {
    if (queryString.startsWith('?') || queryString.startsWith('#')) {
        queryString = queryString.substring(1);
    }
    const vars = queryString.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        let key = decodeURIComponent(pair[0] || '');
        if (key.startsWith('?'))
            key = key.substring(1);
        if (key === name)
            return decodeURIComponent(pair[1] || '');
    }
    return undefined;
};
exports.getQuerystringByName = getQuerystringByName;
const setQuerystringParameter = (name, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(name, decodeURIComponent(value));
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
};
exports.setQuerystringParameter = setQuerystringParameter;
const removeQuerystringParameter = (name) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(name);
    const newRelativePathQuery = window.location.pathname + url.search;
    history.pushState(null, '', newRelativePathQuery);
};
exports.removeQuerystringParameter = removeQuerystringParameter;
const generatePathUrl = (root, pathname, inAuthorMode, search, state) => ({
    pathname: `${root}${pathname}`,
    search: new URLSearchParams(inAuthorMode ? Object.assign(Object.assign({}, search), { wcmmode: 'disabled' }) : search).toString(),
    state,
});
exports.generatePathUrl = generatePathUrl;
