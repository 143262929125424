"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductContentVideoActions_creators_1 = require("../../redux/productContentVideo/actions/ProductContentVideoActions.creators");
const ProductVideoWithContent_1 = require("../../components/product-video-with-content/ProductVideoWithContent");
const MAX_LENGTH_PRODUCT_CONTENT_VIDEO_TITLE = 60;
const MAX_PRODUCT_CONTENT_VIDEOS_T0_SHOW = 2;
const ProductContentVideoContainer = () => {
    var _a, _b;
    const dispatch = (0, react_redux_1.useDispatch)();
    const { productId } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const productVideos = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductContentVideos)());
    (0, react_1.useEffect)(() => {
        dispatch(ProductContentVideoActions_creators_1.productContentVideosAction.getProductContentVideos(productId));
    }, [productId]);
    const sortedProductVideos = productVideos === null || productVideos === void 0 ? void 0 : productVideos.sort((t1, t2) => { var _a, _b; return Number((_a = t1.productVideoDetail) === null || _a === void 0 ? void 0 : _a.priority) - Number((_b = t2.productVideoDetail) === null || _b === void 0 ? void 0 : _b.priority); }).slice(0, MAX_PRODUCT_CONTENT_VIDEOS_T0_SHOW);
    if (!(sortedProductVideos === null || sortedProductVideos === void 0 ? void 0 : sortedProductVideos.length))
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { clear: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 5, marginBottom: 2 } }, tablet: { spacing: { marginTop: 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: (_b = (_a = sortedProductVideos[0].productVideoDetail) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.substring(0, MAX_LENGTH_PRODUCT_CONTENT_VIDEO_TITLE) }) }), sortedProductVideos.map(productVideo => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(ProductVideoWithContent_1.ProductVideoWithContent, Object.assign({}, productVideo)) }, productVideo.videoId)))] }));
};
exports.default = ProductContentVideoContainer;
