"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyVoucher = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const BuyAPI_1 = require("../../../api/buy/BuyAPI");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const BasketCouponForm_1 = require("../../basket-coupon-form/BasketCouponForm");
const BuyVoucher = ({ defaultRequestParams, servicesEndpoint, validationRegExp, logo, imageCdnPrefix, collapsedByDefault, onVoucherAddSuccess, onFormError, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy]);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [isCodeValid, setIsCodeValid] = (0, react_1.useState)(false);
    const [isCollapseOpen, setIsCollapseOpen] = (0, react_1.useState)(!collapsedByDefault);
    const submitVoucher = (_a, _b) => tslib_1.__awaiter(void 0, [_a, _b], void 0, function* ({ code, webcode }, { setFieldError, resetForm }) {
        var _c, _d;
        if (isCodeValid) {
            try {
                yield BuyAPI_1.default.postVoucher(servicesEndpoint, defaultRequestParams, code, webcode);
                resetForm();
                onVoucherAddSuccess(code);
                setIsCollapseOpen(false);
            }
            catch (error) {
                const message = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message;
                if (message) {
                    if (isDesktop) {
                        setFieldError('code', message);
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
                    }
                    else {
                        setFieldError('webcode', message);
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_WEBCODE_ID), isDesktop);
                    }
                    onFormError({
                        ['BuyService._postVoucher']: message,
                        ['buy-voucher.voucher-code_1']: message,
                    });
                }
            }
        }
        else {
            setFieldError('code', t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.mediacode.error`));
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
        }
    });
    const validateVoucher = (values) => {
        const errors = {};
        if (!values.code) {
            errors.code = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.barcode.errormessage`);
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
        }
        if (!values.webcode && isCodeValid) {
            errors.webcode = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.webcode.errormessage`);
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_WEBCODE_ID), isDesktop);
        }
        return errors;
    };
    const onCodeChange = (code) => {
        if (!validationRegExp) {
            return;
        }
        const tmpIsCodeValid = new RegExp(validationRegExp).test(code);
        setIsCodeValid(tmpIsCodeValid);
    };
    const onCollapseToggle = (event) => setIsCollapseOpen(event.target.checked);
    const title = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1.5, marginRight: 3 } }, tablet: { spacing: { marginLeft: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: isMobile ? cotopaxi_1.GroupVerticalAlign.TOP : cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, inline: true, children: [logo && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5, marginRight: 1.5 } }, tablet: { spacing: { marginTop: 0, marginRight: 2 } }, flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: (0, ImageUtil_1.getImageFromCdn)(logo, imageCdnPrefix), size: cotopaxi_1.ImageSize.BUY_VOUCHER }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: isMobile ? cotopaxi_1.TextSize.SMALL : cotopaxi_1.TextSize.LARGE, children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.voucher.title`) })] }) }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { withBorder: true, id: "payment-voucher-collapse", title: title, iconSize: cotopaxi_1.IconSize.EXTRA_MAJOR, open: isCollapseOpen, hasDivider: false, labelGutterVertical: isMobile ? cotopaxi_1.LabelGutter.SMALL : cotopaxi_1.LabelGutter.MEDIUM, handleChangeChecked: onCollapseToggle, iconAlignmentVertical: isMobile ? cotopaxi_1.CollapseIconAlignmentVertical.START : undefined, iconSpacing: isMobile ? cotopaxi_1.CollapseIconSpacing.XSMALL : cotopaxi_1.CollapseIconSpacing.MEDIUM, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginTop: 1, marginRight: 2, marginBottom: 2 } }, tablet: { spacing: { marginLeft: 3, marginRight: 3, marginBottom: 5 } }, children: (0, jsx_runtime_1.jsx)(BasketCouponForm_1.BasketCouponForm, { isPaymentPage: true, isShowWebCode: isCodeValid, onSubmit: submitVoucher, validate: validateVoucher, onCodeChange: onCodeChange }) }) }));
};
exports.BuyVoucher = BuyVoucher;
