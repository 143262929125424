"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedFilterContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const AppliedFilterRow_1 = require("../../components/lister/applied-filters/AppliedFilterRow");
const FilterActions_creators_1 = require("../../redux/filter/actions/FilterActions.creators");
const FilterSelectors_1 = require("../../redux/filter/selectors/FilterSelectors");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AppliedFilterContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const dispatch = (0, react_redux_1.useDispatch)();
    const flattenedActiveFilters = (0, react_redux_1.useSelector)((state) => (0, FilterSelectors_1.makeGetFlattenedActiveFiltersState)()(state.filters));
    const getContentFilters = (filters) => filters.map((filter) => (Object.assign(Object.assign({}, filter), { filterOnName: t(filter.filterOnName) })));
    const activeFilters = (0, react_1.useMemo)(() => getContentFilters(flattenedActiveFilters), [flattenedActiveFilters]);
    const toggleFilter = (toggledFilter) => dispatch(FilterActions_creators_1.filterActions.updateFilters(toggledFilter));
    const removeAllFilters = () => dispatch(FilterActions_creators_1.filterActions.removeAllFilters());
    return ((0, jsx_runtime_1.jsx)(AppliedFilterRow_1.AppliedFilterRow, { flattenedActiveFilters: activeFilters, onToggleFilter: toggleFilter, onRemoveAllFilters: removeAllFilters }));
};
exports.AppliedFilterContainer = AppliedFilterContainer;
