"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
class ProductComparisonAPI {
    constructor() {
        this.getCompareFeatures = (listProducts, servicesEndpoint, defaultRequestParams, locale) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestData = listProducts.map(item => {
                return {
                    productCode: item.productCode,
                    productId: item.productId,
                    colourId: item.colourId,
                };
            });
            const axiosConfig = {
                data: requestData,
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { locale }),
                url: `${servicesEndpoint}api/search/lister/${defaultRequestParams.market}/search/compare`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new ProductComparisonAPI();
