"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreConfiguratorNoDebug = void 0;
const rootModules_1 = require("../rootModules");
const storeConfigure_base_1 = require("./storeConfigure.base");
class StoreConfiguratorNoDebug {
    constructor() {
        this.buildDynamic = (initialState, context, dynamicStore, componentReduxModules, history) => {
            return (0, storeConfigure_base_1.configureDynamicStoreBase)(initialState, context, dynamicStore, componentReduxModules, history);
        };
    }
    loadFromModuleCollection(componentName, initialState) {
        return (0, rootModules_1.loadFromModuleCollectionImplementation)(componentName, initialState);
    }
}
exports.StoreConfiguratorNoDebug = StoreConfiguratorNoDebug;
