"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultNavigationLevel = exports.getMobileNavigationData = void 0;
const NavigationUtil_1 = require("./NavigationUtil");
const getMenuSpacing = (item) => {
    if (item && 'spaceType' in item) {
        return item.spaceType === 'simple' ? 'm' : 'l';
    }
    return 's';
};
const getMenuItems = (items, itemsOrder) => itemsOrder.map(itemId => items[itemId]).filter(Boolean);
const getMobileNavigationItems = (items) => {
    return items
        .filter(item => ('hideMenu' in item ? !item.hideMenu : true))
        .map((item, index, arr) => {
        if ('spaceType' in item)
            return null;
        const subItems = getMenuItems(item[':items'], item[':itemsOrder']);
        return {
            id: item.id,
            title: item.label,
            label: item.label,
            href: item.path,
            icon: item.icon,
            subItems: (subItems === null || subItems === void 0 ? void 0 : subItems.length) ? getMobileNavigationItems(subItems) : undefined,
            spacing: getMenuSpacing(arr[index + 1]),
            emphasized: item.highlightCategory,
            type: item.type,
        };
    })
        .filter(Boolean);
};
const getMobileNavigationData = (navigationConfig, mainLevelTitle) => {
    if (!navigationConfig[':items'] || !navigationConfig[':itemsOrder'].length)
        return null;
    const subItems = getMenuItems(navigationConfig[':items'], navigationConfig[':itemsOrder']);
    return {
        id: navigationConfig.id,
        title: mainLevelTitle,
        label: mainLevelTitle,
        type: 'common',
        subItems: getMobileNavigationItems(subItems),
    };
};
exports.getMobileNavigationData = getMobileNavigationData;
const findMenuItemByHref = (href, navItem) => {
    var _a, _b;
    if ((_a = navItem.href) === null || _a === void 0 ? void 0 : _a.includes(href)) {
        return navItem;
    }
    if ((_b = navItem.subItems) === null || _b === void 0 ? void 0 : _b.length) {
        return (navItem.subItems
            .map(item => findMenuItemByHref(href, item))
            .flat()
            .filter(Boolean)[0] || null);
    }
    return null;
};
const getCategoryHierarchyTreeActiveLinks = (hierarchyTreeItem, categoryIds, lang) => {
    var _a;
    const activeCategoriesLinks = [];
    if (!hierarchyTreeItem)
        return activeCategoriesLinks;
    const isActive = categoryIds.includes(hierarchyTreeItem.item.categoryId);
    if (isActive) {
        activeCategoriesLinks.push((0, NavigationUtil_1.getHomepageUrl)(hierarchyTreeItem.item.categoryTranslatedProperties[lang.toLocaleUpperCase()].link));
        if ((_a = hierarchyTreeItem.children) === null || _a === void 0 ? void 0 : _a.length) {
            return [
                ...activeCategoriesLinks,
                ...hierarchyTreeItem.children.map(item => getCategoryHierarchyTreeActiveLinks(item, categoryIds, lang)).flat(),
            ];
        }
    }
    return activeCategoriesLinks;
};
const getDefaultNavigationLevel = (hierarchyTreeItem, categoryIds, lang, navItem) => {
    var _a;
    const menuItems = [navItem];
    const activeCategoriesLinks = getCategoryHierarchyTreeActiveLinks(hierarchyTreeItem, categoryIds, lang);
    let isTargetMenuItemExists = false;
    for (let i = 0; i < activeCategoriesLinks.length; i++) {
        const menuItem = findMenuItemByHref(activeCategoriesLinks[i], menuItems.at(-1));
        if (activeCategoriesLinks.length - 1 === i && menuItem)
            isTargetMenuItemExists = true;
        if (!menuItem || !((_a = menuItem === null || menuItem === void 0 ? void 0 : menuItem.subItems) === null || _a === void 0 ? void 0 : _a.length))
            break;
        menuItems.push(menuItem);
    }
    return isTargetMenuItemExists
        ? menuItems.reduce((acc, menuItem, index) => {
            acc.push(acc[index - 1] ? `${acc[index - 1]}/${menuItem.id}` : menuItem.id);
            return acc;
        }, [])
        : [navItem.id];
};
exports.getDefaultNavigationLevel = getDefaultNavigationLevel;
