"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutDeliveryProvider = exports.usePickupPointSearchContext = exports.useDeliveryState = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const common_queries_1 = require("@as-react/common-queries");
const defaultState = {
    errors: {},
    deliveryCountryId: undefined,
    activeDeliveryOption: undefined,
    editCustomerDetails: false,
    info: {
        chosenDeliveryOption: null,
        country: '',
        deliveryAddress: { addToProfile: false, deliveryAddressId: undefined, isActive: false, defaultAddress: true },
        deliveryAddresses: null,
        deliveryDate: null,
        deliveryOptions: [],
        deliveryTimeslot: {
            end: null,
            start: null,
        },
        idCountry: undefined,
        priceDelivery: undefined,
        orderReference: '',
        requiresDelivery: undefined,
        store: null,
        storeNumber: null,
        zipCode: null,
        locationDTO: null,
    },
    pickupPoint: {
        isOpen: false,
        selectedPickupPoint: null,
        highlightedPickupPoint: null,
    },
    collectStore: {
        isOpen: false,
        selectedStore: null,
        highlightedStore: null,
    },
};
const DeliveryContext = (0, react_1.createContext)([]);
const useDeliveryState = () => (0, react_1.useContext)(DeliveryContext);
exports.useDeliveryState = useDeliveryState;
const PickupPointSearchContext = (0, react_1.createContext)({});
const usePickupPointSearchContext = () => {
    const context = (0, react_1.useContext)(PickupPointSearchContext);
    if (context === undefined) {
        throw new Error('usePickupPointSearchContext must be used within PickupPointSearchContext Provider');
    }
    return context;
};
exports.usePickupPointSearchContext = usePickupPointSearchContext;
const reducer = (state, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case 'set_delivery_info':
            return Object.assign(Object.assign({}, state), { info: action.payload });
        case 'set_delivery_error':
            return Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { [state.info.chosenDeliveryOption]: action.payload }) });
        case 'set_active_delivery_option':
            return Object.assign(Object.assign({}, state), { info: Object.assign(Object.assign({}, state.info), { chosenDeliveryOption: ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.name) || null }), activeDeliveryOption: action.payload });
        case 'set_delivery_country_id':
            return Object.assign(Object.assign({}, state), { deliveryCountryId: action.payload });
        case 'pickup_point_set_is_open':
            return Object.assign(Object.assign({}, state), { pickupPoint: Object.assign(Object.assign({}, state.pickupPoint), { isOpen: action.payload }) });
        case 'highlight_pickup_point':
            return Object.assign(Object.assign({}, state), { pickupPoint: Object.assign(Object.assign({}, state.pickupPoint), { highlightedPickupPoint: action.payload }) });
        case 'select_pickup_point':
            return Object.assign(Object.assign({}, state), { pickupPoint: Object.assign(Object.assign({}, state.pickupPoint), { selectedPickupPoint: action.payload }) });
        case 'select_store':
            return Object.assign(Object.assign({}, state), { info: Object.assign(Object.assign({}, state.info), { store: ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.storeId) || null, storeNumber: ((_c = action.payload) === null || _c === void 0 ? void 0 : _c.storeNumber) || null }), collectStore: Object.assign(Object.assign({}, state.collectStore), { selectedStore: action.payload }) });
        case 'edit_customer_details':
            return Object.assign(Object.assign({}, state), { editCustomerDetails: action.payload });
        case 'store_set_is_open':
            return Object.assign(Object.assign({}, state), { collectStore: Object.assign(Object.assign({}, state.collectStore), { isOpen: action.payload }) });
        default:
            return state;
    }
};
const CheckoutDeliveryPickupPointProvider = ({ children }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const buyDelivery = (0, ConfigurationUtil_1.getBuyDelivery)(aemConfiguration);
    const [{ info, activeDeliveryOption }] = (0, exports.useDeliveryState)();
    const [locationSearch, setLocationSearch] = (0, react_1.useState)('');
    const { getPickupPoints } = (0, common_queries_1.checkoutQueries)({ servicesEndpoint, defaultRequestParams });
    const getPickupPointsQuery = (0, react_query_1.useQuery)(getPickupPoints({
        countryCode: info.country,
        provider: (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.provider) || '',
        postcode: locationSearch,
        deliveryDate: (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.sendDate) || '',
        limit: buyDelivery.maxPickupPoints,
    }, {
        enabled: Boolean(!!locationSearch && activeDeliveryOption),
    }));
    return ((0, jsx_runtime_1.jsx)(PickupPointSearchContext.Provider, { value: {
            locationSearch,
            setLocationSearch: setLocationSearch,
            getPickupPointsQuery,
        }, children: children }));
};
const CheckoutDeliveryProvider = ({ children }) => {
    return ((0, jsx_runtime_1.jsx)(DeliveryContext.Provider, { value: (0, react_1.useReducer)(reducer, Object.assign({}, defaultState)), children: (0, jsx_runtime_1.jsx)(CheckoutDeliveryPickupPointProvider, { children: children }) }));
};
exports.CheckoutDeliveryProvider = CheckoutDeliveryProvider;
