"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPSwatchesCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SwatchUtil_1 = require("../../../util/SwatchUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const SLIDE_WIDTH = 34;
const SLIDES_TO_SCROLL_CORRECTION = 3;
const MiniPDPSwatchesCarousel = ({ colors, selectedColor, productCode, productImageCdnDomains, setProductColor, showTitle, boxProps = {
    mobile: { spacing: { marginBottom: 1.5 } },
    tablet: { spacing: { marginBottom: 2, paddingTop: 1 } },
}, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const swatchInformation = (0, ConfigurationUtil_1.getProductSwatchInformation)(aemConfiguration);
    const carouselId = (0, react_1.useMemo)(() => (0, common_utils_1.uniqueId)(), []);
    const renderSlide = (0, react_1.useCallback)((colour, index) => {
        const key = `color_swatch_${carouselId}_${colour.colourId}_${index}`;
        return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ImageRadioButton, { id: key, name: `color_swatch_${carouselId}`, value: colour.colourId.toString(), checked: colour.colourId === selectedColor.colourId, imageUrl: colour.swatch, imageLazyLoad: true, textOverlay: true, textImage: (0, SwatchUtil_1.getDiscountIconContent)(colour, swatchInformation), title: colour.color, onChange: () => setProductColor(colour.colourId), size: cotopaxi_1.ImageRadioButtonSize.SMALL }) }, key));
    }, [colors, selectedColor, setProductColor]);
    const calculateSlidesToScroll = (slidesToScroll, swatches) => swatches && swatches.length - 1 === slidesToScroll ? slidesToScroll : slidesToScroll - SLIDES_TO_SCROLL_CORRECTION;
    function onSwiperInit() {
        const maxSlides = Math.floor(this.width / SLIDE_WIDTH);
        this.params.slidesPerView = maxSlides;
        this.navigation.update();
        this.update();
    }
    function onSwiperResize() {
        const maxSlides = Math.floor(this.width / SLIDE_WIDTH);
        if (this.params.slidesPerView !== maxSlides) {
            this.params.slidesPerView = maxSlides;
            this.navigation.update();
            this.update();
        }
    }
    const colorSwatches = colors.map(productColor => (0, SwatchUtil_1.generateSwatchFromProductColor)(productColor, selectedColor, productCode, productImageCdnDomains));
    const colorSwatchesSlideConfig = {
        variant: cotopaxi_1.CarouselVariant.SWATCHES,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.SWATCHES,
        },
        showPagination: false,
        configurationOverrides: {
            preventClicks: false,
            watchOverflow: true,
            slidesPerGroup: calculateSlidesToScroll(5, colorSwatches),
            spaceBetween: 0,
            shouldSwiperUpdate: true,
            noSwiping: false,
            allowTouchMove: false,
            on: {
                init: onSwiperInit,
                resize: onSwiperResize,
            },
        },
    };
    return showTitle ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({}, boxProps, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: `${t('product:pdp.color')}: ` }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, dataQA: "color_name", children: selectedColor.description })] }), colorSwatches && colorSwatches.length > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, colorSwatchesSlideConfig, { children: colorSwatches.map((image, index) => renderSlide(image, index)) })) }))] }))) : (colorSwatches && colorSwatches.length > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, colorSwatchesSlideConfig, { children: colorSwatches.map((image, index) => renderSlide(image, index)) }))));
};
exports.MiniPDPSwatchesCarousel = MiniPDPSwatchesCarousel;
