"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopLoginForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const ShopLoginForm = ({ children, errorFeedback, initialEmailOrCardCode = '', initialLinkedEmail = '', isKnownCustomerCard, loading, onSubmit, validationSchema, validate, emailErrorRender, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.buy, cotopaxi_1.I18N_NAMESPACES.account, cotopaxi_1.I18N_NAMESPACES.checkout]);
    const initialValues = {
        emailOrCardCode: initialEmailOrCardCode,
        linkedEmail: initialLinkedEmail,
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { enableReinitialize: true, initialValues: initialValues, validate: validate, validationSchema: validationSchema, validateOnBlur: false, onSubmit: onSubmit, name: "login-form", method: "post", children: ({ values, errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, id: "shop-login-form__error-feedback" }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: "emailOrCardCode", children: t(`${cotopaxi_1.I18N_NAMESPACES.buy}:buy.shop.login.email.or.customer.card`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, Component: cotopaxi_1.Input, type: cotopaxi_1.InputType.EMAIL, name: "emailOrCardCode", id: "emailOrCardCode", value: values.emailOrCardCode, renderError: emailErrorRender, state: isKnownCustomerCard && !errors.emailOrCardCode
                        ? cotopaxi_1.InputState.SUCCESS
                        : errors.emailOrCardCode && touched.emailOrCardCode && cotopaxi_1.InputState.ERROR, adornmentEnd: isKnownCustomerCard && !errors.emailOrCardCode ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHECK, color: cotopaxi_1.IconColor.POSITIVE })) : (errors.emailOrCardCode &&
                        touched.emailOrCardCode && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE })) }), isKnownCustomerCard && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { iconSize: cotopaxi_1.IconSize.MEDIUM, text: t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:checkout.login.card.link.email`), variant: cotopaxi_1.FeedbackMessageVariant.INFO }) })), isKnownCustomerCard && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: "linkedEmail", children: t(`${cotopaxi_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, Component: cotopaxi_1.Input, type: cotopaxi_1.InputType.EMAIL, name: "linkedEmail", id: "linkedEmail", value: values.linkedEmail, renderError: emailErrorRender, state: errors.linkedEmail && touched.linkedEmail && cotopaxi_1.InputState.ERROR, placeholder: t(`${cotopaxi_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`), adornmentEnd: errors.linkedEmail &&
                                touched.linkedEmail && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }) })] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: isKnownCustomerCard ? 3 : 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, state: loading ? cotopaxi_1.ButtonState.LOADING : null, text: t(`${cotopaxi_1.I18N_NAMESPACES.buy}:buy.shop.login.submit`), dataQA: "login_btn" }) }), children] })) }));
};
exports.ShopLoginForm = ShopLoginForm;
