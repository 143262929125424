"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const ContentReducer_1 = require("../../content/reducers/ContentReducer");
const ContentSaga_1 = require("../../content/sagas/ContentSaga");
const loadContentModule = (initialData) => ({
    id: common_types_1.AEMScenes.CONTENT_TILE_LISTER,
    reducerMap: {
        content: (0, ContentReducer_1.loadContentReducer)(initialData ? initialData.content : null),
    },
    sagas: [ContentSaga_1.default],
});
exports.default = loadContentModule;
