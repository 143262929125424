"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopPaymentGroups = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PaymentGroupTillContainer_1 = require("../../../containers/buy/PaymentGroupTillContainer");
const ImageUtil_1 = require("../../../util/ImageUtil");
const PAY_ON_TILL = 'PAY_ON_TILL';
const ShopPaymentGroups = ({ logo, imageCdnPrefix }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [selectedPaymentGroup, setSelectedPaymentGroup] = (0, react_1.useState)(PAY_ON_TILL);
    const onChange = () => setSelectedPaymentGroup(PAY_ON_TILL);
    const gePaymentGroupContent = (group) => {
        switch (group) {
            case PAY_ON_TILL:
                return (0, jsx_runtime_1.jsx)(PaymentGroupTillContainer_1.PaymentGroupTillContainer, {});
            default:
                return null;
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList, { open: [selectedPaymentGroup], dataQA: "payment_form", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList.Item, { id: `shoplanding_${PAY_ON_TILL}`, title: t('buy.confirmation.shop.pay.at.checkout', { orderId: '' }), icon: logo && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: (0, ImageUtil_1.getImageFromCdn)(logo, imageCdnPrefix), size: cotopaxi_1.ImageSize.CHECK_LIST_ITEM_LOGO }) })), radio: {
                id: PAY_ON_TILL,
                name: 'payment-items-shoplanding',
                value: PAY_ON_TILL,
            }, onChange: onChange, children: selectedPaymentGroup && gePaymentGroupContent(PAY_ON_TILL) }) }));
};
exports.ShopPaymentGroups = ShopPaymentGroups;
