"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const asNumeral_1 = require("../../../vendors/asNumeral");
const MESSAGE_AMOUNT_TEMPLATE = '{{{AMOUNT}}}';
const ClosenessQualifier = ({ amountUntilFreeDelivery, currency, totalPriceSell, showProgressBar, freeDeliveryMessage, }) => {
    const getText = () => {
        return freeDeliveryMessage.replace(MESSAGE_AMOUNT_TEMPLATE, `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(amountUntilFreeDelivery)}`);
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, tabletBleed: true, mobile: { theme: cotopaxi_1.BoxTheme.TERTIARY }, tablet: { spacing: { paddingLeft: 1, paddingRight: 1 } }, desktop: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: getText(), isTransparent: true, iconSize: cotopaxi_1.IconSize.MAJOR, iconName: cotopaxi_1.IconName.LIGHT_BULB, variant: cotopaxi_1.FeedbackMessageVariant.INFO, children: showProgressBar && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: { marginTop: 2, marginBottom: 0.5 },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Progress, { progress: (totalPriceSell / (totalPriceSell + amountUntilFreeDelivery)) * 100 }) })) }) }));
};
exports.default = ClosenessQualifier;
