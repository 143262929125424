"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlReducer = exports.loadUrlReducer = exports.initialState = void 0;
const common_types_1 = require("@as-react/common-types");
const connected_react_router_1 = require("connected-react-router");
const common_utils_1 = require("@as-react/common-utils");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialState = {};
const generateMultiSelectFilterValue = (filteredItems, filterDisplayHint) => {
    const useCommaSeparator = !(0, common_utils_1.isNullOrUndefined)(filterDisplayHint) && filterDisplayHint.length > 0 && filterDisplayHint[0] === 'multiselect-and';
    return `(${filteredItems.map((item) => item.filterKey).join(`${useCommaSeparator ? ',' : ';'}`)})`;
};
const generateRangeFilterValue = (filteredItems) => {
    return `[${filteredItems.map((item) => item.filterKey).join(';')}]`;
};
const generateBucketFilterValue = (filteredItems) => {
    return `${filteredItems[filteredItems.length - 1].filterKey}`;
};
const generateTypeFilterValue = (filterObject) => filterObject.filteredItems.map((item) => `${filterObject.filterOn}:${item.filterKey}`);
const generateCategoryFilterValue = (filterObject) => {
    return `${filterObject.filterOn}:${filterObject.filteredItems[0].filterKey}`;
};
const removeUrlFilter = (initialFilter, action) => {
    if (!(0, common_utils_1.isNullOrUndefined)(initialFilter)) {
        return [
            ...initialFilter.filter((urlFilterObject) => urlFilterObject.filterOn !== action.payload.filterOn),
        ];
    }
    return [];
};
const setUrlFilter = (state, action, generateFilterValue) => {
    return [
        ...removeUrlFilter(state.filter, action),
        {
            filterOn: action.payload.filterOn,
            filterValue: generateFilterValue(action.payload.filteredItems, action.payload.filterDisplayHint),
        },
    ];
};
const setFilters = (state, action) => {
    if (action.payload.queryParamKey === common_types_1.ContentFilterQueryParamKey.TYPE) {
        return Object.assign(Object.assign({}, state), { type: generateTypeFilterValue(action.payload), page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT });
    }
    else if (action.payload.queryParamKey === common_types_1.ContentFilterQueryParamKey.CATEGORY) {
        return Object.assign(Object.assign({}, state), { category: generateCategoryFilterValue(action.payload), page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT });
    }
    else if ((0, common_utils_1.isMultiSelectFilter)(action.payload)) {
        return Object.assign(Object.assign({}, state), { filter: setUrlFilter(state, action, generateMultiSelectFilterValue), page: 0 });
    }
    else if ((0, common_utils_1.isRangeFilter)(action.payload)) {
        return Object.assign(Object.assign({}, state), { filter: setUrlFilter(state, action, generateRangeFilterValue), page: 0 });
    }
    else if ((0, common_utils_1.isBucketFilter)(action.payload)) {
        return Object.assign(Object.assign({}, state), { filter: setUrlFilter(state, action, generateBucketFilterValue), page: 0 });
    }
    return state;
};
const removeFilter = (state, action) => {
    if (action.payload.queryParamKey === common_types_1.ContentFilterQueryParamKey.TYPE) {
        return Object.assign(Object.assign({}, state), { type: [], page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT });
    }
    else if (action.payload.queryParamKey === common_types_1.ContentFilterQueryParamKey.CATEGORY) {
        return Object.assign(Object.assign({}, state), { category: null, page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT });
    }
    else {
        return Object.assign(Object.assign({}, state), { filter: removeUrlFilter(state.filter, action), page: 0 });
    }
};
const loadUrlReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return action.payload.location && action.payload.location.state
                    ? Object.assign({}, action.payload.location.state.url) : state;
            case ActionEnums_1.UrlActionTypes.SET_URL_PARAMS:
                return Object.assign(Object.assign({}, state), action.payload);
            case ActionEnums_1.FilterActionTypes.SET_FILTERS:
                return setFilters(state, action);
            case ActionEnums_1.FilterActionTypes.REMOVE_FILTER:
                return removeFilter(state, action);
            case ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS:
                return Object.assign(Object.assign({}, state), { filter: [], category: null, type: [], page: 0, storeId: null });
            default:
                return state;
        }
    };
};
exports.loadUrlReducer = loadUrlReducer;
exports.urlReducer = (0, exports.loadUrlReducer)();
