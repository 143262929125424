"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmailExists = exports.validateEmail = exports.validateEmailWithBackend = exports.EMAIL_VALIDATION_DEBOUNCE = void 0;
const tslib_1 = require("tslib");
const awesome_debounce_promise_1 = require("awesome-debounce-promise");
const FormValidationUtil_1 = require("../../util/FormValidationUtil");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const common_types_1 = require("@as-react/common-types");
exports.EMAIL_VALIDATION_DEBOUNCE = 250;
const validateEmailWithBackend = (email, context, servicesEndpoint, emailStatus, setSuggestedEmail, setEmailStatus) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    if (emailStatus === `${common_types_1.EmailValidationResponseStatus.SUGGESTION}_${email}`) {
        return context.createError({ message: `${common_types_1.EmailValidationResponseStatus.SUGGESTION}_${email}` });
    }
    if (emailStatus === `${common_types_1.EmailValidationResponseStatus.VALID}_${email}`) {
        return true;
    }
    if (emailStatus === `${common_types_1.EmailValidationResponseStatus.INVALID_CONDITIONALLY}_${email}`) {
        return context.createError({ message: `${common_types_1.EmailValidationResponseStatus.INVALID_CONDITIONALLY}_${email}` });
    }
    try {
        const response = yield CustomerApi_1.default.emailValidate(servicesEndpoint, email);
        if (response.suggestedEmail) {
            setSuggestedEmail === null || setSuggestedEmail === void 0 ? void 0 : setSuggestedEmail(response.suggestedEmail);
            return context.createError({ message: `${common_types_1.EmailValidationResponseStatus.SUGGESTION}_${email}` });
        }
        else if (response.status === common_types_1.EmailValidationResponseStatus.INVALID) {
            return context.createError({ message: `${common_types_1.EmailValidationResponseStatus.INVALID_CONDITIONALLY}_${email}` });
        }
        else {
            setEmailStatus === null || setEmailStatus === void 0 ? void 0 : setEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${email}`);
            return true;
        }
    }
    catch (_a) {
        return true;
    }
});
exports.validateEmailWithBackend = validateEmailWithBackend;
const validateEmail = (servicesEndpoint, skipValidation, emailStatus, setSuggestedEmail, setEmailStatus, withDebounce = true, skipValidationFor) => {
    const validate = (value, context) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (skipValidation || (skipValidationFor && skipValidationFor === value)) {
            return true;
        }
        else if (!value) {
            return context.createError({ message: common_types_1.EmailValidationResponseStatus.REQUIRED });
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(value)) {
            return context.createError({ message: common_types_1.EmailValidationResponseStatus.INVALID });
        }
        else {
            return yield (0, exports.validateEmailWithBackend)(value, context, servicesEndpoint, emailStatus, setSuggestedEmail, setEmailStatus);
        }
    });
    return withDebounce ? (0, awesome_debounce_promise_1.default)(validate, exports.EMAIL_VALIDATION_DEBOUNCE) : validate;
};
exports.validateEmail = validateEmail;
const validateEmailExists = (servicesEndpoint, defaultRequestParameters, errorMessages, withDebounce = true, shouldValidate = true, sendEmail = false, setIsCollapsedForm, withValidateEmail = false, skipValidation = false, emailStatus, setSuggestedEmail, setEmailStatus) => {
    const validate = (value, context) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!shouldValidate) {
            return true;
        }
        if (!value) {
            setIsCollapsedForm && setIsCollapsedForm(false);
            return true;
        }
        if (!FormValidationUtil_1.default.isValidEmailPattern(value)) {
            setIsCollapsedForm && setIsCollapsedForm(false);
            return context.createError({ message: errorMessages.invalidEmail });
        }
        try {
            const { accountStatusCode } = yield CustomerApi_1.default.emailExists(servicesEndpoint, defaultRequestParameters, value, sendEmail);
            if ([common_types_1.AuthStatusCode.NO_ACCOUNT, common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT].includes(accountStatusCode)) {
                setIsCollapsedForm && setIsCollapsedForm(false);
                if (withValidateEmail && !skipValidation) {
                    return yield (0, exports.validateEmailWithBackend)(value, context, servicesEndpoint, emailStatus, setSuggestedEmail, setEmailStatus);
                }
                return true;
            }
            if ([common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA, common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA].includes(accountStatusCode) &&
                setIsCollapsedForm) {
                setIsCollapsedForm(true);
                return context.createError({
                    message: errorMessages.sendLink,
                });
            }
            setIsCollapsedForm && setIsCollapsedForm(false);
            return context.createError({
                message: errorMessages[accountStatusCode],
            });
        }
        catch (_a) {
            setIsCollapsedForm && setIsCollapsedForm(false);
            if (withValidateEmail && !skipValidation) {
                return yield (0, exports.validateEmailWithBackend)(value, context, servicesEndpoint, emailStatus, setSuggestedEmail, setEmailStatus);
            }
            return true;
        }
    });
    return withDebounce ? (0, awesome_debounce_promise_1.default)(validate, exports.EMAIL_VALIDATION_DEBOUNCE) : validate;
};
exports.validateEmailExists = validateEmailExists;
