"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const MonetateReducer_1 = require("../../monetate/reducers/MonetateReducer");
const MonetateSagas_1 = require("../../monetate/sagas/MonetateSagas");
const loadMonetateModule = () => ({
    id: common_types_1.AEMScenes.MONETATE,
    reducerMap: { monetate: MonetateReducer_1.monetateReducer },
    sagas: [MonetateSagas_1.default],
});
exports.default = loadMonetateModule;
