"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlidesDisplayState = void 0;
const react_1 = require("react");
const INITIAL_SLIDE_INDEX = 0;
const getInitialSlidesDisplayState = (slidesAmount, activeSlideIndex, isNextSlideVideo) => new Array(slidesAmount)
    .fill(false)
    .map((_, index) => index === activeSlideIndex || (index === activeSlideIndex + 1 && !isNextSlideVideo));
const useSlidesDisplayState = (activeSlideIndex, slidesAmount, isNextSlideVideo) => {
    const [isRendered, setIsRendered] = (0, react_1.useState)(false);
    const [slidesDisplayState, setSlidesDisplayState] = (0, react_1.useState)(getInitialSlidesDisplayState(slidesAmount, activeSlideIndex, isNextSlideVideo));
    (0, react_1.useEffect)(() => {
        if (isRendered) {
            const isAllSlidesDisplayed = !slidesDisplayState.some(item => item);
            if (!isAllSlidesDisplayed) {
                const newSlidesLoadingState = slidesDisplayState.map((state, index) => index === activeSlideIndex - 1 || index === activeSlideIndex || index === activeSlideIndex + 1 ? true : state);
                setSlidesDisplayState(newSlidesLoadingState);
            }
        }
        else {
            setIsRendered(true);
        }
    }, [activeSlideIndex]);
    (0, react_1.useEffect)(() => {
        if (isRendered) {
            setSlidesDisplayState(getInitialSlidesDisplayState(slidesAmount, INITIAL_SLIDE_INDEX, isNextSlideVideo));
        }
    }, [slidesAmount]);
    return slidesDisplayState;
};
exports.useSlidesDisplayState = useSlidesDisplayState;
