"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroups = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const PaymentGroupUtil_1 = require("../../../util/PaymentGroupUtil");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const react_1 = require("react");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const PaymentGroup_1 = require("./payment-groups/PaymentGroup");
const PaymentGroupTitle_1 = require("./PaymentGroupTitle");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const IMAGE_PREFIX = '/content/dam/general/payment_logos/';
const PaymentGroups = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [selectedPaymentGroup, setSelectedPaymentGroup] = (0, react_1.useState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [{ info }, dispatch] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { paymentGroups, chosenPaymentOptionId } = info;
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const { postPaymentOptionId, postPaymentProviderInput } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postSavePoint } = (0, common_queries_1.basketMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutateAsync: mutatePostPaymentOptionId, isPending: postPaymentOptionPending } = (0, react_query_1.useMutation)(postPaymentOptionId);
    const { mutateAsync: mutatePostSavePoint } = (0, react_query_1.useMutation)(postSavePoint);
    const { mutateAsync: mutatePostPaymentProviderInput } = (0, react_query_1.useMutation)(postPaymentProviderInput);
    (0, react_1.useEffect)(() => {
        if (!paymentGroups) {
            return;
        }
        setSelectedPaymentGroup(() => paymentGroups.find(group => group.paymentOptions.find(option => option.paymentOptionId === chosenPaymentOptionId)));
    }, [paymentGroups, chosenPaymentOptionId]);
    (0, react_1.useEffect)(() => {
        const element = document.getElementById(`${selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.name}_${selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.sortOrder}`);
        if (!element)
            return;
        ScrollUtil_1.ScrollUtil.scrollToPosition(element.offsetTop);
    }, [selectedPaymentGroup]);
    (0, react_1.useEffect)(() => {
        if (!!paymentGroups && selectedPaymentGroup) {
            handlePaymentOptionClick(selectedPaymentGroup)();
        }
    }, [paymentGroups, selectedPaymentGroup]);
    if (!paymentGroups) {
        return null;
    }
    const webImagesList = (group) => Array.from(new Set(group.paymentOptions.map(item => item.webImage)));
    const handlePaymentOptionClick = (group) => () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const options = group.paymentOptions;
        const isSingleOption = options.length === 1;
        let optionId;
        let provider;
        let optionName;
        try {
            if (isSingleOption || group.name === common_types_1.PaymentGroupName.ECOCHEQUE) {
                optionId = options[0].paymentOptionId;
                provider = options[0].provider;
                optionName = options[0].name;
            }
            else {
                const findOption = options.find(opt => opt.name === group.name);
                optionId = findOption === null || findOption === void 0 ? void 0 : findOption.paymentOptionId;
                provider = findOption === null || findOption === void 0 ? void 0 : findOption.provider;
                optionName = findOption === null || findOption === void 0 ? void 0 : findOption.name;
                if (group.supportsTokenization) {
                    yield mutatePostSavePoint();
                    reduxDispatch(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(group.name));
                }
            }
            if (optionId && provider) {
                yield mutatePostPaymentOptionId(optionId);
                yield mutatePostSavePoint();
                setSelectedPaymentGroup(group);
                if (provider === common_types_1.PaymentProvider.KLARNA) {
                    const { clientToken } = yield mutatePostPaymentProviderInput(provider);
                    dispatch({ type: 'set_payment_klarna_token', payload: clientToken });
                }
            }
            else {
                setSelectedPaymentGroup(group);
            }
            dispatch({ type: 'set_payment_option_error', payload: { [group.name]: undefined } });
            if (optionName) {
                reduxDispatch(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(optionName));
            }
        }
        catch (e) {
            dispatch({ type: 'set_payment_option_error', payload: { [group.name]: t(common_types_1.ErrorMessage.GENERAL_PAYMENT) } });
        }
    });
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "3", children: paymentGroups.map(group => {
            const disabled = postPaymentOptionPending || !(0, PaymentGroupUtil_1.isAllPaymentOptionsValid)(group);
            const selected = group.name === (selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.name);
            return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { onClick: selected ? undefined : handlePaymentOptionClick(group), id: `${group.name}_${group.sortOrder}`, state: disabled ? 'disabled' : selected ? 'active' : 'resting', hasGapY: false, children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: group.name, name: "checkout-delivery-options", value: group.name, checked: selected, disabled: disabled, onChange: handlePaymentOptionClick(group) }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", cellProps: { align: 'center' }, children: (0, jsx_runtime_1.jsx)(PaymentGroupTitle_1.PaymentGroupTitle, { group: group, disabled: disabled }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "tag", cellProps: { align: 'center' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { gap: "2", children: webImagesList(group).map(image => ((0, jsx_runtime_1.jsx)(andes_react_1.Image, { width: 30, alt: group.name, source: (0, PaymentGroupUtil_1.getPaymentGroupImageUrl)(image, IMAGE_PREFIX) }, image))) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(PaymentGroup_1.PaymentGroup, { group: group, disabled: disabled, selected: selected }) })] }, `${group.name}_${group.sortOrder}`));
        }) }));
};
exports.PaymentGroups = PaymentGroups;
