"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const list_utils_1 = require("./list.utils");
const VerticalList_1 = require("./VerticalList");
const HorizontalList_1 = require("./HorizontalList");
const List = ({ listPosition, listTitle, listTitleColour, collapsible, listOrientation, listDivider, listDividerSize, uniqueListId, listType = 'none', bannerId, listItems, }) => {
    const renderTitle = listTitle ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.EXTRA_CONTENT, color: list_utils_1.aemColorToCotopaxiColor[listTitleColour], emphasized: true, children: listTitle })) : null;
    if ((!listOrientation || listOrientation === 'vertical') && collapsible) {
        return ((0, jsx_runtime_1.jsx)("div", { "data-analytics-bannerid": bannerId, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: uniqueListId, query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], iconColor: list_utils_1.aemColorToCotopaxiColor[listTitleColour], collapseTitle: listTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.EXTRA_CONTENT, color: list_utils_1.aemColorToCotopaxiColor[listTitleColour], emphasized: true, children: listTitle })), title: renderTitle, children: (0, jsx_runtime_1.jsx)(VerticalList_1.VerticalList, { items: listItems, listType: listType }) }) }));
    }
    return ((0, jsx_runtime_1.jsxs)("div", { "data-analytics-bannerid": bannerId, children: [renderTitle, listOrientation === 'horizontal' ? ((0, jsx_runtime_1.jsx)(HorizontalList_1.HorizontalList, { items: listItems, divider: listDivider, dividerSize: listDividerSize, position: listPosition })) : ((0, jsx_runtime_1.jsx)(VerticalList_1.VerticalList, { items: listItems, listType: listType }))] }));
};
exports.default = List;
