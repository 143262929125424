"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguagePopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const LanguagePopover = ({ currentLang, alternateUrl }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const isCurrentLang = (language) => language === currentLang;
    const getLabel = (language) => `${t(`language.${language}`)} (${language.toUpperCase()})`;
    const buttons = Object.keys(alternateUrl)
        .map(lang => ({
        key: lang,
        active: isCurrentLang(lang),
        href: alternateUrl[lang],
        text: getLabel(lang),
    }))
        .sort((lang1, lang2) => Number(lang2.active) - Number(lang1.active));
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { buttons: buttons });
};
exports.LanguagePopover = LanguagePopover;
