"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryChangeWatcher = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const CountryChangeWatcher = ({ countryIdFieldName, fieldPrefix = '', vatNumberFieldName = '', }) => {
    const fields = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.FINDER,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.ADDRESS_LINE_3,
        common_types_1.AddressFieldName.ADDRESS_LINE_4,
        common_types_1.AddressFieldName.COUNTY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
        common_types_1.AddressFieldName.STATE,
    ], name => (0, BuyFormUtil_1.generateFieldName)(name, fieldPrefix));
    const { resetField, trigger } = (0, react_hook_form_1.useFormContext)();
    const [countryId, vatNumber] = (0, react_hook_form_1.useWatch)({ name: [countryIdFieldName, vatNumberFieldName] });
    const countryIdOld = (0, cotopaxi_1.usePrevious)(countryId);
    const resetFieldValue = (field) => resetField(field, { keepTouched: false, defaultValue: '' });
    (0, react_1.useEffect)(() => {
        if (!countryIdOld || String(countryId) === String(countryIdOld))
            return;
        fields.forEach(resetFieldValue);
        setTimeout(() => {
            vatNumberFieldName && vatNumber && trigger(vatNumberFieldName);
        }, GeneralConstants_1.DELAY.DELAY_0);
    }, [countryId]);
    return null;
};
exports.CountryChangeWatcher = CountryChangeWatcher;
