"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPrefixId = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const Point_1 = require("../point/Point");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const getPrefixId = (collectStore) => (collectStore ? 'collect-store-' : 'collection-point-');
exports.getPrefixId = getPrefixId;
const PointsList = ({ points, height, active, activePointId, isMobile, collectStore = false, showMoreBtn, fasciaFirstMessage = false, mainWebShop, market, handleClickShowMore, setActivePointId, handleChangeView, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.store]);
    (0, react_1.useEffect)(() => {
        if (!showMoreBtn && active === 0) {
            ScrollUtil_1.ScrollUtil.scrollToElement(document.getElementById(`${(0, exports.getPrefixId)(collectStore)}${activePointId}`), true, 'nearest');
        }
    }, [activePointId]);
    const getCollectStoreBoxProps = () => collectStore
        ? {
            mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } },
            tablet: { spacing: { paddingLeft: 3, paddingRight: 4 } },
        }
        : null;
    const mapPointsCallBack = (point, index) => {
        const id = 'storeId' in point ? point.storeId : point.id;
        return ((0, jsx_runtime_1.jsxs)("div", { id: `${(0, exports.getPrefixId)(collectStore)}${id}`, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({}, getCollectStoreBoxProps(), { children: [index === 0 ? null : (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingTop: index === 0 ? 0 : index === 1 && fasciaFirstMessage ? 2 : 2.5,
                                    paddingBottom: index === 0 && fasciaFirstMessage ? 2 : 3,
                                },
                            }, tablet: { spacing: { paddingTop: 2.5, paddingBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(Point_1.default, { point: point, isActive: activePointId === id, withRadioButton: true, collectStore: collectStore, setActivePointId: setActivePointId, handleChangeView: handleChangeView }) })] })), index === 0 && fasciaFirstMessage && points.length > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: { paddingLeft: 2, paddingRight: 2, paddingTop: 1.5, paddingBottom: 1.5 },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: { spacing: { paddingLeft: 3, paddingRight: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, type: cotopaxi_1.TextType.CONTENT, children: StoreUtil_1.default.getStoreOwnFasciaTranslation(market, mainWebShop, t) }) }))] }, id));
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { tablet: { spacing: { paddingRight: collectStore ? 0 : 4 } }, children: [!isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getCollectStoreBoxProps(), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) }))), (0, jsx_runtime_1.jsxs)(cotopaxi_1.ScrollableList, { height: !showMoreBtn && !isMobile ? `${parseInt(height, 10) - 2}px` : null, children: [points.map(mapPointsCallBack), showMoreBtn && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getCollectStoreBoxProps(), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('product:product.review.list.button.show.more'), variant: cotopaxi_1.ButtonVariant.OUTLINED, fill: true, onClick: handleClickShowMore }) })))] }), !showMoreBtn && !isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getCollectStoreBoxProps(), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) })))] }));
};
exports.default = PointsList;
