"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileSort = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const UrlSelectors_1 = require("../../../../redux/url/selectors/UrlSelectors");
const react_redux_1 = require("react-redux");
const UrlActions_creators_1 = require("../../../../redux/url/actions/UrlActions.creators");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const FiltersMobileSort = ({ sortConfiguration }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const queryInformation = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlQueryInformationState)());
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    const { iconClasses, contentProps, toggleProps } = (0, andes_react_1.useCollapsible)();
    const updateSort = (newOrder) => dispatch(UrlActions_creators_1.urlActions.updateSort(newOrder));
    const getCurrentOrderItem = () => {
        const currentOrder = queryInformation.sort || sortConfiguration.defaultValue;
        const foundItem = sortConfiguration.properties.find(orderItem => orderItem.key === currentOrder);
        return foundItem || sortConfiguration.properties[0];
    };
    const [currentOrderItem, setCurrentOrderItem] = (0, react_1.useState)(getCurrentOrderItem);
    (0, react_1.useEffect)(() => {
        setCurrentOrderItem(getCurrentOrderItem);
    }, [sortConfiguration.properties, queryInformation.sort]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleToggle, Object.assign({}, toggleProps, { tag: "div", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", overflow: "hidden", flex: { grow: 1 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, lineClamp: 1, children: t('product:product.sort') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, className: iconClasses, children: (0, jsx_runtime_1.jsx)(andes_react_1.Icon, { name: "chevron-down" }) })] }) })), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, Object.assign({}, contentProps, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "2", children: sortConfiguration.properties.map(item => ((0, jsx_runtime_1.jsx)(cotopaxi_1.FilterItem, { type: cotopaxi_1.FilterItemType.SINGLE, filterOn: 'sort', filterKey: item.key, linkTitle: item.value, text: t(`product:${item.value}`), normalizedName: item.value, onChange: () => updateSort(item.key), active: currentOrderItem.key === item.key, size: cotopaxi_1.TextSize.SMALL }, item.key))) }) }))] }));
};
exports.FiltersMobileSort = FiltersMobileSort;
