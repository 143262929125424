"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountrySelector = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const CountryUtil_1 = require("../../../util/CountryUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormField_1 = require("../FormField");
const countryFieldName = 'address.idCountry';
const CountrySelector = ({ onCountryChange }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { lang, fictiveWebShop } = aemConfiguration;
    const defaultCountryCode = (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebShop);
    const { setValue } = (0, react_hook_form_1.useFormContext)();
    const countryValue = (0, react_hook_form_1.useWatch)({ name: countryFieldName });
    const { getCountries } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: { countries = [], countiesOptions = [] } = {} } = (0, react_query_1.useQuery)(getCountries({
        select: data => ({
            countries: data,
            countiesOptions: (0, common_utils_1.sortAlphabetically)(data.map(country => ({
                id: country.idCountry,
                text: country.countryDescriptionTranslations[lang.toUpperCase()],
            })), 'text'),
        }),
    }));
    (0, react_1.useEffect)(() => {
        if (!defaultCountryCode || !!countryValue || !countries.length)
            return;
        const countryByCode = countries.find(country => country.countryCode === defaultCountryCode.toUpperCase());
        setValue(countryFieldName, countryByCode === null || countryByCode === void 0 ? void 0 : countryByCode.idCountry);
    }, [countries, defaultCountryCode]);
    (0, react_1.useEffect)(() => {
        if (!countryValue || !countries.length)
            return;
        const countryById = countries.find(country => country.idCountry === parseInt(countryValue, 10));
        countryById && onCountryChange(countryById);
    }, [countryValue, countries]);
    return ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: countryFieldName, children: (_a) => {
            var _b = _a.fieldProps, { ref, value, onChange } = _b, options = tslib_1.__rest(_b, ["ref", "value", "onChange"]), { error } = _a;
            return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, { input: Object.assign(Object.assign({}, options), { id: options.name, label: t('account.details.country'), placeholder: t('account.details.country.placeholder'), error }), items: countiesOptions, onSelect: value => onChange(value.id), value: countiesOptions.length ? value : undefined, dataTestId: "country" }));
        } }));
};
exports.CountrySelector = CountrySelector;
