"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizebayContainer = exports.SIZEBAY_CONTAINER_ID = exports.SIZEBAY_SCRIPT_KEY = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ScriptLoaderUtil_1 = require("../../util/ScriptLoaderUtil");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
exports.SIZEBAY_SCRIPT_KEY = 'sizebay-script';
exports.SIZEBAY_CONTAINER_ID = 'sizebay-container';
const SIZEBAY_RENDERED_EVENT = 'sizebay:rendered';
const SIZEBAY_WIDGET_BUTTON_ID = 'szb-vfr-button';
const SIZEBAY_CHART_BUTTON_ID = 'szb-chart-button';
const SizebayContainer = () => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const sizebayScript = (0, ConfigurationUtil_1.getSizebayScript)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        if (!sizebayScript) {
            return;
        }
        ScriptLoaderUtil_1.default.loadScript(sizebayScript, exports.SIZEBAY_SCRIPT_KEY);
        window.addEventListener(SIZEBAY_RENDERED_EVENT, () => {
            const widgetButton = document.getElementById(SIZEBAY_WIDGET_BUTTON_ID);
            const chartButton = document.getElementById(SIZEBAY_CHART_BUTTON_ID);
            if (widgetButton) {
                const widgetButtonClickHandler = () => {
                    dispatch(AnalyticsActions_creators_1.analyticsActions.sizebayClick(AnalyticsEnums_1.EventActions.SIZEBAY_WIDGET_CLICK));
                    widgetButton.removeEventListener('click', widgetButtonClickHandler);
                };
                widgetButton.addEventListener('click', widgetButtonClickHandler);
            }
            if (chartButton) {
                const chartButtonClickHandler = () => {
                    dispatch(AnalyticsActions_creators_1.analyticsActions.sizebayClick(AnalyticsEnums_1.EventActions.SIZEBAY_GUIDE_CLICK));
                    chartButton.removeEventListener('click', chartButtonClickHandler);
                };
                chartButton.addEventListener('click', chartButtonClickHandler);
            }
        });
    }, [sizebayScript]);
    if (!sizebayScript) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)("div", { id: exports.SIZEBAY_CONTAINER_ID });
};
exports.SizebayContainer = SizebayContainer;
