"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamicModuleLoaderAnalyticsGoogleTagManager = exports.analyticsGoogleTagManagerMiddleware = void 0;
const logger_1 = require("@redux-beacon/logger");
const redux_beacon_1 = require("redux-beacon");
const redux_beacon_google_tag_manager_1 = require("@as-react/redux-beacon-google-tag-manager");
const common_utils_1 = require("@as-react/common-utils");
const AnalyticsUtil_1 = require("../../util/AnalyticsUtil");
const ActionEnums_1 = require("../../enums/ActionEnums");
exports.analyticsGoogleTagManagerMiddleware = (0, redux_beacon_1.createMiddleware)({
    [ActionEnums_1.ListerActionTypes.LISTER_INIT]: (_action, prevState) => (0, common_utils_1.mergeObjectDeep)({}, (0, AnalyticsUtil_1.generateGoogleAnalyticsProductImpressionEvent)(prevState.lister.items) || {}),
    [ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsProductImpressionEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.GOOGLE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsBasketEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.REMOVE_GOOGLE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsBasketEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.UPDATE_GOOGLE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsBasketEvent)(action.payload),
    [ActionEnums_1.ProductActionTypes.INIT]: (action, _prevState, nextState) => { var _a; return (0, AnalyticsUtil_1.generateGoogleAnalyticsProductDetailEvent)(nextState.product, 'productDetail', (_a = action.payload) === null || _a === void 0 ? void 0 : _a.currency); },
    [ActionEnums_1.AnalyticsActionTypes.GIFT_VOUCHER_INIT]: (action, _prevState, nextState) => (0, AnalyticsUtil_1.generateGoogleAnalyticsProductDetailEvent)(nextState.product, 'productDetail', action.payload),
    [ActionEnums_1.ProductActionTypes.SET_COLOR]: (_, _prevState, nextState) => (0, AnalyticsUtil_1.generateGoogleAnalyticsProductDetailEvent)(nextState.product, 'productColourSwitch'),
    [ActionEnums_1.ProductActionTypes.SELECT_SIZE]: (_, _prevState, nextState) => (0, AnalyticsUtil_1.generateGoogleAnalyticsSizePickedEvent)(nextState.product, 'productSizePicked'),
    [ActionEnums_1.AnalyticsActionTypes.CHECKOUT_STEP]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsCheckoutStepEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.ANALYTICS_GOOGLE_CONFIRMATION_PAGE]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsTransactionDataEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.LOGIN_STEP_OPTION]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsCheckoutOptionEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CONSENT_UPDATE_PREFERENCES]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsCookieConsentTriggers)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CONSENT_ACCEPT_ALL]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsCookieConsentTriggers)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.DELIVERY_STEP]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsDeliveryStep)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.DELIVERY_STEP_OPTION]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsDeliveryStepOption)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.PAYMENT_STEP]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsPaymentStep)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.PAYMENT_STEP_OPTION]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsPaymentStepOption)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.LISTER_VIEW_PRODUCT_LIST]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsListerViewProductListEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CUSTOMER_IDENTIFICATION]: (action) => (0, AnalyticsUtil_1.generateGoogleCustomerIdentityAction)(action.payload.identity, action.payload.customerInfo),
    [ActionEnums_1.AnalyticsActionTypes.PAYBACK_ENRICHMENT_CHANGED]: (action) => (0, AnalyticsUtil_1.generateGoogleAnalyticsPaybackEnrichmentChanged)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.PAYBACK_ERROR]: ({ payload }) => (0, AnalyticsUtil_1.generateGoogleAnalyticsPaybackError)(payload),
}, (0, redux_beacon_google_tag_manager_1.default)(), !common_utils_1.Environment.isProduction() ? { logger: logger_1.default } : undefined);
const createDynamicModuleLoaderAnalyticsGoogleTagManager = () => ({
    id: 'startup-analytics-google-tag-manager',
    middlewares: [exports.analyticsGoogleTagManagerMiddleware],
});
exports.createDynamicModuleLoaderAnalyticsGoogleTagManager = createDynamicModuleLoaderAnalyticsGoogleTagManager;
