"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CompareConstants_1 = require("../../../constants/CompareConstants");
const compareActions = {
    createAddAction(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_ADD,
            payload,
        };
    },
    createInitAction(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_INIT,
            payload,
        };
    },
    createCompareProductDisplayed() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_PAGE_PRODUCT_DISPLAYED,
        };
    },
    createCompareNoProductDisplayed() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_PAGE_NO_PRODUCT_DISPLAYED,
        };
    },
    createCompareProductDisplayedToggle(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_PAGE_PRODUCT_DISPLAYED_TOGGLE,
            payload,
        };
    },
    createToggleLimitReachedAction(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_TOGGLE_LIMIT_REACHED,
            payload,
        };
    },
    createRemoveAction(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_REMOVE,
            payload,
        };
    },
    createUpdateOnListerAction(product) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_UPDATE,
            payload: {
                product,
                sourceComponent: CompareConstants_1.COMPARE_TRIGGERED_COMPONENT.productLister,
            },
        };
    },
    createUpdateOnProductDetailPageAction(product) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_UPDATE,
            payload: {
                product,
                sourceComponent: CompareConstants_1.COMPARE_TRIGGERED_COMPONENT.productDetail,
            },
        };
    },
    createUpdateInProductComparePageAction(product) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_UPDATE,
            payload: {
                product,
                sourceComponent: CompareConstants_1.COMPARE_TRIGGERED_COMPONENT.comparePage,
            },
        };
    },
    createUpdateInProductCompareBoxAction(product) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_UPDATE,
            payload: {
                product,
                sourceComponent: CompareConstants_1.COMPARE_TRIGGERED_COMPONENT.productCompareBox,
            },
        };
    },
    createUpdateProductsAction(payload) {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS,
            payload,
        };
    },
    createPopupCloseAction() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_POPUP_CLOSE,
        };
    },
    createPopupOpenAction() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_POPUP_OPEN,
        };
    },
    createPageOpenAction() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_PAGE_OPEN,
        };
    },
    createPopupToggleAction() {
        return {
            type: ActionEnums_1.CompareActionTypes.COMPARE_POPUP_TOGGLE,
        };
    },
};
exports.default = compareActions;
