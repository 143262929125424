"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollUtil = exports.NAVIGATION_MENU_OFFSET = exports.STICKY_BAR_OFFSET_DESKTOP = void 0;
const awesome_debounce_promise_1 = require("awesome-debounce-promise");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const SCROLLING_BEHAVIOR_SMOOTH = 'smooth';
const SCROLLING_BEHAVIOR_AUTO = 'auto';
const SCROLL_DEBOUNCE = 100;
const FORM_ERROR_ELEMENT_OFFSET_DESKTOP = 50;
const FORM_ERROR_ELEMENT_OFFSET_MOBILE = 150;
exports.STICKY_BAR_OFFSET_DESKTOP = 80;
exports.NAVIGATION_MENU_OFFSET = 110;
class ScrollUtil {
    /**
     * Scroll to an element, with the option to make it smooth
     *
     * @param {HTMLElement} element element
     * @param {boolean} smooth smoothy scroll
     * @param {ScrollLogicalPosition} verticalAlignment vertical alignment scroll
     * @param {ScrollLogicalPosition} horizontalAlignment horizontal alignment scroll
     */
    static scrollToElement(element, smooth = false, verticalAlignment = 'start', horizontalAlignment = 'nearest') {
        if (element) {
            const scrollBehavior = smooth ? SCROLLING_BEHAVIOR_SMOOTH : SCROLLING_BEHAVIOR_AUTO;
            element.scrollIntoView({
                behavior: scrollBehavior,
                block: verticalAlignment,
                inline: horizontalAlignment,
            });
        }
    }
    /**
     * Scroll to the top of the page, with the option to make it smooth
     *
     * @param {boolean} smooth smoothy scroll
     */
    static scrollToTop(smooth = false) {
        const bodySelect = document.getElementsByTagName('body');
        if (bodySelect === null || bodySelect === void 0 ? void 0 : bodySelect[0]) {
            ScrollUtil.scrollToElement(bodySelect[0], smooth);
        }
    }
    /**
     * Scroll to the position, with the option to make it smooth
     *
     * @param {number} top top position
     * @param {number} left left position
     * @param {boolean} smooth smoothy scroll
     * @param {Function} onScrollFinish will be called after scrolling is completed
     */
    static scrollToPosition(top = 0, left = 0, smooth = true, onScrollFinish) {
        const scrollBehavior = smooth ? SCROLLING_BEHAVIOR_SMOOTH : SCROLLING_BEHAVIOR_AUTO;
        const roundedTop = Math.floor(top);
        if (onScrollFinish) {
            const onScroll = (0, awesome_debounce_promise_1.default)(() => {
                if (roundedTop === Math.floor(window.scrollY)) {
                    window.removeEventListener('scroll', onScroll);
                    onScrollFinish();
                }
            }, SCROLL_DEBOUNCE);
            window.addEventListener('scroll', onScroll);
            onScroll();
            // remove event listener after a few seconds, because in case of not "finishing"
            // the scroll (fe. user scrolls itself during scroll), this will never be removed
            // and possible be fired on an unwanted moment
            setTimeout(() => {
                window.removeEventListener('scroll', onScroll);
            }, GeneralConstants_1.DELAY.DELAY_3000);
        }
        window.scroll({ top: roundedTop, left, behavior: scrollBehavior });
    }
    static scrollToErrorElement(element, isDesktop, onScrollFinish) {
        if (!element) {
            return undefined;
        }
        const top = element.getBoundingClientRect().top +
            window.scrollY -
            (isDesktop ? FORM_ERROR_ELEMENT_OFFSET_DESKTOP : FORM_ERROR_ELEMENT_OFFSET_MOBILE);
        element.getBoundingClientRect().top < 0 ? this.scrollToPosition(top, 0, true, onScrollFinish) : onScrollFinish === null || onScrollFinish === void 0 ? void 0 : onScrollFinish();
    }
    static getScrollingOffset(isDesktop, isTablet, withStickyBar) {
        if (isDesktop && withStickyBar) {
            return exports.STICKY_BAR_OFFSET_DESKTOP;
        }
        if (isTablet) {
            return exports.NAVIGATION_MENU_OFFSET;
        }
        return 0;
    }
}
exports.ScrollUtil = ScrollUtil;
