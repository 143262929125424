"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyForm = exports.BUY_FORM_ERROR_FEEDBACK_ID = exports.BUY_FORM_NAME = exports.initialValuesBuyForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
exports.initialValuesBuyForm = {
    idTitle: null,
    firstName: '',
    middleName: '',
    lastName: '',
    postCode: '',
    houseNumber: '',
    houseNumberAddition: '',
    city: '',
    county: '',
    state: '',
    stateIso: '',
    country: '',
    phoneNumber: '',
    deliveryDate: '',
    address: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    companyName: '',
    customTown: false,
    zipCode: '',
    finder: '',
    chooseAddress: '',
    saveAddress: false,
    alternateInvoiceAddress: false,
    invoiceCompanyName: '',
    invoiceVatNumber: '',
    invoiceCompanyDepartment: '',
    needInvoice: false,
    invoicePostCode: '',
    invoiceHouseNumber: '',
    invoiceHouseNumberAddition: '',
    invoiceAddress: '',
    invoiceCity: '',
    invoiceCustomTown: false,
    invoiceFinder: '',
};
exports.BUY_FORM_NAME = 'BuyForm';
exports.BUY_FORM_ERROR_FEEDBACK_ID = 'buy-form__error-feedback';
const BuyForm = ({ initialValues = {}, onSubmit, buttonState, validate = () => ({}), children, onSubmitError, errorFeedback, checkoutStep, buttonText, deliveryPromiseMessage, validateOnBlur = false, confirmBeforeLeave = false, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const getSubmitText = () => {
        switch (checkoutStep) {
            case common_types_1.RoutePathname.DELIVERY:
                return t('buy.delivery.continue.payment');
            case common_types_1.RoutePathname.PAYMENT:
                return t('buy.payment.submit');
            default:
                return '';
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "buy-form", onSubmit: onSubmit, validate: validate, initialValues: initialValues, confirmBeforeLeave: confirmBeforeLeave, onSubmitError: onSubmitError, enableReinitialize: true, validateOnBlur: validateOnBlur, children: ({ errors, touched, values, status }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } }, tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, children: [react_1.Children.map(children, (child) => {
                            var _a;
                            if (!child)
                                return null;
                            const props = {
                                errors,
                                touched,
                                values,
                                status,
                            };
                            return (0, react_1.cloneElement)(child, ((_a = child.props) === null || _a === void 0 ? void 0 : _a.parent) === exports.BUY_FORM_NAME ? props : undefined);
                        }), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginTop: react_1.Children.toArray(children).length === 0 ? 0 : 2,
                                    marginBottom: react_1.Children.toArray(children).length === 0 ? 0 : 5,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, id: exports.BUY_FORM_ERROR_FEEDBACK_ID }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            padding: 2,
                            marginTop: react_1.Children.count(children) === 0 && !errorFeedback ? 0 : errorFeedback ? 3 : 5,
                        },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: {
                        spacing: {
                            paddingBottom: 2.5,
                            paddingTop: 2.5,
                            paddingLeft: 3,
                            paddingRight: 3,
                        },
                    }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [deliveryPromiseMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, grid: !isMobile ? 7 : 12, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1.5 } }, tablet: { spacing: { paddingTop: 1, paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.POSITIVE, dataQA: "delivery_promise", children: deliveryPromiseMessage }) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: !isMobile ? { span: 5, push: !deliveryPromiseMessage && 7 } : 12, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, iconRight: {
                                        name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                        size: cotopaxi_1.IconSize.MAJOR,
                                    }, text: buttonText || getSubmitText(), state: buttonState, dataQA: "buy_form_submit" }) })] }) })] })) }));
};
exports.BuyForm = BuyForm;
