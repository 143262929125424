"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductWizardFlowSelectorFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductWizardFlowSelectorFooter = ({ activeStateLabel, disabledStateLabel, disabled, onClick, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const label = disabled
        ? disabledStateLabel || t('productWizard.flow.button.selectOption')
        : activeStateLabel || t('productWizard.flow.button.findProduct');
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, fill: isMobile, text: label, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, onClick: onClick, state: disabled ? cotopaxi_1.ButtonState.DISABLED : cotopaxi_1.ButtonState.INTERACTIVE }) }) }));
};
exports.ProductWizardFlowSelectorFooter = ProductWizardFlowSelectorFooter;
