"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18nConstants_1 = require("../../constants/i18nConstants");
const i18nConfigurationGeneral_1 = require("./i18nConfigurationGeneral");
const common_utils_1 = require("@as-react/common-utils");
const isDev = common_utils_1.Environment.isDevelopment();
const AEM_USERNAME = 'admin';
const AEM_PASSWORD = 'admin';
const generateBackEndI18nInitOptions = (version, translationEndpoint) => (Object.assign(Object.assign(Object.assign({}, i18nConfigurationGeneral_1.i18nConfigurationGeneral), { preload: i18nConfigurationGeneral_1.i18nConfigurationGeneral.supportedLngs }), {
    backend: Object.assign(Object.assign(Object.assign({}, i18nConfigurationGeneral_1.i18nConfigurationGeneral.backend), { loadPath: `${translationEndpoint}${i18nConstants_1.I18N_TRANSLATIONS_BASE_PATH}`, 
        // path to post missing resources
        addPath: `${translationEndpoint}${i18nConstants_1.I18N_TRANSLATIONS_BASE_PATH}`, queryStringParams: {
            version: version,
        } }), (isDev
        ? {
            customHeaders: {
                Authorization: `Basic ${btoa(`${AEM_USERNAME}:${AEM_PASSWORD}`)}`,
            },
        }
        : {})),
}));
exports.default = (version, translationLanguage = '') => generateBackEndI18nInitOptions(version, translationLanguage);
