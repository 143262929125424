"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productReducer = exports.loadProductReducer = exports.isNotifyMeEnabled = exports.initialState = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const connected_react_router_1 = require("connected-react-router");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CompareReducer_1 = require("../../compare/reducers/CompareReducer");
const WishListReducer_1 = require("../../wishList/reducers/WishListReducer");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialState = {};
const isNotifyMeEnabled = (pdpVersion) => {
    const config = (0, common_utils_1.getCustomWindowObject)('SITE.Config');
    return !config.isShopLanding && pdpVersion === common_types_1.PDPVersion.V2;
};
exports.isNotifyMeEnabled = isNotifyMeEnabled;
const updateProductsCompareState = (products, compareItems) => (products === null || products === void 0 ? void 0 : products.length) ? (0, CompareReducer_1.toggleComparedStateForItems)(products, compareItems) : products;
const initializeProductsWishListState = (products, action) => (products === null || products === void 0 ? void 0 : products.length) ? (0, WishListReducer_1.initializeWishListProducts)(products, action) : products;
const updateProductsWishListState = (products, action, inWishList) => ((products === null || products === void 0 ? void 0 : products.length) ? (0, WishListReducer_1.updateWishListProducts)(products, action, inWishList) : products);
const loadProductReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const statePayload = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.state;
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return ((_d = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.state)
                    ? Object.assign(Object.assign({}, statePayload.product), { selectedSku: !statePayload.product.selectedSku && state.selectedSku
                            ? state.selectedSku
                            : statePayload.product.selectedSku, deliveryPromise: !statePayload.product.deliveryPromise && state.deliveryPromise
                            ? state.deliveryPromise
                            : statePayload.product.deliveryPromise }) : state;
            case ActionEnums_1.ProductActionTypes.SELECT_SIZE:
                return Object.assign(Object.assign(Object.assign({}, state), { selectedSku: action.payload.sku, notifyMeError: null }), (action.payload.isSoldOut ? { deliveryPromise: null } : {}));
            case ActionEnums_1.ProductActionTypes.SET_SIZE_COMBINATION_NOT_EXIST:
                return Object.assign(Object.assign({}, state), { sizeCombinationNotExist: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_ESI_DATA: {
                const productColorVariations = state.productColorVariations.map(productColorVariation => (Object.assign(Object.assign(Object.assign({}, productColorVariation), (action.payload.productAvailability &&
                    (0, common_utils_1.setColorAvailability)(action.payload.productAvailability, productColorVariation.colorId))), { sizes: productColorVariation.sizes
                        .map(productColorVariationSize => (Object.assign(Object.assign({}, productColorVariationSize), (action.payload.productAvailability &&
                        (0, common_utils_1.setSkuAvailability)(action.payload.productAvailability, productColorVariation.colorId, productColorVariationSize)))))
                        .filter((productColorVariationSize) => (0, common_utils_1.isNullOrUndefined)(productColorVariationSize.availability) ||
                        productColorVariationSize.availability > 0 ||
                        productColorVariationSize.historicAvailable) })));
                return Object.assign(Object.assign(Object.assign(Object.assign({}, state), { productColorVariations: productColorVariations }), (action.payload.productPrice && {
                    unavailableOnline: action.payload.productPrice.unavailableOnline,
                })), { selectedSku: (0, common_utils_1.checkSizesAndSetDefaultSku)(state.selectedColorId, productColorVariations, state.selectedSku, (0, exports.isNotifyMeEnabled)(state.pdpVersion)) });
            }
            case ActionEnums_1.ProductActionTypes.SET_COLOR:
                return Object.assign(Object.assign({}, state), { selectedColorId: action.payload, selectedSku: (0, common_utils_1.checkSizesAndSetDefaultSku)(action.payload, state.productColorVariations, undefined, (0, exports.isNotifyMeEnabled)(state.pdpVersion)), deliveryPromise: undefined, notifyMeError: null });
            case ActionEnums_1.ProductActionTypes.SET_DELIVERY_PROMISE:
                return Object.assign(Object.assign({}, state), { deliveryPromise: action.payload });
            case ActionEnums_1.ProductActionTypes.GET_DELIVERY_PROMISE_EXTENDED:
                return Object.assign(Object.assign({}, state), { deliveryPromiseExtended: Object.assign({ loading: true }, action.payload) });
            case ActionEnums_1.ProductActionTypes.SET_DELIVERY_PROMISE_EXTENDED:
                return Object.assign(Object.assign({}, state), { deliveryPromiseExtended: Object.assign({ loading: false }, action.payload) });
            case ActionEnums_1.ProductActionTypes.SET_NEW_PRODUCT:
                return Object.assign({}, action.payload);
            case ActionEnums_1.ProductActionTypes.CLEAR_PRODUCT_DATA:
                return exports.initialState;
            case ActionEnums_1.ProductActionTypes.SET_SELECTED_PRODUCT_TAB:
                return Object.assign(Object.assign({}, state), { selectedProductTab: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_REVIEWS_DATA:
                return Object.assign(Object.assign({}, state), { productReviews: action.payload.reviews, productReviewsLang: action.payload.lang });
            case ActionEnums_1.ProductActionTypes.SET_PRODUCT_VIDEOS:
                return Object.assign(Object.assign({}, state), { productVideos: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_PRODUCT_CONTENT_VIDEOS:
                return Object.assign(Object.assign({}, state), { productContentVideos: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_TECHNICAL_CATEGORIES:
                return Object.assign(Object.assign({}, state), { productTechnicalCategories: action.payload });
            case ActionEnums_1.ProductActionTypes.GET_LINKED_PRODUCTS:
                return Object.assign(Object.assign({}, state), { linkedProducts: [] });
            case ActionEnums_1.ProductActionTypes.SET_LINKED_PRODUCTS:
                return Object.assign(Object.assign({}, state), { linkedProducts: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_PRODUCT_CARE_DELIVERY_PROMISE:
                return Object.assign(Object.assign({}, state), { productCare: Object.assign(Object.assign({}, state.productCare), { productsData: (0, ProductUtil_1.setDeliveryPromiseToProducts)(state.productCare.productsData, action.payload) }) });
            case ActionEnums_1.ProductActionTypes.SIZE_GUIDE_OPEN:
                return Object.assign(Object.assign({}, state), { sizeGuideViewed: true });
            case ActionEnums_1.ProductActionTypes.SET_BUNDLE:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [action.payload.colourId]: Object.assign({}, action.payload) }) });
            case ActionEnums_1.ProductActionTypes.BUNDLE_SET_SELECTED_SLOT_STATE:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { bundleSlots: state.bundles[state.selectedColorId].bundleSlots.map(slot => {
                                if (slot.slotNumber !== action.payload.slotNumber) {
                                    return slot;
                                }
                                return Object.assign(Object.assign({}, slot), { isSelected: action.payload.state });
                            }) }) }) });
            case ActionEnums_1.ProductActionTypes.BUNDLE_SET_DISPLAYED_PRODUCT:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { bundleSlots: state.bundles[state.selectedColorId].bundleSlots.map(slot => {
                                if (slot.slotNumber !== action.payload.slotNumber) {
                                    return slot;
                                }
                                return Object.assign(Object.assign({}, slot), { displayedProduct: action.payload.displayedProduct });
                            }) }) }) });
            case ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_START:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { calculations: Object.assign(Object.assign({}, state.bundles[state.selectedColorId].calculations), { status: common_types_1.FetchStatus.PENDING }) }) }) });
            case ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_SUCCESS:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { calculations: {
                                status: common_types_1.FetchStatus.RESOLVED,
                                data: action.payload,
                            } }) }) });
            case ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_FAILURE:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { calculations: {
                                status: common_types_1.FetchStatus.REJECTED,
                                error: action.payload,
                            } }) }) });
            case ActionEnums_1.ProductActionTypes.SET_SELECTED_BUNDLE_TAB:
                return Object.assign(Object.assign({}, state), { bundles: Object.assign(Object.assign({}, state.bundles), { [state.selectedColorId]: Object.assign(Object.assign({}, state.bundles[state.selectedColorId]), { selectedTab: action.payload }) }) });
            case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
                return Object.assign(Object.assign({}, state), { alternativeProducts: updateProductsCompareState(state.alternativeProducts, action.payload), productCare: Object.assign(Object.assign({}, state.productCare), (((_e = state.productCare) === null || _e === void 0 ? void 0 : _e.productsData)
                        ? {
                            productsData: updateProductsCompareState(state.productCare.productsData, action.payload),
                        }
                        : {})) });
            case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
                return Object.assign(Object.assign({}, state), { alternativeProducts: initializeProductsWishListState(state.alternativeProducts, action), productCare: Object.assign(Object.assign({}, state.productCare), (((_f = state.productCare) === null || _f === void 0 ? void 0 : _f.productsData)
                        ? { productsData: initializeProductsWishListState(state.productCare.productsData, action) }
                        : {})) });
            case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
                return Object.assign(Object.assign({}, state), { alternativeProducts: updateProductsWishListState(state.alternativeProducts, action, true), productCare: Object.assign(Object.assign({}, state.productCare), (((_g = state.productCare) === null || _g === void 0 ? void 0 : _g.productsData)
                        ? {
                            productsData: updateProductsWishListState(state.productCare.productsData, action, true),
                        }
                        : {})) });
            case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
                return Object.assign(Object.assign({}, state), { alternativeProducts: updateProductsWishListState(state.alternativeProducts, action, false), productCare: Object.assign(Object.assign({}, state.productCare), (((_h = state.productCare) === null || _h === void 0 ? void 0 : _h.productsData)
                        ? {
                            productsData: updateProductsWishListState(state.productCare.productsData, action, false),
                        }
                        : {})) });
            case ActionEnums_1.ProductActionTypes.SET_ALTERNATIVES:
                return Object.assign(Object.assign({}, state), { alternativeProducts: action.payload });
            case ActionEnums_1.ProductActionTypes.SET_NOTIFY_ME_ERROR:
                return Object.assign(Object.assign({}, state), { notifyMeError: action.payload });
            default:
                return state;
        }
    };
};
exports.loadProductReducer = loadProductReducer;
exports.productReducer = (0, exports.loadProductReducer)();
