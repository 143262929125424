"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const PromoBasketPopupCTA_1 = require("../components/promo-basket-popup/PromoBasketPopupCTA");
const PromoBasketPopupCTAContainer = ({ addToBasket, closePopup }) => ((0, jsx_runtime_1.jsx)(PromoBasketPopupCTA_1.PromoBasketPopupCTA, { addToBasket: addToBasket, closePopup: closePopup }));
const mapDispatchToProps = {
    addToBasket: BasketActions_creators_1.basketActions.createBasicAddAction,
};
exports.default = (0, react_redux_1.connect)(null, mapDispatchToProps)(PromoBasketPopupCTAContainer);
