"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promoBasketPopupReducer = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const initialState = {
    collapsed: true,
    message: null,
};
const promoBasketPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.BasketActionTypes.ADD_SUCCESS:
        case ActionEnums_1.BasketActionTypes.ADD_BUNDLE_SUCCESS:
        case ActionEnums_1.PromoBasketPopupActionTypes.POPUP_CLOSE:
            return Object.assign(Object.assign({}, state), { collapsed: true });
        case ActionEnums_1.PromoBasketPopupActionTypes.POPUP_OPEN:
            return Object.assign(Object.assign({}, state), { collapsed: false, message: action.payload });
        default:
            return state;
    }
};
exports.promoBasketPopupReducer = promoBasketPopupReducer;
