"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySearchErrorHandler = void 0;
const react_1 = require("react");
const formik_1 = require("formik");
const DeliverySearchErrorHandler = ({ errorMessage }) => {
    const { setFieldError } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        setFieldError('location', errorMessage);
    }, [errorMessage]);
    return null;
};
exports.DeliverySearchErrorHandler = DeliverySearchErrorHandler;
