"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartTotalTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CartTotalTable = ({ vouchers, deleteVoucher, totalBasketDiscountAmount, totalPriceSell, priceTotalBasket, priceDelivery, currency, totalQuantity, textEnlarged, hideRemoveVoucherButton, dataQA, alreadyPaid, toBePaid, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const cartTotalTableTranslations = {
        subtotal: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.basket.subtotal`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.totalDiscount`),
        shipping: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.basket.shipping`),
        free: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.price.free`),
        total: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.total`),
        items: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.items`),
        item: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.item`),
        toBePaid: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.to_be_paid.title`),
        alreadyPaid: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.already_paid.title`),
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CartTotalTable, { currency: currency, totalBasketDiscountAmount: totalBasketDiscountAmount, totalPriceSell: totalPriceSell, priceTotalBasket: priceTotalBasket, priceDelivery: priceDelivery, totalQuantity: totalQuantity, vouchers: vouchers, deleteVoucher: deleteVoucher, translations: cartTotalTableTranslations, textEnlarged: textEnlarged, hideRemoveVoucherButton: hideRemoveVoucherButton, dataQA: dataQA, alreadyPaid: alreadyPaid, toBePaid: toBePaid }));
};
exports.CartTotalTable = CartTotalTable;
