"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductVideoWithContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const useElementIsVisible_1 = require("../../hooks/useElementIsVisible");
const Video_1 = require("../../components/video/Video");
const VIDEO_ALIGNMENT_RIGHT = 'right';
const YOUTUBE_VIDEO_SEARCH_KEY = 'youtube.com';
const VIMEO_VIDEO_SEARCH_KEY = 'vimeo.com';
const MIN_VIDEO_CONTENT_LENGTH_BEFORE_TRUNCATE = 130;
const MAX_LENGTH_PRODUCT_CONTENT_VIDEO_SUBTITLE = 90;
const ProductVideoWithContent = props => {
    var _a, _b, _c, _d, _e;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isTablet = !isMobile && !isDesktop;
    const { productVideoDetail } = props;
    const isVideoRightAligned = ((_a = productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.alignment) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === VIDEO_ALIGNMENT_RIGHT;
    const isFullWidthVideo = !(productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.content) || isVideoRightAligned;
    const isContentPlacedUnderOrLeftOfVideo = isTablet || (!isVideoRightAligned && isDesktop);
    const refVideo = (0, react_1.useRef)(null);
    const isVisible = (0, useElementIsVisible_1.useElementIsVisible)(refVideo, { threshold: 0.01 });
    const isYouTubeVideo = (_b = props === null || props === void 0 ? void 0 : props.productVideoDetail) === null || _b === void 0 ? void 0 : _b.url.includes(YOUTUBE_VIDEO_SEARCH_KEY);
    const isVimeoVideo = (_c = props === null || props === void 0 ? void 0 : props.productVideoDetail) === null || _c === void 0 ? void 0 : _c.url.includes(VIMEO_VIDEO_SEARCH_KEY);
    const updatedVideoProps = Object.assign(Object.assign({}, props), { productVideoDetail: Object.assign(Object.assign({}, props === null || props === void 0 ? void 0 : props.productVideoDetail), { videoFormat: isYouTubeVideo ? common_types_1.VideoPlayerTypes.YOUTUBE : isVimeoVideo ? common_types_1.VideoPlayerTypes.VIMEO : '' }) });
    const renderVideo = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, desktop: isFullWidthVideo ? 12 : 7, children: productVideoDetail && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { innerRef: refVideo, mobile: { spacing: { marginBottom: 2 }, bleed: { marginInline: 2 } }, desktop: { spacing: { marginBottom: 3 }, bleed: { marginInline: 0 } }, children: props && productVideoDetail.url && isVisible && ((0, jsx_runtime_1.jsx)(Video_1.Video, { pause: true, url: updatedVideoProps.productVideoDetail.url, playerType: updatedVideoProps.productVideoDetail.videoFormat, title: updatedVideoProps.productVideoDetail.title })) })) }));
    const renderTruncatedContent = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, tablet: { span: 10 }, desktop: { push: isFullWidthVideo ? 2 : 0, span: isFullWidthVideo ? 8 : 5 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, children: Number((_d = productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.content) === null || _d === void 0 ? void 0 : _d.length) > MIN_VIDEO_CONTENT_LENGTH_BEFORE_TRUNCATE ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.TruncatedText, { content: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.content }), id: `show-product-video-content-${props.videoId}`, includesSeoSeparator: false, lineClamp: isMobile ? 3 : 5, readMoreText: t('pdp.showmore'), readLessText: t('pdp.showless') })) : ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.content })) }) }));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, children: (productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.subtitle) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, type: cotopaxi_1.TextType.EXTRA_CONTENT, children: (_e = productVideoDetail === null || productVideoDetail === void 0 ? void 0 : productVideoDetail.subtitle) === null || _e === void 0 ? void 0 : _e.substring(0, MAX_LENGTH_PRODUCT_CONTENT_VIDEO_SUBTITLE) }) })) }) }), isFullWidthVideo ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: renderVideo }), renderTruncatedContent] })) : ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [isVideoRightAligned && isDesktop && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, desktop: 5, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: productVideoDetail.content }) }) })), renderVideo, !!productVideoDetail.content && isMobile ? (renderTruncatedContent) : isContentPlacedUnderOrLeftOfVideo ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, tablet: 10, desktop: 5, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginBottom: 5, marginRight: 1 } }, tablet: { spacing: { marginLeft: 0 } }, desktop: { spacing: { marginLeft: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: productVideoDetail.content }) }) })) : null] }))] }));
};
exports.ProductVideoWithContent = ProductVideoWithContent;
