"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPImageCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../../util/ImageUtil");
const react_i18next_1 = require("react-i18next");
const LabelsOverlay_1 = require("../../common/labels-overlay/LabelsOverlay");
const LABELS_AMOUNT = 1;
const FIRST_IMAGE = 0;
const MiniPDPImageCarousel = ({ images, productCode, productImageCdnDomains, isOutOfStock, hasAnotherAvailableColor, genderFeatureId, showGenderIcon, savingsPercentage, priceOverlay, showNewProductLabel, showOnlineExclusiveLabel, showSustainabilityLabel, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const imageCarouselConfig = {
        overlay: isOutOfStock && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, align: cotopaxi_1.TextAlign.CENTER, size: isMobile ? cotopaxi_1.TextSize.SMALL : cotopaxi_1.TextSize.LARGE, children: hasAnotherAvailableColor ? t('wishlist.outOfStock.other.color.available') : t('product.temporarilyOutOfStock') })),
        variant: cotopaxi_1.CarouselVariant.MINI_PDP_IMAGE,
        showPagination: false,
        configurationOverrides: {
            rebuildOnUpdate: true,
            preventClicks: true,
            slidesPerView: 'auto',
            watchOverflow: true,
            spaceBetween: 12,
            shouldSwiperUpdate: true,
            allowSlideNext: !isOutOfStock,
            allowSlidePrev: !isOutOfStock,
            allowTouchMove: !isOutOfStock,
        },
    };
    return (0, react_1.useMemo)(() => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, imageCarouselConfig, { children: images.map((image, index) => {
            const imageUrl = (0, ImageUtil_1.getPortraitImageSizeUrlByProductCode)(productCode, image.picture, productImageCdnDomains, common_types_1.ProductImageSizesPortraitEnum.basket);
            return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { relative: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: imageUrl }), index === FIRST_IMAGE && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Overlay, { noPointerEvents: true, stretchContent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.BOTTOM, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsx)(LabelsOverlay_1.default, { maxLabels: LABELS_AMOUNT, showGenderIcon: showGenderIcon, genderFeatureId: genderFeatureId, savingsPercentage: savingsPercentage, priceOverlay: priceOverlay, showNewProductLabel: showNewProductLabel, showOnlineExclusiveLabel: showOnlineExclusiveLabel, showSustainabilityLabel: showSustainabilityLabel, dataQA: "mini_pdp_product_image_overlay" }) }) }) }))] }, imageUrl));
        }) }))), [isOutOfStock, images]);
};
exports.MiniPDPImageCarousel = MiniPDPImageCarousel;
