"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const UrlUtil_1 = require("../../../util/UrlUtil");
const ProductLD = props => {
    var _a, _b, _c;
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const canonicalUrl = (0, ConfigurationUtil_1.getCanonicalUrl)(aemConfiguration);
    if (((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.brand) === null || _b === void 0 ? void 0 : _b.url) === null && ((_c = detailInformation === null || detailInformation === void 0 ? void 0 : detailInformation.brand) === null || _c === void 0 ? void 0 : _c.relativePath)) {
        const url = new URL(canonicalUrl);
        props.data.brand.url = (0, UrlUtil_1.concatUrls)(url.origin, detailInformation.brand.relativePath);
    }
    const jsonLDScript = `<script type="application/ld+json">${JSON.stringify(props.data)}</script>`;
    return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: jsonLDScript });
};
exports.default = ProductLD;
