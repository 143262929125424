"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LockedBasketNotificationMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../constants/i18nConstants");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const NotificationMessage_1 = require("./NotificationMessage");
const CONTENT_MAX_WIDTH = 1336;
const LockedBasketNotificationMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const isBasketLocked = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketLockState)());
    if (!isBasketLocked)
        return null;
    return ((0, jsx_runtime_1.jsx)(NotificationMessage_1.NotificationMessage, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { dataAttributes: { 'data-qa': 'locked_basket_notification' }, mobile: {
                theme: cotopaxi_1.BoxTheme.NOTICE,
                spacing: { paddingLeft: 1.5, paddingRight: 1.5, paddingBottom: 2, paddingTop: 2 },
            }, desktop: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: { desktop: cotopaxi_1.GroupAlign.CENTER }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { width: CONTENT_MAX_WIDTH }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_TRIANGLE_SOLID, color: cotopaxi_1.IconColor.NOTICE, size: cotopaxi_1.IconSize.MAJOR }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { children: [t('basket.locked.notification.message'), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: `${root}buy.html/${common_types_1.RoutePathname.PAYMENT}`, children: t('basket.locked.notification.link') })] })] }) }) }) }) }));
};
exports.LockedBasketNotificationMessage = LockedBasketNotificationMessage;
