"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ListerReducer_1 = require("../../lister/reducers/ListerReducer");
const ListerSagas_1 = require("../../lister/sagas/ListerSagas");
const loadListerModule = (initialData) => ({
    id: 'Lister',
    reducerMap: {
        lister: (0, ListerReducer_1.loadListerReducer)(initialData ? initialData.lister : null),
    },
    sagas: [ListerSagas_1.default],
});
exports.default = loadListerModule;
