"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const AnalyticsActions_creators_1 = require("../analytics/actions/AnalyticsActions.creators");
const ActionEnums_1 = require("../../enums/ActionEnums");
const EventEnum_1 = require("../../enums/EventEnum");
const customEventSagaHandler_1 = require("./customEventSagaHandler");
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
function* handleCustomEvent(action) {
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.MOBILE_NAVIGATION_CLICK:
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.mobileNavigationClick());
            break;
        default:
            break;
    }
}
function* default_1() {
    yield (0, effects_1.all)([(0, customEventSagaHandler_1.watchForCustomEvents)(), customEventSaga()]);
}
exports.default = default_1;
