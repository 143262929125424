"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItemContentRangeFilterInputs = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const FilterItemContentRangeFilterInputs = ({ value, onChange, min, max, filterPrefix, filterSuffix, }) => {
    var _a, _b;
    const MIN_DIFF_STEP = 1;
    const correctedValue = (val, minVal, maxVal) => {
        // Return value or min / max allowed value
        if (val < minVal) {
            return minVal;
        }
        if (val > maxVal) {
            return maxVal;
        }
        return val;
    };
    const handleChangeEnd = (name) => () => {
        if ((0, common_utils_1.isNullOrUndefined)(value.max) || (0, common_utils_1.isNullOrUndefined)(value.min)) {
            // let parent handle what to do when ending with undefined values
            onChange(value, true);
            return;
        }
        if (name === 'min') {
            onChange(Object.assign(Object.assign({}, value), { min: correctedValue(value.min, min, value.max - MIN_DIFF_STEP) }), true);
        }
        else {
            onChange(Object.assign(Object.assign({}, value), { max: correctedValue(value.max, value.min + MIN_DIFF_STEP, max) }), true);
        }
    };
    const handleKeyUp = (name) => (e) => {
        e.preventDefault();
        if (['Enter', 'Tab'].includes(e.key)) {
            handleChangeEnd(name)();
        }
    };
    const handleChange = (name) => (e) => {
        const roundFn = name === 'min' ? Math.floor : Math.ceil;
        const currentValue = e.currentTarget.value.replace(/[a-zA-Z]/g, '');
        onChange(Object.assign(Object.assign({}, value), { [name]: currentValue ? roundFn(Number(currentValue)) : undefined }));
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, tablet: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Input, { value: (_a = value.min) !== null && _a !== void 0 ? _a : '', type: cotopaxi_1.InputType.NUMBER, onChange: handleChange('min'), onKeyUp: handleKeyUp('min'), onBlur: handleChangeEnd('min'), min: min, max: max, inputMode: cotopaxi_1.InputMode.NUMERIC, adornmentStart: filterPrefix ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: filterPrefix }) : null, adornmentEnd: filterSuffix ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: filterSuffix }) : null }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { size: cotopaxi_1.IconSize.EXTRA_MAJOR, name: cotopaxi_1.IconName.MINUS }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Input, { value: (_b = value.max) !== null && _b !== void 0 ? _b : '', type: cotopaxi_1.InputType.NUMBER, onChange: handleChange('max'), onKeyUp: handleKeyUp('max'), onBlur: handleChangeEnd('max'), min: min, max: max, inputMode: cotopaxi_1.InputMode.NUMERIC, adornmentStart: filterPrefix ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: filterPrefix }) : null, adornmentEnd: filterSuffix ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: filterSuffix }) : null })] }) }));
};
exports.FilterItemContentRangeFilterInputs = FilterItemContentRangeFilterInputs;
