"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectField = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const SelectField = props => {
    const { data, field, className } = props;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.form);
    return ((0, jsx_runtime_1.jsx)("div", { className: "a-select__wrapper", children: (0, jsx_runtime_1.jsxs)(formik_1.Field, Object.assign({}, field, { component: "select", className: className, children: [(0, jsx_runtime_1.jsx)("option", { value: "", disabled: true, children: t('form:form.select.option') }), data.options.map(item => {
                    return ((0, jsx_runtime_1.jsx)("option", { value: item[data.valueLookup], children: item[data.labelLookup] }, item[data.keyLookup]));
                })] })) }));
};
exports.SelectField = SelectField;
