"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryChangeHandler = void 0;
const react_1 = require("react");
const formik_1 = require("formik");
const CountryChangeHandler = ({ countries, setActiveCountry }) => {
    const { values } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        const selectedCountry = (0, formik_1.getIn)(values, 'idCountry');
        if (!selectedCountry || !countries) {
            return;
        }
        const countryById = countries.find(country => country.idCountry === parseInt(selectedCountry, 10));
        countryById && setActiveCountry(countryById);
    }, [values, countries]);
    return null;
};
exports.CountryChangeHandler = CountryChangeHandler;
