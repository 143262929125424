"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailTabPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const ProductActions_1 = require("../../../redux/product/actions/ProductActions");
const ProductDetailTabPopover = ({ children, tabType, title, popoverFooterElement, }) => {
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const selectedTab = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedProductTab)());
    const bodyPopoverRef = (0, react_1.useRef)(null);
    const onPopoverClose = () => {
        setIsOpen(false);
        dispatch(ProductActions_1.productActions.createSetSelectedProductTab(null));
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close: onPopoverClose });
    (0, react_1.useEffect)(() => {
        if (tabType === selectedTab) {
            setIsOpen(true);
        }
    }, [selectedTab]);
    (0, react_1.useEffect)(() => {
        isOpen ? handlePopoverOpen(null, null, true) : handlePopoverClose();
    }, [isOpen]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { shouldAlwaysRender: true, anchorEl: anchorEl, type: cotopaxi_1.PopoverType.DRAWER, onClose: handlePopoverClose, title: title, innerBodyRef: bodyPopoverRef, size: cotopaxi_1.PopoverSize.DRAWER_LARGE, noFooterShadow: true, footerElement: popoverFooterElement, children: react_1.Children.map(children, child => child && (0, react_1.cloneElement)(child, Object.assign(Object.assign({}, child.props), { isPopoverOpen: isOpen, bodyPopoverRef }))) }));
};
exports.ProductDetailTabPopover = ProductDetailTabPopover;
