"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CompareReducer_1 = require("../../compare/reducers/CompareReducer");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const loadCompareModule = () => ({
    id: 'Compare',
    reducerMap: {
        compare: CompareReducer_1.compareReducer,
    },
    sagas: [CompareSagas_1.default],
});
exports.default = loadCompareModule;
