"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerHeadingContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_utils_1 = require("@as-react/common-utils");
const INITIAL_QUERY_TEMPLATE = '{{{INITIAL QUERY}}}';
const _ListerHeadingContainer = ({ listerHeadingInformation, pageSearchInformation, executionStatus, alternativeQueryResults, partialSearchResults, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const needsToRenderCategoryTitle = !!listerHeadingInformation.title && listerHeadingInformation.title !== '';
    const needsToRenderSearchTitle = !!pageSearchInformation && pageSearchInformation !== '';
    const needsToRenderChangedMisspellingTitle = executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_SPELL_CHANGED;
    const needsToRenderATitle = needsToRenderCategoryTitle || needsToRenderSearchTitle || needsToRenderChangedMisspellingTitle;
    if (!needsToRenderATitle) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 3 } }, desktop: {
            spacing: { paddingTop: 1.5 },
        }, children: needsToRenderChangedMisspellingTitle ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: [partialSearchResults.changedMisspelling.title.split(INITIAL_QUERY_TEMPLATE)[0], (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, children: ` “${pageSearchInformation}”` }), partialSearchResults.changedMisspelling.title.split(INITIAL_QUERY_TEMPLATE)[1] || ''] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, children: partialSearchResults.changedMisspelling.description }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: (0, common_utils_1.capitalize)(alternativeQueryResults[0].alternativeQuery) })] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.TITLE, children: needsToRenderCategoryTitle
                ? listerHeadingInformation.title
                : needsToRenderSearchTitle
                    ? t('product.lister.title.search', {
                        searchValue: pageSearchInformation,
                    })
                    : '' })) }));
};
const makeMapStateToProps = () => {
    const getListerHeadingInformation = (0, ListerSelector_1.makeGetListerHeadingInformation)();
    const getSearchInformation = (0, UrlSelectors_1.makeGetUrlInformationSearch)();
    const getExecutionStatus = (0, ListerSelector_1.makeGetExecutionStatus)();
    const getAlternativeQueryResults = (0, ListerSelector_1.makeGetAlternativeQueryResults)();
    const getPartialSearchResults = (0, ListerSelector_1.makeGetPartialSearchResults)();
    return (state) => ({
        listerHeadingInformation: getListerHeadingInformation(state),
        pageSearchInformation: getSearchInformation(state),
        executionStatus: getExecutionStatus(state),
        alternativeQueryResults: getAlternativeQueryResults(state),
        partialSearchResults: getPartialSearchResults(state),
    });
};
exports.ListerHeadingContainer = (0, react_redux_1.connect)(makeMapStateToProps)(_ListerHeadingContainer);
