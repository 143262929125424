"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPlacementCopyContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const productPlacementCopyBox_1 = require("../../components/product-detail/product-placement-copy-box/productPlacementCopyBox");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductPlacementCopyContainer = ({ ppcType }) => {
    const getPPCPositionsForSelectedColor = (0, ProductSelector_1.makeGetPPCForSelectedColorWithId)();
    const items = (0, react_redux_1.useSelector)((state) => getPPCPositionsForSelectedColor(state, ppcType));
    if (!items) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: items.map((item) => ((0, jsx_runtime_1.jsx)(productPlacementCopyBox_1.ProductPlacementCopyBox, { ppc: item }, item.name))) }));
};
exports.ProductPlacementCopyContainer = ProductPlacementCopyContainer;
