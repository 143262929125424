"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
class ApplePayUtil {
    static canMakePaymentsWithActiveCard(merchantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isApplePaySession()) {
                return false;
            }
            try {
                const canMakePayments = yield window.ApplePaySession.canMakePaymentsWithActiveCard(merchantId);
                return canMakePayments;
            }
            catch (_a) {
                return false;
            }
        });
    }
    static canMakePayments() {
        try {
            return this.isApplePaySession() && window.ApplePaySession.canMakePayments();
        }
        catch (_a) {
            return false;
        }
    }
    static isApplePaySession() {
        return !!window.ApplePaySession;
    }
}
ApplePayUtil.defaultConfig = {
    APPLE_PAY_VERSION: 3,
    APPLE_PAY_DISPLAY_NAME: 'Apple Pay',
    APPLE_PAY_MERCHANT_INITIATIVE: 'web',
    APPLE_PAY_MERCHANT_CAPABILITIES: ['supports3DS'],
    APPLE_PAY_SHIPPING_METHOD_IDENTIFIER: 'standardShipping',
    APPLE_PAY_SHIPPING_TYPE: {
        shipping: 'shipping',
        storePickup: 'storePickup',
    },
};
ApplePayUtil.getApplePayButtonStyles = () => `
        .apple-pay-button {
            width: 100%;
            height: 40px;
            border-radius: 2px;
            cursor: pointer;
            -webkit-appearance: -apple-pay-button;
            -apple-pay-button-type: buy;
        }
        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    `;
exports.default = ApplePayUtil;
