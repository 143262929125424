"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AemComponent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const HtmlInclude_1 = require("../html-include/HtmlInclude");
const TeaserContainer_1 = require("../../../containers/TeaserContainer");
const AemComponent = (component) => {
    switch (component[':type']) {
        case common_types_1.AemComponentType.TEXT_BLOCK_V2:
            return (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: component.richText ? (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: component.text }) : component.text });
        case common_types_1.AemComponentType.DIVIDER:
        case common_types_1.AemComponentType.CTA:
            return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: component.html });
        case common_types_1.AemComponentType.TEASER_V2:
            return (0, jsx_runtime_1.jsx)(TeaserContainer_1.default, Object.assign({}, component));
        default:
            return null;
    }
};
exports.AemComponent = AemComponent;
