"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlossaryNavigation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GlossaryNavigation = ({ glossaries, onClick, showAll, activeGlossaryItem, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.BrandFilter, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.BrandFilterItem, { title: t('all'), filterKey: t('all'), onClick: showAll, active: !activeGlossaryItem }, t('all')), Object.keys(glossaries).map((glossaryItem, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.BrandFilterItem, { title: glossaryItem.toUpperCase(), filterKey: glossaryItem, onClick: onClick, disabled: glossaries[glossaryItem].length === 0, active: glossaryItem === activeGlossaryItem }, `${glossaryItem}-${index}`)))] }) }));
};
exports.GlossaryNavigation = GlossaryNavigation;
