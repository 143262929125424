"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareFeatureItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const CompareFeatureItem = ({ feature, isGlossaryLoaded, shouldInitGlossaryTooltip, setSwiperInstance, }) => {
    const [isTooltipApplied, setIsTooltipApplied] = (0, react_1.useState)(false);
    const getGlossaryItem = (title, isLoaded) => { var _a; return isLoaded ? (_a = window[GeneralConstants_1.GLOSSARY_KEY]) === null || _a === void 0 ? void 0 : _a.find((glossary) => glossary.triggers.includes(title)) : null; };
    const glossaryItem = (0, react_1.useMemo)(() => getGlossaryItem(feature.title, isGlossaryLoaded), [feature, isGlossaryLoaded, shouldInitGlossaryTooltip]);
    const _setSwiperInstance = (swiper) => setSwiperInstance(swiper, feature.attribute);
    if (!!glossaryItem && !isTooltipApplied && shouldInitGlossaryTooltip) {
        (0, common_utils_1.setTooltip)({
            element: `#feature-item-${feature.title}`,
            content: glossaryItem.description,
            title: glossaryItem.title,
            optionsOverride: {
                trigger: 'click',
            },
        });
        setIsTooltipApplied(true);
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ComparisonFeatureItem, { title: feature.title, values: feature.valuesByProducts, glossaryId: `feature-item-${feature.title}`, showGlossary: !!glossaryItem, setSwiperInstance: _setSwiperInstance }));
};
exports.CompareFeatureItem = CompareFeatureItem;
