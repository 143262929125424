"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailSpecificationContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const ProductCategorizedFeatureTable_1 = require("../../components/product-detail/product-categorized-features/ProductCategorizedFeatureTable");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const useGlossaryHighlightInPopover_1 = require("../../hooks/useGlossaryHighlightInPopover");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductUtil_1 = require("../../util/ProductUtil");
const ProductDetailSpecificationContainer = ({ bodyPopoverRef, excludedCategories, inPopover, isPopoverOpen, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const categories = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductTechnicalCategories)());
    const { productCode } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const excludedCategoriesId = excludedCategories.map(category => Number(category.id));
    (0, useGlossaryHighlightInPopover_1.useGlossaryHighlightInPopover)(bodyPopoverRef, isPopoverOpen);
    const filteredCategories = categories
        .map(ProductUtil_1.filterProductCategoryEmptyFeatures)
        .filter(category => category.features.length && !excludedCategoriesId.includes(category.categoryId));
    const isNoDefaultCategory = filteredCategories.length > 0 && filteredCategories.every(category => !(0, common_utils_1.isNullOrUndefined)(category.categoryName));
    const tableContentSpacing = {
        firstColumn: {
            mobile: {
                spacing: {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    paddingLeft: inPopover ? 2 : 0,
                    paddingRight: 1,
                },
            },
            tablet: { spacing: { paddingLeft: inPopover ? 4 : 0, paddingRight: 2 } },
        },
        secondColumn: {
            mobile: {
                spacing: {
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    paddingLeft: 1,
                    paddingRight: inPopover ? 2 : 0,
                },
            },
            tablet: { spacing: { paddingLeft: 2, paddingRight: inPopover ? 4 : 0 } },
        },
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: inPopover ? 0 : 2 } }, children: [filteredCategories.map((category, index, array) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: index === 0 ? 0 : 3, marginLeft: inPopover ? 2 : 0 } }, tablet: { spacing: { marginTop: index === 0 ? 0 : 4, marginLeft: inPopover ? 4 : 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, type: inPopover && cotopaxi_1.HeadingType.EXTRA_SMALL_TITLE, size: !inPopover && cotopaxi_1.HeadingSize.XXS, children: category.categoryName || t('pdp.other.features') }) }), (0, jsx_runtime_1.jsx)(ProductCategorizedFeatureTable_1.default, { features: category.features, productCode: productCode, isLastTable: !isNoDefaultCategory && array.length - 1 === index, displayProductCodeAsLink: false, tableContentSpacing: tableContentSpacing })] }, category.categoryCode))), (isNoDefaultCategory || filteredCategories.length === 0) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: isNoDefaultCategory ? 3 : 0, marginLeft: inPopover ? 2 : 0 } }, tablet: { spacing: { marginTop: isNoDefaultCategory ? 4 : 0, marginLeft: inPopover ? 4 : 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, type: inPopover && cotopaxi_1.HeadingType.EXTRA_SMALL_TITLE, size: !inPopover && cotopaxi_1.HeadingSize.XXS, children: t('pdp.other.features') }) }), (0, jsx_runtime_1.jsx)(ProductCategorizedFeatureTable_1.default, { isLastTable: true, productCode: productCode, displayProductCodeAsLink: false, tableContentSpacing: tableContentSpacing })] }))] }));
};
exports.ProductDetailSpecificationContainer = ProductDetailSpecificationContainer;
