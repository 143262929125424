"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ReviewsApi_1 = require("../../api/ReviewsApi");
const AddReviewForm_1 = require("../../components/add-review/add-review-form/AddReviewForm");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../util/ImageUtil");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const NavigationUtil_1 = require("../../util/NavigationUtil");
const ProductConstants_1 = require("../../constants/ProductConstants");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ProductTileVertical_1 = require("../../components/product-tile/vertical/ProductTileVertical");
const ProductTileCompact_1 = require("../../components/product-tile/compact/ProductTileCompact");
const URL_CUSTOMERUUID_PARAM_NAME = 'uuid';
const ProductReviewLandingContainer = ({ product }) => {
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const productImages = (0, ConfigurationUtil_1.getProductImages)(configuration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(configuration);
    const configurationRoot = (0, ConfigurationUtil_1.getRoot)(configuration);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [submitted, setSubmitted] = (0, react_1.useState)(false);
    const [profileData, setProfileData] = (0, react_1.useState)({});
    const [customerUUID, setCustomerUUID] = (0, react_1.useState)('');
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.product,
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.navigation,
        i18nConstants_1.I18N_NAMESPACES.general,
    ]);
    const isProduct = product && Object.keys(product).length > 0;
    const getProfileData = (userUUID) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const basicCustomer = yield CustomerApi_1.default.getBasicInfo(servicesEndpoint, defaultRequestParameters, userUUID);
        setProfileData(basicCustomer.data);
    });
    (0, react_1.useEffect)(() => {
        if (!isProduct)
            return;
        const userUUID = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_CUSTOMERUUID_PARAM_NAME) || '';
        setCustomerUUID(userUUID);
        getProfileData(userUUID);
    }, []);
    if (!isProduct)
        return null;
    const addReview = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ title, rating, advantages, disadvantages }) {
        const reviewData = {
            productId: product.productId.toString(),
            customerUUID,
            reviewTitle: title,
            score: rating,
            reviewTextPositive: advantages,
            reviewTextNegative: disadvantages,
            customerName: profileData.firstName ? profileData.firstName : '',
        };
        if (customerUUID) {
            yield ReviewsApi_1.default.postReview(reviewData, defaultRequestParameters, servicesEndpoint);
            setSubmitted(true);
            setTimeout(() => (window.location.href = (0, NavigationUtil_1.getHomepageUrl)(configurationRoot)), GeneralConstants_1.DELAY.DELAY_5000);
        }
    });
    const processedProduct = Object.assign(Object.assign({}, product), { colours: product.colours.map(item => (item.colourId !== product.colourId ? item : Object.assign(Object.assign({}, item), { prices: null }))) });
    const activeColor = product.colours.find(item => item.colourId === product.colourId);
    const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(product.productCode, activeColor.image, productImages);
    const onErrorHandler = (errors) => {
        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[for="add-review-${errors[0]}"]`), isDesktop);
    };
    const welcomeMessageText = (size) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: size, children: [profileData.firstName &&
                t('product:product.review.landing.greeting', {
                    name: profileData.firstName,
                }), ' ', t(`general:mainwebshop.name.${mainWebShop}`), " ", t('product:product.review.landing.message')] }));
    const getProductDetailUrl = () => {
        const { seoUrl, colourId } = product;
        return configurationRoot
            .concat(seoUrl.indexOf('/') === 0 ? `${ProductConstants_1.PRODUCT_URL_PREFIX}${seoUrl}` : `${ProductConstants_1.PRODUCT_URL_PREFIX}/${seoUrl}`)
            .concat('.html')
            .concat(`?colour=${colourId}`);
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, children: t('product:product.review.landing.title') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, size: cotopaxi_1.HeadingSize.M, children: t('product:product.review.landing.title') }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { desktop: { spacing: { marginTop: 1, marginBottom: 1 } }, mobile: { spacing: { marginTop: 2, marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: welcomeMessageText(cotopaxi_1.TextSize.MEDIUM) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: welcomeMessageText(cotopaxi_1.TextSize.SMALL) })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { grid: 3, tablet: 12, mobile: 12, tabletSmall: 12, mobileLarge: 12, gutter: false, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: {
                                        spacing: {
                                            marginTop: 4,
                                        },
                                    }, children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: processedProduct, format: "simple", shouldLazyLoadImage: common_types_1.LazyLoadOptions.LAZY_LOAD }) }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.NONE }), (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, { brand: product.brand, image: imageUrl[common_types_1.ProductImageSizesPortraitEnum.bundles], url: getProductDetailUrl(), title: product.title, productRating: {
                                            score: product.productRating || undefined,
                                            amount: product.productReviews || undefined,
                                        }, imageSize: "large" })] })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { grid: 9, tablet: 12, mobile: 12, tabletSmall: 12, mobileLarge: 12, gutter: false, children: [(0, jsx_runtime_1.jsx)(AddReviewForm_1.AddReviewForm, { addReview: addReview, onError: onErrorHandler, isReviewLandingPage: true, isSubmitted: submitted }), submitted && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: {
                                    spacing: {
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    },
                                }, tablet: {
                                    spacing: {
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                    },
                                }, mobile: {
                                    spacing: {
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: t('product.review.add.banner.output.success'), variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }) }))] })] })] }));
};
const makeMapStateToProps = () => {
    const getProductReviewLandingInformation = (0, ProductSelector_1.makeGetProductReviewLandingInformation)();
    return (state) => ({
        product: getProductReviewLandingInformation(state),
    });
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(ProductReviewLandingContainer);
