"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductWizardQuestionsFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductWizardQuestionsFooter = ({ showResetBtn, question, resultsUrl, lastQuestion, onNexBtnClick, onResetBtnClick, totalProductsCount, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const hasSelectedAnswer = question.answers.some((answer) => answer.selected);
    const noResults = () => totalProductsCount === 0;
    const nextBtnLabel = () => lastQuestion
        ? noResults()
            ? t('productWizard.question.button.noResults')
            : t('productWizard.question.button.showResults', {
                count: totalProductsCount,
            })
        : hasSelectedAnswer
            ? question.nextButtonLabel || t('productWizard.question.button.next')
            : question.skipButtonLabel || t('productWizard.question.button.skip');
    const redirectToResultsPage = () => {
        window.location.href = resultsUrl;
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, tablet: 4, desktop: 4, children: !isMobile && showResetBtn && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, onClick: onResetBtnClick, text: t('productWizard.question.button.retry'), iconLeft: { name: cotopaxi_1.IconName.REDO_SOLID } })) }), !lastQuestion && !!totalProductsCount && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 6, tablet: 4, desktop: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: isMobile ? cotopaxi_1.GroupAlign.LEFT : cotopaxi_1.GroupAlign.SPACE_AROUND, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { size: cotopaxi_1.LinkSize.SMALL, href: resultsUrl, children: t('productWizard.question.button.showResults', {
                                    count: totalProductsCount,
                                }) }) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: lastQuestion ? 12 : 6, tablet: 4, desktop: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, state: noResults() ? cotopaxi_1.ButtonState.DISABLED : undefined, fill: lastQuestion, variant: hasSelectedAnswer ? cotopaxi_1.ButtonVariant.BRANDED_ALT : cotopaxi_1.ButtonVariant.OUTLINED_ALT, text: nextBtnLabel(), onClick: lastQuestion ? redirectToResultsPage : onNexBtnClick }) }) })] }) }) }));
};
exports.ProductWizardQuestionsFooter = ProductWizardQuestionsFooter;
