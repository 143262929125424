"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductRecentlyViewedActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.ProductRecentlyViewedActions = {
    getRecentlyViewedProducts: () => ({
        type: ActionEnums_1.ProductRecentlyViewedActionTypes.GET,
    }),
    fetchProducts: (payload) => ({
        type: ActionEnums_1.ProductRecentlyViewedActionTypes.FETCH,
        payload,
    }),
    eraseProducts: () => ({
        type: ActionEnums_1.ProductRecentlyViewedActionTypes.ERASE,
    }),
    getDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductRecentlyViewedActionTypes.GET_DELIVERY_PROMISE,
        payload,
    }),
    setDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductRecentlyViewedActionTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
};
