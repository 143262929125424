"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PASSWORD_MIN_LENGTH = exports.RENTAL_FORM_PEOPLE_FIELD_ARRAY_NAME = exports.FORM_FIELD_DATE_RANGE_SEPARATOR = exports.RENTAL_FORM_FIELD_KIND = exports.FormFieldType = void 0;
var FormFieldType;
(function (FormFieldType) {
    FormFieldType["CHECKBOX"] = "checkbox";
    FormFieldType["DATE"] = "date";
    FormFieldType["NUMBER"] = "number";
    FormFieldType["SELECT"] = "select";
    FormFieldType["TEXT"] = "text";
})(FormFieldType || (exports.FormFieldType = FormFieldType = {}));
exports.RENTAL_FORM_FIELD_KIND = {
    period: {
        name: 'period',
        translation: 'rental:rental.title.period',
        type: FormFieldType.DATE,
    },
    firstName: {
        name: 'firstName',
        translation: 'rental:rental.firstName',
        type: FormFieldType.TEXT,
    },
    birthYear: {
        name: 'birthYear',
        translation: 'rental:rental.birthYear',
        type: FormFieldType.NUMBER,
    },
    height: {
        name: 'height',
        translation: 'rental:rental.height',
        type: FormFieldType.NUMBER,
    },
    weight: {
        name: 'weight',
        translation: 'rental:rental.weight',
        type: FormFieldType.NUMBER,
    },
    shoeSize: {
        name: 'shoeSize',
        translation: 'rental:rental.shoeSize',
        type: FormFieldType.TEXT,
    },
    proficiencyId: {
        name: 'proficiencyId',
        translation: 'rental:rental.proficiency',
        type: FormFieldType.SELECT,
    },
};
exports.FORM_FIELD_DATE_RANGE_SEPARATOR = ' - ';
exports.RENTAL_FORM_PEOPLE_FIELD_ARRAY_NAME = 'people';
exports.PASSWORD_MIN_LENGTH = 6;
