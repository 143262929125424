"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const EsiInclude_1 = require("../esi-include/EsiInclude");
const ServerSideInclude = props => {
    /*
      const configuration = useAemConfigurationContextWrapper();
      if (configuration.feature_toggles.ssi_available) {
          return <SsiInclude {...props} />
      }
      */
    return (0, jsx_runtime_1.jsx)(EsiInclude_1.default, Object.assign({}, props));
};
exports.default = (0, react_1.memo)(ServerSideInclude);
