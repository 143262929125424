"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatePasswordForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const FormConstants_1 = require("../../../constants/FormConstants");
const initialValues = {
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
};
const UpdatePasswordForm = ({ onSubmit, validate, buttonState, errorFeedback, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.account);
    const passwordIndicatorTranslations = {
        label: t(`${cotopaxi_1.I18N_NAMESPACES.account}:password.indicator.strength.label`),
        text: value => t(`${cotopaxi_1.I18N_NAMESPACES.account}:${value}`),
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "update-password-form", initialValues: initialValues, validate: validate, onSubmit: onSubmit, validateOnBlur: false, children: ({ values, errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "oldPassword", size: cotopaxi_1.LabelSize.MINOR, children: t('account.password.passwordOld') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.PASSWORD, name: "oldPassword", placeholder: t('account.password'), required: true, autoComplete: cotopaxi_1.AutoComplete.OFF, minLength: FormConstants_1.PASSWORD_MIN_LENGTH, state: errors.oldPassword && touched.oldPassword && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.oldPassword &&
                                touched.oldPassword && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "oldPassword" })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "newPassword", size: cotopaxi_1.LabelSize.MINOR, children: t('account.password.passwordOne') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.PASSWORD, name: "newPassword", autoComplete: cotopaxi_1.AutoComplete.OFF, placeholder: t('account.password'), required: true, minLength: FormConstants_1.PASSWORD_MIN_LENGTH, state: errors.newPassword && touched.newPassword && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.newPassword &&
                                touched.newPassword && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "newPassword" }), values.newPassword.length >= FormConstants_1.PASSWORD_MIN_LENGTH && ((0, jsx_runtime_1.jsx)(cotopaxi_1.PasswordIndicator, { password: values.newPassword, translations: passwordIndicatorTranslations }))] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "repeatPassword", size: cotopaxi_1.LabelSize.MINOR, children: t('account.password.passwordTwo') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.PASSWORD, name: "repeatPassword", autoComplete: cotopaxi_1.AutoComplete.OFF, placeholder: t('account.password'), required: true, minLength: FormConstants_1.PASSWORD_MIN_LENGTH, state: errors.repeatPassword && touched.repeatPassword && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.repeatPassword &&
                                touched.repeatPassword && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "repeatPassword" })] }), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, text: t('account.details.updatepassword'), state: buttonState, finisher: {
                        icon: cotopaxi_1.IconName.CHECK,
                        text: t('account.password.finished.button.state'),
                    } })] })) }));
};
exports.UpdatePasswordForm = UpdatePasswordForm;
