"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistLister = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const ActionEnums_1 = require("../../enums/ActionEnums");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const CompareSelectors_1 = require("../../redux/compare/selectors/CompareSelectors");
const CompareActions_creators_1 = require("../../redux/compare/actions/CompareActions.creators");
const ProductUtil_1 = require("../../util/ProductUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const RouterSelector_1 = require("../../redux/router/selectors/RouterSelector");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const WishlistLister = ({ items, onWishlistToggle, setMiniPDPProduct, productTileFormat, deliveryPromiseExplanations, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.product]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const shopLandingEnabledFlag = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const compareItems = (0, react_redux_1.useSelector)((0, CompareSelectors_1.makeGetCompareItems)());
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const canonicalUrl = (0, ConfigurationUtil_1.getCanonicalUrl)(aemConfiguration);
    const totalPages = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishlistTotalPages)());
    const currentPage = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetQueryParamPage)());
    const handleAddToBasket = (product) => {
        dispatch(BasketActions_creators_1.basketActions.createAddExtensiveTileProductAction(product, ActionEnums_1.ProductAddToBasketLocations.WISHLIST));
    };
    const getDeliveryPromise = (payload) => dispatch(WishListActions_creators_1.wishListActions.getDeliveryPromise(Object.assign({}, payload)));
    const determineLazyLoadState = (index) => {
        const amountNonLazyLoadedTiles = GeneralConstants_1.PLACEHOLDER_TILES.length;
        switch (true) {
            case index < amountNonLazyLoadedTiles:
                return common_types_1.LazyLoadOptions.NO_LAZY_LOAD;
            case index < amountNonLazyLoadedTiles * 1.5:
                return common_types_1.LazyLoadOptions.LAZY_PRE_LOAD;
            default:
                return common_types_1.LazyLoadOptions.LAZY_LOAD;
        }
    };
    const handleUpdateCompare = (product) => {
        dispatch(CompareActions_creators_1.default.createUpdateOnProductDetailPageAction(product));
    };
    const handleOutOfStockClick = (item) => (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        window.location.href = `${root}c${item.category.relativeUrl}.html`;
    };
    const onPageChangeHandler = (newPage) => {
        dispatch(WishListActions_creators_1.wishListActions.updateWishListPage({ page: newPage }));
        ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.WISHLIST_COMPONENT));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductGrid, { analyticsAreaName: common_types_1.LISTER_ID, showExtraDesktopColumn: true, children: items.map((item, index) => {
                    var _a, _b;
                    const shouldLazyLoadImage = determineLazyLoadState(index);
                    const productOutOfStock = (0, ProductUtil_1.isProductOutOfStock)(item);
                    const handleTileClick = (e) => {
                        const isNotBever = mainWebShop !== GeneralConstants_1.FASCIA_AND_MAIN_WEB_SHOP['1_BEVER'];
                        const isPaperVoucher = [
                            cotopaxi_1.ProductTypes.PAPER_VOUCHER,
                            cotopaxi_1.ProductTypes.CUSTOM_PAPER_VOUCHER,
                            cotopaxi_1.ProductTypes.GIFT_CARD,
                            cotopaxi_1.ProductTypes.CUSTOM_GIFT_CARD,
                        ].includes(item.type);
                        const isEVoucher = item.type === cotopaxi_1.ProductTypes.E_VOUCHER;
                        if (isEVoucher || (isPaperVoucher && isNotBever) || productOutOfStock) {
                            return;
                        }
                        e.preventDefault();
                        e.stopPropagation();
                        setMiniPDPProduct(item);
                    };
                    return ((0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: item, format: productTileFormat, onTileClick: (0, ProductUtil_1.isExtensiveProductTile)(productTileFormat) ? undefined : handleTileClick, onWishListToggle: onWishlistToggle, updateCompareList: handleUpdateCompare, compareItems: compareItems, shouldLazyLoadImage: shouldLazyLoadImage, alwaysShowSwatches: shopLandingEnabledFlag, addToBasket: handleAddToBasket, showComparison: true, showSwatchesOnHover: false, showProductOutOfStockOverlay: true, showColorOutOfStockOverlay: true, outOfStockActions: productOutOfStock &&
                            item.active &&
                            ((_a = item.category) === null || _a === void 0 ? void 0 : _a.active) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { size: cotopaxi_1.ButtonSize.XSMALL, shape: cotopaxi_1.ButtonShape.PILL, onClick: handleOutOfStockClick(item), text: t('general:wishlist.outOfStock.suggestions') })), iconName: productOutOfStock ? cotopaxi_1.IconName.CLOSE : undefined, outOfStockMessage: productOutOfStock && !item.active ? t('product:product.definitivelyOutOfStock') : undefined, inWishLister: true, dropdownProps: { appendToBody: true }, showCompareForExtensiveTile: true, inStoreOnly: (0, ProductUtil_1.isInStoreOnly)(item), clickAndCollectOnly: (0, ProductUtil_1.isClickAndCollectOnly)(item), getDeliveryPromise: getDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, defaultSize: (_b = item.sku) === null || _b === void 0 ? void 0 : _b.toString() }, `${item.productCode}${item.colourId}${index}`));
                }) }), totalPages > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Pagination, { baseUrl: canonicalUrl, currentPage: currentPage || 0, onPageChange: onPageChangeHandler, totalPages: totalPages }) }) }))] }));
};
exports.WishlistLister = WishlistLister;
