"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Compare_module_1 = require("./modules/Compare.module");
const FilterModule_1 = require("./modules/FilterModule");
const Lister_module_1 = require("./modules/Lister.module");
const Url_module_1 = require("./modules/Url.module");
const WishList_module_1 = require("./modules/WishList.module");
const Basket_module_1 = require("./modules/Basket.module");
const ProductGroup_module_1 = require("./modules/ProductGroup.module");
const Customer_module_1 = require("./modules/Customer.module");
const loadProductListerModule = (initialData) => ({
    hasRouter: true,
    modules: [
        (0, Compare_module_1.default)(),
        (0, FilterModule_1.default)(initialData),
        (0, Lister_module_1.default)(initialData),
        (0, Url_module_1.default)(initialData),
        (0, WishList_module_1.default)(),
        (0, Basket_module_1.default)(),
        (0, ProductGroup_module_1.default)(initialData),
        (0, Customer_module_1.default)(),
    ],
});
exports.default = loadProductListerModule;
