"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsLetterField = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormField_1 = require("../FormField");
const NewsLetterField = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "newsletter", children: (_a) => {
            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
            return ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { id: fieldProps.name, name: fieldProps.name, label: t('checkout.personal.details.newsletter.subscribe'), dataTestId: fieldProps.name, value: fieldProps.value, checked: fieldProps.value, error: error })));
        } }));
};
exports.NewsLetterField = NewsLetterField;
