"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AccountPaybackEnrichmentForm_1 = require("../../components/account/account-payback-enrichment/AccountPaybackEnrichmentForm");
const react_redux_1 = require("react-redux");
const CustomerSelector_1 = require("../../redux/customer/selectors/CustomerSelector");
const CustomerActions_creators_1 = require("../../redux/customer/actions/CustomerActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const common_utils_1 = require("@as-react/common-utils");
const AccountPaybackEnrichmentContainer = ({ confirmationUrl, image, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const dispatch = (0, react_redux_1.useDispatch)();
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    const handleFinish = () => {
        let redirect = confirmationUrl;
        if (window.location.search) {
            redirect += window.location.search;
        }
        if (window.location.hash) {
            redirect += window.location.hash;
        }
        window.location.href = redirect;
    };
    (0, react_1.useEffect)(() => {
        dispatch(CustomerActions_creators_1.default.getProfile());
    }, [isMounted]);
    (0, react_1.useEffect)(() => {
        if (sessionState.uuid && !sessionState.isLoggedIn) {
            handleFinish();
        }
    }, [sessionState]);
    const backgroundStyle = image
        ? {
            backgroundImage: `url(${image.imagePath})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
        : {};
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { bleed: { marginInline: 1 } }, children: sessionState.isLoggedIn && profile && !(0, common_utils_1.isEmptyObject)(profile) && ((0, jsx_runtime_1.jsx)("div", { style: backgroundStyle, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: { span: 8, push: 2 }, desktop: { span: 6, push: 3 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { theme: cotopaxi_1.BoxTheme.WHITE, spacing: { padding: 2 }, bleed: { marginInline: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: t('account.payback.enrichment.title') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, type: cotopaxi_1.HeadingType.SUBTITLE, children: t('account.payback.enrichment.subtitle') }) }), (0, jsx_runtime_1.jsx)(AccountPaybackEnrichmentForm_1.AccountPaybackEnrichmentForm, { onFinish: handleFinish, profile: profile })] }) }) }) })) }));
};
exports.default = AccountPaybackEnrichmentContainer;
