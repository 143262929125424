"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebouncedQuery = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const GeneralUtil_1 = require("../util/GeneralUtil");
const useDebouncedQuery = (queryOptions, debounceTime = 500) => {
    const { queryKey, queryFn } = queryOptions, options = tslib_1.__rest(queryOptions, ["queryKey", "queryFn"]);
    const [debouncedKey, setDebouncedKey] = (0, react_1.useState)(queryKey);
    (0, react_1.useEffect)(() => {
        const handler = setTimeout(() => {
            if (!(0, GeneralUtil_1.isEqual)(queryKey, debouncedKey)) {
                setDebouncedKey(queryKey);
            }
        }, debounceTime);
        return () => clearTimeout(handler);
    }, [queryKey, debounceTime]);
    return (0, react_query_1.useQuery)(Object.assign(Object.assign({}, options), { queryKey: debouncedKey, queryFn }));
};
exports.useDebouncedQuery = useDebouncedQuery;
