"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceFields = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormField_1 = require("../FormField");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const AddressFields_1 = require("./AddressFields");
const InvoiceFieldsWatcher_1 = require("../invoice-fields/InvoiceFieldsWatcher");
const InvoiceFields = ({ country, onCountryChange }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general]);
    const { registrationForm: { showAddress, showDepartmentName }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const invoice = (0, react_hook_form_1.useWatch)({ name: 'invoice' });
    const defaultCellProps = { span: { xs: 12, md: 6 } };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "invoice", children: (_a) => {
                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                    return ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { id: fieldProps.name, name: fieldProps.name, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.invoice.checkbox`), dataTestId: fieldProps.name, value: fieldProps.value, checked: fieldProps.value, error: error })));
                } }), invoice && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapX: 4, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, defaultCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "finance.vatNumber", required: true, children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.vat`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 50 })));
                                    } }) })), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, defaultCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "finance.companyName", required: true, children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.company`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 100 })));
                                    } }) })), showDepartmentName && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, defaultCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "finance.departmentName", children: (_a) => {
                                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.department`), optionalText: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.optional`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 50 })));
                                    } }) })))] }), invoice && !showAddress && (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { country: country, onCountryChange: onCountryChange })] })), (0, jsx_runtime_1.jsx)(InvoiceFieldsWatcher_1.InvoiceFieldsWatcher, { country: country, prefix: "finance." })] }));
};
exports.InvoiceFields = InvoiceFields;
