"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disableImageTouchZoomHint = exports.disableImageTouchZoomHintSaga = exports.loadImageTouchZoomHintFromLocalStorage = exports.loadImageTouchZoomHintFromLocalStorageSaga = void 0;
const common_utils_1 = require("@as-react/common-utils");
const effects_1 = require("redux-saga/effects");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const ProductPreviewActions_creators_1 = require("../actions/ProductPreviewActions.creators");
const LOCAL_STORAGE_IMAGE_TOUCH_ZOOM_HINT_KEY = 'imageTouchZoomHint';
function* loadImageTouchZoomHintFromLocalStorageSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.INIT, loadImageTouchZoomHintFromLocalStorage);
}
exports.loadImageTouchZoomHintFromLocalStorageSaga = loadImageTouchZoomHintFromLocalStorageSaga;
function* loadImageTouchZoomHintFromLocalStorage() {
    try {
        let imageTouchZoomHint = LocalStorageUtil_1.default.getJsonItem(LOCAL_STORAGE_IMAGE_TOUCH_ZOOM_HINT_KEY);
        if ((0, common_utils_1.isNullOrUndefined)(imageTouchZoomHint)) {
            imageTouchZoomHint = true;
        }
        yield (0, effects_1.put)(ProductPreviewActions_creators_1.productPreviewActions.updateImageTouchZoomHint(imageTouchZoomHint));
    }
    catch (_a) { }
}
exports.loadImageTouchZoomHintFromLocalStorage = loadImageTouchZoomHintFromLocalStorage;
function* disableImageTouchZoomHintSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductPreviewActionTypes.DISABLE_IMAGE_TOUCH_ZOOM_HINT, disableImageTouchZoomHint);
}
exports.disableImageTouchZoomHintSaga = disableImageTouchZoomHintSaga;
function* disableImageTouchZoomHint() {
    try {
        LocalStorageUtil_1.default.setItem(LOCAL_STORAGE_IMAGE_TOUCH_ZOOM_HINT_KEY, false);
        yield (0, effects_1.put)(ProductPreviewActions_creators_1.productPreviewActions.updateImageTouchZoomHint(false));
    }
    catch (_a) { }
}
exports.disableImageTouchZoomHint = disableImageTouchZoomHint;
function* productPreviewSaga() {
    yield (0, effects_1.all)([loadImageTouchZoomHintFromLocalStorageSaga(), disableImageTouchZoomHintSaga()]);
}
exports.default = productPreviewSaga;
