"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const LanguagePopover_1 = require("../components/language-popover/LanguagePopover");
const common_utils_1 = require("@as-react/common-utils");
const MenuUtil_1 = require("../util/MenuUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const LanguageMenuItemContainer = props => {
    const { currentLang, defaultLanguages, isDesktop } = props;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const [languages, setLanguages] = (0, react_1.useState)(defaultLanguages);
    const [alternateUrl, setAlternateUrl] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        const windowAlternateUrl = (0, common_utils_1.getCustomWindowObject)('SITE.Config.alternateUrl');
        if (windowAlternateUrl) {
            setLanguages(Object.keys(windowAlternateUrl));
            setAlternateUrl(windowAlternateUrl);
        }
        else {
            setLanguages(null);
        }
    }, []);
    const getMobileLabel = () => `${t(`general:language.${currentLang}`)} (${currentLang.toUpperCase()})`;
    const closeAll = () => {
        MenuUtil_1.MenuUtil.close();
        handlePopoverClose();
    };
    if (!languages || languages.length < 2) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [isDesktop ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginRight: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "language_switcher", variant: cotopaxi_1.ButtonVariant.LINK_SUBTLE, size: cotopaxi_1.ButtonSize.SMALL, iconRight: {
                            name: anchorEl ? cotopaxi_1.IconName.CHEVRON_UP : cotopaxi_1.IconName.CHEVRON_DOWN,
                            color: cotopaxi_1.IconColor.ACTION,
                            size: cotopaxi_1.IconSize.MINOR,
                        }, onClick: handlePopoverOpen, text: currentLang.toUpperCase() }) }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { buttons: [
                        {
                            text: getMobileLabel(),
                            iconLeft: {
                                name: cotopaxi_1.IconName.GLOBE,
                                color: cotopaxi_1.IconColor.ACTION,
                                size: cotopaxi_1.IconSize.MAJOR,
                            },
                            iconRight: {
                                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                color: cotopaxi_1.IconColor.ACTION,
                            },
                            onClick: handlePopoverOpen,
                            noBubble: true,
                        },
                    ] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { analyticsAreaName: "header-internationalisation", dataQA: "language_switcher_popup", noPadding: true, anchorEl: anchorEl, onClose: handlePopoverClose, position: cotopaxi_1.PopoverPosition.BOTTOM_LEFT, showTitleOnDesktop: false, title: t('navigation:flyout.bottom.language'), leftElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                        name: cotopaxi_1.IconName.ARROW_LEFT,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: closeAll, icon: {
                        name: cotopaxi_1.IconName.CLOSE,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), children: (0, jsx_runtime_1.jsx)(LanguagePopover_1.LanguagePopover, { currentLang: currentLang, alternateUrl: alternateUrl }) })] }));
};
exports.default = LanguageMenuItemContainer;
