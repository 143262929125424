"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewPaybackVoucherMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CurrencyUtil_1 = require("../../util/CurrencyUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const BasketOverviewPaybackVoucherMessage = ({ paybackVoucherMessages, messageBoxSpacing, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy]);
    const validPaybackVoucherMessage = paybackVoucherMessages[0];
    if (!validPaybackVoucherMessage)
        return null;
    const feedbackMessageContext = ((_a = validPaybackVoucherMessage.customMessage) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? validPaybackVoucherMessage.customMessage
        : t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.payback.message`, {
            currency: (0, CurrencyUtil_1.convertToCurrencySymbol)(validPaybackVoucherMessage.currency),
            amountUntilValid: validPaybackVoucherMessage.amountUntilValid.toFixed(2),
        });
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MEDIUM, text: feedbackMessageContext }) })));
};
exports.BasketOverviewPaybackVoucherMessage = BasketOverviewPaybackVoucherMessage;
