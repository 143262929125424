import { renderReactComponentsInsideElementsIfNeeded } from '@as-react/client-side-renderer';
import { availableComponents, renderingTools, usedVersionReact } from '@as-react/as-react';
import { isNullOrUndefined } from '@as-react/common-utils';
import { registerServiceWorker } from './util/serviceWorkerLoader';
import { Logger } from '@as-react/logger';
import { activateMediatorSubscribers } from './mediator/mediatorSubscriber';
import { AemConfiguration } from '@as-react/common-types';
import './util/polyfills';

declare const SITE: { Config: AemConfiguration };

const locale =
  !isNullOrUndefined(SITE) && !isNullOrUndefined(SITE.Config) && !isNullOrUndefined(SITE.Config.locale)
    ? SITE.Config.locale
    : undefined;
const aemVersion =
  !isNullOrUndefined(SITE) && !isNullOrUndefined(SITE.Config) && !isNullOrUndefined(SITE.Config.version)
    ? SITE.Config.version
    : 'latest';
const translationLang =
  !isNullOrUndefined(SITE) && !isNullOrUndefined(SITE.Config) && !isNullOrUndefined(SITE.Config.translationLang)
    ? SITE.Config.translationLang
    : undefined;

registerServiceWorker({
  reactVersion: usedVersionReact,
});

(async () => {
  await Logger.init({
    release: process.env.PACKAGE_VERSION!,
  });

  try {
    activateMediatorSubscribers();
  } catch {}

  await renderReactComponentsInsideElementsIfNeeded(
    availableComponents,
    document,
    renderingTools,
    undefined,
    locale,
    translationLang,
    aemVersion
  );
})();
