"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CartHeading = ({ showResetButton, resetBasketForShopLanding }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: { marginTop: 3 },
        }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, priority: cotopaxi_1.HeadingPriority.H1, children: t('checkout.basket.title.page') }), showResetButton && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK_SUBTLE, size: cotopaxi_1.ButtonSize.SMALL, iconLeft: {
                        name: cotopaxi_1.IconName.TRASH,
                        size: cotopaxi_1.IconSize.EXTRA_MINOR,
                    }, text: t('basket.reset'), dataQA: "reset_basket", onClick: resetBasketForShopLanding }))] }) }));
};
exports.default = CartHeading;
