"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const ProductGroupFredhopperReducer_1 = require("../../productGroupFredhopper/reducers/ProductGroupFredhopperReducer");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const loadProductGroupFredhopperModule = (initialData) => ({
    id: (initialData === null || initialData === void 0 ? void 0 : initialData.productGroupFredhopperId)
        ? `${common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER}_${initialData.productGroupFredhopperId}`
        : common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER,
    reducerMap: {
        productGroupFredhopper: (0, ProductGroupFredhopperReducer_1.loadProductGroupFredhopperReducer)(initialData),
    },
    sagas: [DeliveryPromiseSaga_1.default],
});
exports.default = loadProductGroupFredhopperModule;
