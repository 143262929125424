"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.ordersActions = {
    getOrderedItemsForAuthenticatedUser: () => ({
        type: ActionEnums_1.OrdersActionsTypes.GET_ORDERED_ITEMS_FOR_AUTHENTICATED_USER,
    }),
    getOrderedItemsForGuestUser: () => ({
        type: ActionEnums_1.OrdersActionsTypes.GET_ORDERED_ITEMS_FOR_GUEST_USER,
    }),
    setOrderedItems: (items) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_ORDERED_ITEMS,
        payload: items,
    }),
    // Return items
    getReturnItems: () => ({
        type: ActionEnums_1.OrdersActionsTypes.GET_RETURN_ITEMS,
    }),
    setReturnItems: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_RETURN_ITEMS,
        payload,
    }),
    setAnnouncedReturn: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_ANNOUNCED_RETURN,
        payload,
    }),
    getReturnReasons: () => ({
        type: ActionEnums_1.OrdersActionsTypes.GET_RETURN_REASONS,
    }),
    setReturnReasons: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_RETURN_REASONS,
        payload,
    }),
    submitReturnItems: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SUBMIT_RETURN_ITEMS,
        payload,
    }),
    updateReturnReason: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.UPDATE_RETURN_REASON,
        payload,
    }),
    getReturnedSales: () => ({
        type: ActionEnums_1.OrdersActionsTypes.GET_RETURNED_SALES,
    }),
    setReturnedSales: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_RETURNED_SALES,
        payload,
    }),
    setSubmitReturnItemsState: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_SUBMIT_RETURN_ITEMS_STATE,
        payload,
    }),
    setUpdateAnnouncedReturnState: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_UPDATE_ANNOUNCED_RETURN_STATE,
        payload,
    }),
    setOrderedItemsState: (payload) => ({
        type: ActionEnums_1.OrdersActionsTypes.SET_ORDERED_ITEMS_STATE,
        payload,
    }),
};
