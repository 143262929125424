"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const AddReviewBanner = ({ onShowReviewForm, title, description }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: {
            spacing: {
                paddingTop: 4,
            },
        }, mobile: {
            spacing: {
                paddingTop: 3,
                paddingBottom: 4,
            },
            theme: cotopaxi_1.BoxTheme.TERTIARY,
        }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { align: cotopaxi_1.TextAlign.CENTER, children: [title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, size: cotopaxi_1.HeadingSize.XS, children: title })), description && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            paddingTop: 1,
                            paddingBottom: 2,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: description }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: onShowReviewForm, variant: cotopaxi_1.ButtonVariant.OUTLINED, dataQA: "write_review", text: t('product.review.add.banner.cta') })] }) }));
};
exports.default = AddReviewBanner;
