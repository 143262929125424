"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const AEMUtil_1 = require("../util/AEMUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const LoginRegistrationForm_1 = require("../components/account/registration/LoginRegistrationForm");
const SessionStorageUtil_1 = require("../util/SessionStorageUtil");
const RegistrationCompactContainer = ({ paddings, title, image, registrationRedirectUrl, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy]);
    const handleLoginRegistrationSuccess = (email) => {
        SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.REGISTRATION_EMAIL, email);
        window.location.href = registrationRedirectUrl;
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(paddings), { children: (0, jsx_runtime_1.jsx)(LoginRegistrationForm_1.LoginRegistrationForm, { sectionTitle: title, image: image, onSuccess: handleLoginRegistrationSuccess, emailValidationErrorMessages: {
                invalidEmail: t(common_types_1.ErrorMessage.INVALID_EMAIL),
                [common_types_1.AuthStatusCode.VALID_ACCOUNT]: t(common_types_1.ErrorMessage.REGISTRATION_COMPACT_USER_KNOWN),
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_SAME_FASCIA_NO_PASSWORD),
                [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_OTHER_FASCIA),
                [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_OTHER_FASCIA_NO_PASSWORD),
            } }) })));
};
exports.default = RegistrationCompactContainer;
