"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const SingleProductTileDescription_1 = require("./SingleProductTileDescription");
const ProductTileVertical_1 = require("../../components/product-tile/vertical/ProductTileVertical");
const SingleProductTile = ({ analyticsBannerId, product, tileFormat, deliveryPromiseExplanations, withoutSpacing = false, onWishListToggle, updateCompareList, getDeliveryPromise, addToBasket, isFallbackProduct, shortDescription, pros, cons, adjustTileDescriptionHeight, }) => {
    const boxSpacing = !withoutSpacing
        ? {
            mobile: {
                spacing: {
                    paddingLeft: 1,
                    paddingRight: 1,
                },
            },
            tablet: {
                spacing: {
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                },
            },
            desktop: {
                spacing: {
                    paddingLeft: 2,
                    paddingRight: 2,
                },
            },
        }
        : {};
    const showDescription = tileFormat === 'simple' && !isFallbackProduct && Boolean(shortDescription || (pros === null || pros === void 0 ? void 0 : pros.length) || (cons === null || cons === void 0 ? void 0 : cons.length));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, boxSpacing, { dataAttributes: { 'data-analytics-bannerid': analyticsBannerId }, fullHeight: adjustTileDescriptionHeight, children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: product, format: tileFormat, deliveryPromiseExplanations: deliveryPromiseExplanations, onWishListToggle: onWishListToggle, updateCompareList: updateCompareList, getDeliveryPromise: getDeliveryPromise, addToBasket: addToBasket, shouldLazyLoadImage: common_types_1.LazyLoadOptions.LAZY_LOAD, dropdownProps: { appendToBody: true }, showProductOutOfStockOverlay: true, showColorOutOfStockOverlay: true, adjustDescriptionHeight: adjustTileDescriptionHeight, description: showDescription && (0, jsx_runtime_1.jsx)(SingleProductTileDescription_1.SingleProductTileDescription, { description: shortDescription, pros: pros, cons: cons }) }) })));
};
exports.default = SingleProductTile;
