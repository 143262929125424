"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const NavigationUtil_1 = require("../../util/NavigationUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const HtmlInclude_1 = require("../../components/common/html-include/HtmlInclude");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const AccountPreferenceItem_1 = require("../../components/account/account-preferences/AccountPreferenceItem");
const EMAIL_NEWSLETTER_TYPE = 'email_newsletter';
const AccountUnsubscribeContainer = ({ label, showCancel, newsletters, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    const [buttonState, setButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)('');
    const [uuid, setUuid] = (0, react_1.useState)('');
    const [newsletterType, setNewsletterType] = (0, react_1.useState)('');
    const [preferencesNewsletter, setPreferencesNewsletter] = (0, react_1.useState)({});
    const withSubnewsletters = (newsletters === null || newsletters === void 0 ? void 0 : newsletters.subNewsletters) && (newsletters === null || newsletters === void 0 ? void 0 : newsletters.subNewsletters.length) > 0;
    const getQueryParam = (name) => { var _a; return (_a = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, name)) !== null && _a !== void 0 ? _a : ''; };
    const { getPreferencesNewsletter } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerNewsletters } = (0, react_query_1.useQuery)(getPreferencesNewsletter({ customerUUID: uuid }, { enabled: Boolean(uuid) }));
    (0, react_1.useEffect)(() => {
        setUuid(getQueryParam('uuid'));
        setNewsletterType(getQueryParam('type'));
    }, [isMounted]);
    (0, react_1.useEffect)(() => {
        if (customerNewsletters) {
            setPreferencesNewsletter(customerNewsletters);
        }
    }, [customerNewsletters]);
    const unsubscribe = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const email = getQueryParam('email');
        const uuid = getQueryParam('uuid');
        const idCustomer = getQueryParam('idCustomer');
        const type = getQueryParam('type');
        const fasciaId = getQueryParam('shop');
        setButtonState(cotopaxi_1.ButtonState.LOADING);
        try {
            if (withSubnewsletters && newsletterType === EMAIL_NEWSLETTER_TYPE) {
                yield CustomerApi_1.default.unsubscribeNewsletterPreferences(servicesEndpoint, defaultRequestParams, uuid, preferencesNewsletter);
            }
            else {
                yield CustomerApi_1.default.unsubscribePreferences(servicesEndpoint, defaultRequestParams, email, idCustomer, fasciaId, uuid, type);
            }
            setErrorFeedback('');
            setButtonState(cotopaxi_1.ButtonState.FINISHED);
            setTimeout(() => (window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root)), GeneralConstants_1.DELAY.DELAY_3000);
        }
        catch (_a) {
            setButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            setErrorFeedback(t('account.unsubscribe.error'));
        }
    });
    const onPreferenceChange = (preference, value) => {
        let preferences = {};
        if (preference === newsletters.type) {
            Object.keys(preferencesNewsletter).forEach(key => (preferences[key] = value));
        }
        else {
            preferences = Object.assign(Object.assign({}, preferencesNewsletter), { [`${newsletters.type}#${preference}`]: value });
        }
        setPreferencesNewsletter(preferences);
    };
    const accountUnsubscribeBtn = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: !isMobile, gutter: cotopaxi_1.GroupGutter.LARGE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, state: buttonState, text: label || t('account.unsubscribe.confirm'), finisher: {
                            icon: cotopaxi_1.IconName.CHECK,
                            text: t('account.unsubscribe.unsubscribed'),
                        }, onClick: unsubscribe }), showCancel && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, variant: cotopaxi_1.ButtonVariant.OUTLINED, text: t('account.unsubscribe.cancel'), href: (0, NavigationUtil_1.getHomepageUrl)(root) }))] })] }));
    if (withSubnewsletters && newsletterType === EMAIL_NEWSLETTER_TYPE) {
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3, marginTop: 3 } }, children: [(0, jsx_runtime_1.jsx)(AccountPreferenceItem_1.AccountPreferenceItem, { preferenceType: newsletters.type, selected: Object.values(preferencesNewsletter).some(value => value === true), onChange: isSelected => onPreferenceChange(newsletters.type, isSelected), checkboxLabel: newsletters.checkboxLabel, preferenceText: newsletters.preferenceText }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 5, marginTop: 3, marginBottom: 3 } }, children: newsletters.subNewsletters.map((subItem, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: index === 0 ? 0 : 3 } }, children: (0, jsx_runtime_1.jsx)(AccountPreferenceItem_1.AccountPreferenceItem, { preferenceType: subItem.type, selected: preferencesNewsletter[`${newsletters.type}#${subItem.type}`], onChange: isSelected => onPreferenceChange(subItem.type, isSelected), checkboxLabel: subItem.title, preferenceText: subItem.description }) }, subItem.type))) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: t('account.unsubscribe.message') }) }) }), accountUnsubscribeBtn] }));
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { withShadow: !isMobile, mobile: {
            spacing: {
                paddingTop: 3,
                paddingRight: 2,
                paddingBottom: 2,
                paddingLeft: 2,
                marginBottom: 5,
                marginTop: 3,
            },
            bleed: { marginInline: 2 },
            theme: cotopaxi_1.BoxTheme.PRIMARY,
        }, tablet: {
            spacing: {
                paddingTop: 5,
                paddingRight: 6,
                paddingBottom: 3,
                paddingLeft: 6,
                marginBottom: 6,
                marginTop: 3.5,
            },
        }, desktop: { spacing: { paddingBottom: 6, marginBottom: 7 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: t('account.unsubscribe.message') }) }) }), accountUnsubscribeBtn] }));
};
exports.default = AccountUnsubscribeContainer;
