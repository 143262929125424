"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetQueryParamProfileEdit = exports.makeGetQueryParamPage = exports.makeGetLocationHash = exports.makeGetPathname = void 0;
const reselect_1 = require("reselect");
const routerStateSelector = state => state.router;
const makeGetPathname = () => (0, reselect_1.createSelector)(routerStateSelector, (routerState) => routerState.location.pathname);
exports.makeGetPathname = makeGetPathname;
const makeGetLocationHash = () => (0, reselect_1.createSelector)(routerStateSelector, (routerState) => routerState.location.hash);
exports.makeGetLocationHash = makeGetLocationHash;
const makeGetQueryParamPage = () => {
    return (0, reselect_1.createSelector)(routerStateSelector, 
    // @ts-ignore: "query" parameter is missing in type definitions of connected-react-router package.
    (state) => { var _a; return parseInt((_a = state.location.query) === null || _a === void 0 ? void 0 : _a.page, 10) || 0; });
};
exports.makeGetQueryParamPage = makeGetQueryParamPage;
const makeGetQueryParamProfileEdit = () => {
    return (0, reselect_1.createSelector)(routerStateSelector, 
    // @ts-ignore: "query" parameter is missing in type definitions of connected-react-router package.
    (state) => { var _a; return (_a = state.location.query) === null || _a === void 0 ? void 0 : _a.profileEdit; });
};
exports.makeGetQueryParamProfileEdit = makeGetQueryParamProfileEdit;
