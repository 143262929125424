"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const URLParamUtil_1 = require("../util/URLParamUtil");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const SessionUtil_1 = require("../util/SessionUtil");
const GuestRedirectContainer = ({ visibility }) => {
    const userSession = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const redirectToLogin = () => {
        window.location.href = (0, URLParamUtil_1.generatePathUrl)(root, 'account.html').pathname;
    };
    (0, react_1.useEffect)(() => {
        var _a;
        if (!((_a = userSession === null || userSession === void 0 ? void 0 : userSession.authorities) === null || _a === void 0 ? void 0 : _a.length))
            return;
        switch (visibility) {
            case common_types_1.UserVisibility.AUTHORIZED:
                !(0, SessionUtil_1.isLoggedInUser)(userSession) && redirectToLogin();
                break;
            case common_types_1.UserVisibility.AUTHORIZED_AND_SOFT_LOGGED_IN:
                !(0, SessionUtil_1.isLoggedInUser)(userSession) && !(0, SessionUtil_1.isLoggedInGuestWebReturns)(userSession) && redirectToLogin();
                break;
            default:
                break;
        }
    }, [userSession]);
    return null;
};
exports.default = GuestRedirectContainer;
