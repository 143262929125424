"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItemTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterWizard_1 = require("./filter-wizard/FilterWizard");
const FilterItemTitle = ({ badge, title, tooltip, showFilterWizard, filter, filterWizardConfig, urlFilters, initialItemsCount, updateFilters, }) => {
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingRight: 3.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.SMALL, valign: cotopaxi_1.GroupVerticalAlign.CENTER, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [showFilterWizard ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: title }), (0, jsx_runtime_1.jsx)(FilterWizard_1.FilterWizard, { filter: filter, filterWizardPath: filterWizardConfig === null || filterWizardConfig === void 0 ? void 0 : filterWizardConfig.path, updateFilters: updateFilters, urlFilters: urlFilters, initialItemsCount: initialItemsCount })] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: title })), Boolean(tooltip) && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingRight: 1 } }, flex: { shrink: 0 }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, variant: cotopaxi_1.ButtonVariant.INVERSE_ALT, onClick: handlePopoverOpen, size: cotopaxi_1.ButtonSize.MEDIUM, icon: {
                                        name: cotopaxi_1.IconName.INFORMATION_OUTLINE,
                                        color: cotopaxi_1.IconColor.ACTION,
                                        size: cotopaxi_1.IconSize.MINOR,
                                    }, shape: cotopaxi_1.ButtonShape.PILL }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handlePopoverClose, size: cotopaxi_1.PopoverSize.MEDIUM, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                                            name: cotopaxi_1.IconName.CLOSE,
                                            color: cotopaxi_1.IconColor.ACTION,
                                            size: cotopaxi_1.IconSize.MAJOR,
                                        } }), position: cotopaxi_1.PopoverPosition.RIGHT, title: title, children: tooltip })] }))] }), Boolean(badge) && (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { count: badge })] }) }));
};
exports.FilterItemTitle = FilterItemTitle;
