"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const CookieMessage_1 = require("../components/common/cookie-message/CookieMessage");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const CookieConsentActions_creators_1 = require("../redux/cookieConsent/actions/CookieConsentActions.creators");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const CookieMessageContainer = ({ updateCookieConsentAction, analyticsCookieConsentAcceptAll, analyticsCookieConsentOpenPreferences, analyticsCookieConsentUpdated, cookieConsentSettingAvailable, cookieConsentSettings, logo, panel1, panel2, }) => {
    const cookieConsentAcceptAll = (consentSetting) => {
        updateCookieConsentAction(consentSetting);
        analyticsCookieConsentAcceptAll(consentSetting);
    };
    const cookieConsentUpdated = (consentSetting) => {
        updateCookieConsentAction(consentSetting);
        analyticsCookieConsentUpdated(consentSetting);
    };
    return ((0, jsx_runtime_1.jsx)(CookieMessage_1.default, { cookieConsentAcceptAll: cookieConsentAcceptAll, analyticsCookieConsentOpenPreferences: analyticsCookieConsentOpenPreferences, cookieConsentUpdated: cookieConsentUpdated, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE, cookieConsentSettings: cookieConsentSettings, logo: logo, panel1: panel1, panel2: panel2 }));
};
const makeMapStateToProps = () => {
    const getCookieConsentSettings = (0, CookieConsentSelector_1.makeGetCookieConsentSettings)();
    const getCookieConsentSettingAvailable = (0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)();
    const mapStateToProps = (state) => ({
        cookieConsentSettings: getCookieConsentSettings(state),
        cookieConsentSettingAvailable: getCookieConsentSettingAvailable(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    updateCookieConsentAction: CookieConsentActions_creators_1.default.createSetAction,
    analyticsCookieConsentAcceptAll: AnalyticsActions_creators_1.analyticsActions.cookieConsentAcceptAll,
    analyticsCookieConsentOpenPreferences: AnalyticsActions_creators_1.analyticsActions.cookieConsentOpenPreferences,
    analyticsCookieConsentUpdated: AnalyticsActions_creators_1.analyticsActions.cookieConsentUpdated,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(CookieMessageContainer);
