"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedFilterRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AppliedFilterItem_1 = require("./AppliedFilterItem");
const AppliedFilterRow = ({ flattenedActiveFilters, onToggleFilter, onRemoveAllFilters, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    if (!(!!flattenedActiveFilters && flattenedActiveFilters.length > 0)) {
        return null;
    }
    const getKey = (activeFilterItem) => activeFilterItem.filterType === common_types_1.RANGE_FILTER
        ? activeFilterItem.filterOn
        : `${activeFilterItem.filterOn}_${activeFilterItem.filterKey}`;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [flattenedActiveFilters.map(activeFilterItem => ((0, jsx_runtime_1.jsx)(AppliedFilterItem_1.AppliedFilterItem, { onToggleFilter: onToggleFilter, activeFilterItem: activeFilterItem }, getKey(activeFilterItem)))), "\u2003", (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: onRemoveAllFilters, size: cotopaxi_1.ButtonSize.SMALL, text: t('product.lister.filter.clear') })] }));
};
exports.AppliedFilterRow = AppliedFilterRow;
