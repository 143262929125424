import { presoldenSubscriber } from './subscribers/presoldenSubscriber';
import { Adobe, Satellite } from './mediatorTypes';

declare global {
  interface Window {
    adobe: Adobe; // adobe object exposed on window by Adobe Target
    YonderlandMediator: Array<Record<string, unknown>>; // our custom mediator implementation
    _satellite: Satellite; // _satellite object exposed on window by Adobe Launch
  }
}

export const activateMediatorSubscribers = () => {
  const { adobe, _satellite } = window;
  window.YonderlandMediator = window.YonderlandMediator || [];

  presoldenSubscriber(window.YonderlandMediator, adobe, _satellite);
  // additional subscribers go here
  // ...
};
