"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchReducer = exports.initialState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialState = {
    searchValue: '',
    selectedRow: -1,
    resultsLimit: 3,
    searchKeywords: [],
    searchBrands: [],
    searchCategories: [],
    searchBrandAsCategories: [],
    searchProducts: [],
    searchContent: [],
};
const searchReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.SearchActionTypes.FIND:
            const { searchContent, searchSuggestions } = action.payload;
            return Object.assign(Object.assign({}, state), { searchKeywords: searchSuggestions.keywords || [], searchBrands: searchSuggestions.brands || [], searchCategories: searchSuggestions.categories || [], searchBrandAsCategories: searchSuggestions.brandAsCategories || [], searchProducts: searchSuggestions.products || [], searchContent: searchContent || [] });
        default:
            return state;
    }
};
exports.searchReducer = searchReducer;
