"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsNL = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const AddressFieldsNLDefault_1 = require("./AddressFieldsNLDefault");
const AddressFieldsNLWithCard_1 = require("./AddressFieldsNLWithCard");
const AddressFieldsNL = (_a) => {
    var { showAddressCard } = _a, props = tslib_1.__rest(_a, ["showAddressCard"]);
    return showAddressCard ? (0, jsx_runtime_1.jsx)(AddressFieldsNLWithCard_1.AddressFieldsNLWithCard, Object.assign({}, props)) : (0, jsx_runtime_1.jsx)(AddressFieldsNLDefault_1.AddressFieldsNLDefault, Object.assign({}, props));
};
exports.AddressFieldsNL = AddressFieldsNL;
