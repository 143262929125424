"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationAccount = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AdvantagesSection_1 = require("../../advantages-section/AdvantagesSection");
const LoginUtil_1 = require("../../../util/LoginUtil");
const BuyConfirmationAccount = ({ order, usp, setPassword, resetPasswordState, loginError, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.account]);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isDesktopLarge = (0, cotopaxi_1.useMediaQueryMatchesOnDesktopLarge)();
    const isDesktopSpecific = isDesktop && !isDesktopLarge;
    const [passwordButtonState, setPasswordButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const items = (0, react_1.useMemo)(() => {
        const modifiedUSP = Object.assign({ oneLabel: usp.oneLabel, twoLabel: usp.twoLabel, threeLabel: usp.threeLabel, fourLabel: usp.fourLabel }, usp);
        return Object.entries(modifiedUSP)
            .filter(([key, value]) => key !== 'successMessage' && key !== 'sendMessage' && value)
            .map(([, text]) => ({
            text,
        }));
    }, [usp]);
    const resetPassword = () => {
        setPasswordButtonState(cotopaxi_1.ButtonState.LOADING);
        setPassword(order.contactPerson);
    };
    (0, react_1.useEffect)(() => {
        (resetPasswordState === null || resetPasswordState === void 0 ? void 0 : resetPasswordState.success) && setPasswordButtonState(cotopaxi_1.ButtonState.FINISHED);
    }, [resetPasswordState]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { withShadow: true, mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 6 } }, desktop: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: !isMobile, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, column: isMobile, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { padding: 2, paddingBottom: 3 } }, tablet: { spacing: { padding: 4, paddingBottom: 5 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: isMobile ? cotopaxi_1.HeadingSize.XS : cotopaxi_1.HeadingSize.S, children: t('buy:buy.confirmation.account.create') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 0.25 } }, tablet: { spacing: { paddingTop: 1 } }, children: (0, jsx_runtime_1.jsx)(AdvantagesSection_1.AdvantagesSection, { items: items }) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 2 }, theme: cotopaxi_1.BoxTheme.PRIMARY }, tablet: { spacing: { padding: 4 } }, flex: { basis: isDesktopSpecific ? '50%' : '45%' }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { fullHeight: true, gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.LEFT, column: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, text: passwordButtonState === cotopaxi_1.ButtonState.LOADING ? usp.sendMessage : t('buy:buy.confirmation.password.set'), onClick: resetPassword, state: passwordButtonState, finisher: { text: usp.successMessage, icon: cotopaxi_1.IconName.CHECK } }), loginError && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, size: cotopaxi_1.TextSize.SMALL, children: (0, LoginUtil_1.getLoginError)(loginError, t) }) }))] }) })] }) }));
};
exports.BuyConfirmationAccount = BuyConfirmationAccount;
