"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsDE = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const addressFields_utils_1 = require("../addressFields.utils");
const common_types_1 = require("@as-react/common-types");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const andes_react_1 = require("@yonderland/andes-react");
const FormField_1 = require("../../FormField");
const react_hook_form_1 = require("react-hook-form");
const PostcodeWatcher_1 = require("../watchers/PostcodeWatcher");
const react_1 = require("react");
const AddressFieldsDE = ({ prefix, isRequired = true, dataListEnabled }) => {
    const [addresses, setAddresses] = (0, react_1.useState)([]);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [addressName, houseNumberName, postCodeName, cityName, customTownName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const { control } = (0, react_hook_form_1.useFormContext)();
    const showCustomTown = (0, react_hook_form_1.useWatch)({
        control,
        name: customTownName,
    });
    const cityOptions = addresses
        ? addresses.map(address => ({
            text: address.city,
            id: address.city,
        }))
        : [{ text: '', id: '' }];
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 8 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { error: error, id: addressName, required: isRequired, label: t('address.street'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "address_line1" })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 4 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { error: error, id: houseNumberName, required: isRequired, maxLength: 20, label: t('address.houseNumber'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "houseNumber" })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { error: error, id: postCodeName, required: isRequired, minLength: 5, maxLength: 50, label: t('address.postCode'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "postCode" })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 5 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref, onChange } = _b, fieldProps = tslib_1.__rest(_b, ["ref", "onChange"]), { error } = _a;
                                return showCustomTown ? ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { onChange: onChange, error: error, id: cityName, required: isRequired, label: t('address.city'), placeholder: t('address.city.choose'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "town_city" }))) : ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, { input: {
                                        error,
                                        id: cityName,
                                        required: isRequired,
                                        label: t('address.city'),
                                        optionalText: isRequired ? undefined : t('form.field.optional'),
                                        placeholder: t('address.city.choose'),
                                    }, items: cityOptions, dataTestId: "town_city", onSelect: item => onChange(item.id) }));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 4 }, offset: { md: 7, lg: 0 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: customTownName, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { lg: '9' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { error: error, id: customTownName, label: t('address.enable.custom.town'), dataTestId: "customTown", checked: fieldProps.value })) }));
                            } }) })] }), (0, jsx_runtime_1.jsx)(PostcodeWatcher_1.PostcodeWatcher, { countryId: common_types_1.CountryId.DE, setAddresses: setAddresses, prefix: prefix })] }));
};
exports.AddressFieldsDE = AddressFieldsDE;
