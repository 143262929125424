"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ColorSwatches_1 = require("../components/product-detail/color-swatches/ColorSwatches");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ColorSwatchesContainer = ({ enableShopTheLook = false }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productCdnDomains = (0, ConfigurationUtil_1.getProductImageCdnDomains)(aemConfiguration);
    const swatchInformation = (0, ConfigurationUtil_1.getProductSwatchInformation)(aemConfiguration);
    const isShopLanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const { isPDPPage } = (0, ConfigurationUtil_1.getPageInfo)(aemConfiguration);
    const { categorized_product_features } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const setProductColor = (colourId) => {
        dispatch(ProductActions_1.productActions.createSetColorAction(colourId));
        if (isPDPPage && categorized_product_features) {
            dispatch(ProductActions_1.productActions.createGetTechnicalCategories(colourId));
        }
        if (enableShopTheLook) {
            dispatch(ProductActions_1.productActions.createGetLinkedProducts(colourId));
        }
    };
    const colors = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetAvailableColors)());
    const productCode = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)()).productCode;
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    return ((0, jsx_runtime_1.jsx)(ColorSwatches_1.default, { colors: colors, productCdnDomains: productCdnDomains, productCode: productCode, selectedColorId: selectedColorId, setProductColor: setProductColor, swatchInformation: swatchInformation, isShopLanding: isShopLanding }));
};
exports.default = ColorSwatchesContainer;
