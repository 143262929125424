"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETICKET_CONSTANTS = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../constants/i18nConstants");
const EticketHeader_1 = require("../components/eticket/eticket-header/EticketHeader");
const EticketBody_1 = require("../components/eticket/eticket-body/EticketBody");
const EticketFooter_1 = require("../components/eticket/eticket-footer/EticketFooter");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const EticketAPI_1 = require("../api/EticketAPI");
const URLParamUtil_1 = require("../util/URLParamUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
exports.ETICKET_CONSTANTS = {
    RATING_TYPE: {
        SALE: 11,
        DEPARTMENT: 12,
        REGISTER: 13,
    },
    RATING: {
        GOOD: 5,
        OK: 3,
        BAD: 1,
    },
};
const EticketContainer = ({ departments, feedback }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const [queryCustomerUuid, setQueryCustomerUuid] = (0, react_1.useState)('');
    const [querySaleId, setQuerySaleId] = (0, react_1.useState)('');
    const [ticketInfoError, setTicketInfoError] = (0, react_1.useState)(false);
    const [goodRating, setGoodRating] = (0, react_1.useState)(null);
    const [feedbackFormSuccess, setFeedbackFormSuccess] = (0, react_1.useState)(false);
    const [feedbackFormError, setFeedbackFormError] = (0, react_1.useState)(false);
    const [eTicketInformation, setEticketInformation] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        setQueryCustomerUuid((0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.CUSTOMER_UUID));
        setQuerySaleId((0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.SALE_ID));
    }, []);
    (0, react_1.useEffect)(() => {
        const getCustomerInfo = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                const info = yield EticketAPI_1.default.getTicketInformation(defaultRequestParameters, servicesEndpoint, queryCustomerUuid, querySaleId);
                setEticketInformation(info);
                if (!info.alreadyVotedOnSale) {
                    EticketAPI_1.default.voting(defaultRequestParameters, servicesEndpoint, queryCustomerUuid, querySaleId, exports.ETICKET_CONSTANTS.RATING_TYPE.SALE, Number((0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.RATING)));
                }
                else {
                    setGoodRating(info.saleRating);
                }
            }
            catch (_a) {
                setTicketInfoError(true);
            }
        });
        if (queryCustomerUuid && querySaleId) {
            getCustomerInfo();
        }
    }, [queryCustomerUuid, querySaleId]);
    const sendCustomFeedback = (feedbackText) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield EticketAPI_1.default.sendFeedback(defaultRequestParameters, servicesEndpoint, queryCustomerUuid, querySaleId, feedbackText);
            dispatch(AnalyticsActions_creators_1.analyticsActions.eTicketSendFeedback());
            setFeedbackFormSuccess(true);
        }
        catch (_a) {
            setFeedbackFormError(true);
        }
    });
    const rateProduct = (rating, departmentId) => EticketAPI_1.default.voting(defaultRequestParameters, servicesEndpoint, queryCustomerUuid, querySaleId, departmentId ? exports.ETICKET_CONSTANTS.RATING_TYPE.DEPARTMENT : exports.ETICKET_CONSTANTS.RATING_TYPE.REGISTER, rating, departmentId);
    if (ticketInfoError) {
        return (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: t('eticket.error') });
    }
    if (!eTicketInformation) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('eticket.opinion') }) }), feedback && ((0, jsx_runtime_1.jsx)(EticketHeader_1.EticketHeader, { goodRating: goodRating, sendCustomFeedback: sendCustomFeedback, feedbackFormSuccess: feedbackFormSuccess, feedbackFormError: feedbackFormError })), departments && (0, jsx_runtime_1.jsx)(EticketBody_1.EticketBody, { rateProduct: rateProduct, eTicketInformation: eTicketInformation }), (0, jsx_runtime_1.jsx)(EticketFooter_1.EticketFooter, { mainWebShop: mainWebShop })] }));
};
exports.default = EticketContainer;
