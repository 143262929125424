"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormMessage = exports.FormMessageType = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
var FormMessageType;
(function (FormMessageType) {
    FormMessageType[FormMessageType["ERROR"] = 1] = "ERROR";
    FormMessageType[FormMessageType["INFO"] = 2] = "INFO";
})(FormMessageType || (exports.FormMessageType = FormMessageType = {}));
const FormMessage = props => {
    const { message, type } = props;
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.buy,
        i18nConstants_1.I18N_NAMESPACES.dpe,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const setTypeClass = () => {
        switch (type) {
            case FormMessageType.INFO:
                return 'a-form-message--info';
            default:
                return 'a-form-message--error';
        }
    };
    return (0, jsx_runtime_1.jsx)("div", { className: `a-form-message ${setTypeClass()}`, children: t(message) });
};
exports.FormMessage = FormMessage;
