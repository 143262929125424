"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsFR = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const addressFields_utils_1 = require("../addressFields.utils");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const FormField_1 = require("../../FormField");
const Datalist_1 = require("../../../datalist/Datalist");
const PostcodeWatcher_1 = require("../watchers/PostcodeWatcher");
const AddressFieldsFR = ({ prefix, isRequired = true, dataListEnabled }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [addresses, setAddresses] = (0, react_1.useState)([]);
    const [addressName, houseNumberName, houseNumberAdditionName, postCodeName, cityName, customTownName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const showCustomTown = (0, react_hook_form_1.useWatch)({ name: customTownName });
    const cityNameOptions = addresses
        ? addresses.map(address => ({ text: address.city, id: address.city }))
        : [{ text: '', id: '' }];
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 3, lg: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, required: isRequired, label: t('address.houseNumber'), optionalText: isRequired ? undefined : t('form.field.optional'), id: fieldProps.name, dataTestId: "houseNumber", error: error, maxLength: 20 })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 3, lg: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberAdditionName, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('address.houseNumberAddition'), optionalText: t('form.field.optional'), id: fieldProps.name, dataTestId: "houseNumberAddition", error: error, maxLength: 20 })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, required: isRequired, label: t('address.street'), optionalText: isRequired ? undefined : t('form.field.optional'), id: fieldProps.name, dataTestId: "address_line1", error: error, maxLength: 500 })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: dataListEnabled ? 6 : 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, required: isRequired, label: t('address.postCode'), optionalText: isRequired ? undefined : t('form.field.optional'), id: fieldProps.name, dataTestId: "postcode", error: error, minLength: 4, maxLength: 50 })));
                    } }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6, lg: dataListEnabled ? 6 : 5 }, children: [(showCustomTown || dataListEnabled) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, required: isRequired, label: t('address.city'), optionalText: isRequired ? undefined : t('form.field.optional'), placeholder: t('address.city.choose'), id: fieldProps.name, dataTestId: "town_city", error: error, maxLength: 50, list: `list-${cityName}` })));
                                } }), dataListEnabled && addresses && ((0, jsx_runtime_1.jsx)(Datalist_1.Datalist, { id: `list-${cityName}`, options: addresses === null || addresses === void 0 ? void 0 : addresses.map(address => address.city) }))] })), !showCustomTown && !dataListEnabled && ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                            var _b = _a.fieldProps, { ref, onChange, disabled } = _b, fieldProps = tslib_1.__rest(_b, ["ref", "onChange", "disabled"]), { error } = _a;
                            return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, Object.assign({}, fieldProps, { input: {
                                    name: fieldProps.name,
                                    id: fieldProps.name,
                                    label: t('address.city'),
                                    placeholder: t('address.city.choose'),
                                    optionalText: isRequired ? undefined : t('form.field.optional'),
                                    required: isRequired,
                                    disabled,
                                    error,
                                }, onSelect: item => onChange(Number(item.id)), items: cityNameOptions, dataTestId: "town_city" })));
                        } }))] }), !dataListEnabled && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 5, lg: 4 }, offset: { md: 7, lg: 0 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: customTownName, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { lg: '9' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { id: fieldProps.name, dataTestId: "customTown", label: t('address.enable.custom.town'), checked: fieldProps.value, error: error })) }));
                    } }) })), (0, jsx_runtime_1.jsx)(PostcodeWatcher_1.PostcodeWatcher, { countryId: common_types_1.CountryId.FR, setAddresses: setAddresses, prefix: prefix })] }));
};
exports.AddressFieldsFR = AddressFieldsFR;
