"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetHasGivenAConsent = exports.makeGetHasConsentOnMarketingCookies = exports.makeGetHasConsentOnAnalyticalCookies = exports.makeGetHasConsentOnFunctionalCookies = exports.makeGetCookieConsentSettingAvailable = exports.makeGetCookieConsentSettings = exports.cookieConsentStateSelector = void 0;
const common_types_1 = require("@as-react/common-types");
const reselect_1 = require("reselect");
const CookieConsentReducer_1 = require("../reducers/CookieConsentReducer");
const cookieConsentStateSelector = (state) => state.cookieConsent || CookieConsentReducer_1.initialCookieConsentState;
exports.cookieConsentStateSelector = cookieConsentStateSelector;
const makeGetCookieConsentSettings = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => state.cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE &&
    state.cookieConsentSettings);
exports.makeGetCookieConsentSettings = makeGetCookieConsentSettings;
const makeGetCookieConsentSettingAvailable = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => state.cookieConsentSettingAvailable);
exports.makeGetCookieConsentSettingAvailable = makeGetCookieConsentSettingAvailable;
const makeGetHasConsentOnFunctionalCookies = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => (state.cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE &&
    state.cookieConsentSettings[common_types_1.CookieConsentGroups.FUNCTIONAL]) ||
    false);
exports.makeGetHasConsentOnFunctionalCookies = makeGetHasConsentOnFunctionalCookies;
const makeGetHasConsentOnAnalyticalCookies = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => (state.cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE &&
    state.cookieConsentSettings[common_types_1.CookieConsentGroups.ANALYTICS]) ||
    false);
exports.makeGetHasConsentOnAnalyticalCookies = makeGetHasConsentOnAnalyticalCookies;
const makeGetHasConsentOnMarketingCookies = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => (state.cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE &&
    state.cookieConsentSettings[common_types_1.CookieConsentGroups.MARKETING]) ||
    false);
exports.makeGetHasConsentOnMarketingCookies = makeGetHasConsentOnMarketingCookies;
const makeGetHasGivenAConsent = () => (0, reselect_1.createSelector)(exports.cookieConsentStateSelector, (state) => state.cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE || false);
exports.makeGetHasGivenAConsent = makeGetHasGivenAConsent;
