"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApplePayButton = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ApplePayUtil_1 = require("../util/ApplePayUtil");
const BuyAPI_1 = require("../api/buy/BuyAPI");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const APPLE_PAY_PAYMENT_OPTION_ID = 776;
const APPLE_PAY_SUPPORTED_NETWORKS_MAPPING = {
    MASTERCARD: 'masterCard',
    CARTESBANCAIRES: 'cartesBancaires',
    VPAY: 'vPay',
};
const APPLE_PAY_SUPPORTED_NETWORKS_FALLBACK = ['maestro', 'masterCard', 'visa'];
const useApplePayButton = () => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { merchantId } = (0, ConfigurationUtil_1.getApplePayConfig)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [isApplePayCanMakePaymentsWithActiveCard, setIsApplePayCanMakePaymentsWithActiveCard] = (0, react_1.useState)(null);
    const [isApplePayFastCheckoutEnabled, setIsApplePayFastCheckoutEnabled] = (0, react_1.useState)(false);
    const [isApplePayChecked, setIsApplePayChecked] = (0, react_1.useState)(false);
    const [supportedNetworks, setSupportedNetworks] = (0, react_1.useState)([]);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const checkApplePayCanMakePaymentsWithActiveCard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const canMake = yield ApplePayUtil_1.default.canMakePaymentsWithActiveCard(merchantId);
        setIsApplePayCanMakePaymentsWithActiveCard(canMake);
    });
    const getPaymentOptions = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const paymentOptions = yield BuyAPI_1.default.getPaymentOptions(servicesEndpoint, defaultRequestParameters, common_types_1.PaymentGroupName.CREDITCARD.toLowerCase());
            const networks = paymentOptions
                .filter(option => option.enabled)
                .map(option => APPLE_PAY_SUPPORTED_NETWORKS_MAPPING[option.name] || option.name.toLowerCase());
            setSupportedNetworks(networks);
        }
        catch (_a) {
            setSupportedNetworks(APPLE_PAY_SUPPORTED_NETWORKS_FALLBACK);
        }
    });
    const getApplePayFastCheckoutEnable = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const answer = yield BuyAPI_1.default.getPaymentOptionEnable(servicesEndpoint, defaultRequestParameters, APPLE_PAY_PAYMENT_OPTION_ID);
            setIsApplePayFastCheckoutEnabled(answer);
        }
        catch (_b) {
            setIsApplePayFastCheckoutEnabled(false);
        }
    });
    (0, react_1.useEffect)(() => {
        if (!ApplePayUtil_1.default.canMakePayments()) {
            setIsApplePayChecked(true);
            return;
        }
        if (!(0, common_utils_1.isNullOrUndefined)(isApplePayCanMakePaymentsWithActiveCard)) {
            setIsApplePayChecked(true);
        }
    }, [isApplePayCanMakePaymentsWithActiveCard]);
    (0, react_1.useEffect)(() => {
        getPaymentOptions();
        getApplePayFastCheckoutEnable();
        checkApplePayCanMakePaymentsWithActiveCard();
    }, []);
    return {
        isApplePayChecked,
        isApplePayCanMakePaymentsWithActiveCard,
        isApplePayFastCheckoutEnabled,
        isApplePayAvailable: isClient &&
            ApplePayUtil_1.default.canMakePayments() &&
            isApplePayCanMakePaymentsWithActiveCard &&
            isApplePayFastCheckoutEnabled &&
            supportedNetworks.length > 0,
        applePaySupportedNetworks: supportedNetworks,
    };
};
exports.useApplePayButton = useApplePayButton;
