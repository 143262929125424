"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_utils_1 = require("@as-react/common-utils");
const ProductUtil_1 = require("../util/ProductUtil");
class ProductDetailAPI {
    constructor() {
        this.fetchProductForPromoBasketPopup = (servicesApi, servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, isSingleLanguageSite, brandImageCdnUrl, productCode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const baseProduct = yield this.getProductDetailByProductCode(servicesApi, mainWebShop, productCode);
            const productPrice = yield this.getProductDetailPriceByProductId(servicesApi, mainWebShop, fictiveWebShop, baseProduct.productId);
            const productBrand = yield this.getProductDetailBrandByBrandId(servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, baseProduct.brandId);
            const productAvailability = yield this.getProductDetailAvailabilityByProductId(servicesApi, mainWebShop, baseProduct.productId);
            const { productColorVariations, selectedColorId } = (0, common_utils_1.determineSelectedColorId)(baseProduct, '');
            const productColorVariationsWithPrice = (0, common_utils_1.mapProductPriceToProductColorVariations)(productColorVariations, productPrice);
            const productColorVariationsWithZeroPrice = (0, common_utils_1.mapAllPricesToZeroForProductColorVariations)(productColorVariationsWithPrice);
            const productColorVariationsWithAvailability = (0, common_utils_1.mapProductAvailabilityToProductColorVariations)(productColorVariationsWithZeroPrice, productAvailability);
            const availableProductColors = productColorVariationsWithAvailability.filter(color => { var _a; return ((_a = color.sizes) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            const selectedColor = availableProductColors.find(color => color.colorId === selectedColorId) || availableProductColors[0];
            return Object.assign(Object.assign({}, baseProduct), { brand: {
                    description: productBrand.description,
                    relativePath: `${isSingleLanguageSite ? '' : '/' + language}/${productBrand.relativePath}.html`,
                    logo: `${brandImageCdnUrl}${productBrand.logo}`,
                    logoTimestamp: productBrand.logoTimestamp,
                }, productColorVariations: availableProductColors.map(productColorVariation => (Object.assign(Object.assign({}, productColorVariation), { sizes: 'sizes' in productColorVariation &&
                        productColorVariation.sizes.sort((size, size2) => size.order - size2.order), translations: (0, common_utils_1.filterDataFromObjectByKey)(productColorVariation.translations, language), images: 'images' in productColorVariation &&
                        productColorVariation.images.sort((imageA, imageB) => imageA.sortOrder - imageB.sortOrder) }))), productTranslatedProperties: (0, common_utils_1.filterDataFromObjectByKey)(baseProduct.productTranslatedProperties, language), selectedColorId: Number(selectedColor.colorId), selectedSku: (0, common_utils_1.determineSelectedSku)(availableProductColors[0]) });
        });
        this.fetchProductForBasket = (servicesApi, servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, product) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a;
            const baseProduct = yield this.getProductDetailByProductCode(servicesApi, mainWebShop, product.productCode);
            const productBrand = yield this.getProductDetailBrandByBrandId(servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, baseProduct.brandId);
            const colorVariation = baseProduct.productColorVariations.find(variation => variation.colorId === product.colorId);
            const colorVariationSize = colorVariation.sizes.find(size => size.sku === product.sku.toString());
            return {
                productId: String(product.productId),
                additionalImage: product.additionalProperties.image,
                brand: productBrand.description,
                color: (!!colorVariation.translations[language.toUpperCase()] &&
                    colorVariation.translations[language.toUpperCase()].description) ||
                    '',
                colorId: product.colorId,
                description: (0, common_utils_1.filterDataFromObjectByKey)(baseProduct.productTranslatedProperties, language).title,
                eVoucherId: product.additionalProperties.eVoucherId,
                image: colorVariation.defaultImage ||
                    ((_a = colorVariation.images.sort((imageA, imageB) => imageA.sortOrder - imageB.sortOrder)[0]) === null || _a === void 0 ? void 0 : _a.picture),
                price: product.productPriceSell,
                priceManual: product.productPriceManual,
                previousPrice: product.productPriceStandard,
                totalPrice: product.totalProductPriceSell,
                totalPreviousPrice: product.totalProductPriceStandard,
                productCode: product.productCode,
                productType: product.productType,
                quantity: product.quantity,
                seoUrl: baseProduct.seoUrl,
                sizeDescription: (0, ProductUtil_1.getSizeDescription)(colorVariationSize),
                originalSize: (0, ProductUtil_1.getSizeDescription)(colorVariationSize, true),
                sizes: colorVariation.sizes,
                swatch: colorVariation.swatch,
                sku: product.sku,
                productDiscountAmount: product.productDiscountAmount,
                productDiscountPercentage: product.productDiscountPercentage,
                productHasDiscount: product.productDiscountPercentage > 0,
                promotion: product.promotion,
                additionalProperties: product.additionalProperties,
            };
        });
        this.fetchProductAvailabilityForCurrentStore = (servicesEndpoint_1, _b, productId_1, _c) => tslib_1.__awaiter(this, [servicesEndpoint_1, _b, productId_1, _c], void 0, function* (servicesEndpoint, { market, anaLang, language, mainWebshop, shopId }, productId, { storeNumber, storeId }) {
            const requestUrl = `${servicesEndpoint}api/aem/stock/public/store/product/${market}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
                params: Object.assign(Object.assign({ anaLang,
                    language,
                    mainWebshop,
                    market,
                    productId,
                    shopId }, (storeNumber && { storeNumber })), (storeId && { storeId })),
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_d) {
                return null;
            }
        });
        this.fetchProductAvailabilityForAllStores = (skuId, mainWebshop, shopId, market, anaLang, language, servicesApi) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/stock/public/store/sku/${market}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
                params: {
                    anaLang,
                    language,
                    mainWebshop,
                    market,
                    skuId,
                    shopId,
                },
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_e) {
                return [];
            }
        });
        this.fetchProductForConfirmation = (servicesApi, servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, product) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _f, _g;
            const baseProduct = yield this.getProductDetailByProductCode(servicesApi, mainWebShop, product.productCode);
            const productBrand = yield this.getProductDetailBrandByBrandId(servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, baseProduct.brandId);
            const colorVariation = (_f = baseProduct === null || baseProduct === void 0 ? void 0 : baseProduct.productColorVariations) === null || _f === void 0 ? void 0 : _f.find(variation => variation.colorId === Number(product.colorId));
            if (!colorVariation)
                return;
            const colorVariationTranslations = colorVariation.translations;
            const colorVariationSize = colorVariation.sizes.find(size => size.sku === product.sku.toString());
            return {
                productId: String(product.productId),
                brand: productBrand.description,
                color: ((_g = colorVariationTranslations === null || colorVariationTranslations === void 0 ? void 0 : colorVariationTranslations[language.toUpperCase()]) === null || _g === void 0 ? void 0 : _g.description) || '',
                colorId: product.colorId,
                description: (0, common_utils_1.filterDataFromObjectByKey)(baseProduct.productTranslatedProperties, language).title,
                image: colorVariation.defaultImage ||
                    colorVariation.images.sort((imageA, imageB) => imageA.sortOrder - imageB.sortOrder)[0].picture,
                price: product.productPriceSell,
                previousPrice: product.productPriceStandard,
                totalPrice: product.totalProductPriceSell,
                totalPreviousPrice: product.productPriceStandard * product.quantity,
                productHasDiscount: product.productPriceStandard > product.productPriceSell,
                productCode: product.productCode,
                productType: product.productType,
                quantity: product.quantity,
                seoUrl: baseProduct.seoUrl,
                sizeDescription: (0, ProductUtil_1.getSizeDescription)(colorVariationSize),
                originalSize: (0, ProductUtil_1.getSizeDescription)(colorVariationSize, true),
                size: colorVariationSize,
                sizes: colorVariation.sizes,
                swatch: colorVariation.swatch,
                sku: product.sku,
                prices: product.pdpPrices,
            };
        });
        this.getProductDetailPriceByProductId = (servicesApi, mainWebShop, fictiveWebShop, productId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/price/${productId}/${mainWebShop}/${fictiveWebShop}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_h) {
                return null;
            }
        });
        this.getProductDetailPriceBySku = (servicesApi, market, mainWebShopId, fictiveWebShopId, productId, skuId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/price/sku/${market}/${mainWebShopId}/${fictiveWebShopId}/${productId}/${skuId}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_j) {
                return null;
            }
        });
        this.getProductDetailAvailabilityByProductId = (servicesApi, mainWebShop, productId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/availability/pdp/${mainWebShop}/${productId}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getProductAvailabilitiesByProductId = (servicesApi, mainWebShop, productIds) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/availability/bulk/${mainWebShop}/${productIds}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getProductBasketAvailabilitiesByProductId = (servicesApi, mainWebShop, productIds) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/basket/availability/bulk/${mainWebShop}/${productIds}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getTechnicalCategories = (servicesApi_1, _k, mainWebshopId_1, productId_2, productColorId_1, withProductLevelFeatures_1) => tslib_1.__awaiter(this, [servicesApi_1, _k, mainWebshopId_1, productId_2, productColorId_1, withProductLevelFeatures_1], void 0, function* (servicesApi, { market, language }, mainWebshopId, productId, productColorId, withProductLevelFeatures) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/aem/product/features/${market}/${mainWebshopId}/${productId}/${productColorId}`,
                params: {
                    withProductLevelFeatures,
                    languageCode: language,
                },
            };
            const request = yield axios_1.default.request(axiosConfig);
            return request.data;
        });
        this.getProductDetailByProductCode = (servicesApi, mainWebShop, productCode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApi}api/aem/product/${mainWebShop}/${productCode}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getProductDetailBrandByBrandId = (servicesApiPrefixAemServices, mainWebShop, fictiveWebShop, language, brandId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestUrl = `${servicesApiPrefixAemServices}services/public/brand/${mainWebShop}/${fictiveWebShop}/${brandId}/${language}`;
            const axiosConfig = {
                url: requestUrl,
                method: 'GET',
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_l) {
                return {
                    description: null,
                    relativePath: null,
                    logo: null,
                };
            }
        });
    }
    getAllProductDetailPriceByProductId(productsIds, productServiceApi, defaultRequestParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const productPromises = productsIds.map((productId) => this.getProductDetailPriceByProductId(productServiceApi, defaultRequestParams.mainWebshop, defaultRequestParams.shopId, productId));
            return yield axios_1.default.all(productPromises);
        });
    }
}
exports.default = new ProductDetailAPI();
