"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsNumeral = void 0;
const numeral = require("numeral");
const de_1 = require("./numeralLocales/de");
const en_1 = require("./numeralLocales/en");
const fr_1 = require("./numeralLocales/fr");
const nl_1 = require("./numeralLocales/nl");
class AsNumeral {
    constructor() {
        this.FALLBACK_LANGUAGE = 'nl_NL';
        this.changeLocal = (newLocale) => {
            numeral.locale(newLocale);
        };
        this.formatAsCurrency = (value) => {
            return numeral(value).format('0,0.00');
        };
        numeral.register('locale', 'nl_NL', nl_1.numeralLocaleConfigNlNl);
        numeral.register('locale', 'fr_FR', fr_1.numeralLocaleConfigFr);
        numeral.register('locale', 'en_GB', en_1.numeralLocaleConfigEnGb);
        numeral.register('locale', 'de_DE', de_1.numeralLocaleConfigDE);
        numeral.register('locale', 'en_US', en_1.numeralLocaleConfigEnUs);
        numeral.locale(this.FALLBACK_LANGUAGE);
    }
}
exports.AsNumeral = AsNumeral;
AsNumeral.getInstance = () => {
    if (!AsNumeral.instance) {
        AsNumeral.instance = new AsNumeral();
        // ... any one time initialization goes here ...
    }
    return AsNumeral.instance;
};
const asNumeralInstance = AsNumeral.getInstance();
exports.default = asNumeralInstance;
