"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const axios_1 = require("axios");
const EventEnum_1 = require("../enums/EventEnum");
const EventUtil_1 = require("../util/EventUtil");
// Handling Errors
// https://axios-http.com/docs/handling_errors
const responseSuccessInterceptor = (response) => response;
const responseErrorInterceptor = (error) => {
    var _a;
    switch ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) {
        case common_types_1.HTTPStatusCode.Forbidden:
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_SECURITY_LEVEL_TOO_LOW);
        default:
            return Promise.reject(error);
    }
};
axios_1.default.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
