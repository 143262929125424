"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CookieConsent_module_1 = require("./modules/CookieConsent.module");
const Customer_module_1 = require("./modules/Customer.module");
const Session_module_1 = require("./modules/Session.module");
const loadAccountProfileDetailsModule = () => ({
    hasRouter: true,
    modules: [(0, Session_module_1.default)(), (0, Customer_module_1.default)(), (0, CookieConsent_module_1.default)()],
});
exports.default = loadAccountProfileDetailsModule;
