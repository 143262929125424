"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const Map_1 = require("../../product-detail/store-finder-popup/map/Map");
const OsmApi_1 = require("../../../api/open-street-map/OsmApi");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const LocationUtil_1 = require("../../../util/LocationUtil");
const MarkerPopupContent_1 = require("./MarkerPopupContent");
const StoreLocatorFilter_1 = require("./StoreLocatorFilter");
const StoreNearbyStores_1 = require("../StoreNearbyStores");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const FILTER_ERROR = {
    NO_SEARCH_RESULT: 'store.error.no.store.found',
    NO_FILTER_RESULT: 'store.error.no.store.for.filter.found',
};
const LOCATION_PARAM_KEY = 'location';
const NEARBY_STORES_AMOUNT = 6;
const MARKER_ICON_SIZE = [60, 45];
const mapContainerStyles = (isMobile, isMobileLarge) => ({
    width: '100%',
    height: isMobile ? '250px' : isMobileLarge ? '400px' : '500px',
});
const getNearbyStoresForLocation = (location, storesData, storesAmount) => {
    const storesWithDistance = storesData.map(store => (Object.assign(Object.assign({}, store), { distance: (0, LocationUtil_1.calculateDistanceOSM)(location, {
            latitude: Number(store.geographic.latitude),
            longitude: Number(store.geographic.longitude),
        }) })));
    const orderedByDistanceStores = storesWithDistance.sort((a, b) => a.distance - b.distance);
    return orderedByDistanceStores.slice(0, storesAmount);
};
const getLocationQuerystring = () => typeof window !== 'undefined' ? (0, URLParamUtil_1.getQuerystringByName)(window.location.search, LOCATION_PARAM_KEY) : '';
const StoreLocator = ({ showAllStores, openAfterTime, storeTimeNotation, storeInformation, }) => {
    const aemConfig = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfig);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfig);
    const mapsConfig = (0, ConfigurationUtil_1.getGoogleMapsConfig)(aemConfig);
    const countryCodes = (0, ConfigurationUtil_1.getCountryCodes)(aemConfig);
    const locationQueryString = (0, react_1.useRef)(getLocationQuerystring());
    const { current: stores } = (0, react_1.useRef)((0, common_utils_1.updateStoresOpenState)(storeInformation, openAfterTime));
    const [filteredStores, setFilteredStores] = (0, react_1.useState)(stores);
    const [nearbyStores, setNearbyStores] = (0, react_1.useState)([]);
    const [searchLocation, setSearchLocation] = (0, react_1.useState)(null);
    const [searchTerm, setSearchTerm] = (0, react_1.useState)('');
    const [filterError, setFilterError] = (0, react_1.useState)(null);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const isMobile = (0, cotopaxi_1.useMediaQuery)(`(max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_MOBILE_LARGE}px)`);
    const isMobileLarge = (0, cotopaxi_1.useMediaQuery)(`(min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL}px) and (max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_TABLET_SMALL}px)`);
    const resetFiltersState = () => {
        setSearchLocation(null);
        setSearchTerm('');
        setNearbyStores([]);
    };
    const getNearbyStoresInformation = (location, storesData) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (location) {
            if (location === searchTerm)
                return;
            try {
                const response = yield OsmApi_1.default.getLocationFromOSM(location, countryCodes, 1);
                if (response === null || response === void 0 ? void 0 : response.length) {
                    const _searchLocation = { latitude: Number(response[0].lat), longitude: Number(response[0].lon) };
                    const _nearByStores = getNearbyStoresForLocation(_searchLocation, storesData, NEARBY_STORES_AMOUNT);
                    (0, URLParamUtil_1.setQuerystringParameter)(LOCATION_PARAM_KEY, location);
                    setSearchLocation(_searchLocation);
                    setNearbyStores(_nearByStores);
                }
                else {
                    (0, URLParamUtil_1.removeQuerystringParameter)(LOCATION_PARAM_KEY);
                    setSearchLocation(null);
                    setNearbyStores([]);
                }
                setFilteredStores(storesData);
                setSearchTerm(location);
            }
            catch (_a) { }
        }
        else {
            (0, URLParamUtil_1.removeQuerystringParameter)(LOCATION_PARAM_KEY);
            setFilteredStores(storesData);
            resetFiltersState();
        }
    });
    const handleFiltersChange = (filters) => {
        (0, URLParamUtil_1.removeQuerystringParameter)(LOCATION_PARAM_KEY);
        resetFiltersState();
        const enabledFiltersEntries = Object.entries(filters).filter(entry => entry[1]);
        if (enabledFiltersEntries.length) {
            const _filteredStores = stores.filter(store => {
                let shouldBeDisplayed = true;
                enabledFiltersEntries.forEach(([key, value]) => {
                    if (store[key] !== value) {
                        shouldBeDisplayed = false;
                    }
                });
                return shouldBeDisplayed;
            });
            setFilteredStores(_filteredStores);
        }
        else {
            setFilteredStores(stores);
        }
    };
    const handleSearchSubmit = (location) => getNearbyStoresInformation(location, stores);
    const getMarker = (store) => ({
        id: String(store.code),
        latitude: Number(store.geographic.latitude),
        longitude: Number(store.geographic.longitude),
        storeMainWebShop: defaultRequestParams.mainWebshop,
        popupContent: ((0, jsx_runtime_1.jsx)(MarkerPopupContent_1.MarkerPopupContent, { description: store.description, phoneNumber: store.phoneNumber, address: store.address, storeUrl: `${root}${store.relativeStorePagePath}.html` })),
    });
    const [markers, focusOnMarkers] = (() => {
        const markersData = filteredStores.map(store => getMarker(store));
        const focusOnMarkersData = (nearbyStores.length ? nearbyStores : stores).map(store => getMarker(store));
        if ((searchLocation || !showAllStores) && focusOnMarkersData.length > 0) {
            focusOnMarkersData[0].openPopup = true; // open the nearest store popup when search or only one store configured
        }
        if (searchLocation) {
            markersData.push({
                id: Map_1.SEARCH_LOCATION_ID,
                latitude: Number(searchLocation.latitude),
                longitude: Number(searchLocation.longitude),
            });
        }
        return [markersData, focusOnMarkersData];
    })();
    (0, react_1.useEffect)(() => {
        if (showAllStores && locationQueryString.current) {
            getNearbyStoresInformation(locationQueryString.current, stores);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (!stores.length)
            return;
        if (searchTerm) {
            setFilterError(nearbyStores.length ? null : FILTER_ERROR.NO_SEARCH_RESULT);
        }
        else {
            const hasVisibleMarkers = Boolean(filteredStores.length);
            setFilterError(hasVisibleMarkers ? null : FILTER_ERROR.NO_FILTER_RESULT);
        }
    }, [stores, filteredStores, nearbyStores, searchTerm]);
    if (!isClient)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showAllStores && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(StoreLocatorFilter_1.StoreLocatorFilter, { defaultSearch: locationQueryString.current, errorMessage: filterError, openAfterTime: openAfterTime, storeTimeNotation: storeTimeNotation, onFiltersChange: handleFiltersChange, onSearchSubmit: handleSearchSubmit }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { bleed: { marginInline: 2 } }, tablet: { bleed: { marginInline: 0 } }, children: (0, jsx_runtime_1.jsx)(Map_1.Map, { active: true, isOpen: false, keyboard: true, markers: markers, focusOnMarkers: focusOnMarkers, showFasciaIcons: true, noMarkersAnimation: true, containerStyles: mapContainerStyles(isMobile, isMobileLarge), markerIconConfigOverrides: {
                        fasciaIcon: {
                            iconSize: MARKER_ICON_SIZE,
                            iconAnchor: null,
                            iconUrl: '/content/dam/{mainWebShop}/google-maps-icons/default.png',
                        },
                    }, defaultBounds: [
                        [
                            Number(mapsConfig.defaultBounds.northEastCorner.lat),
                            Number(mapsConfig.defaultBounds.northEastCorner.lng),
                        ],
                        [
                            Number(mapsConfig.defaultBounds.southWestCorner.lat),
                            Number(mapsConfig.defaultBounds.southWestCorner.lng),
                        ],
                    ] }) }), Boolean(showAllStores && nearbyStores.length) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(StoreNearbyStores_1.default, { openedStores: nearbyStores }) }))] }));
};
exports.default = StoreLocator;
