"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapsibleListLD = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const common_types_1 = require("@as-react/common-types");
const generateFAQStructuredData = (props) => {
    const data = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
    };
    props[':itemsOrder'].map((collapsibleListItem, index) => {
        const listItem = props[':items'][collapsibleListItem];
        if (!Object.values(common_types_1.AemComponentType).includes(listItem[':type'])) {
            return null;
        }
        data.mainEntity[index] = {
            '@type': 'Question',
            name: listItem.title,
        };
        return listItem[':itemsOrder'].map((collapsibleListItemComponent) => {
            const component = listItem[':items'][collapsibleListItemComponent];
            if (component[':type'] !== common_types_1.AemComponentType.TEXT_BLOCK_V2)
                return;
            if (!data.mainEntity[index].acceptedAnswer) {
                data.mainEntity[index].acceptedAnswer = {
                    '@type': 'Answer',
                    text: component.text,
                };
            }
            else {
                data.mainEntity[index].acceptedAnswer.text += component.text;
            }
        });
    });
    return data;
};
const CollapsibleListLD = props => ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { scriptFragment: JSON.stringify(generateFAQStructuredData(props), null, 2), scriptType: HtmlInclude_1.ScriptTypes.LD_JSON }));
exports.CollapsibleListLD = CollapsibleListLD;
