"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkerPopupContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MarkerPopupContent = ({ description, phoneNumber, address, storeUrl, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.XSMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, emphasized: true, children: description }), address.street, " ", address.number, (0, jsx_runtime_1.jsx)("br", {}), address.zipCode, " ", address.city, (0, jsx_runtime_1.jsx)("br", {}), phoneNumber, (0, jsx_runtime_1.jsx)("br", {})] }), Boolean(storeUrl) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { size: cotopaxi_1.LinkSize.SMALL, href: storeUrl, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.XSMALL, children: t('store.details.lnk') }) }))] }));
};
exports.MarkerPopupContent = MarkerPopupContent;
