"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connected_react_router_1 = require("connected-react-router");
const loadRouterModule = (history) => ({
    id: 'Router',
    reducerMap: {
        router: (0, connected_react_router_1.connectRouter)(history),
    },
});
exports.default = loadRouterModule;
