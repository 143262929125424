"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const ScriptLoaderUtil_1 = require("../../util/ScriptLoaderUtil");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const BuyVoucher_1 = require("../../components/buy/payment/BuyVoucher");
const FastCheckoutForm_1 = require("../../components/buy/fast-checkout/FastCheckoutForm");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_types_1 = require("@as-react/common-types");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const BasketAPI_1 = require("../../api/basket/BasketAPI");
const SaferpayUtil_1 = require("../../util/SaferpayUtil");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const BuyFastCheckoutContainer = ({ logo, singleDeliveryMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const { fields } = (0, ConfigurationUtil_1.getSaferpayConfig)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const orderLinesHaveDifferentDeliveryPromises = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDifferentDeliveryPromisesState)());
    const basketTotalQuantity = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const requiresDelivery = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetRequiresDelivery)());
    const deliveryLoading = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryLoading)());
    const paymentOptionError = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetPaymentOptionError)());
    const fastCheckoutOverview = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetFastCheckoutOverview)());
    const customer = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetCustomerDetails)());
    const basketValidateRegexState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidateRegexState)());
    const [isSavePointCreated, setIsSavePointCreated] = (0, react_1.useState)(false);
    const onFormError = (error) => dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage(error));
    const showSingleDeliveryMessage = basketTotalQuantity > 1 && orderLinesHaveDifferentDeliveryPromises && singleDeliveryMessage && requiresDelivery;
    const onVoucherAddSuccess = (code) => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.voucherAdded(code));
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
        dispatch(CheckoutActions_creators_1.default.createGetPaymentInfoAction());
        dispatch(CheckoutActions_creators_1.default.createVoucherChangesAction());
        dispatch(CheckoutActions_creators_1.default.createGetFastCheckoutOverviewAction());
    };
    const createSavePoint = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield BasketAPI_1.default.postSavePoint(servicesEndpoint);
            setIsSavePointCreated(true);
        }
        catch (_a) { }
    });
    const loadSaferpayScript = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield ScriptLoaderUtil_1.default.loadScript(fields.scriptUrl, SaferpayUtil_1.SAFERPAY_SCRIPT_ID, true);
            dispatch(CheckoutActions_creators_1.default.createSetSaferpayFieldsReadyAction(true));
        }
        catch (_b) { }
    });
    const renderDeliveryPromise = () => {
        var _a;
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, children: (_a = fastCheckoutOverview.deliveryDetails) === null || _a === void 0 ? void 0 : _a.deliveryMessage }), fastCheckoutOverview.hasEvoucher && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, children: t('checkout:checkout.mailbox') }) }))] }));
    };
    const onSubmit = (values) => {
        dispatch(CheckoutActions_creators_1.default.createSubmitFastCheckoutAction({
            values,
            paymentDetails: Object.assign(Object.assign({}, fastCheckoutOverview.paymentDetails), { provider: fastCheckoutOverview.paymentOption.provider }),
        }));
    };
    (0, react_1.useEffect)(() => {
        loadSaferpayScript();
        dispatch(CheckoutActions_creators_1.default.createGetFastCheckoutOverviewAction());
        dispatch(CheckoutActions_creators_1.default.createGetDeliveryInfoAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutType(AnalyticsEnums_1.CheckoutType.LOGGED_IN));
        dispatch(AnalyticsActions_creators_1.analyticsActions.fastCheckoutStep());
        return () => {
            dispatch(CheckoutActions_creators_1.default.createResetPaymentStateAction());
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (!isSavePointCreated && fastCheckoutOverview && !fastCheckoutOverview.paymentOption.name) {
            createSavePoint();
        }
    }, [fastCheckoutOverview]);
    if (!fastCheckoutOverview) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showSingleDeliveryMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { iconName: cotopaxi_1.IconName.TRUCK, text: singleDeliveryMessage, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }) })), !deliveryLoading && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BuyVoucher_1.BuyVoucher, { validationRegExp: basketValidateRegexState, defaultRequestParams: defaultRequestParams, servicesEndpoint: servicesEndpoint, logo: logo, imageCdnPrefix: imageCdnPrefix, collapsedByDefault: true, onVoucherAddSuccess: onVoucherAddSuccess, onFormError: onFormError }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { withBorder: true, iconHorizontal: null, isChecked: true, asStatic: true, hasDivider: false, id: "fast-checkout-collapse", dataQA: "fast_checkout", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginTop: 2, marginRight: 2, marginBottom: 2 } }, tablet: { spacing: { marginLeft: 3, marginTop: 3, marginRight: 3 } }, children: (0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { buttonText: t('buy:buy.basket.fastcheckout.confirm.button'), onSubmit: onSubmit, checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: paymentOptionError ? t(paymentOptionError) : null, deliveryPromiseMessage: renderDeliveryPromise(), children: (0, jsx_runtime_1.jsx)(FastCheckoutForm_1.FastCheckoutForm, { customer: customer, fastCheckoutOverview: fastCheckoutOverview }) }) }) })] }))] }));
};
exports.default = BuyFastCheckoutContainer;
