"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductComparison_module_1 = require("./modules/ProductComparison.module");
const Compare_module_1 = require("./modules/Compare.module");
const DeliveryPromise_module_1 = require("./modules/DeliveryPromise.module");
const loadProductComparisonRootModule = () => ({
    hasRouter: false,
    modules: [(0, ProductComparison_module_1.default)(), (0, Compare_module_1.default)(), (0, DeliveryPromise_module_1.default)()],
});
exports.default = loadProductComparisonRootModule;
