"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EticketBody = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const EticketItem_1 = require("../eticket-item/EticketItem");
const EticketRatingButtons_1 = require("../eticket-rating-buttons/EticketRatingButtons");
const EticketBody = ({ rateProduct, eTicketInformation }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const rateItem = (rating, departmentId) => {
        rateProduct(rating, departmentId);
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: t('eticket.feedback.department') }) }), eTicketInformation.departments.map(department => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                            bleed: { marginInline: 2 },
                            spacing: { paddingTop: 2.5, paddingBottom: 2.5, paddingLeft: 3, paddingRight: 3 },
                            theme: cotopaxi_1.BoxTheme.PRIMARY,
                        }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: isMobile ? cotopaxi_1.GroupAlign.CENTER : cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.MEDIUM, column: isMobile, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_SMALL_TITLE, priority: cotopaxi_1.HeadingPriority.H3, children: department.department }), (0, jsx_runtime_1.jsx)(EticketRatingButtons_1.EticketRatingButtons, { rating: department.rating, rateItem: e => rateItem(e, department.departmentId) })] }) }), department.saleLines.map(saleLine => ((0, jsx_runtime_1.jsx)(EticketItem_1.EticketItem, { saleLine: saleLine, currency: eTicketInformation.currency }, saleLine.saleLineId)))] }, department.departmentId))), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    bleed: { marginInline: 2 },
                    spacing: { paddingTop: 2.5, paddingBottom: 2.5, paddingLeft: 3, paddingRight: 3 },
                    theme: cotopaxi_1.BoxTheme.PRIMARY,
                }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: isMobile ? cotopaxi_1.GroupAlign.CENTER : cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.MEDIUM, column: isMobile, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_SMALL_TITLE, priority: cotopaxi_1.HeadingPriority.H3, children: t('eticket.cashregister') }), (0, jsx_runtime_1.jsx)(EticketRatingButtons_1.EticketRatingButtons, { rateItem: rateItem, rating: eTicketInformation.registerRating })] }) })] }));
};
exports.EticketBody = EticketBody;
