"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findParents = exports.showMobileHeader = exports.findOrCreateElementInHead = exports.findOrCreateElementInBody = exports.TAG_NAMES = void 0;
const common_utils_1 = require("@as-react/common-utils");
const DOMElementsConstants_1 = require("../constants/DOMElementsConstants");
exports.TAG_NAMES = {
    BASE: 'base',
    BODY: 'body',
    HEAD: 'head',
    HTML: 'html',
    LINK: 'link',
    META: 'meta',
    NOSCRIPT: 'noscript',
    SCRIPT: 'script',
    STYLE: 'style',
    TITLE: 'title',
};
const findOrCreateElementInDomElement = (parentElement, elementSelector, elementTag, attributes) => {
    let domElement = parentElement.querySelector(elementSelector);
    if ((0, common_utils_1.isNullOrUndefined)(domElement)) {
        domElement = document.createElement(elementTag);
        parentElement.appendChild(domElement);
    }
    Object.keys(attributes).forEach(_key => {
        domElement.setAttribute(_key, attributes[_key]);
    });
    return domElement;
};
const findOrCreateElementInBody = (elementSelector, elementTag, attributes) => {
    const bodyElement = document.body || document.querySelector(exports.TAG_NAMES.BODY);
    return findOrCreateElementInDomElement(bodyElement, elementSelector, elementTag, attributes);
};
exports.findOrCreateElementInBody = findOrCreateElementInBody;
const findOrCreateElementInHead = (elementSelector, elementTag, attributes) => {
    const headElement = document.head || document.querySelector(exports.TAG_NAMES.HEAD);
    return findOrCreateElementInDomElement(headElement, elementSelector, elementTag, attributes);
};
exports.findOrCreateElementInHead = findOrCreateElementInHead;
const showMobileHeader = () => {
    if (document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL).classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE)) {
        document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL).classList.remove(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE);
    }
};
exports.showMobileHeader = showMobileHeader;
const findParents = (el, selector) => {
    const parentNodes = [];
    if (!selector) {
        return parentNodes;
    }
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) {
            parentNodes.unshift(el);
        }
    }
    return parentNodes;
};
exports.findParents = findParents;
