"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
class OrdersAPI {
    constructor() {
        this.getSales = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                url: `${servicesEndpoint}api/webreturns/returns/sales`,
                method: 'GET',
                params: defaultRequestParameters,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getSale = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returns/sale`,
                params: defaultRequestParameters,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return [result.data];
        });
        this.getReturnables = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returns/sales/returnable`,
                params: {
                    mainWebshop: defaultRequestParameters.mainWebshop,
                    language: defaultRequestParameters.language,
                    shopId: defaultRequestParameters.shopId,
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getReturnReasons = (servicesEndpoint, market, language) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returnreason/${market}/${language}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.registerReturnAnnounce = (servicesEndpoint, defaultRequestParameters, data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesEndpoint}api/webreturns/returns/announce/register`,
                params: defaultRequestParameters,
                withCredentials: true,
                data,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.registerReturnAnnounceGuest = (servicesEndpoint, defaultRequestParameters, data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesEndpoint}api/webreturns/returns/announce/register/guest`,
                params: defaultRequestParameters,
                withCredentials: true,
                data,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateReturnMethod = (servicesEndpoint, defaultRequestParameters, returnAnnounceId, returnOptionId, returnCountryId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'PUT',
                url: `${servicesEndpoint}api/webreturns/returns/announce/${returnAnnounceId}/location`,
                params: Object.assign(Object.assign({}, defaultRequestParameters), { returnAnnounceId,
                    returnOptionId,
                    returnCountryId }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getReturnedSales = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returns/sales/returned`,
                params: {
                    mainWebshop: defaultRequestParameters.mainWebshop,
                    language: defaultRequestParameters.language,
                    shopId: defaultRequestParameters.shopId,
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getReturnedSalesGuest = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returns/sale/returned`,
                params: {
                    mainWebshop: defaultRequestParameters.mainWebshop,
                    language: defaultRequestParameters.language,
                    shopId: defaultRequestParameters.shopId,
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getReturnOptions = (servicesEndpoint, defaultRequestParameters, countryId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/webreturns/returnoptions`,
                params: {
                    mainWebshop: defaultRequestParameters.mainWebshop,
                    shopId: defaultRequestParameters.shopId,
                    countryId,
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getReturnCountries = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${servicesApi}api/deliverycharges/countries/returnportal/${defaultRequestParams.mainWebshop}/${defaultRequestParams.shopId}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new OrdersAPI();
