"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPartialSearchResult = exports.getListerItemsToShow = void 0;
const common_types_1 = require("@as-react/common-types");
const ScreenWidthUtil_1 = require("./ScreenWidthUtil");
const ScreenWidthConstants_1 = require("../constants/ScreenWidthConstants");
const ProductConstants_1 = require("../constants/ProductConstants");
/*
 * returns the exact nbr of items to fit in the lister window
 * such that at the end of the last row there aren't any empty product tiles
 */
const getListerItemsToShow = (items, pageSize, isClient) => {
    const numberOfItemsInRow = isClient ? (0, ScreenWidthUtil_1.getNbrOfItemsInListerRow)() : ScreenWidthConstants_1.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP;
    if (items.length > (pageSize || ProductConstants_1.MAX_NUMBER_OF_ARTICLES_IN_LISTER_PAGE_DEFAULT)) {
        return items.slice(0, items.length - (items.length % numberOfItemsInRow));
    }
    else {
        return items;
    }
};
exports.getListerItemsToShow = getListerItemsToShow;
const isPartialSearchResult = (executionStatus) => executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_BRAND ||
    executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_QUERIES ||
    executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_SPELL_UNCHANGED;
exports.isPartialSearchResult = isPartialSearchResult;
