"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = require("react");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const jsvat_next_1 = require("jsvat-next");
const common_components_1 = require("@as-react/common-components");
const GeneralAPI_1 = require("../../../../api/general/GeneralAPI");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const GeneralUtil_1 = require("../../../../util/GeneralUtil");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const BuyInvoiceChangeHandler = ({ country, countryId, setCompanyNameLocked, setAddresses, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { values: { invoiceVatNumber, invoiceCompanyName, invoicePostCode, invoiceHouseNumber }, setFieldValue, setFieldError, } = (0, formik_1.useFormikContext)();
    const getCompanyDetailsForVatNumber = (vatCountryCode, vatDigits) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!invoiceCompanyName) {
            const data = yield GeneralAPI_1.default.getCompanyInformationByVAT(servicesEndpoint, defaultRequestParameters, vatCountryCode, vatDigits);
            if (data === null || data === void 0 ? void 0 : data.isVat) {
                setFieldValue('invoiceCompanyName', data.viesResponse.name);
            }
        }
        setCompanyNameLocked(false);
    });
    (0, react_1.useEffect)(() => {
        let isSubscribed = true;
        const validateVat = () => {
            var _a, _b;
            isSubscribed && setCompanyNameLocked(true);
            const currentCountry = jsvat_next_1.countries.find(item => item.codes.includes(country));
            const vatCheckResult = invoiceVatNumber && currentCountry && (0, jsvat_next_1.checkVAT)(invoiceVatNumber, [currentCountry]);
            if (vatCheckResult && vatCheckResult.isValid) {
                isSubscribed && setFieldValue('invoiceVatNumber', vatCheckResult.value);
                const vatCountryCode = (_a = vatCheckResult.value) === null || _a === void 0 ? void 0 : _a.slice(0, 2);
                const vatDigits = (_b = vatCheckResult.value) === null || _b === void 0 ? void 0 : _b.slice(2);
                vatCountryCode && vatDigits && getCompanyDetailsForVatNumber(vatCountryCode, vatDigits);
            }
            else {
                setCompanyNameLocked(false);
                setTimeout(() => {
                    isSubscribed && setFieldError('invoiceVatNumber', t('buy.error.no.results'));
                }, GeneralConstants_1.DELAY.DELAY_0);
            }
        };
        if (invoiceVatNumber && country) {
            validateVat();
        }
        return () => {
            isSubscribed = false;
        };
    }, [country, invoiceVatNumber]);
    (0, react_1.useEffect)(() => {
        let isSubscribed = true;
        let isCurrent = true;
        const validateInvoiceAddress = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (country === 'NL' && (0, BuyFormUtil_1.isValidAddress)(invoicePostCode, invoiceHouseNumber, country)) {
                try {
                    const addresses = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParameters, countryId, invoicePostCode, invoiceHouseNumber);
                    if ((addresses === null || addresses === void 0 ? void 0 : addresses.length) && isSubscribed) {
                        const firstAddress = addresses[0];
                        setFieldValue('invoiceCity', firstAddress.city);
                        setFieldValue('invoiceAddress', firstAddress.address);
                        if (firstAddress.houseNumberAddition) {
                            setFieldValue('invoiceHouseNumberAddition', firstAddress.houseNumberAddition);
                        }
                    }
                }
                catch (_a) {
                    isSubscribed && setFieldError('invoicePostCode', t('buy.error.no.results'));
                }
            }
        });
        !!isCurrent && (0, GeneralUtil_1.mimicDelay)().then(() => validateInvoiceAddress());
        return () => {
            isSubscribed = false;
            isCurrent = false;
        };
    }, [invoicePostCode, invoiceHouseNumber, country]);
    (0, react_1.useEffect)(() => {
        let isSubscribed = true;
        const validateInvoiceAddress = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if ((country === 'BE' || country === 'DE' || country === 'FR') && (0, BuyFormUtil_1.isValidPostCode)(invoicePostCode, country)) {
                try {
                    const addresses = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParameters, countryId, invoicePostCode);
                    if ((addresses === null || addresses === void 0 ? void 0 : addresses.length) && isSubscribed) {
                        setAddresses(addresses);
                        if (addresses.length === 1) {
                            setFieldValue('invoiceCity', addresses[0].city);
                        }
                    }
                }
                catch (_a) {
                    isSubscribed && setFieldError('invoicePostCode', t('buy.error.no.results'));
                }
            }
        });
        if (invoicePostCode) {
            validateInvoiceAddress();
        }
        return () => {
            isSubscribed = false;
        };
    }, [invoicePostCode, country]);
    return null;
};
exports.default = BuyInvoiceChangeHandler;
