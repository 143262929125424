"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDeliveryMax = exports.getDeliveryMin = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const getDeliveryMin = (delivery) => {
    const filterDelivery = delivery.filter(store => !(0, common_utils_1.isNullOrUndefined)(store.deliveryFrom));
    return filterDelivery.length
        ? filterDelivery.reduce((min, quantity) => (quantity.deliveryFrom < min ? quantity.deliveryFrom : min), filterDelivery[0].deliveryFrom)
        : null;
};
exports.getDeliveryMin = getDeliveryMin;
const getDeliveryMax = (delivery) => {
    const filterDelivery = delivery.filter(store => !(0, common_utils_1.isNullOrUndefined)(store.deliveryTo));
    return filterDelivery.length
        ? filterDelivery.reduce((max, quantity) => (quantity.deliveryTo > max ? quantity.deliveryTo : max), filterDelivery[0].deliveryTo)
        : null;
};
exports.getDeliveryMax = getDeliveryMax;
const MapLegend = ({ stores }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    const showInStockMessage = stores.some(store => store.inStock);
    const deliveryLongTerm = stores.filter(store => !store.inStock);
    const deliveryMin = (0, exports.getDeliveryMin)(deliveryLongTerm);
    const deliveryMax = (0, exports.getDeliveryMax)(deliveryLongTerm);
    const getDeliveryMsg = () => deliveryMin === deliveryMax
        ? `${deliveryMax} ${deliveryMax === 1 ? t('store.check.day') : t('store.check.days')}`
        : `${deliveryMin}-${deliveryMax} ${t('store.check.days')}`;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: [showInStockMessage && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CIRCLE, color: cotopaxi_1.IconColor.POSITIVE, size: cotopaxi_1.IconSize.MEDIUM }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, color: cotopaxi_1.TextColor.POSITIVE, inline: true, children: t('store.clickandcollect.instock') }) })] })), !!deliveryLongTerm.length && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: showInStockMessage ? 5 : 0 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CIRCLE, color: cotopaxi_1.IconColor.NOTICE, size: cotopaxi_1.IconSize.MEDIUM }), deliveryMin !== null && deliveryMax !== null && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, color: cotopaxi_1.TextColor.NOTICE, inline: true, children: getDeliveryMsg() }) }))] }) }))] }));
};
exports.default = MapLegend;
