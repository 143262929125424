"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_utils_1 = require("@as-react/common-utils");
const Label = props => {
    const { translation, htmlFor, required, className } = props;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.dpe]);
    return ((0, jsx_runtime_1.jsx)("label", { className: `${className || 'a-label'} ${required ? ' a-label--required' : ''}`, htmlFor: htmlFor, children: (0, common_utils_1.capitalize)(t(translation)) }));
};
exports.Label = Label;
