"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ManualUtil_1 = require("../../../util/ManualUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DownloadManualSection = ({ productManual, manualBaseUrl, lang, filteredProductManualKeys, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { emphasize: true, dataQA: "product_manual", href: manualBaseUrl + (0, ManualUtil_1.getProductManualDocument)(filteredProductManualKeys, lang, productManual, ManualUtil_1.FALLBACK_LANG), iconStart: cotopaxi_1.IconName.DOWNLOAD, target: cotopaxi_1.LinkTarget.BLANK, rel: cotopaxi_1.LinkRelEnum.NOOPENER, children: t('pdp.manual.download') }));
};
exports.default = DownloadManualSection;
