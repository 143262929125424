"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const StoreInfoTag_1 = require("./StoreInfoTag");
const StoreDepartments = ({ departments }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    if (!departments || departments.length === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
            spacing: {
                paddingBottom: 1.5,
                paddingTop: 1.5,
            },
        }, tablet: {
            spacing: {
                paddingTop: 2.5,
                paddingBottom: 2.5,
            },
        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingBottom: 1.5,
                    },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.XL, children: t('store.storedepartments.txt') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { columns: { mobile: 1, tablet: 2, desktop: 3 }, rows: { tablet: 7 }, gap: "s", flow: { mobile: 'row', tablet: 'column' }, children: departments.map(department => ((0, jsx_runtime_1.jsx)(StoreInfoTag_1.StoreInfoTag, { icon: cotopaxi_1.IconName.CHEVRON_RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: t(`store.department.${department.code}`) }) }, department.code))) })] }));
};
exports.default = StoreDepartments;
