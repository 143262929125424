"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutStepper = exports.CheckoutStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutContext_1 = require("../../context/CheckoutContext");
var CheckoutStep;
(function (CheckoutStep) {
    CheckoutStep[CheckoutStep["DETAILS"] = 1] = "DETAILS";
    CheckoutStep[CheckoutStep["DELIVERY"] = 2] = "DELIVERY";
    CheckoutStep[CheckoutStep["PAYMENT"] = 3] = "PAYMENT";
    CheckoutStep[CheckoutStep["CONFIRMATION"] = 4] = "CONFIRMATION";
})(CheckoutStep || (exports.CheckoutStep = CheckoutStep = {}));
const CheckoutStepper = ({ step }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [{ login }] = (0, CheckoutContext_1.useCheckoutState)();
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Stepper, { children: [(step === CheckoutStep.DETAILS || login.registrationPassed) && ((0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.details'), current: step === CheckoutStep.DETAILS, passed: step > CheckoutStep.DETAILS })), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.delivery'), current: step === CheckoutStep.DELIVERY, passed: step > CheckoutStep.DELIVERY }), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.payment'), current: step === CheckoutStep.PAYMENT, passed: step > CheckoutStep.PAYMENT }), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.confirmation'), current: step === CheckoutStep.CONFIRMATION })] }));
};
exports.CheckoutStepper = CheckoutStepper;
