"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = require("react");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const CheckoutSelector_1 = require("../../../../redux/checkout/selectors/CheckoutSelector");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_redux_1 = require("react-redux");
const GeneralUtil_1 = require("../../../../util/GeneralUtil");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const GeneralAPI_1 = require("../../../../api/general/GeneralAPI");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const useABTest_1 = require("../../../../hooks/useABTest");
const HomeDeliveryChangeHandler = ({ setAddresses, setDisableSubmit, country, addressFieldsEnabled, showErrors, deliveryAddressId, disableSubmit, editAddressFieldsEnabled, shouldValidateAddress, setInvoiceActive, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { bpost_address_validation } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const countryId = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryCountryId)());
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isFirstRender = (0, react_1.useRef)(true);
    const addressValidationCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.BPOST_ADDRESS_VALIDATION);
    const { values: { postCode, houseNumber, needInvoice, city }, errors, status, touched, isSubmitting, dirty, setStatus, setFieldError, setFieldValue, setErrors, setFieldTouched, setTouched, validateForm, resetForm, } = (0, formik_1.useFormikContext)();
    const isPostCodeTouched = (0, formik_1.getIn)(touched, 'postCode');
    const isHouseNumberTouched = (0, formik_1.getIn)(touched, 'houseNumber');
    const { getAddresses } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: addresses, error: addressError } = (0, react_query_1.useQuery)(getAddresses({ countryId, postalCode: postCode, houseNumber: houseNumber }, {
        enabled: country === 'NL' && (0, BuyFormUtil_1.isValidAddress)(postCode, houseNumber, country) && dirty,
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (addressError) {
            setFieldError('postCode', t('buy.error.no.results'));
        }
    }, [addressError]);
    (0, react_1.useEffect)(() => {
        if (!addresses || addresses.length === 0) {
            setStatus(Object.assign(Object.assign({}, status), { addressAutofilled: false }));
            return;
        }
        const firstAddress = addresses[0];
        if (firstAddress.address) {
            setFieldValue('address', firstAddress.address);
        }
        if (firstAddress.city) {
            setFieldValue('city', firstAddress.city);
        }
        if (firstAddress.houseNumberAddition) {
            setFieldValue('houseNumberAddition', firstAddress.houseNumberAddition);
        }
        setStatus(Object.assign(Object.assign({}, status), { addressAutofilled: Boolean(firstAddress.address && firstAddress.city) }));
    }, [addresses]);
    (0, react_1.useEffect)(() => {
        setErrors({});
    }, [addressFieldsEnabled, country, setErrors]);
    (0, react_1.useEffect)(() => {
        setInvoiceActive === null || setInvoiceActive === void 0 ? void 0 : setInvoiceActive(Boolean(needInvoice));
    }, [needInvoice]);
    (0, react_1.useEffect)(() => {
        let isSubscribed = true;
        const validate = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (['BE', 'DE', 'FR', 'LU'].includes(country) && (0, BuyFormUtil_1.isValidPostCode)(postCode, country)) {
                try {
                    const data = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParams, countryId, postCode);
                    if ((data === null || data === void 0 ? void 0 : data.length) > 0 && isSubscribed) {
                        setAddresses(data);
                        if (data.length === 1) {
                            setFieldValue('city', data[0].city);
                        }
                    }
                }
                catch (_a) {
                    if (isSubscribed) {
                        setFieldError('postCode', t('buy.error.no.results'));
                        if (bpost_address_validation && addressValidationCookie) {
                            setDisableSubmit(true);
                        }
                    }
                }
            }
        });
        if (postCode && touched.postCode && !isSubmitting) {
            validate();
        }
        if (!touched.postCode && city && ['BE', 'DE', 'FR', 'LU'].includes(country)) {
            setAddresses([{ city }]);
            setFieldValue('city', city);
        }
        return () => {
            isSubscribed = false;
        };
    }, [postCode, country, touched.postCode]);
    (0, react_1.useEffect)(() => {
        if (isSubmitting && errors.phoneNumber) {
            setFieldTouched('phoneNumber', true);
        }
    }, [isSubmitting]);
    (0, react_1.useEffect)(() => {
        const validateManualForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_0);
            const formErrors = yield validateForm();
            const formTouched = {};
            Object.keys(formErrors).forEach(key => (formTouched[key] = true));
            setTouched(Object.assign({}, formTouched));
        });
        if (showErrors) {
            validateManualForm();
        }
    }, [showErrors, deliveryAddressId]);
    (0, react_1.useEffect)(() => {
        if ((!isPostCodeTouched && !isHouseNumberTouched) || !shouldValidateAddress)
            return;
        if ((0, BuyFormUtil_1.isValidAddress)(postCode, houseNumber, country) && disableSubmit) {
            setDisableSubmit(false);
        }
        if (!(0, BuyFormUtil_1.isValidAddress)(postCode, houseNumber, country) && !disableSubmit) {
            setDisableSubmit(true);
        }
    }, [postCode, houseNumber, country, isPostCodeTouched, isHouseNumberTouched]);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (!editAddressFieldsEnabled) {
            resetForm();
        }
    }, [editAddressFieldsEnabled]);
    return null;
};
exports.default = HomeDeliveryChangeHandler;
