"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationCart = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyConfirmationProductsList_1 = require("./BuyConfirmationProductsList");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const BuyConfirmationCart = ({ order }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const homepageUrl = (0, NavigationUtil_1.getHomepageUrl)(root);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, desktop: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t('buy.confirmation.purchase.information.title') }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 6 } }, desktop: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(BuyConfirmationProductsList_1.BuyConfirmationProductsList, { products: order.mappedProducts, pricing: {
                            priceDelivery: order.priceDelivery,
                            priceTotalBasket: order.priceTotalBasket,
                            totalBasketDiscountAmount: order.totalBasketDiscountAmount,
                            totalPriceSell: order.totalPriceSell,
                            totalPriceSellBeforeDiscount: order.totalPriceSellBeforeDiscount,
                        }, vouchers: order.vouchers, currency: currency }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { href: homepageUrl, variant: cotopaxi_1.ButtonVariant.OUTLINED, iconLeft: {
                            name: cotopaxi_1.IconName.CHEVRON_LEFT,
                            size: cotopaxi_1.IconSize.MAJOR,
                        }, dataQA: "continue_shopping", text: t('buy.confirmation.continue.shopping'), fill: isMobile })] })] }));
};
exports.BuyConfirmationCart = BuyConfirmationCart;
