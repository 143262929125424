"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtons = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SizeRecommendationV2_1 = require("../size-recommendation-v2/SizeRecommendationV2");
const SizeSelectButtonsFull_1 = require("./SizeSelectButtonsFull");
const useSelectedSizes_1 = require("../../../hooks/useSelectedSizes");
const SizeSelectButtonsSplitted_1 = require("./SizeSelectButtonsSplitted");
const SizeSelectButtons = ({ id = 'as-size-buttons', sizes, selectedSku, selectSize, basketValidationState, sizeRecommendation, selectedColorId, isOneSizeProduct, isOneSizeProductInline, notifyMeError, sizeCombinationNotExist, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const isNotifyMeSizeError = notifyMeError === common_types_1.NotifyMeError.SELECT_SIZE;
    const { options, filteredSizes } = (0, ProductUtil_1.mapSizeSelectOptions)(sizes, true, undefined, !isShoplanding, false);
    const { isSizeAndLengthSplitted } = (0, ProductUtil_1.splitSizesBySizeAndLength)(filteredSizes);
    const { sizeOptions, setSizeOptions, selectedSizeOption, setSelectedSizeOption, lengthOptions, setLengthOptions, selectedLengthOption, setSelectedLengthOption, } = (0, useSelectedSizes_1.useSelectedSizes)(options, filteredSizes, selectedColorId, selectedSku, selectSize);
    const [firstSelectedType, setFirstSelectedType] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (!firstSelectedType && (selectedSizeOption || selectedLengthOption)) {
            setFirstSelectedType(selectedSizeOption ? 'size' : 'length');
        }
    }, [selectedSizeOption, selectedLengthOption]);
    if (isOneSizeProduct)
        return null;
    if (isOneSizeProductInline) {
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { id: id, children: [sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationV2_1.SizeRecommendationV2, { sizeRecommendation: sizeRecommendation }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size') }), ' ', (_a = options[0]) === null || _a === void 0 ? void 0 : _a.label] })] }));
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationV2_1.SizeRecommendationV2, { sizeRecommendation: sizeRecommendation }), !isSizeAndLengthSplitted ? ((0, jsx_runtime_1.jsx)(SizeSelectButtonsFull_1.SizeSelectButtonsFull, { id: id, selectedSku: selectedSku, selectSize: selectSize, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SizeSelectButtonsSplitted_1.SizeSelectButtonsSplitted, { type: "size", id: `${id}-sizes`, selectedOption: selectedSizeOption, setSelectedOption: setSelectedSizeOption, lengthOptions: lengthOptions, setSizeOptions: setSizeOptions, setLengthOptions: setLengthOptions, sizeOptions: sizeOptions, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState, selectedColorId: selectedColorId, setFirstSelectedType: setFirstSelectedType, firstSelectedType: firstSelectedType, sizeCombinationNotExist: sizeCombinationNotExist }), lengthOptions.length > 0 && ((0, jsx_runtime_1.jsx)(SizeSelectButtonsSplitted_1.SizeSelectButtonsSplitted, { type: "length", id: `${id}-lengths`, selectedOption: selectedLengthOption, setSelectedOption: setSelectedLengthOption, lengthOptions: lengthOptions, setSizeOptions: setSizeOptions, setLengthOptions: setLengthOptions, sizeOptions: sizeOptions, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState, selectedColorId: selectedColorId, setFirstSelectedType: setFirstSelectedType, firstSelectedType: firstSelectedType, sizeCombinationNotExist: sizeCombinationNotExist }))] }))] }));
};
exports.SizeSelectButtons = SizeSelectButtons;
