"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../constants/i18nConstants");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ImageUtil_1 = require("../util/ImageUtil");
var TeaserCTAIconPosition;
(function (TeaserCTAIconPosition) {
    TeaserCTAIconPosition["LEFT"] = "left";
    TeaserCTAIconPosition["RIGHT"] = "right";
})(TeaserCTAIconPosition || (TeaserCTAIconPosition = {}));
const TeaserContainer = ({ image, displayMode, content, cta = [], ctaAlternative, analytics, cdnSettings, cornerImage, paddings, mainCdnSettings, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const getCTAProps = (item) => item
        ? Object.assign({ href: item.href, text: item.label, title: item.title, variant: item.variant, target: item.target, shape: item.shape, fill: item.fullWidth, align: item.labelAlign }, (Boolean(item.iconPosition) && {
            [`${item.iconPosition === TeaserCTAIconPosition.LEFT ? 'iconLeft' : 'iconRight'}`]: {
                name: item.icon,
            },
        })) : null;
    const teaserCdnSettings = mainCdnSettings || cdnSettings;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Teaser, { image: {
            src: image.imagePath,
            srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.imageQuality, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.requestPassThrough),
            isPicture: true,
            lazy: image.lazy,
            alt: image.alt,
        }, displayMode: displayMode.mode, heightValue: displayMode.height, imageOverlay: displayMode.imageOverlay, imagePosition: displayMode.imagePosition, imageHorizontalAlign: displayMode.imageAlignment.horizontal, imageVerticalAlign: displayMode.imageAlignment.vertical, contentHorizontalAlign: displayMode.contentAlignment.horizontal, contentVerticalAlign: displayMode.contentAlignment.vertical, contentWidthDesktop: displayMode.contentWidthDesktop, contentWidthTablet: displayMode.contentWidthTablet, hideOnMobile: displayMode.hideOnMobile, hideCTAOnMobile: displayMode.hideCTAOnMobile, showCTAOnHover: displayMode.showCTAOnHover, href: content.href, target: content.target, isObfuscated: content.isObfuscated, title: content.title, logo: Boolean(content.logo) && {
            src: content.logo.imagePath,
            srcset: (0, ImageUtil_1.getPictureSrcset)(content.logo, imageCdnPrefix, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.imageQuality, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.requestPassThrough),
            isPicture: true,
            lazy: content.logo.lazy,
        }, text: content.text, readMoreLabel: t('readMore'), readLessLabel: t('readLess'), truncateTextOnMobile: content.truncateTextOnMobile, showTextOnHover: content.showTextOnHover, showOverlayOnHover: content.showOverlayOnHover, logoDirection: content.logoDirection, CTAs: cta.map(getCTAProps), alternativeCTA: getCTAProps(ctaAlternative), dataAnalyticsBannerid: analytics === null || analytics === void 0 ? void 0 : analytics.bannerId, cornerImage: Boolean(cornerImage) && {
            src: cornerImage.image.imagePath,
            srcset: (0, ImageUtil_1.getPictureSrcset)(cornerImage.image, imageCdnPrefix, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.imageQuality, teaserCdnSettings === null || teaserCdnSettings === void 0 ? void 0 : teaserCdnSettings.requestPassThrough),
            isPicture: true,
            lazy: cornerImage.image.lazy,
        }, cornerImageHorizontalAlign: cornerImage === null || cornerImage === void 0 ? void 0 : cornerImage.alignment.horizontal, cornerImageVerticalAlign: cornerImage === null || cornerImage === void 0 ? void 0 : cornerImage.alignment.vertical, innerSpacing: paddings }));
};
exports.default = TeaserContainer;
