"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountMenuContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const AccountMenuPopover_1 = require("../../components/account/account-menu/AccountMenuPopover");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
const AccountMenuContainer = ({ items, anchorEl, onClose }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    return ((0, jsx_runtime_1.jsx)(AccountMenuPopover_1.AccountMenuPopover, { items: items, anchorEl: anchorEl, onClose: onClose, logout: () => dispatch(SessionActions_creators_1.default.createLogoutAction()) }));
};
exports.AccountMenuContainer = AccountMenuContainer;
