"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostcodeHouseNumberWatcher = void 0;
const react_hook_form_1 = require("react-hook-form");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const addressFields_utils_1 = require("../addressFields.utils");
const common_types_1 = require("@as-react/common-types");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const PostcodeHouseNumberWatcher = ({ prefix, countryId, postalCodeSearchFieldName, houseNumberSearchFieldName, onAddressFound, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [postalCodeFieldName, houseNumberFieldName, addressFieldName, cityFieldName, houseNumberAdditionFieldName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const { control, setError, setValue, clearErrors, trigger } = (0, react_hook_form_1.useFormContext)();
    const [postalCode, houseNumber] = (0, react_hook_form_1.useWatch)({
        control,
        name: [postalCodeSearchFieldName || postalCodeFieldName, houseNumberSearchFieldName || houseNumberFieldName],
    });
    const { getAddresses } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: addresses, error: addressError } = (0, react_query_1.useQuery)(getAddresses({ countryId, postalCode: postalCode, houseNumber: houseNumber }, {
        enabled: (0, addressFields_utils_1.isValidAddress)(postalCode, houseNumber, common_types_1.CountryId.NL),
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (addressError) {
            setError(postalCodeFieldName, { message: t('buy.error.no.results') });
            postalCodeSearchFieldName && setError(postalCodeSearchFieldName, { message: t('buy.error.no.results') });
        }
    }, [addressError]);
    (0, react_1.useEffect)(() => {
        if (!addresses || addresses.length === 0) {
            return;
        }
        const firstAddress = addresses[0];
        if (firstAddress.address) {
            setValue(addressFieldName, firstAddress.address, { shouldValidate: true });
            clearErrors(addressFieldName);
        }
        if (firstAddress.city) {
            setValue(cityFieldName, firstAddress.city);
            clearErrors(cityFieldName);
        }
        if (firstAddress.houseNumberAddition) {
            setValue(houseNumberAdditionFieldName, firstAddress.houseNumberAddition);
            clearErrors(houseNumberAdditionFieldName);
        }
        // set search post code and house number to the original fields
        postalCodeSearchFieldName && setValue(postalCodeFieldName, postalCode);
        houseNumberSearchFieldName && setValue(houseNumberFieldName, houseNumber);
        trigger([postalCodeFieldName, postalCodeSearchFieldName].filter(Boolean));
        onAddressFound === null || onAddressFound === void 0 ? void 0 : onAddressFound();
    }, [addresses]);
    return null;
};
exports.PostcodeHouseNumberWatcher = PostcodeHouseNumberWatcher;
