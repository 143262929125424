"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductGroupPdpComparison_1 = require("../../components/product-group-comparison/ProductGroupPdpComparison");
const ProductGroupFredhopperSelectors_1 = require("../../redux/productGroupFredhopper/selectors/ProductGroupFredhopperSelectors");
const ProductGroupFredhopper_creators_1 = require("../../redux/productGroupFredhopper/actions/ProductGroupFredhopper.creators");
const ProductGroupPdpComparisonFredhopperContainer = ({ campaign, productGroupFredhopperId, title, productsFeaturesRecord, currentProductHasFeatures }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const productGroupFredhopper = (0, react_redux_1.useSelector)((0, ProductGroupFredhopperSelectors_1.makeGetProductGroupFredhopper)());
    (0, react_1.useEffect)(() => {
        var _a;
        if (productGroupFredhopperId && ((_a = productGroupFredhopper === null || productGroupFredhopper === void 0 ? void 0 : productGroupFredhopper[productGroupFredhopperId]) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(ProductGroupFredhopper_creators_1.ProductGroupFredhopperActions.init());
        }
    }, []);
    const products = productGroupFredhopper === null || productGroupFredhopper === void 0 ? void 0 : productGroupFredhopper[productGroupFredhopperId];
    if (!(products === null || products === void 0 ? void 0 : products.length)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(ProductGroupPdpComparison_1.ProductGroupPdpComparison, { analyticsAreaName: "product-group-pdp-comparison-fredhopper", productGroupId: `product-group-pdp-comparison-fredhopper-${campaign}`, products: products, productsFeaturesRecord: productsFeaturesRecord, title: title, analyticsProductGroupName: `fh_${campaign}`, currentProductHasFeatures: currentProductHasFeatures }));
};
exports.default = ProductGroupPdpComparisonFredhopperContainer;
