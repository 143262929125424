"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const eTicketEndpoints = {
    get: 'api/eticket/get',
    feedback: 'api/eticket/feedback',
    voting: 'api/eticket/voting',
};
class EticketAPI {
    constructor() {
        this.getTicketInformation = (defaultRequestParams, servicesApi, customerUUID, saleId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${eTicketEndpoints.get}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { customerUUID,
                    saleId }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.voting = (defaultRequestParams, servicesApi, customerUUID, saleId, ratingType, rating, departmentId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${eTicketEndpoints.voting}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { customerUUID,
                    saleId,
                    rating,
                    ratingType,
                    departmentId }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.sendFeedback = (defaultRequestParams, servicesApi, customerUUID, saleId, feedback) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${eTicketEndpoints.feedback}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { customerUUID,
                    saleId }),
                data: feedback,
                headers: {
                    'Content-Type': 'text/plain',
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new EticketAPI();
