"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetLoadingState = exports.makeGetContentTotalElements = exports.makeGetContentTotalPages = exports.makeGetContentCategories = exports.makeGetContentTypes = exports.makeGetContentTiles = exports.makeGetContentFilters = exports.makeGetContentState = void 0;
const reselect_1 = require("reselect");
const getContent = (state) => state.content;
const getContentTotalPages = (state) => { var _a, _b; return (_b = (_a = state.content) === null || _a === void 0 ? void 0 : _a.tiles) === null || _b === void 0 ? void 0 : _b.totalPages; };
const getContentTotalElements = (state) => { var _a, _b; return (_b = (_a = state.content) === null || _a === void 0 ? void 0 : _a.tiles) === null || _b === void 0 ? void 0 : _b.totalElements; };
const getLoadingState = (state) => state.content.isLoading;
const makeGetContentState = () => (0, reselect_1.createSelector)(getContent, content => content);
exports.makeGetContentState = makeGetContentState;
const makeGetContentFilters = () => (0, reselect_1.createSelector)(getContent, content => content.filters);
exports.makeGetContentFilters = makeGetContentFilters;
const makeGetContentTiles = () => (0, reselect_1.createSelector)(getContent, content => content.tiles);
exports.makeGetContentTiles = makeGetContentTiles;
const makeGetContentTypes = () => (0, reselect_1.createSelector)(getContent, content => content.types);
exports.makeGetContentTypes = makeGetContentTypes;
const makeGetContentCategories = () => (0, reselect_1.createSelector)(getContent, content => content.categories);
exports.makeGetContentCategories = makeGetContentCategories;
const makeGetContentTotalPages = () => (0, reselect_1.createSelector)([getContentTotalPages], (totalPages) => totalPages);
exports.makeGetContentTotalPages = makeGetContentTotalPages;
const makeGetContentTotalElements = () => (0, reselect_1.createSelector)([getContentTotalElements], (totalElements) => totalElements);
exports.makeGetContentTotalElements = makeGetContentTotalElements;
const makeGetLoadingState = () => (0, reselect_1.createSelector)([getLoadingState], (isLoading) => isLoading);
exports.makeGetLoadingState = makeGetLoadingState;
