"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const MonetateActions_creators_1 = require("../redux/monetate/actions/MonetateActions.creators");
const MonetateContainer = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        dispatch(MonetateActions_creators_1.monetateActions.init());
    }, []);
    return null;
};
exports.default = MonetateContainer;
