"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const LimitedStockNotification = ({ skuAvailability, isOneSizeProduct, sizeCombinationNotExist, market, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    let message;
    const hasExactAvailability = () => skuAvailability.exactAvailability && skuAvailability.exactAvailability > 0;
    if (sizeCombinationNotExist) {
        if (market === 'be') {
            message = t('pdp.size.combination.be.not.exist.error');
        }
        else {
            message = t('pdp.size.combination.not.exist.error');
        }
    }
    else if (skuAvailability.isSoldOut && !isOneSizeProduct) {
        if (market === 'be') {
            message = t('product.selected.size.be.unavailable');
        }
        else {
            message = t('product.selected.size.unavailable');
        }
    }
    else if (hasExactAvailability()) {
        message = t('product.last.pieces.exact', {
            amount: skuAvailability.exactAvailability,
        });
    }
    else if (skuAvailability.lastPieces) {
        message = t('product.last.pieces');
    }
    else {
        message = '';
    }
    if (!message) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.INFORMATION_OUTLINE, color: cotopaxi_1.IconColor.NEGATIVE }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.NEGATIVE, size: cotopaxi_1.TextSize.SMALL, children: message })] }));
};
exports.default = LimitedStockNotification;
