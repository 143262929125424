"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BrowserUtil {
    static detectBrowser(browser) {
        const ua = navigator.userAgent || navigator.vendor;
        return ua.includes(browser);
    }
    static isBrowserInstagram() {
        return this.detectBrowser(this.USER_AGENT_INSTAGRAM);
    }
}
BrowserUtil.USER_AGENT_INSTAGRAM = 'Instagram';
exports.default = BrowserUtil;
