"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wishListEndpoints = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
exports.wishListEndpoints = {
    get: 'api/wishlist/basic',
    add: 'api/wishlist/product/add',
    remove: 'api/wishlist/product/remove',
    lister: 'api/wishlist/lister',
    login: 'api/wishlist/login',
    logout: 'api/wishlist/logout',
};
class WishListApi {
    getWishlistProducts(apiPrefix, authenticationConfig, showOutOfStock) {
        const axiosConfig = {
            method: 'GET',
            url: `${apiPrefix}${exports.wishListEndpoints.get}`,
            withCredentials: true,
            params: showOutOfStock ? Object.assign(Object.assign({}, authenticationConfig), { showOutOfStock }) : authenticationConfig,
        };
        return axios_1.default.request(axiosConfig).then(response => response.data.products);
    }
    addProductToWishList(product, apiPrefix, authenticationConfig) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.wishListEndpoints.add}`,
            withCredentials: true,
            data: product,
            params: authenticationConfig,
        };
        return axios_1.default.request(axiosConfig);
    }
    removeProductFromWishList(product, apiPrefix, authenticationConfig) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.wishListEndpoints.remove}`,
            withCredentials: true,
            data: product,
            params: authenticationConfig,
        };
        return axios_1.default.request(axiosConfig);
    }
    getWishlistListerProducts(apiPrefix, defaultRequestParams, size, page, showOutOfStock) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.wishListEndpoints.lister}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, defaultRequestParams), { size,
                    page,
                    showOutOfStock }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            if (result.data) {
                return {
                    totalPages: result.data.totalPages,
                    itemCount: result.data.itemCount,
                    items: result.data.items.map((product) => (Object.assign(Object.assign({}, product), { colours: product.colours.map((color) => (Object.assign(Object.assign({}, color), { inWishList: true }))) }))),
                };
            }
            return null;
        });
    }
    login(apiPrefix, authenticationConfig) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.wishListEndpoints.login}`,
                withCredentials: true,
                params: authenticationConfig,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    logout(apiPrefix, authenticationConfig) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.wishListEndpoints.logout}`,
                withCredentials: true,
                params: authenticationConfig,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new WishListApi();
