"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerDividerContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ListerDividerContainer = () => {
    const listerHeadingInformation = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetListerHeadingInformation)());
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Divider, { type: cotopaxi_1.DividerType.HORIZONTAL, dataQA: "search_result_product-count", margin: isDesktop ? cotopaxi_1.DividerMargin.MEDIUM : cotopaxi_1.DividerMargin.NONE, children: [listerHeadingInformation.itemCount, " ", t('product.products').toLowerCase()] }));
};
exports.ListerDividerContainer = ListerDividerContainer;
