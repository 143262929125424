"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerEndpoints = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
exports.customerEndpoints = {
    customer: {
        create: 'api/customer/profile/create',
        update: 'api/customer/profile/update',
        fit: 'api/customer/profile/fit',
        profile: 'api/customer/profile/details',
        tealium: 'api/customer/profile/tealium',
        basic_profile: 'api/me',
        basic_info: 'api/customer/basicinfo',
        exists_cardnumber: 'api/customer/exists/cardnumber',
        update_email: 'api/customer/profile/update/email',
        get_card_info: 'api/customer/cardalias',
        delete_card: 'api/customer/cardalias/',
        get_vouchers: 'api/customer/payback',
        activate_voucher: 'api/voucher/public/payback/customer/activate',
        create_return_incentive: 'api/customer/loyalty/requestvouchers',
        create_discount: 'api/customer/discount',
        unsubscribe_preferences: 'api/customer/preference/unregister',
        update_phone_number: 'api/customer/profile/update/mobilephone',
    },
    exists: {
        email: 'api/customer/exists',
    },
    forgot: 'api/credentials/forgot',
    genders: 'api/aem/general/gender/all',
    languages: 'api/aem/general/language/all',
    newsletter: {
        get: 'api/customer/done/newsletter',
        subscribe: 'api/customer/newsletter/subscribe',
    },
    preferences: {
        get: 'api/customer/preference/v2',
        get_all: 'api/customer/preference/all',
        update: 'api/customer/preference/update',
        update_all: 'api/customer/preference/update/all',
        update_uuid: 'api/customer/preference/update/uuid',
    },
    rental: {
        data: 'api/customer/rental/data',
        person: {
            get: 'api/customer/rental/person',
            insert: 'api/customer/rental/person',
            update: 'api/customer/rental/person/update',
            remove: 'api/customer/rental/person/delete',
            proficiencies: 'api/customer/rental/person/proficiencies',
        },
    },
    titles: 'api/aem/general/title/all',
    countries: 'api/aem/general/country/available',
    deliveryAddress: {
        add: 'api/customer/deliveryaddress/add',
        updateOneAddress: 'api/customer/deliveryaddress/updateaddress',
        delete: 'api/customer/deliveryaddress/delete',
        list: 'api/customer/deliveryaddress',
    },
    update_password: 'api/v2/customer/password',
    validate: {
        email: 'api/customer/validate/email',
    },
    social_media_connections: {
        get: 'api/customer/social/all',
        remove: 'api/customer/social/delete',
    },
};
class CustomerApi {
    constructor() {
        this.updatePassword = (servicesApi, defaultRequestParams, data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${exports.customerEndpoints.update_password}`,
                params: defaultRequestParams,
                data,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    create(apiPrefix, params, data) {
        return new Promise((resolve, reject) => {
            this.getLanguages(apiPrefix, params).then((languageData) => {
                const customerLanguage = languageData.find((lang) => {
                    return lang.languageCode === data.language.toUpperCase();
                });
                if (customerLanguage === null || customerLanguage === void 0 ? void 0 : customerLanguage.idLanguage) {
                    data = Object.assign(Object.assign({}, data), { idLanguage: customerLanguage.idLanguage });
                }
                const axiosConfig = {
                    method: 'POST',
                    url: `${apiPrefix}${exports.customerEndpoints.customer.create}`,
                    params,
                    withCredentials: true,
                    data,
                };
                return axios_1.default.request(axiosConfig).then(responseFromBackEnd => resolve(responseFromBackEnd.data), error => reject(error));
            }, error => reject(error));
        });
    }
    update(apiPrefix, params, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.update}`,
                params,
                withCredentials: true,
                data,
            };
            const response = yield axios_1.default.request(axiosConfig);
            return response.data;
        });
    }
    emailExists(apiPrefix, defaultRequestParams, email, sendEmail) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.exists.email}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { email, sendmail: sendEmail }),
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getLanguages(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.languages}`,
                params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getTitles(apiPrefix, params, lang) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.titles}`,
                params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return this.processTitleData(customerEndpointRequest.data, lang);
        });
    }
    getGenders(apiPrefix, params, lang) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.genders}`,
                params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return this.processGenderData(customerEndpointRequest.data, lang);
        });
    }
    sendPasswordResetMail(apiPrefix, params, email) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.customerEndpoints.forgot}`,
            params,
            withCredentials: true,
            data: email,
            headers: {
                'Content-Type': 'text/plain',
            },
        };
        return axios_1.default.request(axiosConfig);
    }
    getProfile(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.profile}`,
                params,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getTealiumCustomerData(apiPrefix, mainWebshop) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.tealium}`,
                params: { mainWebshop },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getBasicProfile(apiPrefix, params) {
        const axiosConfig = {
            method: 'GET',
            url: `${apiPrefix}${exports.customerEndpoints.customer.basic_profile}`,
            params,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios_1.default.request(axiosConfig);
    }
    getBasicInfo(apiPrefix, params, customerUUID) {
        const axiosConfig = {
            method: 'GET',
            url: `${apiPrefix}${exports.customerEndpoints.customer.basic_info}`,
            params: Object.assign(Object.assign({}, params), { customerUuid: customerUUID }),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios_1.default.request(axiosConfig);
    }
    saveRentalData(apiPrefix, params, shopNumber, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.rental.data}`,
                params: Object.assign(Object.assign({}, params), { shopNumber }),
                data,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getRentalPeople(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.rental.person.get}`,
                params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    insertRentalPerson(apiPrefix, params, person) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = {
                birthYear: parseInt(person.birthYear, 10),
                firstName: person.firstName.toString(),
                height: parseInt(person.height, 10),
                primary: person.primary,
                proficiencyId: parseInt(person.proficiencyId, 10),
                shoeSize: person.shoeSize.toString(),
                weight: person.weight.toString(),
            };
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.rental.person.insert}`,
                params,
                data,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    updateRentalPerson(apiPrefix, params, person) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = {
                birthYear: parseInt(person.birthYear, 10),
                firstName: person.firstName.toString(),
                height: parseInt(person.height, 10),
                id: person.id,
                primary: person.primary,
                proficiencyId: parseInt(person.proficiencyId, 10),
                shoeSize: person.shoeSize.toString(),
                weight: person.weight.toString(),
            };
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.rental.person.update}`,
                params,
                data,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    deleteRentalPerson(apiPrefix, params, person) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.rental.person.remove}/${person.id}`,
                params: params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getRentalProficiencies(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.rental.person.proficiencies}`,
                params,
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getNewsletterPreferences(apiPrefix, params, customerUuid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.newsletter.get}`,
                params: Object.assign(Object.assign({}, params), { uuid: customerUuid }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    subscribeToNewsletter(apiPrefix, params, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.newsletter.subscribe}`,
                params: Object.assign(Object.assign({}, params), { email }),
                withCredentials: true,
            };
            const customerEndpointRequest = yield axios_1.default.request(axiosConfig);
            return customerEndpointRequest.data;
        });
    }
    getFitUserData(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.fit}`,
                params,
                withCredentials: true,
            };
            const fitUserDataRequest = yield axios_1.default.request(axiosConfig);
            return fitUserDataRequest.data;
        });
    }
    getCountries(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.countries}`,
                params,
                withCredentials: true,
            };
            const answer = yield axios_1.default.request(axiosConfig);
            return answer.data;
        });
    }
    updatePreferences(apiPrefix, params, preferences, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.preferences.update}`,
                params: Object.assign(Object.assign({}, params), { email }),
                data: preferences,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getPreferences(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.preferences.get}`,
                withCredentials: true,
                params,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getMultiFasciaPreferences(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.preferences.get_all}`,
                withCredentials: true,
                params,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    updateMultiFasciaPreferences(apiPrefix, params, preferences) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.preferences.update_all}`,
                withCredentials: true,
                params,
                data: preferences,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    checkCardNumber(apiPrefix, params, cardNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.exists_cardnumber}`,
                params: Object.assign(Object.assign({}, params), { cardNumber }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    updateCardWithEmail(apiPrefix, params, email, customerUUID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.update_email}`,
                params: Object.assign(Object.assign({}, params), { email,
                    customerUUID }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    deliveryAddress(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.deliveryAddress.list}`,
                params,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    addDeliveryAddress(apiPrefix, params, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.deliveryAddress.add}`,
                params,
                data: address,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    updateOneDeliveryAddress(apiPrefix, params, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.deliveryAddress.updateOneAddress}`,
                params,
                data: address,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    deleteDeliveryAddress(apiPrefix, params, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.deliveryAddress.delete}`,
                params,
                data: address,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    validatePasswordResetLink(apiPrefix, params, uuid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.forgot}`,
                params: Object.assign(Object.assign({}, params), { event: uuid }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getCardInfo(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.get_card_info}`,
                params,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    resetPassword(apiPrefix, params, uuid, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'PUT',
                url: `${apiPrefix}${exports.customerEndpoints.forgot}`,
                params: Object.assign(Object.assign({}, params), { event: uuid }),
                data: password,
                withCredentials: true,
                headers: {
                    'Content-Type': 'text/plain',
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    deleteCard(apiPrefix, params, cardId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'DELETE',
                url: `${apiPrefix}${exports.customerEndpoints.customer.delete_card}${cardId}`,
                params,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getVouchers(apiPrefix, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.customer.get_vouchers}`,
                params,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result;
        });
    }
    emailValidate(apiPrefix, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.validate.email}`,
                withCredentials: true,
                params: {
                    email,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    getSocialMediaConnections(apiPrefix, params, email, customerIdentityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiPrefix}${exports.customerEndpoints.social_media_connections.get}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, params), { email,
                    customerIdentityId }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    removeSocialMediaConnection(apiPrefix, params, socialMediaConnection) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.social_media_connections.remove}`,
                withCredentials: true,
                params,
                data: {
                    customerIdentityId: socialMediaConnection.customerIdentityId,
                    email: socialMediaConnection.email,
                    externalId: socialMediaConnection.externalId,
                    imageLink: socialMediaConnection.imageLink,
                    name: socialMediaConnection.name,
                    type: socialMediaConnection.type,
                },
            };
            return yield axios_1.default.request(axiosConfig);
        });
    }
    unsubscribePreferences(apiPrefix, params, email, idCustomer, fasciaId, customerUUID, preference) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.unsubscribe_preferences}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, params), { email,
                    idCustomer,
                    fasciaId,
                    customerUUID,
                    preference }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    unsubscribeNewsletterPreferences(apiPrefix, params, customerUUID, preferencesNewsletter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.preferences.update_uuid}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, params), { customerUUID }),
                data: preferencesNewsletter,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    updatePhoneNumber(apiPrefix, mainWebshop, phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.update_phone_number}`,
                headers: { 'Content-Type': 'text/plain' },
                withCredentials: true,
                params: { mainWebshop },
                data: phoneNumber,
            };
            return yield axios_1.default.request(axiosConfig);
        });
    }
    postPaybackVouchers(apiPrefix, params, UUID, vouchers) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.activate_voucher}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, params), { UUID }),
                data: vouchers,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    createReturnIncentiveVouchers(apiPrefix, params, customerUuid, returnIncentiveId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.create_return_incentive}`,
                withCredentials: true,
                params: Object.assign(Object.assign({}, params), { customerUuid,
                    returnIncentiveId }),
                data: customerUuid,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    createDiscountVoucher(apiPrefix, params, activationId, discountVoucherId, customerUuid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestData = {
                activationId: Number(activationId),
                customerUUID: customerUuid,
                discountVoucherId: discountVoucherId,
            };
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${exports.customerEndpoints.customer.create_discount}`,
                withCredentials: true,
                params,
                data: requestData,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
    processTitleData(data, lang) {
        const titles = [];
        data.forEach(title => {
            if (title.titleNameTranslations) {
                const customerTitle = {
                    id: title.idTitle,
                    title: title.titleNameTranslations[lang.toUpperCase()],
                    sortOrder: title.sortOrder,
                    idGender: title.idGender,
                };
                titles.push(customerTitle);
            }
        });
        return titles;
    }
    processGenderData(data, lang) {
        const genders = [];
        data.forEach(function (gender) {
            if (gender.genderTitleTranslations) {
                const customerGender = {
                    id: gender.idGenderPeople,
                    peopleCode: gender.genderPeopleCode,
                    gender: gender.genderTitleTranslations[lang.toUpperCase()],
                };
                genders.push(customerGender);
            }
        });
        return genders;
    }
    updateProfileFromReturn(servicesEndpoint, defaultRequestParameters, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!address)
                return;
            const customerProfile = yield this.getProfile(servicesEndpoint, defaultRequestParameters);
            if (customerProfile) {
                customerProfile.address = address;
                return this.update(servicesEndpoint, defaultRequestParameters, customerProfile);
            }
            return;
        });
    }
}
exports.default = new CustomerApi();
