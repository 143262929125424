"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const EventEnum_1 = require("../enums/EventEnum");
const common_utils_1 = require("@as-react/common-utils");
const EventUtil_1 = require("./EventUtil");
class MonetateUtil {
    static onLoadRecommendations(recommendations) {
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.MONETATE_RECOMMENDATIONS, recommendations);
    }
    static getPageType() {
        var _b, _c;
        const pageInfo = (_c = (_b = window === null || window === void 0 ? void 0 : window.SITE) === null || _b === void 0 ? void 0 : _b.Config) === null || _c === void 0 ? void 0 : _c.pageInfo;
        if (!pageInfo) {
            return undefined;
        }
        else if (pageInfo.isHomePage) {
            return 'main';
        }
        else if (pageInfo.isSearchPage) {
            return 'search';
        }
        else if (pageInfo.isBrandPage || pageInfo.isCategoryPage || pageInfo.isBrandAsCategoryPage) {
            return 'index';
        }
        else if (pageInfo.isPDPPage || pageInfo.isGiftCardPage || pageInfo.isGiftVoucherPage) {
            return 'product';
        }
        else if (pageInfo.isBasketOverviewPage) {
            return 'cart';
        }
        else if (pageInfo.isBuyPage && !pageInfo.isConfirmationPage) {
            return 'checkout';
        }
        else if (pageInfo.isConfirmationPage) {
            return 'purchase';
        }
        else if (pageInfo.isWishlistPage) {
            return 'wishlist';
        }
        else if (pageInfo.isNotFoundPage) {
            return 'error';
        }
        else {
            return undefined;
        }
    }
    static trackPageType() {
        if (this.monetateEvents.setPageType) {
            return;
        }
        const pageType = this.getPageType();
        if (pageType) {
            this.pushPageEvent(pageType);
        }
    }
    static trackData() {
        window.monetateQ = window.monetateQ || [];
        if (!this.monetateEvents.setPageType) {
            const pageType = this.getPageType();
            if (pageType) {
                this.monetateEvents.setPageType = pageType;
            }
        }
        Object.keys(this.monetateEvents).forEach(eventType => {
            if (this.monetateEvents[eventType]) {
                window.monetateQ.push([eventType, this.monetateEvents[eventType]]);
            }
        });
        window.monetateQ.push(['trackData']);
    }
}
_a = MonetateUtil;
MonetateUtil.MONETATE_SCRIPT_ID = 'monetateScript';
MonetateUtil.monetateEvents = {};
MonetateUtil.init = (scriptSrcUrl) => {
    if (_a.doesScriptExist()) {
        return;
    }
    _a.setRecommendationsHandler();
    _a.setScript(scriptSrcUrl);
    _a.trackPageType();
};
MonetateUtil.pushPageEvent = (pageType) => {
    _a.monetateEvents.setPageType = pageType !== null && pageType !== void 0 ? pageType : _a.getPageType();
    _a.trackData();
};
MonetateUtil.pushBasketEvent = (products, currency, pageType) => {
    _a.monetateEvents.addCartRows = _a.generateCartProducts(products, currency);
    if (pageType) {
        _a.monetateEvents.setPageType = pageType;
    }
    else {
        _a.monetateEvents.setPageType = _a.getPageType();
    }
    _a.trackData();
};
MonetateUtil.pushListerEvent = (products) => {
    _a.monetateEvents.addProducts = products.map(product => `p${product.productId}`);
    _a.trackData();
};
MonetateUtil.pushProductDetailEvent = (productId) => {
    _a.monetateEvents.addProductDetails = [{ productId: `p${productId}` }];
    _a.trackData();
};
MonetateUtil.pushOrderConfirmationEvent = (products, currency, purchaseId) => {
    _a.monetateEvents.addPurchaseRows = _a.generateCartProducts(products, currency, purchaseId);
    _a.trackData();
};
MonetateUtil.getActionIds = (actionId) => actionId
    .split(',')
    .map(id => id.trim())
    .filter(Boolean);
MonetateUtil.doesScriptExist = () => Boolean(document.body.querySelector(`#${_a.MONETATE_SCRIPT_ID}`));
MonetateUtil.setScript = (scriptSrcUrl) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = _a.MONETATE_SCRIPT_ID;
    script.innerHTML = `
            var monetateT = new Date().getTime();
            (function() {
                var p = document.location.protocol;
                if (p == "http:" || p == "https:") {
                    var m = document.createElement('script');
                    m.type = 'text/javascript';
                    m.async = true;
                    m.src = "${scriptSrcUrl}";
                    m.onload = function() {
                        document.body.dispatchEvent(new CustomEvent("${EventEnum_1.EventEnum.MONETATE_SCRIPT_LOADED}"));
                    };
                    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);
                }
             })();`;
    document.head.prepend(script);
};
MonetateUtil.setRecommendationsHandler = () => {
    window.productRecommendations = recommendations => _a.onLoadRecommendations(recommendations);
};
MonetateUtil.generateCartProducts = (products, currency, purchaseId) => products.map(product => (Object.assign({ productId: `p${product.productId}`, quantity: String(product.quantity), unitPrice: String(product.price), currency: (0, common_utils_1.getCurrencyISOCode)(currency) }, (purchaseId ? { purchaseId } : {}))));
exports.default = MonetateUtil;
