"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreConfiguratorLocalDebug = void 0;
const tslib_1 = require("tslib");
const redux_devtools_extension_1 = require("redux-devtools-extension");
const rootModules_1 = require("../rootModules");
const storeConfigure_base_1 = require("./storeConfigure.base");
class StoreConfiguratorLocalDebug {
    constructor() {
        this.buildDynamic = (initialState, context, dynamicStore, componentReduxModules, history) => {
            const composeEnhancers = (0, redux_devtools_extension_1.composeWithDevTools)({ trace: true, traceLimit: 40 });
            return (0, storeConfigure_base_1.configureDynamicStoreBase)(initialState, context, dynamicStore, componentReduxModules, history, composeEnhancers);
        };
    }
    loadFromModuleCollection(componentName, initialState) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const moduleCollectionImplementation = yield (0, rootModules_1.loadFromModuleCollectionImplementation)(componentName, initialState);
            return moduleCollectionImplementation;
        });
    }
}
exports.StoreConfiguratorLocalDebug = StoreConfiguratorLocalDebug;
