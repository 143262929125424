"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductRecentlyViewed_module_1 = require("./modules/ProductRecentlyViewed.module");
const WishList_module_1 = require("./modules/WishList.module");
const Compare_module_1 = require("./modules/Compare.module");
const loadProductRecentlyViewedRootModule = () => ({
    hasRouter: false,
    modules: [(0, Compare_module_1.default)(), (0, ProductRecentlyViewed_module_1.default)(), (0, WishList_module_1.default)()],
});
exports.default = loadProductRecentlyViewedRootModule;
