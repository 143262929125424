"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listerActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.listerActions = {
    createInitializeAction: () => ({
        type: ActionEnums_1.ListerActionTypes.LISTER_INIT,
    }),
    createTileClickAction: () => ({
        type: ActionEnums_1.ListerActionTypes.LISTER_TILE_CLICK,
    }),
    updateProducts: (items) => ({
        type: ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS,
        payload: items,
    }),
    updateProductsAndTotalPages: (items, totalPages) => ({
        type: ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS_AND_TOTAL_PAGES,
        payload: { products: items, totalPages },
    }),
    updateProductsAndTotalPagesAndItemCount: (products, totalPages, itemCount) => ({
        type: ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS_AND_TOTAL_PAGES_AND_ITEM_COUNT,
        payload: { products, totalPages, itemCount },
    }),
    updateExistingFilters: (filters) => ({
        type: ActionEnums_1.ListerActionTypes.UPDATE_EXISTING_FILTERS,
        payload: filters,
    }),
    showPlaceholderTiles: () => ({
        type: ActionEnums_1.ListerActionTypes.SHOW_PLACEHOLDER_TILES,
    }),
    updateInStoreFilter: (store) => ({
        type: ActionEnums_1.ListerActionTypes.UPDATE_IN_STORE_FILTER,
        payload: store,
    }),
    getListerProducts: () => ({
        type: ActionEnums_1.ListerActionTypes.GET_LISTER_PRODUCTS,
    }),
    setListerStateAction: (searchObject) => ({
        type: ActionEnums_1.ListerActionTypes.SET_LISTER_STATE,
        payload: searchObject,
    }),
    setListerIsLoadedAction: () => ({
        type: ActionEnums_1.ListerActionTypes.SET_LISTER_ISLOADED,
    }),
};
