"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_utils_1 = require("@as-react/common-utils");
class PromotionAPI {
    constructor() {
        this.getProductBundle = (servicesEndpointUrl, defaultRequestParameters, productId, colourId) => {
            return new Promise((resolve, reject) => {
                const axiosConfig = {
                    url: `${servicesEndpointUrl}api/promotion/productbundle/`,
                    method: 'GET',
                    params: {
                        mainWebshop: defaultRequestParameters.mainWebshop,
                        shopId: defaultRequestParameters.shopId,
                        language: defaultRequestParameters.language,
                        productId,
                        colourId,
                    },
                };
                axios_1.default.request(axiosConfig).then(response => {
                    if (!(0, common_utils_1.isNullOrUndefined)(response.data) && !(0, common_utils_1.isNullOrUndefined)(response.data)) {
                        resolve(response.data);
                    }
                    reject();
                }, reject);
            });
        };
        this.calculateProductBundle = (servicesEndpointUrl, defaultRequestParameters, data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesEndpointUrl}api/promotion/productbundle/calculate`,
                params: {
                    mainWebshop: defaultRequestParameters.mainWebshop,
                    shopId: defaultRequestParameters.shopId,
                    language: defaultRequestParameters.language,
                },
                data,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new PromotionAPI();
