"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const AddToBasketButton_1 = require("../../components/product-detail/add-to-basket-button/AddToBasketButton");
const AddToWishlistButton_1 = require("../../components/product-detail/add-to-wishlist-button/AddToWishlistButton");
const StoreFinderButton_1 = require("../../components/product-detail/store-finder-button/StoreFinderButton");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ActionEnums_1 = require("../../enums/ActionEnums");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ProductDetailV2Container_1 = require("./ProductDetailV2Container");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const StoreAPI_1 = require("../../api/store/StoreAPI");
const ProductCTAContainerV2 = ({ quantity }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const configuration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const clickAndCollectEnabled = (0, ConfigurationUtil_1.getCheckoutCc)(configuration);
    const shopLandingEnabledFlag = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const selectedStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const basketValidationState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidationState)());
    const productColorIsInWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetProductColorIsInWishList)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const { productCode, productId, unavailableOnline } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const { hdExcluded, ccExcluded } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const addToBasket = (quantity) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const selectedStoreFromLocalStorage = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY);
        const storagedStoreId = selectedStoreFromLocalStorage === null || selectedStoreFromLocalStorage === void 0 ? void 0 : selectedStoreFromLocalStorage.storeId;
        if (storagedStoreId && Number(storagedStoreId) !== selectedStoreId) {
            yield StoreAPI_1.default.setClickAndCollectStoreId(servicesEndpoint, defaultRequestParams, Number(storagedStoreId));
        }
        dispatch(selectedAdditionalProducts.length > 0
            ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, ActionEnums_1.ProductAddToBasketLocations.PDP)
            : BasketActions_creators_1.basketActions.createAddAction(quantity, ActionEnums_1.ProductAddToBasketLocations.PDP, common_types_1.PDPVersion.V2));
    });
    const toggleWishList = (wishListProduct) => {
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    };
    const openCC = () => {
        if (selectedStoreId) {
            addToBasket(quantity);
        }
        else {
            dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
            dispatch(AnalyticsActions_creators_1.analyticsActions.showStoreStock());
        }
    };
    const sizeForgotten = () => dispatch(BasketActions_creators_1.basketActions.createSizeForgottenShowAction());
    const wishListProduct = {
        colorId: selectedColorId,
        productCode: productCode,
        productId: parseInt(productId, 10),
        sku: selectedSku,
    };
    if (sizeCombinationNotExist ||
        ((hdExcluded || (fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded)) && (ccExcluded || !clickAndCollectEnabled))) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: unavailableOnline ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3, marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: t('pdp.cta.notonline') }) }), !shopLandingEnabledFlag && ((0, jsx_runtime_1.jsx)(AddToWishlistButton_1.default, { isProductInWishList: productColorIsInWishList, onToggle: toggleWishList, wishListProduct: wishListProduct }))] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, children: [!hdExcluded && !(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded) ? ((0, jsx_runtime_1.jsx)(AddToBasketButton_1.AddToBasketButton, { onClick: amount => addToBasket(amount), validationState: basketValidationState, quantity: quantity, disabled: !((_a = priceInformation.prices) === null || _a === void 0 ? void 0 : _a.SELL) })) : clickAndCollectEnabled ? ((0, jsx_runtime_1.jsx)(StoreFinderButton_1.default, { text: t('pdp.cart.addtobasket.btn'), selectedSku: selectedSku, openCC: openCC, sizeForgotten: sizeForgotten, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, icon: {
                            name: cotopaxi_1.IconName.CART,
                            size: cotopaxi_1.IconSize.MAJOR,
                        } })) : null, !shopLandingEnabledFlag && ((0, jsx_runtime_1.jsx)(AddToWishlistButton_1.default, { isProductInWishList: productColorIsInWishList, onToggle: toggleWishList, wishListProduct: wishListProduct }))] }) })) }));
};
exports.default = ProductCTAContainerV2;
