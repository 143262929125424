"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkStockReservationSaga = exports.checkStockReservation = void 0;
const effects_1 = require("redux-saga/effects");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
function* checkStockReservation() {
    var _a, _b;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.checkStockReservation, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createHasUnavailableProductsAction(false));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === common_types_1.HTTPStatusCode.BadRequest && ((_b = error.response.data) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createHasUnavailableProductsAction(true));
            }
        }
    }
}
exports.checkStockReservation = checkStockReservation;
function* checkStockReservationSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.CHECK_STOCK_RESERVATION, checkStockReservation);
}
exports.checkStockReservationSaga = checkStockReservationSaga;
