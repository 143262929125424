"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductWizardQuestionList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductWizardQuestionList = ({ title, subTitle, inputType, answers, showAnswers, onSelectChange, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        marginBottom: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                    },
                }, tablet: {
                    spacing: {
                        marginBottom: 3,
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: isMobile ? cotopaxi_1.HeadingSize.XS : cotopaxi_1.HeadingSize.L, children: title }), subTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: subTitle }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } }, tablet: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: showAnswers &&
                        answers.map((answer, index) => {
                            const key = `product-wizard-item_${answer.title}_${index}`;
                            const _onSelectChange = () => onSelectChange(index);
                            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductWizardItem, { id: key, name: key, value: key, radioButton: inputType === common_types_1.ProductWizardInputType.RADIO, title: answer.title, productCount: answer.productCount || 0, disabled: !answer.productCount, checked: answer.selected || false, onChange: _onSelectChange, subTitle: answer.subTitle, imagePath: answer.imagePath }) }, key));
                        }) }) })] }));
};
exports.ProductWizardQuestionList = ProductWizardQuestionList;
