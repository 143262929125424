"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeGuideV2 = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const EventUtil_1 = require("../../../util/EventUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const SizeGuideV2 = ({ isPopup, onClick, sizeGuideContent, sizeGuideContentUrl, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({
        close: () => setIsOpen(false),
    });
    const handleSizeGuideOpen = () => {
        onClick();
        if (isPopup) {
            setIsOpen(true);
        }
        else {
            window.open(sizeGuideContentUrl, '_blank');
        }
    };
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            handlePopoverOpen(null, null, true);
            setTimeout(() => EventUtil_1.default.dispatch(EventEnum_1.EventEnum.SIZE_GUIDE_POPOVER_OPEN), cotopaxi_1.POPOVER_ANIMATION_DURATION);
        }
    }, [isOpen]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1 } }, tablet: { spacing: { paddingTop: 0 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "size_guide", iconLeft: { name: cotopaxi_1.IconName.DIAGONAL_RULER }, onClick: handleSizeGuideOpen, size: cotopaxi_1.ButtonSize.SMALL, text: t('product.size.guide.link'), variant: cotopaxi_1.ButtonVariant.LINK }), isPopup && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, title: t('product.size.guide.link'), dataQA: "size_guide_popup", children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: sizeGuideContent }) }))] }));
};
exports.SizeGuideV2 = SizeGuideV2;
