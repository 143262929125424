"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monetateActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.monetateActions = {
    init() {
        return { type: ActionEnums_1.MonetateActionTypes.INIT };
    },
    load() {
        return { type: ActionEnums_1.MonetateActionTypes.LOAD };
    },
    onHold() {
        return { type: ActionEnums_1.MonetateActionTypes.ON_HOLD };
    },
    loaded() {
        return { type: ActionEnums_1.MonetateActionTypes.LOADED };
    },
    fetchMonetateProducts(payload) {
        return { type: ActionEnums_1.MonetateActionTypes.FETCH_PRODUCTS, payload };
    },
    setMonetateProducts(payload) {
        return { type: ActionEnums_1.MonetateActionTypes.SET_PRODUCTS, payload };
    },
    getDeliveryPromise(payload) {
        return { type: ActionEnums_1.MonetateActionTypes.GET_DELIVERY_PROMISE, payload };
    },
    setDeliveryPromise(payload) {
        return { type: ActionEnums_1.MonetateActionTypes.SET_DELIVERY_PROMISE, payload };
    },
};
