"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPSizeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const SizeDropdown_1 = require("../../size-dropdown/SizeDropdown");
const common_utils_1 = require("@as-react/common-utils");
const MiniPDPSizeSelect = ({ sizes, selectedSku, sizeSelectError, aemConfiguration, onSizeSelectChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const sizeSelectRef = (0, react_1.useRef)(null);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const oneSizeProductCode = (0, ConfigurationUtil_1.getOneSizeProductCode)(aemConfiguration);
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    const isOneSizeProductInline = (0, common_utils_1.isOnlyOneSizeProduct)(sizes);
    const { filteredSizes, options: selectOptions } = (0, ProductUtil_1.mapSizeSelectOptions)(sizes, true);
    (0, react_1.useEffect)(() => {
        if (sizeSelectError && !selectedSku) {
            handlePopoverOpen(null, sizeSelectRef.current, false, true);
        }
        else {
            handlePopoverClose();
        }
    }, [sizeSelectError]);
    const handleSelectedItemChange = (option) => {
        onSizeSelectChange(option.value.toString());
    };
    if (isOneSizeProductInline) {
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size') }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, dataQA: "one_size", children: selectOptions[0].label })] }));
    }
    if (oneSizeProduct) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size') }) }), (0, jsx_runtime_1.jsx)(SizeDropdown_1.SizeDropdown, { id: "mini_pdp_product_tile_size_select", toggleElementRef: sizeSelectRef, appendToBody: true, sizes: filteredSizes, options: selectOptions, selectedSku: selectedSku, handleSelectedItemChange: handleSelectedItemChange, toggleElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { name: "size-select", dataQA: "size-select", options: selectOptions, selectedValue: selectedSku, placeholder: t('pdp.size.choose.default'), defaultOptionValue: selectOptions.length === 1 ? selectOptions[0].value : undefined, state: sizeSelectError ? cotopaxi_1.SelectState.ERROR : undefined, onChange: onSizeSelectChange, fullWidth: true, disabled: true }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { type: cotopaxi_1.PopoverType.TOOLTIP, anchorEl: anchorEl, noAnimation: true, onClose: handlePopoverClose, position: cotopaxi_1.PopoverPosition.BOTTOM, stickyWrapper: true, scrollableWrapperEl: document.querySelector(cotopaxi_1.POPOVER_BODY_SELECTOR), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('pdp.size.choose.default') }) })] }));
};
exports.MiniPDPSizeSelect = MiniPDPSizeSelect;
