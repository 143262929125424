"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_RESULT_LIMIT = exports.blockTitles = exports.bannerIds = exports.NOTHING_FOUND_SESSION_KEY = void 0;
const common_types_1 = require("@as-react/common-types");
exports.NOTHING_FOUND_SESSION_KEY = 'searchMade';
exports.bannerIds = {
    [common_types_1.MenuSearchResultBlockVariant.SUGGESTIONS]: 'header-search-suggest-keyword',
    [common_types_1.MenuSearchResultBlockVariant.BRANDS]: 'header-search-suggest-brand',
    [common_types_1.MenuSearchResultBlockVariant.CATEGORIES]: 'header-search-suggest-category',
    [common_types_1.MenuSearchResultBlockVariant.BRAND_AS_CATEGORIES]: 'header-search-suggest-brand-as-category',
    [common_types_1.MenuSearchResultBlockVariant.CONTENT]: 'header-search-suggest-content',
};
exports.blockTitles = {
    [common_types_1.MenuSearchResultBlockVariant.SUGGESTIONS]: 'flyout.title.searchsuggestions',
    [common_types_1.MenuSearchResultBlockVariant.BRANDS]: 'flyout.title.brands',
    [common_types_1.MenuSearchResultBlockVariant.CATEGORIES]: 'flyout.title.categories',
    [common_types_1.MenuSearchResultBlockVariant.BRAND_AS_CATEGORIES]: 'flyout.title.brand_as_categories',
    [common_types_1.MenuSearchResultBlockVariant.CONTENT]: 'flyout.title.relevant',
};
exports.SEARCH_RESULT_LIMIT = {
    FOR_1_SEARCH_CHARACTER: 2,
    FOR_2_SEARCH_CHARACTER: 3,
    FOR_MORE_THEN_2_SEARCH_CHARACTER: 5,
    FOR_SEARCH_SUGGESTIONS: 7,
};
