"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCountdownTimer = void 0;
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const react_1 = require("react");
const DeliveryConstants_1 = require("../constants/DeliveryConstants");
dayjs.extend(utc);
const useCountdownTimer = (cutoff, message) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [countdownMessage, setCountdownMessage] = (0, react_1.useState)(message);
    const intervalRef = (0, react_1.useRef)(null);
    const setCountdownTimer = (deadlineHour, deadlineMinute) => {
        const setMessage = () => {
            const now = dayjs.utc();
            const deadline = now.set('hour', deadlineHour).set('minute', deadlineMinute).set('second', 0);
            let countdownReplacement = '';
            if (now.isAfter(deadline)) {
                intervalRef.current && clearInterval(intervalRef.current);
                countdownReplacement = `${t('pdp.size.deliverypromise.txt.minutes_seconds_left', {
                    minutes: '00',
                    seconds: '00',
                })}`;
            }
            else {
                const minutesLeft = deadline.diff(now, 'minute');
                const hoursLeft = Math.floor(minutesLeft / 60);
                const minutesLeftAfterHours = minutesLeft - hoursLeft * 60;
                const secondsLeft = deadline.diff(now, 'second');
                const secondsLeftAfterMinutes = secondsLeft - minutesLeft * 60;
                countdownReplacement =
                    minutesLeft < 60
                        ? `${t('pdp.size.deliverypromise.txt.minutes_seconds_left', {
                            minutes: String(minutesLeft).padStart(2, '0'),
                            seconds: String(secondsLeftAfterMinutes).padStart(2, '0'),
                        })}`
                        : `${t('pdp.size.deliverypromise.txt.hours_minutes_left', {
                            hours: String(hoursLeft).padStart(2, '0'),
                            minutes: String(minutesLeftAfterHours).padStart(2, '0'),
                        })}`;
            }
            setCountdownMessage(message.replace(DeliveryConstants_1.COUNTDOWN, countdownReplacement));
        };
        setMessage();
        intervalRef.current = setInterval(() => {
            setMessage();
        }, GeneralConstants_1.DELAY.DELAY_1000);
    };
    (0, react_1.useEffect)(() => {
        var _a, _b;
        intervalRef.current && clearInterval(intervalRef.current);
        if (!cutoff || !(message === null || message === void 0 ? void 0 : message.includes(DeliveryConstants_1.COUNTDOWN)))
            return;
        const deadlineHour = parseInt((_a = cutoff.split(':')) === null || _a === void 0 ? void 0 : _a[0], 10);
        const deadlineMinute = parseInt((_b = cutoff.split(':')) === null || _b === void 0 ? void 0 : _b[1], 10);
        setCountdownTimer(deadlineHour, deadlineMinute);
    }, [cutoff, message]);
    return { countdownMessage };
};
exports.useCountdownTimer = useCountdownTimer;
