"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ProductFulfillmentContainer = ({ fulfillment: { ccExcluded, hdExcluded, fulfillmentCopyDescription }, fulfillmentBySize: { storeSourceExcluded }, }) => {
    const configuration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const clickAndCollectEnabled = (0, ConfigurationUtil_1.getCheckoutCc)(configuration);
    const availableInStoreOnly = ccExcluded && (hdExcluded || storeSourceExcluded);
    if (!availableInStoreOnly || !clickAndCollectEnabled) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: fulfillmentCopyDescription }) }));
};
const makeMapStateToProps = () => {
    const getProductFulfillment = (0, ProductSelector_1.makeGetProductFulfillment)();
    const getProductFulfillmentBySize = (0, ProductSelector_1.makeGetProductFulfillmentBySize)();
    const mapStateToProps = (state) => ({
        fulfillment: getProductFulfillment(state),
        fulfillmentBySize: getProductFulfillmentBySize(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(ProductFulfillmentContainer);
