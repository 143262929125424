"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const BasketPersonalShoppingAdvice_1 = require("../components/basket/basket-personal-shopping-advice/BasketPersonalShoppingAdvice");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const cotopaxi_1 = require("@as/cotopaxi");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const common_types_1 = require("@as-react/common-types");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const BasketPersonalShoppingAdviceContainer = ({ getProducts, isHorizontal, basketStatus, }) => {
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const root = (0, ConfigurationUtil_1.getRoot)(configuration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const [personalShoppingAdvice, setPersonalShoppingAdvice] = (0, react_1.useState)();
    const isPersonalShoppingAdviceEnabled = () => !!personalShoppingAdvice && personalShoppingAdvice === 'true';
    (0, react_1.useEffect)(() => {
        if (isShoplanding) {
            const value = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY);
            if (value) {
                setPersonalShoppingAdvice(value);
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (isShoplanding && isPersonalShoppingAdviceEnabled() && isHorizontal) {
            getProducts(); // Todo: remove after basket redesign is used
        }
    }, [personalShoppingAdvice]);
    return isShoplanding && isPersonalShoppingAdviceEnabled() && basketStatus === common_types_1.BasketStatus.READY ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: !isHorizontal && { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(BasketPersonalShoppingAdvice_1.BasketPersonalShoppingAdvice, { root: root, defaultRequestParams: defaultRequestParams, servicesEndpoint: servicesEndpoint, isHorizontal: isHorizontal }) })) : null;
};
const makeMapStateToProps = () => {
    const getBasketStatusState = (0, BasketSelector_1.makeGetBasketStatusState)();
    const mapStateToProps = state => ({
        basketStatus: getBasketStatusState(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    getProducts: BasketActions_creators_1.basketActions.createGetAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(BasketPersonalShoppingAdviceContainer);
