"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_FEATURES = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ProductFeature_1 = require("../../components/product-detail/product-feature/ProductFeature");
const i18nConstants_1 = require("../../constants/i18nConstants");
exports.MIN_FEATURES = 2;
const PRODUCT_FEATURES_ID = 'product-features';
const ProductFeaturesContainer = ({ technicalFeatures }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, tablet: { spacing: { marginTop: 3 } }, id: PRODUCT_FEATURES_ID, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('product.features.title') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 3 } }, children: technicalFeatures.map((feature, index) => ((0, jsx_runtime_1.jsx)(ProductFeature_1.default, { feature: feature, isFirstFeature: index === 0 }, feature.featureId))) })] }));
};
exports.default = ProductFeaturesContainer;
