"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyHomeDelivery = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const BuyForm_1 = require("../BuyForm");
const BuyAddressCard_1 = require("../buy-address-card/BuyAddressCard");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BuyHomeDelivery = ({ errors, status, touched, deliveryAddresses, address, chooseDeliveryAddress, toggleNewAddressFields, toggleEditAddressFields, addressFieldsEnabled = true, isLoggedIn, isShoplanding, children, isEditAddress = false, editAddressFieldsEnabled = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const addressFormat = (da) => {
        const houseNumberAdditionTranslation = da.houseNumberAddition
            ? da.idCountry === 15
                ? t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.addition`)
                : t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.bus`)
            : '';
        const bus = houseNumberAdditionTranslation && ` ${houseNumberAdditionTranslation} ${da.houseNumberAddition}`;
        const company = da.companyName ? `${da.companyName}, ` : '';
        const countyName = da.county ? ` ${da.county}` : '';
        const street = da.houseNumber ? `${da.address} ${da.houseNumber}` : da.address;
        const fullName = da.firstName && da.lastName ? `${da.firstName} ${da.lastName}, ` : '';
        const addressLine = [da.addressLine2, da.addressLine3, da.addressLine4].filter(Boolean);
        const town = da.country && ['GB', 'BFPO', 'IE', 'United Kingdom'].includes(da.country)
            ? `, ${da.city}, ${da.postCode}`
            : ` ${da.postCode} ${da.city}`;
        return `${fullName}${company}${street}${bus}${addressLine.length ? `, ${addressLine.join(', ')}` : ''}${town}${countyName}`;
    };
    const getDeliveryAddresses = () => deliveryAddresses
        ? deliveryAddresses.map(da => ({ label: addressFormat(da), value: da.deliveryAddressId }))
        : [{ label: '', value: '' }];
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [addressFieldsEnabled && (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.length) > 0 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.go.back`), onClick: toggleNewAddressFields, iconLeft: {
                        name: cotopaxi_1.IconName.ARROW_LEFT,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }) })), !addressFieldsEnabled && (isShoplanding || isLoggedIn) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, type: cotopaxi_1.HeadingType.SUBTITLE, children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.address.to`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, tablet: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: !isMobile, valign: cotopaxi_1.GroupVerticalAlign.BASELINE, children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-chooseAddress", name: "chooseAddress", fullWidth: true, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.choose.address`), options: getDeliveryAddresses(), state: errors.chooseAddress && touched.chooseAddress && cotopaxi_1.SelectState.ERROR, onChange: chooseDeliveryAddress, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginLeft: 1 } }, flex: { basis: isMobile ? '100%' : '55%' }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { size: cotopaxi_1.ButtonSize.SMALL, iconLeft: {
                                                name: cotopaxi_1.IconName.PLUS,
                                                size: cotopaxi_1.IconSize.MAJOR,
                                            }, text: t(`${i18nConstants_1.I18N_NAMESPACES.general}:address.add`), onClick: toggleNewAddressFields, variant: cotopaxi_1.ButtonVariant.LINK }) })] }) }) }), isEditAddress && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.IconSize.MAJOR }, text: t(editAddressFieldsEnabled
                                ? `${i18nConstants_1.I18N_NAMESPACES.general}:address.edit.cancel`
                                : `${i18nConstants_1.I18N_NAMESPACES.general}:address.edit`), size: cotopaxi_1.ButtonSize.SMALL, onClick: toggleEditAddressFields }) })), address && !editAddressFieldsEnabled && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(BuyAddressCard_1.BuyAddressCard, { address: address }) }))] })), (addressFieldsEnabled || editAddressFieldsEnabled || (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.length) === 0) &&
                react_1.Children.map(children, (child) => {
                    var _a;
                    if (!child)
                        return null;
                    const props = {
                        errors,
                        touched,
                        status,
                    };
                    return (0, react_1.cloneElement)(child, ((_a = child.props) === null || _a === void 0 ? void 0 : _a.parent) === BuyForm_1.BUY_FORM_NAME ? props : undefined);
                }), (addressFieldsEnabled || editAddressFieldsEnabled) && isLoggedIn && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-saveAddress", name: "saveAddress", labelChildren: t(`${i18nConstants_1.I18N_NAMESPACES.general}:address.save`), componentType: cotopaxi_1.FormFieldType.CHECKBOX, Component: cotopaxi_1.CheckboxWithLabel }) }) }))] }));
};
exports.BuyHomeDelivery = BuyHomeDelivery;
