"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginGuestForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const LoginGuestForm = ({ children, onSubmit, onSubmitError, validate, validationSchema, errorFeedback, initialEmail = '', loading, emailErrorRender, hideGuestLoginInfo, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const initialValues = {
        guestEmail: initialEmail,
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "login-guest-form", initialValues: initialValues, validate: validate, validationSchema: validationSchema, onSubmit: onSubmit, onSubmitError: onSubmitError, children: ({ errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, id: "login-guest-form__error-feedback" }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "guestEmail", size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.EMAIL, name: "guestEmail", renderError: emailErrorRender, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`), required: true, state: errors.guestEmail && touched.guestEmail && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.guestEmail &&
                        touched.guestEmail && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "guestEmail" }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, desktop: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.continue`), state: loading ? cotopaxi_1.ButtonState.LOADING : null }) }), !hideGuestLoginInfo && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, tablet: { spacing: { marginTop: 1 } }, desktop: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.guest.info`) }) })), children] })) }));
};
exports.LoginGuestForm = LoginGuestForm;
