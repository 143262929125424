"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruncatedText = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const TruncatedText = (_a) => {
    var { content, readLessText, readMoreText, lineClamp = 1 } = _a, textProps = tslib_1.__rest(_a, ["content", "readLessText", "readMoreText", "lineClamp"]);
    const [isTextTruncated, setIsTextTruncated] = (0, react_1.useState)(false);
    const [isExpanded, setIsExpanded] = (0, react_1.useState)(false);
    const textRef = (0, react_1.useRef)();
    const showToggle = isTextTruncated || isExpanded;
    const updateReadMoreBtnState = (0, react_1.useCallback)(() => {
        if (!textRef.current || isExpanded) {
            return;
        }
        const _isTextTruncated = textRef.current.offsetHeight !== textRef.current.scrollHeight;
        if (_isTextTruncated !== isTextTruncated) {
            setIsTextTruncated(_isTextTruncated);
        }
    }, [textRef, isTextTruncated, isExpanded]);
    const handleResize = (0, cotopaxi_1.useDebounce)(updateReadMoreBtnState, GeneralConstants_1.DELAY.DELAY_100);
    (0, react_1.useEffect)(() => {
        updateReadMoreBtnState();
    }, [isTextTruncated, isExpanded]);
    (0, react_1.useEffect)(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [updateReadMoreBtnState, handleResize, isExpanded]);
    const textLineClamp = typeof lineClamp === 'number'
        ? lineClamp
        : Object.assign(Object.assign({}, lineClamp), { withGradient: lineClamp.withGradient && isTextTruncated });
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, Object.assign({}, textProps, { innerRef: textRef, lineClamp: isExpanded ? undefined : textLineClamp, children: content })), showToggle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: () => setIsExpanded(prev => !prev), text: isExpanded ? readLessText : readMoreText, variant: cotopaxi_1.ButtonVariant.LINK }) }))] }));
};
exports.TruncatedText = TruncatedText;
