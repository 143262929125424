"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupCreditCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const PaymentGroupContent_1 = require("./PaymentGroupContent");
const CheckoutPaymentContext_1 = require("../../../../context/CheckoutPaymentContext");
const PaymentGroupCreditCard = ({ disabled, selected, group }) => {
    const [{ optionError }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const optionSelectError = optionError[group.name];
    return ((0, jsx_runtime_1.jsx)(PaymentGroupContent_1.PaymentGroupContent, { onSubmit: () => { }, disabled: disabled, selected: selected, group: group, error: optionSelectError }));
};
exports.PaymentGroupCreditCard = PaymentGroupCreditCard;
