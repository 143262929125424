"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rental = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const React = require("react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const RentalPersonItem_1 = require("./rental-person-item/RentalPersonItem");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const FormField_1 = require("../common/form/form-field/FormField");
const FormConstants_1 = require("../../constants/FormConstants");
const common_types_1 = require("@as-react/common-types");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const AuthApi_1 = require("../../api/auth/AuthApi");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const DateField_1 = require("../../components/common/form/date-field/DateField");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
var RentalStatus;
(function (RentalStatus) {
    RentalStatus["LOGGED_OUT"] = "loggedOut";
    RentalStatus["LOADING"] = "loading";
    RentalStatus["FORM"] = "form";
    RentalStatus["SUBMIT"] = "submit";
    RentalStatus["FINISHED"] = "finished";
})(RentalStatus || (RentalStatus = {}));
const initialState = {
    rentalStatus: RentalStatus.LOADING,
    formValues: {
        period: '',
        people: [],
    },
    proficiencies: [],
    shopNumber: '',
};
const dateFieldData = {
    inline: true,
    startDate: new Date(),
    minDate: new Date(),
    range: true,
    view: DateField_1.FormFieldDateViews.MONTHS,
};
const PERSON_ID_NEW = -1;
class _Rental extends react_1.PureComponent {
    constructor(props) {
        super(props);
        this.componentDidMount = () => {
            this.getShopNumber();
            this.checkLoggedIn().then(() => {
                // logged in
                this.getRentalPeople();
                this.getProficiencies();
            }, () => {
                // logged out
                this.setRentalStatus(RentalStatus.LOGGED_OUT);
            });
        };
        this.componentDidUpdate = prevProps => {
            const { rentalStatus } = this.state;
            if (rentalStatus === RentalStatus.LOADING && this.isDataLoaded()) {
                this.setRentalStatus(RentalStatus.FORM);
            }
            if (rentalStatus === RentalStatus.FINISHED) {
                this.props.onLogout();
            }
        };
        this.setRentalStatus = (rentalStatus) => {
            this.setState({ rentalStatus });
        };
        this.getShopNumber = () => {
            const shopNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
            if (shopNumber) {
                this.setState({
                    shopNumber,
                });
            }
        };
        this.checkLoggedIn = () => {
            const { apiPrefix } = this.props;
            return AuthApi_1.default.isAuthenticated(apiPrefix);
        };
        this.isDataLoaded = () => {
            const { formValues, proficiencies } = this.state;
            const hasPeople = formValues.people && formValues.people.length > 0;
            const hasProficiencies = proficiencies && proficiencies.length > 0;
            return hasPeople && hasProficiencies;
        };
        this.getRentalPeople = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const { apiPrefix, defaultRequestParams } = this.props;
                const result = yield CustomerApi_1.default.getRentalPeople(apiPrefix, defaultRequestParams);
                let people = [];
                if (result && result.length > 0) {
                    people = result;
                }
                else {
                    const firstPerson = this.createEmptyRentalPerson();
                    firstPerson.primary = true;
                    people.push(firstPerson);
                }
                people.forEach(person => {
                    if (person.checkHeightAndWeight) {
                        person.placeholderHeight = person.height;
                        person.placeholderWeight = person.weight;
                        person.height = '';
                        person.weight = '';
                    }
                });
                this.setState({
                    formValues: Object.assign(Object.assign({}, this.state.formValues), { people: people }),
                });
            }
            catch (_a) { }
        });
        this.createEmptyRentalPerson = () => {
            return {
                id: PERSON_ID_NEW,
                birthYear: '',
                checkHeightAndWeight: false,
                firstName: '',
                height: '',
                primary: false,
                proficiencyId: '1',
                shoeSize: '',
                weight: '',
            };
        };
        this.getProficiencies = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { apiPrefix, defaultRequestParams, t } = this.props;
            try {
                const proficiencies = yield CustomerApi_1.default.getRentalProficiencies(apiPrefix, defaultRequestParams);
                proficiencies.forEach(proficiency => {
                    proficiency.translation = t(`rental:rental.proficiency.${proficiency.code}`);
                });
                this.setState({ proficiencies });
            }
            catch (_b) { }
        });
        this.insertRentalPerson = (person) => {
            const { apiPrefix, defaultRequestParams } = this.props;
            return CustomerApi_1.default.insertRentalPerson(apiPrefix, defaultRequestParams, person);
        };
        this.updateRentalPerson = (person) => {
            const { apiPrefix, defaultRequestParams } = this.props;
            return CustomerApi_1.default.updateRentalPerson(apiPrefix, defaultRequestParams, person);
        };
        this.deleteRentalPerson = (person) => {
            const { apiPrefix, defaultRequestParams } = this.props;
            return CustomerApi_1.default.deleteRentalPerson(apiPrefix, defaultRequestParams, person);
        };
        this.onDeletePersonClick = (person) => {
            if (person.id !== PERSON_ID_NEW) {
                this.deleteRentalPerson(person);
            }
        };
        this.onSubmitClick = () => {
            ScrollUtil_1.ScrollUtil.scrollToTop(true);
        };
        this.submit = values => {
            if (!values && !values.period && !values.people && values.people.length < 1) {
                return;
            }
            values.people.forEach((person, index) => {
                person.primary = index === 0;
            });
            values.people.forEach(person => {
                if (person.id === PERSON_ID_NEW) {
                    this.insertRentalPerson(person);
                }
                else {
                    this.updateRentalPerson(person);
                }
            });
            this.saveRentalData(values);
            this.setRentalStatus(RentalStatus.SUBMIT);
        };
        this.saveRentalData = (values) => {
            const { apiPrefix, defaultRequestParams } = this.props;
            const { shopNumber } = this.state;
            const data = this.buildRentalData(values);
            if (data && shopNumber) {
                CustomerApi_1.default.saveRentalData(apiPrefix, defaultRequestParams, shopNumber, data).then(() => {
                    this.setState({
                        rentalStatus: RentalStatus.FINISHED,
                        formValues: values,
                    });
                });
            }
        };
        this.buildRentalData = (values) => {
            const { proficiencies } = this.state;
            if (!(proficiencies && proficiencies.length > 0))
                return null;
            const period = values.period.split(FormConstants_1.FORM_FIELD_DATE_RANGE_SEPARATOR);
            let from = '';
            let until = '';
            if (period && period.length === 2) {
                from = this.formatDate(period[0]);
                until = this.formatDate(period[1]);
            }
            const people = values.people.map((person) => {
                const proficiency = proficiencies.find(proficiencyItem => proficiencyItem.id === parseInt(person.proficiencyId, 10));
                const experience = proficiency.code;
                return {
                    configuration: '',
                    experience: experience,
                    firstName: person.firstName,
                    height: parseInt(person.height, 10),
                    remarks: '',
                    shoeSize: person.shoeSize,
                    weight: person.weight,
                };
            });
            return {
                from: from,
                until: until,
                people: people,
            };
        };
        this.formatDate = (date) => {
            const separator = '-';
            return date.split(separator).reverse().join(separator);
        };
        this.validate = (values) => {
            return Object.assign(Object.assign({}, this.validatePeriod(values.period)), this.validatePeople(values.people));
        };
        this.validatePeriod = (period) => {
            const errors = {};
            if (!period || period.split(FormConstants_1.FORM_FIELD_DATE_RANGE_SEPARATOR).length < 2) {
                errors.period = common_types_1.ErrorMessage.REQUIRED;
            }
            return errors;
        };
        this.validatePeople = (people) => {
            const errors = {};
            people.forEach((person, index) => {
                const keyPrefix = `${FormConstants_1.RENTAL_FORM_PEOPLE_FIELD_ARRAY_NAME}.${index}.`;
                const requiredFields = [
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.firstName.name,
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.birthYear.name,
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.height.name,
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.weight.name,
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.shoeSize.name,
                    FormConstants_1.RENTAL_FORM_FIELD_KIND.proficiencyId.name,
                ];
                requiredFields.forEach(fieldName => {
                    if (!person[fieldName]) {
                        const key = `${keyPrefix}${fieldName}`;
                        errors[key] = common_types_1.ErrorMessage.REQUIRED;
                    }
                });
            });
            return errors;
        };
        this.renderInputFeedbackMessage = (values, withNames) => {
            return ((0, jsx_runtime_1.jsxs)(React.Fragment, { children: [this.renderPeriodMessage(values), this.renderPeopleCounter(values, withNames)] }));
        };
        this.renderPeriodMessage = values => {
            const { t } = this.props;
            let message = t('rental:rental.period.feedback.required');
            if (values.period) {
                const period = values.period.split(FormConstants_1.FORM_FIELD_DATE_RANGE_SEPARATOR);
                if (period && period.length === 2) {
                    message = t('rental:rental.period.feedback.info', {
                        from: period[0],
                        until: period[1],
                    });
                }
            }
            return (0, jsx_runtime_1.jsx)("div", { className: "o-rental__message", children: message });
        };
        this.renderPeopleCounter = (values, withNames) => {
            const { t } = this.props;
            let amountOfPeople = 0;
            let peopleNames = '';
            if (values.people) {
                amountOfPeople = values.people.length;
                peopleNames = values.people.map(person => person.firstName).join(', ');
            }
            const translationKey = amountOfPeople === 1 ? 'single' : 'multiple';
            return ((0, jsx_runtime_1.jsxs)("div", { className: "o-rental__message", children: [amountOfPeople, " ", t(`rental:rental.person.counter.${translationKey}`), withNames && `: ${peopleNames}`] }));
        };
        this.renderForm = () => {
            const { t } = this.props;
            const { formValues, proficiencies } = this.state;
            return ((0, jsx_runtime_1.jsx)("div", { className: "o-rental", children: (0, jsx_runtime_1.jsx)(formik_1.Formik, { initialValues: formValues, onSubmit: this.submit, validate: this.validate, validateOnBlur: true, children: props => ((0, jsx_runtime_1.jsxs)(formik_1.Form, { className: "o-rental__form", children: [(0, jsx_runtime_1.jsxs)("section", { className: "o-rental__section o-rental__section--period", children: [(0, jsx_runtime_1.jsx)(FormField_1.default, { formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.period, data: dateFieldData }), (0, jsx_runtime_1.jsx)("div", { className: "o-rental__message", children: (0, jsx_runtime_1.jsx)("span", { className: "a-text", children: t('rental:rental.text.form') }) })] }), (0, jsx_runtime_1.jsxs)("section", { className: "o-rental__section o-rental__section--people", children: [(0, jsx_runtime_1.jsx)("label", { className: "a-label", children: t('rental:rental.title.people') }), (0, jsx_runtime_1.jsx)(formik_1.FieldArray, { name: FormConstants_1.RENTAL_FORM_PEOPLE_FIELD_ARRAY_NAME, render: arrayHelpers => ((0, jsx_runtime_1.jsxs)(React.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "o-rental__people-list", children: props.values.people &&
                                                        props.values.people.map((person, index) => ((0, jsx_runtime_1.jsx)(RentalPersonItem_1.default, { index: index, person: person, proficiencies: proficiencies, onDeleteClick: () => {
                                                                arrayHelpers.remove(index);
                                                                this.onDeletePersonClick(person);
                                                            } }, index))) }), (0, jsx_runtime_1.jsx)("div", { className: "o-rental__action", children: (0, jsx_runtime_1.jsx)("div", { className: "m-form-control", children: (0, jsx_runtime_1.jsx)("button", { className: "a-button a-button--secondary a-button--icon-add", type: "button", onClick: () => arrayHelpers.push(this.createEmptyRentalPerson()), children: t('rental:rental.person.add') }) }) })] })) })] }), (0, jsx_runtime_1.jsxs)("section", { className: "o-rental-section o-rental__section--save", children: [this.renderInputFeedbackMessage(props.values), (0, jsx_runtime_1.jsx)("div", { className: "o-rental__action", children: (0, jsx_runtime_1.jsx)("div", { className: "m-form-control", children: (0, jsx_runtime_1.jsx)("button", { className: "a-button a-button--primary a-button--icon-save", type: "submit", onClick: this.onSubmitClick, children: t('rental:rental.submit') }) }) })] })] })) }) }));
        };
        this.renderSpinner = () => {
            return ((0, jsx_runtime_1.jsx)("div", { className: "o-rental o-rental--loading", children: (0, jsx_runtime_1.jsx)("svg", { className: "spinner spinner--position-relative", viewBox: "0 0 40 40", children: (0, jsx_runtime_1.jsx)("circle", { className: "path", cx: "20", cy: "20", r: "15", fill: "none", strokeWidth: "5" }) }) }));
        };
        this.renderLoggedOut = () => {
            const { t } = this.props;
            return ((0, jsx_runtime_1.jsx)("div", { className: "o-rental", children: (0, jsx_runtime_1.jsx)("p", { className: "a-text", children: t('rental:rental.message.loggedOut') }) }));
        };
        this.renderFinished = () => {
            const { t } = this.props;
            const { formValues } = this.state;
            return ((0, jsx_runtime_1.jsxs)("div", { className: "o-rental", children: [(0, jsx_runtime_1.jsx)("div", { className: "a-title", children: t('rental:rental.message.finished') }), this.renderInputFeedbackMessage(formValues, true)] }));
        };
        this.state = initialState;
    }
    render() {
        const { rentalStatus } = this.state;
        switch (rentalStatus) {
            case RentalStatus.LOGGED_OUT:
                return this.renderLoggedOut();
            case RentalStatus.LOADING:
            case RentalStatus.SUBMIT:
                return this.renderSpinner();
            case RentalStatus.FORM:
                return this.renderForm();
            case RentalStatus.FINISHED:
            default:
                return this.renderFinished();
        }
    }
}
exports.Rental = (0, react_i18next_1.withTranslation)(['rental'])(_Rental);
