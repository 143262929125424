"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVideoPlayerType = exports.getYouTubeVideoUrl = exports.getYouTubeId = exports.getVimeoId = exports.injectVimeoAPI = exports.injectYoutubeAPI = exports.setupYouTubeIframeAPIReadyHandler = exports.addYoutubeReadyEvent = exports.addVimeoReadyEvent = void 0;
const common_types_1 = require("@as-react/common-types");
const YOUTUBE_IFRAME_API = 'https://www.youtube.com/iframe_api';
const VIMEO_PLAYER_SRC = 'https://player.vimeo.com/api/player.js';
const vimeoLoadQueue = [];
let vimeoIsReady = false;
let youtubeLoadQueue = [];
const addVimeoReadyEvent = (callback) => {
    if (vimeoIsReady) {
        callback();
    }
    else {
        vimeoLoadQueue.push(callback);
    }
};
exports.addVimeoReadyEvent = addVimeoReadyEvent;
const onVimeoAPIReady = () => {
    vimeoIsReady = true;
    vimeoLoadQueue.forEach(callback => {
        callback();
    });
};
const addYoutubeReadyEvent = (callback) => {
    if (window.youtubeIsReady) {
        callback();
    }
    else {
        youtubeLoadQueue.push(callback);
    }
};
exports.addYoutubeReadyEvent = addYoutubeReadyEvent;
const handleYouTubeIframeAPIReady = () => {
    window.youtubeIsReady = true;
    youtubeLoadQueue.forEach(callback => callback());
    youtubeLoadQueue = [];
};
const setupYouTubeIframeAPIReadyHandler = () => {
    if (window.youtubeIsReady) {
        handleYouTubeIframeAPIReady();
    }
    else if (window.onYouTubeIframeAPIReady) {
        const originalHandler = window.onYouTubeIframeAPIReady;
        window.onYouTubeIframeAPIReady = () => {
            handleYouTubeIframeAPIReady();
            originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler();
        };
    }
    else {
        window.onYouTubeIframeAPIReady = handleYouTubeIframeAPIReady;
    }
};
exports.setupYouTubeIframeAPIReadyHandler = setupYouTubeIframeAPIReadyHandler;
const injectYoutubeAPI = () => {
    if (document.querySelectorAll(`script[src="${YOUTUBE_IFRAME_API}"]`).length === 0) {
        const tag = document.createElement('script');
        tag.src = YOUTUBE_IFRAME_API;
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};
exports.injectYoutubeAPI = injectYoutubeAPI;
const injectVimeoAPI = () => {
    if (document.querySelectorAll(`script[src="${VIMEO_PLAYER_SRC}"]`).length === 0) {
        const tag = document.createElement('script');
        tag.src = VIMEO_PLAYER_SRC;
        tag.onload = onVimeoAPIReady;
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};
exports.injectVimeoAPI = injectVimeoAPI;
const getVimeoId = (vimeoUrl) => {
    const match = /vimeo.*\/(\d+)/i.exec(vimeoUrl);
    return match ? Number(match[1]) : undefined;
};
exports.getVimeoId = getVimeoId;
const getYouTubeId = (url) => {
    const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);
    return match ? match[2] : undefined;
};
exports.getYouTubeId = getYouTubeId;
const getYouTubeVideoUrl = (videoId) => `https://www.youtube-nocookie.com/embed/${videoId}?wmode=opaque&rel=0&enablejsapi=1`;
exports.getYouTubeVideoUrl = getYouTubeVideoUrl;
const getVideoPlayerType = (url) => {
    if (!url) {
        return null;
    }
    else if (url.includes('youtube')) {
        return common_types_1.VideoPlayerTypes.YOUTUBE;
    }
    else if (url.includes('vimeo')) {
        return common_types_1.VideoPlayerTypes.VIMEO;
    }
    else {
        return common_types_1.VideoPlayerTypes.NATIVE;
    }
};
exports.getVideoPlayerType = getVideoPlayerType;
