"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basketReducer = exports.initialState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const common_types_1 = require("@as-react/common-types");
exports.initialState = {
    basketId: null,
    deliveryInfo: {
        amountUntilFreeDelivery: null,
        delivery: '',
        deliveryMessages: {
            HOME_DELIVERY: '',
        },
        deliveryUnit: '',
        hasEvoucher: false,
        requiresDelivery: true,
    },
    popup: {
        collapsed: true,
        basketProductData: null,
        status: undefined,
    },
    ccPopup: {
        collapsed: true,
    },
    validationState: undefined,
    products: [],
    mappedProducts: [],
    promotionProducts: [],
    pricing: {
        priceDelivery: 0,
        priceTotalBasket: 0,
        totalBasketDiscountAmount: 0,
        totalPriceSell: 0,
        totalPriceSellBeforeDiscount: 0,
    },
    status: common_types_1.BasketStatus.LOADING,
    totalQuantity: 0,
    vouchers: [],
    validateRegex: '',
    popupMessages: [],
    popupMessagesEnriched: [],
    paybackVoucherMessages: [],
    hasUnavailableProducts: false,
    orderLinesHaveDifferentDeliveryPromises: false,
    clickAndCollectStoreId: null,
};
const basketReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.BasketActionTypes.CC_POPUP_OPEN:
            return Object.assign(Object.assign({}, state), { ccPopup: Object.assign(Object.assign({}, state.ccPopup), { collapsed: false }) });
        case ActionEnums_1.BasketActionTypes.CC_POPUP_CLOSE:
            return Object.assign(Object.assign({}, state), { ccPopup: Object.assign(Object.assign({}, state.ccPopup), { collapsed: true }) });
        case ActionEnums_1.BasketActionTypes.POPUP_OPEN:
            return Object.assign(Object.assign({}, state), { popup: {
                    collapsed: false,
                    basketProductData: action.payload.basketProductData,
                    status: action.payload.status,
                    errorCode: action.payload.errorCode,
                    isBulk: action.payload.isBulk,
                    price: action.payload.price,
                }, validationState: undefined });
        case ActionEnums_1.BasketActionTypes.POPUP_CLOSE:
            return Object.assign(Object.assign({}, state), { popup: Object.assign({}, exports.initialState.popup) });
        case ActionEnums_1.BasketActionTypes.SET:
            return Object.assign(Object.assign({}, state), action.payload);
        case ActionEnums_1.BasketActionTypes.SET_BASIC:
            return Object.assign(Object.assign({}, state), { basketId: action.payload.basketId, totalQuantity: action.payload.totalQuantity });
        case ActionEnums_1.BasketActionTypes.STATUS_UPDATE:
            return Object.assign(Object.assign({}, state), { popup: Object.assign(Object.assign({}, state.popup), { status: action.payload.status, errorCode: action.payload.errorCode }) });
        case ActionEnums_1.BasketActionTypes.ERROR_SET:
            return Object.assign(Object.assign({}, state), { validationState: action.payload });
        case ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY_RESULT: {
            const { product } = action.payload;
            return Object.assign(Object.assign({}, state), { productQuantity: {
                    amountUnableToReserve: product.amountUnableToReserve,
                    availableQuantity: product.availableQuantity,
                    requestedQuantity: product.requestedQuantity,
                } });
        }
        case ActionEnums_1.BasketActionTypes.UPDATE_ITEMS_ERROR_STATE: {
            const { mappedProducts } = state;
            const { product: targetProduct } = action.payload;
            if (!targetProduct) {
                mappedProducts.forEach((mappedProduct) => (mappedProduct.errorState = action.payload.errorState));
            }
            else {
                const mappedProduct = mappedProducts.find(pr => pr.productCode === targetProduct.productCode &&
                    pr.colorId === targetProduct.colorId &&
                    String(pr.sku) === targetProduct.sku);
                if (mappedProduct) {
                    mappedProduct.errorState = action.payload.errorState;
                }
            }
            return Object.assign(Object.assign({}, state), { mappedProducts: [...mappedProducts] });
        }
        case ActionEnums_1.BasketActionTypes.HAS_UNAVAILABLE_PRODUCTS_SET:
            return Object.assign(Object.assign({}, state), { hasUnavailableProducts: action.payload });
        default:
            return state;
    }
};
exports.basketReducer = basketReducer;
