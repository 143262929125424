"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoginError = void 0;
const common_types_1 = require("@as-react/common-types");
const AuthSocialLoginFacebookApi_1 = require("../api/auth/AuthSocialLoginFacebookApi");
const getLoginError = (error, t, mainWebShop) => {
    if (!error) {
        return null;
    }
    else if (Object.values(common_types_1.ErrorMessage).includes(error)) {
        return t(mainWebShop ? error.replace(common_types_1.ERROR_MESSAGE_FASCIA_PLACEHOLDER, mainWebShop) : error);
    }
    else {
        switch (error) {
            case AuthSocialLoginFacebookApi_1.FacebookErrorsEnum.PERMISSION_EMAIL:
                return t('account:account.social.media.login.error.permission.email.retry');
            case AuthSocialLoginFacebookApi_1.FacebookErrorsEnum.ALREADY_EXIST:
                return t('account:account.social.media.connections.error.already.connected', {
                    platform: 'Facebook',
                });
            case AuthSocialLoginFacebookApi_1.FacebookErrorsEnum.EMAIL_MISMATCH:
                return t('account:account.social.media.login.email.mismatch');
            default:
                return t('account:account.error.general');
        }
    }
};
exports.getLoginError = getLoginError;
