"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrderedItemsForAuthenticatedUserSaga = exports.getOrderedItemsForAuthenticatedUser = void 0;
const SagaContextSelectors_1 = require("./../../SagaContextSelectors");
const effects_1 = require("redux-saga/effects");
const OrdersApi_1 = require("../../../api/OrdersApi");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const OrdersActions_creators_1 = require("../actions/OrdersActions.creators");
const SessionUtil_1 = require("../../../util/SessionUtil");
const CustomerActions_creators_1 = require("../../customer/actions/CustomerActions.creators");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const common_types_1 = require("@as-react/common-types");
const getIsAuthenticated = state => (0, SessionUtil_1.isLoggedInUser)(state.session);
function* getOrderedItemsForAuthenticatedUser() {
    try {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.PENDING));
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const AuthEndpointUrl = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const items = yield (0, effects_1.call)(OrdersApi_1.default.getSales, AuthEndpointUrl, defaultRequestParams);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItems(items));
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.RESOLVED));
    }
    catch (_a) {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.REJECTED));
    }
}
exports.getOrderedItemsForAuthenticatedUser = getOrderedItemsForAuthenticatedUser;
function* getOrderedItemsForGuestUser() {
    try {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.PENDING));
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const OrderHistoryEndpointUrl = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const items = yield (0, effects_1.call)(OrdersApi_1.default.getSale, OrderHistoryEndpointUrl, defaultRequestParams);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItems(items));
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.RESOLVED));
    }
    catch (_a) {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setOrderedItemsState(common_types_1.FetchStatus.REJECTED));
    }
}
function* getReturnItems() {
    try {
        const isAuthenticated = yield (0, effects_1.select)(getIsAuthenticated);
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const items = yield (0, effects_1.call)(isAuthenticated ? OrdersApi_1.default.getReturnables : OrdersApi_1.default.getSale, serviceEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setReturnItems(items));
        if (isAuthenticated) {
            yield (0, effects_1.put)(CustomerActions_creators_1.default.getProfile());
        }
    }
    catch (_a) { }
}
function* getReturnReasons() {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const items = yield (0, effects_1.call)(OrdersApi_1.default.getReturnReasons, serviceEndpoint, defaultRequestParams.market, defaultRequestParams.language);
    yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setReturnReasons(items));
}
function* submitReturnItems({ payload }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const isAuthenticated = yield (0, effects_1.select)(getIsAuthenticated);
    const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    try {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setSubmitReturnItemsState(common_types_1.FetchStatus.PENDING));
        const announcedReturn = yield (0, effects_1.call)(isAuthenticated ? OrdersApi_1.default.registerReturnAnnounce : OrdersApi_1.default.registerReturnAnnounceGuest, serviceEndpoint, defaultRequestParams, payload);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setAnnouncedReturn(announcedReturn));
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setSubmitReturnItemsState(common_types_1.FetchStatus.RESOLVED));
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.returnAnnounced(String(payload.returnOptionId)));
    }
    catch (_a) {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setSubmitReturnItemsState(common_types_1.FetchStatus.REJECTED));
    }
}
function* updateAnnouncedReturn({ payload }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    try {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setUpdateAnnouncedReturnState(common_types_1.FetchStatus.PENDING));
        yield (0, effects_1.call)(OrdersApi_1.default.updateReturnMethod, serviceEndpoint, defaultRequestParams, payload.returnAnnounceId, payload.returnOptionId, payload.returnCountryId);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.getReturnedSales());
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setUpdateAnnouncedReturnState(common_types_1.FetchStatus.RESOLVED));
    }
    catch (_a) {
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setUpdateAnnouncedReturnState(common_types_1.FetchStatus.REJECTED));
    }
}
function* getReturnedSales() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const isAuthenticated = yield (0, effects_1.select)(getIsAuthenticated);
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const items = yield (0, effects_1.call)(isAuthenticated ? OrdersApi_1.default.getReturnedSales : OrdersApi_1.default.getReturnedSalesGuest, serviceEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(OrdersActions_creators_1.ordersActions.setReturnedSales(items));
    }
    catch (_a) { }
}
function* getOrderedItemsForAuthenticatedUserSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.GET_ORDERED_ITEMS_FOR_AUTHENTICATED_USER, getOrderedItemsForAuthenticatedUser);
}
exports.getOrderedItemsForAuthenticatedUserSaga = getOrderedItemsForAuthenticatedUserSaga;
function* getOrderedItemsForGuestUserSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.GET_ORDERED_ITEMS_FOR_GUEST_USER, getOrderedItemsForGuestUser);
}
function* getReturnItemsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.GET_RETURN_ITEMS, getReturnItems);
}
function* getReturnReasonsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.GET_RETURN_REASONS, getReturnReasons);
}
function* submitReturnItemsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.SUBMIT_RETURN_ITEMS, submitReturnItems);
}
function* updateAnnouncedReturnSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.UPDATE_RETURN_REASON, updateAnnouncedReturn);
}
function* getAnnouncedReturnsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.OrdersActionsTypes.GET_RETURNED_SALES, getReturnedSales);
}
function* ordersSaga() {
    yield (0, effects_1.all)([
        getOrderedItemsForAuthenticatedUserSaga(),
        getOrderedItemsForGuestUserSaga(),
        getReturnItemsSaga(),
        getReturnReasonsSaga(),
        submitReturnItemsSaga(),
        updateAnnouncedReturnSaga(),
        getAnnouncedReturnsSaga(),
    ]);
}
exports.default = ordersSaga;
