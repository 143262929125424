"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Basket_module_1 = require("./modules/Basket.module");
const Checkout_module_1 = require("./modules/Checkout.module");
const CookieConsent_module_1 = require("./modules/CookieConsent.module");
const PromoBasketPopup_module_1 = require("./modules/PromoBasketPopup.module");
const Product_module_1 = require("./modules/Product.module");
const DeliveryPromise_module_1 = require("./modules/DeliveryPromise.module");
const ProductGroupFredhopper_module_1 = require("./modules/ProductGroupFredhopper.module");
const Customer_module_1 = require("./modules/Customer.module");
const loadBasketMenuItemModule = initialData => ({
    hasRouter: false,
    modules: [
        (0, CookieConsent_module_1.default)(),
        (0, Basket_module_1.default)(),
        (0, Checkout_module_1.default)(),
        (0, PromoBasketPopup_module_1.default)(),
        (0, Product_module_1.default)(),
        (0, DeliveryPromise_module_1.default)(),
        (0, Customer_module_1.default)(),
        (0, ProductGroupFredhopper_module_1.default)(initialData),
    ],
});
exports.default = loadBasketMenuItemModule;
