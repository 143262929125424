"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PromoBasketPopupReducer_1 = require("../../promo-basket-popup/reducers/PromoBasketPopupReducer");
const loadPromoBasketPopupModule = () => ({
    id: 'PromoBasketPopup',
    reducerMap: {
        promoBasketPopup: PromoBasketPopupReducer_1.promoBasketPopupReducer,
    },
    sagas: [],
});
exports.default = loadPromoBasketPopupModule;
