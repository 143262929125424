"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddReviewContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const AddReviewBanner_1 = require("../components/add-review/add-review-banner/AddReviewBanner");
const AddReviewForm_1 = require("../components/add-review/add-review-form/AddReviewForm");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ReviewsApi_1 = require("../api/ReviewsApi");
const ScrollUtil_1 = require("../util/ScrollUtil");
const AddReviewContainer = ({ title, description }) => {
    const addReviewFormRef = (0, react_1.useRef)(null);
    const [showForm, setShowForm] = (0, react_1.useState)(false);
    const [showLoggedOffMessage, setShowLoggedOffMessage] = (0, react_1.useState)(false);
    const [submitted, setSubmitted] = (0, react_1.useState)(false);
    const [submitting, setSubmitting] = (0, react_1.useState)(false);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const productId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)()).productId;
    const customerUUID = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUUID)());
    const customerName = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetFirstName)());
    const onCancel = () => {
        setShowForm(false);
    };
    const onShowReviewForm = () => {
        setShowForm(loggedIn);
        setShowLoggedOffMessage(!showForm && !loggedIn);
    };
    const scrollToAddReview = () => {
        if (addReviewFormRef.current) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(addReviewFormRef.current, isDesktop);
        }
    };
    const addReview = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ rating, advantages, disadvantages }) {
        const reviewData = {
            productId,
            customerUUID,
            reviewTitle: title,
            score: rating,
            reviewTextPositive: advantages,
            reviewTextNegative: disadvantages,
            customerName,
        };
        setSubmitting(true);
        if (customerUUID) {
            yield ReviewsApi_1.default.postReview(reviewData, defaultRequestParameters, servicesEndpoint);
            setSubmitted(true);
            setSubmitting(false);
            setShowForm(false);
            scrollToAddReview();
        }
    });
    return ((0, jsx_runtime_1.jsxs)("div", { ref: addReviewFormRef, children: [!showForm && !submitted && ((0, jsx_runtime_1.jsx)(AddReviewBanner_1.default, { onShowReviewForm: onShowReviewForm, title: title, description: description })), showForm && loggedIn && ((0, jsx_runtime_1.jsx)(AddReviewForm_1.AddReviewForm, { addReview: addReview, onCancel: onCancel, submitting: submitting, boxTheme: cotopaxi_1.BoxTheme.TERTIARY })), showLoggedOffMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { align: cotopaxi_1.FeedbackMessageAlign.CENTER, text: t('product.review.logged.off'), variant: cotopaxi_1.FeedbackMessageVariant.INFO })), submitted && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { align: cotopaxi_1.FeedbackMessageAlign.CENTER, text: t('product.review.add.banner.output.success'), variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }))] }));
};
exports.AddReviewContainer = AddReviewContainer;
