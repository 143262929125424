"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftVoucherMainSlider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ImageUtil_1 = require("../../util/ImageUtil");
const GiftVoucherMainSlider = ({ activeSlideIndex, brand, images, onActiveSlideIndexChange, hideImageOverlay, imageCdnPrefix, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [swiper, setSwiper] = (0, react_1.useState)(null);
    const onGallerySlideChange = function () {
        onActiveSlideIndexChange(this.activeIndex);
    };
    const renderSlide = (0, react_1.useCallback)((image, index) => {
        const imagePath = (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix, '1024');
        return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.GiftCardMainSlide, { imagePath: imagePath }) }, `gift_voucher_main_${index}`));
    }, [images]);
    const giftVoucherSlideOverlay = !hideImageOverlay ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginLeft: 2 } }, desktop: { spacing: { marginTop: 3, marginLeft: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.XLARGE, children: brand }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.XLARGE, children: t('voucher.cadeaucheque') })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
    const giftVoucherSlideConfig = {
        variant: cotopaxi_1.CarouselVariant.GIFT_CARD_MAIN,
        showPagination: false,
        configurationOverrides: {
            getSwiper: setSwiper,
            watchOverflow: true,
            centeredSlides: true,
            effect: 'fade',
            on: {
                slideChange: onGallerySlideChange,
            },
        },
        overlay: giftVoucherSlideOverlay,
    };
    (0, react_1.useEffect)(() => {
        if (swiper !== null) {
            swiper.slideTo(activeSlideIndex);
        }
    }, [activeSlideIndex]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, giftVoucherSlideConfig, { dataQA: "gift_voucher_main_slider", children: images.map(renderSlide) })));
};
exports.GiftVoucherMainSlider = GiftVoucherMainSlider;
