"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CustomerSagas_1 = require("../../../redux/customer/sagas/CustomerSagas");
const CustomerReducer_1 = require("../../../redux/customer/reducers/CustomerReducer");
const loadCustomerModule = () => ({
    id: 'Customer',
    reducerMap: {
        customer: CustomerReducer_1.customerReducer,
    },
    sagas: [CustomerSagas_1.default],
});
exports.default = loadCustomerModule;
