"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterWizardItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterWizardItem = ({ checked, description, filterKey, imageURL, itemCount, multiSelect, onChange, title, }) => {
    const Selector = multiSelect ? cotopaxi_1.CheckboxWithLabel : cotopaxi_1.RadioButtonWithLabel;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, tablet: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1, paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(Selector, { id: filterKey, name: filterKey, value: filterKey, checked: checked, onChange: onChange, labelChildren: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.SUBTLE, size: cotopaxi_1.TextSize.SMALL, children: `(${itemCount})` }) })] }) }) }), !!imageURL && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, tablet: { spacing: { paddingTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: imageURL, lazy: true }) })), !!description && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: description }) }))] }));
};
exports.FilterWizardItem = FilterWizardItem;
