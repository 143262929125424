"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIMEZONE_BY_MARKET = exports.FILTER_SIZE_SEPARATOR = exports.FASCIA_GROUP = exports.FASCIA_AND_MAIN_WEB_SHOP = exports.DateFormat = exports.GLOSSARY_KEY = exports.CUSTOM_OBJECT_COUNTRIES = exports.PLACEHOLDER_TILES = exports.DELAY = void 0;
exports.DELAY = {
    DELAY_0: 0,
    DELAY_20: 20,
    DELAY_100: 100,
    DELAY_250: 250,
    DELAY_300: 300,
    DELAY_500: 500,
    DELAY_1000: 1000,
    DELAY_1500: 1500,
    DELAY_2000: 2000,
    DELAY_3000: 3000,
    DELAY_5000: 5000,
};
exports.PLACEHOLDER_TILES = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
exports.CUSTOM_OBJECT_COUNTRIES = 'SITE.data.countries';
exports.GLOSSARY_KEY = 'glossary';
exports.DateFormat = {
    DD_MM_YYYY: {
        value: 'dd/mm/yyyy',
    },
    YYYY_MM_DD: {
        value: 'yyyy/mm/dd',
    },
};
exports.FASCIA_AND_MAIN_WEB_SHOP = {
    '1_BEVER': 'bever',
    '2_TNF': 'tnf',
    '3_FdW': 'fdw',
    '1_ASL': 'asadventure',
    '2_Juttu': 'juttu',
    '3_TNF': 'thenorthface',
    '4_YAYA': 'yaya',
    '5_OE': 'outdoor-exclusive',
    '1_CW': 'cotswold',
    '2_SR': 'snowandrock',
    '3_CS': 'cyclesurgery',
    '4_RN': 'runnersneed',
};
exports.FASCIA_GROUP = {
    asadventure: 'Retail concepts n.v.',
    juttu: 'Retail concepts n.v.',
    bever: 'Bever B.V.',
    snowandrock: 'Outdoor & Cycle Concepts Ltd.',
    runnersneed: 'Outdoor & Cycle Concepts Ltd.',
    cotswold: 'Outdoor & Cycle Concepts Ltd.',
};
exports.FILTER_SIZE_SEPARATOR = '##';
var TIMEZONE_BY_MARKET;
(function (TIMEZONE_BY_MARKET) {
    TIMEZONE_BY_MARKET["nl"] = "Europe/Amsterdam";
    TIMEZONE_BY_MARKET["be"] = "Europe/Brussels";
    TIMEZONE_BY_MARKET["uk"] = "Europe/London";
})(TIMEZONE_BY_MARKET || (exports.TIMEZONE_BY_MARKET = TIMEZONE_BY_MARKET = {}));
