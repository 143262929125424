"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EticketItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const ImageUtil_1 = require("../../../util/ImageUtil");
const CurrencyUtil_1 = require("../../../util/CurrencyUtil");
const ProductTileCompact_1 = require("../../product-tile/compact/ProductTileCompact");
const EticketItem = ({ saleLine, currency }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImages = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const rootWithLanguageSuffix = (0, ConfigurationUtil_1.getRootWithLanguageSuffix)(aemConfiguration);
    const productUrl = saleLine.url
        ? saleLine.colourId
            ? `${rootWithLanguageSuffix}/p${saleLine.url}.${saleLine.colourId}.html`
            : `${rootWithLanguageSuffix}/p${saleLine.url}.html`
        : '';
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, { asStatic: !productUrl, withoutInnerSpacing: true, brand: saleLine.brand, title: saleLine.productDescription, quantity: saleLine.quantity, url: productUrl, image: saleLine.picture
                ? (0, ImageUtil_1.getImageSourceSetByProductCode)(saleLine.productCode, saleLine.picture, productImages)[common_types_1.ProductImageSizesPortraitEnum.bundles]
                : '/content/dam/general/imagenotavailable.png', imageSize: "large", sizeDescription: saleLine.productSize, colorDescription: saleLine.productColour, currency: (0, CurrencyUtil_1.convertToCurrencySymbol)(currency) || currency, priceActual: { formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(saleLine.totalSellPrice) } }) }));
};
exports.EticketItem = EticketItem;
