"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductGroupPdpComparison = exports.GRID_AREAS = exports.GRID_BOX_PARAMS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ScreenWidthConstants_1 = require("../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../constants/SliderConstants");
const react_i18next_1 = require("react-i18next");
const ProductGroupPdpComparisonProduct_1 = require("./ProductGroupPdpComparisonProduct");
const useProductTileHelpers_1 = require("../../hooks/useProductTileHelpers");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const common_utils_1 = require("@as-react/common-utils");
const useProductGroupAnalytics_1 = require("../../hooks/useProductGroupAnalytics");
const ProductUtil_1 = require("../../util/ProductUtil");
const SLIDES_AMOUNT_MOBILE = 1.5;
const SLIDES_AMOUNT_TABLET = 3.5;
const MAX_PRODUCTS_SIZE = 5;
const MAX_FEATURES_SIZE = 5;
var GRID_SPACING;
(function (GRID_SPACING) {
    GRID_SPACING[GRID_SPACING["MOBILE"] = 1] = "MOBILE";
    GRID_SPACING[GRID_SPACING["TABLET"] = 2] = "TABLET";
})(GRID_SPACING || (GRID_SPACING = {}));
exports.GRID_BOX_PARAMS = {
    mobile: {
        spacing: {
            marginRight: GRID_SPACING.MOBILE,
            marginLeft: GRID_SPACING.MOBILE,
        },
    },
    tablet: {
        spacing: {
            marginRight: GRID_SPACING.TABLET,
            marginLeft: GRID_SPACING.TABLET,
        },
    },
};
var GRID_AREAS;
(function (GRID_AREAS) {
    GRID_AREAS["PRODUCT"] = "product";
    GRID_AREAS["PRODUCT_TILE"] = "tile";
    GRID_AREAS["FEATURE"] = "feature";
    GRID_AREAS["FEATURE_TITLE"] = "title";
    GRID_AREAS["FEATURE_VALUE"] = "value";
    GRID_AREAS["COMPARE"] = "compare";
})(GRID_AREAS || (exports.GRID_AREAS = GRID_AREAS = {}));
const repeat = (count, prefix, children = null) => {
    if (!children) {
        return Array.from({ length: count }, (_, index) => (count > 1 ? `${prefix}-${index}` : `${prefix}`));
    }
    else {
        return Array.from({ length: count }, (_, index) => children.map(child => `${prefix}-${index}-${child}`)).flat(1);
    }
};
const generateGridTemplateAreas = (columnStructure, rowStructure) => {
    let templateAreas = '';
    const rows = rowStructure.flat(2);
    const columns = columnStructure.flat(2);
    rows.forEach(row => {
        let templateAreaRow = '"';
        columns.forEach(col => {
            templateAreaRow += `${col}-${row} `;
        });
        templateAreas += templateAreaRow.trim() + '"\n';
    });
    return templateAreas.trim();
};
const COMPARE_PRODUCT_INDEX_ADDITION = 1;
const GRID_TEMPLATE_AREAS = generateGridTemplateAreas([repeat(MAX_PRODUCTS_SIZE, GRID_AREAS.PRODUCT)], [
    [GRID_AREAS.PRODUCT_TILE],
    repeat(MAX_FEATURES_SIZE, GRID_AREAS.FEATURE, [GRID_AREAS.FEATURE_TITLE, GRID_AREAS.FEATURE_VALUE]),
    [GRID_AREAS.COMPARE],
]);
const ProductGroupPdpComparison = ({ analyticsAreaName, productGroupId, products, title, productsFeaturesRecord, analyticsProductGroupName, currentProductHasFeatures, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    const { handleWishlistToggle, handleUpdateCompareList, compareItems } = (0, useProductTileHelpers_1.useProductTileHelpers)();
    const productUniqueKey = (0, react_1.useId)();
    const productDetail = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProduct)());
    const productGroupRef = (0, react_1.useRef)(null);
    const { handleItemClickAnalytics } = (0, useProductGroupAnalytics_1.useProductGroupAnalytics)(Boolean(analyticsProductGroupName), productGroupRef, analyticsProductGroupName, title, products);
    if (!(products === null || products === void 0 ? void 0 : products.length)) {
        return null;
    }
    const currentProduct = currentProductHasFeatures && productDetail && productDetail.productColorVariations
        ? (0, ProductUtil_1.createProductDetailToProductListerAdapter)(productDetail)
        : null;
    const productsWithFeatures = (0, common_utils_1.mergeProductFeatures)(currentProduct ? [currentProduct, ...products] : products, productsFeaturesRecord, MAX_PRODUCTS_SIZE, currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.productId, MAX_FEATURES_SIZE);
    const createCurrentProductTag = () => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.SEPTENARY, children: t('product.group.current.tag') }, "current_product_tag"));
    const carouselConfig = {
        variant: cotopaxi_1.CarouselVariant.PRODUCT_TILE_COMPARISON,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: false,
        fullWidth: true,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: SLIDES_AMOUNT_MOBILE,
            spaceBetween: 0,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView: SLIDES_AMOUNT_TABLET,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP_LARGE,
                },
            },
        },
    };
    const mainBoxParams = {
        mobile: {
            spacing: {
                paddingBottom: 0,
                paddingTop: 2,
            },
        },
        tablet: {
            spacing: {
                paddingBottom: 6,
                paddingTop: 0,
                marginTop: 0,
            },
        },
        desktop: { spacing: { paddingBottom: 7 } },
        dataAttributes: { 'data-analytics-bannerid': analyticsAreaName },
    };
    const innerBoxParams = {
        mobile: {
            spacing: {
                paddingBottom: 3,
                paddingTop: 2,
            },
            bleed: {
                marginInline: GRID_SPACING.MOBILE,
            },
        },
        tablet: {
            spacing: {
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: 3,
            },
            bleed: {
                marginInline: GRID_SPACING.TABLET,
            },
        },
        desktop: { spacing: { marginTop: 3 } },
    };
    const gridAreasStyle = {
        '--pdp-comparison-grid-template-areas': GRID_TEMPLATE_AREAS,
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({ innerRef: productGroupRef }, mainBoxParams, { children: [title && (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SUBTITLE, children: title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, innerBoxParams, { children: !!productsWithFeatures && ((0, jsx_runtime_1.jsx)("div", { style: gridAreasStyle, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: productsWithFeatures.map((compareProduct, productIndex) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { desktop: SliderConstants_1.SLIDES_AMOUNT_DESKTOP, children: (0, jsx_runtime_1.jsx)(ProductGroupPdpComparisonProduct_1.ProductGroupPdpComparisonProduct, { compareItems: compareItems, hideTags: Boolean(currentProduct && productIndex !== 0), customTags: compareProduct.productId === (currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.productId) ? createCurrentProductTag() : undefined, onWishListToggle: handleWishlistToggle, parentId: productGroupId, product: compareProduct, productIndex: productIndex, productTileFormat: "simple", updateCompareList: handleUpdateCompareList, onTileClick: () => {
                                    handleItemClickAnalytics(productIndex + COMPARE_PRODUCT_INDEX_ADDITION, compareProduct);
                                } }) }, `${productUniqueKey}-${compareProduct.productCode}_${compareProduct.colourId}`))) })) })) }))] })));
};
exports.ProductGroupPdpComparison = ProductGroupPdpComparison;
