"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ManualUtil_1 = require("../../../util/ManualUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductCategorizedFeaturesTable = ({ features, isLastTable, canonicalUrl, productCode, productManual, manualBaseUrl, lang, displayProductCodeAsLink = true, tableContentSpacing, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const defaultTableContentSpacing = {
        mobile: {
            spacing: {
                paddingTop: 1.5,
                paddingBottom: 1.5,
                paddingLeft: 2,
                paddingRight: 2,
            },
        },
    };
    const filteredProductManualKeys = (0, ManualUtil_1.getFilteredProductManualKeys)(productManual);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Table, { striped: true, wrapText: true, align: cotopaxi_1.TableAlign.LEFT, valign: cotopaxi_1.TableVerticalAlign.TOP, children: (0, jsx_runtime_1.jsxs)("tbody", { children: [features
                        ? features.map(feature => ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { style: { width: '40%' }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((tableContentSpacing === null || tableContentSpacing === void 0 ? void 0 : tableContentSpacing.firstColumn) ? tableContentSpacing.firstColumn : {}), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, withGlossary: true, children: feature.featureName }) })) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((tableContentSpacing === null || tableContentSpacing === void 0 ? void 0 : tableContentSpacing.secondColumn) ? tableContentSpacing.secondColumn : {}), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, withGlossary: true, children: feature.featureValue }) })) })] }, feature.featureId)))
                        : null, isLastTable && ((canonicalUrl && displayProductCodeAsLink) || !displayProductCodeAsLink) ? ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { style: { width: '40%' }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((tableContentSpacing === null || tableContentSpacing === void 0 ? void 0 : tableContentSpacing.firstColumn) ? tableContentSpacing.firstColumn : {}), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: t('pdp.prodfeat.productcode').slice(0, -1) }) })) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, ((tableContentSpacing === null || tableContentSpacing === void 0 ? void 0 : tableContentSpacing.secondColumn) ? tableContentSpacing.secondColumn : {}), { children: displayProductCodeAsLink ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { dataQA: "product_code", href: canonicalUrl, variant: cotopaxi_1.LinkVariant.HIDDEN, size: cotopaxi_1.LinkSize.SMALL, children: productCode })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { dataQA: "product_code", inline: true, size: cotopaxi_1.TextSize.SMALL, children: productCode })) })) })] })) : null, isLastTable && canonicalUrl && filteredProductManualKeys.length ? ((0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("td", { colSpan: 2, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, defaultTableContentSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { dataQA: "product_manual", iconStart: cotopaxi_1.IconName.BOOK, size: cotopaxi_1.LinkSize.SMALL, target: cotopaxi_1.LinkTarget.BLANK, href: manualBaseUrl +
                                        (0, ManualUtil_1.getProductManualDocument)(filteredProductManualKeys, lang, productManual, ManualUtil_1.FALLBACK_LANG), children: t('pdp.manual.download') }) })) }) })) : null] }) }) }));
};
exports.default = ProductCategorizedFeaturesTable;
