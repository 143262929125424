"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_utils_1 = require("@as-react/common-utils");
class ABTestUtil {
    static onClickCompare(e, root) {
        this.onClick(e, 'comparePath', 'products/product-compare.html', root);
    }
    static redirectToCheckout(root, defaultPath, subRoute) {
        this.redirect('checkoutPath', defaultPath, root, subRoute);
    }
    static getABTest(keyABTest) {
        let ABTest;
        if (!(0, common_utils_1.isNullOrUndefined)((0, common_utils_1.getCustomWindowObject)(this.customObjectABTest))) {
            ABTest = (0, common_utils_1.getCustomWindowObject)(`${this.customObjectABTest}.${keyABTest}`);
        }
        return ABTest;
    }
    static onClick(e, keyABTest, defaultPath, root) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.redirect(keyABTest, defaultPath, root);
    }
    static redirect(keyABTest, defaultPath, root, subRoute) {
        let path = this.getABTest(keyABTest);
        if ((0, common_utils_1.isNullOrUndefined)(path) || path.length < 1) {
            path = defaultPath;
        }
        if (subRoute) {
            path = `${path}${subRoute}`;
        }
        window.location.href = `${root}${encodeURI(path)}`;
    }
}
ABTestUtil.customObjectABTest = 'SITE.ABTest';
exports.default = ABTestUtil;
