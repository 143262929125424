"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCompare = exports.loadCompareDataFromLocalStorage = exports.updateCompareProducts = exports.showCompareLimitReachedError = exports.showAndHideComparePopup = void 0;
const effects_1 = require("redux-saga/effects");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const CompareActions_creators_1 = require("../actions/CompareActions.creators");
const CompareSelectors_1 = require("../selectors/CompareSelectors");
const common_utils_1 = require("@as-react/common-utils");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ComparePopup_1 = require("./../../../components/product-detail/compare-popup/ComparePopup");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const COMPARE_STATE_LIFETIME = 86400000; // 24h
function* loadCompareDataFromLocalStorageSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductRecentlyViewedActionTypes.FETCH, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ListerActionTypes.LISTER_INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductComparisonActionTypes.INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductGroupFredhopperTypes.INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductGroupActionTypes.INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.PartialSearchActionTypes.INIT, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.WishListActionTypes.LISTER_SET_PRODUCTS, loadCompareDataFromLocalStorage);
    yield (0, effects_1.takeLatest)(ActionEnums_1.MonetateActionTypes.SET_PRODUCTS, loadCompareDataFromLocalStorage);
}
function* updateCompareSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CompareActionTypes.COMPARE_UPDATE, updateCompare);
}
function* showAndHideComparePopup() {
    yield (0, effects_1.put)(CompareActions_creators_1.default.createPopupOpenAction());
    yield (0, effects_1.delay)(GeneralConstants_1.DELAY.DELAY_3000);
    yield (0, effects_1.put)(CompareActions_creators_1.default.createPopupCloseAction());
}
exports.showAndHideComparePopup = showAndHideComparePopup;
function* showCompareLimitReachedError() {
    const compareState = yield (0, effects_1.select)(CompareSelectors_1.getCompareState);
    yield (0, effects_1.put)(CompareActions_creators_1.default.createToggleLimitReachedAction(true));
    yield (0, effects_1.delay)(GeneralConstants_1.DELAY.DELAY_5000);
    if (compareState.showError) {
        yield (0, effects_1.put)(CompareActions_creators_1.default.createToggleLimitReachedAction(false));
    }
}
exports.showCompareLimitReachedError = showCompareLimitReachedError;
function* updateCompareProducts(optionalCompareData) {
    let compareData;
    if (optionalCompareData) {
        compareData = optionalCompareData;
    }
    else {
        const compareState = yield (0, effects_1.select)(CompareSelectors_1.getCompareState);
        compareData = compareState.items;
    }
    yield (0, effects_1.put)(CompareActions_creators_1.default.createUpdateProductsAction(compareData.map((compareProduct) => ({
        colorId: compareProduct.color,
        productCode: compareProduct.productCode,
    }))));
}
exports.updateCompareProducts = updateCompareProducts;
function* loadCompareDataFromLocalStorage() {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const compareEnabled = yield (0, effects_1.call)(SagaContextSelectors_1.getCompareFeatureToggle, aemConfigurationContext);
    if (compareEnabled) {
        try {
            let compareData = yield (0, effects_1.call)(LocalStorageUtil_1.default.getItemWithExpiry, StorageKeysConstants_1.PRODUCT_COMPARE_LIST);
            if ((0, common_utils_1.isNullOrUndefined)(compareData) || (0, common_utils_1.isNullOrUndefined)(compareData[0])) {
                compareData = [];
            }
            const compareInitAction = CompareActions_creators_1.default.createInitAction(compareData);
            yield (0, effects_1.put)(compareInitAction);
            yield (0, effects_1.fork)(updateCompareProducts, compareData);
        }
        catch (_a) { }
    }
}
exports.loadCompareDataFromLocalStorage = loadCompareDataFromLocalStorage;
function* updateCompare(action) {
    try {
        const compareState = yield (0, effects_1.select)(CompareSelectors_1.getCompareState);
        const existingCompareItem = compareState.items.find((item) => item.color === action.payload.product.color && item.productCode === action.payload.product.productCode);
        if (existingCompareItem) {
            const compareRemoveAction = CompareActions_creators_1.default.createRemoveAction(action.payload);
            yield (0, effects_1.put)(compareRemoveAction);
        }
        else if (compareState.items.length < ComparePopup_1.MAX_COMPARE_PRODUCTS) {
            const compareAddAction = CompareActions_creators_1.default.createAddAction(action.payload);
            yield (0, effects_1.put)(compareAddAction);
        }
        if (existingCompareItem || compareState.items.length < ComparePopup_1.MAX_COMPARE_PRODUCTS) {
            const newCompareState = yield (0, effects_1.select)(CompareSelectors_1.getCompareState);
            LocalStorageUtil_1.default.setItemWithExpiry(StorageKeysConstants_1.PRODUCT_COMPARE_LIST, newCompareState.items, COMPARE_STATE_LIFETIME);
            yield (0, effects_1.fork)(updateCompareProducts, newCompareState.items);
            if (!action.payload.product.collapsedComparePopup) {
                yield (0, effects_1.fork)(showAndHideComparePopup);
            }
            if (action.payload.product.collapsedComparePopup && !compareState.collapsed) {
                if (compareState.items.length > 1) {
                    yield (0, effects_1.delay)(GeneralConstants_1.DELAY.DELAY_3000);
                }
                yield (0, effects_1.put)(CompareActions_creators_1.default.createPopupCloseAction());
            }
        }
        else {
            yield (0, effects_1.put)(CompareActions_creators_1.default.createPopupOpenAction());
            yield (0, effects_1.spawn)(showCompareLimitReachedError);
        }
    }
    catch (_a) { }
}
exports.updateCompare = updateCompare;
function* compareSaga() {
    yield (0, effects_1.all)([loadCompareDataFromLocalStorageSaga(), updateCompareSaga()]);
}
exports.default = compareSaga;
