"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceFieldsWatcher = exports.FinanceFieldName = void 0;
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const jsvat_next_1 = require("jsvat-next");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const addressFields_utils_1 = require("../address-fields/addressFields.utils");
const invoiceFields_utils_1 = require("./invoiceFields.utils");
var FinanceFieldName;
(function (FinanceFieldName) {
    FinanceFieldName["IS_VAT"] = "isVat";
    FinanceFieldName["IS_VIES_VERIFIED"] = "isViesVerified";
})(FinanceFieldName || (exports.FinanceFieldName = FinanceFieldName = {}));
const InvoiceFieldsWatcher = ({ country, prefix }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.account]);
    const { control, setError, setValue } = (0, react_hook_form_1.useFormContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [vatNumberField, companyNameField] = (0, ArrayUtil_1.mapWithAssert)([invoiceFields_utils_1.InvoiceFieldName.VAT_NUMBER, invoiceFields_utils_1.InvoiceFieldName.COMPANY_NAME], name => (0, addressFields_utils_1.generateFieldName)(name, prefix || 'invoice.'));
    const [isVatField, isViesVerifiedField] = (0, ArrayUtil_1.mapWithAssert)([FinanceFieldName.IS_VAT, FinanceFieldName.IS_VIES_VERIFIED], name => (0, addressFields_utils_1.generateFieldName)(name, prefix || 'finance.'));
    const vatNumber = (0, react_hook_form_1.useWatch)({ control, name: vatNumberField });
    const { getCompanyDetailsByVAT } = (0, common_queries_1.generalQueries)({ servicesEndpoint, defaultRequestParams });
    const currentCountry = country && jsvat_next_1.countries.find(item => item.codes.includes(country.toUpperCase()));
    const { data: companyDetails, error: companyDetailsError } = (0, react_query_1.useQuery)(getCompanyDetailsByVAT({ country: vatNumber.slice(0, 2), vatNumber: vatNumber.slice(2) }, {
        enabled: Boolean(currentCountry) && (0, jsvat_next_1.checkVAT)(vatNumber, [currentCountry]).isValid,
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (companyDetailsError) {
            setError(vatNumberField, { message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.error.no.results`) });
        }
    }, [companyDetailsError]);
    (0, react_1.useEffect)(() => {
        if (!companyDetails) {
            return;
        }
        if (!companyDetails.isVat) {
            setError(vatNumberField, { message: t(common_types_1.ErrorMessage.INVALID_VAT_NUMBER) });
        }
        setValue(companyNameField, companyDetails.viesResponse.name, { shouldValidate: true });
        setValue(isVatField, companyDetails.isVat);
        setValue(isViesVerifiedField, companyDetails.isViesVerified);
    }, [companyDetails]);
    return null;
};
exports.InvoiceFieldsWatcher = InvoiceFieldsWatcher;
