"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacebookPermissionStatus = exports.FacebookErrorsEnum = exports.FacebookLoginStatusEnum = void 0;
const common_utils_1 = require("@as-react/common-utils");
const ScriptLoaderUtil_1 = require("../../util/ScriptLoaderUtil");
const CustomerApi_1 = require("../customer/CustomerApi");
const CustomerSocialMediaApi_1 = require("../customer/CustomerSocialMediaApi");
const AuthApi_1 = require("./AuthApi");
const FACEBOOK_ELEMENT_ID = 'facebook-jssdk';
var FacebookLoginStatusEnum;
(function (FacebookLoginStatusEnum) {
    FacebookLoginStatusEnum["CONNECTED"] = "connected";
    FacebookLoginStatusEnum["NOT_AUTHORIZED"] = "not_authorized";
    FacebookLoginStatusEnum["AUTHORIZATION_EXPIRED"] = "authorization_expired";
    FacebookLoginStatusEnum["UNKNOWN"] = "unknown";
})(FacebookLoginStatusEnum || (exports.FacebookLoginStatusEnum = FacebookLoginStatusEnum = {}));
var FacebookErrorsEnum;
(function (FacebookErrorsEnum) {
    FacebookErrorsEnum["LOGOUT_WITH_FACEBOOK"] = "Unable to logout with Facebook";
    FacebookErrorsEnum["GET_USER_PERMISSIONS"] = "get Facebook user permissions";
    FacebookErrorsEnum["PERMISSION_EMAIL"] = "Facebook user did not provide email permission";
    FacebookErrorsEnum["GET_USER_INFO"] = "get Facebook user info";
    FacebookErrorsEnum["GET_USER_PICTURE"] = "get Facebook user profile picture";
    FacebookErrorsEnum["ALREADY_EXIST"] = "Socialmedia Login reference already exists";
    FacebookErrorsEnum["EMAIL_MISMATCH"] = "the facebook email does not match with the given email";
})(FacebookErrorsEnum || (exports.FacebookErrorsEnum = FacebookErrorsEnum = {}));
var FacebookPermissionStatus;
(function (FacebookPermissionStatus) {
    FacebookPermissionStatus["GRANTED"] = "granted";
})(FacebookPermissionStatus || (exports.FacebookPermissionStatus = FacebookPermissionStatus = {}));
class AuthSocialLoginFacebookApi {
    constructor() {
        this.isSDKInitialized = false;
        this.facebookAppId = undefined;
        this.isBuyPage = false;
        this.locale = undefined;
        this.apiPrefix = undefined;
        this.defaultRequestParams = undefined;
        this.localFacebookLoginStatus = FacebookLoginStatusEnum.UNKNOWN;
        this.initializeSDK = this.initializeSDK.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.login = this.login.bind(this);
        this.updatePermissions = this.updatePermissions.bind(this);
        this.logoutWithFacebook = this.logoutWithFacebook.bind(this);
    }
    initializeSDK() {
        const facebookUrlScript = `//connect.facebook.net/${this.locale}/sdk.js`;
        if (!this.isSDKInitialized) {
            if ((0, common_utils_1.isNullOrUndefined)(this.locale) || (0, common_utils_1.isNullOrUndefined)(this.facebookAppId)) {
                return new Promise((resolve, reject) => {
                    reject({
                        key: FACEBOOK_ELEMENT_ID,
                        loaded: false,
                        scriptUrl: facebookUrlScript,
                        status: 'Not all correct parameters needed',
                    });
                });
            }
            return ScriptLoaderUtil_1.default.loadScript(facebookUrlScript, FACEBOOK_ELEMENT_ID).then(scriptResponse => {
                if (scriptResponse.loaded) {
                    FB.init({
                        appId: this.facebookAppId,
                        cookie: true,
                        xfbml: true,
                        version: 'v2.10',
                    });
                }
                this.isSDKInitialized = true;
                return scriptResponse;
            });
        }
        else {
            return new Promise((resolve, reject) => {
                resolve({
                    key: FACEBOOK_ELEMENT_ID,
                    loaded: true,
                    scriptUrl: facebookUrlScript,
                    status: 'ok',
                });
            });
        }
    }
    setFacebookApplicationId(facebookAppId) {
        this.facebookAppId = facebookAppId;
    }
    setLocale(locale) {
        this.locale = locale;
    }
    setApiPrefix(apiPrefix) {
        this.apiPrefix = apiPrefix;
    }
    setIsBuyPage(isBuyPage) {
        this.isBuyPage = isBuyPage;
    }
    setDefaultRequestParams(defaultRequestParams) {
        this.defaultRequestParams = defaultRequestParams;
    }
    isInitialized() {
        return this.isSDKInitialized;
    }
    login() {
        return new Promise((resolve, reject) => {
            if (!this.isLoginStatusEnumConnected(this.localFacebookLoginStatus)) {
                this.loginWithFacebook().then(facebookStatusResponse => {
                    this.createSocialMediaProfileAndLoginWithBackend(facebookStatusResponse).then(resolve, reject);
                }, reject);
            }
        });
    }
    updatePermissions() {
        return new Promise((resolve, reject) => {
            this.loginWithFacebook(true).then(facebookStatusResponse => {
                this.createSocialMediaProfileAndLoginWithBackend(facebookStatusResponse).then(resolve, reject);
            }, reject);
        });
    }
    addAccount() {
        return new Promise((resolve, reject) => {
            if (!this.isLoginStatusEnumConnected(this.localFacebookLoginStatus)) {
                this.loginWithFacebook().then(facebookStatusResponse => {
                    this.createSocialMediaProfileAndAddConnection(facebookStatusResponse).then(resolve, reject);
                }, reject);
            }
        });
    }
    isAuthenticated() {
        return new Promise(resolve => {
            this.initializeSDK().then(script => {
                if (script.loaded) {
                    FB.getLoginStatus(response => {
                        this.localFacebookLoginStatus = FacebookLoginStatusEnum[response.status.toUpperCase()];
                        resolve(this.isLoginStatusConnected(response));
                    });
                }
                else {
                    this.localFacebookLoginStatus = FacebookLoginStatusEnum.UNKNOWN;
                    resolve(false);
                }
            }, () => resolve(false));
        });
    }
    logout() {
        return new Promise((resolve, reject) => {
            this.logoutWithFacebook().then(value => {
                AuthApi_1.default.logout(this.apiPrefix, this.defaultRequestParams).then(() => resolve(true), reject);
            }, reject);
        });
    }
    logoutWithFacebook() {
        return new Promise((resolve, reject) => {
            FB.logout((response) => {
                if (response && response.status !== FacebookLoginStatusEnum.CONNECTED) {
                    this.localFacebookLoginStatus = FacebookLoginStatusEnum.UNKNOWN;
                    resolve(response);
                }
                else {
                    const error = new Error(FacebookErrorsEnum.LOGOUT_WITH_FACEBOOK);
                    reject(error);
                }
            });
        });
    }
    isLoginStatusConnected(facebookLoginStatus) {
        return FacebookLoginStatusEnum[facebookLoginStatus.status.toUpperCase()] === FacebookLoginStatusEnum.CONNECTED;
    }
    isLoginStatusEnumConnected(facebookLoginStatus) {
        return facebookLoginStatus === FacebookLoginStatusEnum.CONNECTED;
    }
    // Check if email permission is granted
    isFacebookUserEmailPermissionGranted(permissions) {
        const emailGrantedPermission = permissions.filter(permission => {
            return permission.permission === 'email' && permission.status === 'granted';
        });
        return emailGrantedPermission.length > 0;
    }
    // Check if email permission is granted
    convertLoginPermissionsToFacebookPermissions(grantedScopes) {
        return grantedScopes.split(',').map(scope => {
            return {
                permission: scope,
                status: FacebookPermissionStatus.GRANTED,
            };
        });
    }
    loginWithFacebook(updatePermission = false) {
        const options = Object.assign({ scope: 'email,public_profile', return_scopes: true }, (updatePermission
            ? {
                auth_type: 'rerequest',
            }
            : {}));
        return new Promise((resolve, reject) => {
            FB.login((response) => {
                if (response && response.authResponse && this.isLoginStatusConnected(response)) {
                    resolve(response);
                }
                else {
                    reject(Object.assign(Object.assign({}, response), { message: 'account.error.general' }));
                }
            }, options);
        });
    }
    // Get info from current Facebook user
    getFacebookUserInfo() {
        return new Promise((resolve, reject) => {
            FB.api('/me', { fields: 'first_name,last_name,email,gender' }, response => {
                if (!response || response.error) {
                    const error = new Error(FacebookErrorsEnum.GET_USER_INFO);
                    reject(error);
                }
                else {
                    resolve(response);
                }
            });
        });
    }
    // Get profile picture from current facebook user
    getFacebookUserPicture() {
        return new Promise((resolve, reject) => {
            FB.api('/me/picture', { redirect: false }, response => {
                if (!response || response.error) {
                    const error = new Error(FacebookErrorsEnum.GET_USER_PICTURE);
                    reject(error);
                }
                else {
                    resolve(response);
                }
            });
        });
    }
    generateSocialMediaProfile(facebookLoginStatus, userInfo, userPicture) {
        const socialMediaProfile = {
            externalId: facebookLoginStatus.authResponse.userID,
            firstname: userInfo.first_name,
            lastname: userInfo.last_name,
            name: `${userInfo.first_name} ${userInfo.last_name}`,
            email: userInfo.email,
            gender: userInfo.gender,
            imageLink: userPicture.url || '',
            type: 'facebook',
            token: facebookLoginStatus.authResponse.accessToken,
        };
        return socialMediaProfile;
    }
    // Create social media profile
    createSocialMediaProfile(facebookLoginStatus, userMailShouldMatch) {
        return new Promise((resolve, reject) => {
            const permissions = this.convertLoginPermissionsToFacebookPermissions(facebookLoginStatus.authResponse.grantedScopes);
            if (this.isFacebookUserEmailPermissionGranted(permissions)) {
                this.getFacebookUserInfo().then((userInfo) => {
                    if (!(0, common_utils_1.isNullOrUndefined)(userMailShouldMatch) && userMailShouldMatch !== userInfo.email) {
                        const error = new Error(FacebookErrorsEnum.EMAIL_MISMATCH);
                        return reject(error);
                    }
                    this.getFacebookUserPicture().then(userPicture => {
                        const socialMediaProfile = this.generateSocialMediaProfile(facebookLoginStatus, userInfo, userPicture);
                        resolve(socialMediaProfile);
                    });
                });
            }
            else {
                const error = new Error(FacebookErrorsEnum.PERMISSION_EMAIL);
                return reject(error);
            }
        });
    }
    // Create social media profile and create login session
    createSocialMediaProfileAndLoginWithBackend(facebookLoginStatus) {
        return new Promise((resolve, reject) => {
            this.createSocialMediaProfile(facebookLoginStatus).then(socialProfile => {
                AuthApi_1.default.socialLogin(this.apiPrefix, this.defaultRequestParams, socialProfile, this.isBuyPage).then(value => {
                    resolve(true);
                }, reject);
            }, reject);
        });
    }
    // Create social media profile and add as a social media connection
    createSocialMediaProfileAndAddConnection(facebookLoginStatus) {
        return new Promise((resolve, reject) => {
            this.createSocialMediaProfile(facebookLoginStatus).then(socialProfile => {
                CustomerApi_1.default.getBasicProfile(this.apiPrefix, this.defaultRequestParams).then(value => {
                    const enhancedSocialMediaProfile = Object.assign(Object.assign({}, socialProfile), { customerIdentityId: value.data.uuid });
                    CustomerSocialMediaApi_1.default.addSocialMediaConnection(this.apiPrefix, this.defaultRequestParams, enhancedSocialMediaProfile).then(responseAddSocialMediaConnection => {
                        // ToDo: don't forget to add publish event for 'socialMediaConnectionAdded' on success
                        resolve(true);
                    }, reject);
                }, reject);
            });
        });
    }
}
exports.default = new AuthSocialLoginFacebookApi();
