"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationSizebay = void 0;
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ScriptLoaderUtil_1 = require("../../util/ScriptLoaderUtil");
const ProductUtil_1 = require("../../util/ProductUtil");
const TRACKER_KEY = 'sizebay-script';
const TRACKER_SCRIPT = 'https://vfr-v3-production.sizebay.technology/V4/implantation/index.js';
const tenantIdRegexp = new RegExp(/\/(\d+)\//);
const BuyConfirmationSizebay = ({ order }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const sizebayScript = (0, ConfigurationUtil_1.getSizebayScript)(aemConfiguration);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!sizebayScript) {
            return;
        }
        const tenantId = (_a = tenantIdRegexp.exec(sizebayScript)) === null || _a === void 0 ? void 0 : _a[1];
        if (!tenantId) {
            return;
        }
        ScriptLoaderUtil_1.default.loadScript(TRACKER_SCRIPT, TRACKER_KEY).then(() => {
            window.Sizebay.events.ordered({
                orderId: order.orderNumber,
                items: order.mappedProducts.map(product => ({
                    permalink: `${window.location.origin}/${product.productCode}`,
                    price: product.price,
                    quantity: product.quantity,
                    size: product.size.commercialSizeDefault
                        ? (0, ProductUtil_1.makeCommercialSize)(product.size)
                        : (0, ProductUtil_1.makeOriginalSize)(product.size),
                })),
            }, tenantId);
        });
    }, [sizebayScript]);
    return null;
};
exports.BuyConfirmationSizebay = BuyConfirmationSizebay;
