"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductQuantityContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const PDP_QUANTITY_SELECTOR = 'pdp-quantity';
const ProductQuantityContainer = ({ quantity, setQuantity, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: PDP_QUANTITY_SELECTOR, children: t('pdp.quantity') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ControlledNumberInput, { id: PDP_QUANTITY_SELECTOR, minQuantity: 1, quantity: quantity, onUpdate: setQuantity }) })] }));
};
exports.ProductQuantityContainer = ProductQuantityContainer;
