"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BasketReducer_1 = require("../../basket/reducers/BasketReducer");
const BasketSagas_1 = require("../../basket/sagas/BasketSagas");
const loadBasketModule = () => ({
    id: 'Basket',
    reducerMap: {
        basket: BasketReducer_1.basketReducer,
    },
    sagas: [BasketSagas_1.default],
});
exports.default = loadBasketModule;
