"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const BasketPopup_1 = require("../components/basket/basket-popup/BasketPopup");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const BasketPopupContainer = ({ recommendations }) => {
    const basketPopupState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPopupState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handlePopupClose = () => dispatch(BasketActions_creators_1.basketActions.createPopupCloseAction());
    return ((0, jsx_runtime_1.jsx)(BasketPopup_1.BasketPopup, { isOpen: !basketPopupState.collapsed, closePopup: handlePopupClose, basketProductData: basketPopupState.basketProductData, status: basketPopupState.status, errorCode: basketPopupState.errorCode, isBulk: basketPopupState.isBulk, price: basketPopupState.price, recommendations: recommendations }));
};
exports.default = BasketPopupContainer;
