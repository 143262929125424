"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCareMedia = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../../util/ImageUtil");
const VideoUtil_1 = require("../../../util/VideoUtil");
const Video_1 = require("../../video/Video");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const ProductCareMedia = ({ type, image, description, imageLinkPath, imageCdnPrefix, video, textIconColor, }) => type === common_types_1.ProductCareMediaType.IMAGE ? (imageLinkPath ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: imageLinkPath, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix), isPicture: true, lazy: true }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix), isPicture: true, lazy: true }))) : ((0, jsx_runtime_1.jsx)(Video_1.Video, { iframe: true, withOverlay: true, autoPlay: video.autoplay, loop: video.loop, poster: image
        ? {
            src: image.imagePath,
            srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix),
            isPicture: true,
            lazy: true,
        }
        : null, overlayTheme: textIconColor, url: video.embeddedLink || video.videoPath, allowFullScreen: video.type === common_types_1.ProductCareVideoType.EMBEDDED_LINK && video.allowFullscreen, description: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: description }), playerType: (0, VideoUtil_1.getVideoPlayerType)(video.embeddedLink || video.videoPath) }));
exports.ProductCareMedia = ProductCareMedia;
