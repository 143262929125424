"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetIsPaymentOptionUpdating = exports.makeGetHasRegistration = exports.makeGetCustomerDetails = exports.makeGetFastCheckoutOverview = exports.makeGetCollectStore = exports.makeGetSelectedPaymentOption = exports.makeGetNewsletterPreferences = exports.makeGetConfirmationIsCollectStore = exports.makeGetConfirmationOrder = exports.makeGetConfirmationError = exports.makeGetStoreId = exports.makeGetCollectionPoint = exports.makeGetPaymentOrderReference = exports.makeGetKlarna = exports.makeGetSaferpayFieldsReady = exports.makeGetStoreNumber = exports.makeGetStoreDetails = exports.makeGetIsSetPaymentOptionSuccess = exports.makeGetPaymentOptionError = exports.makeGetPaymentInfo = exports.makeGetDeliveryDates = exports.makeGetDeliveryUSStates = exports.makeGetDeliveryStore = exports.makeGetDeliveryErrors = exports.makeGetCustomerTitles = exports.makeGetDeliverySaving = exports.makeGetDeliveryInfo = exports.makeGetDeliveryAddress = exports.makeGetDeliveryAddresses = exports.makeGetDeliveryLoading = exports.makeGetChosenDeliveryOption = exports.makeGetRequiresDelivery = exports.makeGetDeliveryOptions = exports.makeGetDeliveryCountryId = exports.makeGetDeliveryCountry = exports.makeGetDeliveryCountries = exports.makeGetInitReadyForCheckoutState = exports.makeGetCheckoutStatus = void 0;
const reselect_1 = require("reselect");
const checkoutStateSelector = (state) => state.checkout;
const makeGetCheckoutStatus = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState);
exports.makeGetCheckoutStatus = makeGetCheckoutStatus;
const makeGetInitReadyForCheckoutState = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => (!!checkoutState.initResponse && checkoutState.initResponse.readyForCheckout) || false);
exports.makeGetInitReadyForCheckoutState = makeGetInitReadyForCheckoutState;
const makeGetDeliveryCountries = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryCountries);
exports.makeGetDeliveryCountries = makeGetDeliveryCountries;
const makeGetDeliveryCountry = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.country);
exports.makeGetDeliveryCountry = makeGetDeliveryCountry;
const makeGetDeliveryCountryId = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.idCountry);
exports.makeGetDeliveryCountryId = makeGetDeliveryCountryId;
const makeGetDeliveryOptions = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.deliveryOptions);
exports.makeGetDeliveryOptions = makeGetDeliveryOptions;
const makeGetRequiresDelivery = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.requiresDelivery);
exports.makeGetRequiresDelivery = makeGetRequiresDelivery;
const makeGetChosenDeliveryOption = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.chosenDeliveryOption);
exports.makeGetChosenDeliveryOption = makeGetChosenDeliveryOption;
const makeGetDeliveryLoading = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryLoading);
exports.makeGetDeliveryLoading = makeGetDeliveryLoading;
const makeGetDeliveryAddresses = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.deliveryAddresses);
exports.makeGetDeliveryAddresses = makeGetDeliveryAddresses;
const makeGetDeliveryAddress = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.deliveryAddress);
exports.makeGetDeliveryAddress = makeGetDeliveryAddress;
const makeGetDeliveryInfo = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo);
exports.makeGetDeliveryInfo = makeGetDeliveryInfo;
const makeGetDeliverySaving = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliverySaving);
exports.makeGetDeliverySaving = makeGetDeliverySaving;
const makeGetCustomerTitles = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.customerTitles);
exports.makeGetCustomerTitles = makeGetCustomerTitles;
const makeGetDeliveryErrors = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryErrors);
exports.makeGetDeliveryErrors = makeGetDeliveryErrors;
const makeGetDeliveryStore = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.store);
exports.makeGetDeliveryStore = makeGetDeliveryStore;
const makeGetDeliveryUSStates = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.states);
exports.makeGetDeliveryUSStates = makeGetDeliveryUSStates;
const makeGetDeliveryDates = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryDates);
exports.makeGetDeliveryDates = makeGetDeliveryDates;
const makeGetPaymentInfo = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.paymentInfo);
exports.makeGetPaymentInfo = makeGetPaymentInfo;
const makeGetPaymentOptionError = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.paymentOptionError);
exports.makeGetPaymentOptionError = makeGetPaymentOptionError;
const makeGetIsSetPaymentOptionSuccess = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.isSetPaymentOptionSuccess);
exports.makeGetIsSetPaymentOptionSuccess = makeGetIsSetPaymentOptionSuccess;
const makeGetStoreDetails = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.storeDetails);
exports.makeGetStoreDetails = makeGetStoreDetails;
const makeGetStoreNumber = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.storeNumber);
exports.makeGetStoreNumber = makeGetStoreNumber;
const makeGetSaferpayFieldsReady = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.saferpayFieldsReady);
exports.makeGetSaferpayFieldsReady = makeGetSaferpayFieldsReady;
const makeGetKlarna = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.klarna);
exports.makeGetKlarna = makeGetKlarna;
const makeGetPaymentOrderReference = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => { var _a; return (_a = checkoutState.paymentInfo) === null || _a === void 0 ? void 0 : _a.orderReference; });
exports.makeGetPaymentOrderReference = makeGetPaymentOrderReference;
const makeGetCollectionPoint = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.collectionPoint);
exports.makeGetCollectionPoint = makeGetCollectionPoint;
const makeGetStoreId = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.deliveryInfo.store);
exports.makeGetStoreId = makeGetStoreId;
const makeGetConfirmationError = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.confirmationError);
exports.makeGetConfirmationError = makeGetConfirmationError;
const makeGetConfirmationOrder = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.confirmationOrder);
exports.makeGetConfirmationOrder = makeGetConfirmationOrder;
const makeGetConfirmationIsCollectStore = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.confirmationIsCollectStore);
exports.makeGetConfirmationIsCollectStore = makeGetConfirmationIsCollectStore;
const makeGetNewsletterPreferences = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.newsletterPreferences);
exports.makeGetNewsletterPreferences = makeGetNewsletterPreferences;
const makeGetSelectedPaymentOption = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.selectedPaymentOption);
exports.makeGetSelectedPaymentOption = makeGetSelectedPaymentOption;
const makeGetCollectStore = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.collectStore);
exports.makeGetCollectStore = makeGetCollectStore;
const makeGetFastCheckoutOverview = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.fastCheckoutOverview);
exports.makeGetFastCheckoutOverview = makeGetFastCheckoutOverview;
const makeGetCustomerDetails = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.customerDetails);
exports.makeGetCustomerDetails = makeGetCustomerDetails;
const makeGetHasRegistration = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.hasRegistration);
exports.makeGetHasRegistration = makeGetHasRegistration;
const makeGetIsPaymentOptionUpdating = () => (0, reselect_1.createSelector)(checkoutStateSelector, (checkoutState) => checkoutState.isPaymentOptionUpdating);
exports.makeGetIsPaymentOptionUpdating = makeGetIsPaymentOptionUpdating;
