"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const react_1 = require("react");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ProductPreviewMainSlider_1 = require("./product-preview-main-slider/ProductPreviewMainSlider");
const ProductPreviewThumbSlider_1 = require("./product-preview-thumb-slider/ProductPreviewThumbSlider");
const ProductPreviewLightBox_1 = require("./product-preview-light-box/ProductPreviewLightBox");
const cotopaxi_1 = require("@as/cotopaxi");
const INITIAL_SLIDE_INDEX = 0;
const populateMediaCollection = (images, productCode, productImages, videos) => {
    let imageCollection = [];
    let videoCollection = [];
    let mediaCollection = [];
    if (!(0, common_utils_1.isNullOrUndefined)(images)) {
        imageCollection = images.map((image) => ({
            image,
            sourceSet: (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, image.picture, productImages),
            video: null,
            isVideo: false,
        }));
        mediaCollection = [...imageCollection];
    }
    if (!(0, common_utils_1.isNullOrUndefined)(videos) && imageCollection.length > 0) {
        videoCollection = videos
            .filter((video) => { var _a; return (_a = video.productVideoDetail) === null || _a === void 0 ? void 0 : _a.url; })
            .map((video) => ({
            image: imageCollection[0].image,
            sourceSet: imageCollection[0].sourceSet,
            video,
            isVideo: true,
        }));
        mediaCollection.splice(2, 0, ...videoCollection);
    }
    return mediaCollection;
};
const ProductPreview = ({ alt, genderCode, images, priceOverlay, productCode, productImages, savingsPercentage, selectedColorId, showGenderIcon, showOnlineExclusiveLabel, videos, showImageTouchZoomHint, showSustainabilityLabel, showNewProductLabel, onImageTouchZoom, }) => {
    const [activeSlideIndex, setActiveSlideIndex] = (0, react_1.useState)(INITIAL_SLIDE_INDEX);
    const popoverHandlers = (0, cotopaxi_1.usePopoverHandlers)();
    const mediaCollection = populateMediaCollection(images, productCode, productImages, videos);
    const onActiveSlideIndexChange = (newActiveSlideIndex) => {
        setActiveSlideIndex(newActiveSlideIndex);
    };
    (0, react_1.useEffect)(() => {
        setActiveSlideIndex(INITIAL_SLIDE_INDEX);
    }, [selectedColorId]);
    if (!mediaCollection)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProductPreviewThumbSlider_1.default, { activeSlideIndex: activeSlideIndex, alt: alt, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange }), (0, jsx_runtime_1.jsx)(ProductPreviewMainSlider_1.default, { activeSlideIndex: activeSlideIndex, alt: alt, genderCode: genderCode, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange, priceOverlay: priceOverlay, savingsPercentage: savingsPercentage, showGenderIcon: showGenderIcon, showOnlineExclusiveLabel: showOnlineExclusiveLabel, showImageTouchZoomHint: showImageTouchZoomHint, showSustainabilityLabel: showSustainabilityLabel, showNewProductLabel: showNewProductLabel, onImageTouchZoom: onImageTouchZoom, popoverHandlers: popoverHandlers }), (0, jsx_runtime_1.jsx)(ProductPreviewLightBox_1.ProductPreviewLightBox, { activeSlideIndex: activeSlideIndex, alt: alt, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange, popoverHandlers: popoverHandlers })] }));
};
exports.default = ProductPreview;
