"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.brandListerReducer = exports.loadBrandListerReducer = exports.initialState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const connected_react_router_1 = require("connected-react-router");
exports.initialState = {
    items: [],
    filters: [],
    popularList: {},
};
const setActiveFilter = (filters, filterToActivate) => {
    return filters.map(filter => (Object.assign(Object.assign({}, filter), { active: filter.key === filterToActivate })));
};
const loadBrandListerReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        var _a, _b, _c;
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return ((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.brandLister) ? Object.assign({}, action.payload.location.state.brandLister) : state;
            case ActionEnums_1.BrandListerActionTypes.SET_FILTER:
                return Object.assign(Object.assign({}, state), { filters: setActiveFilter(state.filters, action.payload) });
            default:
                return state;
        }
    };
};
exports.loadBrandListerReducer = loadBrandListerReducer;
exports.brandListerReducer = (0, exports.loadBrandListerReducer)();
