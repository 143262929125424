"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18nConstants_1 = require("../../constants/i18nConstants");
const i18nConfigurationGeneral_1 = require("./i18nConfigurationGeneral");
const generateFrontEndI18nInitOptions = (version, translationLanguage = '', translationEndpoint = '') => (Object.assign(Object.assign(Object.assign(Object.assign({}, i18nConfigurationGeneral_1.i18nConfigurationGeneral), { fallbackLng: translationLanguage || i18nConfigurationGeneral_1.i18nConfigurationGeneral.fallbackLng, preload: Object.keys(i18nConstants_1.I18N_NAMESPACES) }), {
    cache: Object.assign(Object.assign({}, i18nConfigurationGeneral_1.i18nConfigurationGeneral.cache), { enabled: true, versions: Object.keys(i18nConfigurationGeneral_1.i18nConfigurationGeneral.supportedLngs).reduce((previous, current) => {
            previous[current] = version;
            return previous;
        }, {}) }),
}), {
    backend: Object.assign(Object.assign({}, i18nConfigurationGeneral_1.i18nConfigurationGeneral.backend), { loadPath: `${translationEndpoint}${i18nConstants_1.I18N_TRANSLATIONS_BASE_PATH}`, queryStringParams: {
            version: version,
        }, parse: function (data) {
            return typeof data === 'string' ? JSON.parse(data) : data;
        } }),
}));
exports.default = (version, translationLanguage = '', translationEndpoint = '') => generateFrontEndI18nInitOptions(version, translationLanguage, translationEndpoint);
