"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSustainabilityProductPlacementCopy = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductSustainabilityProductPlacementCopy = ({ imageUrl, altText, description }) => {
    const IMAGE_HEIGHT = 44;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: { paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1 },
            theme: cotopaxi_1.BoxTheme.SUSTAINABLE,
        }, tablet: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)("div", { style: { height: `${IMAGE_HEIGHT}px` }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: imageUrl, alt: altText }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, align: cotopaxi_1.TextAlign.CENTER, size: cotopaxi_1.TextSize.SMALL, children: description })] }) }));
};
exports.ProductSustainabilityProductPlacementCopy = ProductSustainabilityProductPlacementCopy;
