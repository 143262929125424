"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ActionEnums_1 = require("../../../enums/ActionEnums");
const cookieConsentActions = {
    createClearAction() {
        return {
            type: ActionEnums_1.CookieConsentActionTypes.CLEAR,
        };
    },
    createSetAction(payload) {
        return {
            type: ActionEnums_1.CookieConsentActionTypes.SET,
            payload,
        };
    },
};
exports.default = cookieConsentActions;
