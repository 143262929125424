"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const endpoints = {
    paymentRequest: 'api/buy/paymentrequest/payment',
    paymentSaferpayToken: 'api/buy/paymentrequest/input/saferpay/token', // Bancontact & Credit Card
    postPaymentRequest: 'api/buy/paymentrequest/payment/v2', // Third Party
    providerPaymentRequest: 'api/buy/paymentrequest/input', // Third Party
    initSaferpayAlternative: 'api/buy/paymentrequest/input/saferpay/alternative', // QR Code
    statusSaferpayAlternative: 'api/buy/status/saferpay/alternative', // QR code
};
class PaymentRequestAPI {
    constructor() {
        this.getPaymentRequest = (servicesApi, defaultRequestParams, paymentRequestId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${endpoints.paymentRequest}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { paymentRequestId }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.initSaferpayAlternative = (servicesApi, defaultRequestParams, paymentRequestId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${endpoints.initSaferpayAlternative}`,
                params: defaultRequestParams,
                data: {
                    fictiveWebshopId: defaultRequestParams.shopId,
                    market: defaultRequestParams.market,
                    paymentRequestId,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.statusSaferpayAlternative = (servicesApi, requestId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${endpoints.statusSaferpayAlternative}`,
                params: requestId,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postSaferpayToken = (servicesApi, defaultRequestParams, paymentRequestId, saferpayFieldsToken) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${endpoints.paymentSaferpayToken}`,
                params: defaultRequestParams,
                data: {
                    fictiveWebshopId: defaultRequestParams.shopId,
                    market: defaultRequestParams.market,
                    saveForFutureUse: false,
                    paymentRequestId,
                    saferpayFieldsToken,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postPaymentRequest = (servicesApi, defaultRequestParams, paymentRequestId, payment) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${endpoints.postPaymentRequest}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { paymentRequestId }),
                data: payment,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.paymentProviderInputRequest = (servicesApi, defaultRequestParams, provider, data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${endpoints.providerPaymentRequest}/${provider.toLowerCase()}`,
                params: defaultRequestParams,
                data,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new PaymentRequestAPI();
