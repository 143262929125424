"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileSizeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SizeDropdown_1 = require("../size-dropdown/SizeDropdown");
const ProductUtil_1 = require("../../util/ProductUtil");
const ProductTileSizeSelect = ({ colorId, productId, dropdownProps, selectedSku, sizes, isOneSizeProduct, scrollableWrapperSelector, onSizeChange, hasError, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const [selectState, setSelectState] = (0, react_1.useState)(null);
    const sizeSelectRef = (0, react_1.useRef)(null);
    const { filteredSizes, options: selectOptions } = (0, ProductUtil_1.mapSizeSelectOptions)(sizes, true);
    const handleSelectedItemChange = (option) => {
        setSelectState(null);
        onSizeChange(String(option.value));
    };
    (0, react_1.useEffect)(() => {
        if (hasError) {
            handlePopoverOpen(null, sizeSelectRef.current);
            setSelectState(cotopaxi_1.SelectState.ERROR);
        }
        else {
            handlePopoverClose();
            setSelectState(null);
        }
    }, [hasError]);
    (0, react_1.useEffect)(() => {
        if (selectState === cotopaxi_1.SelectState.ERROR && selectedSku) {
            setSelectState(null);
            handlePopoverClose();
        }
    }, [selectedSku]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsx)(SizeDropdown_1.SizeDropdown, { id: `product_tile_size_select_${productId}_${colorId}`, toggleElementRef: sizeSelectRef, handleSelectedItemChange: handleSelectedItemChange, appendToBody: dropdownProps === null || dropdownProps === void 0 ? void 0 : dropdownProps.appendToBody, sizes: filteredSizes, options: selectOptions, selectedSku: selectedSku, toggleElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { name: `size-select-${productId}-${colorId}`, options: selectOptions, selectedValue: selectedSku, placeholder: t(isOneSizeProduct ? 'product.extensiveBlock.singleSizeAvailable' : 'pdp.size.choose.default'), defaultOptionValue: selectOptions.length === 1 && !isOneSizeProduct ? selectOptions[0].value : undefined, state: selectState, dataQA: "size-select", disabled: true, noChevron: isOneSizeProduct, fullWidth: true }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { type: cotopaxi_1.PopoverType.TOOLTIP, anchorEl: anchorEl, onClose: handlePopoverClose, position: cotopaxi_1.PopoverPosition.BOTTOM, stickyWrapper: Boolean(scrollableWrapperSelector), scrollableWrapperEl: scrollableWrapperSelector && typeof window === 'object'
                    ? document.querySelector(scrollableWrapperSelector)
                    : undefined, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('pdp.size.choose.default') }) })] }));
};
exports.ProductTileSizeSelect = ProductTileSizeSelect;
