"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGuestLogin = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const common_types_1 = require("@as-react/common-types");
const react_router_1 = require("react-router");
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const SessionStorageUtil_1 = require("../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const URLParamUtil_1 = require("../util/URLParamUtil");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const EventUtil_1 = require("../util/EventUtil");
const EventEnum_1 = require("../enums/EventEnum");
const BasketUtil_1 = require("../util/BasketUtil");
const useWishlistLogin_1 = require("./useWishlistLogin");
const useGuestLogin = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { push } = (0, react_router_1.useHistory)();
    const [errorMessage, setErrorMessage] = (0, react_1.useState)(null);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { isShopLanding, root, isInAuthorMode } = aemConfiguration;
    const { wishlistLogin } = (0, useWishlistLogin_1.useWishlistLogin)();
    const { updateEmailMutation } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { loginGuestMutation } = (0, common_queries_1.authMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: updateEmail } = (0, react_query_1.useMutation)(updateEmailMutation());
    const { mutateAsync: loginGuest } = (0, react_query_1.useMutation)(loginGuestMutation());
    const guestLogin = (email, exploreMoreCardNumber, customerUUID) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setErrorMessage(null);
        try {
            if (exploreMoreCardNumber && customerUUID) {
                yield updateEmail({ email, customerUUID });
            }
            yield loginGuest({ username: email, exploreMoreCardNumber });
            if (!isShopLanding) {
                SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.GUEST_EMAIL, email);
            }
            dispatch(AnalyticsActions_creators_1.analyticsActions.loginStepOption(true));
            dispatch(BasketActions_creators_1.basketActions.createGetBasicAction());
            yield wishlistLogin();
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn: true });
            push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
        }
        catch (e) {
            const error = e;
            if (!isShopLanding) {
                SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_EMAIL);
            }
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
                return;
            }
            setErrorMessage(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error) === common_types_1.CheckoutErrorMessage.INVALID_EMAIL
                ? common_types_1.ErrorMessage.INVALID_EMAIL
                : common_types_1.ErrorMessage.API_ERROR);
        }
    });
    return { guestLogin, errorMessage };
};
exports.useGuestLogin = useGuestLogin;
