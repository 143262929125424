"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addDeliveryAddressesSaga = exports.deleteDeliveryAddressesSaga = exports.getDeliveryAddressesSaga = exports.getProfileSaga = void 0;
const effects_1 = require("redux-saga/effects");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const SagaContextSelectors_1 = require("./../../SagaContextSelectors");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CustomerActions_creators_1 = require("../actions/CustomerActions.creators");
function* getProfile() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const response = yield (0, effects_1.call)(CustomerApi_1.default.getProfile, serviceEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(CustomerActions_creators_1.default.setProfile(response));
    }
    catch (_a) { }
}
function* getDeliveryAddresses() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const response = yield (0, effects_1.call)(CustomerApi_1.default.deliveryAddress, serviceEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(CustomerActions_creators_1.default.setDeliveryAddresses(response));
    }
    catch (_a) { }
}
function* deleteDeliveryAddress(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(CustomerApi_1.default.deleteDeliveryAddress, serviceEndpoint, defaultRequestParams, action.payload);
        yield (0, effects_1.put)(CustomerActions_creators_1.default.getDeliveryAddresses());
    }
    catch (_a) { }
}
function* addDeliveryAddress(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(CustomerApi_1.default.addDeliveryAddress, serviceEndpoint, defaultRequestParams, action.payload);
    }
    catch (_a) { }
}
function* getProfileSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CustomerActionTypes.GET_PROFILE, getProfile);
}
exports.getProfileSaga = getProfileSaga;
function* getDeliveryAddressesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CustomerActionTypes.GET_DELIVERY_ADDRESSES, getDeliveryAddresses);
}
exports.getDeliveryAddressesSaga = getDeliveryAddressesSaga;
function* deleteDeliveryAddressesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CustomerActionTypes.DELETE_DELIVERY_ADDRESS, deleteDeliveryAddress);
}
exports.deleteDeliveryAddressesSaga = deleteDeliveryAddressesSaga;
function* addDeliveryAddressesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CustomerActionTypes.ADD_DELIVERY_ADDRESS, addDeliveryAddress);
}
exports.addDeliveryAddressesSaga = addDeliveryAddressesSaga;
function* customerSaga() {
    yield (0, effects_1.all)([getProfileSaga(), getDeliveryAddressesSaga(), deleteDeliveryAddressesSaga(), addDeliveryAddressesSaga()]);
}
exports.default = customerSaga;
