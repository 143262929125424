"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewListSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const DateUtil_1 = require("../../util/DateUtil");
const ReviewListSection = ({ reviews }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: reviews.map((item) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ReviewItem, { ratingScore: item.score, reviewTitle: item.reviewTitle, reviewSubTitle: t('product.review.list.item.sub.title'), customerName: item.customerName, dateInserted: DateUtil_1.default.convertMillisecondsToUTCDate(item.dateInserted, common_types_1.DateFormat.DD_MM_YYYY_SLASH), reviewTextPositive: item.reviewTextPositive, reviewTextNegative: item.reviewTextNegative }, item.reviewId))) }));
};
exports.ReviewListSection = ReviewListSection;
