"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuUtil = void 0;
class MenuUtil {
    static close() {
        if (window['navigationMobileMenuReset']) {
            window.navigationMobileMenuReset();
        }
    }
}
exports.MenuUtil = MenuUtil;
