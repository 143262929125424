"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const AEMUtil_1 = require("../util/AEMUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const CategoryTileContainer_1 = require("./CategoryTileContainer");
const SingleProductTileContainer_1 = require("./SingleProductTileContainer");
const TeaserContainer_1 = require("./TeaserContainer");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ImageUtil_1 = require("../util/ImageUtil");
const SingleProductTileActions_creators_1 = require("../redux/single-product-tile/actions/SingleProductTileActions.creators");
const Slider_1 = require("../components/slider/Slider");
const VideoPlayer_1 = require("../components/video-player/VideoPlayer");
const MINIMUM_AMOUNT_OF_SLIDES_TO_REMOVE_LARGE_RENDITIONS_FOR = 2;
const LARGE_RENDITION_THRESHOLD = 600;
const SliderContainer = ({ paddings, cdnSettings, height, properties, slides, sliderId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const contentTileTranslations = {
        readMore: t(`${i18nConstants_1.I18N_NAMESPACES.general}:readMore`),
    };
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        if (slides.some(slide => slide.type === common_types_1.SlideType.SINGLE_PRODUCT_TILE)) {
            const products = [];
            slides.forEach(slide => {
                if ('initializationProduct' in slide) {
                    products.push(slide.initializationProduct);
                }
            });
            dispatch(SingleProductTileActions_creators_1.singleProductTileActions.addBulkProductsToList(products));
        }
    }, []);
    function getContentTileSrcSet(image, imageCdnPrefix) {
        let srcSet = (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix);
        if (properties.slidesToShowDesktop > MINIMUM_AMOUNT_OF_SLIDES_TO_REMOVE_LARGE_RENDITIONS_FOR) {
            srcSet = Object.fromEntries(Object.entries(srcSet).filter(([key]) => {
                const parsedKey = parseInt(key);
                return isNaN(parsedKey) || parsedKey < LARGE_RENDITION_THRESHOLD;
            }));
        }
        return srcSet;
    }
    const renderSlide = (0, react_1.useCallback)((_a) => {
        var { type } = _a, slideProps = tslib_1.__rest(_a, ["type"]);
        switch (type) {
            case common_types_1.SlideType.CATEGORY_TILE:
                return (0, jsx_runtime_1.jsx)(CategoryTileContainer_1.default, Object.assign({}, slideProps));
            case common_types_1.SlideType.TEASER:
                return (0, jsx_runtime_1.jsx)(TeaserContainer_1.default, Object.assign({}, slideProps, { mainCdnSettings: cdnSettings }));
            case common_types_1.SlideType.SINGLE_PRODUCT_TILE:
                return ((0, jsx_runtime_1.jsx)(SingleProductTileContainer_1.default, Object.assign({}, slideProps, { withoutSpacing: true, shouldInitProduct: false, adjustTileDescriptionHeight: slideProps.tileFormat === 'simple' })));
            case common_types_1.SlideType.CONTENT_TILE: {
                const _b = slideProps, { image } = _b, contentTileProps = tslib_1.__rest(_b, ["image"]);
                return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ContentTile, Object.assign({}, contentTileProps, { translations: contentTileTranslations, image: (0, common_utils_1.isNullOrUndefined)(image)
                        ? { src: '' }
                        : {
                            src: image.imagePath,
                            srcset: getContentTileSrcSet(image, imageCdnPrefix),
                            isPicture: true,
                            lazy: true,
                        }, withoutSpacing: true })));
            }
            case common_types_1.SlideType.VIDEO:
                return (0, jsx_runtime_1.jsx)(VideoPlayer_1.default, Object.assign({}, slideProps));
            default:
                return null;
        }
    }, [slides]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(paddings), { children: (0, jsx_runtime_1.jsx)(Slider_1.Slider, { properties: properties, id: sliderId, height: height, children: slides.map((slide, index) => ((0, jsx_runtime_1.jsx)("div", { style: slide.type === common_types_1.SlideType.SINGLE_PRODUCT_TILE ? { height: 'auto' } : undefined, children: renderSlide(slide) }, `slide_component_${index}`))) }) })));
};
exports.default = SliderContainer;
