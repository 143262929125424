"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const URLParamUtil_1 = require("../util/URLParamUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const CustomerApi_1 = require("../api/customer/CustomerApi");
const BasketAPI_1 = require("../api/basket/BasketAPI");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../components/common/html-include/HtmlInclude");
var VoucherStatus;
(function (VoucherStatus) {
    VoucherStatus["LOADING"] = "loading";
    VoucherStatus["SUCCESS"] = "success";
    VoucherStatus["INVALID"] = "invalid";
    VoucherStatus["ERROR"] = "error";
})(VoucherStatus || (VoucherStatus = {}));
var VoucherActionEnum;
(function (VoucherActionEnum) {
    VoucherActionEnum["CREATE_CUSTOMER_RETURN_INCENTIVE"] = "createCustomerReturnIncentive";
    VoucherActionEnum["ASSIGN_VOUCHER_TO_CUSTOMER"] = "assignVoucherToCustomer";
    VoucherActionEnum["CREATE_DISCOUNT_VOUCHER"] = "createDiscountVoucher";
})(VoucherActionEnum || (VoucherActionEnum = {}));
var VoucherMessageCode;
(function (VoucherMessageCode) {
    VoucherMessageCode["VOUCHER_USED"] = "voucher.used";
    VoucherMessageCode["VOUCHER_INVALID"] = "voucher.invalid";
    VoucherMessageCode["INVALID_CUSTOMER_UUID"] = "invalid.customer.uuid";
    VoucherMessageCode["NO_VOUCHERS_TO_ACTIVATE"] = "no.vouchers.to.activate";
    VoucherMessageCode["ALREADY_ACTIVE"] = "already.active";
})(VoucherMessageCode || (VoucherMessageCode = {}));
const URL_UUID_PARAM_NAME = 'customerUuid';
const URL_RETURN_INCENTIVE_ID_PARAM_NAME = 'returnIncentiveId';
const URL_VOUCHER_ONE_PARAM_NAME = 'voucherId1';
const URL_VOUCHER_TWO_PARAM_NAME = 'voucherId2';
const URL_ACTIVATION_ID_PARAM_NAME = 'activationId';
const DISCOUTNT_VOUCHER_UD_PARAM_NAME = 'discountVoucherId';
const refreshCustomerVouchers = (servicesEndpoint, defaultRequestParameters) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        yield BasketAPI_1.default.refreshCustomerVouchers(servicesEndpoint, defaultRequestParameters);
    }
    catch (_a) { }
});
const createVouchers = (uuid, servicesEndpoint, defaultRequestParameters) => new Promise((resolve, reject) => {
    const voucher1 = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_VOUCHER_ONE_PARAM_NAME);
    const voucher2 = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_VOUCHER_TWO_PARAM_NAME);
    const vouchers = [voucher1, voucher2].filter(Boolean);
    if (vouchers.length === 0 || !uuid) {
        resolve(VoucherStatus.ERROR);
    }
    CustomerApi_1.default.postPaybackVouchers(servicesEndpoint, defaultRequestParameters, uuid, vouchers).then(() => {
        resolve(VoucherStatus.SUCCESS);
    }, reject);
});
const createReturnIncentiveVoucher = (uuid, servicesEndpoint, defaultRequestParameters) => new Promise((resolve, reject) => {
    const returnIncentiveId = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_RETURN_INCENTIVE_ID_PARAM_NAME);
    if (!returnIncentiveId || !uuid) {
        resolve(VoucherStatus.ERROR);
    }
    CustomerApi_1.default.createReturnIncentiveVouchers(servicesEndpoint, defaultRequestParameters, uuid, returnIncentiveId).then(() => {
        refreshCustomerVouchers(servicesEndpoint, defaultRequestParameters);
        resolve(VoucherStatus.SUCCESS);
    }, reject);
});
const createDiscountVoucher = (uuid, servicesEndpoint, defaultRequestParameters) => new Promise((resolve, reject) => {
    const activationId = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_ACTIVATION_ID_PARAM_NAME);
    const discountVoucherId = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, DISCOUTNT_VOUCHER_UD_PARAM_NAME);
    if (!activationId || !discountVoucherId || !uuid) {
        resolve(VoucherStatus.ERROR);
    }
    CustomerApi_1.default.createDiscountVoucher(servicesEndpoint, defaultRequestParameters, activationId, discountVoucherId, uuid).then(() => {
        refreshCustomerVouchers(servicesEndpoint, defaultRequestParameters);
        resolve(VoucherStatus.SUCCESS);
    }, reject);
});
const VoucherContainer = ({ voucherAction, loading, success, error: defaultError, alreadyActivated, alreadyUsed, voucherHasExpired, CouldNotBeCreated, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [status, setStatus] = (0, react_1.useState)(VoucherStatus.LOADING);
    const [uniqueVouchers, setUniqueVouchers] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        const uuid = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_UUID_PARAM_NAME);
        if (!uuid) {
            setStatus(VoucherStatus.INVALID);
            setUniqueVouchers([{ messageCode: VoucherMessageCode.INVALID_CUSTOMER_UUID }]);
            return;
        }
        const handleError = (error) => {
            const errData = error.response.data;
            if (!errData || (!errData.vouchers && !errData.code)) {
                setStatus(VoucherStatus.ERROR);
                return;
            }
            setStatus(VoucherStatus.INVALID);
            setUniqueVouchers(errData.vouchers
                ? errData.vouchers
                    .filter(voucher => !voucher.valid)
                    .filter((value, index, array) => array.findIndex(voucher => voucher.messageCode === value.messageCode) === index)
                : errData.code
                    ? [{ messageCode: errData.code }]
                    : []);
        };
        switch (voucherAction) {
            case VoucherActionEnum.ASSIGN_VOUCHER_TO_CUSTOMER:
                createVouchers(uuid, servicesEndpoint, defaultRequestParameters).then(setStatus, handleError);
                break;
            case VoucherActionEnum.CREATE_CUSTOMER_RETURN_INCENTIVE:
                createReturnIncentiveVoucher(uuid, servicesEndpoint, defaultRequestParameters).then(setStatus, handleError);
                break;
            case VoucherActionEnum.CREATE_DISCOUNT_VOUCHER:
                createDiscountVoucher(uuid, servicesEndpoint, defaultRequestParameters).then(setStatus, handleError);
                break;
            default:
        }
    }, []);
    const getVoucherErrorStatus = (voucher) => {
        switch (voucher.messageCode) {
            case VoucherMessageCode.VOUCHER_USED:
                return alreadyUsed;
            case VoucherMessageCode.VOUCHER_INVALID:
                return voucherHasExpired;
            case VoucherMessageCode.INVALID_CUSTOMER_UUID:
            case VoucherMessageCode.NO_VOUCHERS_TO_ACTIVATE:
                return CouldNotBeCreated;
            case VoucherMessageCode.ALREADY_ACTIVE:
                return alreadyActivated;
            default:
                return defaultError;
        }
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 }, bleed: { marginInline: 1 } }, tablet: { spacing: { marginTop: 4 } }, desktop: { spacing: { marginTop: 3, marginBottom: 3 } }, children: [status === VoucherStatus.LOADING && (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: loading }), status === VoucherStatus.SUCCESS && (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: success }), status === VoucherStatus.ERROR && (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: defaultError }), status === VoucherStatus.INVALID &&
                uniqueVouchers.map(voucher => ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: getVoucherErrorStatus(voucher) }, voucher.barcode || voucher.messageCode)))] }));
};
exports.default = VoucherContainer;
