"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterSizes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const FilterSizeItem_1 = require("./FilterSizeItem");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const FilterSizes = ({ items, type, filter, toggleFilterValue, desktop, search, }) => {
    var _a;
    const isSizeCategoryFilter = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.name.includes(GeneralConstants_1.FILTER_SIZE_SEPARATOR);
    const isSizeFilterContentType = filter.filterContentType === common_types_1.FilterContentTypes.SIZE;
    if (isSizeFilterContentType && isSizeCategoryFilter) {
        const filterMap = {};
        items.map(filterItem => {
            const filterName = filterItem.name.split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[1];
            if (filterName && filterMap[filterName]) {
                filterMap[filterName].push(filterItem);
            }
            else if (filterName) {
                filterMap[filterName] = [filterItem];
            }
        });
        const filterEntries = Object.entries(filterMap);
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: filterEntries.map(([filterName, filterValues], index) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: filterEntries.length - 1 === index ? 0 : 1 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, emphasized: true, children: filterName }) }), filterValues.map((item) => ((0, jsx_runtime_1.jsx)(FilterSizeItem_1.FilterSizeItem, { item: item, toggleFilterValue: toggleFilterValue, desktop: desktop, filter: filter, type: type, search: search }, item.filterKey)))] }, filterName))) }));
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: items.map((item) => ((0, jsx_runtime_1.jsx)(FilterSizeItem_1.FilterSizeItem, { item: item, toggleFilterValue: toggleFilterValue, desktop: desktop, filter: filter, type: type, search: search }, item.filterKey))) }));
};
exports.FilterSizes = FilterSizes;
