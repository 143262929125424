"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTouchScreen = exports.getNbrOfItemsInListerRow = exports.isScreenMobile = exports.isScreenMobileLarge = exports.isScreenTabletSmall = exports.isScreenTablet = exports.isScreenDesktop = exports.isScreenDesktopLarge = void 0;
const screenWidthConstants = require("../constants/ScreenWidthConstants");
const isScreenDesktopLarge = () => window.matchMedia(`(min-width: ${screenWidthConstants.MIN_SCREEN_WIDTH_DESKTOP_LARGE}px)`).matches;
exports.isScreenDesktopLarge = isScreenDesktopLarge;
const isScreenDesktop = () => window.matchMedia(`(min-width: ${screenWidthConstants.MIN_SCREEN_WIDTH_DESKTOP}px)`).matches;
exports.isScreenDesktop = isScreenDesktop;
const isScreenTablet = () => window.matchMedia(`(min-width: ${screenWidthConstants.MIN_SCREEN_WIDTH_TABLET}px)`).matches &&
    window.matchMedia(`(max-width: ${screenWidthConstants.MAX_SCREEN_WIDTH_TABLET}px)`).matches;
exports.isScreenTablet = isScreenTablet;
const isScreenTabletSmall = () => window.matchMedia(`(min-width: ${screenWidthConstants.MIN_SCREEN_WIDTH_TABLET_SMALL}px)`).matches &&
    window.matchMedia(`(max-width: ${screenWidthConstants.MAX_SCREEN_WIDTH_TABLET_SMALL}px)`).matches;
exports.isScreenTabletSmall = isScreenTabletSmall;
const isScreenMobileLarge = () => window.matchMedia(`(min-width: ${screenWidthConstants.MIN_SCREEN_WIDTH_MOBILE_LARGE}px)`).matches &&
    window.matchMedia(`(max-width: ${screenWidthConstants.MAX_SCREEN_WIDTH_MOBILE_LARGE}px)`).matches;
exports.isScreenMobileLarge = isScreenMobileLarge;
const isScreenMobile = () => window.matchMedia(`(max-width: ${screenWidthConstants.MAX_SCREEN_WIDTH_MOBILE}px)`).matches;
exports.isScreenMobile = isScreenMobile;
const getNbrOfItemsInListerRow = () => {
    if ((0, exports.isScreenDesktopLarge)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP_LARGE;
    }
    else if ((0, exports.isScreenDesktop)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP;
    }
    else if ((0, exports.isScreenTablet)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET;
    }
    else if ((0, exports.isScreenTabletSmall)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET_SMALL;
    }
    else if ((0, exports.isScreenMobileLarge)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE_LARGE;
    }
    else if ((0, exports.isScreenMobile)()) {
        return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE;
    }
    return screenWidthConstants.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP;
};
exports.getNbrOfItemsInListerRow = getNbrOfItemsInListerRow;
const isTouchScreen = () => typeof window !== 'undefined' && 'ontouchstart' in document.documentElement;
exports.isTouchScreen = isTouchScreen;
