"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const AEMUtil_1 = require("../util/AEMUtil");
const Authentication_1 = require("../components/account/authentication/Authentication");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const NavigationUtil_1 = require("../util/NavigationUtil");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const GeneralUtil_1 = require("../util/GeneralUtil");
const usePageShow_1 = require("../hooks/usePageShow");
const LoginContainer = ({ paddings, redirectLink, hideTitle }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const isInAuthorMode = (0, ConfigurationUtil_1.getInAuthorMode)(aemConfiguration);
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, usePageShow_1.usePageShow)();
    (0, react_1.useEffect)(() => {
        const redirectUser = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)();
            window.location.href = redirectLink || (0, NavigationUtil_1.getHomepageUrl)(root);
        });
        if (loggedIn) {
            redirectUser();
        }
    }, [loggedIn]);
    const handleLoginSubmit = ({ email, password, keepSignedIn }) => {
        dispatch(AuthActions_creators_1.authActions.login({
            username: email,
            password,
            keepSignedIn,
            reloadOnSuccess: false,
            location: common_types_1.AuthFormLocation.LOGIN,
        }));
    };
    const handleResetPasswordSubmit = ({ email }) => {
        dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(email));
    };
    const handleSocialLogin = (payload) => {
        dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(payload));
    };
    const handleOnAuthResetForm = () => {
        dispatch(AuthActions_creators_1.authActions.resetError());
    };
    const resetForgotPasswordState = () => {
        (resetPasswordState.success || resetPasswordState.error) &&
            dispatch(AuthActions_creators_1.authActions.createResetCredentialsSuccessAction(null));
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(paddings), { children: (0, jsx_runtime_1.jsx)(Authentication_1.Authentication, { hasShadow: true, login: {
                onSubmit: handleLoginSubmit,
                errorFeedback: loginError,
                loading,
            }, forgotPassword: {
                onSubmit: handleResetPasswordSubmit,
                errorFeedback: resetPasswordState.error,
                successFeedback: resetPasswordState.success,
            }, facebook: {}, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE, hideTitle: hideTitle, isInAuthorMode: isInAuthorMode, socialLogin: handleSocialLogin, onAuthResetForm: handleOnAuthResetForm, resetForgotPasswordState: resetForgotPasswordState }) })));
};
exports.default = LoginContainer;
