"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountOverviewInfo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AddressUtil_1 = require("../../../util/AddressUtil");
const AccountConstant_1 = require("../../../constants/AccountConstant");
const AccountOverviewInfo = ({ profile, country, editProfileLink, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const address = (0, AddressUtil_1.getCustomerAddress)(profile.address, country, t);
    const editProfileLinkWithQuery = `${editProfileLink}${AccountConstant_1.ACCOUNT_PROFILE_PAGE_LINK_EDIT_FORM_QUERY}`;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 6, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: title.show ? cotopaxi_1.GroupAlign.SPACE_BETWEEN : cotopaxi_1.GroupAlign.RIGHT, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [title.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SUBTITLE, children: title.value || t('account.overview.my-account-details') })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: editProfileLinkWithQuery, dataQA: "edit_profile_link", iconStart: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.LinkSize.SMALL, children: t('delivery.edit').toLowerCase() })] }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [profile.firstName, " ", profile.middleName, " ", profile.lastName] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: address }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, tablet: { spacing: { marginTop: 4, marginBottom: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.NONE }) })] }) }) }));
};
exports.AccountOverviewInfo = AccountOverviewInfo;
