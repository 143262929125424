"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductGroupFredhopperActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.ProductGroupFredhopperActions = {
    init: () => ({
        type: ActionEnums_1.ProductGroupFredhopperTypes.INIT,
    }),
    getDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductGroupFredhopperTypes.GET_DELIVERY_PROMISE,
        payload,
    }),
    setDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductGroupFredhopperTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
    setProductGroupFredhopperData: (payload) => ({
        type: ActionEnums_1.ProductGroupFredhopperTypes.SET_FREDHOPPER_DATA,
        payload,
    }),
};
