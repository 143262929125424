"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileSwatches = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../../util/ImageUtil");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const SwatchUtil_1 = require("../../../../util/SwatchUtil");
const PRODUCT_TILE_SWATCH_DIMENSION = 24;
const ProductTileSwatches = ({ hidden, showOnMobile, idPrefix, onChange, innerRef, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { productCode, activeColorId, colors } = (0, cotopaxi_1.useProductTileContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const listerConfiguration = (0, ConfigurationUtil_1.getListerConfiguration)(aemConfiguration);
    const swatchInformation = (0, ConfigurationUtil_1.getProductSwatchInformation)(aemConfiguration);
    const shopLandingEnabledFlag = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const colorSize = Number(colors === null || colors === void 0 ? void 0 : colors.length);
    let maxColors = showOnMobile && isMobile ? swatchInformation.maxColorsMobile : swatchInformation.maxColors;
    if (colorSize > maxColors) {
        maxColors = maxColors - 1;
    }
    const isMaxSwatchesExceeded = colorSize > maxColors;
    const swatches = (isMaxSwatchesExceeded ? colors.slice(0, maxColors) : colors).map((color, index) => {
        const itemIdentifier = `${idPrefix}-${productCode}-${color.colourId}__${index}`;
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ImageRadioButton, { id: itemIdentifier, name: `color_swatch_${itemIdentifier}`, value: color.colourId.toString(), checked: color.colourId === activeColorId, imageUrl: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, color.swatch, listerConfiguration.productCdnDomains), imageLazyLoad: true, imageWidth: PRODUCT_TILE_SWATCH_DIMENSION, imageHeight: PRODUCT_TILE_SWATCH_DIMENSION, textImage: (0, SwatchUtil_1.getDiscountIconContent)(color, swatchInformation), textOverlay: true, icon: (0, SwatchUtil_1.getExtendedRangeIcon)(shopLandingEnabledFlag, color.extendedRange), title: color.description, onChange: () => onChange(color) }, itemIdentifier));
    });
    isMaxSwatchesExceeded &&
        swatches.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.PLUS, size: cotopaxi_1.IconSize.MINOR, color: cotopaxi_1.IconColor.SECONDARY }, cotopaxi_1.IconName.PLUS));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { opacity: hidden ? 0 : undefined, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { innerRef: innerRef, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: swatches }) }));
};
exports.ProductTileSwatches = ProductTileSwatches;
