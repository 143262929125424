"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ActionEnums_1 = require("../../../enums/ActionEnums");
const sessionActions = {
    createInitAction() {
        return {
            type: ActionEnums_1.SessionActionTypes.INIT,
        };
    },
    createLogoutAction() {
        return {
            type: ActionEnums_1.SessionActionTypes.LOGOUT,
        };
    },
    createSetAction(payload) {
        return {
            type: ActionEnums_1.SessionActionTypes.SET,
            payload,
        };
    },
    createNewsletterSubscribeAction() {
        return {
            type: ActionEnums_1.SessionActionTypes.NEWSLETTER_SUBSCRIBE,
        };
    },
};
exports.default = sessionActions;
