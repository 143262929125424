"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPDescription = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MiniPDPDescription = ({ description }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: "mini-pdp-description", title: t('pdp.mini.description'), hasDivider: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { feature: cotopaxi_1.TextFeature.PRODUCT_TECHNICAL_DESCRIPTION, size: cotopaxi_1.TextSize.SMALL, dataQA: "product_description", withGlossary: true, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: description }) }) })] }));
};
exports.MiniPDPDescription = MiniPDPDescription;
