"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const DeliveryEVoucherChangeHandler_1 = require("../../components/buy/utils/delivery-evoucher-change-handler/DeliveryEVoucherChangeHandler");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const BuyFormUtil_1 = require("../../util/BuyFormUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const BuyCustomerFields_1 = require("../../components/buy/forms/buy-customer-fields/BuyCustomerFields");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const DeliveryEVoucherOptionTitle = ({ title }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1.5, marginRight: 3 } }, tablet: { spacing: { marginLeft: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, valign: cotopaxi_1.GroupVerticalAlign.TOP, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EMAIL, size: cotopaxi_1.IconSize.EXTRA_MAJOR }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, children: title })] }) }));
const DeliveryEVoucherContainer = ({ title }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [formValid, setFormValid] = (0, react_1.useState)(false);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const customerTitles = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetCustomerTitles)());
    const deliveryInfo = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryInfo)());
    const deliverySaving = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliverySaving)());
    const deliveryErrors = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryErrors)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const optionsTitle = (0, react_1.useMemo)(() => customerTitles.map(ti => ({
        value: ti.id,
        label: ti.title,
    })), [customerTitles]);
    const onSubmit = (values) => {
        dispatch(CheckoutActions_creators_1.default.createSaveDeliveryInfoAction({ values }));
    };
    const handleValidateInformation = (values) => (Object.assign({}, (0, BuyFormUtil_1.validateCustomerFields)(values, t)));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { withBorder: true, title: (0, jsx_runtime_1.jsx)(DeliveryEVoucherOptionTitle, { title: title }), iconHorizontal: null, iconVertical: null, isChecked: true, asStatic: true, hasDivider: false, id: "voucher-delivery-option-collapse", dataQA: "voucher_delivery_option", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginTop: 1, marginRight: 2, marginBottom: 2 } }, tablet: { spacing: { marginLeft: 3, marginRight: 3 } }, children: (0, jsx_runtime_1.jsxs)(BuyForm_1.BuyForm, { initialValues: (0, BuyFormUtil_1.getInitialValues)(deliveryInfo, BuyForm_1.initialValuesBuyForm), buttonState: deliverySaving ? cotopaxi_1.ButtonState.LOADING : formValid ? cotopaxi_1.ButtonState.INTERACTIVE : cotopaxi_1.ButtonState.DISABLED, errorFeedback: deliveryErrors[common_types_1.DeliveryOptionsEnum.NAMED_DAY]
                    ? t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:${deliveryErrors[common_types_1.DeliveryOptionsEnum.NAMED_DAY]}`)
                    : null, validate: handleValidateInformation, checkoutStep: common_types_1.RoutePathname.DELIVERY, onSubmit: onSubmit, validateOnBlur: true, children: [(0, jsx_runtime_1.jsx)(BuyCustomerFields_1.BuyCustomerFields, { optionsTitle: optionsTitle, parent: BuyForm_1.BUY_FORM_NAME, type: BuyCustomerFields_1.BuyCustomerFieldsType.MIXED, showMiddleName: defaultRequestParameters.market === 'nl' }), (0, jsx_runtime_1.jsx)(DeliveryEVoucherChangeHandler_1.default, { setFormValid: setFormValid })] }) }) }));
};
exports.default = DeliveryEVoucherContainer;
