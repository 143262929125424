"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useCountdownTimer_1 = require("../../../hooks/useCountdownTimer");
const DeliveryConstants_1 = require("../../../constants/DeliveryConstants");
const andes_react_1 = require("@yonderland/andes-react");
const DeliveryPromiseMessage = ({ deliveryPromise, textProps }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [deliveryMessage, setDeliveryMessage] = (0, react_1.useState)('');
    const { countdownMessage } = (0, useCountdownTimer_1.useCountdownTimer)(deliveryPromise.cutoff, deliveryPromise.deliveryMessage);
    const isCountdownTimerMessage = () => {
        var _a;
        return deliveryPromise.hasDeliveryMessage &&
            !!deliveryPromise.cutoff &&
            ((_a = deliveryPromise.deliveryMessage) === null || _a === void 0 ? void 0 : _a.includes(DeliveryConstants_1.COUNTDOWN)) &&
            DeliveryConstants_1.ENABLED_DELIVERY_PROMISES.includes(deliveryPromise.name);
    };
    (0, react_1.useEffect)(() => {
        var _a;
        setDeliveryMessage(deliveryPromise.hasDeliveryMessage
            ? deliveryPromise.deliveryMessage
            : `${t('pdp.size.deliverypromise.txt', {
                deliveryFrom: deliveryPromise.deliveryFrom,
                deliveryTo: deliveryPromise.deliveryTo,
            })} ${t((_a = deliveryPromise.deliveryUnit) !== null && _a !== void 0 ? _a : '')}`);
    }, [deliveryPromise]);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, Object.assign({ color: "success" }, textProps, { as: "span", children: isCountdownTimerMessage() ? (0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: countdownMessage } }) : deliveryMessage })));
};
exports.default = DeliveryPromiseMessage;
