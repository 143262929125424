"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupPrice = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const asNumeral_1 = require("../../vendors/asNumeral");
const PromoBasketPopupPrice = ({ priceInformation, aemConfiguration, }) => {
    var _a, _b;
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const priceActual = {
        formattedPrice: `${(_a = priceInformation.prices) === null || _a === void 0 ? void 0 : _a.SELL.original},-`,
    };
    const pricePrevious = {
        formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency((_b = priceInformation.prices) === null || _b === void 0 ? void 0 : _b.STD.original),
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, tablet: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Price, { size: isMobile ? cotopaxi_1.PriceSize.MEDIUM : cotopaxi_1.PriceSize.LARGE, spacing: isMobile ? cotopaxi_1.PriceSpacing.SMALL : cotopaxi_1.PriceSpacing.MEDIUM, currency: currency, priceActual: priceActual, pricePrevious: pricePrevious }) }));
};
exports.PromoBasketPopupPrice = PromoBasketPopupPrice;
