"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToCurrencySymbol = void 0;
const convertToCurrencySymbol = (symb) => {
    switch (symb) {
        case '&pound;':
        case '&#163;':
            return '£';
        case '&dollar;':
        case '&#36;':
            return '$';
        case '&euro;':
        case '&#128;':
            return '€';
        default:
            return '';
    }
};
exports.convertToCurrencySymbol = convertToCurrencySymbol;
