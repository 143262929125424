"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthFormReducer_1 = require("../../authForm/reducers/AuthFormReducer");
const AuthFormSaga_1 = require("../../authForm/sagas/AuthFormSaga");
const loadAuthenticationFormModule = () => ({
    id: 'AuthenticationForm',
    reducerMap: {
        authForm: AuthFormReducer_1.authFormReducer,
    },
    sagas: [AuthFormSaga_1.default],
});
exports.default = loadAuthenticationFormModule;
