"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const ProductActions_1 = require("../../../redux/product/actions/ProductActions");
const redux_1 = require("redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ServerSideInclude_1 = require("../server-side-include/ServerSideInclude");
class EsiData extends react_1.PureComponent {
    constructor(props) {
        super(props);
        this.buildPricesUrl = (localeInformation) => {
            const { productDefaults } = this.props;
            return (`${common_utils_1.Environment.isDevelopment() ? 'http://localhost:8081/hypernova/esi/' : '/api/hypernova/esi/'}` +
                `product-detail/${localeInformation.mainWebShop}/${localeInformation.fictiveWebShop}` +
                `/${productDefaults.productId}` +
                `/${localeInformation.lang}`);
        };
    }
    render() {
        const { keyName, setEsiData } = this.props;
        return ((0, jsx_runtime_1.jsx)(common_components_1.AemConfigurationContext.Consumer, { children: (configuration) => {
                const localeInformation = (0, ConfigurationUtil_1.getLocaleInformation)(configuration);
                const url = this.buildPricesUrl(localeInformation);
                return (0, jsx_runtime_1.jsx)(ServerSideInclude_1.default, { dataKey: keyName, throwAction: setEsiData, url: url });
            } }));
    }
}
const makeMapStateToProps = () => {
    const getProductDefaults = (0, ProductSelector_1.makeGetProductDefaults)();
    const mapStateToProps = (state) => ({
        productDefaults: getProductDefaults(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch) => (0, redux_1.bindActionCreators)({
    setEsiData: ProductActions_1.productActions.createSetEsiDataAction,
}, dispatch);
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(EsiData);
