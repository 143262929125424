"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchForCustomEvents = void 0;
const logger_1 = require("@as-react/logger");
const redux_saga_1 = require("redux-saga");
const effects_1 = require("redux-saga/effects");
const EventEnum_1 = require("../../enums/EventEnum");
const ActionEnums_1 = require("../../enums/ActionEnums");
const EventUtil_1 = require("../../util/EventUtil");
const createCustomEventChannel = () => {
    return (0, redux_saga_1.eventChannel)(emit => {
        const handleEventCall = (event) => {
            emit({
                eventType: event.type,
                data: event.detail && event.detail !== '' ? event.detail : null,
            });
        };
        const subscribe = () => {
            Object.keys(EventEnum_1.EventEnum).forEach(key => {
                EventUtil_1.default.listen(EventEnum_1.EventEnum[key], handleEventCall);
            });
        };
        const unsubscribe = () => {
            Object.keys(EventEnum_1.EventEnum).forEach(key => {
                EventUtil_1.default.remove(EventEnum_1.EventEnum[key], handleEventCall);
            });
        };
        subscribe();
        return unsubscribe;
    });
};
function* watchForCustomEvents() {
    const customEventChannel = yield (0, effects_1.call)(createCustomEventChannel);
    while (true) {
        try {
            const payload = yield (0, effects_1.take)(customEventChannel);
            yield (0, effects_1.put)({
                type: ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT,
                payload,
            });
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
}
exports.watchForCustomEvents = watchForCustomEvents;
