"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductGroupPdpComparisonProduct = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const ProductUtil_1 = require("../../util/ProductUtil");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const CompareSelectors_1 = require("../../redux/compare/selectors/CompareSelectors");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ProductConstants_1 = require("../../constants/ProductConstants");
const react_i18next_1 = require("react-i18next");
const ProductGroupPdpComparisonFeatureItem_1 = require("./ProductGroupPdpComparisonFeatureItem");
const ProductGroupPdpComparison_1 = require("./ProductGroupPdpComparison");
const useInitGlossaryTooltips_1 = require("../../hooks/useInitGlossaryTooltips");
const ProductGroupPdpComparisonProduct = ({ compareItems, customTags, hideTags, onWishListToggle, product, parentId, productTileFormat, updateCompareList, productIndex, onTileClick, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const activeColour = product.colours.find(colour => colour.colourId === product.colourId);
    const isCompareProduct = (0, react_redux_1.useSelector)((0, CompareSelectors_1.makeGetIsCompareProduct)(product.productCode, product.colourId));
    const { isGlossaryLoaded, shouldInitGlossaryTooltips } = (0, useInitGlossaryTooltips_1.useInitGlossaryTooltips)();
    const localeInformation = (0, ConfigurationUtil_1.getLocaleInformation)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const { seoUrl } = product;
    const productDetailUrl = localeInformation.root
        .concat(seoUrl.indexOf('/') === 0 ? `${ProductConstants_1.PRODUCT_URL_PREFIX}${seoUrl}` : `${ProductConstants_1.PRODUCT_URL_PREFIX}/${seoUrl}`)
        .concat('.html')
        .concat(`?colour=${activeColour.colourId}`);
    const compareConfig = featureToggles.product_compare
        ? {
            onAddToCompareChecked: () => updateCompareList({
                brand: product.brand,
                color: activeColour.colourId,
                image: activeColour.image,
                productCode: product.productCode,
                selected: false,
                id: product.productId,
                title: product.title,
                gender: product.genderCode,
                url: productDetailUrl,
                colorDescription: activeColour.description,
                collapsedComparePopup: true,
            }),
            isProductInCompare: isCompareProduct,
            labelChildren: (compareItems === null || compareItems === void 0 ? void 0 : compareItems.length) > 1 && isCompareProduct ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('productCompare.pdp.checkbox'), variant: cotopaxi_1.ButtonVariant.OUTLINED_ALT, size: cotopaxi_1.ButtonSize.XSMALL, shape: cotopaxi_1.ButtonShape.PILL, iconRight: {
                                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                size: cotopaxi_1.IconSize.MEDIUM,
                            }, href: `${root}products/product-compare.html` }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: t(`${cotopaxi_1.I18N_NAMESPACES.product}:productCompare.pdp.checkbox`) })] })) : (t(`${cotopaxi_1.I18N_NAMESPACES.product}:productCompare.pdp.checkbox`)),
        }
        : null;
    const compareCheckboxName = `${parentId ? `${parentId}--` : ''}compare-${product.productCode}-${product.colourId}`;
    const productGridArea = `${ProductGroupPdpComparison_1.GRID_AREAS.PRODUCT}-${productIndex}`;
    const hasFeatures = (_a = product === null || product === void 0 ? void 0 : product.features) === null || _a === void 0 ? void 0 : _a.length;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { style: { gridArea: `${productGridArea}-${ProductGroupPdpComparison_1.GRID_AREAS.PRODUCT_TILE}` }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: Object.assign(Object.assign({}, ProductGroupPdpComparison_1.GRID_BOX_PARAMS.mobile.spacing), { marginBottom: 3 }),
                    }, tablet: ProductGroupPdpComparison_1.GRID_BOX_PARAMS.tablet, children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { alwaysShowSwatches: false, clickAndCollectOnly: (0, ProductUtil_1.isClickAndCollectOnly)(product), customTags: customTags, dropdownProps: { appendToBody: true }, hideBasketPopup: true, inStoreOnly: (0, ProductUtil_1.isInStoreOnly)(product), onWishListToggle: onWishListToggle, parentId: parentId, product: product, hideTags: hideTags, format: productTileFormat, shouldLazyLoadImage: common_types_1.LazyLoadOptions.LAZY_LOAD, showProductOutOfStockOverlay: false, onTileClick: onTileClick }) }) }), hasFeatures &&
                product.features.map((feature, featureIndex) => ((0, jsx_runtime_1.jsx)(ProductGroupPdpComparisonFeatureItem_1.ProductGroupPdpComparisonFeatureItem, { name: feature.featureLabel ? feature.featureLabel : feature.featureCode, productIndex: productIndex, featureIndex: featureIndex, value: feature.attributeValue, isGlossaryLoaded: isGlossaryLoaded, shouldInitGlossaryTooltip: shouldInitGlossaryTooltips }, feature.featureId))), !!compareConfig && ((0, jsx_runtime_1.jsx)("div", { style: { gridArea: `${productGridArea}-${ProductGroupPdpComparison_1.GRID_AREAS.COMPARE}` }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: Object.assign(Object.assign({}, ProductGroupPdpComparison_1.GRID_BOX_PARAMS.mobile.spacing), { marginTop: hasFeatures ? 3 : 0 }),
                    }, tablet: ProductGroupPdpComparison_1.GRID_BOX_PARAMS.tablet, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: compareCheckboxName, name: compareCheckboxName, value: compareCheckboxName, labelChildren: compareConfig.labelChildren, checked: compareConfig.isProductInCompare, onChange: compareConfig.onAddToCompareChecked, size: cotopaxi_1.LabelSize.MINOR, align: cotopaxi_1.CheckboxWithLabelVerticallyAlign.CENTER }) }) }))] }));
};
exports.ProductGroupPdpComparisonProduct = ProductGroupPdpComparisonProduct;
