"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetSingleProductTileProduct = void 0;
const reselect_1 = require("reselect");
const singleProductTileProductSelector = (state, props) => {
    const { productCode, colourId } = props.initializationProduct;
    return state.singleProductsList.find((product) => product.productCode === productCode && product.colourId === colourId);
};
const makeGetSingleProductTileProduct = () => (0, reselect_1.createSelector)([singleProductTileProductSelector], (product) => product);
exports.makeGetSingleProductTileProduct = makeGetSingleProductTileProduct;
