"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductWizard = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterUtil_1 = require("../../util/FilterUtil");
const ProductWizardIntro_1 = require("./ProductWizardIntro");
const ProductWizardFlowSelectorFooter_1 = require("./ProductWizardFlowSelectorFooter");
const ProductWizardQuestionsFooter_1 = require("./ProductWizardQuestionsFooter");
const ProductWizardQuestionList_1 = require("./ProductWizardQuestionList");
const UrlUtil_1 = require("../../util/UrlUtil");
var ProductWizardView;
(function (ProductWizardView) {
    ProductWizardView[ProductWizardView["INTRO"] = 0] = "INTRO";
    ProductWizardView[ProductWizardView["FLOW_SELECTOR"] = 1] = "FLOW_SELECTOR";
    ProductWizardView[ProductWizardView["QUESTIONS"] = 2] = "QUESTIONS";
})(ProductWizardView || (ProductWizardView = {}));
const ProductWizard = ({ anchorEl, loggedIn, userFirstName, productWizardData, handlePopoverClose, fetchSearchResults, }) => {
    var _a;
    const [flowOptions, setFlowOptions] = (0, react_1.useState)(productWizardData.question);
    const [selectedFlowId, setSelectedFlowId] = (0, react_1.useState)(null);
    const [selectedFlow, setSelectedFlow] = (0, react_1.useState)(null);
    const [questionIndex, setQuestionIndex] = (0, react_1.useState)(null);
    const [filter, setFilter] = (0, react_1.useState)(null); // include category and all questions answers
    const [totalProductsCount, setTotalProductsCount] = (0, react_1.useState)(0);
    const [showLoadingIcon, setShowLoadingIcon] = (0, react_1.useState)(false);
    const [view, setView] = (0, react_1.useState)(ProductWizardView.INTRO);
    const getQuestionFilter = (question) => {
        let filterValue = '';
        const selectedAnswers = question.answers.filter((answer) => answer.selected);
        if (selectedAnswers.length) {
            if (question.filterType === common_types_1.RANGE_FILTER) {
                filterValue = (0, FilterUtil_1.getRangeFilter)(question.filter, selectedAnswers[0].min, selectedAnswers[0].max);
            }
            else if (question.filterType === common_types_1.BUCKET_FILTER) {
                filterValue = (0, FilterUtil_1.getBucketFilter)(question.filter, selectedAnswers[0].appliedFilters[0]);
            }
            else {
                filterValue = (0, FilterUtil_1.getMultiFilter)(question.filter, selectedAnswers.map((answer) => answer.appliedFilters.join(';')));
            }
        }
        return filterValue;
    };
    const getFilterQueryParams = (questions) => questions.reduce((query, question) => `${query}${getQuestionFilter(question)}`, (0, FilterUtil_1.getFilterQueryParameter)(selectedFlow.categoryFilter));
    const loadFlowOptionsProductsCount = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setShowLoadingIcon(true);
        const results = yield Promise.all(flowOptions.answers.map((answer) => fetchSearchResults(answer.categoryFilter)));
        flowOptions.dataLoaded = true;
        flowOptions.answers.forEach((answer, index) => (answer.productCount = results[index]));
        setFlowOptions(Object.assign({}, flowOptions));
        setShowLoadingIcon(false);
    });
    const loadQuestionOptionsProductsCount = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setShowLoadingIcon(true);
        const baseFilter = getFilterQueryParams(selectedFlow.questions.slice(0, questionIndex));
        const question = selectedFlow.questions[questionIndex];
        const results = yield Promise.all(question.answers.map((answer) => {
            let answerFilter = '';
            if (question.filterType === common_types_1.RANGE_FILTER) {
                answerFilter = (0, FilterUtil_1.getRangeFilter)(question.filter, answer.min, answer.max);
            }
            else if (question.filterType === common_types_1.BUCKET_FILTER) {
                answerFilter = (0, FilterUtil_1.getBucketFilter)(question.filter, answer.appliedFilters[0]);
            }
            else {
                answerFilter = (0, FilterUtil_1.getMultiFilter)(question.filter, answer.appliedFilters);
            }
            return fetchSearchResults(`${baseFilter}${answerFilter}`);
        }));
        question.dataLoaded = true;
        question.answers.forEach((answer, index) => (answer.productCount = results[index]));
        setSelectedFlow(Object.assign({}, selectedFlow));
        setShowLoadingIcon(false);
    });
    const loadTotalProductsCount = (_filter) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const productsCount = yield fetchSearchResults(_filter);
        setTotalProductsCount(productsCount || 0);
    });
    const isMultiFlow = () => productWizardData.type === common_types_1.ProductWizardFlowType.MULTIPLE && productWizardData.flows.length > 1;
    const setFlowView = () => {
        if (isMultiFlow()) {
            setView(ProductWizardView.FLOW_SELECTOR);
            loadFlowOptionsProductsCount();
        }
        else {
            setSelectedFlow(productWizardData.flows[0]);
            setQuestionIndex(0);
            setView(ProductWizardView.QUESTIONS);
        }
    };
    const onFlowChange = (answerIndex) => {
        flowOptions.answers.forEach((answer, index) => (answer.selected = index === answerIndex));
        setSelectedFlowId(flowOptions.answers[answerIndex].id);
        setFlowOptions(Object.assign({}, flowOptions));
        setTotalProductsCount(0);
    };
    const resetQuestionState = (question) => {
        question.dataLoaded = false;
        question.answers.forEach((answer) => {
            answer.selected = false;
            answer.productCount = undefined;
        });
    };
    const onAnswerChange = (answerIndex) => {
        const questions = selectedFlow.questions;
        const question = questions[questionIndex];
        if (question.inputType === common_types_1.ProductWizardInputType.CHECKBOX) {
            question.answers[answerIndex].selected = !question.answers[answerIndex].selected;
        }
        else {
            question.answers.forEach((answer, index) => (answer.selected = index === answerIndex));
        }
        // clear products count and deselect answers in next questions
        for (let i = questionIndex + 1; i < questions.length; i++) {
            resetQuestionState(questions[i]);
        }
        setSelectedFlow(Object.assign({}, selectedFlow));
    };
    const setQuestionsView = () => {
        const flow = productWizardData.flows.find((item) => item.id === selectedFlowId);
        setSelectedFlow(flow);
        setQuestionIndex(0);
        setView(ProductWizardView.QUESTIONS);
    };
    const isLastQuestion = () => questionIndex === selectedFlow.questions.length - 1;
    const isFirstQuestion = () => questionIndex === 0;
    const selectNextQuestion = () => {
        !isLastQuestion() && setQuestionIndex(questionIndex + 1);
    };
    const goToPrevStep = () => {
        if (!isFirstQuestion()) {
            setQuestionIndex(questionIndex - 1);
        }
        else {
            if (isMultiFlow()) {
                setQuestionIndex(null);
                setFilter(null);
                setView(ProductWizardView.FLOW_SELECTOR);
            }
        }
    };
    const resetWizardState = () => {
        selectedFlow.questions.forEach((question) => resetQuestionState(question));
        if (isMultiFlow()) {
            resetQuestionState(flowOptions);
            setFlowOptions(Object.assign({}, flowOptions));
            setQuestionIndex(null);
            setSelectedFlowId(null);
            setSelectedFlow(null);
            setView(ProductWizardView.FLOW_SELECTOR);
            loadFlowOptionsProductsCount();
        }
        else {
            isFirstQuestion() ? loadQuestionOptionsProductsCount() : setQuestionIndex(0);
        }
        setFilter(null);
        setTotalProductsCount(0);
    };
    const shouldShowBackBtn = () => view === ProductWizardView.QUESTIONS ? (isMultiFlow() ? true : !isFirstQuestion()) : false;
    const getFooterElement = () => {
        if (view === ProductWizardView.FLOW_SELECTOR) {
            return ((0, jsx_runtime_1.jsx)(ProductWizardFlowSelectorFooter_1.ProductWizardFlowSelectorFooter, { activeStateLabel: flowOptions.nextButtonLabel, disabledStateLabel: flowOptions.nonActiveStatLabel, onClick: setQuestionsView, disabled: !selectedFlowId }));
        }
        else if (view === ProductWizardView.QUESTIONS) {
            // Server expects double encoded filters inside the url
            const doubleEncodedFilter = (0, UrlUtil_1.fixedEncodeURIComponent)(filter);
            return ((0, jsx_runtime_1.jsx)(ProductWizardQuestionsFooter_1.ProductWizardQuestionsFooter, { showResetBtn: isMultiFlow() || !isFirstQuestion(), question: selectedFlow.questions[questionIndex], lastQuestion: isLastQuestion(), onResetBtnClick: resetWizardState, onNexBtnClick: selectNextQuestion, totalProductsCount: totalProductsCount, resultsUrl: `${selectedFlow.categoryUrl}?filter=${doubleEncodedFilter}` }));
        }
        return null;
    };
    const popoverHeader = ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductWizardHeader, { questionsCount: (_a = selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.questions) === null || _a === void 0 ? void 0 : _a.length, activeQuestion: questionIndex + 1, showBackButton: shouldShowBackBtn(), showStepper: view === ProductWizardView.QUESTIONS, onBackBtnClick: goToPrevStep, onCloseBtnClick: handlePopoverClose }));
    (0, react_1.useEffect)(() => {
        if (selectedFlow) {
            const filterQuery = getFilterQueryParams(selectedFlow.questions);
            const shouldLoadTotalProductsCount = filter !== filterQuery;
            if (shouldLoadTotalProductsCount) {
                setFilter(filterQuery);
                loadTotalProductsCount(filterQuery);
            }
        }
    }, [selectedFlow]);
    (0, react_1.useEffect)(() => {
        if (questionIndex !== null && selectedFlow) {
            const shouldLoadQuestionOptionsProductsCount = (0, common_utils_1.isNullOrUndefined)(selectedFlow.questions[questionIndex].answers[0].productCount);
            shouldLoadQuestionOptionsProductsCount && loadQuestionOptionsProductsCount();
        }
    }, [questionIndex]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { withBackground: true, loading: showLoadingIcon, height: view !== ProductWizardView.INTRO && cotopaxi_1.PopoverHeight.LARGE, customHeader: popoverHeader, footerElement: getFooterElement(), anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, children: [productWizardData && view === ProductWizardView.INTRO && ((0, jsx_runtime_1.jsx)(ProductWizardIntro_1.ProductWizardIntro, { title: productWizardData.intro.title, subTitle: productWizardData.intro.subTitle, buttonLabel: productWizardData.intro.buttonLabel, loggedIn: loggedIn, userFirstName: userFirstName, startProductWizard: setFlowView })), flowOptions && view === ProductWizardView.FLOW_SELECTOR && ((0, jsx_runtime_1.jsx)(ProductWizardQuestionList_1.ProductWizardQuestionList, { title: flowOptions.title, subTitle: flowOptions.subTitle, inputType: common_types_1.ProductWizardInputType.RADIO, answers: flowOptions.answers, showAnswers: flowOptions.dataLoaded, onSelectChange: onFlowChange })), productWizardData && view === ProductWizardView.QUESTIONS && ((0, jsx_runtime_1.jsx)(ProductWizardQuestionList_1.ProductWizardQuestionList, { title: selectedFlow.questions[questionIndex].title, subTitle: selectedFlow.questions[questionIndex].subTitle, inputType: selectedFlow.questions[questionIndex].inputType, answers: selectedFlow.questions[questionIndex].answers, showAnswers: selectedFlow.questions[questionIndex].dataLoaded, onSelectChange: onAnswerChange }))] }));
};
exports.ProductWizard = ProductWizard;
