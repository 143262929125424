"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeGuideContainerV2 = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const SizeGuideV2_1 = require("../components/product-detail/size-guide-v2/SizeGuideV2");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const SizeGuideContainerV2 = () => {
    const { sizeGuideContent, sizeGuideContentUrl } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeGuideData)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const pdpTemplateName = (0, ConfigurationUtil_1.getPDPTemplateName)(aemConfiguration);
    const onSizeGuideClick = () => {
        dispatch(ProductActions_1.productActions.sizeGuideOpen());
        dispatch(AnalyticsActions_creators_1.analyticsActions.productDetailSizeGuideClick(pdpTemplateName));
    };
    return !sizeGuideContent && !sizeGuideContentUrl ? null : ((0, jsx_runtime_1.jsx)(SizeGuideV2_1.SizeGuideV2, { isPopup: Boolean(sizeGuideContent), sizeGuideContent: sizeGuideContent, sizeGuideContentUrl: sizeGuideContentUrl, onClick: onSizeGuideClick }));
};
exports.SizeGuideContainerV2 = SizeGuideContainerV2;
