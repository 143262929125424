"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCategoryAndTypeByTags = void 0;
const common_utils_1 = require("@as-react/common-utils");
const getCategoryAndTypeByTags = (mainWebShop, tags, types, categories) => tags && tags.length > 0
    ? tags
        .sort((tagA, tagB) => {
        const a = tagA.indexOf(`${mainWebShop}_department:`) === 0 ? 0 : 1;
        const b = tagB.indexOf(`${mainWebShop}_department:`) === 0 ? 0 : 1;
        return a - b;
    })
        .map((tag) => {
        var _a, _b;
        if (tag.indexOf(`${mainWebShop}_department:`) === 0) {
            const categoryLabel = (_a = categories.find(category => category.value === tag)) === null || _a === void 0 ? void 0 : _a.label;
            return categoryLabel ? (0, common_utils_1.capitalizeOnly)(categoryLabel).replace(/-/g, ' ') : '';
        }
        if (tag.indexOf('type:') === 0) {
            const typeLabel = (_b = types.find((type) => type.value === tag)) === null || _b === void 0 ? void 0 : _b.label;
            return typeLabel ? (0, common_utils_1.capitalizeOnly)(typeLabel).replace(/-/g, ' ') : '';
        }
        return '';
    })
        .filter(Boolean)
    : [];
exports.getCategoryAndTypeByTags = getCategoryAndTypeByTags;
