"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaferpay = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const ScriptLoaderUtil_1 = require("../util/ScriptLoaderUtil");
const SaferpayUtil_1 = require("../util/SaferpayUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const useSaferpay = (paymentMethods, enabled = true) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [hasError, setHasError] = (0, react_1.useState)(false);
    const [validation, setValidation] = (0, react_1.useState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { fields } = (0, ConfigurationUtil_1.getSaferpayConfig)(aemConfiguration);
    const loadSaferpayScript = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield ScriptLoaderUtil_1.default.loadScript(fields.scriptUrl, SaferpayUtil_1.SAFERPAY_SCRIPT_ID, true);
            setIsReady(true);
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        enabled && !isReady && loadSaferpayScript();
    }, [enabled, isReady]);
    (0, react_1.useEffect)(() => {
        if (!isReady || !enabled) {
            return;
        }
        try {
            window.SaferpayFields.init({
                accessToken: fields.accessToken,
                url: fields.apiUrl,
                paymentMethods,
                placeholders: {
                    cardnumber: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CARDNUMBER}.placeholder`),
                    holdername: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.HOLDERNAME}.placeholder`),
                    expiration: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.EXPIRATION}.placeholder`),
                    cvc: '',
                },
                style: SaferpayUtil_1.andesSaferpayFieldStyles,
                onError: () => setHasError(true),
                onValidated: (e) => {
                    setHasError(false);
                    return setValidation(prev => (Object.assign(Object.assign({}, prev), { [e.fieldType]: e })));
                },
            });
        }
        catch (e) {
            setHasError(true);
        }
    }, [enabled, isReady]);
    return {
        hasError,
        validation,
    };
};
exports.useSaferpay = useSaferpay;
