"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketCouponForm = exports.BASKET_COUPON_WEBCODE_ID = exports.BASKET_COUPON_CODE_ID = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const initialValues = {
    code: '',
    webcode: '',
};
exports.BASKET_COUPON_CODE_ID = 'basket-coupon-code';
exports.BASKET_COUPON_WEBCODE_ID = 'basket-coupon-webcode';
const ValueChangeHandler = ({ value, onChange }) => {
    const { values: { code }, setFieldValue, } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        code.length && setFieldValue('code', code.trim());
    }, [setFieldValue, code]);
    (0, react_1.useEffect)(() => {
        onChange(value);
    }, [value, onChange]);
    return null;
};
const BasketCouponForm = ({ isPaymentPage = false, isShowWebCode, onSubmit, validate, onCodeChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { onSubmit: onSubmit, validate: validate, initialValues: initialValues, name: "basket-coupon-form", autoComplete: cotopaxi_1.AutoComplete.OFF, children: ({ errors, touched, values }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ValueChangeHandler, { onChange: onCodeChange, value: values.code }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 6, desktop: isPaymentPage ? 6 : 12, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: isShowWebCode ? 2 : 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: exports.BASKET_COUPON_CODE_ID, children: t('checkout:checkout.basket.voucher.barcode.label') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: exports.BASKET_COUPON_CODE_ID, name: "code", state: errors.code && touched.code && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input, dataQA: "evoucher_code", errorDataQA: "evoucher_error_message" })] }) }), isShowWebCode && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 3, desktop: isPaymentPage ? 3 : 12, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginLeft: 2 } }, desktop: {
                                    spacing: { marginLeft: isPaymentPage ? 2 : 0 },
                                }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: exports.BASKET_COUPON_WEBCODE_ID, children: t('checkout:checkout.basket.voucher.webcode.label') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: exports.BASKET_COUPON_WEBCODE_ID, name: "webcode", state: errors.webcode && touched.webcode && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input, dataQA: "evoucher_webcode", errorDataQA: "evoucher_error_message" })] }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, text: t(isPaymentPage ? 'buy:buy.payment.voucher.submit' : 'checkout:checkout.basket.voucher.submit'), variant: !isPaymentPage && cotopaxi_1.ButtonVariant.OUTLINED, dataQA: "evoucher_confirm" })] })) }));
};
exports.BasketCouponForm = BasketCouponForm;
