"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_utils_1 = require("@as-react/common-utils");
class SelectorUtil {
    static getSelectedColor(product) {
        if ((0, common_utils_1.isNullOrUndefined)(product) || (0, common_utils_1.isNullOrUndefined)(product.productColorVariations)) {
            return null;
        }
        return product.productColorVariations.find(productColorVariation => productColorVariation.colorId === product.selectedColorId);
    }
    static getSelectedSku(product, selectedColor) {
        return selectedColor.sizes.find(size => size.sku === product.selectedSku);
    }
    static getAvailableColors(product) {
        return product.productColorVariations.filter((productColor) => productColor.active &&
            productColor.visible &&
            (productColor.availability > 0 || productColor.colorId === product.selectedColorId));
    }
    static getPriceInformation(product, colorVariation) {
        if ((0, common_utils_1.isNullOrUndefined)(product) || (0, common_utils_1.isEmptyObject)(product) || (0, common_utils_1.isNullOrUndefined)(colorVariation))
            return null;
        if (!(0, common_utils_1.isNullOrUndefined)(product.selectedSku)) {
            const colorVariationSize = SelectorUtil.getSelectedSku(product, colorVariation);
            if (!(0, common_utils_1.isNullOrUndefined)(colorVariationSize) && !(0, common_utils_1.isNullOrUndefined)(colorVariationSize.prices)) {
                return {
                    hasDiscount: Boolean(colorVariation.hasDiscount),
                    prices: colorVariationSize.prices,
                };
            }
        }
        return {
            hasDiscount: Boolean(colorVariation.hasDiscount),
            prices: colorVariation.prices,
        };
    }
    static getDynamicSavingsInformation(product, colorVariation) {
        if (!(0, common_utils_1.isNullOrUndefined)(product.selectedSku)) {
            const colorVariationSize = SelectorUtil.getSelectedSku(product, colorVariation);
            if (!(0, common_utils_1.isNullOrUndefined)(colorVariationSize)) {
                return {
                    productDiscountPercentage: Number(colorVariationSize.productDiscountPercentage),
                    productDiscountAmount: Number(colorVariationSize.productDiscountAmount),
                };
            }
        }
        return {
            productDiscountPercentage: colorVariation.colourDiscountPercentage,
            productDiscountAmount: colorVariation.colourDiscountAmount,
        };
    }
}
exports.default = SelectorUtil;
