"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const PartialSearchResultsTitles_1 = require("../components/common/partial-search-results-titles/PartialSearchResultsTitles");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductGroupContainer_1 = require("./product/ProductGroupContainer");
const i18nConstants_1 = require("../constants/i18nConstants");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const ProductsAPI_1 = require("../api/ProductsAPI");
const ComparePopupContainer_1 = require("./ComparePopupContainer");
const PartialsSearchActions_creators_1 = require("../redux/partialsSearch/actions/PartialsSearchActions.creators");
const common_utils_1 = require("@as-react/common-utils");
const PartialSearchResultsContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const [itemCount, setItemCount] = (0, react_1.useState)({});
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const executionStatus = (0, react_redux_1.useSelector)((state) => state.lister.executionStatus);
    const alternativeQueryResults = (0, react_redux_1.useSelector)((state) => state.lister.alternativeQueryResults);
    const productGroupUniqueIds = (0, react_redux_1.useSelector)((state) => state.lister.productGroupUniqueIds);
    const partialSearchResults = (0, react_redux_1.useSelector)((state) => state.lister.partialSearchResults);
    const queryInformation = (0, react_redux_1.useSelector)((state) => state.url.q);
    const urlState = (0, react_redux_1.useSelector)((state) => state.url);
    const productGroup = (0, react_redux_1.useSelector)((state) => state.productGroup);
    const { alternativeBrand, maybeMeant, maybeLike } = partialSearchResults;
    (0, react_1.useEffect)(() => {
        dispatch(PartialsSearchActions_creators_1.partialSearchActions.createInitializeAction());
    }, []);
    const hasHeaderValidTitleAndDescription = () => {
        if (executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_BRAND &&
            (alternativeBrand === null || alternativeBrand === void 0 ? void 0 : alternativeBrand.title) &&
            (alternativeBrand === null || alternativeBrand === void 0 ? void 0 : alternativeBrand.description)) {
            return true;
        }
        else if (executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_QUERIES &&
            (maybeMeant === null || maybeMeant === void 0 ? void 0 : maybeMeant.title) &&
            (maybeMeant === null || maybeMeant === void 0 ? void 0 : maybeMeant.description)) {
            return true;
        }
        else if (executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_SPELL_UNCHANGED &&
            (maybeLike === null || maybeLike === void 0 ? void 0 : maybeLike.title) &&
            (maybeLike === null || maybeLike === void 0 ? void 0 : maybeLike.description)) {
            return true;
        }
        return false;
    };
    const isAlternativeBrandSuggestion = () => executionStatus === common_types_1.SearchResultsExecutionStatusEnums.ALT_BRAND;
    const getAlternativeBrandSuggestionTitle = () => alternativeQueryResults.map(item => item.alternativeQuery)[0];
    const getFormattedTitles = productGroupIdKey => {
        const availableTerms = productGroupUniqueIds[productGroupIdKey];
        if (productGroup[productGroupIdKey].length < 1) {
            return null;
        }
        const initialSearchTerms = queryInformation.split(' ');
        const formattedTitles = initialSearchTerms.map(item => {
            return { [item]: availableTerms.includes(item.toLowerCase()) };
        });
        return initialSearchTerms.map((item, index) => !formattedTitles[index][item] ? ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("del", { children: item.trim() }), ' '] }, index)) : ((0, jsx_runtime_1.jsxs)("span", { children: [item.trim(), " "] }, index)));
    };
    const getSearchUrl = productGroupKey => `${aemConfiguration.services.fredHopper.search}?mainWebShop=${aemConfiguration.mainWebShop}&fictiveWebShop=${aemConfiguration.fictiveWebShop}&anaLang=${aemConfiguration.lang}&locale=${aemConfiguration.locale}&platform=${aemConfiguration.isShopLanding
        ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
        : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site}&size=${urlState.size}&page=${urlState.page}&query=${encodeURIComponent(productGroupUniqueIds[productGroupKey])}`;
    (0, react_1.useEffect)(() => {
        const fetchData = () => {
            let items = {};
            Object.keys(productGroupUniqueIds).map((key) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                const searchUrl = getSearchUrl(key);
                const nbrOfItems = yield ProductsAPI_1.default.getItemCount(searchUrl);
                items = Object.assign(Object.assign({}, items), { [`${key}`]: nbrOfItems });
                setItemCount(items);
            }));
        };
        fetchData();
    }, []);
    const getLabelText = (productGroupKey) => {
        const searchTerm = (0, common_utils_1.capitalizeOnly)(productGroupUniqueIds[productGroupKey]);
        return `${t('product.lister.partial.search.button')} ${searchTerm} (${itemCount[productGroupKey]})`;
    };
    (0, react_1.useEffect)(() => {
        const hasUndefinedItems = Object.keys(productGroupUniqueIds).some(productGroupKey => itemCount[productGroupKey] === undefined);
        if (!hasUndefinedItems) {
            setShouldRender(true);
        }
    }, [itemCount]);
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { children: [hasHeaderValidTitleAndDescription() && ((0, jsx_runtime_1.jsx)(PartialSearchResultsTitles_1.PartialSearchResultsTitles, { alternativeBrand: alternativeBrand, maybeMeant: maybeMeant, maybeLike: maybeLike, executionStatus: executionStatus, initialSearchTerm: queryInformation, alternativeQueryResults: alternativeQueryResults })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 5 } }, children: Object.keys(productGroupUniqueIds).map(productGroupKey => ((0, jsx_runtime_1.jsxs)("div", { children: [isAlternativeBrandSuggestion() ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, size: cotopaxi_1.HeadingSize.M, children: getAlternativeBrandSuggestionTitle() })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, size: cotopaxi_1.HeadingSize.M, children: getFormattedTitles(`${productGroupKey}`) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { desktop: { spacing: { marginTop: 5 } }, mobile: { spacing: { marginTop: 3 } }, children: [(0, jsx_runtime_1.jsx)(ProductGroupContainer_1.default, { tileFormat: "normal", collapsible: false, productGroupId: productGroupKey }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginBottom: 7 } }, tablet: { spacing: { marginBottom: 6 } }, mobile: { spacing: { marginBottom: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { href: `${root}lister.html?q=${encodeURIComponent(productGroupUniqueIds[productGroupKey])}`, text: getLabelText(productGroupKey) }) }) })] })] }, productGroupKey))) }), featureToggles.product_compare && (0, jsx_runtime_1.jsx)(ComparePopupContainer_1.default, {})] }));
};
exports.default = PartialSearchResultsContainer;
