"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeURLParameter = exports.fixedEncodeURIComponent = exports.concatUrls = void 0;
const concatUrls = (basePath, appendix) => {
    const normalizedBasePath = basePath.endsWith('/') ? basePath.slice(0, -1) : basePath;
    const normalizedAppendix = appendix.startsWith('/') ? appendix.substring(1) : appendix;
    return `${normalizedBasePath}/${normalizedAppendix}`;
};
exports.concatUrls = concatUrls;
// RFC 3986 - compliant encoding
const fixedEncodeURIComponent = (text) => {
    return encodeURIComponent(text).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
};
exports.fixedEncodeURIComponent = fixedEncodeURIComponent;
const removeURLParameter = (url, parameter) => {
    const [path, params] = url.split('?');
    if (params) {
        const urlParams = new URLSearchParams(params);
        urlParams.delete(parameter);
        const paramsRes = urlParams.toString();
        return paramsRes ? `${path}?${paramsRes}` : path;
    }
    return url;
};
exports.removeURLParameter = removeURLParameter;
