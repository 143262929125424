"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftCardInteractionBlockContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ColorSwatchesContainer_1 = require("./ColorSwatchesContainer");
const AdvantagesBlock_1 = require("../components/product-detail/advantages-block/AdvantagesBlock");
const PriceAmountWithCTAContainer_1 = require("./PriceAmountWithCTAContainer");
const GiftCardInteractionBlockContainer = ({ aemConfiguration, advantages, }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ColorSwatchesContainer_1.default, {}), (0, jsx_runtime_1.jsx)(PriceAmountWithCTAContainer_1.default, { isShopLanding: (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.XLARGE }), (0, jsx_runtime_1.jsx)(AdvantagesBlock_1.default, { advantages: advantages })] }));
exports.GiftCardInteractionBlockContainer = GiftCardInteractionBlockContainer;
