"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FUNCTIONAL_KEY = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const CookiePreferences_1 = require("./CookiePreferences");
const CookiePrivacy_1 = require("./CookiePrivacy");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const EventUtil_1 = require("../../../util/EventUtil");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const BrowserUtil_1 = require("../../../util/BrowserUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const ImageUtil_1 = require("../../../util/ImageUtil");
exports.FUNCTIONAL_KEY = 'functional';
const CookieMessage = ({ cookieConsentAcceptAll, analyticsCookieConsentOpenPreferences, cookieConsentUpdated, cookieConsentSettingAvailable, cookieConsentSettings, logo, panel1, panel2, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [open, setOpen] = (0, react_1.useState)(!LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED));
    const [isCookiePreferencesOpen, setIsCookiePreferencesOpen] = (0, react_1.useState)(false);
    const [isCookiePreferenceOpen, setIsCookiePreferenceOpen] = (0, react_1.useState)(false);
    const [isReOpen, setIsReOpen] = (0, react_1.useState)(false);
    const [isCookiePrivacyOpen, setIsCookiePrivacyOpen] = (0, react_1.useState)(false);
    const [checkedPreferences, setCheckedPreferences] = (0, react_1.useState)({});
    const [initialCheckedPreferences, setInitialCheckedPreferences] = (0, react_1.useState)({});
    const [isCookieModalOpen, setIsCookieModalOpen] = (0, react_1.useState)(false);
    const [privacyData, setPrivacyData] = (0, react_1.useState)('');
    const [preferenceData, setPreferenceData] = (0, react_1.useState)('');
    const [isBrowserInstagram, setIsBrowserInstagram] = (0, react_1.useState)(false);
    const isCookiePreferencesOpenPrevValue = (0, cotopaxi_1.usePrevious)(isCookiePreferencesOpen);
    const onCookiePreferencesClose = () => {
        setIsCookiePreferencesOpen(false);
        setCheckedPreferences(initialCheckedPreferences);
    };
    const onCookiePrivacyClose = () => {
        setIsCookiePrivacyOpen(false);
        setOpen(true);
    };
    const convertCookieGroupsToObject = (data = cookieConsentSettings) => {
        if (panel2 === null || panel2 === void 0 ? void 0 : panel2.cookieGroups) {
            return panel2.cookieGroups.reduce((obj, item) => {
                obj[item.key] = data[item.key] || item.key === exports.FUNCTIONAL_KEY;
                return obj;
            }, checkedPreferences);
        }
        return {};
    };
    const handlePopoverClose = () => {
        setOpen(false);
    };
    const showCookiePreference = (cookieGroup) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const detailLink = (_a = panel2.cookieGroups.find((group) => group.key === cookieGroup.key)) === null || _a === void 0 ? void 0 : _a.detailLink;
        const axiosConfig = {
            method: 'GET',
            url: detailLink,
        };
        try {
            const res = yield axios_1.default.request(axiosConfig);
            setPreferenceData(res.data);
            setIsCookiePreferenceOpen(true);
            setIsCookiePreferencesOpen(false);
        }
        catch (_b) { }
    });
    const getLogoUrl = () => (0, ImageUtil_1.getImageFromCdn)(logo, imageCdnPrefix);
    (0, react_1.useEffect)(() => {
        setCheckedPreferences(convertCookieGroupsToObject());
        setInitialCheckedPreferences(convertCookieGroupsToObject());
        setIsBrowserInstagram(BrowserUtil_1.default.isBrowserInstagram());
        EventUtil_1.default.listen(EventEnum_1.EventEnum.COOKIE_CONSENT_PREFERENCES, () => {
            const cookieConsentSettingsUpdated = (0, common_utils_1.cookieConsentSettingsParser)(decodeURIComponent(common_utils_1.CookieUtil.get(common_types_1.Cookie.CONSENT_SETTING)));
            setIsReOpen(true);
            setIsCookiePreferencesOpen(true);
            setCheckedPreferences(convertCookieGroupsToObject(cookieConsentSettingsUpdated));
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (!LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED) &&
            (isCookiePreferencesOpen || isCookiePrivacyOpen || isReOpen)) {
            handlePopoverClose();
        }
        else if (!isCookiePreferencesOpen && !isCookiePreferenceOpen && !isReOpen) {
            setOpen(true);
        }
    }, [isCookiePreferencesOpen, isCookiePrivacyOpen, isReOpen]);
    (0, react_1.useEffect)(() => {
        if (isCookiePreferencesOpen !== isCookiePreferencesOpenPrevValue && isCookiePreferencesOpen) {
            analyticsCookieConsentOpenPreferences(checkedPreferences);
        }
    }, [isCookiePreferencesOpen, isCookiePreferencesOpenPrevValue]);
    (0, react_1.useEffect)(() => {
        if (LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED)) {
            setOpen(false);
            setIsCookieModalOpen(false);
        }
        if (!isCookieModalOpen && !LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED)) {
            setOpen(true);
            setIsCookieModalOpen(true);
        }
    }, [isCookieModalOpen]);
    const showCookiePreferences = (e) => {
        e.preventDefault();
        setIsCookiePreferencesOpen(true);
    };
    const onCookiePreferenceClose = () => {
        setPreferenceData('');
        setIsCookiePreferenceOpen(false);
        setIsCookiePreferencesOpen(true);
    };
    const showCookiePrivacy = (e) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const axiosConfig = {
            method: 'GET',
            url: panel1.privacyLinkHref,
        };
        try {
            const res = yield axios_1.default.request(axiosConfig);
            setPrivacyData(res.data);
            setIsCookiePrivacyOpen(true);
        }
        catch (_c) { }
    });
    const saveCookiePreferences = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const axiosConfig = {
            method: 'POST',
            data: checkedPreferences,
            url: `${servicesEndpoint}api/eprivacy/consent`,
            params: defaultRequestParameters,
            withCredentials: true,
        };
        try {
            yield axios_1.default.request(axiosConfig);
            setIsCookiePreferenceOpen(false);
            setIsCookiePreferencesOpen(false);
            handlePopoverClose();
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.COOKIE_CONSENT_PREFERENCES_UPDATED);
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED, true);
            setIsReOpen(true);
        }
        catch (_d) { }
    });
    const updateCookiePreferences = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield saveCookiePreferences();
        cookieConsentUpdated(checkedPreferences);
    });
    const changeCookiePreference = (e) => {
        const { value } = e.currentTarget;
        if (value === exports.FUNCTIONAL_KEY) {
            return;
        }
        setCheckedPreferences((prevPreferences) => (Object.assign(Object.assign({}, prevPreferences), { [value]: !prevPreferences[value] })));
    };
    const acceptAllCookies = () => {
        setCheckedPreferences(Object.keys(checkedPreferences).reduce((obj, item) => {
            obj[item] = true;
            return obj;
        }, checkedPreferences));
        cookieConsentAcceptAll(checkedPreferences);
        saveCookiePreferences();
    };
    if ((LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED) && !isCookiePreferencesOpen && !isCookiePreferenceOpen) ||
        (!isReOpen && (cookieConsentSettingAvailable || !panel1))) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { noClose: true, disableClickOutside: true, anchorEl: open, type: cotopaxi_1.PopoverType.MODAL_SSR, position: cotopaxi_1.PopoverPosition.BOTTOM, onClose: handlePopoverClose, headerAlign: cotopaxi_1.PopoverHeaderAlign.CENTER, withHeader: !isMobile, noAnimation: true, title: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: getLogoUrl(), size: cotopaxi_1.ImageSize.COOKIE_BRAND_LOGO }), children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            marginTop: 2,
                            marginLeft: 2,
                            marginRight: 2,
                            marginBottom: isBrowserInstagram ? 10 : 2,
                        },
                    }, tablet: {
                        spacing: {
                            marginTop: 0,
                            marginLeft: 4,
                            marginRight: 4,
                            marginBottom: 4,
                        },
                    }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginBottom: 1,
                                },
                            }, tablet: {
                                spacing: {
                                    marginBottom: 1.5,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: panel1.title }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, html: panel1.description }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: showCookiePrivacy, variant: cotopaxi_1.ButtonVariant.LINK, text: panel1.privacyLinkText }) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginTop: 3,
                                    marginBottom: 1.5,
                                },
                            }, tablet: {
                                spacing: {
                                    marginTop: 5,
                                    marginBottom: 2,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { id: "accept-all-cookies", text: panel1.btnLabel, fill: true, onClick: acceptAllCookies, iconRight: {
                                    name: cotopaxi_1.IconName.COOKIE_BITE_SOLID,
                                    size: cotopaxi_1.IconSize.MEDIUM,
                                } }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: showCookiePreferences, variant: cotopaxi_1.ButtonVariant.LINK, text: panel1.moreOptions })] }) }), (0, jsx_runtime_1.jsx)(CookiePreferences_1.default, { checkedPreferences: checkedPreferences, panel2: panel2, isCookiePreferenceOpen: isCookiePreferenceOpen, onCookiePreferenceClose: onCookiePreferenceClose, setIsCookiePreferenceOpen: setIsCookiePreferenceOpen, isCookiePreferencesOpen: isCookiePreferencesOpen, onCookiePreferencesClose: onCookiePreferencesClose, logoUrl: getLogoUrl(), saveCookiePreferences: updateCookiePreferences, changeCookiePreference: changeCookiePreference, showCookiePreference: showCookiePreference, preferenceData: preferenceData }), (0, jsx_runtime_1.jsx)(CookiePrivacy_1.default, { privacyData: privacyData, isCookiePrivacyOpen: isCookiePrivacyOpen, onCookiePrivacyClose: onCookiePrivacyClose, logoUrl: getLogoUrl() })] }));
};
exports.default = CookieMessage;
