"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCareCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductCareCTA = ({ alt, buttonVariant, label, linkPath, targetIsBlank, horizontalAlign, }) => {
    const isLinkStyle = buttonVariant === cotopaxi_1.ButtonVariant.LINK || buttonVariant === cotopaxi_1.ButtonVariant.LINK_SUBTLE;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: horizontalAlign || cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: buttonVariant, size: isLinkStyle ? cotopaxi_1.ButtonSize.SMALL : cotopaxi_1.ButtonSize.NORMAL, href: linkPath, target: targetIsBlank ? cotopaxi_1.LinkTarget.BLANK : null, text: label, title: alt, iconRight: {
                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                size: cotopaxi_1.IconSize.MINOR,
            } }) }));
};
exports.ProductCareCTA = ProductCareCTA;
