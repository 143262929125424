"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const ProductUtil_1 = require("../util/ProductUtil");
const ProductsAPI_1 = require("../api/ProductsAPI");
const DeliveryPromiseAPI_1 = require("../api/DeliveryPromiseAPI");
const MiniPDP_1 = require("../components/product-detail/mini-pdp/MiniPDP");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductDetailAPI_1 = require("../api/ProductDetailAPI");
const common_utils_1 = require("@as-react/common-utils");
const ActionEnums_1 = require("../enums/ActionEnums");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const MiniPDPContainer = ({ productCode, colorId, deliveryPromiseExplanations, selectedSku, displayPackShotImagesFirst, popoverSize = cotopaxi_1.PopoverSize.DRAWER_LARGE, submitText, onClose, onSubmit, miniPDPProps, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const authenticationConfig = (0, ConfigurationUtil_1.getAuthenticationConfig)(aemConfiguration);
    const [imagesByColor, setImagesByColor] = (0, react_1.useState)(null);
    const [productTranslatedProperties, setProductTranslatedProperties] = (0, react_1.useState)(null);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const [deliveryPromise, setDeliveryPromise] = (0, react_1.useState)(null);
    const [selectedSizes, setSelectedSizes] = (0, react_1.useState)([]);
    const wishlistProducts = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishListProducts)());
    const [product, setProduct] = (0, react_1.useState)(null);
    const oldProductCode = (0, cotopaxi_1.usePrevious)(productCode);
    const oldColorId = (0, cotopaxi_1.usePrevious)(colorId);
    const onWishListToggle = (wishListProduct) => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    const _onClose = () => setTimeout(() => {
        setShouldRender(false);
        onClose();
    }, cotopaxi_1.POPOVER_ANIMATION_DURATION);
    const onSizeChange = (productId, selectedColorId, sku) => {
        if (selectedColorId === colorId) {
            const sizeIndex = selectedSizes.findIndex((size) => size.productId === productId && size.colorId === selectedColorId);
            if (sizeIndex !== -1) {
                selectedSizes[sizeIndex].sku = sku;
            }
            else {
                selectedSizes.push({
                    sku,
                    colorId: selectedColorId,
                    productId,
                });
            }
            setSelectedSizes(selectedSizes);
        }
    };
    const defaultSize = (0, react_1.useMemo)(() => {
        if (!product) {
            return null;
        }
        const productSKU = selectedSku ? `${selectedSku}` : product.sku ? `${product.sku}` : null;
        const sizeIndex = selectedSizes.findIndex((size) => size.productId === product.productId && size.colorId === colorId);
        return sizeIndex === -1 ? productSKU : selectedSizes[sizeIndex].sku;
    }, [colorId, product, selectedSizes]);
    const clickAndCollectOnly = product ? (0, ProductUtil_1.isClickAndCollectOnly)(product) : false;
    const inStoreOnly = product ? (0, ProductUtil_1.isInStoreOnly)(product) : false;
    const sortImages = (_images) => {
        if (displayPackShotImagesFirst) {
            const packShotImages = [];
            const modelImages = [];
            _images.forEach(image => {
                image.picture.includes('_packshot') ? packShotImages.push(image) : modelImages.push(image);
            });
            return packShotImages.concat(modelImages.sort((x, y) => x.sortOrder - y.sortOrder));
        }
        return _images.sort((x, y) => x.sortOrder - y.sortOrder);
    };
    const getPriceOverlayByColor = (colors) => colors.reduce((acc, color) => (Object.assign(Object.assign({}, acc), { [color.colourId]: color.priceOverlay })), {});
    const fetchProductData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let productData = null;
        let productTileData = null;
        let productPrice = null;
        let productAvailability = null;
        setImagesByColor(null);
        try {
            productData = yield ProductsAPI_1.default.getProductByProductCode(servicesEndpoint, mainWebShop, productCode);
        }
        catch (_b) { }
        if (!productData) {
            return;
        }
        setProductTranslatedProperties((0, common_utils_1.filterDataFromObjectByKey)(productData.productTranslatedProperties, defaultRequestParameters.language));
        try {
            productTileData = yield ProductsAPI_1.default.getSingleProductData(servicesEndpoint, mainWebShop, defaultRequestParameters.anaLang, defaultRequestParameters.language, defaultRequestParameters.shopId, productCode, colorId.toString(), undefined, true);
        }
        catch (_c) { }
        if (!productTileData) {
            return;
        }
        try {
            productPrice = yield ProductDetailAPI_1.default.getProductDetailPriceByProductId(servicesEndpoint, defaultRequestParameters.mainWebshop, defaultRequestParameters.shopId, productTileData.productId);
        }
        catch (_d) { }
        if (!productPrice) {
            return;
        }
        try {
            productAvailability = yield ProductDetailAPI_1.default.getProductDetailAvailabilityByProductId(servicesEndpoint, defaultRequestParameters.mainWebshop, String(productTileData.productId));
        }
        catch (_e) { }
        if (productAvailability) {
            productTileData = (0, common_utils_1.mergeProductAvailabilityToProductLister)(productTileData, productAvailability);
        }
        const mergedProduct = (0, common_utils_1.mergeProductPricesToProductLister)(productTileData, productPrice);
        const selectedProductColor = productTileData.colours.find(color => color.colourId === colorId);
        const possibleSkus = selectedProductColor === null || selectedProductColor === void 0 ? void 0 : selectedProductColor.sizes.map((productColorSize) => productColorSize.sku);
        if ((possibleSkus === null || possibleSkus === void 0 ? void 0 : possibleSkus.length) === 1) {
            mergedProduct.sku = parseInt(possibleSkus[0], 10);
        }
        setProduct(mergedProduct);
        const images = (_a = productData.productColorVariations) === null || _a === void 0 ? void 0 : _a.map((color) => {
            const colorVariation = productData.productColorVariations.find((tmpColorVariation) => color.colorId === tmpColorVariation.colorId);
            return {
                colorId: colorVariation.colorId,
                images: colorVariation.images,
            };
        });
        if (!!images && images.length > 0) {
            const sortedImages = images.map(image => ({
                colorId: image.colorId,
                images: sortImages(image.images),
            }));
            setImagesByColor(sortedImages);
            setShouldRender(true);
        }
    });
    const fetchDeliveryPromise = (productId, skuId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const fetchedDeliveryPromise = yield DeliveryPromiseAPI_1.default.getDeliveryPromise(servicesEndpoint, skuId, productId, authenticationConfig);
        !!fetchedDeliveryPromise && setDeliveryPromise(fetchedDeliveryPromise);
    });
    const removeDeliveryPromise = () => setDeliveryPromise(null);
    const handleAddToBasketMiniPdp = (extensiveTileProduct, location) => {
        dispatch(BasketActions_creators_1.basketActions.createAddExtensiveTileProductAction(extensiveTileProduct, location));
    };
    const handleOnSubmit = (selectedColorId, sku) => {
        const basketProduct = { product: product, colorId: selectedColorId, sku };
        if (onSubmit) {
            onSubmit(basketProduct);
        }
        else {
            handleAddToBasketMiniPdp(basketProduct, ActionEnums_1.ProductAddToBasketLocations.MINI_PDP);
        }
    };
    (0, react_1.useEffect)(() => {
        if (productCode && productCode !== oldProductCode && colorId && colorId !== oldColorId) {
            fetchProductData();
        }
    }, [productCode, oldProductCode, colorId, oldColorId]);
    if (!shouldRender || !imagesByColor) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(MiniPDP_1.MiniPDP, Object.assign({ isOpen: !!product && !!colorId, aemConfiguration: aemConfiguration, onSubmit: handleOnSubmit, colorId: colorId, clickAndCollectOnly: clickAndCollectOnly, inStoreOnly: inStoreOnly, productId: product.productId, productBrand: product.brand, productCode: product.productCode, productTitle: product.title, productColors: product.colours, productImagesByColor: imagesByColor, onClose: _onClose, onSizeChange: onSizeChange, seoUrl: product.seoUrl, defaultSize: defaultSize, getDeliveryPromise: fetchDeliveryPromise, removeDeliveryPromise: removeDeliveryPromise, deliveryPromise: deliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, wishlistProducts: wishlistProducts, onWishListToggle: onWishListToggle, popoverSize: popoverSize, submitText: submitText, genderFeatureId: product.featureValueGenderId, ourPlanet: Boolean(product.ourPlanet), description: productTranslatedProperties.information, priceOverlayByColor: getPriceOverlayByColor(product.colours) }, miniPDPProps)));
};
exports.MiniPDPContainer = MiniPDPContainer;
