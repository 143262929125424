"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const BASKET_QUANTITY_SELECTOR = 'basket-quantity';
const BasketQuantityField = ({ quantity, setQuantity }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [value, setValue] = (0, react_1.useState)(quantity.toString());
    const enhanceValue = (val) => {
        let result = val;
        if (val === '0') {
            result = '1';
        }
        else if (val === '') {
            result = quantity.toString();
        }
        return Math.abs(parseInt(result, 10)).toString();
    };
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleBlur = (event) => {
        const { value: val } = event.target;
        const enhancedValue = enhanceValue(val);
        setValue(enhancedValue);
        setQuantity(parseInt(enhancedValue, 10));
    };
    (0, react_1.useEffect)(() => {
        const enhancedValue = enhanceValue(value);
        const timer = window.setTimeout(() => {
            setValue(enhancedValue);
            setQuantity(parseInt(enhancedValue, 10));
        }, GeneralConstants_1.DELAY.DELAY_2000);
        return () => window.clearTimeout(timer);
    }, [value]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, htmlFor: BASKET_QUANTITY_SELECTOR, children: t('pdp.quantity') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: { marginTop: 1, marginBottom: 1 },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Input, { type: cotopaxi_1.InputType.NUMBER, textAlign: cotopaxi_1.InputTextAlign.CENTER, name: BASKET_QUANTITY_SELECTOR, id: BASKET_QUANTITY_SELECTOR, onChange: handleChange, onBlur: handleBlur, value: value, min: 1 }) })] }));
};
exports.default = BasketQuantityField;
