"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const ContentActions_creators_1 = require("../../redux/content/actions/ContentActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const ContentOverviewForm_1 = require("../../components/content-lister/content-overview-form/ContentOverviewForm");
const ContentListerHeadingContainer = ({ hideTitle, title, hideSearch, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const searchTerm = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetSearchTerm)()) || '';
    const [valuesForm, setValuesForm] = (0, react_1.useState)({
        contentSearch: searchTerm,
    });
    const [valueChanged, setValueChanged] = (0, react_1.useState)(false);
    const handleResetValues = () => {
        setValuesForm(ContentOverviewForm_1.initialValuesContentOverviewForm);
        setValueChanged(false);
        dispatch(ContentActions_creators_1.contentOverviewActions.findContentAction(ContentOverviewForm_1.initialValuesContentOverviewForm.contentSearch));
    };
    const onSearchChange = () => setValueChanged(true);
    const handleSubmitSearch = (values) => {
        if (valueChanged && values.contentSearch !== ContentOverviewForm_1.initialValuesContentOverviewForm.contentSearch) {
            dispatch(ContentActions_creators_1.contentOverviewActions.findContentAction(values.contentSearch));
            setValueChanged(false);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: hideTitle ? cotopaxi_1.GroupAlign.RIGHT : cotopaxi_1.GroupAlign.SPACE_BETWEEN, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, inline: true, children: [!hideTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.TITLE, children: title })), !hideSearch && ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(ContentOverviewForm_1.ContentOverviewForm, { initialValues: valuesForm, onSubmit: handleSubmitSearch, handleResetValues: handleResetValues, onSearchChange: onSearchChange }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.SMALL }) })] }));
};
exports.default = ContentListerHeadingContainer;
