"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailFeatureCategoriesContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const ProductCategorizedFeatureTable_1 = require("../../components/product-detail/product-categorized-features/ProductCategorizedFeatureTable");
const i18nConstants_1 = require("../../constants/i18nConstants");
const useGlossaryHighlightInPopover_1 = require("../../hooks/useGlossaryHighlightInPopover");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductUtil_1 = require("../../util/ProductUtil");
const ProductDetailFeatureCategoriesContainer = ({ includedCategories, inPopover, isPopoverOpen }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const categories = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductTechnicalCategories)());
    const scrollContainer = (0, react_1.useRef)(null);
    const includedCategoriesIDs = includedCategories.map(({ id }) => Number(id));
    const filteredCategories = categories
        .map(ProductUtil_1.filterProductCategoryEmptyFeatures)
        .filter(category => category.features.length && includedCategoriesIDs.includes(category.categoryId));
    (0, useGlossaryHighlightInPopover_1.useGlossaryHighlightInPopover)(scrollContainer, isPopoverOpen);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: inPopover, wrapper: children => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, fullHeight: true, scrollContainerRef: scrollContainer, children: children })), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: inPopover ? 0 : 2 } }, children: filteredCategories.map((category, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: index === 0 ? 0 : 3, marginLeft: inPopover ? 2 : 0 } }, tablet: { spacing: { marginTop: index === 0 ? 0 : 4, marginLeft: inPopover ? 4 : 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, type: inPopover && cotopaxi_1.HeadingType.EXTRA_SMALL_TITLE, size: !inPopover && cotopaxi_1.HeadingSize.XXS, children: category.categoryName || t('pdp.other.features') }) }), (0, jsx_runtime_1.jsx)(ProductCategorizedFeatureTable_1.default, { features: category.features, tableContentSpacing: {
                            firstColumn: {
                                mobile: {
                                    spacing: {
                                        paddingTop: 1.5,
                                        paddingBottom: 1.5,
                                        paddingLeft: 2,
                                        paddingRight: 1,
                                    },
                                },
                                tablet: { spacing: { paddingLeft: inPopover ? 4 : 0, paddingRight: 2 } },
                            },
                            secondColumn: {
                                mobile: {
                                    spacing: {
                                        paddingTop: 1.5,
                                        paddingBottom: 1.5,
                                        paddingLeft: 1,
                                        paddingRight: 2,
                                    },
                                },
                                tablet: { spacing: { paddingLeft: 2, paddingRight: inPopover ? 4 : 0 } },
                            },
                        } })] }, category.categoryCode))) }) }));
};
exports.ProductDetailFeatureCategoriesContainer = ProductDetailFeatureCategoriesContainer;
