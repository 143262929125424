"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const Registration_1 = require("../../components/account/registration/Registration");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const NavigationUtil_1 = require("../../util/NavigationUtil");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const AuthActions_creators_1 = require("../../redux/authentication/actions/AuthActions.creators");
const SessionStorageUtil_1 = require("../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const common_types_1 = require("@as-react/common-types");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const AccountRegistrationContainer = props => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const [defaultEmail, setDefaultEmail] = (0, react_1.useState)();
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const getRedirectionLink = () => {
        const callbackUrl = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, common_types_1.RegistrationSearchParameters.CALLBACK_URL);
        if (callbackUrl) {
            return decodeURIComponent(callbackUrl);
        }
        if (props.redirectUrl) {
            return props.redirectUrl;
        }
        return (0, NavigationUtil_1.getHomepageUrl)(root);
    };
    const getEmailFromStorage = () => {
        const registrationEmail = SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.REGISTRATION_EMAIL);
        SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.REGISTRATION_EMAIL);
        return registrationEmail;
    };
    (0, react_1.useEffect)(() => {
        if (shouldRender) {
            return;
        }
        setDefaultEmail((0, URLParamUtil_1.getQuerystringByName)(window.location.search, common_types_1.RegistrationSearchParameters.EMAIL) ||
            getEmailFromStorage() ||
            undefined);
        setShouldRender(true);
    }, [shouldRender]);
    (0, react_1.useEffect)(() => {
        if (loggedIn) {
            setTimeout(() => {
                window.location.href = getRedirectionLink();
            }, GeneralConstants_1.DELAY.DELAY_500);
        }
    }, [loggedIn]);
    if (!shouldRender) {
        return null;
    }
    const handleRegistrationSuccess = (email, password) => {
        dispatch(AuthActions_creators_1.authActions.login({
            username: email,
            password: password,
            keepSignedIn: false,
            reloadOnSuccess: false,
        }));
    };
    return (0, jsx_runtime_1.jsx)(Registration_1.Registration, Object.assign({ onSuccess: handleRegistrationSuccess, defaultEmail: defaultEmail }, props));
};
exports.default = AccountRegistrationContainer;
