"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyCustomerFields = exports.BuyCustomerFieldsType = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_utils_1 = require("@as-react/common-utils");
var BuyCustomerFieldsType;
(function (BuyCustomerFieldsType) {
    BuyCustomerFieldsType["COLUMN"] = "column";
    BuyCustomerFieldsType["INLINE"] = "inline";
    BuyCustomerFieldsType["MIXED"] = "mixed";
})(BuyCustomerFieldsType || (exports.BuyCustomerFieldsType = BuyCustomerFieldsType = {}));
const BuyCustomerFields = ({ optionsTitle, showMiddleName = false, collectStore = false, withSpacing = true, type = BuyCustomerFieldsType.COLUMN, }) => {
    const { errors, touched } = (0, formik_1.useFormikContext)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isInline = type === BuyCustomerFieldsType.INLINE;
    const isMixed = type === BuyCustomerFieldsType.MIXED;
    const customerTitle = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: isInline ? { spacing: { marginBottom: 0 } } : undefined, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "buy-idTitle", children: (0, common_utils_1.trimAsterisk)(t('buy.customer.title')) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, id: "buy-idTitle", name: "idTitle", state: errors.idTitle && touched.idTitle && cotopaxi_1.SelectState.ERROR, fullWidth: true, options: optionsTitle, placeholder: t('buy.customer.choose.title'), componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select })] }));
    const customerFirstName = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: isInline || isMixed ? { spacing: { marginBottom: 0 } } : undefined, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "buy-firstName", children: (0, common_utils_1.trimAsterisk)(t('buy.customer.firstname')) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, id: "buy-firstName", name: "firstName", maxLength: 50, state: errors.firstName && touched.firstName && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input })] }));
    const customerMiddleName = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: isInline || isMixed ? { spacing: { marginBottom: 0 } } : undefined, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "buy-middleName", children: t('buy.customer.infixname') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "buy-middleName", name: "middleName", state: errors.middleName && touched.middleName && cotopaxi_1.InputState.ERROR, maxLength: 50, Component: cotopaxi_1.Input })] }));
    const customerLastName = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "buy-lastName", children: (0, common_utils_1.trimAsterisk)(t('buy.customer.lastname')) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, id: "buy-lastName", name: "lastName", maxLength: 50, state: errors.lastName && touched.lastName && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input })] }));
    switch (type) {
        case BuyCustomerFieldsType.COLUMN:
            return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: withSpacing ? 3 : 0 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: isMobile, grid: 6, tablet: 3, desktopSpecific: 6, desktopLarge: 3, children: customerTitle }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: collectStore ? 10 : 7, desktopSpecific: 10, desktopLarge: collectStore ? 10 : 7, children: customerFirstName }) }), showMiddleName && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: collectStore ? 5 : 7, desktopSpecific: collectStore ? 7 : 10, desktopLarge: collectStore ? 5 : 7, children: customerMiddleName }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: collectStore ? 10 : 7, desktopSpecific: 10, desktopLarge: collectStore ? 10 : 7, children: customerLastName }) })] }));
        case BuyCustomerFieldsType.INLINE:
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, grid: 6, tablet: 2, desktop: 2, children: customerTitle }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: isMobile, noGutterRight: isMobile, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: collectStore ? 10 : 4, desktop: 4, desktopLarge: collectStore ? 10 : undefined, children: customerFirstName }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: isMobile, noGutterRight: true, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: collectStore ? 10 : 6, desktop: 6, desktopLarge: collectStore ? 10 : undefined, children: customerLastName })] }) }));
        case BuyCustomerFieldsType.MIXED:
            return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: withSpacing ? 3 : 0 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, grid: 6, tablet: 3, desktop: 3, children: customerTitle }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: isMobile, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 5, desktop: 5, desktopLarge: 5, children: customerFirstName }), showMiddleName && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, mobile: 6, mobileLarge: 6, tabletSmall: 6, tablet: 2, desktopSpecific: 2, desktopLarge: 2, children: customerMiddleName })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterRight: true, noGutterLeft: isMobile, grid: 0, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 5, desktop: 5, desktopLarge: 5, children: customerLastName })] })] }));
        default:
            return null;
    }
};
exports.BuyCustomerFields = BuyCustomerFields;
