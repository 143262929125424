"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YouTubeVideo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const VideoUtil_1 = require("../../util/VideoUtil");
var PlayerState;
(function (PlayerState) {
    PlayerState[PlayerState["UNSTARTED"] = -1] = "UNSTARTED";
    PlayerState[PlayerState["ENDED"] = 0] = "ENDED";
    PlayerState[PlayerState["PLAYING"] = 1] = "PLAYING";
    PlayerState[PlayerState["PAUSED"] = 2] = "PAUSED";
    PlayerState[PlayerState["BUFFERING"] = 3] = "BUFFERING";
    PlayerState[PlayerState["VIDEO_CUED"] = 5] = "VIDEO_CUED";
})(PlayerState || (PlayerState = {}));
const YOUTUBE_HOST = 'https://www.youtube-nocookie.com';
const YouTubeVideo = ({ allowFullScreen, description, idPostfix = '', iframe, pause, stop, play, url, withOverlay, overlayTheme, poster, title = '', }) => {
    const player = (0, react_1.useRef)();
    const [playerState, setPlayerState] = (0, react_1.useState)(PlayerState.UNSTARTED);
    const [showOverlay, setShowOverlay] = (0, react_1.useState)(withOverlay);
    const videoId = (0, VideoUtil_1.getYouTubeId)(url);
    const iframeId = `video_iframe_${videoId}`;
    const onPlayerStateChange = (event) => {
        const iframeElement = document.getElementById(iframeId);
        if (event.data === PlayerState.ENDED) {
            const custEvent = new CustomEvent('youtube:milestone-100', {
                detail: {
                    videoName: title,
                    videoEvent: 'event21',
                    videoSegment: '75-100%',
                },
            });
            iframeElement.dispatchEvent(custEvent);
        }
        if (event.data === PlayerState.PLAYING) {
            const custEvent = new CustomEvent('youtube:playing', {
                detail: { videoName: title, videoEvent: 'event17' },
            });
            iframeElement.dispatchEvent(custEvent);
        }
        setPlayerState(event.data);
    };
    const onPlayerReady = () => {
        let custEvent;
        let progress25;
        let progress50;
        let progress75;
        const iframeElement = document.getElementById(iframeId);
        const checkVideoProgress = () => {
            const duration = player.current.getDuration();
            const progress = player.current.getCurrentTime() / duration;
            if (progress >= 0.25 && !progress25) {
                custEvent = new CustomEvent('youtube:milestone-25', {
                    detail: {
                        videoName: title,
                        videoEvent: 'event18',
                        videoSegment: '0-25%',
                    },
                });
                progress25 = true;
            }
            if (progress >= 0.5 && !progress50) {
                custEvent = new CustomEvent('youtube:milestone-50', {
                    detail: {
                        videoName: title,
                        videoEvent: 'event19',
                        videoSegment: '25-50%',
                    },
                });
                progress50 = true;
            }
            if (progress >= 0.75 && !progress75) {
                custEvent = new CustomEvent('youtube:milestone-75', {
                    detail: {
                        videoName: title,
                        videoEvent: 'event20',
                        videoSegment: '50-75%',
                    },
                });
                progress75 = true;
            }
            if (custEvent) {
                iframeElement.dispatchEvent(custEvent);
            }
            window.requestAnimationFrame(checkVideoProgress);
        };
        window.requestAnimationFrame(checkVideoProgress);
    };
    const onYouTubePlayerAPIReady = () => {
        const options = {
            videoId,
            host: YOUTUBE_HOST,
            events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange,
            },
        };
        player.current = new window.YT.Player(`${iframeId}${idPostfix}`, options);
    };
    const playVideo = () => {
        var _a;
        if (((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.playVideo) && playerState !== 1) {
            player.current.playVideo();
        }
        showOverlay && setShowOverlay(false);
    };
    const pauseVideo = () => {
        var _a;
        if (((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.pauseVideo) && playerState === 1) {
            player.current.pauseVideo();
        }
    };
    const stopVideo = () => {
        var _a;
        if (((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.stopVideo) && playerState === 1) {
            player.current.stopVideo();
        }
    };
    (0, react_1.useEffect)(() => {
        (0, VideoUtil_1.setupYouTubeIframeAPIReadyHandler)();
        (0, VideoUtil_1.injectYoutubeAPI)();
        (0, VideoUtil_1.addYoutubeReadyEvent)(onYouTubePlayerAPIReady);
    }, []);
    (0, react_1.useEffect)(() => {
        pause && pauseVideo();
    }, [pause]);
    (0, react_1.useEffect)(() => {
        play && playVideo();
    }, [play]);
    (0, react_1.useEffect)(() => {
        stop && stopVideo();
    }, [stop]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Video, { url: (0, VideoUtil_1.getYouTubeVideoUrl)(videoId), iframe: iframe, iframeId: iframeId, idPostfix: idPostfix, allowFullScreen: allowFullScreen, withOverlay: showOverlay, description: description, onOverlayClick: playVideo, overlayTheme: overlayTheme, poster: poster }));
};
exports.YouTubeVideo = YouTubeVideo;
