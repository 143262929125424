"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
// https://nominatim.org/release-docs/develop/api/Search/
const apiUrlSearch = 'https://nominatim.openstreetmap.org';
class OsmAPI {
    constructor() {
        this.osmFormat = 'jsonv2';
        this.getLocationFromOSM = (location_1, ...args_1) => tslib_1.__awaiter(this, [location_1, ...args_1], void 0, function* (location, countryCodes = '', osmLimit = 3) {
            const axiosConfig = {
                method: 'GET',
                params: {
                    q: location,
                    format: this.osmFormat,
                    limit: osmLimit,
                    countrycodes: countryCodes,
                },
                url: `${apiUrlSearch}/search`,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getAddressFromOSM = (_a) => tslib_1.__awaiter(this, [_a], void 0, function* ({ lat, lng }) {
            const axiosConfig = {
                method: 'GET',
                params: {
                    lat,
                    lon: lng,
                    format: this.osmFormat,
                },
                url: `${apiUrlSearch}/reverse`,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new OsmAPI();
