"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const SessionActions_creators_1 = require("../redux/session/actions/SessionActions.creators");
const i18nConstants_1 = require("../constants/i18nConstants");
const NewsletterSubscribeForm_1 = require("../components/newsletter-subscribe-form/NewsletterSubscribeForm");
const NavigationUtil_1 = require("../util/NavigationUtil");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const AEMUtil_1 = require("../util/AEMUtil");
const NewsletterSubscribeContainer = ({ redirectUrl, buttonLabel, title, paddings, borders, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [feedbackState, setFeedbackState] = (0, react_1.useState)(null);
    const handleSuccess = () => {
        dispatch(SessionActions_creators_1.default.createNewsletterSubscribeAction());
        if (redirectUrl) {
            window.location.href = (0, NavigationUtil_1.getHomepageUrl)(redirectUrl);
        }
        else {
            setFeedbackState(common_types_1.NewsletterSubscribeFeedbackTypes.SUCCESS);
            setTimeout(() => setFeedbackState(null), GeneralConstants_1.DELAY.DELAY_2000);
        }
    };
    const handleFail = (state = common_types_1.NewsletterSubscribeFeedbackTypes.ERROR) => {
        setFeedbackState(state);
        setTimeout(() => setFeedbackState(null), GeneralConstants_1.DELAY.DELAY_2000);
    };
    const getFeedbackMessage = (state) => {
        switch (state) {
            case common_types_1.NewsletterSubscribeFeedbackTypes.SUCCESS:
                return t('account.subscribe.confirmMessage');
            case common_types_1.NewsletterSubscribeFeedbackTypes.ALREADY_SUBSCRIBED:
                return t('account.subscribe.alreadySubscribedMessage');
            default:
                return t('account.subscribe.errorMessage');
        }
    };
    const wrapperClassNames = (0, react_1.useMemo)(() => `${Object.values(borders).filter(Boolean).join(' ')}`, [borders]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(paddings), { className: wrapperClassNames, children: (0, jsx_runtime_1.jsx)(NewsletterSubscribeForm_1.NewsletterSubscribeForm, { title: title ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: title }) : undefined, submitButtonText: buttonLabel, onSuccess: handleSuccess, onFail: handleFail, feedbackState: feedbackState, feedbackMessage: getFeedbackMessage(feedbackState) }) })));
};
exports.default = NewsletterSubscribeContainer;
