"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRequestGroupGenericContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const PaymentRequestAPI_1 = require("../../../api/buy/PaymentRequestAPI");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const PaymentGroupGeneric_1 = require("../../../components/buy/payment/payment-groups/PaymentGroupGeneric");
const PaymentRequestGroupGenericContainer = ({ paymentRequestId, selectedPaymentOption, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const handleSubmit = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const paymentData = {
            chosenPaymentOption: selectedPaymentOption === null || selectedPaymentOption === void 0 ? void 0 : selectedPaymentOption.name,
            chosenPaymentOptionId: selectedPaymentOption === null || selectedPaymentOption === void 0 ? void 0 : selectedPaymentOption.id,
            tp: `${defaultRequestParams.mainWebshop}.html`,
        };
        const providerInformation = yield PaymentRequestAPI_1.default.postPaymentRequest(servicesEndpoint, defaultRequestParams, paymentRequestId, paymentData);
        if (providerInformation.provider === common_types_1.PaymentProvider.SAFERPAY) {
            const { redirectUrl } = yield PaymentRequestAPI_1.default.paymentProviderInputRequest(servicesEndpoint, defaultRequestParams, providerInformation.provider, {
                fictiveWebshopId: defaultRequestParams.shopId,
                market: defaultRequestParams.market,
                paymentRequestId: paymentRequestId,
            });
            if (redirectUrl) {
                location.href = redirectUrl;
            }
        }
    });
    return (0, jsx_runtime_1.jsx)(PaymentGroupGeneric_1.PaymentGroupGeneric, { onSubmit: handleSubmit });
};
exports.PaymentRequestGroupGenericContainer = PaymentRequestGroupGenericContainer;
