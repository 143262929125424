"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFields = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const AddressFieldsBasic_1 = require("./address-fields-basic/AddressFieldsBasic");
const AddressFieldsBasicAlt_1 = require("./address-fields-basic-alt/AddressFieldsBasicAlt");
const AddressFieldsBE_1 = require("./address-fields-be/AddressFieldsBE");
const AddressFieldsDE_1 = require("./address-fields-de/AddressFieldsDE");
const AddressFieldsFR_1 = require("./address-fields-fr/AddressFieldsFR");
const AddressFieldsNL_1 = require("./address-fields-nl/AddressFieldsNL");
const AddressFieldsLU_1 = require("./address-fields-lu/AddressFieldsLU");
const AddressFieldsUS_1 = require("./address-fields-us/AddressFieldsUS");
const AddressFieldsUK_1 = require("./address-fields-uk/AddressFieldsUK");
const AddressFields = props => {
    switch (props.country) {
        case 'NL':
            return (0, jsx_runtime_1.jsx)(AddressFieldsNL_1.AddressFieldsNL, Object.assign({}, props));
        case 'DE':
            return (0, jsx_runtime_1.jsx)(AddressFieldsDE_1.AddressFieldsDE, Object.assign({}, props));
        case 'FR':
            return (0, jsx_runtime_1.jsx)(AddressFieldsFR_1.AddressFieldsFR, Object.assign({}, props));
        case 'US':
            return (0, jsx_runtime_1.jsx)(AddressFieldsUS_1.AddressFieldsUS, Object.assign({}, props));
        case 'BE':
            return (0, jsx_runtime_1.jsx)(AddressFieldsBE_1.AddressFieldsBE, Object.assign({}, props));
        case 'LU':
            return (0, jsx_runtime_1.jsx)(AddressFieldsLU_1.AddressFieldsLU, Object.assign({}, props));
        case 'GB':
        case 'GG':
        case 'JE':
            return (0, jsx_runtime_1.jsx)(AddressFieldsUK_1.AddressFieldsUK, Object.assign({}, props));
        case 'BFPO':
        case 'IE':
        case 'AU':
        case 'NZ':
            return (0, jsx_runtime_1.jsx)(AddressFieldsBasicAlt_1.AddressFieldsBasicAlt, Object.assign({}, props));
        default:
            return (0, jsx_runtime_1.jsx)(AddressFieldsBasic_1.AddressFieldsBasic, Object.assign({}, props));
    }
};
exports.AddressFields = AddressFields;
