"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionReducer = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const SessionUtil_1 = require("../../../util/SessionUtil");
const initialState = {
    shopId: null,
    market: null,
    firstName: '',
    lastName: '',
    deprecatedUser: false,
    uuid: null,
    amcUuid: null,
    username: null,
    authorities: [],
    enabled: true,
    isLoggedIn: false,
};
const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.SessionActionTypes.SET:
            return Object.assign(Object.assign({}, action.payload), { isLoggedIn: (0, SessionUtil_1.isLoggedInUser)(action.payload) });
        case ActionEnums_1.SessionActionTypes.LOGOUT:
            return Object.assign(Object.assign({}, state), { isLoggedIn: false });
        default:
            return state;
    }
};
exports.sessionReducer = sessionReducer;
