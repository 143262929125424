"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToBasketPopupTile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const IMAGE_SIZE = {
    mobile: { width: 88, height: 130 },
    tablet: { width: 64, height: 96 },
};
const AddToBasketPopupTile = ({ brand, description, image, priceActual, pricePrevious, productDiscount, productType, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile, { variant: "horizontal", product: { image, currency, brand, title, alt: title, pricePrevious, priceActual }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "primary", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Image, { width: IMAGE_SIZE.mobile.width, height: IMAGE_SIZE.mobile.height, containerOptions: {
                        mobile: {
                            spacing: { marginRight: 1.5 },
                            width: IMAGE_SIZE.mobile.width,
                            height: IMAGE_SIZE.mobile.height,
                        },
                        tablet: {
                            spacing: { marginRight: 3 },
                            width: IMAGE_SIZE.tablet.width,
                            height: IMAGE_SIZE.tablet.height,
                        },
                    } }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile.Area, { area: "secondary", children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Title, { emphasizeTitle: productType === common_types_1.ProductTypes.E_VOUCHER, brandOnTheSameLine: true, containerOptions: {
                            mobile: { spacing: { marginBottom: productType === common_types_1.ProductTypes.E_VOUCHER ? 0 : 0.5 } },
                            tablet: { spacing: { marginBottom: 1 } },
                        } }), Boolean((productType === common_types_1.ProductTypes.PRODUCT || productType === common_types_1.ProductTypes.E_VOUCHER) && description) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.25 } }, tablet: { spacing: { marginBottom: productType === common_types_1.ProductTypes.E_VOUCHER ? 0.25 : 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: description }) })), Boolean(priceActual) && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: isMobile, valign: isMobile ? undefined : cotopaxi_1.GroupVerticalAlign.CENTER, gutter: { mobile: cotopaxi_1.GroupGutter.SMALL, tablet: cotopaxi_1.GroupGutter.MEDIUM }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Price, { size: cotopaxi_1.PriceSize.LARGE, spacing: isDesktop ? cotopaxi_1.PriceSpacing.SMALL : cotopaxi_1.PriceSpacing.XSMAll }), Boolean(productDiscount) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.DISCOUNT, children: [t('pdp.dynamic.savings'), " ", productDiscount] }) }))] }))] })] }));
};
exports.AddToBasketPopupTile = AddToBasketPopupTile;
