"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const SavedCreditCardForm = ({ onSelectedCardChange, savedCards, selectedSavedCard, toggleSavedCardForm, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const getSavedCardOptions = () => savedCards.map(card => ({ label: card.cardNumber, value: card.id }));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t('buy.payment.saferpay.creditcard.title.saved') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 7, desktop: 7, desktopLarge: 8, grid: 0, noGutterLeft: true, noGutterRight: isMobile, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { fullWidth: true, name: "select-saved-card", options: getSavedCardOptions(), onChange: onSelectedCardChange, selectedValue: selectedSavedCard.id }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 5, desktop: 5, desktopLarge: 4, grid: 0, noGutterLeft: isMobile, noGutterRight: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2.5 } }, tablet: { spacing: { paddingLeft: 1, marginTop: 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.PLUS, size: cotopaxi_1.IconSize.MEDIUM }, onClick: toggleSavedCardForm, text: t('buy.payment.saferpay.creditcard.goto.new') }) }) })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4 } }, tablet: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [selectedSavedCard.cardName, " ", (0, jsx_runtime_1.jsx)("br", {}), selectedSavedCard.cardNumber, " ", (0, jsx_runtime_1.jsx)("br", {}), selectedSavedCard.brand, " ", (0, jsx_runtime_1.jsx)("br", {})] }) })] }));
};
exports.default = SavedCreditCardForm;
