"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationWishlistBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const WishListSelectors_1 = require("../../../redux/wishList/selectors/WishListSelectors");
const NavigationWishlistBadge = () => {
    const wishlistCount = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishlistCounter)());
    if (!wishlistCount) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { count: wishlistCount });
};
exports.NavigationWishlistBadge = NavigationWishlistBadge;
