"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthReducer_1 = require("../../authentication/reducers/AuthReducer");
const AuthSaga_1 = require("../../authentication/sagas/AuthSaga");
const loadAuthenticationModule = () => ({
    id: 'Authentication',
    reducerMap: {
        authentication: AuthReducer_1.authReducer,
    },
    sagas: [AuthSaga_1.default],
});
exports.default = loadAuthenticationModule;
