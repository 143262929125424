"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Monetate_module_1 = require("./modules/Monetate.module");
const CookieConsent_module_1 = require("./modules/CookieConsent.module");
const Basket_module_1 = require("./modules/Basket.module");
const WishList_module_1 = require("./modules/WishList.module");
const Compare_module_1 = require("./modules/Compare.module");
const DeliveryPromise_module_1 = require("./modules/DeliveryPromise.module");
const loadProductGroupMonetateModule = () => ({
    hasRouter: false,
    modules: [
        (0, Monetate_module_1.default)(),
        (0, CookieConsent_module_1.default)(),
        (0, Basket_module_1.default)(),
        (0, Compare_module_1.default)(),
        (0, WishList_module_1.default)(),
        (0, DeliveryPromise_module_1.default)(),
    ],
});
exports.default = loadProductGroupMonetateModule;
