"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeProductAndUpdateSaga = exports.removeProductAndUpdate = void 0;
const effects_1 = require("redux-saga/effects");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const RemoveProductFromBasketSaga_1 = require("./RemoveProductFromBasketSaga");
function* removeProductAndUpdate(action) {
    yield (0, effects_1.call)(RemoveProductFromBasketSaga_1.removeProductFromBasket, action);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
}
exports.removeProductAndUpdate = removeProductAndUpdate;
function* removeProductAndUpdateSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT_AND_UPDATE, removeProductAndUpdate);
}
exports.removeProductAndUpdateSaga = removeProductAndUpdateSaga;
