"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterActionTypes = exports.UrlActionTypes = exports.ProductContentVideoActionsTypes = exports.CustomerActionTypes = exports.OrdersActionsTypes = exports.ProductPreviewActionTypes = exports.PartialSearchActionTypes = exports.BrandListerActionTypes = exports.ProductGroupFredhopperTypes = exports.MonetateActionTypes = exports.SearchActionTypes = exports.AuthActionTypes = exports.CheckoutActionTypes = exports.SessionActionTypes = exports.CookieConsentActionTypes = exports.DeliveryPromiseActionTypes = exports.CompareActionTypes = exports.ProductComparisonActionTypes = exports.ProductGroupActionTypes = exports.ProductRecentlyViewedActionTypes = exports.GeneralActionTypes = exports.PromoBasketPopupActionTypes = exports.ListerActionTypes = exports.WishListActionTypes = exports.SingleProductTileActionTypes = exports.AnalyticsActionTypes = exports.BasketActionTypes = exports.AuthFormActionsTypes = exports.ProductActionTypes = exports.ProductAddToBasketLocations = void 0;
var ProductAddToBasketLocations;
(function (ProductAddToBasketLocations) {
    ProductAddToBasketLocations["PDP"] = "PDP";
    ProductAddToBasketLocations["CLICK_AND_COLLECT"] = "ClickAndCollect";
    ProductAddToBasketLocations["STICKY_BAR"] = "StickyBar";
    ProductAddToBasketLocations["PROMO_BASKET_POPUP"] = "BasketPopup";
    ProductAddToBasketLocations["BASKET_QUANTITY"] = "basket-quantity";
    ProductAddToBasketLocations["MINI_PDP"] = "mini-pdp";
    ProductAddToBasketLocations["MINI_PDP_SOP_THE_LOOK"] = "mini-pdp-shop-the-look";
    ProductAddToBasketLocations["BASKET_RECOMMENDATIONS"] = "basket-recommendations";
    ProductAddToBasketLocations["PRODUCT_PAGE"] = "product page";
    ProductAddToBasketLocations["PRODUCT_BUNDLES"] = "product bundles";
    ProductAddToBasketLocations["PRODUCT_BUNDLES_MONETATE"] = "product bundle monetate";
    ProductAddToBasketLocations["WISHLIST"] = "wishlist";
    ProductAddToBasketLocations["BASKET_POPUP_MONETATE"] = "basket popup monetate";
    ProductAddToBasketLocations["PRODUCT_GROUP_MONETATE"] = "monetate recommendation";
    ProductAddToBasketLocations["PRODUCT_GROUP_FREDHOPPER"] = "fredhopper recommendation";
    ProductAddToBasketLocations["RECENTLY_VIEWED"] = "recently viewed";
    ProductAddToBasketLocations["PRODUCT_COMPARE"] = "product compare";
    ProductAddToBasketLocations["CAMPAIGN_GROUP"] = "campaign group";
    ProductAddToBasketLocations["PRODUCT_GROUP"] = "product group";
    ProductAddToBasketLocations["PRODUCT_TILE_SMALL"] = "product tile small";
    ProductAddToBasketLocations["PDP_ADDITIONAL_PRODUCTS"] = "additional";
})(ProductAddToBasketLocations || (exports.ProductAddToBasketLocations = ProductAddToBasketLocations = {}));
var ProductActionTypes;
(function (ProductActionTypes) {
    ProductActionTypes["INIT"] = "PRODUCT_INIT";
    ProductActionTypes["SELECT_SIZE"] = "PRODUCT_SELECT_SIZE";
    ProductActionTypes["CLEAR_SIZE"] = "PRODUCT_CLEAR_SIZE";
    ProductActionTypes["SET_COLOR"] = "PRODUCT_SET_COLOR";
    ProductActionTypes["SET_ESI_DATA"] = "PRODUCT_SET_ESI_DATA";
    ProductActionTypes["GET_DELIVERY_PROMISE_EXTENDED"] = "PRODUCT_GET_DELIVERY_PROMISE_EXTENDED";
    ProductActionTypes["SET_DELIVERY_PROMISE_EXTENDED"] = "PRODUCT_SET_DELIVERY_PROMISE_EXTENDED";
    ProductActionTypes["GET_DELIVERY_PROMISE"] = "PRODUCT_GET_DELIVERY_PROMISE";
    ProductActionTypes["SET_DELIVERY_PROMISE"] = "PRODUCT_SET_DELIVERY_PROMISE";
    ProductActionTypes["SET_NEW_PRODUCT"] = "PRODUCT_SET_NEW_PRODUCT";
    ProductActionTypes["LOAD_PRODUCT_DATA"] = "PRODUCT_LOAD_PRODUCT_DATA";
    ProductActionTypes["CLEAR_PRODUCT_DATA"] = "CLEAR_PRODUCT_DATA";
    ProductActionTypes["SET_SELECTED_PRODUCT_TAB"] = "SET_SELECTED_PRODUCT_TAB";
    ProductActionTypes["FETCH_REVIEWS_DATA"] = "FETCH_REVIEWS_DATA";
    ProductActionTypes["SET_REVIEWS_DATA"] = "SET_REVIEWS_DATA";
    ProductActionTypes["SET_PRODUCT_VIDEOS"] = "SET_PRODUCT_VIDEOS";
    ProductActionTypes["SET_PRODUCT_CONTENT_VIDEOS"] = "SET_PRODUCT_CONTENT_VIDEOS";
    ProductActionTypes["GET_TECHNICAL_CATEGORIES"] = "GET_TECHNICAL_CATEGORIES";
    ProductActionTypes["SET_TECHNICAL_CATEGORIES"] = "SET_TECHNICAL_CATEGORIES";
    ProductActionTypes["GET_LINKED_PRODUCTS"] = "GET_LINKED_PRODUCTS";
    ProductActionTypes["SET_LINKED_PRODUCTS"] = "SET_LINKED_PRODUCTS";
    ProductActionTypes["GET_PRODUCT_CARE_DELIVERY_PROMISE"] = "PRODUCT_GET_PRODUCT_CARE_DELIVERY_PROMISE";
    ProductActionTypes["SET_PRODUCT_CARE_DELIVERY_PROMISE"] = "PRODUCT_SET_PRODUCT_CARE_DELIVERY_PROMISE";
    ProductActionTypes["SIZE_GUIDE_OPEN"] = "PRODUCT_SIZE_GUIDE_OPEN";
    ProductActionTypes["GET_BUNDLE"] = "PRODUCT_GET_BUNDLE";
    ProductActionTypes["SET_BUNDLE"] = "PRODUCT_SET_BUNDLE";
    ProductActionTypes["BUNDLE_SET_SELECTED_SLOT_STATE"] = "PRODUCT_BUNDLE_SET_SELECTED_SLOT_STATE";
    ProductActionTypes["BUNDLE_SET_DISPLAYED_PRODUCT"] = "BUNDLE_SET_DISPLAYED_PRODUCT";
    ProductActionTypes["SET_SELECTED_BUNDLE_TAB"] = "SET_SELECTED_BUNDLE_TAB";
    ProductActionTypes["BUNDLE_CALCULATE_START"] = "BUNDLE_CALCULATE_START";
    ProductActionTypes["BUNDLE_CALCULATE_SUCCESS"] = "BUNDLE_CALCULATE_SUCCESS";
    ProductActionTypes["BUNDLE_CALCULATE_FAILURE"] = "BUNDLE_CALCULATE_FAILURE";
    ProductActionTypes["FETCH_ALTERNATIVES"] = "PRODUCT_FETCH_ALTERNATIVES";
    ProductActionTypes["SET_ALTERNATIVES"] = "PRODUCT_SET_ALTERNATIVES";
    ProductActionTypes["SET_NOTIFY_ME_ERROR"] = "PRODUCT_SET_NOTIFY_ME_ERROR";
    ProductActionTypes["SET_SIZE_COMBINATION_NOT_EXIST"] = "PRODUCT_SET_COMBINATION_NOT_EXIST";
})(ProductActionTypes || (exports.ProductActionTypes = ProductActionTypes = {}));
var AuthFormActionsTypes;
(function (AuthFormActionsTypes) {
    AuthFormActionsTypes["UPDATE_STEP_TYPE"] = "UPDATE_STEP";
})(AuthFormActionsTypes || (exports.AuthFormActionsTypes = AuthFormActionsTypes = {}));
var BasketActionTypes;
(function (BasketActionTypes) {
    BasketActionTypes["ADD"] = "BASKET_ADD";
    BasketActionTypes["ADD_BUNDLE"] = "BASKET_ADD_BUNDLE";
    BasketActionTypes["ADD_VOUCHER"] = "BASKET_ADD_VOUCHER";
    BasketActionTypes["ADD_PROMOTION"] = "BASKET_ADD_PROMOTION";
    BasketActionTypes["ADD_GIFT_CARD"] = "BASKET_ADD_GIFT_CARD";
    BasketActionTypes["ADD_SUCCESS"] = "BASKET_ADD_SUCCESS";
    BasketActionTypes["ADD_BUNDLE_SUCCESS"] = "ADD_BUNDLE_SUCCESS";
    BasketActionTypes["ADD_ADDITIONAL_PRODUCTS"] = "BASKET_ADD_ADDITIONAL_PRODUCTS";
    BasketActionTypes["BASIC_ADD"] = "BASKET_BASIC_ADD";
    BasketActionTypes["BASKET_OVERVIEW_INIT"] = "BASKET_OVERVIEW_INIT";
    BasketActionTypes["BASKET_OVERVIEW_ADOBE_ANALYTICS_SUCCESS"] = "BASKET_OVERVIEW_ADOBE_ANALYTICS_SUCCESS";
    BasketActionTypes["ADD_EXTENSIVE_TILE_PRODUCT"] = "BASKET_ADD_EXTENSIVE_TILE_PRODUCT";
    BasketActionTypes["GET"] = "BASKET_GET";
    BasketActionTypes["GET_BASIC"] = "BASKET_GET_BASIC";
    BasketActionTypes["CC_POPUP_OPEN"] = "BASKET_CC_POPUP_OPEN";
    BasketActionTypes["CC_POPUP_CLOSE"] = "BASKET_CC_POPUP_CLOSE";
    BasketActionTypes["POPUP_CLOSE"] = "BASKET_POPUP_CLOSE";
    BasketActionTypes["POPUP_OPEN"] = "BASKET_POPUP_OPEN";
    BasketActionTypes["POPUP_SHOW"] = "BASKET_POPUP_SHOW";
    BasketActionTypes["ERROR_SET"] = "BASKET_ERROR_SET";
    BasketActionTypes["SET"] = "BASKET_SET";
    BasketActionTypes["SET_BASIC"] = "BASKET_SET_BASIC";
    BasketActionTypes["STATUS_UPDATE"] = "BASKET_STATUS_UPDATE";
    BasketActionTypes["SIZE_FORGOTTEN_SHOW"] = "SIZE_FORGOTTEN_SHOW";
    BasketActionTypes["ADOBE_ANALYTICS_SUCCESS"] = "BASKET_ADOBE_ANALYTICS_SUCCESS";
    BasketActionTypes["ADOBE_ANALYTICS_TOKEN_SET"] = "BASKET_ADOBE_ANALYTICS_TOKEN_SET";
    BasketActionTypes["GOOGLE_ANALYTICS_SUCCESS"] = "BASKET_GOOGLE_ANALYTICS_SUCCESS";
    BasketActionTypes["UPDATE_QUANTITY"] = "BASKET_UPDATE_QUANTITY";
    BasketActionTypes["UPDATE_QUANTITY_RESULT"] = "BASKET_UPDATE_QUANTITY_RESULT";
    BasketActionTypes["REMOVE_PRODUCT"] = "BASKET_REMOVE_PRODUCT";
    BasketActionTypes["REMOVE_PRODUCT_AND_UPDATE"] = "BASKET_REMOVE_PRODUCT_AND_UPDATE";
    BasketActionTypes["REMOVE_PRODUCT_SUCCESS"] = "BASKET_REMOVE_PRODUCT_SUCCESS";
    BasketActionTypes["REMOVE_GOOGLE_ANALYTICS_SUCCESS"] = "BASKET_REMOVE_GOOGLE_ANALYTICS_SUCCESS";
    BasketActionTypes["REMOVE_ADOBE_ANALYTICS_SUCCESS"] = "BASKET_REMOVE_ADOBE_ANALYTICS_SUCCESS";
    BasketActionTypes["RESET_BASKET_FOR_SHOPLANDING"] = "RESET_BASKET_FOR_SHOPLANDING";
    BasketActionTypes["CHECK_STOCK_RESERVATION"] = "BASKET_CHECK_STOCK_RESERVATION";
    BasketActionTypes["UPDATE_QUANTITY_CUSTOM"] = "BASKET_UPDATE_QUANTITY_CUSTOM";
    BasketActionTypes["UPDATE_PRODUCT_SUCCESS"] = "BASKET_UPDATE_PRODUCT_SUCCESS";
    BasketActionTypes["UPDATE_GOOGLE_ANALYTICS_SUCCESS"] = "BASKET_UPDATE_GOOGLE_ANALYTICS_SUCCESS";
    BasketActionTypes["UPDATE_ADOBE_ANALYTICS_SUCCESS"] = "BASKET_UPDATE_ADOBE_ANALYTICS_SUCCESS";
    BasketActionTypes["INCREASE_QUANTITY"] = "BASKET_INCREASE_QUANTITY";
    BasketActionTypes["DECREASE_QUANTITY"] = "BASKET_DECREASE_QUANTITY";
    BasketActionTypes["UPDATE_ITEMS_ERROR_STATE"] = "BASKET_UPDATE_ITEMS_ERROR_STATE";
    BasketActionTypes["CHANGE_SIZE"] = "BASKET_CHANGE_SIZE";
    BasketActionTypes["HAS_UNAVAILABLE_PRODUCTS_SET"] = "HAS_UNAVAILABLE_PRODUCTS_SET";
    BasketActionTypes["MERGE"] = "BASKET_MERGE";
    BasketActionTypes["ADD_BULK"] = "BASKET_ADD_BULK";
})(BasketActionTypes || (exports.BasketActionTypes = BasketActionTypes = {}));
var AnalyticsActionTypes;
(function (AnalyticsActionTypes) {
    AnalyticsActionTypes["DPE_ENROLMENT_START"] = "ANALYTICS_DPE_START";
    AnalyticsActionTypes["DPE_ENROLMENT_AUTH_EMAIL"] = "ANALYTICS_DPE_ENROLMENT_AUTH_EMAIL";
    AnalyticsActionTypes["DPE_ENROLMENT_AUTH_LOGIN"] = "ANALYTICS_DPE_ENROLMENT_AUTH_LOGIN";
    AnalyticsActionTypes["DPE_ENROLMENT_AUTH_REGISTER"] = "ANALYTICS_DPE_ENROLMENT_AUTH_REGISTER";
    AnalyticsActionTypes["DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN"] = "ANALYTICS_DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN";
    AnalyticsActionTypes["DPE_ENROLMENT_ELIGIBILITY_CHECK"] = "ANALYTICS_DPE_ENROLMENT_ELIGIBILITY_CHECK";
    AnalyticsActionTypes["DPE_ENROLMENT_CONFIRMATION"] = "ANALYTICS_DPE_ENROLMENT_CONFIRMATION";
    AnalyticsActionTypes["DPE_ENROLMENT_LEAVE"] = "ANALYTICS_DPE_ENROLMENT_LEAVE";
    AnalyticsActionTypes["FORMS_ERROR_MESSAGE"] = "ANALYTICS_FORMS_ERROR_MESSAGE";
    AnalyticsActionTypes["SEARCH_ON_SITE_SEARCH_UPDATE"] = "SEARCH_ONSITE_SEARCH_UPDATE";
    AnalyticsActionTypes["CONSENT_ACCEPT_ALL"] = "CONSENT_ACCEPT_ALL";
    AnalyticsActionTypes["CONSENT_OPEN_PREFERENCES"] = "CONSENT_OPEN_PREFERENCES";
    AnalyticsActionTypes["CONSENT_UPDATE_PREFERENCES"] = "CONSENT_UPDATE_PREFERENCES";
    AnalyticsActionTypes["LOGIN_STEP_OPTION"] = "LOGIN_STEP_OPTION";
    AnalyticsActionTypes["CHECKOUT_STEP"] = "CHECKOUT_STEP";
    AnalyticsActionTypes["REGISTRATION_STEP"] = "ANALYTICS_REGISTRATION_STEP";
    AnalyticsActionTypes["DELIVERY_STEP"] = "ANALYTICS_DELIVERY_STEP";
    AnalyticsActionTypes["DELIVERY_STEP_OPTION"] = "ANALYTICS_DELIVERY_STEP_OPTION";
    AnalyticsActionTypes["PAYMENT_REQUEST"] = "ANALYTICS_PAYMENT_REQUEST";
    AnalyticsActionTypes["PAYMENT_STEP"] = "ANALYTICS_PAYMENT_STEP";
    AnalyticsActionTypes["PAYMENT_STEP_OPTION"] = "ANALYTICS_PAYMENT_STEP_OPTION";
    AnalyticsActionTypes["FAST_CHECKOUT_STEP"] = "ANALYTICS_FAST_CHECKOUT_STEP";
    AnalyticsActionTypes["VOUCHER_ADDED"] = "ANALYTICS_VOUCHER_ADDED";
    AnalyticsActionTypes["CONFIRMATION_PAGE"] = "ANALYTICS_CONFIRMATION_PAGE";
    AnalyticsActionTypes["ANALYTICS_GOOGLE_CONFIRMATION_PAGE"] = "ANALYTICS_GOOGLE_CONFIRMATION_PAGE";
    AnalyticsActionTypes["FILTER_WIZARD_OPENED"] = "FILTER_WIZARD_OPENED";
    AnalyticsActionTypes["FILTER_WIZARD_SELECTED"] = "FILTER_WIZARD_SELECTED";
    AnalyticsActionTypes["CHECKOUT_TYPE"] = "CHECKOUT_TYPE";
    AnalyticsActionTypes["COMPARE_PRODUCTS_FEATURES"] = "COMPARE_PRODUCTS_FEATURES";
    AnalyticsActionTypes["PRODUCT_SEARCH_PERSONALIZATION"] = "ANALYTICS_PRODUCT_SEARCH_PERSONALIZATION";
    AnalyticsActionTypes["STORE_FILTER_STARTED"] = "STORE_FILTER_STARTED";
    AnalyticsActionTypes["STORE_FILTER_APPLIED"] = "STORE_FILTER_APPLIED";
    AnalyticsActionTypes["STORE_FILTER_MODIFIED"] = "STORE_FILTER_MODIFIED";
    AnalyticsActionTypes["SHOP_THE_LOOK_OPENED"] = "SHOP_THE_LOOK_OPENED";
    AnalyticsActionTypes["LISTER_SORT_CHANGE"] = "ANALYTICS_LISTER_SORT_CHANGE";
    AnalyticsActionTypes["LISTER_RESULTS"] = "ANALYTICS_LISTER_RESULTS";
    AnalyticsActionTypes["LISTER_VIEW_PRODUCT_LIST"] = "ANALYTICS_LISTER_VIEW_PRODUCT_LIST";
    AnalyticsActionTypes["NEWSLETTER_PREFERENCES_SUBSCRIPTION_UPDATE"] = "ANALYTICS_NEWSLETTER_PREFERENCES_SUBSCRIPTION_UPDATE";
    AnalyticsActionTypes["NEWSLETTER_PREFERENCES_SUBSCRIBE"] = "ANALYTICS_NEWSLETTER_PREFERENCES_SUBSCRIBE";
    AnalyticsActionTypes["NEWSLETTER_PREFERENCES_UNSUBSCRIBE"] = "ANALYTICS_NEWSLETTER_PREFERENCES_UNSUBSCRIBE";
    AnalyticsActionTypes["NEWSLETTER_PREFERENCES_UPDATE"] = "ANALYTICS_NEWSLETTER_PREFERENCES_UPDATE";
    AnalyticsActionTypes["RETURN_PORTAL_LOGIN"] = "ANALYTICS_RETURN_PORTAL_LOGIN";
    AnalyticsActionTypes["RETURN_PRODUCT_OVERVIEW"] = "ANALYTICS_RETURN_PRODUCT_OVERVIEW";
    AnalyticsActionTypes["RETURN_PRODUCTS_CONTINUE_CLICK"] = "ANALYTICS_RETURN_PRODUCTS_CONTINUE_CLICK";
    AnalyticsActionTypes["RETURN_PRODUCT_SELECTED"] = "ANALYTICS_RETURN_PRODUCT_SELECTED";
    AnalyticsActionTypes["RETURN_PRODUCT_UNSELECTED"] = "ANALYTICS_RETURN_PRODUCT_UNSELECTED";
    AnalyticsActionTypes["RETURN_METHOD_OVERVIEW"] = "ANALYTICS_RETURN_METHOD_OVERVIEW";
    AnalyticsActionTypes["RETURN_METHOD_CONFIRM_CLICK"] = "ANALYTICS_RETURN_METHOD_CONFIRM_CLICK";
    AnalyticsActionTypes["RETURN_METHOD_SELECTED"] = "ANALYTICS_RETURN_METHOD_SELECTED";
    AnalyticsActionTypes["RETURN_ANNOUNCED"] = "ANALYTICS_RETURN_ANNOUNCED";
    AnalyticsActionTypes["SHOW_STORE_STOCK"] = "SHOW_STORE_STOCK";
    AnalyticsActionTypes["PRODUCT_DETAIL_TAB_OPEN"] = "ANALYTICS_PRODUCT_DETAIL_TAB_OPEN";
    AnalyticsActionTypes["GIFT_VOUCHER_INIT"] = "ANALYTICS_GIFT_VOUCHER_INIT";
    AnalyticsActionTypes["PRODUCT_DETAIL_SIZE_GUIDE_CLICK"] = "ANALYTICS_PRODUCT_DETAIL_SIZE_GUIDE_CLICK";
    AnalyticsActionTypes["PRODUCT_DETAIL_ADD_TO_BASKET"] = "ANALYTICS_PRODUCT_DETAIL_ADD_TO_BASKET";
    AnalyticsActionTypes["CUSTOMER_IDENTIFICATION"] = "ANALYTICS_CUSTOMER_IDENTIFICATION";
    AnalyticsActionTypes["BUNDLE_DEALS_VIEWED"] = "BUNDLE_DEALS_VIEWED";
    AnalyticsActionTypes["FAQ_QUESTION_EXPAND"] = "FAQ_QUESTION_EXPAND";
    AnalyticsActionTypes["NOTIFY_ME_REQUEST"] = "NOTIFY_ME_REQUEST";
    AnalyticsActionTypes["APPLE_PAY_CLICK"] = "ANALYTICS_APPLE_PAY_CLICK";
    AnalyticsActionTypes["APPLE_PAY_BUTTON_IMPRESSION"] = "ANALYTICS_APPLE_PAY_BUTTON_IMPRESSION";
    AnalyticsActionTypes["APPLE_PAY_BUTTON_INITIAL_IN_VIEWPORT"] = "ANALYTICS_APPLE_PAY_BUTTON_INITIAL_IN_VIEWPORT";
    AnalyticsActionTypes["ETICKET_SEND_FEEDBACK"] = "ETICKET_SEND_FEEDBACK";
    AnalyticsActionTypes["PAYBACK_ENRICHMENT_CHANGED"] = "PAYBACK_ENRICHMENT_CHANGED";
    AnalyticsActionTypes["PAYBACK_ERROR"] = "PAYBACK_ERROR";
    AnalyticsActionTypes["PRODUCT_GROUP_VIEWED"] = "ANALYTICS_PRODUCT_GROUP_VIEWED";
    AnalyticsActionTypes["PRODUCT_GROUP_ITEM_CLICK"] = "ANALYTICS_PRODUCT_GROUP_ITEM_CLICK";
    AnalyticsActionTypes["PRODUCT_VARIANT_CLICK"] = "PRODUCT_VARIANT_CLICK";
    AnalyticsActionTypes["BLOCKED_CHECKOUT"] = "BLOCKED_CHECKOUT";
    AnalyticsActionTypes["BASKET_REMINDER_NOTIFICATION_VIEWED"] = "BASKET_REMINDER_NOTIFICATION_VIEWED";
    AnalyticsActionTypes["BASKET_REMINDER_NOTIFICATION_EVENT"] = "BASKET_REMINDER_NOTIFICATION_EVENT";
    AnalyticsActionTypes["ADDITIONAL_PRODUCT_SELECTOR_VIEWED"] = "ADDITIONAL_PRODUCT_SELECTOR_VIEWED";
    AnalyticsActionTypes["MOBILE_NAVIGATION_CLICK"] = "ANALYTICS_NAVIGATION_CLICK";
    AnalyticsActionTypes["SIZEBAY_CLICK"] = "ANALYTICS_SIZEBAY_CLICK";
})(AnalyticsActionTypes || (exports.AnalyticsActionTypes = AnalyticsActionTypes = {}));
var SingleProductTileActionTypes;
(function (SingleProductTileActionTypes) {
    SingleProductTileActionTypes["SINGLE_PRODUCT_TILE_INIT"] = "SINGLE_PRODUCT_TILE_INIT";
    SingleProductTileActionTypes["ADD_PRODUCT_TO_LIST"] = "SINGLE_PRODUCT_TILE_ADD_PRODUCT_TO_LIST";
    SingleProductTileActionTypes["ADD_BULK_PRODUCTS_TO_LIST"] = "SINGLE_PRODUCT_TILE_ADD_BULK_PRODUCTS_TO_LIST";
    SingleProductTileActionTypes["GET_DELIVERY_PROMISE"] = "SINGLE_PRODUCT_TILE_GET_DELIVERY_PROMISE";
    SingleProductTileActionTypes["SET_DELIVERY_PROMISE"] = "SINGLE_PRODUCT_TILE_SET_DELIVERY_PROMISE";
})(SingleProductTileActionTypes || (exports.SingleProductTileActionTypes = SingleProductTileActionTypes = {}));
var WishListActionTypes;
(function (WishListActionTypes) {
    WishListActionTypes["ANALYTICS_ADD"] = "WISHLIST_ANALYTICS_ADD";
    WishListActionTypes["ANALYTICS_REMOVE"] = "WISHLIST_ANALYTICS_REMOVE";
    WishListActionTypes["LOGIN"] = "WISHLIST_LOGIN";
    WishListActionTypes["LOGOUT"] = "WISHLIST_LOGOUT";
    WishListActionTypes["LOGGED_IN"] = "WISHLIST_LOGGED_IN";
    WishListActionTypes["LOGGED_OUT"] = "WISHLIST_LOGGED_OUT";
    WishListActionTypes["GET_PRODUCTS"] = "WISHLIST_GET_PRODUCTS";
    WishListActionTypes["UPDATE_PRODUCTS"] = "WISHLIST_UPDATE_PRODUCTS";
    WishListActionTypes["TOGGLE_PRODUCT"] = "WISHLIST_TOGGLE_PRODUCT";
    WishListActionTypes["ADD_PRODUCT"] = "WISHLIST_ADD_PRODUCT";
    WishListActionTypes["REMOVE_PRODUCT"] = "WISHLIST_REMOVE_PRODUCT";
    WishListActionTypes["LISTER_INIT"] = "WISHLIST_LISTER_INIT";
    WishListActionTypes["LISTER_GET_PRODUCTS"] = "WISHLIST_LISTER_GET_PRODUCTS";
    WishListActionTypes["LISTER_SET_PRODUCTS"] = "WISHLIST_LISTER_SET_PRODUCTS";
    WishListActionTypes["LISTER_SET_FETCH_STATUS"] = "WISHLIST_LISTER_SET_FETCH_STATUS";
    WishListActionTypes["LISTER_UPDATE_PAGE"] = "WISHLIST_LISTER_UPDATE_PAGE";
    WishListActionTypes["LISTER_GET_DELIVERY_PROMISE"] = "WISHLIST_LISTER_GET_DELIVERY_PROMISE";
    WishListActionTypes["LISTER_SET_DELIVERY_PROMISE"] = "WISHLIST_LISTER_SET_DELIVERY_PROMISE";
})(WishListActionTypes || (exports.WishListActionTypes = WishListActionTypes = {}));
var ListerActionTypes;
(function (ListerActionTypes) {
    ListerActionTypes["LISTER_INIT"] = "LISTER_INIT";
    ListerActionTypes["LISTER_TILE_CLICK"] = "LISTER_TILE_CLICK";
    ListerActionTypes["UPDATE_PRODUCTS"] = "UPDATE_PRODUCTS";
    ListerActionTypes["UPDATE_PRODUCTS_AND_TOTAL_PAGES"] = "UPDATE_PRODUCTS_AND_TOTAL_PAGES";
    ListerActionTypes["UPDATE_PRODUCTS_AND_TOTAL_PAGES_AND_ITEM_COUNT"] = "UPDATE_PRODUCTS_AND_TOTAL_PAGES_AND_ITEM_COUNT";
    ListerActionTypes["UPDATE_EXISTING_FILTERS"] = "UPDATE_EXISTING_FILTERS";
    ListerActionTypes["SHOW_PLACEHOLDER_TILES"] = "SHOW_PLACEHOLDER_TILE";
    ListerActionTypes["UPDATE_IN_STORE_FILTER"] = "UPDATE_IN_STORE_FILTER";
    ListerActionTypes["GET_LISTER_PRODUCTS"] = "GET_LISTER_PRODUCTS";
    ListerActionTypes["SET_LISTER_STATE"] = "SET_LISTER_STATE";
    ListerActionTypes["SET_LISTER_ISLOADED"] = "SET_LISTER_ISLOADED";
})(ListerActionTypes || (exports.ListerActionTypes = ListerActionTypes = {}));
var PromoBasketPopupActionTypes;
(function (PromoBasketPopupActionTypes) {
    PromoBasketPopupActionTypes["INIT"] = "PBP_INIT";
    PromoBasketPopupActionTypes["POPUP_CLOSE"] = "PBP_POPUP_CLOSE";
    PromoBasketPopupActionTypes["POPUP_OPEN"] = "PBP_POPUP_OPEN";
})(PromoBasketPopupActionTypes || (exports.PromoBasketPopupActionTypes = PromoBasketPopupActionTypes = {}));
var GeneralActionTypes;
(function (GeneralActionTypes) {
    GeneralActionTypes["CUSTOM_EVENT"] = "CUSTOM_EVENT";
})(GeneralActionTypes || (exports.GeneralActionTypes = GeneralActionTypes = {}));
var ProductRecentlyViewedActionTypes;
(function (ProductRecentlyViewedActionTypes) {
    ProductRecentlyViewedActionTypes["GET"] = "RECENTLY_VIEWED_PRODUCTS_GET";
    ProductRecentlyViewedActionTypes["FETCH"] = "PRODUCT_RECENTLY_VIEWED_FETCH";
    ProductRecentlyViewedActionTypes["ERASE"] = "PRODUCT_RECENTLY_VIEWED_ERASE";
    ProductRecentlyViewedActionTypes["GET_DELIVERY_PROMISE"] = "RECENTLY_VIEWED_GET_DELIVERY_PROMISE";
    ProductRecentlyViewedActionTypes["SET_DELIVERY_PROMISE"] = "RECENTLY_VIEWED_SET_DELIVERY_PROMISE";
})(ProductRecentlyViewedActionTypes || (exports.ProductRecentlyViewedActionTypes = ProductRecentlyViewedActionTypes = {}));
var ProductGroupActionTypes;
(function (ProductGroupActionTypes) {
    ProductGroupActionTypes["INIT"] = "PRODUCT_GROUP_INIT";
    ProductGroupActionTypes["GET_DELIVERY_PROMISE"] = "PRODUCT_GROUP_GET_DELIVERY_PROMISE";
    ProductGroupActionTypes["SET_DELIVERY_PROMISE"] = "PRODUCT_GROUP_SET_DELIVERY_PROMISE";
    ProductGroupActionTypes["SET_PRODUCT_GROUPS"] = "SET_PRODUCT_GROUPS";
})(ProductGroupActionTypes || (exports.ProductGroupActionTypes = ProductGroupActionTypes = {}));
var ProductComparisonActionTypes;
(function (ProductComparisonActionTypes) {
    ProductComparisonActionTypes["INIT"] = "PRODUCT_COMPARISON_INIT";
    ProductComparisonActionTypes["FETCH"] = "PRODUCT_COMPARISON_FETCH";
    ProductComparisonActionTypes["REMOVE"] = "PRODUCT_COMPARISON_REMOVE";
    ProductComparisonActionTypes["COMPARE_ITEM_SELECT_SIZE"] = "PRODUCT_COMPARISON_ITEM_SELECT_SIZE";
    ProductComparisonActionTypes["GET_DELIVERY_PROMISE"] = "PRODUCT_COMPARISON_GET_DELIVERY_PROMISE";
    ProductComparisonActionTypes["SET_DELIVERY_PROMISE"] = "PRODUCT_COMPARISON_SET_DELIVERY_PROMISE";
})(ProductComparisonActionTypes || (exports.ProductComparisonActionTypes = ProductComparisonActionTypes = {}));
var CompareActionTypes;
(function (CompareActionTypes) {
    CompareActionTypes["COMPARE_ADD"] = "COMPARE_ADD";
    CompareActionTypes["COMPARE_INIT"] = "COMPARE_INIT";
    CompareActionTypes["COMPARE_TOGGLE_LIMIT_REACHED"] = "COMPARE_TOGGLE_LIMIT_REACHED";
    CompareActionTypes["COMPARE_REMOVE"] = "COMPARE_REMOVE";
    CompareActionTypes["COMPARE_UPDATE"] = "COMPARE_UPDATE";
    CompareActionTypes["COMPARE_UPDATE_COMPLETE"] = "COMPARE_UPDATE_COMPLETE";
    CompareActionTypes["COMPARE_UPDATE_PRODUCTS"] = "COMPARE_UPDATE_PRODUCTS";
    CompareActionTypes["COMPARE_POPUP_OPEN"] = "COMPARE_POPUP_OPEN";
    CompareActionTypes["COMPARE_POPUP_CLOSE"] = "COMPARE_POPUP_CLOSE";
    CompareActionTypes["COMPARE_POPUP_TOGGLE"] = "COMPARE_POPUP_TOGGLE";
    CompareActionTypes["COMPARE_PAGE_OPEN"] = "COMPARE_PAGE_OPEN";
    CompareActionTypes["COMPARE_PAGE_NO_PRODUCT_DISPLAYED"] = "COMPARE_PAGE_NO_PRODUCT_DISPLAYED";
    CompareActionTypes["COMPARE_PAGE_PRODUCT_DISPLAYED"] = "COMPARE_PAGE_PRODUCT_DISPLAYED";
    CompareActionTypes["COMPARE_PAGE_PRODUCT_DISPLAYED_TOGGLE"] = "COMPARE_PAGE_PRODUCT_DISPLAYED_TOGGLE";
})(CompareActionTypes || (exports.CompareActionTypes = CompareActionTypes = {}));
var DeliveryPromiseActionTypes;
(function (DeliveryPromiseActionTypes) {
    DeliveryPromiseActionTypes["ADD"] = "DELIVERY_PROMISE_ADD";
})(DeliveryPromiseActionTypes || (exports.DeliveryPromiseActionTypes = DeliveryPromiseActionTypes = {}));
var CookieConsentActionTypes;
(function (CookieConsentActionTypes) {
    CookieConsentActionTypes["CLEAR"] = "COOKIE_CONSENT_CLEAR";
    CookieConsentActionTypes["SET"] = "COOKIE_CONSENT_SET";
})(CookieConsentActionTypes || (exports.CookieConsentActionTypes = CookieConsentActionTypes = {}));
var SessionActionTypes;
(function (SessionActionTypes) {
    SessionActionTypes["INIT"] = "SESSION_INIT";
    SessionActionTypes["SET"] = "SESSION_SET";
    SessionActionTypes["LOGOUT"] = "SESSION_LOGOUT";
    SessionActionTypes["NEWSLETTER_SUBSCRIBE"] = "SESSION_NEWSLETTER_SUBSCRIBE";
})(SessionActionTypes || (exports.SessionActionTypes = SessionActionTypes = {}));
var CheckoutActionTypes;
(function (CheckoutActionTypes) {
    CheckoutActionTypes["INIT"] = "CHECKOUT_INIT";
    CheckoutActionTypes["INIT_SET"] = "CHECKOUT_INIT_SET";
    CheckoutActionTypes["ERROR"] = "CHECKOUT_ERROR";
    CheckoutActionTypes["LOGIN_ANALYTICS"] = "CHECKOUT_LOGIN_ANALYTICS";
    CheckoutActionTypes["ANALYTICS_SUCCESS"] = "CHECKOUT_ANALYTICS_SUCCESS";
    CheckoutActionTypes["ANALYTICS_CONFIRMATION_PAGE_STEP"] = "CHECKOUT_ANALYTICS_CONFIRMATION_PAGE_STEP";
    CheckoutActionTypes["ANALYTICS_MERGE_CONFIRMATION_PAGE"] = "CHECKOUT_ANALYTICS_MERGE_CONFIRMATION_PAGE";
    CheckoutActionTypes["GET_CUSTOMER_DETAILS"] = "GET_CUSTOMER_DETAILS";
    CheckoutActionTypes["SET_CUSTOMER_DETAILS"] = "SET_CUSTOMER_DETAILS";
    CheckoutActionTypes["GET_FAST_CHECKOUT_OVERVIEW"] = "GET_FAST_CHECKOUT_OVERVIEW";
    CheckoutActionTypes["SET_FAST_CHECKOUT_OVERVIEW"] = "SET_FAST_CHECKOUT_OVERVIEW";
    CheckoutActionTypes["SUBMIT_FAST_CHECKOUT"] = "SUBMIT_FAST_CHECKOUT";
    CheckoutActionTypes["GET_DELIVERY_COUNTRIES"] = "GET_DELIVERY_COUNTRIES";
    CheckoutActionTypes["SET_DELIVERY_COUNTRIES"] = "SET_DELIVERY_COUNTRIES";
    CheckoutActionTypes["SET_DELIVERY_INFO_ADDRESS"] = "SET_DELIVERY_INFO_ADDRESS";
    CheckoutActionTypes["SET_DELIVERY_DATES"] = "SET_DELIVERY_DATES";
    CheckoutActionTypes["SET_DELIVERY_US_STATES"] = "SET_DELIVERY_US_STATES";
    CheckoutActionTypes["SET_DELIVERY_ADDRESS_STATE"] = "SET_DELIVERY_ADDRESS_STATE";
    CheckoutActionTypes["GET_DELIVERY_INFO"] = "GET_DELIVERY_INFO";
    CheckoutActionTypes["SET_DELIVERY_INFO"] = "SET_DELIVERY_INFO";
    CheckoutActionTypes["CHANGE_DELIVERY_COUNTRY"] = "CHANGE_DELIVERY_COUNTRY";
    CheckoutActionTypes["SAVE_DELIVERY_INFO"] = "SAVE_DELIVERY_INFO";
    CheckoutActionTypes["SAVE_DELIVERY_INFO_SUCCESS"] = "SAVE_DELIVERY_INFO_SUCCESS";
    CheckoutActionTypes["SAVE_DELIVERY_INFO_FAIL"] = "SAVE_DELIVERY_INFO_FAIL";
    CheckoutActionTypes["GET_CUSTOMER_TITLES"] = "GET_CUSTOMER_TITLES";
    CheckoutActionTypes["SET_CUSTOMER_TITLES"] = "SET_CUSTOMER_TITLES";
    CheckoutActionTypes["CHANGE_DELIVERY_OPTION"] = "CHANGE_DELIVERY_OPTION";
    CheckoutActionTypes["SET_DELIVERY_OPTION"] = "SET_DELIVERY_OPTION";
    CheckoutActionTypes["CHANGE_DELIVERY_STORE"] = "CHANGE_DELIVERY_STORE";
    CheckoutActionTypes["SET_DELIVERY_STORE"] = "SET_DELIVERY_STORE";
    CheckoutActionTypes["SET_PAYMENT_INFO"] = "SET_PAYMENT_INFO";
    CheckoutActionTypes["GET_PAYMENT_INFO"] = "GET_PAYMENT_INFO";
    CheckoutActionTypes["GET_STORE_DETAILS"] = "GET_STORE_DETAILS";
    CheckoutActionTypes["SET_STORE_DETAILS"] = "SET_STORE_DETAILS";
    CheckoutActionTypes["SAVE_PAYMENT_OPTION"] = "SAVE_PAYMENT_OPTION";
    CheckoutActionTypes["SAVE_PAYMENT_OPTION_SUCCESS"] = "SAVE_PAYMENT_OPTION_SUCCESS";
    CheckoutActionTypes["SET_PAYMENT_OPTION_ERROR"] = "SET_PAYMENT_OPTION_ERROR";
    CheckoutActionTypes["CHANGE_PAYMENT_OPTION"] = "CHANGE_PAYMENT_OPTION";
    CheckoutActionTypes["SET_PAYMENT_OPTION_SUCCESS"] = "SET_PAYMENT_OPTION_SUCCESS";
    CheckoutActionTypes["SET_SELECTED_PAYMENT_OPTION"] = "SET_SELECTED_PAYMENT_OPTION";
    CheckoutActionTypes["SET_PAYMENT_ADDRESS_STATE"] = "SET_PAYMENT_ADDRESS_STATE";
    CheckoutActionTypes["VOUCHER_CHANGES"] = "VOUCHER_CHANGES";
    CheckoutActionTypes["SET_KLARNA"] = "SET_KLARNA";
    CheckoutActionTypes["SET_SAFERPAY_FIELDS_READY"] = "SET_SAFERPAY_FIELDS_READY";
    CheckoutActionTypes["GET_PICKUP_POINTS_BY_SEARCH"] = "GET_PICKUP_POINTS_BY_SEARCH";
    CheckoutActionTypes["GET_PICKUP_POINTS_BY_LOCATION"] = "GET_PICKUP_POINTS_BY_LOCATION";
    CheckoutActionTypes["SET_PICKUP_POINTS"] = "SET_PICKUP_POINTS";
    CheckoutActionTypes["RESET_PICKUP_POINTS"] = "RESET_PICKUP_POINTS";
    CheckoutActionTypes["SET_ACTIVE_PICKUP_POINT_ID"] = "SET_ACTIVE_PICKUP_POINT_ID";
    CheckoutActionTypes["SET_SHOW_ALL_PICKUP_POINTS"] = "SET_SHOW_ALL_PICKUP_POINTS";
    CheckoutActionTypes["SET_PICK_UP_POINT_LOCATION_FIELD_ERROR"] = "SET_PICK_UP_POINT_LOCATION_FIELD_ERROR";
    CheckoutActionTypes["CHANGE_COLLECTION_POINT_VIEW"] = "CHANGE_COLLECTION_POINT_VIEW";
    CheckoutActionTypes["SET_LOCATION_ERROR_COLLECTION_POINT"] = "SET_LOCATION_ERROR_COLLECTION_POINT";
    CheckoutActionTypes["GET_CONFIRMATION_ORDER"] = "GET_CONFIRMATION_ORDER";
    CheckoutActionTypes["SET_CONFIRMATION_ORDER"] = "SET_CONFIRMATION_ORDER";
    CheckoutActionTypes["SET_CONFIRMATION_IS_COLLECT_STORE"] = "SET_CONFIRMATION_IS_COLLECT_STORE";
    CheckoutActionTypes["SET_CONFIRMATION_ORDER_ERROR"] = "SET_CONFIRMATION_ORDER_ERROR";
    CheckoutActionTypes["SET_NEWSLETTER_PREFERENCES"] = "SET_NEWSLETTER_PREFERENCES";
    CheckoutActionTypes["GET_STORES_BY_SEARCH"] = "GET_STORES_BY_SEARCH";
    CheckoutActionTypes["GET_STORES_BY_LOCATION"] = "GET_STORES_BY_LOCATION";
    CheckoutActionTypes["SET_STORES"] = "SET_STORES";
    CheckoutActionTypes["GET_STORES_FAIL"] = "GET_STORES_FAIL";
    CheckoutActionTypes["SET_LOCATION_ERROR_COLLECT_STORE"] = "SET_LOCATION_ERROR_COLLECT_STORE";
    CheckoutActionTypes["SET_ACTIVE_STORE_ID"] = "SET_ACTIVE_STORE_ID";
    CheckoutActionTypes["SET_SHOW_ALL_STORES"] = "SET_SHOW_ALL_STORES";
    CheckoutActionTypes["CHANGE_COLLECT_STORE_VIEW"] = "CHANGE_COLLECT_STORE_VIEW";
    CheckoutActionTypes["RESET_PAYMENT_STATE"] = "RESET_PAYMENT_STATE";
    CheckoutActionTypes["SHOPLANDING_PAYMENT"] = "SHOPLANDING_PAYMENT";
    CheckoutActionTypes["CHANGE_COLLECT_STORE_ONLY_IN_STOCK"] = "CHANGE_COLLECT_STORE_ONLY_IN_STOCK";
    CheckoutActionTypes["SET_HAS_REGISTRATION"] = "SET_HAS_REGISTRATION";
    CheckoutActionTypes["SET_IS_PAYMENT_OPTION_UPDATING"] = "SET_IS_PAYMENT_OPTION_UPDATING";
})(CheckoutActionTypes || (exports.CheckoutActionTypes = CheckoutActionTypes = {}));
var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["LOGIN"] = "LOGIN";
    AuthActionTypes["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
    AuthActionTypes["LOGIN_FAIL"] = "LOGIN_FAIL";
    AuthActionTypes["REGISTERED_LOGIN"] = "REGISTERED_LOGIN";
    AuthActionTypes["FAST_CHECKOUT"] = "FAST_CHECKOUT";
    AuthActionTypes["LOGOUT"] = "LOGOUT";
    AuthActionTypes["LOGOUT_SUCCESS"] = "LOGOUT_SUCCESS";
    AuthActionTypes["LOGOUT_FAIL"] = "LOGOUT_FAIL";
    AuthActionTypes["RESET_ERROR"] = "RESET_ERROR";
    AuthActionTypes["INIT_SOCIAL"] = "AUTH_INIT_SOCIAL";
    AuthActionTypes["LOAD_SOCIAL_SUCCESS"] = "AUTH_LOAD_SOCIAL_SUCCESS";
    AuthActionTypes["SOCIAL_LOGIN"] = "AUTH_SOCIAL_LOGIN";
    AuthActionTypes["RESET_PASSWORD"] = "AUTH_RESET_PASSWORD";
    AuthActionTypes["RESET_PASSWORD_ERROR"] = "AUTH_RESET_PASSWORD_ERROR";
    AuthActionTypes["RESET_PASSWORD_SUCCESS"] = "AUTH_RESET_PASSWORD_SUCCESS";
    AuthActionTypes["LOGIN_GUEST"] = "LOGIN_GUEST";
    AuthActionTypes["LOGIN_GUEST_SUCCESS"] = "LOGIN_GUEST_SUCCESS";
    AuthActionTypes["LOGIN_GUEST_FAIL"] = "LOGIN_GUEST_FAIL";
    AuthActionTypes["LOGIN_RETURN_GUEST"] = "LOGIN_RETURN_GUEST";
    AuthActionTypes["LOGIN_RETURN_GUEST_SUCCESS"] = "LOGIN_RETURN_GUEST_SUCCESS";
    AuthActionTypes["LOGIN_RETURN_GUEST_FAIL"] = "LOGIN_RETURN_GUEST_FAIL";
    AuthActionTypes["GET_USER_AUTH_STATE"] = "GET_USER_AUTH_STATE";
    AuthActionTypes["UPDATE_USER_AUTH_STATE"] = "UPDATE_USER_AUTH_STATE";
})(AuthActionTypes || (exports.AuthActionTypes = AuthActionTypes = {}));
var SearchActionTypes;
(function (SearchActionTypes) {
    SearchActionTypes["FIND"] = "[SEARCH] FIND";
    SearchActionTypes["FETCH_WITHOUT_PRODUCTS"] = "[SEARCH] FETCH_WITHOUT_PRODUCTS";
    SearchActionTypes["SET_VALUE"] = "[SEARCH] SET_VALUE";
    SearchActionTypes["SET_SELECTED_ROW"] = "[SEARCH] SET_SELECTED_ROW";
    SearchActionTypes["SET_RESULTS_LIMIT"] = "[SEARCH] SET_RESULTS_LIMIT";
    SearchActionTypes["ADOBE_ANALYTICS_SUCCESSFULL_SEARCH_EVENT_PRODUCT"] = "[SEARCH] ADOBE ANALYTICS SUCCESSFULL PRODUCT";
    SearchActionTypes["ADOBE_ANALYTICS_SET_SEARCH_TERM"] = "[SEARCH] ADOBE ANALYTICS SET SEARCH TERM";
})(SearchActionTypes || (exports.SearchActionTypes = SearchActionTypes = {}));
var MonetateActionTypes;
(function (MonetateActionTypes) {
    MonetateActionTypes["INIT"] = "MONETATE_INIT";
    MonetateActionTypes["LOAD"] = "MONETATE_LOAD";
    MonetateActionTypes["ON_HOLD"] = "MONETATE_ON_HOLD";
    MonetateActionTypes["LOADED"] = "MONETATE_LOADED";
    MonetateActionTypes["FETCH_PRODUCTS"] = "MONETATE_FETCH_PRODUCTS";
    MonetateActionTypes["SET_PRODUCTS"] = "MONETATE_SET_PRODUCTS";
    MonetateActionTypes["GET_DELIVERY_PROMISE"] = "MONETATE_GET_DELIVERY_PROMISE";
    MonetateActionTypes["SET_DELIVERY_PROMISE"] = "MONETATE_SET_DELIVERY_PROMISE";
})(MonetateActionTypes || (exports.MonetateActionTypes = MonetateActionTypes = {}));
var ProductGroupFredhopperTypes;
(function (ProductGroupFredhopperTypes) {
    ProductGroupFredhopperTypes["INIT"] = "PRODUCT_GROUP_FREDHOPPER_INIT";
    ProductGroupFredhopperTypes["GET_DELIVERY_PROMISE"] = "FREDHOPPER_GET_DELIVERY_PROMISE";
    ProductGroupFredhopperTypes["SET_DELIVERY_PROMISE"] = "FREDHOPPER_SET_DELIVERY_PROMISE";
    ProductGroupFredhopperTypes["SET_FREDHOPPER_DATA"] = "SET_FREDHOPPER_DATA";
})(ProductGroupFredhopperTypes || (exports.ProductGroupFredhopperTypes = ProductGroupFredhopperTypes = {}));
var BrandListerActionTypes;
(function (BrandListerActionTypes) {
    BrandListerActionTypes["SET_FILTER"] = "BRAND_LISTER_SET_FILTER";
})(BrandListerActionTypes || (exports.BrandListerActionTypes = BrandListerActionTypes = {}));
var PartialSearchActionTypes;
(function (PartialSearchActionTypes) {
    PartialSearchActionTypes["INIT"] = "PARTIAL_SEARCH_INIT";
})(PartialSearchActionTypes || (exports.PartialSearchActionTypes = PartialSearchActionTypes = {}));
var ProductPreviewActionTypes;
(function (ProductPreviewActionTypes) {
    ProductPreviewActionTypes["DISABLE_IMAGE_TOUCH_ZOOM_HINT"] = "DISABLE_IMAGE_TOUCH_ZOOM_HINT";
    ProductPreviewActionTypes["UPDATE_IMAGE_TOUCH_ZOOM_HINT"] = "UPDATE_IMAGE_TOUCH_ZOOM_HINT";
})(ProductPreviewActionTypes || (exports.ProductPreviewActionTypes = ProductPreviewActionTypes = {}));
var OrdersActionsTypes;
(function (OrdersActionsTypes) {
    OrdersActionsTypes["GET_ORDERED_ITEMS_FOR_AUTHENTICATED_USER"] = "ORDER_GET_ORDERED_ITEMS_FOR_AUTHENTICATED_USER";
    OrdersActionsTypes["GET_ORDERED_ITEMS_FOR_GUEST_USER"] = "ORDER_GET_ORDERED_ITEMS_FOR_GUEST_USER";
    OrdersActionsTypes["SET_ORDERED_ITEMS"] = "ORDER_SET_ORDERED_ITEMS";
    OrdersActionsTypes["SET_ORDERED_ITEMS_STATE"] = "ORDER_SET_ORDERED_ITEMS_STATE";
    OrdersActionsTypes["GET_RETURNED_SALES"] = "ORDER_GET_RETURNED_SALES";
    OrdersActionsTypes["SET_RETURNED_SALES"] = "ORDER_SET_RETURNED_SALES";
    OrdersActionsTypes["SET_ANNOUNCED_RETURN"] = "ORDER_SET_ANNOUNCED_RETURN";
    OrdersActionsTypes["GET_RETURN_ITEMS"] = "ORDER_GET_RETURN_ITEMS";
    OrdersActionsTypes["SET_RETURN_ITEMS"] = "ORDER_SET_RETURN_ITEMS";
    OrdersActionsTypes["GET_RETURN_REASONS"] = "ORDER_GET_RETURN_REASONS";
    OrdersActionsTypes["SET_RETURN_REASONS"] = "ORDER_SET_RETURN_REASONS";
    OrdersActionsTypes["SUBMIT_RETURN_ITEMS"] = "ORDER_SUBMIT_RETURN_ITEMS";
    OrdersActionsTypes["UPDATE_RETURN_REASON"] = "ORDER_UPDATE_RETURN_REASON";
    OrdersActionsTypes["SET_SUBMIT_RETURN_ITEMS_STATE"] = "SET_SUBMIT_RETURN_ITEMS_STATE";
    OrdersActionsTypes["SET_UPDATE_ANNOUNCED_RETURN_STATE"] = "SET_UPDATE_ANNOUNCED_RETURN_STATE";
})(OrdersActionsTypes || (exports.OrdersActionsTypes = OrdersActionsTypes = {}));
var CustomerActionTypes;
(function (CustomerActionTypes) {
    CustomerActionTypes["GET_PROFILE"] = "CUSTOMER_GET_PROFILE";
    CustomerActionTypes["SET_PROFILE"] = "CUSTOMER_SET_PROFILE";
    CustomerActionTypes["UPDATE_PROFILE"] = "CUSTOMER_UPDATE_PROFILE";
    CustomerActionTypes["GET_DELIVERY_ADDRESSES"] = "GET_DELIVERY_ADDRESSES";
    CustomerActionTypes["SET_DELIVERY_ADDRESSES"] = "SET_DELIVERY_ADDRESSES";
    CustomerActionTypes["DELETE_DELIVERY_ADDRESS"] = "DELETE_DELIVERY_ADDRESS";
    CustomerActionTypes["ADD_DELIVERY_ADDRESS"] = "ADD_DELIVERY_ADDRESS";
})(CustomerActionTypes || (exports.CustomerActionTypes = CustomerActionTypes = {}));
var ProductContentVideoActionsTypes;
(function (ProductContentVideoActionsTypes) {
    ProductContentVideoActionsTypes["GET_PRODUCT_CONTENT_VIDEOS"] = "GET_PRODUCT_CONTENT_VIDEOS";
})(ProductContentVideoActionsTypes || (exports.ProductContentVideoActionsTypes = ProductContentVideoActionsTypes = {}));
var UrlActionTypes;
(function (UrlActionTypes) {
    UrlActionTypes["UPDATE_PAGE"] = "UPDATE_PAGE";
    UrlActionTypes["UPDATE_SIZE"] = "UPDATE_SIZE";
    UrlActionTypes["UPDATE_SORT"] = "UPDATE_SORT";
    UrlActionTypes["UPDATE_STORE_ID"] = "UPDATE_STORE_ID";
    UrlActionTypes["UPDATE_FILTERS"] = "UPDATE_FILTERS";
    UrlActionTypes["SET_URL_PARAMS"] = "SET_URL_PARAMS";
})(UrlActionTypes || (exports.UrlActionTypes = UrlActionTypes = {}));
var FilterActionTypes;
(function (FilterActionTypes) {
    FilterActionTypes["MULTISELECT_FILTER"] = "MULTISELECT";
    FilterActionTypes["RANGE_FILTER"] = "RANGE";
    FilterActionTypes["BUCKET_FILTER"] = "BUCKET";
    FilterActionTypes["LINK_FILTER"] = "LINK";
    FilterActionTypes["UNHANDLED_FILTER"] = "UNHANDLED";
    FilterActionTypes["SET_FILTERS"] = "SET_FILTERS";
    FilterActionTypes["REMOVE_FILTER"] = "REMOVE_FILTER";
    FilterActionTypes["REMOVE_ALL_FILTERS"] = "REMOVE_ALL_FILTERS";
    FilterActionTypes["SET_ACTIVE_FILTERS"] = "SET_ACTIVE_FILTERS";
})(FilterActionTypes || (exports.FilterActionTypes = FilterActionTypes = {}));
