"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRelatedProductPageLinks = void 0;
const URLParamUtil_1 = require("./URLParamUtil");
const HtmlElementUtil_1 = require("./HtmlElementUtil");
const common_utils_1 = require("@as-react/common-utils");
const generateRelatedProductPageLinks = (canonicalUrl, currentPage, sizeParameter, totalPages, updateCanonicalLinkElement = true) => {
    const linkPrevDomElement = (0, HtmlElementUtil_1.findOrCreateElementInHead)('link[rel="prev"]', 'link', { rel: 'prev' });
    const linkNextDomElement = (0, HtmlElementUtil_1.findOrCreateElementInHead)('link[rel="next"]', 'link', { rel: 'next' });
    if (updateCanonicalLinkElement) {
        const linkCanonicalDomElement = (0, HtmlElementUtil_1.findOrCreateElementInHead)('link[rel="canonical"]', 'link', {
            rel: 'canonical',
        });
        if (!(0, common_utils_1.isNullOrUndefined)(linkCanonicalDomElement)) {
            linkCanonicalDomElement.href = (0, URLParamUtil_1.generateAbsoluteUrl)(canonicalUrl, currentPage, sizeParameter);
        }
    }
    if (!(0, common_utils_1.isNullOrUndefined)(linkPrevDomElement)) {
        if (currentPage > 0) {
            const prevPage = currentPage - 1;
            linkPrevDomElement.href = (0, URLParamUtil_1.generateAbsoluteUrl)(canonicalUrl, prevPage, sizeParameter);
        }
        else {
            linkPrevDomElement.parentNode.removeChild(linkPrevDomElement);
        }
    }
    if (!(0, common_utils_1.isNullOrUndefined)(linkNextDomElement)) {
        if (currentPage < totalPages - 1) {
            const nextPage = currentPage + 1;
            linkNextDomElement.href = (0, URLParamUtil_1.generateAbsoluteUrl)(canonicalUrl, nextPage, sizeParameter);
        }
        else {
            linkNextDomElement.parentNode.removeChild(linkNextDomElement);
        }
    }
};
exports.generateRelatedProductPageLinks = generateRelatedProductPageLinks;
