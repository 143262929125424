"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const BoxedTitle = ({ heading, queryValue, headingOne, headingSize, leadingSpaceNeeded, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { paddingBottom: 2, paddingLeft: leadingSpaceNeeded ? 4 : 0 } }, tablet: { spacing: { paddingLeft: leadingSpaceNeeded ? 3 : 0 } }, mobile: { spacing: { paddingRight: 2, paddingLeft: leadingSpaceNeeded ? 2 : 0 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { priority: headingOne ? cotopaxi_1.HeadingPriority.H1 : cotopaxi_1.HeadingPriority.H2, type: headingSize, children: [`${heading} `, t('boxed-title.search-term'), ` ${queryValue}`] }) }));
};
exports.default = BoxedTitle;
