"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationField = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const Field_1 = require("../../../components/field/Field");
const RegistrationField = (_a) => {
    var { label, hasLabelBox, required, value, state, isPreFilled = false, withAutoFocus = false, children } = _a, fieldProps = tslib_1.__rest(_a, ["label", "hasLabelBox", "required", "value", "state", "isPreFilled", "withAutoFocus", "children"]);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [editField, setEditField] = (0, react_1.useState)(!isPreFilled);
    const toggleEditField = () => setEditField(prevValue => !prevValue);
    const innerRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        if (withAutoFocus) {
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)(Field_1.Field, { id: fieldProps.id, label: label, hasLabelBox: hasLabelBox, required: required, children: ({ fieldId, isRequired }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!editField && isPreFilled && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: value }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.IconSize.MEDIUM }, text: t('account.details.edit'), size: cotopaxi_1.ButtonSize.SMALL, onClick: toggleEditField }) })] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, Object.assign({ dataQA: `registration_${fieldProps.name}`, state: editField || !isPreFilled ? state : cotopaxi_1.InputState.INVISIBLE }, fieldProps, { id: fieldId, required: isRequired, innerRef: innerRef })), children] })) }));
};
exports.RegistrationField = RegistrationField;
