"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBrandListerFilter = exports.updateBrandListerFilterSaga = exports.getModuleState = void 0;
const effects_1 = require("redux-saga/effects");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const getModuleState = state => ({
    brandLister: state.brandLister,
});
exports.getModuleState = getModuleState;
function* updateBrandListerFilterSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BrandListerActionTypes.SET_FILTER, updateBrandListerFilter);
}
exports.updateBrandListerFilterSaga = updateBrandListerFilterSaga;
function* updateBrandListerFilter(action) {
    const partialRootState = yield (0, effects_1.select)(exports.getModuleState);
    const pushingRouterStateTask = yield (0, effects_1.fork)(RouterSagas_1.pushRouterState, {
        filter: action.payload,
    }, Object.assign({}, partialRootState));
    yield (0, effects_1.join)(pushingRouterStateTask);
}
exports.updateBrandListerFilter = updateBrandListerFilter;
function* brandListerSaga() {
    yield (0, effects_1.all)([updateBrandListerFilterSaga()]);
}
exports.default = brandListerSaga;
