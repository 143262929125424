"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ActionEnums_1 = require("../../enums/ActionEnums");
const PRODUCT_QUANTITY = 1;
const PromoBasketPopupCTA = ({ addToBasket, closePopup }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const onAddToBasketButtonClick = () => {
        addToBasket(PRODUCT_QUANTITY, ActionEnums_1.ProductAddToBasketLocations.PROMO_BASKET_POPUP, true);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, dataQA: "add_to_basket", size: isMobile ? cotopaxi_1.ButtonSize.NORMAL : cotopaxi_1.ButtonSize.LARGE, onClick: onAddToBasketButtonClick, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: t('product:pdp.cart.addtobasket.btn'), iconLeft: {
                        name: cotopaxi_1.IconName.CART,
                        size: cotopaxi_1.IconSize.MEDIUM,
                    } }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, text: t('checkout:basket.promo.popup.cancel'), variant: cotopaxi_1.ButtonVariant.LINK, onClick: closePopup })] }));
};
exports.PromoBasketPopupCTA = PromoBasketPopupCTA;
