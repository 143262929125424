"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productGroupActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.productGroupActions = {
    init: () => ({
        type: ActionEnums_1.ProductGroupActionTypes.INIT,
    }),
    getDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductGroupActionTypes.GET_DELIVERY_PROMISE,
        payload,
    }),
    setDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductGroupActionTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
    setProductGroups: (payload) => ({
        type: ActionEnums_1.ProductGroupActionTypes.SET_PRODUCT_GROUPS,
        payload,
    }),
};
