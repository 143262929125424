"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeDropdown = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductUtil_1 = require("../../util/ProductUtil");
const SizeDropdown = ({ id, sizes, options, width, dataQA, toggleElement, extraContent, handleSelectedItemChange, appendToBody, stickyWrapper, selectedSku, toggleElementRef, isOpen, onOpen, onClose, disabled, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const selectedOption = options.length === 1
        ? options[0]
        : selectedSku
            ? options.find(({ value }) => String(value) === String(selectedSku))
            : null;
    if (!sizes.length)
        return null;
    const hasCommercialSizeDefault = sizes.some(size => Boolean(size.commercialSizeDefault));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Dropdown, { id: id, isOpen: isOpen, onClose: onClose, onOpen: onOpen, toggleElementRef: toggleElementRef, lockScroll: true, width: width, minWidth: hasCommercialSizeDefault ? '280px' : '180px', dataQA: dataQA, appendToBody: appendToBody, extraContent: extraContent, stickyWrapper: stickyWrapper, handleSelectedItemChange: handleSelectedItemChange, toggleElement: props => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonRef, Object.assign({ state: disabled ? cotopaxi_1.ButtonState.DISABLED : undefined, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_TOGGLE, fill: true, ariaSelected: props.open, text: toggleElement }, props))), content: ({ getItemProps }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { height: "286px", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Table, { align: cotopaxi_1.TableAlign.LEFT, withHover: true, children: [hasCommercialSizeDefault && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Table.Head, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Table.Row, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Table.HeadCell, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                            spacing: {
                                                paddingTop: 2,
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                            },
                                        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, children: t('pdp.sizeSmall') }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Table.HeadCell, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                            spacing: {
                                                paddingTop: 2,
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                            },
                                        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, children: t('pdp.size.label') }) }) })] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Table.Body, { children: options.map((item, index) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Table.Row, { highlighted: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) === (item === null || item === void 0 ? void 0 : item.label), disabled: item.disabled, children: [hasCommercialSizeDefault && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Table.Cell, Object.assign({}, getItemProps({ item, index }), { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                                spacing: {
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    paddingTop: 1.5,
                                                    paddingBottom: 1.5,
                                                },
                                            }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, strike: item.disabled || item.isSoldOut, color: item.disabled || item.isSoldOut ? cotopaxi_1.TextColor.SUBTLE : undefined, children: [(0, ProductUtil_1.makeCommercialSize)(sizes[index]), "\u00A0"] }) }), index + 1 !== options.length && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) }))] }))), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Table.Cell, Object.assign({}, getItemProps({ item, index }), { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                                spacing: {
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    paddingTop: 1.5,
                                                    paddingBottom: 1.5,
                                                },
                                            }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, strike: item.disabled || item.isSoldOut, color: item.disabled || item.isSoldOut ? cotopaxi_1.TextColor.SUBTLE : undefined, children: [(0, ProductUtil_1.makeOriginalSize)(sizes[index]), "\u00A0"] }) }), index + 1 !== options.length && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                                spacing: {
                                                    paddingRight: 2,
                                                    paddingLeft: hasCommercialSizeDefault ? 0 : 2,
                                                },
                                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) }))] }))] }, `${item.label}${index}`))) })] }) })) }));
};
exports.SizeDropdown = SizeDropdown;
