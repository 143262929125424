"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configureDynamicStoreBase = void 0;
const logger_1 = require("@as-react/logger");
const redux_dynamic_modules_1 = require("redux-dynamic-modules");
const redux_dynamic_modules_saga_1 = require("redux-dynamic-modules-saga");
const analyticsAdobeTagManagerMiddlewareBuilder_1 = require("../middleware/analyticsAdobeTagManagerMiddlewareBuilder");
const analyticsCriteoMiddlewareBuilder_1 = require("../middleware/analyticsCriteoMiddlewareBuilder");
const analyticsGoogleTagManagerMiddlewareBuilder_1 = require("../middleware/analyticsGoogleTagManagerMiddlewareBuilder");
const routerMiddlewareBuilder_1 = require("../middleware/routerMiddlewareBuilder");
const Router_module_1 = require("../rootModules/modules/Router.module");
const customEventMiddlewareBuilder_1 = require("../middleware/customEventMiddlewareBuilder");
const configureDynamicStoreBase = (initialState, context, dynamicStore, componentReduxModules, history, composeEnhancers) => {
    if (!dynamicStore.getStore()) {
        dynamicStore.setStore((0, redux_dynamic_modules_1.createStore)({
            initialState: initialState,
            extensions: [(0, redux_dynamic_modules_saga_1.getSagaExtension)(context)],
            enhancers: logger_1.Logger.reduxEnhancer ? [logger_1.Logger.reduxEnhancer] : undefined,
            advancedComposeEnhancers: composeEnhancers,
        }, 
        /* Custom compose */
        (0, analyticsAdobeTagManagerMiddlewareBuilder_1.createDynamicModuleLoaderAnalyticsAdobeTagManager)(), (0, analyticsCriteoMiddlewareBuilder_1.createDynamicModuleLoaderAnalyticsCriteo)(), (0, analyticsGoogleTagManagerMiddlewareBuilder_1.createDynamicModuleLoaderAnalyticsGoogleTagManager)(), (0, customEventMiddlewareBuilder_1.default)()));
    }
    if (componentReduxModules) {
        if (Array.isArray(componentReduxModules)) {
            dynamicStore.getStore().addModules(componentReduxModules);
        }
        else {
            dynamicStore.getStore().addModule(componentReduxModules);
        }
    }
    if (history) {
        dynamicStore.getStore().addModules([(0, Router_module_1.default)(history), (0, routerMiddlewareBuilder_1.createDynamicModuleLoaderRouterMiddleWare)(history)]);
    }
    return dynamicStore.getStore();
};
exports.configureDynamicStoreBase = configureDynamicStoreBase;
