"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductGroupPdpComparisonFeatureItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductGroupPdpComparison_1 = require("./ProductGroupPdpComparison");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const common_utils_1 = require("@as-react/common-utils");
var DIVIDER_SPACING;
(function (DIVIDER_SPACING) {
    DIVIDER_SPACING[DIVIDER_SPACING["MOBILE"] = 1.5] = "MOBILE";
    DIVIDER_SPACING[DIVIDER_SPACING["DESKTOP"] = 2] = "DESKTOP";
})(DIVIDER_SPACING || (DIVIDER_SPACING = {}));
const DIVIDER_BOX_PARAMS = {
    mobile: {
        spacing: {
            marginTop: DIVIDER_SPACING.MOBILE,
            marginBottom: DIVIDER_SPACING.MOBILE,
        },
    },
    desktop: {
        spacing: {
            marginTop: DIVIDER_SPACING.DESKTOP,
            marginBottom: DIVIDER_SPACING.DESKTOP,
        },
    },
};
const ProductGroupPdpComparisonFeatureItem = ({ name, value, productIndex, featureIndex, isGlossaryLoaded, shouldInitGlossaryTooltip, }) => {
    const [isTooltipApplied, setIsTooltipApplied] = (0, react_1.useState)(false);
    const tooltipId = `feature-item-${productIndex}-${featureIndex}`;
    const getGlossaryItem = (title, isLoaded) => { var _a; return isLoaded ? (_a = window[GeneralConstants_1.GLOSSARY_KEY]) === null || _a === void 0 ? void 0 : _a.find((glossary) => glossary.triggers.includes(title)) : null; };
    const glossaryItem = (0, react_1.useMemo)(() => getGlossaryItem(name, isGlossaryLoaded), [isGlossaryLoaded, shouldInitGlossaryTooltip]);
    (0, react_1.useEffect)(() => {
        if (!!glossaryItem && !isTooltipApplied) {
            (0, common_utils_1.setTooltip)({
                element: `#${tooltipId}`,
                content: glossaryItem.description,
                title: glossaryItem.title,
                showCloseButton: false,
            });
            setIsTooltipApplied(true);
        }
    }, [glossaryItem]);
    const featureGridArea = `${ProductGroupPdpComparison_1.GRID_AREAS.PRODUCT}-${productIndex}-${ProductGroupPdpComparison_1.GRID_AREAS.FEATURE}-${featureIndex}`;
    let renderValue = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, lineClamp: 3, children: value }));
    if ((0, common_utils_1.isNullOrUndefined)(value)) {
        renderValue = null;
    }
    else if (typeof value === 'boolean') {
        renderValue = value ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHECK, size: cotopaxi_1.IconSize.MEDIUM, color: cotopaxi_1.IconColor.POSITIVE })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.MINUS, size: cotopaxi_1.IconSize.MEDIUM, color: "N500" }));
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { style: { gridArea: `${featureGridArea}-${ProductGroupPdpComparison_1.GRID_AREAS.FEATURE_TITLE}` }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: ProductGroupPdpComparison_1.GRID_BOX_PARAMS.mobile, tablet: ProductGroupPdpComparison_1.GRID_BOX_PARAMS.tablet, children: [featureIndex > 0 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: DIVIDER_BOX_PARAMS.mobile, desktop: DIVIDER_BOX_PARAMS.desktop, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, size: cotopaxi_1.TextSize.SMALL, inline: true, children: name }), glossaryItem && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { id: tooltipId, text: name, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_ALT, size: cotopaxi_1.ButtonSize.XSMALL, icon: {
                                        name: cotopaxi_1.IconName.INFORMATION_OUTLINE,
                                        color: 'N500',
                                    } }))] })] }) }), (0, jsx_runtime_1.jsx)("div", { style: { gridArea: `${featureGridArea}-${ProductGroupPdpComparison_1.GRID_AREAS.FEATURE_VALUE}` }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: Object.assign(Object.assign({}, ProductGroupPdpComparison_1.GRID_BOX_PARAMS.mobile.spacing), { marginTop: 0.5 }),
                    }, tablet: ProductGroupPdpComparison_1.GRID_BOX_PARAMS.tablet, children: renderValue }) })] }));
};
exports.ProductGroupPdpComparisonFeatureItem = ProductGroupPdpComparisonFeatureItem;
