"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ContinueShoppingCTA = ({ root }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [isButtonFill, setIsButtonFill] = (0, react_1.useState)(false);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    (0, react_1.useEffect)(() => {
        setIsButtonFill(!isDesktop);
    }, [isDesktop]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: { marginBottom: 4, marginTop: 2 },
        }, desktop: {
            spacing: { marginBottom: 7, marginTop: 3 },
        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isButtonFill, href: root, variant: cotopaxi_1.ButtonVariant.OUTLINED, text: t('account.payback.continue.shopping'), iconLeft: {
                name: cotopaxi_1.IconName.CHEVRON_LEFT,
                size: cotopaxi_1.IconSize.MAJOR,
            }, dataQA: "continue_shopping" }) }));
};
exports.default = ContinueShoppingCTA;
