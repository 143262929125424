"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ComparePopupContainer_1 = require("../ComparePopupContainer");
const AppliedFilterContainer_1 = require("../lister/AppliedFilterContainer");
const FilterContainer_1 = require("../lister/FilterContainer");
const ListerDividerContainer_1 = require("../lister/ListerDividerContainer");
const ListerHeadingContainer_1 = require("../lister/ListerHeadingContainer");
const ListerPaginationContainer_1 = require("../lister/ListerPaginationContainer");
const ListerResultContainer_1 = require("../lister/ListerResultContainer");
const ListerSeoTextContainer_1 = require("../lister/ListerSeoTextContainer");
const QuickFilterContainer_1 = require("../lister/QuickFilterContainer");
const SortContainer_1 = require("../lister/SortContainer");
const InStoreFilterContainer_1 = require("../lister/InStoreFilterContainer");
const InStoreFilterPopupContainer_1 = require("../lister/InStoreFilterPopupContainer");
const InStoreFilterShoplanding_1 = require("../../components/lister/in-store-filter-shoplanding/InStoreFilterShoplanding");
const PartialSearchResultsContainer_1 = require("../PartialSearchResultsContainer");
const ProductSchemaDataContainer_1 = require("../lister/ProductSchemaDataContainer");
const ListerEmptyResultContainer_1 = require("../lister/ListerEmptyResultContainer");
const ProductListerUtil_1 = require("../../util/ProductListerUtil");
const useABTest_1 = require("../../hooks/useABTest");
const common_types_1 = require("@as-react/common-types");
const FilterUtil_1 = require("../../util/FilterUtil");
const ProductListerContainer = () => {
    var _a;
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const listerConfiguration = (0, ConfigurationUtil_1.getListerConfiguration)(aemConfiguration);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const pageInfo = (0, ConfigurationUtil_1.getPageInfo)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const seoTextInclude = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetSeoTextInformation)());
    const isCSR = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetIsCSR)());
    const hideTitle = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetHideTitle)());
    const executionStatus = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetExecutionStatus)());
    const isLoaded = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetIsLoaded)());
    const enableJsonLd = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetEnableJsonLd)());
    const isClient = (0, cotopaxi_1.useIsClient)();
    const seoTextLocation = (_a = aemConfiguration === null || aemConfiguration === void 0 ? void 0 : aemConfiguration.seo) === null || _a === void 0 ? void 0 : _a.productListerSEOTextLocation;
    const newMobileFilterDesignABCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.NEW_MOBILE_FILTER_DESIGN);
    const showNewMobileFilterDesign = (0, FilterUtil_1.isNewMobileFilterDesignEnabled)(featureToggles === null || featureToggles === void 0 ? void 0 : featureToggles.new_mobile_filter_design, newMobileFilterDesignABCookie);
    const getSeoText = (seoTextOrder) => {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: seoTextOrder, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: { marginTop: 1 },
                }, children: (0, jsx_runtime_1.jsx)(ListerSeoTextContainer_1.ListerSeoTextContainer, { seoTextInclude: seoTextInclude }) }) }));
    };
    (0, react_1.useEffect)(() => {
        if (isCSR) {
            dispatch(ListerActions_creators_1.listerActions.getListerProducts());
        }
    }, []);
    if (isCSR && (!isClient || !isLoaded)) {
        return null;
    }
    else if ((0, ProductListerUtil_1.isPartialSearchResult)(executionStatus)) {
        return (0, jsx_runtime_1.jsx)(PartialSearchResultsContainer_1.default, {});
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [featureToggles.lister_json_ld && enableJsonLd && (0, jsx_runtime_1.jsx)(ProductSchemaDataContainer_1.ProductSchemaDataContainer, {}), (0, jsx_runtime_1.jsxs)(cotopaxi_1.ListerLayout, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 5, variant: cotopaxi_1.ListerLayoutItemVariants.FILTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: { marginTop: 2 },
                            }, desktop: {
                                spacing: { marginTop: 0 },
                            }, children: (0, jsx_runtime_1.jsx)(FilterContainer_1.FilterContainer, { isBrandPage: pageInfo.isBrandPage, showInStoreFilter: featureToggles.lister_in_store_filter }) }) }), !hideTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 1, children: (0, jsx_runtime_1.jsx)(ListerHeadingContainer_1.ListerHeadingContainer, {}) })), !!seoTextInclude && seoTextLocation === 'top' && getSeoText(2), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 3, children: (0, jsx_runtime_1.jsx)(QuickFilterContainer_1.QuickFilterContainer, {}) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(ListerDividerContainer_1.ListerDividerContainer, {}) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 6, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: { marginTop: 2 },
                                }, desktop: {
                                    spacing: { marginTop: 0 },
                                }, children: (0, jsx_runtime_1.jsx)(AppliedFilterContainer_1.AppliedFilterContainer, {}) }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 7, variant: cotopaxi_1.ListerLayoutItemVariants.SORT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: { marginTop: 3 },
                            }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: showNewMobileFilterDesign, wrapper: children => (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: children }), children: (0, jsx_runtime_1.jsx)(SortContainer_1.SortContainer, { sortConfiguration: listerConfiguration.sortConfiguration }) }), featureToggles.lister_in_store_filter && ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(InStoreFilterContainer_1.default, {}) }))] }) }) }), showNewMobileFilterDesign && ((0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 8, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(ListerDividerContainer_1.ListerDividerContainer, {}) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 9, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                                spacing: { marginTop: 3 },
                            }, children: [(0, jsx_runtime_1.jsx)(ListerResultContainer_1.ListerResultContainer, {}), (0, jsx_runtime_1.jsx)(ListerEmptyResultContainer_1.ListerEmptyResultContainer, {})] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ListerLayoutItem, { order: 10, gutter: cotopaxi_1.ListerLayoutItemGutter.LARGE, children: (0, jsx_runtime_1.jsx)(ListerPaginationContainer_1.ListerPaginationContainer, {}) }), !!seoTextInclude && seoTextLocation === 'bottom' && getSeoText(11)] }), featureToggles.product_compare && (0, jsx_runtime_1.jsx)(ComparePopupContainer_1.default, {}), featureToggles.lister_in_store_filter && (0, jsx_runtime_1.jsx)(InStoreFilterPopupContainer_1.default, {}), isShoplanding && (0, jsx_runtime_1.jsx)(InStoreFilterShoplanding_1.InStoreFilterShoplanding, {})] }));
};
exports.default = ProductListerContainer;
