"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shoplandingPayment = exports.shoplandingPaymentSaga = exports.analyticsConfirmationPage = exports.analyticsConfirmationPageSaga = exports.orderConfirmation = exports.orderConfirmationSaga = exports.getStoresByLocation = exports.getStoresBySearch = exports.getPickupPointsByLocation = exports.getPickupPointsBySearch = exports.voucherChanges = exports.voucherChangesSaga = exports.submitFastCheckout = exports.submitFastCheckoutSaga = exports.savePaymentOption = exports.savePaymentOptionSaga = exports.changePaymentOption = exports.changePaymentOptionSaga = exports.getStoreDetails = exports.getPaymentInfo = exports.getPaymentInfoSaga = exports.changeDeliveryStore = exports.changeDeliveryOption = exports.getCustomerTitles = exports.saveDeliveryInfo = exports.changeDeliveryCountry = exports.getDeliveryInfo = exports.getDeliveryCountries = exports.getFastCheckoutOverview = exports.getFastCheckoutOverviewSaga = exports.analyticsLoginCheckout = exports.analyticsLoginCheckoutSaga = exports.initCheckout = exports.getCheckoutState = exports.initCheckoutSaga = exports.getSelectedPaymentOption = exports.getPaymentInfoState = void 0;
const effects_1 = require("redux-saga/effects");
const connected_react_router_1 = require("connected-react-router");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const CheckoutAPI_1 = require("../../../api/CheckoutAPI");
const BuyAPI_1 = require("../../../api/buy/BuyAPI");
const GeneralAPI_1 = require("../../../api/general/GeneralAPI");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const StoreAPI_1 = require("../../../api/store/StoreAPI");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const CheckoutActions_creators_1 = require("../actions/CheckoutActions.creators");
const AnalyticsActions_creators_1 = require("../../analytics/actions/AnalyticsActions.creators");
const BasketUtil_1 = require("../../../util/BasketUtil");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const KlarnaUtil_1 = require("../../../util/KlarnaUtil");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const EventUtil_1 = require("../../../util/EventUtil");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const ReevooUtil_1 = require("../../../util/ReevooUtil");
const LuxUtil_1 = require("../../../util/LuxUtil");
const ABTestUtil_1 = require("../../../util/ABTestUtil");
const SessionSelector_1 = require("../../session/selectors/SessionSelector");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const BuyAddressFields_1 = require("../../../components/buy/forms/buy-address-fields/BuyAddressFields");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const axios_1 = require("axios");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const getDeliveryInfoState = (state) => state.checkout.deliveryInfo;
const getPaymentInfoState = (state) => state.checkout.paymentInfo;
exports.getPaymentInfoState = getPaymentInfoState;
const getSelectedPaymentOption = (state) => state.checkout.selectedPaymentOption;
exports.getSelectedPaymentOption = getSelectedPaymentOption;
const getFastCheckoutOverviewState = (state) => state.checkout.fastCheckoutOverview;
const getIsSetPaymentOptionSuccess = (state) => state.checkout.isSetPaymentOptionSuccess;
const getOrderReference = (state) => state.checkout.paymentInfo.orderReference;
const getActiveDeliveryOption = (state) => state.checkout.deliveryInfo.deliveryOptions.find(option => option.name === state.checkout.deliveryInfo.chosenDeliveryOption);
const getCountry = (state) => state.checkout.deliveryInfo.country;
const getSelectedPickUpPointId = (state) => { var _a, _b; return (_b = (_a = state.checkout.deliveryInfo.pickupInformation) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.locationId; };
function* initCheckoutSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.INIT, initCheckout);
}
exports.initCheckoutSaga = initCheckoutSaga;
const getCheckoutState = (state) => state.checkout.state;
exports.getCheckoutState = getCheckoutState;
function* initCheckout(action) {
    var _a;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const currentCheckoutState = yield (0, effects_1.select)(exports.getCheckoutState);
        const loggedIn = yield (0, effects_1.select)((0, SessionSelector_1.makeGetLoggedInState)());
        const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
        if (currentCheckoutState === common_types_1.CheckoutStatus.INITIALIZING) {
            const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
            const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
            const checkoutInitData = yield (0, effects_1.call)(CheckoutAPI_1.default.initRequest, servicesEndpoint, defaultRequestParams);
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createInitSetAction(checkoutInitData));
            if (checkoutInitData.readyForCheckout) {
                if (checkoutInitData.fastCheckoutEligible) {
                    ABTestUtil_1.default.redirectToCheckout(root, 'buy.html', `/${common_types_1.RoutePathname.FAST_CHECKOUT}`);
                }
                else if ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.hrefCheckout) {
                    ABTestUtil_1.default.redirectToCheckout(root, loggedIn ? `buy.html/${common_types_1.RoutePathname.DELIVERY}` : 'buy.html');
                }
            }
        }
    }
    catch (e) {
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createErrorAction());
    }
}
exports.initCheckout = initCheckout;
function* analyticsLoginCheckoutSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.LOGIN_ANALYTICS, analyticsLoginCheckout);
}
exports.analyticsLoginCheckoutSaga = analyticsLoginCheckoutSaga;
function* analyticsLoginCheckout() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(CheckoutAPI_1.default.checkoutStepDtm, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createAnalyticsSuccessAction(analyticsAdobeTagManagerData));
    }
    catch (_a) { }
}
exports.analyticsLoginCheckout = analyticsLoginCheckout;
function* getFastCheckoutOverviewSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_FAST_CHECKOUT_OVERVIEW, getFastCheckoutOverview);
}
exports.getFastCheckoutOverviewSaga = getFastCheckoutOverviewSaga;
function* getFastCheckoutOverview() {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        yield (0, effects_1.call)(BuyAPI_1.default.fastCheckoutValidate, servicesEndpoint, defaultRequestParams);
        const answer = yield (0, effects_1.call)(BuyAPI_1.default.getFastCheckoutOverview, servicesEndpoint, defaultRequestParams);
        const contact = yield (0, effects_1.call)(CheckoutAPI_1.default.contact, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetFastCheckoutOverviewAction(answer));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetCustomerDetailsAction(contact));
    }
    catch (error) {
        location.href = `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`;
    }
}
exports.getFastCheckoutOverview = getFastCheckoutOverview;
function* getDeliveryCountriesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_DELIVERY_COUNTRIES, getDeliveryCountries);
}
function* getDeliveryCountries() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const result = yield (0, effects_1.call)(CheckoutAPI_1.default.getDeliveryCountries, servicesEndpoint, defaultRequestParams);
        const sortedCountries = (0, common_utils_1.sortCountriesAlphabetically)(result.countryResourceList, defaultRequestParams.language);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryCountriesAction(sortedCountries));
    }
    catch (_a) { }
}
exports.getDeliveryCountries = getDeliveryCountries;
function* getDeliveryInfoSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_DELIVERY_INFO, getDeliveryInfo);
}
function* getDeliveryInfo(action) {
    var _a, _b, _c, _d;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        const deliveryData = yield (0, effects_1.call)(BuyAPI_1.default.getDelivery, servicesEndpoint, defaultRequestParams, action.payload);
        if (!deliveryData.customerDetails) {
            const userData = SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
            if (userData && userData.customerDetails) {
                deliveryData.customerDetails = userData.customerDetails;
            }
            if (userData && userData.deliveryAddress) {
                deliveryData.deliveryAddress = userData.deliveryAddress;
            }
        }
        if (deliveryData.chosenDeliveryOption) {
            deliveryData.chosenDeliveryOption =
                ((_a = deliveryData.deliveryOptions
                    .filter(option => !option.exclusionCausedByProduct)
                    .find(option => option.name === deliveryData.chosenDeliveryOption)) === null || _a === void 0 ? void 0 : _a.name) || null;
        }
        if (((_b = deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.customerDetails) === null || _b === void 0 ? void 0 : _b.idTitle) === -1) {
            deliveryData.customerDetails.idTitle = undefined;
        }
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryInfoAction(deliveryData));
        // Get state name in case of US address
        if (deliveryData.idCountry === 20) {
            const usStates = yield (0, effects_1.call)(GeneralAPI_1.default.getUsStates, servicesEndpoint, defaultRequestParams, deliveryData.idCountry);
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryUSStatesAction(usStates));
            const usState = (0, BuyFormUtil_1.findUSStateByCounty)(usStates, deliveryData.deliveryAddress);
            if (usState) {
                yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryAddressStateAction(usState));
            }
        }
        if ((0, BuyFormUtil_1.showDeliveryDates)(deliveryData.deliveryOptions)) {
            const deliveryDates = yield (0, effects_1.call)(BuyAPI_1.default.getNamedDayDeliveryDates, servicesEndpoint, defaultRequestParams);
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryDatesAction(deliveryDates));
        }
        if ((_d = (_c = deliveryData.pickupInformation) === null || _c === void 0 ? void 0 : _c.properties) === null || _d === void 0 ? void 0 : _d.locationId) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetActivePickupPointIdAction(deliveryData.pickupInformation.properties.locationId));
        }
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotFound(error) || BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }
}
exports.getDeliveryInfo = getDeliveryInfo;
function* changeDeliveryCountrySaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_COUNTRY, changeDeliveryCountry);
}
function* changeDeliveryCountry(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        yield (0, effects_1.call)(BuyAPI_1.default.changeDeliveryCountry, servicesEndpoint, defaultRequestParams, action.payload);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createResetPickupPointsAction());
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createGetDeliveryInfoAction(action.payload));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createChangeCollectionPointViewAction(false));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }
}
exports.changeDeliveryCountry = changeDeliveryCountry;
function* saveDeliveryInfoSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO, saveDeliveryInfo);
}
function* saveDeliveryInfo({ payload: { values, pickupInformation, updateDeliveryAddress, newDeliveryAddress }, }) {
    var _a, _b, _c, _d;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    const inAuthorMode = yield (0, effects_1.call)(SagaContextSelectors_1.getInAuthorMode, aemConfigurationContext);
    const deliveryInfo = yield (0, effects_1.select)(getDeliveryInfoState);
    SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
    const getFieldName = (field, prefix) => {
        const fieldName = field.replace(prefix, '');
        return `${fieldName.charAt(0).toLowerCase()}${fieldName.slice(1)}`;
    };
    const delivery = Object.assign({}, deliveryInfo);
    let invoiceAddress = {};
    if (!delivery.customerDetails) {
        delivery.customerDetails = {};
    }
    if (pickupInformation) {
        delivery.pickupInformation = pickupInformation;
    }
    if (deliveryInfo.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_STORE) {
        delivery.customerDetails.needInvoice = false;
    }
    delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle, phoneNumber: values.phoneNumber || '' });
    if ((values.saveAddress && !updateDeliveryAddress) || newDeliveryAddress) {
        delete delivery.deliveryAddress.deliveryAddressId;
    }
    if (delivery.deliveryDate) {
        delivery.deliveryDate = values.deliveryDate || null;
    }
    if (delivery.chosenDeliveryOption !== common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
        delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { addressLine2: values.addressLine2, addressLine3: values.addressLine3, addressLine4: values.addressLine4, addToProfile: values.saveAddress, idCountry: deliveryInfo.idCountry, country: deliveryInfo.country, customTown: values.customTown, companyName: values.companyName, address: values.address, houseNumber: values.houseNumber, houseNumberAddition: values.houseNumberAddition, postCode: values.postCode, city: values.city, county: values.county, state: values.state });
    }
    // Check if delivery address is an US address
    if (delivery.idCountry === 20) {
        delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { county: values.state, state: values.state, stateIso: values.stateIso });
    }
    if (delivery.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
        delivery.customerDetails.address = Object.assign(Object.assign({}, delivery.customerDetails.address), { postCode: values.postCode, houseNumber: values.houseNumber, houseNumberAddition: values.houseNumberAddition, address: values.address, city: values.city });
    }
    if ((_b = (_a = delivery.customerDetails) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) {
        invoiceAddress = Object.assign({}, delivery.customerDetails.address);
    }
    for (const field in values) {
        if (Object.hasOwn(values, field) &&
            field.includes(BuyAddressFields_1.BuyAddressFieldsPrefix.INVOICE) &&
            values[field]) {
            invoiceAddress = Object.assign(Object.assign({}, invoiceAddress), { [getFieldName(field, BuyAddressFields_1.BuyAddressFieldsPrefix.INVOICE)]: values[field] });
        }
    }
    delivery.customerDetails.invoiceAddress = invoiceAddress;
    delivery.customerDetails.needInvoice = values.needInvoice;
    delivery.customerDetails.alternateInvoiceAddress = values.alternateInvoiceAddress;
    delivery.customerDetails.companyDepartment = values.invoiceCompanyDepartment;
    delivery.customerDetails.companyName = values.invoiceCompanyName;
    delivery.customerDetails.vatNumber = values.invoiceVatNumber;
    if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
        delivery.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle });
    }
    try {
        if (values.savePhoneNumber &&
            values.phoneNumber &&
            values.phoneNumber !== ((_c = delivery.customerDetails) === null || _c === void 0 ? void 0 : _c.mobilePhoneNumber)) {
            delivery.customerDetails.mobilePhoneNumber = values.phoneNumber;
            yield (0, effects_1.call)(CustomerApi_1.default.updatePhoneNumber, servicesEndpoint, defaultRequestParams.mainWebshop, values.phoneNumber);
        }
        if (deliveryInfo.chosenDeliveryOption) {
            yield (0, effects_1.call)(BuyAPI_1.default.postDeliveryOption, servicesEndpoint, defaultRequestParams, deliveryInfo.chosenDeliveryOption);
        }
        yield (0, effects_1.call)(BuyAPI_1.default.postDelivery, servicesEndpoint, defaultRequestParams, delivery);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSaveDeliveryInfoSuccessAction(delivery));
        yield (0, effects_1.put)((0, connected_react_router_1.push)((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.PAYMENT}`, inAuthorMode)));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
                return;
            }
            if ((_d = error.response) === null || _d === void 0 ? void 0 : _d.data.find((item) => item.code === 'non_numeric_field')) {
                yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSaveDeliveryInfoFailAction(common_types_1.ErrorMessage.INVALID_POSTAL_CODE));
            }
            else {
                if ((0, BuyFormUtil_1.isUkCountry)(delivery.country)) {
                    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSaveDeliveryInfoFailAction(common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY));
                }
                else {
                    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSaveDeliveryInfoFailAction(common_types_1.ErrorMessage.API_ERROR));
                }
            }
        }
    }
}
exports.saveDeliveryInfo = saveDeliveryInfo;
function* getCustomerTitlesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_CUSTOMER_TITLES, getCustomerTitles);
}
function* getCustomerTitles() {
    const CUSTOM_OBJECT_TITLES = 'SITE.data.titles';
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const lang = yield (0, effects_1.call)(SagaContextSelectors_1.getLang, aemConfigurationContext);
    let customerTitles;
    try {
        const titles = (0, common_utils_1.getCustomWindowObject)(CUSTOM_OBJECT_TITLES);
        if (!(0, common_utils_1.isNullOrUndefined)(titles)) {
            customerTitles = titles.map(title => ({
                id: title.idTitle,
                title: title.titleNameTranslations[lang.toUpperCase()],
                sortOrder: title.sortOrder,
            }));
        }
        else {
            customerTitles = yield (0, effects_1.call)([CustomerApi_1.default, CustomerApi_1.default.getTitles], servicesEndpoint, defaultRequestParams, lang);
        }
        customerTitles = customerTitles.sort((a, b) => a.sortOrder - b.sortOrder);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetCustomerTitlesAction(customerTitles));
    }
    catch (_a) { }
}
exports.getCustomerTitles = getCustomerTitles;
function* changeDeliveryOptionSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_OPTION, changeDeliveryOption);
}
function* changeDeliveryOption(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.deliveryStepOption(action.payload));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryOptionAction(action.payload));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }
}
exports.changeDeliveryOption = changeDeliveryOption;
function* changeDeliveryStoreSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_STORE, changeDeliveryStore);
}
function* changeDeliveryStore({ payload: { storeId, storeNumber } }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    const deliveryInfo = yield (0, effects_1.select)(getDeliveryInfoState);
    const customerDetails = deliveryInfo.customerDetails
        ? deliveryInfo.customerDetails
        : { emailOnly: true };
    try {
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetDeliveryStoreAction({ storeId, storeNumber }));
        yield (0, effects_1.call)(BuyAPI_1.default.updateCustomerData, servicesEndpoint, defaultRequestParams, customerDetails);
        yield (0, effects_1.call)(StoreAPI_1.default.setClickAndCollectStoreId, servicesEndpoint, defaultRequestParams, storeId);
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }
}
exports.changeDeliveryStore = changeDeliveryStore;
function* getPaymentInfoSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_PAYMENT_INFO, getPaymentInfo);
}
exports.getPaymentInfoSaga = getPaymentInfoSaga;
function* getPaymentInfo() {
    var _a, _b;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const selectedPaymentOption = yield (0, effects_1.select)(exports.getSelectedPaymentOption);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        const paymentInfo = yield (0, effects_1.call)(BuyAPI_1.default.getPayment, servicesEndpoint, defaultRequestParams);
        if ((_a = paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.deliveryAddress) === null || _a === void 0 ? void 0 : _a.storeId) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createGetStoreDetailsAction(paymentInfo.deliveryAddress.storeId));
        }
        if (defaultRequestParams.mainWebshop === common_types_1.MainWebShop.COTSWOLD &&
            (paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.paymentGroups) &&
            !selectedPaymentOption.name &&
            !selectedPaymentOption.id &&
            !LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION)) {
            const creditCardGroup = paymentInfo.paymentGroups.find(group => group.name === common_types_1.PaymentGroupName.CREDITCARD);
            if (creditCardGroup) {
                yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetSelectedPaymentOptionAction({
                    name: creditCardGroup.name,
                    id: creditCardGroup.paymentOptions[0].paymentOptionId,
                }));
            }
        }
        else if (paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.chosenPaymentOption) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetSelectedPaymentOptionAction({
                name: paymentInfo.chosenPaymentOption,
                id: paymentInfo.chosenPaymentOptionId,
            }));
        }
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentInfoAction(paymentInfo));
        // Get state name in case of US address
        if ((_b = paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.deliveryAddress) === null || _b === void 0 ? void 0 : _b.stateIso) {
            const usStates = yield (0, effects_1.call)(GeneralAPI_1.default.getUsStates, servicesEndpoint, defaultRequestParams, paymentInfo.deliveryAddress.idCountry);
            const usState = (0, BuyFormUtil_1.findUSStateByStateIso)(usStates, paymentInfo.deliveryAddress);
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentAddressStateAction(usState));
        }
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotFound(error) ||
                BasketUtil_1.default.isBasketNotInitialized(error) ||
                BasketUtil_1.default.isCheckoutStepInvalid(error)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }
}
exports.getPaymentInfo = getPaymentInfo;
function* getStoreDetailsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_STORE_DETAILS, getStoreDetails);
}
function* getStoreDetails({ payload: storeId }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const businessUnitId = yield (0, effects_1.call)(SagaContextSelectors_1.getBusinessUnitId, aemConfigurationContext);
    try {
        const storeData = yield (0, effects_1.call)(StoreAPI_1.default.getStoreInformation, servicesEndpoint, defaultRequestParams, businessUnitId, storeId, 1);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetStoreDetailsAction(storeData));
    }
    catch (_a) { }
}
exports.getStoreDetails = getStoreDetails;
function* changePaymentOptionSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.CHANGE_PAYMENT_OPTION, changePaymentOption);
}
exports.changePaymentOptionSaga = changePaymentOptionSaga;
function* changePaymentOption({ payload: { option, group } }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const options = group.paymentOptions;
    const isSingleOption = options.length === 1;
    let optionId;
    let provider;
    let optionName;
    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentOptionErrorAction(null));
    try {
        if (isSingleOption || option === common_types_1.PaymentGroupName.ECOCHEQUE) {
            optionId = options[0].paymentOptionId;
            provider = options[0].provider;
            optionName = options[0].name;
        }
        else {
            const findOption = options.find(opt => opt.name === option);
            optionId = findOption === null || findOption === void 0 ? void 0 : findOption.paymentOptionId;
            provider = findOption === null || findOption === void 0 ? void 0 : findOption.provider;
            optionName = findOption === null || findOption === void 0 ? void 0 : findOption.name;
            if (group.supportsTokenization) {
                yield (0, effects_1.call)(BasketAPI_1.default.postSavePoint, servicesEndpoint);
                yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(group.name));
            }
        }
        if (optionId && provider) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetIsPaymentOptionUpdatingAction(true));
            yield (0, effects_1.call)(BuyAPI_1.default.updatePaymentId, servicesEndpoint, defaultRequestParams, optionId);
            yield (0, effects_1.call)(BasketAPI_1.default.postSavePoint, servicesEndpoint);
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createGetPaymentInfoAction());
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentOptionSuccessAction(true));
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetSelectedPaymentOptionAction({ name: optionName, id: optionId }));
            if (provider === common_types_1.PaymentProvider.KLARNA) {
                const { clientToken } = yield (0, effects_1.call)(BuyAPI_1.default.paymentProviderInputRequest, servicesEndpoint, defaultRequestParams, provider);
                yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetKlarnaAction({ klarnaToken: clientToken }));
            }
        }
        if (optionName) {
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(optionName));
        }
    }
    catch (error) {
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentOptionErrorAction(common_types_1.ErrorMessage.GENERAL_PAYMENT));
    }
    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetIsPaymentOptionUpdatingAction(false));
}
exports.changePaymentOption = changePaymentOption;
function* savePaymentOptionSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.SAVE_PAYMENT_OPTION, savePaymentOption);
}
exports.savePaymentOptionSaga = savePaymentOptionSaga;
function* savePaymentOption({ payload }) {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    const paymentInfo = yield (0, effects_1.select)(exports.getPaymentInfoState);
    const inAuthorMode = yield (0, effects_1.call)(SagaContextSelectors_1.getInAuthorMode, aemConfigurationContext);
    const selectedPaymentOption = yield (0, effects_1.select)(exports.getSelectedPaymentOption);
    try {
        if (paymentInfo.fullyPaid) {
            try {
                const data = yield (0, effects_1.call)(BuyAPI_1.default.orderPaidByVoucher, servicesEndpoint, defaultRequestParams);
                const search = {
                    status: 'accept',
                    orderID: data.orderReference,
                };
                const location = (0, URLParamUtil_1.generatePathUrl)(root, `buy/${common_types_1.RoutePathname.CONFIRMATION}.html`, inAuthorMode, search);
                window.location.href = `${location.pathname}?${location.search}`;
            }
            catch (_b) { }
        }
        else {
            const { group } = payload;
            const options = group.paymentOptions;
            const isSingleOption = options.length === 1;
            const provider = isSingleOption
                ? options[0].provider
                : (_a = options.find(opt => opt.name === selectedPaymentOption.name)) === null || _a === void 0 ? void 0 : _a.provider;
            const payment = {
                chosenPaymentOption: selectedPaymentOption.name,
                chosenPaymentOptionId: selectedPaymentOption.id,
                provider,
                tp: `${defaultRequestParams.mainWebshop}.html`,
            };
            const providerInformation = yield (0, effects_1.call)(BuyAPI_1.default.postPayment, servicesEndpoint, defaultRequestParams, payment);
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
                paymentGroup: group.name,
                paymentMethod: selectedPaymentOption.name,
            }));
            if (providerInformation.provider === common_types_1.PaymentProvider.SAFERPAY ||
                providerInformation.provider === common_types_1.PaymentProvider.WORLDLINE) {
                const { redirectUrl } = yield (0, effects_1.call)(BuyAPI_1.default.paymentProviderInputRequest, servicesEndpoint, defaultRequestParams, providerInformation.provider);
                if (redirectUrl) {
                    location.href = redirectUrl;
                }
            }
            else if (providerInformation.provider === common_types_1.PaymentProvider.KLARNA) {
                const orderReference = yield (0, effects_1.select)(getOrderReference);
                KlarnaUtil_1.default.authorize([providerInformation.paymentMethodCategory], orderReference, result => {
                    if (result.approved === true) {
                        BuyAPI_1.default.paymentProviderPlaceOrder(servicesEndpoint, defaultRequestParams, providerInformation.provider.toLowerCase(), result.authorization_token).then(redirectUrl => {
                            location.href = redirectUrl;
                        });
                    }
                });
            }
        }
    }
    catch (error) {
        if (BasketUtil_1.default.isBasketNotInitialized(error)) {
            location.href = `${root}checkout/basket-overview.html`;
            return;
        }
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentOptionErrorAction(common_types_1.ErrorMessage.GENERAL_PAYMENT));
    }
}
exports.savePaymentOption = savePaymentOption;
function* submitFastCheckoutSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.SUBMIT_FAST_CHECKOUT, submitFastCheckout);
}
exports.submitFastCheckoutSaga = submitFastCheckoutSaga;
function* submitFastCheckout({ payload }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    const inAuthorMode = yield (0, effects_1.call)(SagaContextSelectors_1.getInAuthorMode, aemConfigurationContext);
    const fastCheckoutOverview = yield (0, effects_1.select)(getFastCheckoutOverviewState);
    try {
        if (fastCheckoutOverview.fullyPaid) {
            try {
                const data = yield (0, effects_1.call)(BuyAPI_1.default.orderPaidByVoucher, servicesEndpoint, defaultRequestParams);
                const search = {
                    status: 'accept',
                    orderID: data.orderReference,
                };
                const location = (0, URLParamUtil_1.generatePathUrl)(root, `buy/${common_types_1.RoutePathname.CONFIRMATION}.html`, inAuthorMode, search);
                window.location.href = `${location.pathname}?${location.search}`;
            }
            catch (_a) { }
        }
        else {
            const payment = {
                chosenPaymentOption: payload.paymentDetails.paymentOption,
                chosenPaymentOptionId: payload.paymentDetails.paymentOptionId,
                provider: payload.paymentDetails.provider,
                tp: `${defaultRequestParams.mainWebshop}.html`,
            };
            const providerInformation = yield (0, effects_1.call)(BuyAPI_1.default.postPayment, servicesEndpoint, defaultRequestParams, payment);
            if (providerInformation.provider === common_types_1.PaymentProvider.SAFERPAY) {
                const { redirectUrl } = yield (0, effects_1.call)(BuyAPI_1.default.paymentProviderInputRequest, servicesEndpoint, defaultRequestParams, common_types_1.PaymentProvider.SAFERPAY);
                if (redirectUrl) {
                    location.href = redirectUrl;
                }
            }
            else if (providerInformation.provider === common_types_1.PaymentProvider.KLARNA) {
                const orderReference = yield (0, effects_1.select)(getOrderReference);
                KlarnaUtil_1.default.authorize([providerInformation.paymentMethodCategory], orderReference, result => {
                    if (result.approved === true) {
                        BuyAPI_1.default.paymentProviderPlaceOrder(servicesEndpoint, defaultRequestParams, providerInformation.provider.toLowerCase(), result.authorization_token).then(redirectUrl => {
                            location.href = redirectUrl;
                        });
                    }
                });
            }
        }
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotFound(error) || BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
                return;
            }
        }
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPaymentOptionErrorAction(common_types_1.ErrorMessage.GENERAL_PAYMENT));
    }
}
exports.submitFastCheckout = submitFastCheckout;
function* voucherChangesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.VOUCHER_CHANGES, voucherChanges);
}
exports.voucherChangesSaga = voucherChangesSaga;
function* voucherChanges() {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const isSetPaymentOptionSuccess = yield (0, effects_1.select)(getIsSetPaymentOptionSuccess);
    if (isSetPaymentOptionSuccess) {
        const paymentInfo = yield (0, effects_1.select)(exports.getPaymentInfoState);
        const selectedPaymentOption = yield (0, effects_1.select)(exports.getSelectedPaymentOption);
        const selectedPaymentGroup = (_a = paymentInfo.paymentGroups) === null || _a === void 0 ? void 0 : _a.find(group => group.paymentOptions.find(option => option.paymentOptionId === selectedPaymentOption.id));
        if (selectedPaymentGroup) {
            yield (0, effects_1.call)(changePaymentOption, CheckoutActions_creators_1.default.createChangePaymentOptionAction({
                option: selectedPaymentOption.name,
                group: selectedPaymentGroup,
            }));
        }
    }
    else {
        try {
            yield (0, effects_1.call)(BasketAPI_1.default.postSavePoint, servicesEndpoint);
        }
        catch (_b) { }
    }
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.VOUCHER_CHANGED, true);
}
exports.voucherChanges = voucherChanges;
function* getPickupPointsBySearchSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_PICKUP_POINTS_BY_SEARCH, getPickupPointsBySearch);
}
function* getPickupPointsBySearch({ payload }) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const { maxPickupPoints } = yield (0, effects_1.call)(SagaContextSelectors_1.getBuyDelivery, aemConfigurationContext);
        const { provider, sendDate } = yield (0, effects_1.select)(getActiveDeliveryOption);
        const selectedPickUpPointId = yield (0, effects_1.select)(getSelectedPickUpPointId);
        const country = yield (0, effects_1.select)(getCountry);
        const answer = yield (0, effects_1.call)(BuyAPI_1.default.searchPickupPoints, servicesEndpoint, payload, provider, country, sendDate, maxPickupPoints);
        if (answer.status === common_types_1.HTTPStatusCode.NoContent) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointLocationFieldErrorAction('buy:buy.delivery.pickuppoint.noresults'));
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointsAction([]));
        }
        else {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointsAction(answer.data));
            if (selectedPickUpPointId) {
                const selectedPickUpPoint = answer.data.find(pickUpPoint => pickUpPoint.id === selectedPickUpPointId);
                if (selectedPickUpPoint) {
                    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetActivePickupPointIdAction(selectedPickUpPointId));
                }
            }
        }
    }
    catch (error) {
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointLocationFieldErrorAction('buy:buy.delivery.pickuppoint.noresults'));
    }
}
exports.getPickupPointsBySearch = getPickupPointsBySearch;
function* getPickupPointsByLocationSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_PICKUP_POINTS_BY_LOCATION, getPickupPointsByLocation);
}
function* getPickupPointsByLocation({ payload }) {
    try {
        const RADIUS = 10;
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const { maxPickupPoints } = yield (0, effects_1.call)(SagaContextSelectors_1.getBuyDelivery, aemConfigurationContext);
        const { provider, sendDate } = yield (0, effects_1.select)(getActiveDeliveryOption);
        const selectedPickUpPointId = yield (0, effects_1.select)(getSelectedPickUpPointId);
        const country = yield (0, effects_1.select)(getCountry);
        const answer = yield (0, effects_1.call)(BuyAPI_1.default.searchPickupPointsByGeoLocation, servicesEndpoint, provider, country, sendDate, RADIUS, maxPickupPoints, Number(payload.lat), Number(payload.lng));
        if (answer.status === common_types_1.HTTPStatusCode.NoContent) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointsAction([]));
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetLocationErrorCollectionPointAction('buy:buy.delivery.pickuppoint.noresults'));
        }
        else {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetPickupPointsAction(answer.data));
            if (selectedPickUpPointId) {
                const selectedPickUpPoint = answer.data.find(pickUpPoint => pickUpPoint.id === selectedPickUpPointId);
                if (selectedPickUpPoint) {
                    yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetActivePickupPointIdAction(selectedPickUpPointId));
                }
            }
        }
    }
    catch (error) {
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetLocationErrorCollectionPointAction('buy:buy.delivery.pickuppoint.noresults'));
    }
}
exports.getPickupPointsByLocation = getPickupPointsByLocation;
function* getStoresBySearchSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_STORES_BY_SEARCH, getStoresBySearch);
}
function* getStoresBySearch({ payload: { search, countryCode, setFieldError, errorMessage, onlyInStock }, }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const fasciaFirst = yield (0, effects_1.call)(SagaContextSelectors_1.getFasciaFirstFeatureToggle, aemConfigurationContext);
    const { maxStores } = yield (0, effects_1.call)(SagaContextSelectors_1.getBuyDelivery, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        const location = yield (0, effects_1.call)(GeneralAPI_1.default.getGeoLocation, servicesEndpoint, defaultRequestParams, countryCode, search);
        const stores = yield (0, effects_1.call)(BuyAPI_1.default.getStoresForGeoLocation, servicesEndpoint, defaultRequestParams, location, maxStores, onlyInStock, fasciaFirst);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetStoresAction({ stores, location }));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                window.location.href = `${root}checkout/basket-overview.html`;
            }
        }
        setFieldError('location', errorMessage);
    }
}
exports.getStoresBySearch = getStoresBySearch;
function* getStoresByLocationSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_STORES_BY_LOCATION, getStoresByLocation);
}
function* getStoresByLocation({ payload: { location, onlyInStock } }) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const fasciaFirst = yield (0, effects_1.call)(SagaContextSelectors_1.getFasciaFirstFeatureToggle, aemConfigurationContext);
    const { maxStores } = yield (0, effects_1.call)(SagaContextSelectors_1.getBuyDelivery, aemConfigurationContext);
    const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
    try {
        const stores = yield (0, effects_1.call)(BuyAPI_1.default.getStoresForGeoLocation, servicesEndpoint, defaultRequestParams, location, maxStores, onlyInStock, fasciaFirst);
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetStoresAction({ stores, location }));
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                window.location.href = `${root}checkout/basket-overview.html`;
            }
        }
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createGetStoresFailAction());
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetLocationErrorCollectStoreAction('buy:buy.delivery.store.noresults'));
    }
}
exports.getStoresByLocation = getStoresByLocation;
function* orderConfirmationSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.GET_CONFIRMATION_ORDER, orderConfirmation);
}
exports.orderConfirmationSaga = orderConfirmationSaga;
function* orderConfirmation() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const currency = yield (0, effects_1.call)(SagaContextSelectors_1.getCurrency, aemConfigurationContext);
        const orderId = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'orderID');
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createAnalyticsConfirmationPageAction());
        const order = yield (0, effects_1.call)(BuyAPI_1.default.getConfirmedOrder, servicesEndpoint, defaultRequestParams, orderId);
        const newsletterPreferences = yield (0, effects_1.call)(CustomerApi_1.default.getNewsletterPreferences, servicesEndpoint, defaultRequestParams, order.customerUUID);
        if (newsletterPreferences.newCustomer) {
            const profile_details = {
                email_newsletter: true,
            };
            yield (0, effects_1.call)(CustomerApi_1.default.updatePreferences, servicesEndpoint, defaultRequestParams, profile_details, order.contactPerson);
        }
        const mappedProducts = yield (0, effects_1.all)(order.products.map(product => (0, effects_1.call)(ProductDetailAPI_1.default.fetchProductForConfirmation, aemConfigurationContext.servicesApi, aemConfigurationContext.servicesApiPrefixAemServices, aemConfigurationContext.mainWebShop, aemConfigurationContext.fictiveWebShop, aemConfigurationContext.lang, product)));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetConfirmationOrderAction(Object.assign(Object.assign({}, order), { mappedProducts })));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetNewsletterPreferencesAction(newsletterPreferences));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetConfirmationOrderErrorAction(null));
        yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetConfirmationIsCollectStoreAction(order.deliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_STORE));
        ReevooUtil_1.default.setReevooTracking(order);
        MonetateUtil_1.default.pushOrderConfirmationEvent(mappedProducts, currency, orderId);
    }
    catch (error) {
        if ((0, axios_1.isAxiosError)(error)) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createSetConfirmationOrderErrorAction(error.message));
        }
    }
}
exports.orderConfirmation = orderConfirmation;
function* analyticsConfirmationPageSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.ANALYTICS_CONFIRMATION_PAGE_STEP, analyticsConfirmationPage);
}
exports.analyticsConfirmationPageSaga = analyticsConfirmationPageSaga;
function* analyticsConfirmationPage() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const orderId = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'orderID');
        const purchaseStepInformation = yield (0, effects_1.call)(BuyAPI_1.default.getAnalyticsPurchaseStepInformation, servicesEndpoint, defaultRequestParams, orderId);
        const dtmPurchaseStepInformation = yield (0, effects_1.call)(BuyAPI_1.default.getDtmAnalyticsPurchaseStepInformation, servicesEndpoint, defaultRequestParams, orderId);
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.confirmationPage());
        if (purchaseStepInformation) {
            yield (0, effects_1.put)(CheckoutActions_creators_1.default.createAnalyticsMergeConfirmationPageAction(dtmPurchaseStepInformation));
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.googleConfirmationPage(purchaseStepInformation));
            LuxUtil_1.default.confirmationPage(purchaseStepInformation);
        }
    }
    catch (_a) { }
}
exports.analyticsConfirmationPage = analyticsConfirmationPage;
function* shoplandingPaymentSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.CheckoutActionTypes.SHOPLANDING_PAYMENT, shoplandingPayment);
}
exports.shoplandingPaymentSaga = shoplandingPaymentSaga;
function* shoplandingPayment({ payload }) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const inAuthorMode = yield (0, effects_1.call)(SagaContextSelectors_1.getInAuthorMode, aemConfigurationContext);
        const root = yield (0, effects_1.call)(SagaContextSelectors_1.getRoot, aemConfigurationContext);
        const orderReference = yield (0, effects_1.select)(getOrderReference);
        yield (0, effects_1.call)(BuyAPI_1.default.shoplandingPayment, servicesEndpoint, defaultRequestParams, payload);
        const search = {
            status: 'accept',
            orderID: orderReference,
        };
        const location = (0, URLParamUtil_1.generatePathUrl)(root, `buy/${common_types_1.RoutePathname.CONFIRMATION}.html`, inAuthorMode, search);
        window.location.href = `${location.pathname}?${location.search}`;
    }
    catch (_a) { }
}
exports.shoplandingPayment = shoplandingPayment;
function* checkoutSaga() {
    yield (0, effects_1.all)([
        initCheckoutSaga(),
        analyticsLoginCheckoutSaga(),
        getFastCheckoutOverviewSaga(),
        submitFastCheckoutSaga(),
        getDeliveryCountriesSaga(),
        getDeliveryInfoSaga(),
        changeDeliveryCountrySaga(),
        saveDeliveryInfoSaga(),
        getCustomerTitlesSaga(),
        changeDeliveryOptionSaga(),
        changeDeliveryStoreSaga(),
        getPaymentInfoSaga(),
        getStoreDetailsSaga(),
        changePaymentOptionSaga(),
        savePaymentOptionSaga(),
        voucherChangesSaga(),
        getPickupPointsBySearchSaga(),
        getPickupPointsByLocationSaga(),
        orderConfirmationSaga(),
        analyticsConfirmationPageSaga(),
        getStoresBySearchSaga(),
        getStoresByLocationSaga(),
        shoplandingPaymentSaga(),
    ]);
}
exports.default = checkoutSaga;
