"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CountrySelector = ({ deliveryCountries, deliveryCountryId, deliveryLoading, lang, changeDeliveryCountry, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const options = (0, react_1.useMemo)(() => deliveryCountries.map(country => {
        var _a, _b;
        return ({
            label: (_b = (_a = country.countryTranslations[lang.toUpperCase()]) === null || _a === void 0 ? void 0 : _a.countryDescription) !== null && _b !== void 0 ? _b : '',
            value: country.countryId,
            isEmphasized: deliveryCountryId === country.countryId,
        });
    }), [deliveryCountries, deliveryCountryId, lang]);
    const handleChangeCountry = (countryId) => {
        if (Number(countryId) !== deliveryCountryId) {
            changeDeliveryCountry(Number(countryId));
        }
    };
    if (!(0, ArrayUtil_1.hasItems)(options) || deliveryCountryId === null)
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: !isMobile, gutter: isMobile ? cotopaxi_1.GroupGutter.SMALL : cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, type: cotopaxi_1.TextType.CONTENT, children: t('buy.delivery.country.selector.title') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, tablet: 3, desktop: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { fullWidth: true, name: "country-select", state: deliveryLoading ? cotopaxi_1.SelectState.DISABLED : undefined, options: options, selectedValue: deliveryCountryId, onChange: handleChangeCountry }) })] }));
};
exports.default = CountrySelector;
