"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeVideo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const NativeVideo = ({ url, description, overlayTheme, withOverlay, play, pause, stop, autoPlay, loop, poster, }) => {
    const player = (0, react_1.useRef)(null);
    const [showOverlay, setShowOverlay] = (0, react_1.useState)(withOverlay);
    const playVideo = () => {
        var _a;
        if ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.play) {
            player.current.play();
        }
        showOverlay && setShowOverlay(false);
    };
    const pauseVideo = () => {
        var _a;
        if ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.pause) {
            player.current.pause();
        }
    };
    const stopVideo = () => {
        var _a;
        if ((_a = player === null || player === void 0 ? void 0 : player.current) === null || _a === void 0 ? void 0 : _a.pause) {
            player.current.pause();
            player.current.currentTime = 0;
        }
    };
    (0, react_1.useEffect)(() => {
        pause && pauseVideo();
    }, [pause, player]);
    (0, react_1.useEffect)(() => {
        play && playVideo();
    }, [play, player]);
    (0, react_1.useEffect)(() => {
        stop && stopVideo();
    }, [stop, player]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Video, { nativePlayer: true, nativePlayerRef: player, nativePlayerAutoPlay: autoPlay, nativePlayerLoop: loop, url: url, poster: poster, withOverlay: showOverlay, description: description, onOverlayClick: playVideo, overlayTheme: overlayTheme }));
};
exports.NativeVideo = NativeVideo;
