"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_utils_1 = require("@as-react/common-utils");
const generalEndpoints = {
    getUsStates: 'api/general/county/country',
    getCities: 'api/aem/general/postal',
    getCitiesUk: 'api/aem/general/postal/v2/uk/search',
    getCitiesUkDetail: 'api/aem/general/postal/v2/uk/format',
    checkVAT: 'api/general/vat/validate',
    geoCode: 'api/aem/general/geocode/components',
};
class GeneralApi {
    constructor() {
        this.getUsStates = (servicesApi, defaultRequestParams, countryId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.getUsStates}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { countryId }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getAddresses = (servicesApi, defaultRequestParams, countryId, postalCode, houseNumber, language) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.getCities}/${postalCode}${houseNumber ? `/${houseNumber}` : ''}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { countryId, language }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            if (result.data.addresses) {
                result.data.addresses = result.data.addresses.map(address => {
                    address.city = (0, common_utils_1.capitalize)(address.city);
                    return address;
                });
                return result.data.addresses;
            }
            return result.data;
        });
        this.getAddressUkList = (servicesApi, defaultRequestParams, finder, size) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.getCitiesUk}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { term: finder, size }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data.pickList;
        });
        this.getAddressUkDetail = (servicesApi, defaultRequestParams, moniker) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.getCitiesUkDetail}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { moniker }),
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getCompanyInformationByVAT = (servicesApi, defaultRequestParams, country, vatNumber) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.checkVAT}`,
                params: Object.assign({ vatnumber: vatNumber, country }, defaultRequestParams),
                withCredentials: true,
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data;
            }
            catch (_a) {
                return null;
            }
        });
        this.getGeoLocation = (servicesApi, defaultRequestParams, countryCode, location) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${generalEndpoints.geoCode}`,
                params: {
                    countryCode,
                    languageCode: defaultRequestParams.language,
                    addressQuery: location,
                },
                withCredentials: true,
            };
            const answer = yield axios_1.default.request(axiosConfig);
            return answer.data;
        });
    }
}
exports.default = new GeneralApi();
