"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const LabelsOverlay = ({ showGenderIcon, genderFeatureId, savingsPercentage, priceOverlay, showNewProductLabel, showOnlineExclusiveLabel, showSustainabilityLabel, hasWideGutter, dataQA, maxLabels, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const market = (0, ConfigurationUtil_1.getMarket)(aemConfiguration);
    const { our_planet: showOurPlanet } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const labels = [];
    const labelsToShow = maxLabels ? maxLabels : market === 'nl' ? 2 : 3;
    const { overlayTagColor, overlayTagContent } = (0, ProductUtil_1.getProductPriceOverlayTagColorAndContent)(priceOverlay);
    const showGenderLabel = showGenderIcon && genderFeatureId && (0, cotopaxi_1.getIconCode)(genderFeatureId);
    if (showGenderLabel) {
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { dataQA: "gender_icon", color: cotopaxi_1.TagColor.GENERAL, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: (0, cotopaxi_1.getIconCode)(genderFeatureId), size: cotopaxi_1.IconSize.MINOR }) }, "gender_icon"));
    }
    if (savingsPercentage > 0) {
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.DISCOUNT, dataQA: "savings_percentage", children: `-${savingsPercentage}%` }, "dynamic_discount_label"));
    }
    if (overlayTagContent) {
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: overlayTagColor, dataQA: "price_overlay", children: overlayTagContent }, "commercial_label"));
    }
    if (showNewProductLabel) {
        const { tagColor, translationKey } = (0, ProductUtil_1.getNewProductOverlayTagColorAndTranslationKey)(mainWebShop);
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: tagColor, children: t(translationKey) }, "new_product_label"));
    }
    if (showOnlineExclusiveLabel) {
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { dataQA: "online_exclusive", children: t('pdp.overlay.onlineexclusive') }, "online_exclusive_label"));
    }
    if (showSustainabilityLabel && showOurPlanet) {
        labels.push((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.SUSTAINABLE, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { children: [(0, jsx_runtime_1.jsx)("span", { children: t('product.sustainable') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.LEAF })] }) }, "sustainability_label"));
    }
    if (labels.length === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: hasWideGutter ? 1 : 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { dataQA: dataQA, gutter: hasWideGutter ? cotopaxi_1.GroupGutter.SMALL : { mobile: cotopaxi_1.GroupGutter.XSMALL, desktop: cotopaxi_1.GroupGutter.SMALL }, children: labels.slice(0, labelsToShow) }) }));
};
exports.default = LabelsOverlay;
