"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPExtensiveImageCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../../util/ImageUtil");
const MiniPDPExtensiveImageCarousel = ({ images, imageCdnPrefix, cdnSettings, }) => {
    const slides = (0, react_1.useMemo)(() => images.map((image, i) => ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { lazy: true, src: (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix, '1024', cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.imageQuality, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.requestPassThrough), backgroundImage: true }) }, `shop_look_slide_${i}`))), [images]);
    const shopLookCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.SHOP_LOOK,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
            color: cotopaxi_1.SliderNavColor.BASE,
            iconSize: cotopaxi_1.IconSize.MAJOR,
        },
        showPagination: true,
        configurationOverrides: {
            shouldSwiperUpdate: true,
            watchOverflow: true,
            centeredSlides: true,
        },
    };
    if (images.length < 2) {
        shopLookCarouselConfig.configurationOverrides.navigation = false;
        shopLookCarouselConfig.showPagination = false;
    }
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, shopLookCarouselConfig, { children: slides }));
};
exports.MiniPDPExtensiveImageCarousel = MiniPDPExtensiveImageCarousel;
