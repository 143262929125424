"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnItemsChangeHandler = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const formik_1 = require("formik");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ReturnItemsChangeHandler = () => {
    const { values } = (0, formik_1.useFormikContext)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const selectedItemsCount = (0, react_1.useMemo)(() => {
        return values.saleLines.filter(saleLine => !!saleLine.isSelected).length;
    }, [values.saleLines]);
    const previousCount = (0, cotopaxi_1.usePrevious)(selectedItemsCount);
    (0, react_1.useEffect)(() => {
        if (previousCount === null)
            return;
        const currentCount = values.saleLines.filter(saleLine => !!saleLine.isSelected).length;
        if (previousCount < currentCount) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnProductSelected());
        }
        else if (previousCount > currentCount) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnProductUnselected());
        }
    }, [values.saleLines]);
    (0, react_1.useEffect)(() => {
        if (!values.returnOptionId)
            return;
        dispatch(AnalyticsActions_creators_1.analyticsActions.returnMethodSelected(values.returnOptionId));
    }, [values.returnOptionId]);
    return null;
};
exports.ReturnItemsChangeHandler = ReturnItemsChangeHandler;
