"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const UrlReducer_1 = require("../../url/reducers/UrlReducer");
const UrlSagas_1 = require("../../url/sagas/UrlSagas");
const loadUrlModule = (initialData) => ({
    id: 'Url',
    reducerMap: {
        url: (0, UrlReducer_1.loadUrlReducer)(initialData ? initialData.url : null),
    },
    sagas: [UrlSagas_1.default],
});
exports.default = loadUrlModule;
