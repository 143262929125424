"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const HtmlInclude_1 = require("../html-include/HtmlInclude");
const CookiePreference = ({ preferenceData, logoUrl, isCookiePreferenceOpen, onCookiePreferenceClose, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const { handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({
        close: onCookiePreferenceClose,
    });
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    (0, react_1.useEffect)(() => {
        if (isCookiePreferenceOpen) {
            handlePopoverOpen(null, null, true, true);
        }
    }, [handlePopoverOpen, isCookiePreferenceOpen]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { multi: true, disableClickOutside: true, noAnimation: true, anchorEl: isCookiePreferenceOpen, type: cotopaxi_1.PopoverType.MODAL, position: cotopaxi_1.PopoverPosition.BOTTOM, onClose: handlePopoverClose, headerAlign: cotopaxi_1.PopoverHeaderAlign.CENTER, title: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: logoUrl, size: cotopaxi_1.ImageSize.COOKIE_BRAND_LOGO }), footerElement: isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: handlePopoverClose, text: t('cookie.consent.back.button'), iconLeft: {
                name: cotopaxi_1.IconName.CHEVRON_LEFT,
                size: cotopaxi_1.IconSize.MAJOR,
            } })), children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: isMobile && cotopaxi_1.ScrollableListVariant.SHADOW, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingBottom: 2,
                        },
                    }, tablet: {
                        spacing: {
                            paddingLeft: 4,
                            paddingRight: 4,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: preferenceData }) }) }), !isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingTop: 3,
                        paddingLeft: 4,
                        paddingBottom: 4,
                    },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('cookie.consent.back.button'), iconLeft: {
                        name: cotopaxi_1.IconName.CHEVRON_LEFT,
                        size: cotopaxi_1.IconSize.MAJOR,
                    }, onClick: handlePopoverClose }) }))] }));
};
exports.default = CookiePreference;
