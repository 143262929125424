"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareProductsBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../../constants/SliderConstants");
const CompareProductsBarSlide_1 = require("./CompareProductsBarSlide");
const CompareProductsBar = ({ comparisonProducts, addToBasket, getDeliveryPromise, deliveryPromiseExplanations, compareItemSelectSize, setSwiperInstance, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [compareProductsCarouselSwiper, setCompareProductsCarouselSwiper] = (0, react_1.useState)(null);
    const setSwiper = (swiper) => {
        setCompareProductsCarouselSwiper(swiper);
        setSwiperInstance(swiper);
    };
    (0, react_1.useEffect)(() => {
        if (compareProductsCarouselSwiper !== null) {
            compareProductsCarouselSwiper.update();
        }
    }, [comparisonProducts, compareProductsCarouselSwiper]);
    const compareProductsCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.PRODUCT_TILE_NORMAL,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: false,
        fullWidth: true,
        withDivider: isMobile,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            navigation: {
                prevEl: undefined,
                nextEl: undefined,
            },
            renderPrevButton: undefined,
            renderNextButton: undefined,
            getSwiper: setSwiper,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView: 'auto',
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.TABLET,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP_LARGE,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
            },
        },
    };
    const compareSlide = (0, react_1.useCallback)((product) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 4, desktop: 3, tablet: 4, tabletSmall: 4, children: (0, jsx_runtime_1.jsx)(CompareProductsBarSlide_1.CompareProductsBarSlide, { product: product, addToBasket: addToBasket, getDeliveryPromise: getDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, compareItemSelectSize: compareItemSelectSize }) }, `${product.productCode}${product.colourId}`)), [comparisonProducts, isMobile, isDesktop]);
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, compareProductsCarouselConfig, { children: comparisonProducts.map(compareSlide) }));
};
exports.CompareProductsBar = CompareProductsBar;
