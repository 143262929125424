"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_COMPARE_PRODUCTS = exports.MIN_COMPARE_PRODUCTS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ImageUtil_1 = require("../../../util/ImageUtil");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
exports.MIN_COMPARE_PRODUCTS = 1;
exports.MAX_COMPARE_PRODUCTS = 6;
const ComparePopup = ({ compareState: { items, collapsed, showError }, root, isStickyBar, updateCompareList, toggleComparePopup, openComparePage, toggleLimitReached, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const comparePopupTranslations = {
        title: t(`${i18nConstants_1.I18N_NAMESPACES.product}:comparePopup.header.title`),
    };
    const amount = items.length;
    const hasItems = amount > 0;
    const withShadow = amount > 3;
    const showFeedBack = amount === exports.MIN_COMPARE_PRODUCTS || amount === exports.MAX_COMPARE_PRODUCTS;
    const isDisabledBtn = amount === exports.MIN_COMPARE_PRODUCTS;
    const isOpen = !collapsed && hasItems;
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const getKeyForText = (0, react_1.useCallback)(() => {
        switch (amount) {
            case exports.MIN_COMPARE_PRODUCTS:
                return `${i18nConstants_1.I18N_NAMESPACES.product}:comparePopup.feedBack.oneMoreItem`;
            case exports.MAX_COMPARE_PRODUCTS:
                return `${i18nConstants_1.I18N_NAMESPACES.product}:comparePopup.feedBack.maxItems`;
            default:
                return '';
        }
    }, [amount]);
    const onClose = () => toggleLimitReached(false);
    const handleHeaderClick = () => {
        hasItems && toggleComparePopup();
        isOpen && onClose();
    };
    const handleCloseClick = (product) => () => {
        updateCompareList(product);
    };
    const [width, height] = common_types_1.ProductImageSizesPortraitEnum.bundles.split('x').map(Number);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Toast, { open: isMobile && showError, onClose: onClose, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.INFORMATION_OUTLINE, size: cotopaxi_1.IconSize.MEDIUM, inline: true }), ' ', t(`${i18nConstants_1.I18N_NAMESPACES.product}:comparePopup.feedBack.maxItems.toast`), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: `${root}products/product-compare.html`, children: t(`${i18nConstants_1.I18N_NAMESPACES.product}:comparePopup.feedBack.maxItems.toast.link`) })] }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.ComparePopup, { isOpen: isOpen, isShift: isStickyBar, count: amount, togglePopup: handleHeaderClick, translations: comparePopupTranslations, children: [showFeedBack && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { iconSize: cotopaxi_1.IconSize.MEDIUM, variant: cotopaxi_1.FeedbackMessageVariant.INFO, text: t(getKeyForText()) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { height: showFeedBack ? '362px' : '436px', variant: withShadow ? cotopaxi_1.ScrollableListVariant.SHADOW : undefined, children: items.map((item, index, arr) => {
                            const { productCode, brand, title, image, size, color, colorDescription, hasDiscount, discountPercentage } = item;
                            const textSize = `${t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.sizeSmall`)}: ${size}${size && colorDescription ? ', ' : ''}`;
                            const textColor = `${t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.color`)}: ${colorDescription}`;
                            const textDiscount = `${discountPercentage}% ${t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.discount`)}`;
                            return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { margin: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile, { variant: "horizontal", product: {
                                                brand: brand,
                                                title: title,
                                                image: (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, image, productImageConfiguration)[common_types_1.ProductImageSizesPortraitEnum.bundles],
                                            }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "primary", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Image, { width: width, height: height, containerOptions: { mobile: { width: 56 } } }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "secondary", containerOptions: { mobile: { spacing: { marginLeft: 2 } } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: 2, inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: 1, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Title, { brandOnTheSameLine: true }), (0, jsx_runtime_1.jsxs)("div", { children: [size && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: textSize })), colorDescription && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: textColor }))] }), hasDiscount && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.DISCOUNT, children: textDiscount }) }))] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { icon: {
                                                                        name: cotopaxi_1.IconName.CLOSE,
                                                                        size: cotopaxi_1.IconSize.MAJOR,
                                                                    }, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_SUBTLE, onClick: handleCloseClick(item) }) })] }) })] }) }), arr.length - 1 !== index && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { size: "calc(100% - 4rem)" })] }, `${productCode}${color}`));
                        }) }), !withShadow && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:productCompare.pdp.checkbox`), fill: true, onClick: openComparePage, href: !isDisabledBtn ? `${root}products/product-compare.html` : undefined, state: isDisabledBtn ? cotopaxi_1.ButtonState.DISABLED : cotopaxi_1.ButtonState.INTERACTIVE }) })] })] }));
};
exports.default = (0, react_1.memo)(ComparePopup);
