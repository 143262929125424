"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryConditionsBanner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const useCountdownTimer_1 = require("../../hooks/useCountdownTimer");
const asNumeral_1 = require("../../vendors/asNumeral");
const MESSAGE_AMOUNT = '{AMOUNT}';
const DeliveryConditionsBanner = ({ message, icon, link, cutoffTime, amountUntilFreeDelivery, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { countdownMessage } = (0, useCountdownTimer_1.useCountdownTimer)(cutoffTime, message);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: Boolean(link), wrapper: children => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: link, variant: cotopaxi_1.LinkVariant.SUBTLE_ALT, children: children })), children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: [icon && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: icon, inline: true }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, html: amountUntilFreeDelivery
                        ? countdownMessage === null || countdownMessage === void 0 ? void 0 : countdownMessage.replace(MESSAGE_AMOUNT, `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(amountUntilFreeDelivery)}`)
                        : countdownMessage })] }) }));
};
exports.DeliveryConditionsBanner = DeliveryConditionsBanner;
