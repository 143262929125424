"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const FormValidationUtil_1 = require("../../util/FormValidationUtil");
const NavigationUtil_1 = require("../../util/NavigationUtil");
const i18nConstants_1 = require("../../constants/i18nConstants");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const PasswordResetForm_1 = require("../../components/password-reset-form/PasswordResetForm");
const EVENT_PARAM_KEY = 'event';
const AccountPasswordResetContainer = ({ title }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const [customerUUID, setCustomerUUID] = (0, react_1.useState)('');
    const [isValidUUID, setIsValidUUID] = (0, react_1.useState)(false);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const [buttonState, setButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        var _a;
        const validateUUID = (uuid) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                yield CustomerApi_1.default.validatePasswordResetLink(servicesEndpoint, defaultRequestParams, uuid);
                setIsValidUUID(true);
                setShouldRender(true);
            }
            catch (_b) {
                setShouldRender(true);
            }
        });
        const uuid = (_a = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, EVENT_PARAM_KEY)) !== null && _a !== void 0 ? _a : '';
        setCustomerUUID(uuid);
        validateUUID(uuid);
    }, []);
    const handleValidationDefault = (values) => {
        const errors = {};
        if (!values.password) {
            errors.password = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
        }
        else if (values.password.length < common_types_1.DefaultSettings.MIN_LENGTH_PASSWORD) {
            errors.password = t(common_types_1.ErrorMessage.ACCOUNT_MIN_LENGTH);
        }
        if (!values.repeatPassword) {
            errors.repeatPassword = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
        }
        else if (values.password !== values.repeatPassword) {
            errors.repeatPassword = t(common_types_1.ErrorMessage.PASSWORD_MATCH);
        }
        return errors;
    };
    const handleValidationEmail = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(values.email)) {
            errors.email = t(common_types_1.ErrorMessage.INVALID_EMAIL);
        }
        return errors;
    };
    const handleSubmitDefault = (values) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setButtonState(cotopaxi_1.ButtonState.LOADING);
        try {
            yield CustomerApi_1.default.resetPassword(servicesEndpoint, defaultRequestParams, customerUUID, values.password);
            setButtonState(cotopaxi_1.ButtonState.FINISHED);
            if (errorFeedback) {
                setErrorFeedback('');
            }
            setTimeout(() => (window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root)), GeneralConstants_1.DELAY.DELAY_3000);
        }
        catch (_a) {
            setButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            setErrorFeedback(t('account:account.reset.password.error'));
        }
    });
    const handleSubmitEmail = (values) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setButtonState(cotopaxi_1.ButtonState.LOADING);
        try {
            yield CustomerApi_1.default.sendPasswordResetMail(servicesEndpoint, defaultRequestParams, values.email);
            setButtonState(cotopaxi_1.ButtonState.FINISHED);
            if (errorFeedback) {
                setErrorFeedback('');
            }
            setTimeout(() => (window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root)), GeneralConstants_1.DELAY.DELAY_3000);
        }
        catch (_b) {
            setButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            setErrorFeedback(t('navigation:flyout.account.error.operation.mail'));
        }
    });
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { withShadow: !isMobile, mobile: {
            spacing: {
                paddingTop: 3,
                paddingRight: 2,
                paddingBottom: 2,
                paddingLeft: 2,
                marginBottom: 5,
                marginTop: 3,
            },
            theme: cotopaxi_1.BoxTheme.PRIMARY,
        }, tablet: {
            spacing: {
                paddingTop: 5,
                paddingRight: 6,
                paddingBottom: 3,
                paddingLeft: 6,
                marginBottom: 6,
                marginTop: 3.5,
            },
        }, desktop: { spacing: { paddingBottom: 6, marginBottom: 7 } }, mobileBleed: true, children: [isValidUUID ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: title.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: title.value || t('account:account.password.passwordOne') }) })) })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t('account:account.reset.password.invalidtoken') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, size: cotopaxi_1.TextSize.SMALL, children: t('account:account.passwordForgottenText') }) })] })), (0, jsx_runtime_1.jsx)(PasswordResetForm_1.PasswordResetForm, { validate: isValidUUID ? handleValidationDefault : handleValidationEmail, onSubmit: isValidUUID ? handleSubmitDefault : handleSubmitEmail, view: isValidUUID ? PasswordResetForm_1.PasswordResetFormView.DEFAULT : PasswordResetForm_1.PasswordResetFormView.EMAIL, buttonState: buttonState, errorFeedback: errorFeedback })] }));
};
exports.default = AccountPasswordResetContainer;
