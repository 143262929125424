"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGiftCardConfig = exports.getReviewConfig = exports.getAlternateUrl = exports.getProductManualBaseUrl = exports.getVoucherSizeCode = exports.getReevooStarsEnabled = exports.getReevooShowBrand = exports.getReevooAccountCode = exports.getReevooEnabled = exports.getFeefoIdentifier = exports.getOneSizeProductCode = exports.getDpeApiConfig = exports.getDpeAuthConfig = exports.getDpeConfig = exports.getSizebayScript = exports.getSocialEnabledListConfig = exports.getApplePayConfig = exports.getSaferpayConfig = exports.getKlarnaConfig = exports.getFacebookConfig = exports.getGoogleMapsConfig = exports.getDefaultCountryCode = exports.getStoreCheckDefaultLocation = exports.getLang = exports.getLocale = exports.getElasticSearchIndexWithEnv = exports.getServicesApiPrefixAemServices = exports.getServicesEndpoint = exports.getCheckoutCc = exports.getShopNumber = exports.getShoplandingEnabledFlag = exports.getPageInfo = exports.getFictiveWebShop = exports.getMainWebShop = exports.getRootWithLanguageSuffix = exports.getRoot = exports.getBrandPagePathPrefix = exports.getCurrency = exports.getVideoOverlayPath = exports.getImageCdnPredix = exports.getProductImageCdnDomains = exports.getProductImages = exports.getBaseOverlayUrl = exports.getFeatureToggles = exports.getProductSwatchInformation = exports.getCanonicalUrl = exports.getSizeGuideConfig = exports.getDefaultRequestParameters = exports.getListerConfiguration = exports.getLocaleInformation = void 0;
exports.getCountryCodes = exports.getGlossaryPath = exports.getPrivacyPolicyLink = exports.getPDPTemplateName = exports.getMarket = exports.getCheckoutLoyaltyCardPrefix = exports.getBusinessUnitId = exports.getInvoiceHideCompanyName = exports.getPlaceholderVat = exports.getEnableFullVatForm = exports.getBuyDelivery = exports.getIsEditor = exports.getInAuthorMode = exports.getAuthenticationConfig = exports.getFredHopperSearchUrl = void 0;
const ImageUtil_1 = require("./ImageUtil");
const getLocaleInformation = (configuration) => ({
    mainWebShop: configuration.mainWebShop,
    fictiveWebShop: configuration.fictiveWebShop,
    market: configuration.market,
    lang: configuration.lang,
    locale: configuration.locale,
    currency: configuration.currency,
    rootWithLanguageSuffix: configuration.rootWithLanguageSuffix,
    analyticsLanguage: configuration.languageAnalytics,
    root: configuration.root,
});
exports.getLocaleInformation = getLocaleInformation;
const getListerConfiguration = (configuration) => ({
    isShopLanding: configuration.isShopLanding,
    sortConfiguration: {
        defaultValue: configuration.lister.sort.defaultValue,
        properties: configuration.lister.sort.properties,
    },
    productCdnDomains: configuration.product.images.cdnDomains,
    // ToDo: needs to be a field in AemConfiguration
    overlayBasePath: (0, ImageUtil_1.getOverlayImageBaseUrl)(configuration.mainWebShop, configuration.lang),
    reviewConfiguration: configuration.review,
    productImageMaxWidth: (0, ImageUtil_1.calculateListerImageMaxWidth)(configuration.product.images.cdnDomains.pathBaseTile),
});
exports.getListerConfiguration = getListerConfiguration;
const getDefaultRequestParameters = (configuration) => ({
    shopId: configuration.fictiveWebShop,
    market: configuration.market,
    mainWebshop: configuration.mainWebShop,
    language: configuration.lang,
    anaLang: configuration.languageAnalytics,
});
exports.getDefaultRequestParameters = getDefaultRequestParameters;
const getSizeGuideConfig = (configuration) => ({
    isSingleLanguageSite: configuration.isSingleLanguageSite,
    sizeGuidePath: configuration.sizeGuidePath,
    baseUrl: configuration.services.api,
});
exports.getSizeGuideConfig = getSizeGuideConfig;
const getCanonicalUrl = (configuration) => configuration.canonicalUrl;
exports.getCanonicalUrl = getCanonicalUrl;
const getProductSwatchInformation = (configuration) => configuration.product.swatch;
exports.getProductSwatchInformation = getProductSwatchInformation;
const getFeatureToggles = (configuration) => configuration.feature_toggles;
exports.getFeatureToggles = getFeatureToggles;
const getBaseOverlayUrl = (configuration) => (0, ImageUtil_1.getOverlayImageBaseUrl)(configuration.mainWebShop, configuration.lang);
exports.getBaseOverlayUrl = getBaseOverlayUrl;
const getProductImages = (configuration) => configuration.product.images;
exports.getProductImages = getProductImages;
const getProductImageCdnDomains = (configuration) => configuration.product.images.cdnDomains;
exports.getProductImageCdnDomains = getProductImageCdnDomains;
const getImageCdnPredix = (configuration) => configuration.imageCdnPrefix;
exports.getImageCdnPredix = getImageCdnPredix;
const getVideoOverlayPath = (configuration) => configuration.product.video.overlayLocation;
exports.getVideoOverlayPath = getVideoOverlayPath;
const getCurrency = (configuration) => configuration.currency;
exports.getCurrency = getCurrency;
const getBrandPagePathPrefix = (configuration) => configuration.brandPagePathPrefix;
exports.getBrandPagePathPrefix = getBrandPagePathPrefix;
const getRoot = (configuration) => configuration.root;
exports.getRoot = getRoot;
const getRootWithLanguageSuffix = (configuration) => configuration.rootWithLanguageSuffix;
exports.getRootWithLanguageSuffix = getRootWithLanguageSuffix;
const getMainWebShop = (configuration) => configuration.mainWebShop;
exports.getMainWebShop = getMainWebShop;
const getFictiveWebShop = (configuration) => configuration.fictiveWebShop;
exports.getFictiveWebShop = getFictiveWebShop;
const getPageInfo = (configuration) => configuration.pageInfo;
exports.getPageInfo = getPageInfo;
const getShoplandingEnabledFlag = (configuration) => configuration.isShopLanding;
exports.getShoplandingEnabledFlag = getShoplandingEnabledFlag;
const getShopNumber = (configuration) => configuration.shopNumber;
exports.getShopNumber = getShopNumber;
const getCheckoutCc = (configuration) => configuration.checkoutCc;
exports.getCheckoutCc = getCheckoutCc;
const getServicesEndpoint = (configuration) => configuration.services.api;
exports.getServicesEndpoint = getServicesEndpoint;
const getServicesApiPrefixAemServices = (configuration) => configuration.servicesApiPrefixAemServices;
exports.getServicesApiPrefixAemServices = getServicesApiPrefixAemServices;
const getElasticSearchIndexWithEnv = (configuration) => `${configuration.activeEnvironment}_${configuration.elasticsearchIndex || 'www-asadventure-com_nl'}`;
exports.getElasticSearchIndexWithEnv = getElasticSearchIndexWithEnv;
const getLocale = (configuration) => configuration.locale;
exports.getLocale = getLocale;
const getLang = (configuration) => configuration.lang;
exports.getLang = getLang;
const getStoreCheckDefaultLocation = (configuration) => configuration.storeCheckDefaultLocation;
exports.getStoreCheckDefaultLocation = getStoreCheckDefaultLocation;
const getDefaultCountryCode = (configuration) => configuration.defaultCountryCode;
exports.getDefaultCountryCode = getDefaultCountryCode;
const getGoogleMapsConfig = (configuration) => configuration.thirdparty.googleMaps;
exports.getGoogleMapsConfig = getGoogleMapsConfig;
const getFacebookConfig = (configuration) => configuration.thirdparty.facebook;
exports.getFacebookConfig = getFacebookConfig;
const getKlarnaConfig = (configuration) => configuration.thirdparty.klarna;
exports.getKlarnaConfig = getKlarnaConfig;
const getSaferpayConfig = (configuration) => configuration.thirdparty.saferpay;
exports.getSaferpayConfig = getSaferpayConfig;
const getApplePayConfig = (configuration) => configuration.thirdparty.applePay;
exports.getApplePayConfig = getApplePayConfig;
const getSocialEnabledListConfig = (configuration) => configuration.thirdparty.socialLogin.enabledList;
exports.getSocialEnabledListConfig = getSocialEnabledListConfig;
const getSizebayScript = (configuration) => configuration.sizebayScript;
exports.getSizebayScript = getSizebayScript;
const getDpeConfig = (configuration) => ({
    apiPrefix: configuration.servicesApiPrefixAemServices,
    dpePath: configuration.DPEPath,
});
exports.getDpeConfig = getDpeConfig;
const getDpeAuthConfig = (configuration) => ({
    apiPrefix: configuration.servicesApi,
    defaultRequestParameters: (0, exports.getDefaultRequestParameters)(configuration),
    lang: configuration.lang,
});
exports.getDpeAuthConfig = getDpeAuthConfig;
const getDpeApiConfig = (configuration) => ({
    apiPrefix: configuration.servicesApi,
    defaultRequestParameters: (0, exports.getDefaultRequestParameters)(configuration),
});
exports.getDpeApiConfig = getDpeApiConfig;
const getOneSizeProductCode = (configuration) => configuration.product.code.oneSize;
exports.getOneSizeProductCode = getOneSizeProductCode;
const getFeefoIdentifier = (configuration) => configuration.review.feefoIdentifier;
exports.getFeefoIdentifier = getFeefoIdentifier;
const getReevooEnabled = (configuration) => configuration.review.reevoo;
exports.getReevooEnabled = getReevooEnabled;
const getReevooAccountCode = (configuration) => configuration.review.reevooAccountCode;
exports.getReevooAccountCode = getReevooAccountCode;
const getReevooShowBrand = (configuration) => configuration.review.reevooShowBrand;
exports.getReevooShowBrand = getReevooShowBrand;
const getReevooStarsEnabled = (configuration) => configuration.review.reevooStarsEnabled;
exports.getReevooStarsEnabled = getReevooStarsEnabled;
const getVoucherSizeCode = (configuration) => configuration.product.voucher.productSize;
exports.getVoucherSizeCode = getVoucherSizeCode;
const getProductManualBaseUrl = (configuration) => configuration.product.manual.cdnDomain + configuration.product.manual.location;
exports.getProductManualBaseUrl = getProductManualBaseUrl;
const getAlternateUrl = (configuration) => configuration.alternateUrl;
exports.getAlternateUrl = getAlternateUrl;
const getReviewConfig = (configuration) => configuration.review;
exports.getReviewConfig = getReviewConfig;
const getGiftCardConfig = (configuration) => configuration.giftCard;
exports.getGiftCardConfig = getGiftCardConfig;
const getFredHopperSearchUrl = (configuration) => configuration.services.fredHopper.search;
exports.getFredHopperSearchUrl = getFredHopperSearchUrl;
const getAuthenticationConfig = (configuration) => ({
    anaLang: configuration.languageAnalytics,
    fictiveWebShop: configuration.fictiveWebShop,
    locale: configuration.lang,
    mainWebshop: configuration.mainWebShop,
    market: configuration.market,
});
exports.getAuthenticationConfig = getAuthenticationConfig;
const getInAuthorMode = (configuration) => configuration.isInAuthorMode;
exports.getInAuthorMode = getInAuthorMode;
const getIsEditor = (configuration) => configuration.isEditor;
exports.getIsEditor = getIsEditor;
const getBuyDelivery = (configuration) => configuration.buy.delivery;
exports.getBuyDelivery = getBuyDelivery;
const getEnableFullVatForm = (configuration) => configuration.enableFullVatForm;
exports.getEnableFullVatForm = getEnableFullVatForm;
const getPlaceholderVat = (configuration) => configuration.placeholders.vat;
exports.getPlaceholderVat = getPlaceholderVat;
const getInvoiceHideCompanyName = (configuration) => configuration.invoiceHideCompany;
exports.getInvoiceHideCompanyName = getInvoiceHideCompanyName;
const getBusinessUnitId = (configuration) => configuration.businessUnitId;
exports.getBusinessUnitId = getBusinessUnitId;
const getCheckoutLoyaltyCardPrefix = (configuration) => configuration.checkoutLoyaltyCardPrefix;
exports.getCheckoutLoyaltyCardPrefix = getCheckoutLoyaltyCardPrefix;
const getMarket = (configuration) => configuration.market;
exports.getMarket = getMarket;
const getPDPTemplateName = (configuration) => configuration.pageInfo.pdpTemplateName;
exports.getPDPTemplateName = getPDPTemplateName;
const getPrivacyPolicyLink = (configuration) => configuration.privacyPolicyLink;
exports.getPrivacyPolicyLink = getPrivacyPolicyLink;
const getGlossaryPath = (configuration) => configuration.glossaryPath;
exports.getGlossaryPath = getGlossaryPath;
const getCountryCodes = (configuration) => configuration.countryCodes;
exports.getCountryCodes = getCountryCodes;
