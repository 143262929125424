"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationCountrySelector = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const formik_1 = require("formik");
const react_1 = require("react");
const RegistrationField_1 = require("./RegistrationField");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const CountryUtil_1 = require("../../../util/CountryUtil");
const countryFieldName = 'address.idCountry';
const RegistrationCountrySelector = ({ setActiveCountry, }) => {
    const [countries, setCountries] = (0, react_1.useState)([]);
    const [customerCountries, setCustomerCountries] = (0, react_1.useState)([]);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const fictiveWebshop = (0, ConfigurationUtil_1.getFictiveWebShop)(aemConfiguration);
    const defaultCountryCode = (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebshop);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const { errors, touched, values, setFieldValue } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        const getCountriesFromApi = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield CustomerApi_1.default.getCountries(servicesEndpoint, defaultRequestParameters);
                const mappedCountries = (0, common_utils_1.sortAlphabetically)(response.map(country => ({
                    value: country.idCountry,
                    label: country.countryDescriptionTranslations[lang.toUpperCase()],
                })), 'label');
                if (isMounted()) {
                    setCountries(response);
                    setCustomerCountries(mappedCountries);
                }
            }
            catch (_a) { }
        });
        getCountriesFromApi();
    }, [isMounted]);
    (0, react_1.useEffect)(() => {
        const countryValue = (0, formik_1.getIn)(values, countryFieldName);
        if (!defaultCountryCode || !!countryValue || !customerCountries) {
            return;
        }
        const countryByCode = countries.find(country => country.countryCode === defaultCountryCode.toUpperCase());
        setFieldValue(countryFieldName, countryByCode === null || countryByCode === void 0 ? void 0 : countryByCode.idCountry);
    }, [values, countries, customerCountries, defaultCountryCode]);
    (0, react_1.useEffect)(() => {
        const countryValue = (0, formik_1.getIn)(values, countryFieldName);
        if (!countryValue || !countries) {
            return;
        }
        const countryById = countries.find(country => country.idCountry === parseInt(countryValue, 10));
        countryById && setActiveCountry(countryById);
    }, [values, countries]);
    return ((0, jsx_runtime_1.jsx)(RegistrationField_1.RegistrationField, { label: t('account.details.country'), placeholder: t('account.details.country.placeholder'), name: countryFieldName, id: "country", state: errors.country && touched.country && cotopaxi_1.InputState.ERROR, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select, fullWidth: true, options: customerCountries, dataQA: "country" }));
};
exports.RegistrationCountrySelector = RegistrationCountrySelector;
