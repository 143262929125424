"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnItemsList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const dayjs = require("dayjs");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const SaleLineRow_1 = require("./SaleLineRow");
const AccountConstant_1 = require("../../constants/AccountConstant");
const FormikUtil_1 = require("../../util/FormikUtil");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const ReturnItemsList = ({ returnItems, returnReasons }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { values, errors, submitCount, isValid, isSubmitting } = (0, formik_1.useFormikContext)();
    const hasOneSaleSelected = (0, react_1.useMemo)(() => values.saleLines.some(item => item.isSelected), [values]);
    (0, react_1.useEffect)(() => {
        const firstErrorPath = (0, FormikUtil_1.getFirstErrorPath)(errors);
        if (submitCount > 0 && !isValid && !isSubmitting && firstErrorPath) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[name="${firstErrorPath}"]`));
        }
    }, [submitCount, isSubmitting, errors]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [returnItems.map((returnItem, returnItemIndex) => {
                const saleDate = dayjs(returnItem.saleDate);
                const lastPossibleReturnDate = dayjs(returnItem.lastPossibleReturnDate);
                const countdown = saleDate.diff(lastPossibleReturnDate, 'day');
                return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: returnItemIndex === 0 ? 0 : 1 } }, className: `${AccountConstant_1.RETURN_ITEM_REDIRECT_PREFIX}${returnItem.orderId}`, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    marginBottom: 1,
                                },
                                theme: cotopaxi_1.BoxTheme.PRIMARY,
                            }, tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, desktop: { spacing: { paddingLeft: 1, paddingRight: 2 } }, mobileBleed: true, tabletBleed: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [`${dayjs(returnItem.saleDate).format(common_types_1.DateFormat.DD_MM_YYYY_DASH)} `, (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, dataQA: "order_number", size: cotopaxi_1.TextSize.INHERIT, children: [t('account.order.list.header.order.number'), " #", returnItem.orderId] }), returnItem.shopName && ` - ${returnItem.shopName}`] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: t('returnItems.possibleDaysCountdown', {
                                                count: countdown,
                                            }) }) })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.MOBILE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: t('returnItems.possibleDaysCountdown', {
                                        count: countdown,
                                    }) }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsx)(formik_1.FieldArray, { name: `returnItems.${returnItemIndex}.saleLines`, children: () => ((0, jsx_runtime_1.jsx)("div", { children: returnItem.saleLines.map((saleLine, rowIndex) => {
                                        const saleLineIndex = values.saleLines.findIndex(tmpSaleLine => tmpSaleLine.saleLineId === saleLine.saleLineId);
                                        const saleLineFieldPrefix = `saleLines.${saleLineIndex}`;
                                        return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SaleLineRow_1.SaleLineRow, { saleLine: saleLine, fieldPrefix: saleLineFieldPrefix, returnReasons: returnReasons }), rowIndex + 1 < returnItem.saleLines.length && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.NONE })] }, `${saleLine.saleLineId}-${rowIndex}`));
                                    }) })) }) })] }, `${returnItem.orderId}-${returnItemIndex}`));
            }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4 } }, tablet: { spacing: { marginTop: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "continue_button", text: t('returnItems.form.continue'), fill: isMobile, iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MEDIUM }, state: hasOneSaleSelected ? undefined : cotopaxi_1.ButtonState.DISABLED, type: cotopaxi_1.ButtonType.SUBMIT }) })] }));
};
exports.ReturnItemsList = ReturnItemsList;
