"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightBoxThumbSlider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const LightBoxThumbSlider = ({ activeSlideIndex, alt, mediaCollection, onActiveSlideIndexChange, }) => {
    const [gallerySwiper, setGallerySwiper] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (gallerySwiper !== null && !!activeSlideIndex) {
            const activeSlide = gallerySwiper.slides[activeSlideIndex];
            if ((activeSlide === null || activeSlide === void 0 ? void 0 : activeSlide.className.indexOf('swiper-slide-visible')) < 0) {
                gallerySwiper.slideTo(activeSlideIndex);
            }
        }
    }, [activeSlideIndex]);
    (0, react_1.useEffect)(() => {
        if (gallerySwiper !== null) {
            gallerySwiper.update();
        }
    }, [mediaCollection]);
    const onThumbClick = function (index) {
        if (index !== activeSlideIndex) {
            onActiveSlideIndexChange(index);
        }
    };
    const renderSlide = (0, react_1.useCallback)((image, index) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    (0, jsx_runtime_1.jsx)("div", { onClick: () => onThumbClick(index), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.LightBoxThumbSlide, { active: activeSlideIndex === index, isVideo: image.isVideo, imagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.thumb], alt: alt }) }, `image_${index}`)), [activeSlideIndex]);
    const lightBoxThumbSliderCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.LIGHT_BOX_THUMB,
        configurationOverrides: {
            initialSlide: activeSlideIndex,
            getSwiper: setGallerySwiper,
            autoHeight: true,
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 24,
        },
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, lightBoxThumbSliderCarouselConfig, { dataQA: "light_box_thumb_slider", children: mediaCollection.map((image, index) => renderSlide(image, index)) })));
};
exports.LightBoxThumbSlider = LightBoxThumbSlider;
