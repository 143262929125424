"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const EventUtil_1 = require("../util/EventUtil");
const EventEnum_1 = require("../enums/EventEnum");
const DeliveryConditionsBanner_1 = require("../components/delivery-conditions-banner/DeliveryConditionsBanner");
const DeliveryConstants_1 = require("../constants/DeliveryConstants");
dayjs.extend(utc);
const getDefaultCutOffUTCTime = (date, exceptions, days) => {
    const today = date.format('YYYY-MM-DD');
    const isExceptionalDay = exceptions === null || exceptions === void 0 ? void 0 : exceptions.find(exception => exception.date === today);
    if (isExceptionalDay) {
        return isExceptionalDay.cutOffUTC;
    }
    const todaysDayOfWeek = date.day();
    return days.find(day => common_types_1.deliveryDay[day.day] === todaysDayOfWeek).cutOffUTC;
};
const isNextDayDeliveryEligible = (messages) => {
    return messages && Object.keys(messages).some(key => DeliveryConstants_1.ENABLED_DELIVERY_PROMISES.includes(key));
};
const DeliveryConditionsBannerContainer = ({ basketNddEligibleAbove, basketNddEligibleBelow, basketNotNddEligibleAbove, basketNotNddEligibleBelow, default: defaultState, emptyBasketAfterCutoff, emptyBasketBeforeCutoff, nextDayDeliveryInfo: { days, exceptions, thresholdFreeDelivery }, }) => {
    const productAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const deliveryInfo = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDeliveryInfoState)());
    const [condition, setCondition] = (0, react_1.useState)();
    const [basketLoaded, setBasketLoaded] = (0, react_1.useState)(false);
    const now = dayjs.utc();
    const cutoffTime = (deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.deliveryPromiseCutoff) || getDefaultCutOffUTCTime(now, exceptions, days);
    const amountUntilFreeDelivery = deliveryInfo.hasDeliveryMessage && deliveryInfo.deliveryMessages && deliveryInfo.amountUntilFreeDelivery
        ? deliveryInfo.amountUntilFreeDelivery
        : thresholdFreeDelivery;
    EventUtil_1.default.listen(EventEnum_1.EventEnum.BASKET_SET, () => {
        setBasketLoaded(true);
    });
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (!basketLoaded || !cutoffTime)
            return;
        const deadlineHour = parseInt((_a = cutoffTime.split(':')) === null || _a === void 0 ? void 0 : _a[0], 10);
        const deadlineMinute = parseInt((_b = cutoffTime.split(':')) === null || _b === void 0 ? void 0 : _b[1], 10);
        const deadline = now.set('hour', deadlineHour).set('minute', deadlineMinute).set('second', 0);
        const hasFreeDelivery = amountUntilFreeDelivery <= 0;
        setCondition(() => {
            let tmpState = basketNotNddEligibleBelow;
            if (productAmount === 0) {
                tmpState = now.isAfter(deadline) ? emptyBasketAfterCutoff : emptyBasketBeforeCutoff;
            }
            else if (isNextDayDeliveryEligible(deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.deliveryMessages)) {
                tmpState = hasFreeDelivery ? basketNddEligibleAbove : basketNddEligibleBelow;
            }
            else if (hasFreeDelivery) {
                tmpState = basketNotNddEligibleAbove;
            }
            return (tmpState === null || tmpState === void 0 ? void 0 : tmpState.message) ? tmpState : defaultState;
        });
    }, [basketLoaded, amountUntilFreeDelivery, deliveryInfo.deliveryMessages]);
    if (!condition || !condition.message)
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1, paddingBottom: 1 } }, children: [(condition === emptyBasketAfterCutoff || condition === basketNotNddEligibleBelow) && ((0, jsx_runtime_1.jsx)(DeliveryConditionsBanner_1.DeliveryConditionsBanner, { message: condition.message, icon: condition.icon, link: condition.link, amountUntilFreeDelivery: amountUntilFreeDelivery })), (condition === emptyBasketBeforeCutoff || condition === basketNddEligibleBelow) && ((0, jsx_runtime_1.jsx)(DeliveryConditionsBanner_1.DeliveryConditionsBanner, { message: condition.message, cutoffTime: cutoffTime, icon: condition.icon, link: condition.link, amountUntilFreeDelivery: amountUntilFreeDelivery })), condition === basketNddEligibleAbove && ((0, jsx_runtime_1.jsx)(DeliveryConditionsBanner_1.DeliveryConditionsBanner, { message: condition.message, cutoffTime: cutoffTime, icon: condition.icon, link: condition.link })), (condition === basketNotNddEligibleAbove || condition === defaultState) && ((0, jsx_runtime_1.jsx)(DeliveryConditionsBanner_1.DeliveryConditionsBanner, { message: condition.message, icon: condition.icon, link: condition.link }))] }));
};
exports.default = DeliveryConditionsBannerContainer;
