"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductReducer_1 = require("../../product/reducers/ProductReducer");
const ProductSagas_1 = require("../../product/sagas/ProductSagas");
const loadProductModule = (initialData) => ({
    id: 'Product',
    reducerMap: {
        product: (0, ProductReducer_1.loadProductReducer)(initialData ? initialData.product : null),
    },
    sagas: [ProductSagas_1.default],
});
exports.default = loadProductModule;
