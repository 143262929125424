"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailChangeHandler = void 0;
const react_1 = require("react");
const formik_1 = require("formik");
const EmailChangeHandler = ({ emailFieldKey, emailValue, skipEmailValidation, onEmailChange, setEmailStatus, }) => {
    const { errors, values, setFieldValue, setFieldError } = (0, formik_1.useFormikContext)();
    const currentEmailValue = (0, formik_1.getIn)(values, emailFieldKey);
    const currentEmailError = (0, formik_1.getIn)(errors, emailFieldKey);
    (0, react_1.useEffect)(() => {
        currentEmailValue !== emailValue && setFieldValue(emailFieldKey, emailValue);
    }, [emailValue]);
    (0, react_1.useEffect)(() => {
        onEmailChange && onEmailChange(currentEmailValue);
    }, [currentEmailValue]);
    (0, react_1.useEffect)(() => {
        if (skipEmailValidation) {
            setFieldError(emailFieldKey, undefined);
        }
        else {
            setFieldValue(emailFieldKey, currentEmailValue); // set value to trigger validation
        }
    }, [skipEmailValidation]);
    (0, react_1.useEffect)(() => {
        if (currentEmailError && setEmailStatus) {
            setEmailStatus(currentEmailError);
        }
    }, [currentEmailError]);
    return null;
};
exports.EmailChangeHandler = EmailChangeHandler;
