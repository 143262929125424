"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const awesome_debounce_promise_1 = require("awesome-debounce-promise");
const Yup = require("yup");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const EmailValidationUtil_1 = require("../../email/EmailValidationUtil");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const EmailChangeHandler_1 = require("../../email/EmailChangeHandler");
const EmailError_1 = require("../../email/EmailError");
const ShopLoginForm_1 = require("./shop-login-form/ShopLoginForm");
const common_types_1 = require("@as-react/common-types");
const defaultCardInfo = {
    customerUUID: null,
    email: null,
    validEmail: false,
    existingCustomer: false,
};
const ShopLoginForm = ({ onSubmit, errorFeedback }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const checkoutLoyaltyCardPrefix = (0, ConfigurationUtil_1.getCheckoutLoyaltyCardPrefix)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.account]);
    const [customerCardKnown, setCustomerCardKnown] = (0, react_1.useState)(false);
    const [customerCardInfo, setCustomerCardInfo] = (0, react_1.useState)(defaultCardInfo);
    const [linkedEmail, setLinkedEmail] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [emailOrCardCodeFieldValue, setEmailOrCardCodeFieldValue] = (0, react_1.useState)();
    const [emailOrCardCodeStatus, setEmailOrCardCodeStatus] = (0, react_1.useState)();
    const [linkedEmailStatus, setLinkedEmailStatus] = (0, react_1.useState)();
    const [linkedEmailFieldValue, setLinkedEmailFieldValue] = (0, react_1.useState)();
    const [suggestedEmail, setSuggestedEmail] = (0, react_1.useState)();
    const [skipEmailValidation, setSkipEmailValidation] = (0, react_1.useState)(false);
    const getCustomerInfoForExploreMoreCard = (customerCardNumber) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield CustomerApi_1.default.checkCardNumber(servicesEndpoint, defaultRequestParams, customerCardNumber);
            return result;
        }
        catch (_a) {
            return defaultCardInfo;
        }
    });
    const getEmailStatus = (email) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield CustomerApi_1.default.emailExists(servicesEndpoint, defaultRequestParams, email, false);
            return result;
        }
        catch (_b) {
            return { accountStatusCode: common_types_1.AuthStatusCode.NO_ACCOUNT, knownForFascia: false, hasIdentity: false };
        }
    });
    const validateEmailOrCardCode = (0, react_1.useCallback)((0, awesome_debounce_promise_1.default)((emailOrCardCode, context) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (skipEmailValidation) {
            return true;
        }
        if (emailOrCardCode) {
            const { isValidFormat, isDefaultCardNumber } = FormValidationUtil_1.default.isValidExploreMoreCardFormat(emailOrCardCode, checkoutLoyaltyCardPrefix);
            if (isValidFormat && isDefaultCardNumber) {
                return context.createError({
                    message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.error.loyalty.card.default`),
                });
            }
            else if (isValidFormat && !isDefaultCardNumber) {
                const cardInfo = yield getCustomerInfoForExploreMoreCard(emailOrCardCode);
                setCustomerCardInfo(cardInfo);
                setCustomerCardKnown(cardInfo.existingCustomer);
                if (!cardInfo.existingCustomer) {
                    return context.createError({
                        message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.error.loyalty.card`),
                    });
                }
                else if (cardInfo.validEmail) {
                    setLinkedEmail((_c = cardInfo.email) !== null && _c !== void 0 ? _c : undefined);
                }
                else if (!cardInfo.validEmail) {
                    setLinkedEmail('');
                }
            }
            else if (!isValidFormat) {
                if (!FormValidationUtil_1.default.isValidEmailPattern(emailOrCardCode)) {
                    return context.createError({ message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.error.invalid`) });
                }
                else {
                    return yield (0, EmailValidationUtil_1.validateEmailWithBackend)(emailOrCardCode, context, servicesEndpoint, emailOrCardCodeStatus, setSuggestedEmail, setEmailOrCardCodeStatus);
                }
            }
        }
        else if (!emailOrCardCode) {
            setCustomerCardKnown(false);
            return context.createError({ message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.error.required`) });
        }
        return true;
    }), EmailValidationUtil_1.EMAIL_VALIDATION_DEBOUNCE), [customerCardKnown, skipEmailValidation, emailOrCardCodeStatus]);
    const validateLinkedEmail = (0, react_1.useCallback)((0, awesome_debounce_promise_1.default)((tmpLinkedEmail, context) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (skipEmailValidation) {
            return true;
        }
        if (!tmpLinkedEmail) {
            return context.createError({ message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.email.error.required`) });
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(tmpLinkedEmail)) {
            return context.createError({
                message: common_types_1.EmailValidationResponseStatus.INVALID,
            });
        }
        else if (linkedEmail !== tmpLinkedEmail) {
            const { hasIdentity } = yield getEmailStatus(tmpLinkedEmail);
            if (hasIdentity) {
                return context.createError({
                    message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.error.known.user`),
                });
            }
            return yield (0, EmailValidationUtil_1.validateEmailWithBackend)(tmpLinkedEmail, context, servicesEndpoint, linkedEmailStatus, setSuggestedEmail, setLinkedEmailStatus);
        }
        return true;
    }), EmailValidationUtil_1.EMAIL_VALIDATION_DEBOUNCE), [customerCardKnown, skipEmailValidation, linkedEmail]);
    const emailValidationSchema = Yup.object().shape({
        emailOrCardCode: Yup.string().test(validateEmailOrCardCode),
        linkedEmail: customerCardKnown ? Yup.string().test(validateLinkedEmail) : Yup.string().optional(),
    });
    const onEmailOrCardCodeChange = (email) => {
        setEmailOrCardCodeFieldValue(email);
        skipEmailValidation && setSkipEmailValidation(false);
        linkedEmail && setLinkedEmail('');
        customerCardInfo && setCustomerCardInfo(defaultCardInfo);
        customerCardKnown && setCustomerCardKnown(false);
    };
    const onLinkedEmailChange = (email) => {
        setLinkedEmailFieldValue(email);
        skipEmailValidation && setSkipEmailValidation(false);
    };
    const onIgnoreSuggestedEmailClick = () => {
        setSkipEmailValidation(true);
    };
    const handleAcceptClick = () => {
        if (customerCardKnown) {
            setLinkedEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
            setLinkedEmailFieldValue(suggestedEmail);
        }
        else {
            setEmailOrCardCodeStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
            setEmailOrCardCodeFieldValue(suggestedEmail);
        }
    };
    const handleShopLoginSubmit = (values) => {
        var _a;
        setIsLoading(true);
        if (customerCardKnown) {
            onSubmit(values.linkedEmail, values.emailOrCardCode, (_a = customerCardInfo.customerUUID) !== null && _a !== void 0 ? _a : undefined);
        }
        else {
            onSubmit(values.emailOrCardCode);
        }
    };
    (0, react_1.useEffect)(() => {
        if (errorFeedback && isLoading) {
            setIsLoading(false);
        }
    }, [errorFeedback]);
    (0, react_1.useEffect)(() => {
        setLinkedEmailFieldValue(linkedEmail);
    }, [linkedEmail]);
    return ((0, jsx_runtime_1.jsxs)(ShopLoginForm_1.ShopLoginForm, { errorFeedback: errorFeedback, onSubmit: handleShopLoginSubmit, isKnownCustomerCard: customerCardKnown, validationSchema: emailValidationSchema, loading: isLoading, emailErrorRender: msg => ((0, jsx_runtime_1.jsx)(EmailError_1.EmailError, { emailStatus: msg, suggestedEmail: suggestedEmail, requiredErrorLabel: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.email.error.required`), onAcceptClick: handleAcceptClick, onIgnoreClick: onIgnoreSuggestedEmailClick })), children: [(0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "emailOrCardCode", emailValue: emailOrCardCodeFieldValue, skipEmailValidation: skipEmailValidation, onEmailChange: onEmailOrCardCodeChange, setEmailStatus: setEmailOrCardCodeStatus }), (0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "linkedEmail", emailValue: linkedEmailFieldValue, skipEmailValidation: skipEmailValidation, onEmailChange: onLinkedEmailChange, setEmailStatus: setLinkedEmailStatus })] }));
};
exports.default = ShopLoginForm;
