"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const Video_1 = require("../../../video/Video");
const VideoUtil_1 = require("../../../../util/VideoUtil");
const LabelsOverlay_1 = require("../../../common/labels-overlay/LabelsOverlay");
const useSlidesDisplayState_1 = require("../../../../hooks/useSlidesDisplayState");
const ProductPreviewMainSlider = ({ activeSlideIndex, alt, genderCode, mediaCollection, onActiveSlideIndexChange, priceOverlay, savingsPercentage, showGenderIcon, showOnlineExclusiveLabel, showSustainabilityLabel, showNewProductLabel, popoverHandlers, }) => {
    var _a;
    const [gallerySwiper, setGallerySwiper] = (0, react_1.useState)(null);
    const { handlePopoverOpen } = popoverHandlers;
    const slidesDisplayState = (0, useSlidesDisplayState_1.useSlidesDisplayState)(activeSlideIndex, mediaCollection.length, (_a = mediaCollection === null || mediaCollection === void 0 ? void 0 : mediaCollection[activeSlideIndex + 1]) === null || _a === void 0 ? void 0 : _a.isVideo);
    const onGallerySlideChange = function () {
        if (this.activeIndex === 0 || this.activeIndex !== activeSlideIndex) {
            onActiveSlideIndexChange(this.activeIndex);
        }
    };
    const renderSlide = (0, react_1.useCallback)((image, index, altCopy) => ((0, jsx_runtime_1.jsx)("div", { children: slidesDisplayState[index] && ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPreviewMainSlide, { smallImagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.detail], largeImagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.zoom], videoComponent: image.video && ((0, jsx_runtime_1.jsx)(Video_1.Video, { url: image.video.productVideoDetail.url, playerType: (0, VideoUtil_1.getVideoPlayerType)(image.video.productVideoDetail.url), stop: index !== activeSlideIndex })), alt: altCopy, onClick: !image.isVideo ? handlePopoverOpen : null })) }, `image_${index}`)), [activeSlideIndex, slidesDisplayState]);
    const productMainSliderCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.MAIN,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.SEMICIRCLE,
        },
        showPagination: true,
        configurationOverrides: {
            shouldSwiperUpdate: true,
            getSwiper: setGallerySwiper,
            spaceBetween: 4,
            centeredSlides: true,
            watchOverflow: true,
            on: {
                slideChange: onGallerySlideChange,
            },
        },
    };
    const renderMainCarouselOverlay = ((0, jsx_runtime_1.jsx)(LabelsOverlay_1.default, { showGenderIcon: showGenderIcon, genderFeatureId: genderCode, savingsPercentage: savingsPercentage, priceOverlay: priceOverlay, showNewProductLabel: showNewProductLabel, showOnlineExclusiveLabel: showOnlineExclusiveLabel, showSustainabilityLabel: showSustainabilityLabel, hasWideGutter: true, dataQA: "product_image_overlay" }));
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.slideTo(activeSlideIndex);
    }, [activeSlideIndex]);
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.update();
    }, [mediaCollection]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, productMainSliderCarouselConfig, { dataQA: "product_main_slider", overlay: renderMainCarouselOverlay, children: mediaCollection.map((image, index) => renderSlide(image, index, alt)) })));
};
exports.default = ProductPreviewMainSlider;
