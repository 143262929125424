"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUKAddressList = void 0;
const tslib_1 = require("tslib");
const awesome_debounce_promise_1 = require("awesome-debounce-promise");
const GeneralAPI_1 = require("../../../api/general/GeneralAPI");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const ADDRESS_FINDER_MINIMUM_TERM_LENGTH = 3;
const ADDRESS_RESPONSE_SIZE = 100;
const getAddressUkListDebounced = (0, awesome_debounce_promise_1.default)((servicesEndpoint, defaultRequestParams, finder) => GeneralAPI_1.default.getAddressUkList(servicesEndpoint, defaultRequestParams, finder, ADDRESS_RESPONSE_SIZE), GeneralConstants_1.DELAY.DELAY_300);
const getUKAddressList = (finder, country, servicesEndpoint, defaultRequestParams) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    if ((finder === null || finder === void 0 ? void 0 : finder.length) >= ADDRESS_FINDER_MINIMUM_TERM_LENGTH && (0, BuyFormUtil_1.isUkCountry)(country)) {
        try {
            const data = yield getAddressUkListDebounced(servicesEndpoint, defaultRequestParams, finder);
            return data.map(({ moniker, partialAddress }) => ({
                id: moniker,
                text: partialAddress,
            }));
        }
        catch (_a) { }
    }
    return [];
});
exports.getUKAddressList = getUKAddressList;
