"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const BrandListerActions_1 = require("../redux/brandLister/actions/BrandListerActions");
const BrandListerSelectors_1 = require("../redux/brandLister/selectors/BrandListerSelectors");
const BrandListerFilters_1 = require("../components/brand-lister/brand-lister-filters/BrandListerFilters");
const BrandLister_1 = require("../components/brand-lister/brand-lister/BrandLister");
const BrandListerContainer = props => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const items = (0, react_redux_1.useSelector)((0, BrandListerSelectors_1.makeGetBrandListerItems)());
    const filters = (0, react_redux_1.useSelector)((0, BrandListerSelectors_1.makeGetBrandListerFilters)());
    const handleClick = (filter) => {
        dispatch(BrandListerActions_1.brandListerActions.createSetFilterAction(filter));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BrandListerFilters_1.BrandListerFilters, { onClick: handleClick, filters: filters }), (0, jsx_runtime_1.jsx)(BrandLister_1.BrandLister, { items: items })] }));
};
exports.default = BrandListerContainer;
