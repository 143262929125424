"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowBanner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const client_side_renderer_1 = require("@as-react/client-side-renderer");
const common_utils_1 = require("@as-react/common-utils");
const index_1 = require("../../../index");
const logger_1 = require("@as-react/logger");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const RowBanner = ({ content }) => {
    const containerEl = (0, react_1.useRef)(null);
    const { i18n } = (0, react_i18next_1.useTranslation)();
    (0, react_1.useEffect)(() => {
        (0, client_side_renderer_1.renderReactComponentsInsideElementsIfNeeded)(index_1.availableComponents, containerEl.current, index_1.renderingTools, i18n).catch(logger_1.Logger.logError);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { ref: containerEl, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: (0, common_utils_1.unescapeHTML)(content) }) }));
};
exports.RowBanner = RowBanner;
