"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basketActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.basketActions = {
    createAddAction(amount, location, pdpVersion) {
        const payload = {
            amount,
            location,
            pdpVersion,
        };
        return { type: ActionEnums_1.BasketActionTypes.ADD, payload };
    },
    createAddBundleAction: (location, price) => {
        const payload = {
            location,
            price,
        };
        return { type: ActionEnums_1.BasketActionTypes.ADD_BUNDLE, payload };
    },
    createAddVoucherAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ADD_VOUCHER, payload };
    },
    createAddGiftCardAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ADD_GIFT_CARD, payload };
    },
    createAddPromotionAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ADD_PROMOTION, payload };
    },
    createAddSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.ADD_SUCCESS,
            payload,
        };
    },
    createAddBundleSuccessAction(products, location) {
        return {
            type: ActionEnums_1.BasketActionTypes.ADD_BUNDLE_SUCCESS,
            payload: {
                products,
                location,
            },
        };
    },
    createBasicAddAction(amount, location, updateBasket) {
        const payload = {
            amount,
            location,
            updateBasket,
        };
        return { type: ActionEnums_1.BasketActionTypes.BASIC_ADD, payload };
    },
    createAddExtensiveTileProductAction(productData, location, analyticsData) {
        return {
            type: ActionEnums_1.BasketActionTypes.ADD_EXTENSIVE_TILE_PRODUCT,
            payload: { productData, location, analyticsData },
        };
    },
    createGetAction() {
        return { type: ActionEnums_1.BasketActionTypes.GET };
    },
    createGetBasicAction() {
        return { type: ActionEnums_1.BasketActionTypes.GET_BASIC };
    },
    createAdobeAnalyticsSuccessAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ADOBE_ANALYTICS_SUCCESS, payload };
    },
    createAdobeAnalyticsTokenSetAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ADOBE_ANALYTICS_TOKEN_SET, payload };
    },
    createCCPopupOpenAction() {
        return { type: ActionEnums_1.BasketActionTypes.CC_POPUP_OPEN };
    },
    createCCPopupCloseAction() {
        return { type: ActionEnums_1.BasketActionTypes.CC_POPUP_CLOSE };
    },
    createErrorAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.ERROR_SET, payload };
    },
    createGoogleAnalyticsSuccessAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.GOOGLE_ANALYTICS_SUCCESS, payload };
    },
    createPopupOpenAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.POPUP_OPEN, payload };
    },
    createPopupCloseAction() {
        return { type: ActionEnums_1.BasketActionTypes.POPUP_CLOSE };
    },
    createPopupShowAction(status) {
        return { type: ActionEnums_1.BasketActionTypes.POPUP_SHOW, payload: status };
    },
    createSetAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.SET,
            payload,
        };
    },
    createSetBasicAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.SET_BASIC,
            payload,
        };
    },
    createSizeForgottenShowAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.SIZE_FORGOTTEN_SHOW, payload };
    },
    createStatusUpdateAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.STATUS_UPDATE, payload };
    },
    createUpdateQuantityAction(payload) {
        return { type: ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY, payload };
    },
    createUpdateQuantityResultAction(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY_RESULT,
            payload: {
                product,
            },
        };
    },
    createRemoveProduct(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT,
            payload: {
                product,
            },
        };
    },
    createRemoveProductAndUpdate(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT_AND_UPDATE,
            payload: {
                product,
            },
        };
    },
    createRemoveSuccessAction(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT_SUCCESS,
            payload: {
                product,
            },
        };
    },
    createResetBasketForShopLanding() {
        return {
            type: ActionEnums_1.BasketActionTypes.RESET_BASKET_FOR_SHOPLANDING,
        };
    },
    createRemoveGoogleAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.REMOVE_GOOGLE_ANALYTICS_SUCCESS,
            payload,
        };
    },
    createRemoveAdobeAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.REMOVE_ADOBE_ANALYTICS_SUCCESS,
            payload,
        };
    },
    createCheckStockReservationAction() {
        return { type: ActionEnums_1.BasketActionTypes.CHECK_STOCK_RESERVATION };
    },
    createUpdateQuantityCustomAction(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY_CUSTOM,
            payload: {
                product,
            },
        };
    },
    createUpdateSuccessAction(product, quantity) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_PRODUCT_SUCCESS,
            payload: {
                product,
                quantity,
            },
        };
    },
    createUpdateGoogleAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_GOOGLE_ANALYTICS_SUCCESS,
            payload,
        };
    },
    createUpdateAdobeAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_ADOBE_ANALYTICS_SUCCESS,
            payload,
        };
    },
    createDecreaseProductQuantityAction(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.DECREASE_QUANTITY,
            payload: {
                product,
            },
        };
    },
    createIncreaseProductQuantityAction(product) {
        return {
            type: ActionEnums_1.BasketActionTypes.INCREASE_QUANTITY,
            payload: {
                product,
            },
        };
    },
    createUpdateItemsErrorStateAction(errorState, product) {
        return {
            type: ActionEnums_1.BasketActionTypes.UPDATE_ITEMS_ERROR_STATE,
            payload: {
                errorState,
                product,
            },
        };
    },
    createChangeSizeAction(product, newSku) {
        return {
            type: ActionEnums_1.BasketActionTypes.CHANGE_SIZE,
            payload: {
                product,
                newSku,
            },
        };
    },
    createHasUnavailableProductsAction(hasUnavailableProducts) {
        return {
            type: ActionEnums_1.BasketActionTypes.HAS_UNAVAILABLE_PRODUCTS_SET,
            payload: hasUnavailableProducts,
        };
    },
    createBasketOverviewInitAction() {
        return {
            type: ActionEnums_1.BasketActionTypes.BASKET_OVERVIEW_INIT,
        };
    },
    createBasketOverviewAdobeAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.BasketActionTypes.BASKET_OVERVIEW_ADOBE_ANALYTICS_SUCCESS,
            payload,
        };
    },
    createMergeBasketsAction(basketToken, type) {
        return {
            type: ActionEnums_1.BasketActionTypes.MERGE,
            payload: {
                basketToken,
                type,
            },
        };
    },
    createAddBulkAction(products, location, totalQuantityProducts) {
        return {
            type: ActionEnums_1.BasketActionTypes.ADD_BULK,
            payload: {
                products,
                location,
                totalQuantityProducts,
            },
        };
    },
    createAddAdditionalProductsAction(products, activeProductQuantity, location) {
        return {
            type: ActionEnums_1.BasketActionTypes.ADD_ADDITIONAL_PRODUCTS,
            payload: {
                products,
                activeProductQuantity,
                location,
            },
        };
    },
};
