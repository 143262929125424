"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decreaseProductQuantitySaga = exports.decreaseProductQuantity = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* decreaseProductQuantity(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(null));
        yield (0, effects_1.call)(BasketAPI_1.default.reduceQuantity, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createRemoveSuccessAction(action.payload.product));
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) {
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
}
exports.decreaseProductQuantity = decreaseProductQuantity;
function* decreaseProductQuantitySaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.DECREASE_QUANTITY, decreaseProductQuantity);
}
exports.decreaseProductQuantitySaga = decreaseProductQuantitySaga;
