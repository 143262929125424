"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaferpayDeviceFingerPrinting = void 0;
const tslib_1 = require("tslib");
const ScriptLoaderUtil_1 = require("../util/ScriptLoaderUtil");
const logger_1 = require("@as-react/logger");
let scriptInitialized = false;
const useSaferpayDeviceFingerPrinting = (dfpBaseUrl, session_id, instanceId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    if (!scriptInitialized) {
        scriptInitialized = true;
        const urlParams = new URLSearchParams({ session_id, instanceId, pageId: 'P' });
        const dfpScriptUrl = `${dfpBaseUrl}?${urlParams.toString()}`;
        try {
            yield ScriptLoaderUtil_1.default.loadScript(dfpScriptUrl, 'saferpay-device-fingerprinting', true);
            window.dfp.doFpt(document);
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
});
exports.useSaferpayDeviceFingerPrinting = useSaferpayDeviceFingerPrinting;
