"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const DeliveryPromiseMessage_1 = require("../../../components/product-detail/delivery-promise/DeliveryPromiseMessage");
var DeliveryDateEnum;
(function (DeliveryDateEnum) {
    DeliveryDateEnum["HOURS"] = "H";
    DeliveryDateEnum["DAYS"] = "D";
    DeliveryDateEnum["WEEKS"] = "W";
})(DeliveryDateEnum || (DeliveryDateEnum = {}));
const NO_DELIVERY_MESSAGE = '0-0';
const DeliveryPromise = ({ deliveryTimeText, deliveryInfo: { delivery, deliveryMessages, deliveryUnit, hasEvoucher, requiresDelivery, deliveryPromiseCutoff, hasDeliveryMessage, }, delivery_promise_standard_delivery_label, orderLinesHaveDifferentDeliveryPromises, deliveryStoreName, isCheckoutFlowDisabled, handleChangeStore, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const getTitleForFallback = () => {
        const deliveryTime = delivery.split('-');
        let deliveryUnitTranslate = '';
        if (deliveryUnit) {
            switch (deliveryUnit) {
                case DeliveryDateEnum.HOURS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.hours`);
                    break;
                case DeliveryDateEnum.DAYS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.days`);
                    break;
                case DeliveryDateEnum.WEEKS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.weeks`);
                    break;
                default:
                    deliveryUnitTranslate = '';
            }
        }
        return (t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt`, {
            deliveryFrom: deliveryTime[0],
            deliveryTo: deliveryTime[1],
        }) +
            ' ' +
            deliveryUnitTranslate);
    };
    const getTitle = (key) => delivery_promise_standard_delivery_label
        ? `${t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.` + key)}${deliveryMessages[key]}`
        : deliveryMessages[key];
    const getChangeStoreButton = () => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, children: [deliveryStoreName, " -", ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleChangeStore, variant: cotopaxi_1.ButtonVariant.LINK, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.INHERIT, children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.change.store`) }) })] }));
    const getChangeStoreButtonIfExists = () => (deliveryStoreName ? getChangeStoreButton() : null);
    const getText = () => {
        if (orderLinesHaveDifferentDeliveryPromises && deliveryTimeText) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [getChangeStoreButtonIfExists(), deliveryTimeText] }));
        }
        return getChangeStoreButtonIfExists() || '';
    };
    if (isCheckoutFlowDisabled) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 1, paddingRight: 1 }, theme: cotopaxi_1.BoxTheme.QUATERNARY }, tablet: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { title: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.cannot.be.collected`), text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1 } }, children: getText() }), isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, iconName: cotopaxi_1.IconName.EXCLAMATION_TRIANGLE_SOLID, iconSize: cotopaxi_1.IconSize.MEDIUM, dataQA: "delivery_promise" }) }));
    }
    if (delivery === NO_DELIVERY_MESSAGE) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 1, paddingRight: 1 }, theme: cotopaxi_1.BoxTheme.SECONDARY_LIGHT }, tablet: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: [!deliveryMessages && requiresDelivery && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { title: getTitleForFallback(), text: getText(), isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE, iconName: cotopaxi_1.IconName.TRUCK, iconSize: cotopaxi_1.IconSize.MEDIUM, dataQA: "delivery_promise" })), deliveryMessages &&
                requiresDelivery &&
                Object.entries(deliveryMessages).map(([key, deliveryMessage]) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { title: deliveryPromiseCutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: {
                            cutoff: deliveryPromiseCutoff,
                            deliveryMessage,
                            hasDeliveryMessage: Boolean(hasDeliveryMessage),
                            name: key,
                        }, textProps: {
                            strong: true,
                        } })) : (getTitle(key)), text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1 } }, children: getText() }), isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE, iconName: key === common_types_1.DeliveryOptionsEnum.COLLECT_STORE || key === common_types_1.DeliveryOptionsEnum.COLLECT_HQ
                        ? cotopaxi_1.IconName.STORE
                        : cotopaxi_1.IconName.TRUCK, iconSize: cotopaxi_1.IconSize.MEDIUM, dataQA: "delivery_promise" }, key))), hasEvoucher && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { title: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.mailbox`), isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE, iconName: cotopaxi_1.IconName.TRUCK, iconSize: cotopaxi_1.IconSize.MEDIUM, dataQA: "delivery_promise" }))] }));
};
exports.default = DeliveryPromise;
