"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const SortControl_1 = require("../../components/lister/SortControl");
const redux_1 = require("redux");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const _SortContainer = props => {
    const { sortConfiguration, queryInformation, updateSort } = props;
    const getCurrentOrderItem = () => {
        const currentOrder = queryInformation.sort || sortConfiguration.defaultValue;
        const foundItem = sortConfiguration.properties.find(orderItem => orderItem.key === currentOrder);
        if (foundItem)
            return foundItem;
        return sortConfiguration.properties[0];
    };
    const [currentOrderItem, setCurrentOrderItem] = (0, react_1.useState)(getCurrentOrderItem);
    (0, react_1.useEffect)(() => {
        setCurrentOrderItem(getCurrentOrderItem);
    }, [sortConfiguration.properties, queryInformation.sort]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(SortControl_1.SortControl, { orderItems: sortConfiguration.properties, currentOrderItem: currentOrderItem, onOrderChange: updateSort }) }));
};
const makeMapStateToProps = () => {
    const getUrlQueryInformationState = (0, UrlSelectors_1.makeGetUrlQueryInformationState)();
    const mapStateToProps = (state) => ({
        queryInformation: getUrlQueryInformationState(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch) => (0, redux_1.bindActionCreators)({
    updateSort: UrlActions_creators_1.urlActions.updateSort,
}, dispatch);
const composer = (0, redux_1.compose)((0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps));
exports.SortContainer = composer(_SortContainer);
