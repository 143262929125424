"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRequestGroupBancontactContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const PaymentGroupBancontact_1 = require("../../../components/buy/payment/payment-groups/PaymentGroupBancontact");
const PaymentRequestAPI_1 = require("../../../api/buy/PaymentRequestAPI");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const PaymentRequestGroupBancontactContainer = ({ paymentRequestId, saferpayReady, totalPrice, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const handleSubmit = (saferpayFieldsToken) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { redirectUrl } = yield PaymentRequestAPI_1.default.postSaferpayToken(servicesEndpoint, defaultRequestParameters, paymentRequestId, saferpayFieldsToken);
        location.href = redirectUrl;
    });
    const handleInitAlternative = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () { return PaymentRequestAPI_1.default.initSaferpayAlternative(servicesEndpoint, defaultRequestParameters, paymentRequestId); });
    const handlePollingAlternative = (requestId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const result = yield PaymentRequestAPI_1.default.statusSaferpayAlternative(servicesEndpoint, requestId);
        if ((result === null || result === void 0 ? void 0 : result.status) &&
            (result.status === common_types_1.SaferpayAltStatusResult.CAPTURED || result.status === common_types_1.SaferpayAltStatusResult.FAILURE)) {
            return result.redirectUrl;
        }
        return null;
    });
    return ((0, jsx_runtime_1.jsx)(PaymentGroupBancontact_1.PaymentGroupBancontact, { totalPrice: totalPrice, isSetPaymentOptionSuccess: true, saferpayFieldsReady: saferpayReady, onSubmit: handleSubmit, onInitAlternative: handleInitAlternative, onPollingAlternative: handlePollingAlternative }));
};
exports.PaymentRequestGroupBancontactContainer = PaymentRequestGroupBancontactContainer;
