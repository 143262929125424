"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAnalyticsDigitalPartnerEnrolmentLeave = exports.isAnalyticsDigitalPartnerEnrolmentConfirmation = exports.isAnalyticsDigitalPartnerEnrolmentEligibilityCheck = exports.isAnalyticsDigitalPartnerEnrolmentAuthPasswordForgotten = exports.isAnalyticsDigitalPartnerEnrolmentAuthRegister = exports.isAnalyticsDigitalPartnerEnrolmentAuthLogin = exports.isAnalyticsDigitalPartnerEnrolmentStart = exports.isAnalyticsDigitalPartnerEnrolmentAuthEmail = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const isAnalyticsDigitalPartnerEnrolmentAuthEmail = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_EMAIL;
exports.isAnalyticsDigitalPartnerEnrolmentAuthEmail = isAnalyticsDigitalPartnerEnrolmentAuthEmail;
const isAnalyticsDigitalPartnerEnrolmentStart = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_START;
exports.isAnalyticsDigitalPartnerEnrolmentStart = isAnalyticsDigitalPartnerEnrolmentStart;
const isAnalyticsDigitalPartnerEnrolmentAuthLogin = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_LOGIN;
exports.isAnalyticsDigitalPartnerEnrolmentAuthLogin = isAnalyticsDigitalPartnerEnrolmentAuthLogin;
const isAnalyticsDigitalPartnerEnrolmentAuthRegister = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_REGISTER;
exports.isAnalyticsDigitalPartnerEnrolmentAuthRegister = isAnalyticsDigitalPartnerEnrolmentAuthRegister;
const isAnalyticsDigitalPartnerEnrolmentAuthPasswordForgotten = (item) => item.type ===
    ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN;
exports.isAnalyticsDigitalPartnerEnrolmentAuthPasswordForgotten = isAnalyticsDigitalPartnerEnrolmentAuthPasswordForgotten;
const isAnalyticsDigitalPartnerEnrolmentEligibilityCheck = (item) => item.type ===
    ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_ELIGIBILITY_CHECK;
exports.isAnalyticsDigitalPartnerEnrolmentEligibilityCheck = isAnalyticsDigitalPartnerEnrolmentEligibilityCheck;
const isAnalyticsDigitalPartnerEnrolmentConfirmation = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_CONFIRMATION;
exports.isAnalyticsDigitalPartnerEnrolmentConfirmation = isAnalyticsDigitalPartnerEnrolmentConfirmation;
const isAnalyticsDigitalPartnerEnrolmentLeave = (item) => item.type === ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_LEAVE;
exports.isAnalyticsDigitalPartnerEnrolmentLeave = isAnalyticsDigitalPartnerEnrolmentLeave;
