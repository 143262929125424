"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductBundleDeals = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductBundleTileItem_1 = require("./ProductBundleTileItem");
const ProductBundleDeals = ({ bundle }) => {
    const selectedSlots = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetBundleSelectedSlots)());
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.TileList, { tileListName: `bundle-${bundle.bundleId}`, selectable: true, selectedItems: selectedSlots, children: bundle.bundleSlots.map(slot => ((0, jsx_runtime_1.jsx)(ProductBundleTileItem_1.ProductBundleTileItem, { productSlot: slot, isMonetateBundle: bundle.isMonetateBundle }, slot.slotNumber))) }) }));
};
exports.ProductBundleDeals = ProductBundleDeals;
