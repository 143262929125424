"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageUrlForBasketTile = exports.getSwatchUrlByProductCode = exports.getImageSourceSetByProductCode = exports.getImageTileUrlByProductCode = exports.getImageLargeUrlByProductCode = exports.getPortraitImageSizeUrlByProductCode = exports.getImageBaseDomain = exports.getImageSrcset = exports.getPictureSrcset = exports.getImageFromCdn = exports.getOverlayImageBaseUrl = exports.calculateListerImageMaxWidth = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const PRICE_OVERLAY_LOCATION = '/web_overlays/';
const DAM = '/content/dam/';
const SIZES = [50, 88, 128, 150, 219, 254, 285, 370, 400, 580];
const DEFAULT_TILE_WIDTH = 254;
const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);
const calculateListerImageMaxWidth = (pathBaseTile) => {
    const productImageMaxWidthFolder = pathBaseTile.substr(pathBaseTile.indexOf('/'));
    const productImageMaxWidthImage = Number(productImageMaxWidthFolder.substr(0, productImageMaxWidthFolder.indexOf('x')));
    return isNumeric(productImageMaxWidthImage) ? productImageMaxWidthImage : DEFAULT_TILE_WIDTH;
};
exports.calculateListerImageMaxWidth = calculateListerImageMaxWidth;
const convertStringToNumber = (originalValue, i) => {
    const parseableValue = originalValue.charAt(i);
    if (isNumeric(parseableValue)) {
        return parseFloat(parseableValue);
    }
    return parseInt(parseableValue, 36) - 9;
};
const sumOffAllCharacters = (stringValue) => {
    let i = stringValue.length, r = 0;
    while (--i >= 0) {
        r += convertStringToNumber(stringValue, i);
    }
    return r;
};
const generateDomainIndexBasedOnProductCode = (productCode, cdnDomains) => {
    return sumOffAllCharacters(productCode) % cdnDomains.length;
};
const getCdnDomainByProductCode = (productCode, cdnDomains) => {
    const index = generateDomainIndexBasedOnProductCode(productCode, cdnDomains);
    return cdnDomains[index];
};
const generateMultiDomainCdns = (cdnDomainRawString) => {
    if (!(0, common_utils_1.isNullOrUndefined)(cdnDomainRawString) &&
        typeof cdnDomainRawString === 'string' &&
        cdnDomainRawString.trim() !== '') {
        return cdnDomainRawString.split(',');
    }
    return [];
};
const getRenditionSize = (rendition) => rendition.split('.')[2];
const getPixelDensity = (rendition) => {
    if (rendition.includes('@')) {
        return parseInt(rendition.substr(rendition.lastIndexOf('@') + 1, 1), 10);
    }
    return 1;
};
const getOverlayImageBaseUrl = (mainWebShop, lang) => {
    return `${DAM}${mainWebShop}${PRICE_OVERLAY_LOCATION}${lang}/`;
};
exports.getOverlayImageBaseUrl = getOverlayImageBaseUrl;
const getImageFromCdn = (image, imageCdnPrefix, rendition, imageQuality, requestPassThrough) => {
    if (image.renditions.length === 0) {
        return `${imageCdnPrefix}${image.imagePath}`;
    }
    const quality = imageQuality ? `/cmpr_${imageQuality}` : '';
    const passThrough = (0, common_utils_1.isNullOrUndefined)(requestPassThrough) ? '' : `/pass_${requestPassThrough}`;
    if (!rendition) {
        const sortedRenditionNames = image.renditions.sort((r1, r2) => Number(getRenditionSize(r1)) - Number(getRenditionSize(r2)));
        const firstSortedName = sortedRenditionNames[0];
        return `${imageCdnPrefix}${image.imagePath}/jcr:content/renditions/${firstSortedName}?imgeng=/w_${getRenditionSize(firstSortedName)}${quality}${passThrough}`;
    }
    const validRenditionName = image.renditions.find((img) => getRenditionSize(img) === rendition);
    return `${imageCdnPrefix}${image.imagePath}${validRenditionName
        ? `/jcr:content/renditions/${validRenditionName}?imgeng=/w_${rendition}${quality}${passThrough}`
        : ''}`;
};
exports.getImageFromCdn = getImageFromCdn;
const getPictureSrcset = (image, imageCdnPrefix, imageQuality, requestPassThrough) => {
    const quality = imageQuality ? `/cmpr_${imageQuality}` : '';
    const passThrough = (0, common_utils_1.isNullOrUndefined)(requestPassThrough) ? '' : `/pass_${requestPassThrough}`;
    return image.renditions.sort().reduce((obj, rendition) => {
        const renditionSize = getRenditionSize(rendition);
        const pixelDensity = getPixelDensity(rendition);
        const version = image.lastModified || 0;
        const pixelDensityDescriptor = pixelDensity > 1 ? `${pixelDensity}x` : '';
        const width = parseInt(renditionSize, 10) * pixelDensity;
        const url = `${imageCdnPrefix}${image.imagePath}/jcr:content/renditions/${rendition}?v=${version}&imgeng=/w_${width}`.trim() +
            `${quality}${passThrough}` +
            ` ${pixelDensityDescriptor}`;
        if (Object.hasOwn(obj, renditionSize)) {
            obj[renditionSize] = obj[renditionSize].concat(`, ${url}`);
            return obj;
        }
        return Object.assign(Object.assign({}, obj), { [renditionSize]: url });
    }, {});
};
exports.getPictureSrcset = getPictureSrcset;
const getImageSrcset = (image, imageCdnPrefix, imageQuality, requestPassThrough) => {
    const quality = imageQuality ? `/cmpr_${imageQuality}` : '';
    const passThrough = (0, common_utils_1.isNullOrUndefined)(requestPassThrough) ? '' : `/pass_${requestPassThrough}`;
    return image.renditions.sort().map(rendition => {
        const renditionSize = getRenditionSize(rendition);
        const pixelDensity = getPixelDensity(rendition);
        const version = image.lastModified || 0;
        const pixelDensityDescriptor = pixelDensity > 1 ? ` ${pixelDensity}x` : '';
        const width = parseInt(renditionSize, 10) * pixelDensity;
        const url = `${imageCdnPrefix}${image.imagePath}/jcr:content/renditions/${rendition}?v=${version}&imgeng=/w_${width}`.trim() +
            `${quality}${passThrough}`;
        return { source: url, descriptor: `${renditionSize}w${pixelDensityDescriptor}` };
    });
};
exports.getImageSrcset = getImageSrcset;
const getImageBaseDomain = (multiCdnDomainActivated, singleCdnDomain, multiCdnDomains, productCode) => {
    if (multiCdnDomainActivated === true) {
        return getCdnDomainByProductCode(productCode, multiCdnDomains);
    }
    return singleCdnDomain;
};
exports.getImageBaseDomain = getImageBaseDomain;
const getImageUrlByProductCodeAndType = (productCode, image, productImageCdnConfiguration, imagePath) => {
    const multiCdnDomains = generateMultiDomainCdns(productImageCdnConfiguration.domains);
    const imageCdnBaseUrl = (0, exports.getImageBaseDomain)(productImageCdnConfiguration.activated, productImageCdnConfiguration.domain, multiCdnDomains, productCode);
    return `${imageCdnBaseUrl}${imagePath}${image}`;
};
const getPortraitImageSizeUrlByProductCode = (productCode, image, productImageCdnConfiguration, size) => {
    const sizeBaseUrl = `${productImageCdnConfiguration.pathBaseTileLocation}${size}/`;
    return getImageUrlByProductCodeAndType(productCode, image, productImageCdnConfiguration, sizeBaseUrl);
};
exports.getPortraitImageSizeUrlByProductCode = getPortraitImageSizeUrlByProductCode;
const getImageLargeUrlByProductCode = (productCode, image, productImageCdnConfiguration) => {
    return getImageUrlByProductCodeAndType(productCode, image, productImageCdnConfiguration, productImageCdnConfiguration.pathBaseProductDetailLarge);
};
exports.getImageLargeUrlByProductCode = getImageLargeUrlByProductCode;
const getImageTileUrlByProductCode = (productCode, image, productImageCdnConfiguration) => {
    return getImageUrlByProductCodeAndType(productCode, image, productImageCdnConfiguration, productImageCdnConfiguration.pathBaseNewDesignTile);
};
exports.getImageTileUrlByProductCode = getImageTileUrlByProductCode;
const getImageSourceSetByProductCode = (productCode, image, productImagesConfiguration) => {
    let sources = {};
    SIZES.forEach((size) => {
        const sizeBaseUrl = `${productImagesConfiguration.cdnDomains.pathBaseTileLocation}${size}x${size}/`;
        sources[`${size}w`] = getImageUrlByProductCodeAndType(productCode, image, productImagesConfiguration.cdnDomains, sizeBaseUrl);
    });
    Object.entries(common_types_1.ProductImageSizesPortraitEnum).forEach(([_, value]) => {
        const sizeBaseUrl = `${productImagesConfiguration.cdnDomains.pathBaseTileLocation}${value}/`;
        sources[value] = getImageUrlByProductCodeAndType(productCode, image, productImagesConfiguration.cdnDomains, sizeBaseUrl);
    });
    sources = Object.assign(Object.assign({}, sources), { big: (0, exports.getImageLargeUrlByProductCode)(productCode, image, productImagesConfiguration.cdnDomains) });
    return sources;
};
exports.getImageSourceSetByProductCode = getImageSourceSetByProductCode;
const getSwatchUrlByProductCode = (productCode, image, productImageCdnConfiguration) => {
    return getImageUrlByProductCodeAndType(productCode, image, productImageCdnConfiguration, productImageCdnConfiguration.pathBaseSwatch);
};
exports.getSwatchUrlByProductCode = getSwatchUrlByProductCode;
const getImageUrlForBasketTile = (productData, productImageConfiguration, imageWidth) => productData.additionalImage
    ? productData.additionalImage
    : (0, exports.getImageSourceSetByProductCode)(productData.productCode, productData.image, productImageConfiguration)[imageWidth];
exports.getImageUrlForBasketTile = getImageUrlForBasketTile;
