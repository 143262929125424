"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkModuleCollectionForComponent = exports.loadFromModuleCollectionImplementation = void 0;
const tslib_1 = require("tslib");
const common_types_1 = require("@as-react/common-types");
// prettier-ignore
const moduleCollection = {
    [common_types_1.AEMScenes.BUY]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Buy_Module" */ './Buy.rootModule')),
    [common_types_1.AEMScenes.BUY_PAYMENT_REQUEST]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Buy_Module" */ './Buy.rootModule')),
    [common_types_1.AEMScenes.BUY_CONFIRMATION]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_BuyConfirmation_Module" */ './BuyConfirmation.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_MENU_ITEM]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountMenuItem_Module" */ './AccountMenuItem.rootModule')),
    [common_types_1.AEMScenes.WISHLIST]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Wishlist_Module" */ './WishlistLister.rootModule')),
    [common_types_1.AEMScenes.WISHLIST_MENU_ITEM]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_WishlistMenuItem_Module" */ './WishlistMenuItem.rootModule')),
    [common_types_1.AEMScenes.BASKET_MENU_ITEM]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketMenuItem_Module" */ './BasketMenuItem.rootModule')),
    [common_types_1.AEMScenes.SEARCH_MENU_ITEM]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_MenuSearch_Module" */ './MenuSearch.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_LISTER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductLister_Module" */ './ProductLister.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_DETAIL_V2]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductDetail_v2_Module" */ './productDetail.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_GROUP]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroup_Module" */ './productGroup.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_RECENTLY_VIEWED]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductRecentlyViewed_Module" */ './productRecentlyViewed.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupFredhopper_Module" */ './ProductGroupFredhopper.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_GROUP_MONETATE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupMonetate_Module" */ './ProductGroupMonetate.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_FREDHOPPER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupPdpComparisonFredhopper_Module" */ './ProductGroupPdpComparisonFredhopper.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_MONETATE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupPdpComparisonMonetate_Module" */ './ProductGroupPdpComparisonMonetate.rootModule')),
    [common_types_1.AEMScenes.SINGLE_PRODUCT_TILE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "SingleProductTileNewDesing_Module" */ './SingleProductTile.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_REVIEW]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductReview_Module" */ './ProductReview.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_REVIEW_LANDING]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductReviewLanding_Module" */ './ProductReviewLanding.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_COMPARISON]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductComparison_Module" */ './ProductComparison.rootModule')),
    [common_types_1.AEMScenes.GIFT_VOUCHER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_GiftVoucher_Module" */ './GiftVoucher.rootModule')),
    [common_types_1.AEMScenes.DPE_ACTIVATOR]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_DpeActivator_Module" */ './DpeActivator.rootModule')),
    [common_types_1.AEMScenes.BASKET_OVERVIEW]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketOverview_Module" */ './BasketOverview.rootModule')),
    [common_types_1.AEMScenes.BASKET_PERSONAL_SHOPPING_ADVICE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketPersonalShoppingAdvice_Module" */ './BasketPersonalShoppingAdvice.rootModule')),
    [common_types_1.AEMScenes.ESSENTIALS]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Essentials_Module" */ './Essentials.rootModule')),
    [common_types_1.AEMScenes.GUEST_REDIRECT]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_GuestRedurect_Module" */ './GuestRedirect.rootModule')),
    [common_types_1.AEMScenes.SHOP_NUMBER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ShopNumber_Module" */ './ShopNumber.rootModule')),
    [common_types_1.AEMScenes.COOKIE_MESSAGE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_CookieMessage_Module" */ './CookieMessage.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_WIZARD]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductWizard_Module" */ './ProductWizard.rootModule')),
    [common_types_1.AEMScenes.BRAND_TILE_LISTER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_BrandTileLister_Module" */ './BrandLister.rootModule')),
    [common_types_1.AEMScenes.CONTENT_TILE_LISTER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ContentTileLister_Module" */ './ContentTileLister.rootModule')),
    [common_types_1.AEMScenes.CAMPAIGN_GROUP]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ContentTileLister_Module" */ './CampaignGroup.rootModule')),
    [common_types_1.AEMScenes.COLLAPSIBLE_LIST]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_CollapsibleList_Module" */ './CollapsibleList.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_INSPIRATION]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductInspiration_Module" */ './ProductInspiration.rootModule')),
    [common_types_1.AEMScenes.RETURN_ITEMS]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReturnItems_Module" */ './ReturnItems.rootModule')),
    [common_types_1.AEMScenes.NEWSLETTER_SUBSCRIBE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_NewsletterSubscribe_Module" */ './NewsletterSubscribe.rootModule')),
    [common_types_1.AEMScenes.ORDER_OVERVIEW]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_OrderOverview_Module" */ './OrderOverview.rootModule')),
    [common_types_1.AEMScenes.RETURN_PORTAL_LOGIN]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReturnPortalLogin_Module" */ './ReturnPortalLogin.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_MENU]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountMenu_Module" */ './AccountMenu.rootModule')),
    [common_types_1.AEMScenes.SHOP_THE_LOOK]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ShopTheLook_Module" */ './ShopTheLook.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_REGISTRATION]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ShopTheLook_Module" */ './AccountRegistration.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_PAYBACK_ENRICHMENT]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountPaybackEnrichment_Module" */ './AccountPaybackEnrichment.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_PREFERENCES]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountPreferences_Module" */ './AccountPreferences.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_DELIVERY_ADDRESSES]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountDeliveryAddresses_Component" */ './AccountDeliveryAddresses.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_PROFILE_DETAILS]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountProfileDetails_Component" */ './AccountProfileDetails.rootModule')),
    [common_types_1.AEMScenes.ACCOUNT_ORDER_HISTORY]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountOrderHistory_Component" */ './AccountOrderHistory.rootModule')),
    [common_types_1.AEMScenes.PRODUCT_CONTENT_VIDEO]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductContentVideo_Component" */ './ProductContentVideo.rootModule')),
    [common_types_1.AEMScenes.LOGIN]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Login_Component" */ './Login.rootModule')),
    [common_types_1.AEMScenes.REGISTRATION_COMPACT]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_RegistrationCompact_Module" */ './RegistrationCompact.rootModule')),
    [common_types_1.AEMScenes.SLIDER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Slider_Component" */ './Slider.rootModule')),
    [common_types_1.AEMScenes.RENTAL]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Rental_Module" */ './Rental.rootModule')),
    [common_types_1.AEMScenes.MONETATE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Monetate_Module" */ './Monetate.rootModule')),
    [common_types_1.AEMScenes.REEVOO_CUSTOMER_EXPERIENCE]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "ReevooCustomerExperience_Module" */ './Reevoo.rootModule')),
    [common_types_1.AEMScenes.ETICKET]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Eticket_Module" */ './Eticket.rootModule')),
    [common_types_1.AEMScenes.SHOPPING_BUDGET]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "ShoppingBudget_Module" */ './ShoppingBudget.rootModule')),
    [common_types_1.AEMScenes.DELIVERY_CONDITIONS_BANNER]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "DeliveryConditionsBanner_Module" */ './DeliveryConditionsBanner.rootModule')),
    [common_types_1.AEMScenes.MOBILE_NAVIGATION]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "MobileNavigation_Module" */ './MobileNavigation.rootModule')),
    [common_types_1.AEMScenes.CHECKOUT]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Checkout_Module" */ './Checkout.rootModule')),
    [common_types_1.AEMScenes.RELATED_CATEGORIES]: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "RelatedCategories_Module" */ './RelatedCategories.rootModule')),
};
const loadFromModuleCollectionImplementation = (componentName, initialState) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    const loadedDynamicModule = yield moduleCollection[componentName]();
    return loadedDynamicModule.default(initialState);
});
exports.loadFromModuleCollectionImplementation = loadFromModuleCollectionImplementation;
const checkModuleCollectionForComponent = (componentName) => Object.keys(moduleCollection).indexOf(componentName) >= 0;
exports.checkModuleCollectionForComponent = checkModuleCollectionForComponent;
