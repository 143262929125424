"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationErrorPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyConfirmationErrorPage = ({ retry }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: {
                marginTop: 8,
                marginBottom: 10,
            },
        }, tablet: {
            spacing: {
                marginTop: 16,
                marginBottom: 16,
            },
        }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_AROUND, valign: cotopaxi_1.GroupVerticalAlign.CENTER, column: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, inline: true, dataQA: "error_confirmation_message", align: cotopaxi_1.TextAlign.CENTER, children: t(common_types_1.ErrorMessage.API_ERROR) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            paddingTop: 2,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: retry, text: t('buy.error.retry') }) })] }) }));
};
exports.BuyConfirmationErrorPage = BuyConfirmationErrorPage;
