"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authEndpoints = exports.API_BACKEND_BASIC_AUTH = void 0;
const axios_1 = require("axios");
exports.API_BACKEND_BASIC_AUTH = 'Basic Y3VzdG9tZXItYXBwOmN1c3RvbWVyLXNlY3JldA==';
exports.authEndpoints = {
    authenticated: 'api/authenticated',
    login: 'api/oauth/login',
    registeredCheckout: 'api/oauth/login/checkout',
    logout: 'api/oauth/logout',
    forgot_password: 'api/credentials/forgot',
    login_social: 'api/oauth/login/social',
    login_social_checkout: 'api/oauth/login/social/checkout',
    login_guest: 'api/buy/login',
    login_return_guest: 'api/returnannounce/login',
};
class AuthApi {
    constructor() {
        this.socialLogin = (servicesApi, defaultRequestParams, accountData, isBuyPage = false, isConfirmationPage = false) => {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${isBuyPage && !isConfirmationPage ? exports.authEndpoints.login_social_checkout : exports.authEndpoints.login_social}`,
                params: defaultRequestParams,
                withCredentials: true,
                data: accountData,
                headers: {
                    Authorization: exports.API_BACKEND_BASIC_AUTH,
                },
            };
            return axios_1.default.request(axiosConfig);
        };
    }
    login(apiPrefix, defaultRequestParams, username, password, keepSignedIn) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.authEndpoints.login}`,
            headers: {
                Authorization: exports.API_BACKEND_BASIC_AUTH,
            },
            params: Object.assign(Object.assign({}, defaultRequestParams), { keepSignedIn }),
            withCredentials: true,
        };
        if (username || password) {
            axiosConfig.data = {
                username,
                password,
            };
        }
        return axios_1.default.request(axiosConfig);
    }
    registeredLogin(apiPrefix, defaultRequestParams, username, password, keepSignedIn) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.authEndpoints.registeredCheckout}`,
            headers: {
                Authorization: exports.API_BACKEND_BASIC_AUTH,
            },
            params: Object.assign(Object.assign({}, defaultRequestParams), { keepSignedIn }),
            withCredentials: true,
        };
        if (username || password) {
            axiosConfig.data = {
                username,
                password,
            };
        }
        return axios_1.default.request(axiosConfig);
    }
    logout(apiPrefix, defaultRequestParams) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.authEndpoints.logout}`,
            params: defaultRequestParams,
            withCredentials: true,
        };
        return axios_1.default.request(axiosConfig);
    }
    isAuthenticated(apiPrefix) {
        const axiosConfig = {
            method: 'GET',
            url: `${apiPrefix}${exports.authEndpoints.authenticated}`,
            withCredentials: true,
        };
        return axios_1.default.request(axiosConfig);
    }
    loginGuest(apiPrefix, defaultRequestParams, username, exploreMoreCardNumber) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.authEndpoints.login_guest}`,
            headers: {
                Authorization: exports.API_BACKEND_BASIC_AUTH,
            },
            params: defaultRequestParams,
            withCredentials: true,
            data: { username, exploreMoreCardNumber },
        };
        return axios_1.default.request(axiosConfig);
    }
    loginReturnGuest(apiPrefix, { language, mainWebshop, shopId }, userName, orderNumber) {
        const axiosConfig = {
            method: 'POST',
            url: `${apiPrefix}${exports.authEndpoints.login_return_guest}`,
            data: {
                email: userName,
                language,
                mainWebshop,
                shopId,
                orderId: orderNumber,
            },
            withCredentials: true,
        };
        return axios_1.default.request(axiosConfig);
    }
}
exports.default = new AuthApi();
