"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const asNumeral_1 = require("../../../vendors/asNumeral");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MiniPDPMultiFooter = ({ numberProducts, totalPrice, currency, handleAddToBasket, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.checkout]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { desktop: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: [totalPrice && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: [t('checkout:checkout.basket.total'), ":"] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Price, { currency: currency, priceActual: {
                                formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPrice.totalSellPrice),
                            }, pricePrevious: totalPrice.totalSellPrice !== totalPrice.totalStandardPrice
                                ? {
                                    formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPrice.totalStandardPrice),
                                }
                                : null })] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, iconLeft: { name: cotopaxi_1.IconName.CART, size: cotopaxi_1.IconSize.MINOR }, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.INHERIT, children: !numberProducts
                        ? t('product:product.look.no.items')
                        : numberProducts === 1
                            ? t('product:product.look.add.item')
                            : t('product:product.look.add.items', {
                                amount: numberProducts,
                            }) }), variant: cotopaxi_1.ButtonVariant.BRANDED, state: !numberProducts ? cotopaxi_1.ButtonState.DISABLED : undefined, onClick: handleAddToBasket })] }));
};
exports.default = MiniPDPMultiFooter;
