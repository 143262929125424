"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnPortalEventActionType = exports.ReturnPortalLoginType = exports.CheckoutType = exports.EventActions = exports.Events = exports.PageId = exports.DirectCallRules = void 0;
var DirectCallRules;
(function (DirectCallRules) {
    DirectCallRules["CHECKOUT"] = "checkout";
    DirectCallRules["PRODUCT_SIZE_PICKED"] = "productSizePicked";
    DirectCallRules["PRODUCT_VIEW"] = "productView";
    DirectCallRules["PRODUCT_COLOR_SWITCH"] = "productColourSwitch";
    DirectCallRules["PRODUCT_LIST_PAGE"] = "product list page";
    DirectCallRules["NOTIFY_ME"] = "notifyMe";
    DirectCallRules["APPLE_PAY_CLICK"] = "initiatePayment";
    DirectCallRules["APPLE_PAY_BUTTON_IMPRESSION"] = "buttonImpression";
    DirectCallRules["SHOPPING_BUDGET"] = "shoppingbudget";
    DirectCallRules["SUCCESSFUL_SEARCH_EVENT"] = "searchAutoSuggestion";
    DirectCallRules["SUCCESSFUL_SEARCH_EVENT_PRODUCT"] = "searchAutoSuggestionProduct";
    DirectCallRules["SUCCESSFUL_SEARCH_EVENT_ITEM"] = "searchAutoSuggestionItem";
    DirectCallRules["LISTER_PRODUCT_CLICK"] = "succesfull search";
    DirectCallRules["REGISTRATION_POPUP_REGISTRATION"] = "registration popup =registration";
    DirectCallRules["REGISTRATION_POPUP_1"] = "registration popup =1";
    DirectCallRules["REGISTRATION_POPUP_2"] = "registration popup =2";
    DirectCallRules["REGISTRATION_POPUP_3"] = "registration popup =3";
    DirectCallRules["ERROR_MESSAGE"] = "errorMessage";
    DirectCallRules["WISHLIST_ADD"] = "addToWishlist";
    DirectCallRules["WISHLIST_REMOVE"] = "removeWishlist";
    DirectCallRules["WISHLIST_REMOVE_PDP"] = "removeWishlist =productDetailsPage";
    DirectCallRules["WISHLIST_REMOVE_WLIST"] = "removeWishlist =wishListPage";
    DirectCallRules["COMPARE_ADD"] = "addToCompare";
    DirectCallRules["COMPARE_REMOVE_LISTER"] = "removeCompare:listerPageTile";
    DirectCallRules["COMPARE_REMOVE"] = "removeCompare";
    DirectCallRules["COMPARE_REMOVE_PDP"] = "removeCompare:productDetailPage";
    DirectCallRules["COMPARE_REMOVE_COMPARE_BOX"] = "removeCompare:compareBox";
    DirectCallRules["COMPARE_REMOVE_COMPARE_PAGE"] = "removeCompare:comparePage";
    DirectCallRules["COMPARE_POPUP_OPEN"] = "openCompareProductPop-up";
    DirectCallRules["COMPARE_POPUP_CLOSE"] = "closeCompareProductPop-up";
    DirectCallRules["COMPARE_PAGE_OPEN"] = "open product comparison page";
    DirectCallRules["COMPARE_PAGE_NO_PRODUCT_DISPLAYED"] = "compareNoProductDisplayed";
    DirectCallRules["COMPARE_PAGE_PRODUCT_DISPLAYED"] = "compareProductDisplayed";
    DirectCallRules["NEWSLETTER_OPT_IN"] = "newsletterOptin";
    DirectCallRules["NEWSLETTER_OPT_OUT"] = "newsletterOptout";
    DirectCallRules["NEWSLETTER_PREFERENCES"] = "newsletterPreferences";
    DirectCallRules["NEWSLETTER_UPDATE_SUBSCRIPTIONS"] = "newsletterUpdate";
    DirectCallRules["CONSENT_ACCEPT_ALL"] = "ccAcceptAllCookie";
    DirectCallRules["CONSENT_OPEN_PREFERENCES"] = "ccOpenCookiePreference";
    DirectCallRules["CONSENT_UPDATE_PREFERENCES"] = "ccUpdateCookie";
    DirectCallRules["VOUCHER_ADDED"] = "addPromoCode";
    DirectCallRules["FILTER_WIZARD_OPENED"] = "filterWizardOpened";
    DirectCallRules["FILTER_WIZARD_SELECTED"] = "filterWizardSelected";
    DirectCallRules["STORE_FILTER_STARTED"] = "storeFilterStarted";
    DirectCallRules["STORE_FILTER_APPLIED"] = "storeFilterApplied";
    DirectCallRules["STORE_FILTER_MODIFIED"] = "storeFilterModified";
    DirectCallRules["SHOP_THE_LOOK"] = "shopTheLook";
    DirectCallRules["SC_ADD"] = "scAdd";
    DirectCallRules["SC_OPEN"] = "scOpen";
    DirectCallRules["LISTER_SORT_BY"] = "sortBy";
    DirectCallRules["RETURN_PORTAL_LOGIN"] = "returnPortalLogin";
    DirectCallRules["RETURN_PRODUCT_OVERVIEW"] = "returnProductOverview";
    DirectCallRules["RETURN_PRODUCTS_CONTINUE_CLICK"] = "returnProductsContinueClick";
    DirectCallRules["RETURN_PRODUCT_SELECTED"] = "returnProductSelected";
    DirectCallRules["RETURN_PRODUCT_UNSELECTED"] = "returnProductUnselected";
    DirectCallRules["RETURN_METHOD_OVERVIEW"] = "returnMethodOverview";
    DirectCallRules["RETURN_METHOD_CONFIRM_CLICK"] = "returnMethodConfirmClick";
    DirectCallRules["RETURN_METHOD_SELECTED"] = "returnMethodSelected";
    DirectCallRules["RETURN_ANNOUNCED"] = "returnAnnounced";
    DirectCallRules["SHOW_STORE_STOCK"] = "showStoreStock";
    DirectCallRules["PRODUCT_DETAIL_TAB_OPEN"] = "productInformationTab";
    DirectCallRules["PRODUCT_DETAIL_SIZE_GUIDE_CLICK"] = "sizeTable";
    DirectCallRules["BUNDLE_DEALS_VIEW"] = "bundleView";
    DirectCallRules["FAQ_QUESTION_EXPAND"] = "faqQuestionExpand";
    DirectCallRules["ETICKET_SEND_FEEDBACK"] = "e-satisfaction:sendFeedback";
    DirectCallRules["PRODUCT_GROUP_VIEWED"] = "productRecommendationView";
    DirectCallRules["PRODUCT_GROUP_ITEM_CLICK"] = "productRecommendationClick";
    DirectCallRules["PRODUCT_VARIANT_CLICK"] = "productVariantClick";
    DirectCallRules["BASKET_REMINDER_NOTIFICATION_VIEW"] = "basketReminderNotificationView";
    DirectCallRules["BASKET_REMINDER_NOTIFICATION_CLICK"] = "basketReminderNotificationClick";
    DirectCallRules["ADDITIONAL_PRODUCT_SELECTOR_VIEW"] = "additionalProductSelectorView";
    DirectCallRules["MOBILE_NAVIGATION_CLICK"] = "navigationClick";
    DirectCallRules["SIZEBAY_CLICK"] = "sizebayClick";
})(DirectCallRules || (exports.DirectCallRules = DirectCallRules = {}));
var PageId;
(function (PageId) {
    PageId["REGISTRATION"] = "registration";
    PageId["DELIVERY"] = "delivery";
    PageId["FAST_CHECKOUT"] = "fast checkout";
    PageId["LOGIN"] = "login";
    PageId["PAYMENT"] = "payment";
    PageId["RETURN_PORTAL_LOGIN"] = "return portal: login";
    PageId["RETURN_PORTAL_ANNOUNCED_RETURNS"] = "return portal: announced returns";
    PageId["RETURN_PORTAL_RETURN_PRODUCTS"] = "return portal: return products";
    PageId["RETURN_PORTAL_RETURN_METHOD"] = "return portal: return method";
    PageId["RETURN_PORTAL_RETURN_ANNOUNCED"] = "return portal: return announced";
})(PageId || (exports.PageId = PageId = {}));
var Events;
(function (Events) {
    Events["VIEW_PRODUCT_LIST"] = "viewProductList";
    Events["VIEW_SEARCH_RESULTS"] = "viewSearchResults";
})(Events || (exports.Events = Events = {}));
var EventActions;
(function (EventActions) {
    EventActions["FILTER_WIZARD_OPENED"] = "Open filter wizard";
    EventActions["FILTER_WIZARD_SELECTED"] = "filter wizard selected";
    EventActions["STORE_FILTER_STARTED"] = "store filter started";
    EventActions["STORE_FILTER_APPLIED"] = "store filter applied";
    EventActions["STORE_FILTER_MODIFIED"] = "store filter modified";
    EventActions["LISTER_SORT_BY"] = "sort by filter applied";
    EventActions["SHOW_STORE_STOCK"] = "show store stock";
    EventActions["PRODUCT_DETAIL_TAB_OPEN"] = "product information tab click";
    EventActions["PRODUCT_DETAIL_SIZE_GUIDE_CLICK"] = "size table click";
    EventActions["PRODUCT_DETAIL_ADD_TO_BASKET"] = "add to basket";
    EventActions["PRODUCT_DETAIL_ADD_TO_EMPTY_BASKET"] = "add to empty basket";
    EventActions["PRODUCT_BUNDLE_VIEW"] = "product bundle view";
    EventActions["FAQ_QUESTION_CLICK_OPEN"] = "click:open faq question";
    EventActions["NOTIFY_ME"] = "notify me request";
    EventActions["APPLE_PAY_CLICK"] = "apple pay initiated";
    EventActions["APPLE_PAY_BUTTON_IMPRESSION"] = "view:button:apple pay";
    EventActions["ETICKET_SEND_FEEDBACK"] = "e-satisfaction feedback sent";
    EventActions["PRODUCT_GROUP_VIEWED"] = "view:product recommendations";
    EventActions["PRODUCT_GROUP_ITEM_CLICK"] = "click:product recommendations";
    EventActions["PRODUCT_VARIANT_CLICK"] = "product variant click";
    EventActions["BASKET_REMINDER_NOTIFICATION_VIEW"] = "basket reminder view";
    EventActions["BASKET_REMINDER_NOTIFICATION_CLICK"] = "basket reminder click";
    EventActions["ADDITIONAL_PRODUCT_SELECTOR_VIEW"] = "additional product view";
    EventActions["MOBILE_NAVIGATION_CLICK"] = "click:mobile navigation:hamburger icon";
    EventActions["SIZEBAY_WIDGET_CLICK"] = "click:sizebay:widgetOpen";
    EventActions["SIZEBAY_GUIDE_CLICK"] = "click:sizebay:sizeGuideOpen";
})(EventActions || (exports.EventActions = EventActions = {}));
var CheckoutType;
(function (CheckoutType) {
    CheckoutType["GUEST"] = "guest";
    CheckoutType["LOGGED_IN"] = "logged in";
})(CheckoutType || (exports.CheckoutType = CheckoutType = {}));
var ReturnPortalLoginType;
(function (ReturnPortalLoginType) {
    ReturnPortalLoginType["KNOWN_USER"] = "known user";
    ReturnPortalLoginType["GUEST"] = "guest";
})(ReturnPortalLoginType || (exports.ReturnPortalLoginType = ReturnPortalLoginType = {}));
var ReturnPortalEventActionType;
(function (ReturnPortalEventActionType) {
    ReturnPortalEventActionType["LOGIN"] = "return portal: login";
    ReturnPortalEventActionType["CONTINUE_TO_METHOD"] = "continue to return method";
    ReturnPortalEventActionType["SELECT_PRODUCT"] = "return product selected";
    ReturnPortalEventActionType["UNSELECT_PRODUCT"] = "return product unselected";
    ReturnPortalEventActionType["CONFIRM_RETURN_METHOD"] = "confirm return method";
    ReturnPortalEventActionType["SELECT_RETURN_METHOD"] = "return method selected";
})(ReturnPortalEventActionType || (exports.ReturnPortalEventActionType = ReturnPortalEventActionType = {}));
