"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentOverviewActionTypes = void 0;
var ContentOverviewActionTypes;
(function (ContentOverviewActionTypes) {
    ContentOverviewActionTypes["UPDATE_CONTENT"] = "CONTENT_OVERVIEW_UPDATE_CONTENT";
    ContentOverviewActionTypes["UPDATE_FILTERS"] = "CONTENT_OVERVIEW_UPDATE_FILTERS";
    ContentOverviewActionTypes["FIND_CONTENT"] = "CONTENT_OVERVIEW_FIND_CONTENT";
    ContentOverviewActionTypes["SHOW_PLACEHOLDER_TILES"] = "CONTENT_OVERVIEW_SHOW_PLACEHOLDER_TILES";
})(ContentOverviewActionTypes || (exports.ContentOverviewActionTypes = ContentOverviewActionTypes = {}));
