"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetLoggedInReturnGuestState = exports.makeGetErrorReturnGuestState = exports.makeGetErrorGuestState = exports.makeGetLoggedGuestState = exports.makeGetFastCheckoutState = exports.makeGetResetPasswordState = exports.makeGetLoginError = exports.makeGetLoginReturnGuestLoading = exports.makeGetLoginGuestLoading = exports.makeGetLoginLoading = exports.makeGetAuthStatus = void 0;
const reselect_1 = require("reselect");
const authStateSelector = (state) => state.authentication;
const getAuthStatus = (state) => state.authentication.authenticated;
const makeGetAuthStatus = () => (0, reselect_1.createSelector)(getAuthStatus, (authenticated) => authenticated);
exports.makeGetAuthStatus = makeGetAuthStatus;
const getLoginLoading = (state) => state.authentication.loading;
const makeGetLoginLoading = () => (0, reselect_1.createSelector)(getLoginLoading, (loading) => loading);
exports.makeGetLoginLoading = makeGetLoginLoading;
const getLoginGuestLoading = (state) => state.authentication.loadingGuest;
const getLoginReturnGuestLoading = (state) => state.authentication.loadingReturnGuest;
const makeGetLoginGuestLoading = () => (0, reselect_1.createSelector)(getLoginGuestLoading, (loadingGuest) => loadingGuest);
exports.makeGetLoginGuestLoading = makeGetLoginGuestLoading;
const makeGetLoginReturnGuestLoading = () => (0, reselect_1.createSelector)(getLoginReturnGuestLoading, (loadingReturnGuest) => loadingReturnGuest);
exports.makeGetLoginReturnGuestLoading = makeGetLoginReturnGuestLoading;
const getLoginError = (state) => state.authentication.error;
const makeGetLoginError = () => (0, reselect_1.createSelector)(getLoginError, (error) => error);
exports.makeGetLoginError = makeGetLoginError;
const makeGetResetPasswordState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.resetPassword);
exports.makeGetResetPasswordState = makeGetResetPasswordState;
const makeGetFastCheckoutState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.fastCheckout);
exports.makeGetFastCheckoutState = makeGetFastCheckoutState;
const makeGetLoggedGuestState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.loggedGuest);
exports.makeGetLoggedGuestState = makeGetLoggedGuestState;
const makeGetErrorGuestState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.errorGuest);
exports.makeGetErrorGuestState = makeGetErrorGuestState;
const makeGetErrorReturnGuestState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.errorReturnGuest);
exports.makeGetErrorReturnGuestState = makeGetErrorReturnGuestState;
const makeGetLoggedInReturnGuestState = () => (0, reselect_1.createSelector)(authStateSelector, (authState) => authState.loggedInReturnGuest);
exports.makeGetLoggedInReturnGuestState = makeGetLoggedInReturnGuestState;
