"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeatureToggles = exports.getCurrency = exports.getFasciaFirstFeatureToggle = exports.getBusinessUnitId = exports.getBuyDelivery = exports.getServicesApiPrefixAemServices = exports.getLang = exports.getInAuthorMode = exports.getRoot = exports.getActiveEnvironment = exports.getCanonicalUrl = exports.getElasticSearchIndexWithEnv = exports.getPageInfo = exports.getMonetateUrl = exports.getCompareFeatureToggle = exports.getReviewConfiguration = exports.getProductSwatchInformation = exports.getAuthenticationConfig = exports.getDefaultRequestParams = exports.getLocale = exports.getFredhopperSuggestEndpointUrl = exports.getSearchEndpointUrl = exports.getShopLandingInfo = exports.getServicesEndpoint = void 0;
const common_types_1 = require("@as-react/common-types");
const getServicesEndpoint = (aemConfiguration) => aemConfiguration.services.api;
exports.getServicesEndpoint = getServicesEndpoint;
const getShopLandingInfo = (aemConfiguration) => ({
    isShopLanding: aemConfiguration.isShopLanding,
    shopNumber: aemConfiguration.shopNumber,
});
exports.getShopLandingInfo = getShopLandingInfo;
const getSearchEndpointUrl = (aemConfiguration) => ({
    endpoint: aemConfiguration.services.fredHopper.search,
    defaultParameters: {
        fictiveWebShop: aemConfiguration.fictiveWebShop,
        locale: aemConfiguration.lang,
        mainWebShop: aemConfiguration.mainWebShop,
        platform: aemConfiguration.isShopLanding
            ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
            : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site,
    },
    defaultListerSort: aemConfiguration.lister.sort.defaultValue,
});
exports.getSearchEndpointUrl = getSearchEndpointUrl;
const getFredhopperSuggestEndpointUrl = (aemConfiguration) => aemConfiguration.services.fredHopper.suggest;
exports.getFredhopperSuggestEndpointUrl = getFredhopperSuggestEndpointUrl;
const getLocale = (aemConfiguration) => aemConfiguration.locale;
exports.getLocale = getLocale;
const getDefaultRequestParams = (aemConfiguration) => ({
    anaLang: aemConfiguration.languageAnalytics,
    language: aemConfiguration.lang,
    mainWebshop: aemConfiguration.mainWebShop,
    market: aemConfiguration.market,
    shopId: aemConfiguration.fictiveWebShop,
});
exports.getDefaultRequestParams = getDefaultRequestParams;
const getAuthenticationConfig = (aemConfiguration) => ({
    anaLang: aemConfiguration.languageAnalytics,
    fictiveWebShop: aemConfiguration.fictiveWebShop,
    locale: aemConfiguration.lang,
    mainWebshop: aemConfiguration.mainWebShop,
    market: aemConfiguration.market,
});
exports.getAuthenticationConfig = getAuthenticationConfig;
const getProductSwatchInformation = (aemConfiguration) => aemConfiguration.product.swatch;
exports.getProductSwatchInformation = getProductSwatchInformation;
const getReviewConfiguration = (aemConfiguration) => aemConfiguration.review;
exports.getReviewConfiguration = getReviewConfiguration;
const getCompareFeatureToggle = (aemConfiguration) => aemConfiguration.feature_toggles.product_compare;
exports.getCompareFeatureToggle = getCompareFeatureToggle;
const getMonetateUrl = (aemConfiguration) => aemConfiguration.monetateUrl;
exports.getMonetateUrl = getMonetateUrl;
const getPageInfo = (aemConfiguration) => aemConfiguration.pageInfo;
exports.getPageInfo = getPageInfo;
const getElasticSearchIndexWithEnv = (aemConfiguration) => aemConfiguration.elasticsearchIndex
    ? `${aemConfiguration.activeEnvironment}_${aemConfiguration.elasticsearchIndex}`
    : 'dev_www-asadventure-com_nl';
exports.getElasticSearchIndexWithEnv = getElasticSearchIndexWithEnv;
const getCanonicalUrl = (aemConfiguration) => aemConfiguration.canonicalUrl;
exports.getCanonicalUrl = getCanonicalUrl;
const getActiveEnvironment = (aemConfiguration) => aemConfiguration.activeEnvironment;
exports.getActiveEnvironment = getActiveEnvironment;
const getRoot = (aemConfiguration) => aemConfiguration.root;
exports.getRoot = getRoot;
const getInAuthorMode = (aemConfiguration) => aemConfiguration.isInAuthorMode;
exports.getInAuthorMode = getInAuthorMode;
const getLang = (aemConfiguration) => aemConfiguration.lang;
exports.getLang = getLang;
const getServicesApiPrefixAemServices = (aemConfiguration) => aemConfiguration.servicesApiPrefixAemServices;
exports.getServicesApiPrefixAemServices = getServicesApiPrefixAemServices;
const getBuyDelivery = (aemConfiguration) => aemConfiguration.buy.delivery;
exports.getBuyDelivery = getBuyDelivery;
const getBusinessUnitId = (aemConfiguration) => aemConfiguration.businessUnitId;
exports.getBusinessUnitId = getBusinessUnitId;
const getFasciaFirstFeatureToggle = (aemConfiguration) => aemConfiguration.feature_toggles.stores_own_fascia_first;
exports.getFasciaFirstFeatureToggle = getFasciaFirstFeatureToggle;
const getCurrency = (aemConfiguration) => aemConfiguration.currency;
exports.getCurrency = getCurrency;
const getFeatureToggles = (aemConfiguration) => aemConfiguration.feature_toggles;
exports.getFeatureToggles = getFeatureToggles;
