"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlipClockSize = exports.FlipClockTheme = exports.FlipClockFace = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const React = require("react");
const react_1 = require("react");
const EventEnum_1 = require("../../../enums/EventEnum");
const EventUtil_1 = require("../../../util/EventUtil");
var FlipClockFace;
(function (FlipClockFace) {
    FlipClockFace["MINUTE_COUNTER"] = "MinuteCounter";
    FlipClockFace["DAILY_COUNTER"] = "DailyCounter";
    FlipClockFace["TWELVE_HOUR_CLOCK"] = "TwelveHourClock";
    FlipClockFace["TWENTY_FOUR_HOUR_CLOCK"] = "TwentyFourHourClock";
    FlipClockFace["COUNTER"] = "Counter";
})(FlipClockFace || (exports.FlipClockFace = FlipClockFace = {}));
var FlipClockTheme;
(function (FlipClockTheme) {
    FlipClockTheme["LIGHT"] = "light";
    FlipClockTheme["DARK"] = "dark";
})(FlipClockTheme || (exports.FlipClockTheme = FlipClockTheme = {}));
var FlipClockSize;
(function (FlipClockSize) {
    FlipClockSize["SMALL"] = "small";
    FlipClockSize["MEDIUM"] = "medium";
    FlipClockSize["LARGE"] = "large";
})(FlipClockSize || (exports.FlipClockSize = FlipClockSize = {}));
const initialState = {
    CountDownTime: 0,
};
class FlipClock extends react_1.PureComponent {
    constructor(props) {
        super(props);
        this.element$ = null;
        this.onEvent = (e) => {
            if (e && e.detail === 'flipclock') {
                this.startFlipClock();
            }
        };
        this.startFlipClock = () => {
            if (this.props.countdown) {
                this.setCountdown();
            }
            else {
                this.setCounter();
            }
        };
        this.state = initialState;
        this.flipClockRef = (0, react_1.createRef)();
    }
    componentDidMount() {
        if (dynamicImports && dynamicImports.indexOf('flipclock') >= 0) {
            this.startFlipClock();
        }
        else {
            EventUtil_1.default.listen(EventEnum_1.EventEnum.DYNAMIC_IMPORT_SUCCESS, this.onEvent);
        }
    }
    setCountdown() {
        this.initializeClock(0);
        setInterval(() => {
            const currentDate = new Date();
            const futureDate = new Date(Number(this.props.dateTime));
            let countDownTime = futureDate.getTime() / 1000 - currentDate.getTime() / 1000;
            if (countDownTime <= 1) {
                countDownTime = 0;
            }
            this.element$.setTime(countDownTime);
            this.element$.start();
        }, 1000);
    }
    setCounter() {
        this.initializeClock();
    }
    initializeClock(time) {
        const flipClockRef = this.flipClockRef.current;
        this.element$ = $(flipClockRef).FlipClock(time, Object.assign({}, this.props));
    }
    setClassNames() {
        const { theme, size } = this.props;
        return `flip-clock-wrapper--${theme} flip-clock-wrapper--${size}`;
    }
    render() {
        return (0, jsx_runtime_1.jsx)("div", { ref: this.flipClockRef, className: this.setClassNames() });
    }
}
// eslint-disable-next-line react/display-name
exports.default = React.forwardRef((props) => ((0, jsx_runtime_1.jsx)(common_components_1.AemConfigurationContext.Consumer, { children: (configuration) => {
        return (0, jsx_runtime_1.jsx)(FlipClock, Object.assign({}, props, { language: configuration.lang }));
    } })));
