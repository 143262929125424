"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAFERPAY_SCRIPT_ID = exports.andesSaferpayFieldStyles = exports.saferpayFieldStyles = void 0;
exports.saferpayFieldStyles = {
    '.form-control.is-touched.is-valid': 'border-color: #008d00;',
    '.form-control.is-touched.is-invalid': 'border-color: #e50b0b;',
    '.form-control:focus,.form-control.is-touched.is-invalid:focus': 'border-color: #151515; outline: none;',
    '.form-control:hover': 'border-color: #8a8a8a;',
    '.form-control.input-small,.form-control.input-large': 'width: calc(100% - 1px);',
};
exports.andesSaferpayFieldStyles = {
    '.form-control': `height: 44px !important; 
  background-color: #fff;
  border: 1px solid #e7e5e4;
  border-radius: .375rem;
  outline: none;`,
    '.form-control.is-touched.is-valid': 'border-color: #15803d;',
    '.form-control.is-touched.is-invalid': 'border-color: #b91c1c;',
    '.form-control:hover': 'border-color: #404040;',
};
exports.SAFERPAY_SCRIPT_ID = 'saferpay-script';
