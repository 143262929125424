"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENABLED_DELIVERY_PROMISES = exports.COUNTDOWN = void 0;
exports.COUNTDOWN = '{COUNTDOWN}';
/*
    These values are being returned from the homedelivery-promise-service:
    DELIVERED_TOMORROW = NEXT_DAY
    NAMED_DAY_DELIVERED_TOMORROW = NAMED_DAY | PICKUP_POINT | COLLECT_HQ
 */
exports.ENABLED_DELIVERY_PROMISES = ['NEXT_DAY', 'NAMED_DAY', 'PICKUP_POINT', 'COLLECT_HQ'];
