"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySearchForm = exports.initialValues = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
exports.initialValues = {
    location: '',
};
const DeliverySearchForm = ({ children, onSubmit, validate, onlyPostalCode = true, collectStore = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const keyTranslation = onlyPostalCode ? 'buy.pickup.placeholder.postcode' : 'buy.placeholder.zip.code.or.city';
    const getLocationId = () => (collectStore ? 'delivery-location-store' : 'delivery-location');
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { onSubmit: onSubmit, initialValues: exports.initialValues, validateOnBlur: false, validate: validate, autoComplete: cotopaxi_1.AutoComplete.OFF, name: "delivery-search-form", children: ({ errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [children, (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: getLocationId(), required: true, children: t(keyTranslation) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: getLocationId(), name: "location", state: errors.location && touched.location && cotopaxi_1.InputState.ERROR, adornmentEnd: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, icon: {
                            name: cotopaxi_1.IconName.MAGNIFY,
                            size: cotopaxi_1.IconSize.MEDIUM,
                        } }), Component: cotopaxi_1.Input })] })) }));
};
exports.DeliverySearchForm = DeliverySearchForm;
