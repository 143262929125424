"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalProductSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const common_utils_1 = require("@as-react/common-utils");
const react_i18next_1 = require("react-i18next");
const ProductDetailV2Container_1 = require("../../../containers/product/ProductDetailV2Container");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const AdditionalProductSelector = ({ additionalProductSelector, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.product);
    const { selectedAdditionalProducts, setSelectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const { additionalProducts } = additionalProductSelector;
    (0, react_1.useEffect)(() => {
        (additionalProducts === null || additionalProducts === void 0 ? void 0 : additionalProducts.length) > 0 && dispatch(AnalyticsActions_creators_1.analyticsActions.additionalProductSelector(additionalProducts));
    }, [additionalProducts]);
    if ((additionalProducts === null || additionalProducts === void 0 ? void 0 : additionalProducts.length) <= 0) {
        return null;
    }
    const handleSelectionChange = (_event, product) => {
        setSelectedAdditionalProducts(product);
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: 1.5, column: true, children: [additionalProductSelector.title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: additionalProductSelector.title })), additionalProducts && ((0, jsx_runtime_1.jsx)(andes_react_1.ResourceList, { items: additionalProducts, hasCheckbox: true, selectable: true, selectedItems: selectedAdditionalProducts, onSelectionChange: handleSelectionChange, renderItem: (additionalProduct, index) => {
                        if (!additionalProduct.product) {
                            return null;
                        }
                        const { product, displayedColor } = additionalProduct;
                        const { productCode, productTranslatedProperties } = product;
                        const prices = (0, common_utils_1.generateCotopaxiPriceViewModel)(displayedColor, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency);
                        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile, { variant: "horizontal", product: Object.assign(Object.assign({ image: (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, displayedColor.image, productImageConfiguration)[common_types_1.ProductImageSizesPortraitEnum.thumb] }, prices), { currency }), containerOptions: { flex: { grow: 1 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "primary", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Image, { width: 40, height: 60 }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "secondary", containerOptions: { mobile: { spacing: { marginLeft: 1 } } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: 0, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, inline: true, children: [productTranslatedProperties.brand && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, size: cotopaxi_1.TextSize.INHERIT, lineClamp: 1, children: productTranslatedProperties.brand })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Price, { emphasized: false, containerOptions: { flex: { shrink: 0 } } })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, children: [productTranslatedProperties.title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, color: cotopaxi_1.TextColor.SUBTLE, inline: true, lineClamp: 2, children: productTranslatedProperties.title })), additionalProduct.tooltip && ((0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { id: `aps-tooltip-${index}`, hasCloseBtn: false, text: additionalProduct.tooltip, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { size: cotopaxi_1.IconSize.MEDIUM, color: "N500", name: cotopaxi_1.IconName.INFORMATION_OUTLINE, inline: true }) }))] })] }) })] }));
                    } }))] }) }));
};
exports.AdditionalProductSelector = AdditionalProductSelector;
