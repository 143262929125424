"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPPCPositionTypesByMarket = void 0;
const common_types_1 = require("@as-react/common-types");
const getPPCPositionTypesByMarket = (market) => {
    switch (market) {
        case 'uk':
            return common_types_1.PPCPositionTypesUK;
        default:
            return common_types_1.PPCPositionTypes;
    }
};
exports.getPPCPositionTypesByMarket = getPPCPositionTypesByMarket;
