"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const AccountOrderHistory_1 = require("../../components/account/account-order-history/AccountOrderHistory");
const i18nConstants_1 = require("../../constants/i18nConstants");
const OrdersActions_creators_1 = require("../../redux/orders/actions/OrdersActions.creators");
const OrdersSelectors_1 = require("../../redux/orders/selectors/OrdersSelectors");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const MAX_NUMBER_OF_ITEMS_IN_LIMITED_VIEW = 3;
const AccountOrderHistoryContainer = ({ showMoreUrl, showMoreLabel, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const orders = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetOrderedItems)());
    const ordersStatus = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetOrderedItemsState)());
    const visibleOrders = (orders || []).slice(0, MAX_NUMBER_OF_ITEMS_IN_LIMITED_VIEW);
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = sessionState.authorities) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        loggedIn
            ? dispatch(OrdersActions_creators_1.ordersActions.getOrderedItemsForAuthenticatedUser())
            : dispatch(OrdersActions_creators_1.ordersActions.getOrderedItemsForGuestUser());
    }, [sessionState.authorities, loggedIn]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: 4 } }, mobileBleed: true, children: [title.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SUBTITLE, children: title.value || t('account.order.title') }) })), ordersStatus === common_types_1.FetchStatus.REJECTED && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('account.order.history.error.getOrders') }), ordersStatus === common_types_1.FetchStatus.RESOLVED && !visibleOrders.length && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('account.order.no.orders') })), ordersStatus === common_types_1.FetchStatus.RESOLVED && Boolean(visibleOrders.length) && ((0, jsx_runtime_1.jsx)(AccountOrderHistory_1.default, { orders: visibleOrders, loggedIn: loggedIn })), ((0, common_utils_1.isNullOrUndefined)(ordersStatus) || ordersStatus === common_types_1.FetchStatus.PENDING) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Spinner, { size: cotopaxi_1.SpinnerSize.LARGE }) })), !(0, common_utils_1.isNullOrUndefined)(ordersStatus) && ordersStatus !== common_types_1.FetchStatus.PENDING && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4 } }, tablet: { spacing: { marginTop: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: showMoreLabel || t('account.order.show.more'), href: showMoreUrl, fill: isMobile, variant: cotopaxi_1.ButtonVariant.OUTLINED, iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MAJOR } }) }) }))] }));
};
exports.default = AccountOrderHistoryContainer;
