"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCareProductCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductTileVertical_1 = require("../../product-tile/vertical/ProductTileVertical");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
var ExtensiveSlidesCount;
(function (ExtensiveSlidesCount) {
    ExtensiveSlidesCount["MOBILE"] = "auto";
    ExtensiveSlidesCount[ExtensiveSlidesCount["TABLET"] = 2] = "TABLET";
})(ExtensiveSlidesCount || (ExtensiveSlidesCount = {}));
var SlidesCount;
(function (SlidesCount) {
    SlidesCount[SlidesCount["MOBILE"] = 2] = "MOBILE";
    SlidesCount[SlidesCount["TABLET"] = 3] = "TABLET";
})(SlidesCount || (SlidesCount = {}));
var SlidesSpacing;
(function (SlidesSpacing) {
    SlidesSpacing[SlidesSpacing["MOBILE"] = 8] = "MOBILE";
    SlidesSpacing[SlidesSpacing["TABLET"] = 16] = "TABLET";
})(SlidesSpacing || (SlidesSpacing = {}));
const ProductCareProductCarousel = ({ addToBasket, compareItems, deliveryPromiseExplanations, getDeliveryPromise, onWishListToggle, products, productTileFormat, updateCompareList, parentId, }) => {
    const isExtensiveTile = (0, ProductUtil_1.isExtensiveProductTile)(productTileFormat);
    const carouselConfig = {
        variant: isExtensiveTile
            ? cotopaxi_1.CarouselVariant.COMPACT_PRODUCT_TILE_EXTENSIVE
            : cotopaxi_1.CarouselVariant.COMPACT_PRODUCT_TILE_NORMAL,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
            iconSize: cotopaxi_1.IconSize.MINOR,
        },
        showPagination: false,
        configurationOverrides: {
            preventClicks: true,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            slidesPerView: isExtensiveTile ? ExtensiveSlidesCount.MOBILE : SlidesCount.MOBILE,
            spaceBetween: SlidesSpacing.MOBILE,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    spaceBetween: SlidesSpacing.TABLET,
                    slidesPerView: isExtensiveTile ? ExtensiveSlidesCount.TABLET : SlidesCount.TABLET,
                },
            },
        },
    };
    const slides = products.map(product => ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { compact: true, scrollableWrapperSelector: `#${parentId} ${cotopaxi_1.POPOVER_BODY_SELECTOR} ${cotopaxi_1.SCROLLABLE_LIST_CONTAINER_SELECTOR}`, addToBasket: addToBasket, getDeliveryPromise: getDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, onWishListToggle: onWishListToggle, product: product, compareItems: compareItems, format: productTileFormat, showSwatchesOnHover: false, updateCompareList: updateCompareList, shouldLazyLoadImage: common_types_1.LazyLoadOptions.LAZY_LOAD, showProductOutOfStockOverlay: isExtensiveTile, clickAndCollectOnly: (0, ProductUtil_1.isClickAndCollectOnly)(product), inStoreOnly: (0, ProductUtil_1.isInStoreOnly)(product), dropdownProps: { appendToBody: true }, parentId: "product-care" }) }, `${product.productCode}_${product.colourId}`)));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: slides })) }));
};
exports.ProductCareProductCarousel = ProductCareProductCarousel;
