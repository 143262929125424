"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectHQContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const BuyForm_1 = require("../../../components/buy/forms/BuyForm");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const SagaContextSelectors_1 = require("../../../redux/SagaContextSelectors");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const BuyAddressFields_1 = require("../../buy/forms/buy-address-fields/BuyAddressFields");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const andes_react_1 = require("@yonderland/andes-react");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const react_router_1 = require("react-router");
const axios_1 = require("axios");
const BasketUtil_1 = require("../../../util/BasketUtil");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const CollectHQContainer = ({ option }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const root = (0, SagaContextSelectors_1.getRoot)(aemConfiguration);
    const inAuthorMode = (0, ConfigurationUtil_1.getInAuthorMode)(aemConfiguration);
    const history = (0, react_router_1.useHistory)();
    const [state, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { info } = state;
    const { postDeliveryOption, postDelivery } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: postDeliveryOptionMutation } = (0, react_query_1.useMutation)(postDeliveryOption);
    const { mutate: postDeliveryMutation, isPending, isError } = (0, react_query_1.useMutation)(postDelivery);
    if (isError) {
        dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
    }
    const onSubmit = () => {
        var _a, _b;
        const values = (0, BuyFormUtil_1.getInitialValues)(info, BuyForm_1.initialValuesBuyForm, option.name);
        SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
        const getFieldName = (field, prefix) => {
            const fieldName = field.replace(prefix, '');
            return `${fieldName.charAt(0).toLowerCase()}${fieldName.slice(1)}`;
        };
        const delivery = Object.assign({}, info);
        let invoiceAddress = {};
        if (!delivery.customerDetails) {
            delivery.customerDetails = {};
        }
        // if (pickupInformation) {
        //   delivery.pickupInformation = pickupInformation;
        // }
        if (info.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_STORE) {
            delivery.customerDetails.needInvoice = false;
        }
        delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle, phoneNumber: values.phoneNumber || '' });
        // if ((values.saveAddress && !updateDeliveryAddress) || newDeliveryAddress) {
        //   delete delivery.deliveryAddress.deliveryAddressId;
        // }
        if (delivery.deliveryDate) {
            delivery.deliveryDate = values.deliveryDate || null;
        }
        if (delivery.chosenDeliveryOption !== common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { addressLine2: values.addressLine2, addressLine3: values.addressLine3, addressLine4: values.addressLine4, addToProfile: values.saveAddress, idCountry: info.idCountry, country: info.country, customTown: values.customTown, companyName: values.companyName, address: values.address, houseNumber: values.houseNumber, houseNumberAddition: values.houseNumberAddition, postCode: values.postCode, city: values.city, county: values.county, state: values.state });
        }
        // Check if delivery address is an US address
        if (delivery.idCountry === 20) {
            delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { county: values.state, state: values.state, stateIso: values.stateIso });
        }
        if (delivery.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            delivery.customerDetails.address = Object.assign(Object.assign({}, delivery.customerDetails.address), { postCode: values.postCode, houseNumber: values.houseNumber, houseNumberAddition: values.houseNumberAddition, address: values.address, city: values.city });
        }
        if ((_b = (_a = delivery.customerDetails) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) {
            invoiceAddress = Object.assign({}, delivery.customerDetails.address);
        }
        for (const field in values) {
            if (Object.hasOwn(values, field) &&
                field.includes(BuyAddressFields_1.BuyAddressFieldsPrefix.INVOICE) &&
                values[field]) {
                invoiceAddress = Object.assign(Object.assign({}, invoiceAddress), { [getFieldName(field, BuyAddressFields_1.BuyAddressFieldsPrefix.INVOICE)]: values[field] });
            }
        }
        delivery.customerDetails.invoiceAddress = invoiceAddress;
        delivery.customerDetails.needInvoice = values.needInvoice;
        delivery.customerDetails.alternateInvoiceAddress = values.alternateInvoiceAddress;
        delivery.customerDetails.companyDepartment = values.invoiceCompanyDepartment;
        delivery.customerDetails.companyName = values.invoiceCompanyName;
        delivery.customerDetails.vatNumber = values.invoiceVatNumber;
        if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
            delivery.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle });
        }
        if (info.chosenDeliveryOption) {
            postDeliveryOptionMutation(info.chosenDeliveryOption);
        }
        postDeliveryMutation(delivery, {
            onSuccess: () => {
                history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.PAYMENT}`, inAuthorMode));
            },
            onError: error => {
                var _a;
                if ((0, axios_1.isAxiosError)(error)) {
                    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                        location.href = `${root}checkout/basket-overview.html`;
                        return;
                    }
                    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.find((item) => item.code === 'non_numeric_field')) {
                        dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.INVALID_POSTAL_CODE });
                    }
                    else {
                        if ((0, BuyFormUtil_1.isUkCountry)(delivery.country)) {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY });
                        }
                        else {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
                        }
                    }
                }
            },
        });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [state.errors[option.name] && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(state.errors[option.name]), status: "error" }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { state: isPending ? 'disabled' : undefined, text: "Continue to payment", onClick: () => onSubmit() })] }));
};
exports.CollectHQContainer = CollectHQContainer;
