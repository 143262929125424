"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPrefilledAddress = exports.getCustomerAddress = exports.getAddressStreetInfoByCountry = exports.getAddressSearchValidationSchema = exports.getAddressValidationSchema = exports.prepareAddressByCountry = exports.getHouseNumberNLPattern = exports.emptyAddress = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const Yup = require("yup");
const BuyFormUtil_1 = require("./BuyFormUtil");
const ArrayUtil_1 = require("./ArrayUtil");
exports.emptyAddress = {
    idCountry: '',
    country: '',
    postCode: '',
    city: '',
    cityFrench: undefined,
    county: '',
    houseNumber: '',
    houseNumberAddition: '',
    address: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
};
const getHouseNumberNLPattern = (value) => new RegExp('^[A-Z]{1}', 'i').test(value) ? common_types_1.DefaultSettings.HOUSE_NUMBER_NL_ALPHA : common_types_1.DefaultSettings.HOUSE_NUMBER_NL;
exports.getHouseNumberNLPattern = getHouseNumberNLPattern;
Yup.addMethod(Yup.string, 'conditionalMatches', function (regex, message) {
    return this.test({
        name: 'conditionalMatches',
        message,
        test: value => (!value ? true : regex.test(value)),
    });
});
const prepareAddressByCountry = (address, emptyValue) => {
    switch (Number(address.idCountry)) {
        case common_types_1.CountryId.NL:
        case common_types_1.CountryId.BE:
        case common_types_1.CountryId.LU:
        case common_types_1.CountryId.FR:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddress), { postCode: address.postCode || emptyValue, houseNumber: address.houseNumber || emptyValue, houseNumberAddition: address.houseNumberAddition || emptyValue, address: address.address || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.DE:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddress), { postCode: address.postCode || emptyValue, houseNumber: address.houseNumber || emptyValue, address: address.address || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.GB:
        case common_types_1.CountryId.GG:
        case common_types_1.CountryId.JE:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddress), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, addressLine3: address.addressLine3 || emptyValue, addressLine4: address.addressLine4 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, county: address.county || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.US:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddress), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, county: address.state || emptyValue, idCountry: address.idCountry, country: address.country });
        default:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddress), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
    }
};
exports.prepareAddressByCountry = prepareAddressByCountry;
const getAddressValidationSchema = ({ country, t, prefix, isOptional }) => {
    const [addressName, houseNumberName, houseNumberAdditionName, postCodeName, cityName, stateName, finderName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.STATE,
        common_types_1.AddressFieldName.FINDER,
    ], name => (0, BuyFormUtil_1.generateFieldName)(name, prefix));
    const defaultRequiredMessage = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
    const addIsOptional = (values) => (Object.assign(Object.assign({}, (0, exports.prepareAddressByCountry)(values, '')), { isOptional }));
    const checkOptional = () => ({
        is: (val) => val === true,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) => schema.required(defaultRequiredMessage),
    });
    switch (country) {
        case 'NL':
            return Yup.object({
                [postCodeName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_NL)),
                [houseNumberName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .test('checkNlHouseNumber', t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_NL), houseNumber => {
                    if (!houseNumber)
                        return true;
                    return new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber);
                }),
                [addressName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
        case 'DE':
        case 'FR':
            return Yup.object({
                [addressName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                [houseNumberName]: Yup.string().when('isOptional', checkOptional()),
                [postCodeName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_DE_FR, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE)),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
        case 'US':
            return Yup.object({
                [addressName]: Yup.string().when('isOptional', checkOptional()),
                [postCodeName]: Yup.string().when('isOptional', checkOptional()),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
                [stateName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
        case 'BE':
        case 'LU':
            return Yup.object({
                [addressName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_STREET_BE_LU)),
                [houseNumberName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_BE_LU)),
                [houseNumberAdditionName]: Yup.string().conditionalMatches(new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_ADDITION_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_ADDITION_BE_LU)),
                [postCodeName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_BE)),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
        case 'GB':
        case 'GG':
        case 'JE':
            return Yup.object({
                [finderName]: Yup.string()
                    .when([addressName, 'isOptional'], {
                    is: (addressValue, isOptionalValue) => !addressValue && !isOptionalValue,
                    then: () => Yup.string()
                        .required(defaultRequiredMessage)
                        .test('isValidUKAddress', t(common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY), () => false),
                })
                    .when([addressName, 'isOptional'], {
                    is: (addressValue, isOptionalValue) => !addressValue && isOptionalValue,
                    then: () => Yup.string().test('isValidUKAddress', t(common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY), finderValue => !finderValue),
                }),
                [addressName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                [postCodeName]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_UK)),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
        default:
            return Yup.object({
                [addressName]: Yup.string().when('isOptional', checkOptional()),
                [postCodeName]: Yup.string().when('isOptional', checkOptional()),
                [cityName]: Yup.string().when('isOptional', checkOptional()),
            }).transform(addIsOptional);
    }
};
exports.getAddressValidationSchema = getAddressValidationSchema;
const getAddressSearchValidationSchema = ({ country, t, isOptional, }) => {
    const defaultRequiredMessage = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
    const addIsOptional = (values) => ({
        postCode: values.postCode || '',
        houseNumber: values.houseNumber || '',
        isOptional,
    });
    const checkOptional = () => ({
        is: (val) => val === true,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) => schema.required(defaultRequiredMessage),
    });
    switch (country) {
        case 'NL': {
            return Yup.object({
                [common_types_1.AddressFieldName.POSTCODE]: Yup.string()
                    .when('isOptional', checkOptional())
                    .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_NL)),
                [common_types_1.AddressFieldName.HOUSE_NUMBER]: Yup.string()
                    .when('isOptional', checkOptional())
                    .test('checkNlHouseNumber', t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_NL), houseNumber => {
                    if (!houseNumber)
                        return true;
                    return new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber);
                }),
            }).transform(addIsOptional);
        }
        default:
            return Yup.object({
                [common_types_1.AddressFieldName.POSTCODE]: Yup.string(),
                [common_types_1.AddressFieldName.HOUSE_NUMBER]: Yup.string(),
            });
    }
};
exports.getAddressSearchValidationSchema = getAddressSearchValidationSchema;
const getAddressThirdLineByCountry = (address, country) => {
    if (!address.city && !address.postCode) {
        return null;
    }
    switch (address.country) {
        case 'AU':
        case 'NZ':
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [address.city, " ", address.postCode, ", ", country] }));
        case 'GB':
        case 'GG':
        case 'JE':
            return [address.city, address.county, address.postCode, country].filter(Boolean).map((item, index, arr) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [item, index !== arr.length - 1 && (0, jsx_runtime_1.jsx)("br", {})] }, item.replace(/ /g, ''))));
        case 'US':
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [address.city, ", ", address.county, " ", address.postCode, ", ", country] }));
        default:
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [address.postCode, " ", address.city, ", ", country] }));
    }
};
const getAddressStreetInfoByCountry = (address, t) => {
    const houseNumberAddition = address &&
        !!address.houseNumberAddition &&
        `${t(address.idCountry === common_types_1.CountryId.NL ? '' : 'account:delivery.bus')} ${address.houseNumberAddition}`;
    const streetInfoComponents = [
        address.address,
        address.houseNumber,
        address.addressLine2,
        address.addressLine3,
        address.addressLine4,
        houseNumberAddition,
    ].filter(Boolean);
    switch (address.country) {
        case 'GB':
        case 'GG':
        case 'JE':
            return streetInfoComponents.map((street, index, arr) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [street, index !== arr.length - 1 && (0, jsx_runtime_1.jsx)("br", {})] }, street.replace(/ /g, ''))));
        default: {
            return streetInfoComponents.join(' ');
        }
    }
};
exports.getAddressStreetInfoByCountry = getAddressStreetInfoByCountry;
const getCustomerAddress = (address, country, t) => {
    const streetInfo = (0, exports.getAddressStreetInfoByCountry)(address, t);
    const cityInfo = getAddressThirdLineByCountry(address, country);
    if (streetInfo && cityInfo) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [streetInfo, (0, jsx_runtime_1.jsx)("br", {}), cityInfo] }));
    }
    if (streetInfo) {
        return streetInfo;
    }
    if (cityInfo) {
        return cityInfo;
    }
    return null;
};
exports.getCustomerAddress = getCustomerAddress;
const hasPrefilledAddress = (address = {}) => {
    const fieldsName = [
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.ADDRESS_LINE_3,
        common_types_1.AddressFieldName.ADDRESS_LINE_4,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.COUNTY,
    ];
    return Object.entries(address)
        .filter(([key]) => fieldsName.includes(key))
        .some(([key, value]) => Boolean(value));
};
exports.hasPrefilledAddress = hasPrefilledAddress;
