"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationBasketBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const NavigationBasketBadge = () => {
    const basketCount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    if (!basketCount) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { count: basketCount });
};
exports.NavigationBasketBadge = NavigationBasketBadge;
