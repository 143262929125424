"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExtendedRangeIcon = exports.getDiscountIconContent = exports.generateSwatchFromProductColorVariation = exports.generateSwatchFromProductColor = void 0;
const ImageUtil_1 = require("./ImageUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const generateSwatchFromProductColor = (productColor, selectedProductColor, productCode, productCdnDomains) => ({
    colourId: Number(productColor.colourId),
    color: productColor.description,
    extendedRange: productColor.extendedRange,
    swatch: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, productColor.swatch, productCdnDomains),
    selected: selectedProductColor && Number(selectedProductColor.colourId) === Number(productColor.colourId),
    hasDiscount: productColor.hasDiscount,
    hasPermanentDiscount: productColor.markdown,
});
exports.generateSwatchFromProductColor = generateSwatchFromProductColor;
const generateSwatchFromProductColorVariation = (productColorVariation, selectedColorId, productCode, productCdnDomains) => ({
    colourId: productColorVariation.colorId,
    color: productColorVariation.translations.description,
    extendedRange: productColorVariation.webOnly,
    swatch: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, productColorVariation.swatch, productCdnDomains),
    selected: selectedColorId === productColorVariation.colorId,
    hasDiscount: Boolean(productColorVariation.hasDiscount),
    hasPermanentDiscount: Boolean(productColorVariation.markdown),
    outOfStock: productColorVariation.availability === 0,
});
exports.generateSwatchFromProductColorVariation = generateSwatchFromProductColorVariation;
const getDiscountIconContent = (productSwatch, swatchInformation) => {
    let iconContent = '';
    if (productSwatch.hasDiscount) {
        iconContent = swatchInformation.actionLabel;
        if (productSwatch.hasPermanentDiscount || productSwatch.markdown) {
            iconContent = swatchInformation.saleLabel;
        }
    }
    return iconContent;
};
exports.getDiscountIconContent = getDiscountIconContent;
const getExtendedRangeIcon = (isShopLanding, extendedRange) => isShopLanding && extendedRange ? cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID : undefined;
exports.getExtendedRangeIcon = getExtendedRangeIcon;
