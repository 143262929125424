"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPoint = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointUtil_1 = require("../../../../util/PickupPointUtil");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const NumberUtil_1 = require("../../../../util/NumberUtil");
const andes_icons_1 = require("@yonderland/andes-icons");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const POINT_DAY_OF_WEEK_PREFIX = 'buy:buy.pickup.dayofweek.';
const PickupPoint = ({ pickupPoint, selected }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const market = (0, ConfigurationUtil_1.getMarket)(aemConfiguration);
    const [, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { address, city, distance, houseNumber, locationName, locationType, openingHours, organisationName, postcode, sanitizedLocationType, } = pickupPoint;
    const organisationNameWithType = `${organisationName}${PickupPointUtil_1.default.getTranslation(sanitizedLocationType, locationType, t)}`;
    const fullAdress = `${organisationNameWithType.trim()}, ${address}${houseNumber ? ` ${houseNumber}` : ''}, ${postcode} ${city}`;
    const isAlwaysOpen = PickupPointUtil_1.default.isAlwaysOpen(sanitizedLocationType || locationType);
    const getDistance = (market, distance) => (market === 'uk' ? distance : (0, NumberUtil_1.round)(distance / 1000, 0.1));
    const onClick = () => {
        dispatch({ type: 'highlight_pickup_point', payload: pickupPoint });
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { state: selected ? 'active' : 'resting', onClick: onClick, children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: locationName }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "tag", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", strong: true, color: "subdued", children: `${getDistance(market, distance)} ${t(market === 'uk'
                        ? 'checkout:checkout.delivery.pickuppoint.flyout.distance.unit.miles'
                        : 'checkout:checkout.delivery.pickuppoint.flyout.distance.unit.km')}` }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.MapPinIcon, size: "xs", color: "subdued" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: fullAdress })] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: !!openingHours.length && ((0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: isAlwaysOpen && selected ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.ClockIcon, size: "xs", color: "subdued" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t('buy:buy.delivery.pickuppoint.open.24') })] })) : (selected && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingInlineStart: "6", children: openingHours.map(openingHour => ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 2, gapX: "10", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", variant: "bodyS", children: t(`${POINT_DAY_OF_WEEK_PREFIX}${openingHour.dayOfWeek.toLowerCase()}`) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "subdued", variant: "bodyS", children: [openingHour.openingTime, " - ", openingHour.closingTime] }) })] }, openingHour.dayOfWeek))) }))) })) })] }));
};
exports.PickupPoint = PickupPoint;
