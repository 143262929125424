"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const awesome_debounce_promise_1 = require("awesome-debounce-promise");
const Yup = require("yup");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const EmailChangeHandler_1 = require("../../email/EmailChangeHandler");
const EmailError_1 = require("../../email/EmailError");
const EmailValidationUtil_1 = require("../../email/EmailValidationUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const DpeAuthForm_1 = require("../dpe-auth-form/DpeAuthForm");
const DpeAuth = ({ config, step, data, onStepUpdate, onLogin, onValidationError, loginState, }) => {
    const [responseErrorMessage, setResponseErrorMessage] = (0, react_1.useState)(null);
    const [responseSuccessMessage, setResponseSuccessMessage] = (0, react_1.useState)(null);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [customerTitles, setCustomerTitles] = (0, react_1.useState)([]);
    const [customerGenders, setCustomerGenders] = (0, react_1.useState)([]);
    const [emailFieldValue, setEmailFieldValue] = (0, react_1.useState)();
    const [suggestedEmail, setSuggestedEmail] = (0, react_1.useState)();
    const [skipEmailValidation, setSkipEmailValidation] = (0, react_1.useState)(false);
    const [emailStatus, setEmailStatus] = (0, react_1.useState)();
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.buy,
        i18nConstants_1.I18N_NAMESPACES.dpe,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const setFormStep = (to) => {
        if (to !== step) {
            onStepUpdate({
                from: step,
                to,
            });
            setResponseErrorMessage(null);
            setResponseSuccessMessage(null);
        }
    };
    const emailExists = (email) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield CustomerApi_1.default.emailExists(config.apiPrefix, config.defaultRequestParameters, email, false);
            return response.knownForFascia;
        }
        catch (_a) {
            return false;
        }
    });
    const handleLoginSubmit = (values) => {
        onLogin({
            username: values.email,
            password: values.password,
            keepSignedIn: true,
        });
    };
    const getTitlesFromApi = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const response = yield CustomerApi_1.default.getTitles(config.apiPrefix, config.defaultRequestParameters, config.lang);
        const sortedTitles = response.sort((t1, t2) => t1.sortOrder < t2.sortOrder ? -1 : 1);
        setCustomerTitles(sortedTitles);
    });
    const getGendersFromApi = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const response = yield CustomerApi_1.default.getGenders(config.apiPrefix, config.defaultRequestParameters, config.lang);
        setCustomerGenders(response);
    });
    const handleRegistrationSubmit = (values) => {
        const { email, password, title, firstName, lastName, gender, mailSubscribe, emailSubscribe } = values;
        setLoading(true);
        CustomerApi_1.default.create(config.apiPrefix, config.defaultRequestParameters, {
            email,
            firstName,
            gender,
            idTitle: Number(title),
            language: config.lang,
            lastName,
            password,
            preference: {
                preference: !!mailSubscribe,
                exploreMoreNewsletter: !!emailSubscribe,
            },
        })
            .then(() => onLogin({
            username: email,
            password,
            keepSignedIn: true,
        }))
            .catch(e => {
            var _a;
            const errorMessage = ((_a = e.response.data[0]) === null || _a === void 0 ? void 0 : _a.field) === 'password'
                ? `${t('password.indicator.strength.label')}${t('password.indicator.strength.short')}`
                : t(common_types_1.ErrorMessage.GENERAL_VITALITY);
            onValidationError({
                registration_api: errorMessage,
            });
            setResponseErrorMessage(errorMessage);
        })
            .then(() => {
            setLoading(false);
        });
    };
    const handlePasswordForgottenSubmit = (values) => {
        setLoading(true);
        CustomerApi_1.default.sendPasswordResetMail(config.apiPrefix, config.defaultRequestParameters, values.email)
            .then(() => {
            setFormStep(common_types_1.AuthFormStep.LOGIN);
            setResponseSuccessMessage(t('navigation:flyout.account.reset.password.success.operation'));
        })
            .catch(() => {
            onValidationError({
                passwordForgotton_api: t(common_types_1.ErrorMessage.GENERAL_VITALITY),
            });
            setResponseErrorMessage(t(common_types_1.ErrorMessage.GENERAL_VITALITY));
        })
            .then(() => {
            setLoading(false);
        });
    };
    const validateEmailField = (0, react_1.useCallback)((0, awesome_debounce_promise_1.default)((email, context) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (skipEmailValidation) {
            return true;
        }
        const isRegistrationStep = step === common_types_1.AuthFormStep.REGISTRATION;
        if (!email) {
            return context.createError({
                message: isRegistrationStep ? common_types_1.EmailValidationResponseStatus.REQUIRED : t('account:delivery.required'),
            });
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(email)) {
            return context.createError({
                message: isRegistrationStep
                    ? common_types_1.EmailValidationResponseStatus.INVALID
                    : t('navigation:flyout.account.error.email'),
            });
        }
        else if (step === common_types_1.AuthFormStep.PASSWORD_FORGOTTEN) {
            return true;
        }
        else {
            const isCustomerEmailExist = yield emailExists(email);
            if (isCustomerEmailExist) {
                setFormStep(common_types_1.AuthFormStep.LOGIN);
                return true;
            }
            else {
                setFormStep(common_types_1.AuthFormStep.REGISTRATION);
                return yield (0, EmailValidationUtil_1.validateEmailWithBackend)(email, context, config.apiPrefix, emailStatus, setSuggestedEmail, setEmailStatus);
            }
        }
    }), EmailValidationUtil_1.EMAIL_VALIDATION_DEBOUNCE), [skipEmailValidation, emailStatus, step]);
    const onEmailChange = (email) => {
        setEmailFieldValue(email);
        skipEmailValidation && setSkipEmailValidation(false);
    };
    const onIgnoreSuggestedEmailClick = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setSkipEmailValidation(true);
        const isCustomerEmailExist = emailFieldValue ? yield emailExists(emailFieldValue) : false;
        setFormStep(isCustomerEmailExist ? common_types_1.AuthFormStep.LOGIN : common_types_1.AuthFormStep.REGISTRATION);
    });
    const emailValidationSchema = Yup.object().shape({
        email: Yup.string().test(validateEmailField),
    });
    const loginValidationSchema = Yup.object().shape({
        password: Yup.string().required(t('account:delivery.required')),
    });
    const registrationValidationSchema = Yup.object().shape({
        title: Yup.string().required(t('account:delivery.required')),
        firstName: Yup.string().required(t('account:delivery.required')),
        lastName: Yup.string().required(t('account:delivery.required')),
        gender: Yup.string().required(t('account:delivery.required')),
    });
    const validationSchemas = {
        [common_types_1.AuthFormStep.EMAIL]: emailValidationSchema,
        [common_types_1.AuthFormStep.PASSWORD_FORGOTTEN]: emailValidationSchema,
        [common_types_1.AuthFormStep.LOGIN]: emailValidationSchema.concat(loginValidationSchema),
        [common_types_1.AuthFormStep.REGISTRATION]: emailValidationSchema
            .concat(loginValidationSchema)
            .concat(registrationValidationSchema),
    };
    const handleSubmit = (values) => {
        switch (step) {
            case common_types_1.AuthFormStep.LOGIN:
                return handleLoginSubmit(values);
            case common_types_1.AuthFormStep.REGISTRATION:
                return handleRegistrationSubmit(values);
            case common_types_1.AuthFormStep.PASSWORD_FORGOTTEN:
                return handlePasswordForgottenSubmit(values);
            default:
                break;
        }
    };
    const dpeAuthMessages = () => {
        switch (step) {
            case common_types_1.AuthFormStep.LOGIN:
                return data.loginMessage;
            case common_types_1.AuthFormStep.REGISTRATION:
                return data.registrationMessage;
            case common_types_1.AuthFormStep.PASSWORD_FORGOTTEN:
                return data.passwordForgottenMessage;
            case common_types_1.AuthFormStep.EMAIL:
            default:
                return data.introductionMessage;
        }
    };
    const handleLinkClick = () => {
        switch (step) {
            case common_types_1.AuthFormStep.LOGIN:
                return setFormStep(common_types_1.AuthFormStep.PASSWORD_FORGOTTEN);
            case common_types_1.AuthFormStep.PASSWORD_FORGOTTEN:
            default:
                return setFormStep(common_types_1.AuthFormStep.LOGIN);
        }
    };
    const handleAcceptClick = () => {
        setEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
        setEmailFieldValue(suggestedEmail);
    };
    (0, react_1.useEffect)(() => {
        if (step === common_types_1.AuthFormStep.LOGIN) {
            if (loginState && loginState.error !== responseErrorMessage) {
                setResponseErrorMessage(t(loginState.error));
            }
            if (loginState && loginState.loading !== loading) {
                setLoading(loginState.loading);
            }
        }
    }, [step, loginState]);
    (0, react_1.useEffect)(() => {
        getTitlesFromApi();
        getGendersFromApi();
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!responseSuccessMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingBottom: step === common_types_1.AuthFormStep.PASSWORD_FORGOTTEN ? 4 : 3,
                    },
                }, tablet: {
                    spacing: {
                        paddingBottom: step === common_types_1.AuthFormStep.REGISTRATION ? 4 : 2,
                    },
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: dpeAuthMessages() }) }) })), responseErrorMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2, paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: responseErrorMessage, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), responseSuccessMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2, paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: responseSuccessMessage, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }) })), (0, jsx_runtime_1.jsx)(DpeAuthForm_1.DpeAuthForm, { onSubmit: handleSubmit, validationSchema: validationSchemas[step], step: step, onLinkClick: handleLinkClick, customerGenders: customerGenders, customerTitles: customerTitles, loading: loading, onValidationError: onValidationError, emailErrorRender: msg => ((0, jsx_runtime_1.jsx)(EmailError_1.EmailError, { emailStatus: msg, suggestedEmail: suggestedEmail, requiredErrorLabel: t('account:delivery.required'), onAcceptClick: handleAcceptClick, onIgnoreClick: onIgnoreSuggestedEmailClick })), children: (0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "email", emailValue: emailFieldValue, skipEmailValidation: skipEmailValidation, onEmailChange: onEmailChange, setEmailStatus: setEmailStatus }) })] }));
};
exports.default = DpeAuth;
