"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HorizontalList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const list_utils_1 = require("./list.utils");
const ListItemContent_1 = require("./ListItemContent");
const HorizontalList = ({ items, divider, dividerSize, position }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { wrap: cotopaxi_1.GroupWrap.WRAP, align: list_utils_1.mapGroupAlign[position], valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: divider ? cotopaxi_1.GroupGutter.MEDIUM : cotopaxi_1.GroupGutter.NONE, children: items.map((item, key) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ListItemContent_1.ListItemContent, { item: item }), divider && key < items.length - 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, size: list_utils_1.mapListItemTextSize[dividerSize], children: divider === 'pipe' ? '|' : '/' }))] }, `list-item-${key}`))) }));
exports.HorizontalList = HorizontalList;
