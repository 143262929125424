"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.brandListerActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.brandListerActions = {
    createSetFilterAction: (payload) => ({
        type: ActionEnums_1.BrandListerActionTypes.SET_FILTER,
        payload,
    }),
};
