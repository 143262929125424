"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const MenuUtil_1 = require("../../util/MenuUtil");
const CurrencyMenuItem = ({ currencies, isDesktop }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const currentCurrency = currencies.find(curr => curr.active === true) || currencies[0];
    const closeAll = () => {
        MenuUtil_1.MenuUtil.close();
        handlePopoverClose();
    };
    if (!currentCurrency)
        return null;
    const currencyButton = (currency, active) => ({
        active,
        key: currency.name,
        href: currency.homepage,
        text: (0, jsx_runtime_1.jsx)(cotopaxi_1.CurrencyItem, { name: currency.name, flag: currency.flag, currency: currency.currency }),
    });
    const getCurrencyButtonList = currencies.reduce((acc, currency) => {
        if (currentCurrency.name !== currency.name) {
            acc.push(currencyButton(currency, false));
        }
        return acc;
    }, [currencyButton(currentCurrency, true)]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [isDesktop ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginRight: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK_SUBTLE, size: cotopaxi_1.ButtonSize.SMALL, iconRight: {
                            name: anchorEl ? cotopaxi_1.IconName.CHEVRON_UP : cotopaxi_1.IconName.CHEVRON_DOWN,
                            color: cotopaxi_1.IconColor.ACTION,
                            size: cotopaxi_1.IconSize.MINOR,
                        }, onClick: handlePopoverOpen, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: currentCurrency.flag }) }) }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { buttons: [
                        {
                            text: (0, jsx_runtime_1.jsx)(cotopaxi_1.CurrencyItem, { name: currentCurrency.name, flag: currentCurrency.flag }),
                            iconRight: {
                                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                color: cotopaxi_1.IconColor.ACTION,
                            },
                            onClick: handlePopoverOpen,
                            noBubble: true,
                        },
                    ] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { analyticsAreaName: "header-internationalisation", noPadding: true, anchorEl: anchorEl, onClose: handlePopoverClose, position: cotopaxi_1.PopoverPosition.BOTTOM_LEFT, showTitleOnDesktop: false, title: t('flyout.bottom.currency'), leftElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                        name: cotopaxi_1.IconName.ARROW_LEFT,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: closeAll, icon: {
                        name: cotopaxi_1.IconName.CLOSE,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { buttons: getCurrencyButtonList }) })] }));
};
exports.default = CurrencyMenuItem;
