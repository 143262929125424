"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySelectedLocation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DeliverySelectedLocation = ({ locationName, addressContent, openingHoursContent, additionalContent, handleEdit, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet", padding: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", contentAlign: "between", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", color: "neutral", children: locationName }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('checkout:checkout.delivery.change.location'), onClick: handleEdit }) })] }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.MapPinIcon, size: "xs", color: "subdued" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: addressContent })] }), openingHoursContent && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.ClockIcon, size: "xs", color: "subdued" }) }), openingHoursContent] })), additionalContent] }) }));
};
exports.DeliverySelectedLocation = DeliverySelectedLocation;
