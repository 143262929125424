"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewDynamicMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const DYNAMIC_MESSAGES_TEXT_VARIABLE = '[difference]';
const DYNAMIC_MESSAGES_PRICE_CONSTANT = 0.01;
const BasketOverviewDynamicMessage = ({ dynamicMessages, messageBoxSpacing, pricing, products, vouchers, }) => {
    if (!dynamicMessages)
        return null;
    const getTotalPriceForDynamicMessages = (messages) => {
        let vouchersPrice = 0;
        if (messages.ignoreGiftOrEvouchers) {
            products.map((product) => {
                if (product.productType === common_types_1.ProductTypes.E_VOUCHER ||
                    product.productType === common_types_1.ProductTypes.CUSTOM_PAPER_VOUCHER) {
                    vouchersPrice +=
                        messages.calculateBy === common_types_1.DinamicMessagesCalculateTypes.TOTAL
                            ? product.totalPrice
                            : product.totalPreviousPrice;
                }
            });
        }
        let totalPriceForDynamicMessages = messages.calculateBy === common_types_1.DinamicMessagesCalculateTypes.TOTAL
            ? pricing.priceTotalBasket
            : pricing.totalPriceSellBeforeDiscount;
        if (messages.calculateBy === common_types_1.DinamicMessagesCalculateTypes.SUBTOTAL &&
            messages.includeVouchers &&
            vouchers.length) {
            vouchers.map((voucher) => {
                totalPriceForDynamicMessages -= voucher.amountUsedForThisBasket;
            });
        }
        if (messages.calculateBy === common_types_1.DinamicMessagesCalculateTypes.TOTAL && !messages.includeVouchers && vouchers.length) {
            vouchers.map((voucher) => {
                totalPriceForDynamicMessages += voucher.amountUsedForThisBasket;
            });
        }
        return totalPriceForDynamicMessages - vouchersPrice;
    };
    const getDynamicMessage = (totalPriceForDynamicMessages) => {
        if (!dynamicMessages.messages.length) {
            return null;
        }
        const dynamicMessage = dynamicMessages.messages.find((dynamicMessageItem) => totalPriceForDynamicMessages <= dynamicMessageItem.maximumValue &&
            totalPriceForDynamicMessages >= dynamicMessageItem.minimumValue);
        if (!dynamicMessage) {
            return null;
        }
        const currentDate = new Date();
        if (dynamicMessage.stopDate &&
            dynamicMessage.startDate &&
            currentDate < new Date(Date.parse(dynamicMessage.stopDate)) &&
            currentDate > new Date(Date.parse(dynamicMessage.startDate))) {
            return dynamicMessage;
        }
        return null;
    };
    const totalPriceForDynamicMessages = getTotalPriceForDynamicMessages(dynamicMessages);
    const dynamicMessageItem = getDynamicMessage(totalPriceForDynamicMessages);
    if ((0, common_utils_1.isNullOrUndefined)(dynamicMessageItem))
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MEDIUM, text: dynamicMessageItem.message.replace(DYNAMIC_MESSAGES_TEXT_VARIABLE, `${(0, common_utils_1.getRoundedPrice)(dynamicMessageItem.maximumValue - totalPriceForDynamicMessages + DYNAMIC_MESSAGES_PRICE_CONSTANT)}`) }) })));
};
exports.BasketOverviewDynamicMessage = BasketOverviewDynamicMessage;
