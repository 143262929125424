"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const axios_1 = require("axios");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const DeliveryPromise_1 = require("../delivery-promise/DeliveryPromise");
const ClosenessQualifier_1 = require("../closeness-qualifier/ClosenessQualifier");
const ProceedCheckoutCTA_1 = require("../proceed-checkout-cta/ProceedCheckoutCTA");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const asNumeral_1 = require("../../../vendors/asNumeral");
const VoucherUtil_1 = require("../../../util/VoucherUtil");
const BasketCouponForm_1 = require("../../basket-coupon-form/BasketCouponForm");
const CartTotalTable_1 = require("../cart-total-table/CartTotalTable");
const ApplePayButton_1 = require("../../apple-pay-button/ApplePayButton");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const useIsInViewport_1 = require("../../../hooks/useIsInViewport");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const CartTotalBlock = ({ vouchers, validateRegex, defaultRequestParams, servicesEndpoint, pricing: { priceDelivery, priceTotalBasket, totalBasketDiscountAmount, totalPriceSell, totalPriceSellBeforeDiscount }, productsAmount, currency, totalQuantity, totalMiniBlock, deliveryInfo, delivery_promise_standard_delivery_label, isShoplanding, orderLinesHaveDifferentDeliveryPromises, getProducts, onAnalyticsFormsError, checkoutInit, freeDeliveryMessage, showDeliveryPromise, scrollToStoreSourceExcludedProduct, isCheckoutFlowDisabled, storeName, isApplePayAvailable, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [isOpenCollapse, setOpenCollapse] = (0, react_1.useState)(() => {
        if (isDesktop) {
            return !(totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.isVoucherCodeCollapsedOnDesktop);
        }
        return !(totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.isVoucherCodeCollapsedOnMobileTablet);
    });
    const [isShowWebCode, setShowWebCode] = (0, react_1.useState)(false);
    const [terms, setTerms] = (0, react_1.useState)('');
    const dispatch = (0, react_redux_1.useDispatch)();
    const userEmail = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUserEmail)());
    const clickAndCollectStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const totalVoucherAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalVoucherAmountState)());
    const onApplePayButtonClick = () => dispatch(AnalyticsActions_creators_1.analyticsActions.applePayClick());
    const hasAndOnlyDigitalProduct = products.every(p => p.productType === common_types_1.ProductTypes.E_VOUCHER);
    const applePayButtonRef = (0, react_1.useRef)(null);
    const [applePayAnalyticsSent, setApplePayAnalyticsSent] = (0, react_1.useState)(false);
    const [scrollPositionChanged, setScrollPositionChanged] = (0, react_1.useState)(false);
    const isInViewport = (0, useIsInViewport_1.useIsInViewport)(applePayButtonRef);
    (0, react_1.useEffect)(() => {
        const analyticsData = { componentInfo: { componentName: 'apple pay', componentType: 'payment button' } };
        if (isInViewport && !applePayAnalyticsSent) {
            if (!scrollPositionChanged) {
                dispatch(AnalyticsActions_creators_1.analyticsActions.applePayButtonInitialInViewport(analyticsData));
            }
            else {
                dispatch(AnalyticsActions_creators_1.analyticsActions.applePayButtonImpression(analyticsData));
            }
            setApplePayAnalyticsSent(true);
        }
    }, [isInViewport, applePayAnalyticsSent]);
    (0, cotopaxi_1.useScrollPosition)(() => setScrollPositionChanged(true), [scrollPositionChanged]);
    (0, react_1.useEffect)(() => {
        if (totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.termsUrl) {
            const getTerms = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                const axiosConfig = {
                    method: 'GET',
                    url: totalMiniBlock.termsUrl,
                    withCredentials: true,
                };
                try {
                    const answer = yield axios_1.default.request(axiosConfig);
                    setTerms(answer.data);
                }
                catch (_a) { }
            });
            getTerms();
        }
    }, []);
    const handleSubmit = (_a, _b) => tslib_1.__awaiter(void 0, [_a, _b], void 0, function* ({ code, webcode }, { setFieldError, resetForm }) {
        var _c, _d, _e, _f;
        try {
            yield BasketAPI_1.default.postVoucherCode(servicesEndpoint, defaultRequestParams, code, webcode);
            resetForm();
            getProducts();
            setOpenCollapse(false);
        }
        catch (error) {
            if ((0, axios_1.isAxiosError)(error)) {
                if ((_c = error.response.data) === null || _c === void 0 ? void 0 : _c.message) {
                    if (!isShowWebCode) {
                        setFieldError('code', (_d = error.response.data) === null || _d === void 0 ? void 0 : _d.message);
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
                    }
                    else {
                        setFieldError('webcode', (_e = error.response.data) === null || _e === void 0 ? void 0 : _e.message);
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
                    }
                    onAnalyticsFormsError({
                        voucher_code: (_f = error.response.data) === null || _f === void 0 ? void 0 : _f.message,
                    });
                }
            }
        }
    });
    const handleDeleteVoucher = (barcode) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield BasketAPI_1.default.deleteVoucherCode(servicesEndpoint, defaultRequestParams, barcode);
            getProducts();
        }
        catch (_g) { }
    });
    const handleValidate = (values) => {
        const errors = {};
        if (!values.code) {
            errors.code = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.barcode.errormessage`);
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_CODE_ID), isDesktop);
        }
        if (!values.webcode && isShowWebCode) {
            errors.webcode = t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.webcode.errormessage`);
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BasketCouponForm_1.BASKET_COUPON_WEBCODE_ID), isDesktop);
        }
        if (Object.keys(errors).length) {
            onAnalyticsFormsError(errors);
        }
        return errors;
    };
    const handleCodeChange = (code) => {
        if (!validateRegex) {
            return;
        }
        const isValidCode = new RegExp(validateRegex).test(code);
        if (isValidCode && !isShowWebCode) {
            setShowWebCode(true);
        }
        if (!isValidCode && isShowWebCode) {
            setShowWebCode(false);
        }
    };
    const changeOpenCollapse = (event) => setOpenCollapse(event.target.checked);
    const handleChangeStore = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.showStoreStock());
    };
    const titleCollapse = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.25 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.GIFT, size: cotopaxi_1.IconSize.MINOR }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1, marginRight: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.subtitle`) }) })] }));
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.CheckoutLayoutTotalBlock, { children: [freeDeliveryMessage && deliveryInfo.amountUntilFreeDelivery && priceDelivery > 0 && ((0, jsx_runtime_1.jsx)(ClosenessQualifier_1.default, { showProgressBar: true, freeDeliveryMessage: freeDeliveryMessage, totalPriceSell: totalPriceSell, amountUntilFreeDelivery: deliveryInfo.amountUntilFreeDelivery, currency: currency })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingTop: 3,
                        paddingBottom: 3,
                    },
                }, desktop: {
                    spacing: {
                        paddingTop: 4,
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.title`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, tablet: { spacing: { marginBottom: 3, marginTop: 3 } }, children: !isShoplanding && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { title: titleCollapse, hasDivider: false, handleChangeChecked: changeOpenCollapse, iconAlignmentVertical: cotopaxi_1.CollapseIconAlignmentVertical.START, labelGutterVertical: cotopaxi_1.LabelGutter.NONE, open: isOpenCollapse, id: "evoucher-collapse", dataQA: "evoucher_toggle", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: { marginTop: 2, marginBottom: 4 },
                                }, children: (0, jsx_runtime_1.jsx)(BasketCouponForm_1.BasketCouponForm, { isShowWebCode: isShowWebCode, onSubmit: handleSubmit, validate: handleValidate, onCodeChange: handleCodeChange }) }) })) }), (0, jsx_runtime_1.jsx)(CartTotalTable_1.CartTotalTable, { currency: currency, totalBasketDiscountAmount: totalBasketDiscountAmount && totalPriceSellBeforeDiscount
                            ? asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalBasketDiscountAmount)
                            : '0', totalPriceSell: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPriceSellBeforeDiscount || totalPriceSell), priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(priceTotalBasket), priceDelivery: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(priceDelivery), totalQuantity: totalQuantity, vouchers: VoucherUtil_1.default.mapBasketAppliedVouchersToAppliedVouchers(vouchers, currency, t), deleteVoucher: handleDeleteVoucher, hideRemoveVoucherButton: false })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, tabletBleed: true, mobile: {
                    spacing: {
                        paddingTop: 2,
                        paddingBottom: 3,
                    },
                    theme: cotopaxi_1.BoxTheme.PRIMARY,
                }, tablet: {
                    spacing: { paddingTop: 3 },
                }, children: [showDeliveryPromise && ((0, jsx_runtime_1.jsx)(DeliveryPromise_1.default, { deliveryInfo: deliveryInfo, deliveryTimeText: totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.deliveryTimeText, delivery_promise_standard_delivery_label: delivery_promise_standard_delivery_label, orderLinesHaveDifferentDeliveryPromises: orderLinesHaveDifferentDeliveryPromises, deliveryStoreName: storeName, handleChangeStore: handleChangeStore, isCheckoutFlowDisabled: isCheckoutFlowDisabled })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } }, tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, desktop: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: [(0, jsx_runtime_1.jsx)(ProceedCheckoutCTA_1.default, { checkoutInit: checkoutInit, isOpenedCollapseBlock: isOpenCollapse, productsAmount: productsAmount, scrollToStoreSourceExcludedProduct: scrollToStoreSourceExcludedProduct, isCheckoutFlowDisabled: isCheckoutFlowDisabled, isApplePayAvailable: isApplePayAvailable }), isApplePayAvailable && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { align: cotopaxi_1.TextAlign.CENTER, color: cotopaxi_1.TextColor.SUBTLE, children: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.check.popup.or`) }), (0, jsx_runtime_1.jsx)(ApplePayButton_1.ApplePayButton, { totalPrices: {
                                            totalBasketDiscountAmount: totalBasketDiscountAmount && totalPriceSellBeforeDiscount
                                                ? totalBasketDiscountAmount.toString()
                                                : '0',
                                            totalPriceSell: (totalPriceSellBeforeDiscount || totalPriceSell).toString(),
                                            priceTotalBasket: priceTotalBasket.toString(),
                                            priceDelivery: priceDelivery.toString(),
                                        }, totalVoucherAmount: totalVoucherAmount, onClick: onApplePayButtonClick, hasAndOnlyDigitalProduct: hasAndOnlyDigitalProduct, userEmail: userEmail, clickAndCollectStoreId: clickAndCollectStoreId, storeName: storeName, innerRef: applePayButtonRef })] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.SUB_CONTENT, color: cotopaxi_1.TextColor.SUBTLE, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: terms }) })] })] })] }));
};
exports.default = CartTotalBlock;
