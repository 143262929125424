"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductGroup_1 = require("../../components/product-group/ProductGroup");
const ProductGroupFredhopperSelectors_1 = require("../../redux/productGroupFredhopper/selectors/ProductGroupFredhopperSelectors");
const ProductGroupFredhopper_creators_1 = require("../../redux/productGroupFredhopper/actions/ProductGroupFredhopper.creators");
const useProductTileHelpers_1 = require("../../hooks/useProductTileHelpers");
const ActionEnums_1 = require("../../enums/ActionEnums");
const ProductGroupFredhopperContainer = ({ title, productGroupFredhopperId, tileFormat, deliveryPromiseExplanations, collapsed, collapsible, numberOfTiles, onAddToBasketCallback, campaign, }) => {
    var _a;
    const dispatch = (0, react_redux_1.useDispatch)();
    const { handleWishlistToggle, handleUpdateCompareList, handleAddToBasket, compareItems } = (0, useProductTileHelpers_1.useProductTileHelpers)();
    const productGroupFredhopper = (0, react_redux_1.useSelector)((0, ProductGroupFredhopperSelectors_1.makeGetProductGroupFredhopper)());
    const getDeliveryPromise = (payload) => dispatch(ProductGroupFredhopper_creators_1.ProductGroupFredhopperActions.getDeliveryPromise(Object.assign(Object.assign({}, payload), { productGroupId: productGroupFredhopperId })));
    (0, react_1.useEffect)(() => {
        var _a;
        if (productGroupFredhopperId && ((_a = productGroupFredhopper === null || productGroupFredhopper === void 0 ? void 0 : productGroupFredhopper[productGroupFredhopperId]) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(ProductGroupFredhopper_creators_1.ProductGroupFredhopperActions.init());
        }
    }, []);
    if (!productGroupFredhopperId || !((_a = productGroupFredhopper === null || productGroupFredhopper === void 0 ? void 0 : productGroupFredhopper[productGroupFredhopperId]) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return ((0, jsx_runtime_1.jsx)(ProductGroup_1.ProductGroup, { products: productGroupFredhopper[productGroupFredhopperId], productGroupId: `product-group-fredhopper-${productGroupFredhopperId}`, onWishListToggle: handleWishlistToggle, title: title, updateCompareList: handleUpdateCompareList, productTileFormat: tileFormat, addToBasket: (...args) => handleAddToBasket(...args, ActionEnums_1.ProductAddToBasketLocations.PRODUCT_GROUP_FREDHOPPER), onAddToBasketCallback: onAddToBasketCallback, getDeliveryPromise: getDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, collapsed: collapsed, collapsible: collapsible, compareItems: compareItems, numberOfTiles: numberOfTiles, analyticsAreaName: "fredhopper-recommendation", analyticsProductGroupName: `fh_${campaign}` }));
};
exports.default = ProductGroupFredhopperContainer;
