"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MiniPDPCTA = ({ showGoToPdpBtn = false, productUrl, submitText, onAddToBasketClick, inWishList, isShopLanding, toggleWishList, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const getWishlistBtn = () => isShopLanding ? null : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.OUTLINED_ALT, onClick: toggleWishList, icon: {
            size: cotopaxi_1.IconSize.MAJOR,
            name: inWishList ? cotopaxi_1.IconName.HEART : cotopaxi_1.IconName.HEART_EMPTY,
            animation: inWishList ? cotopaxi_1.IconAnimation.HEART_PULSE : null,
        } }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.SMALL, children: [isMobile && getWishlistBtn(), showGoToPdpBtn ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, href: productUrl, variant: cotopaxi_1.ButtonVariant.OUTLINED, text: submitText || t('pdp.mini.goToPdp'), iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MINOR } })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: onAddToBasketClick, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: submitText || t('product:pdp.cart.addtobasket.btn'), iconLeft: submitText ? undefined : { name: cotopaxi_1.IconName.CART, size: cotopaxi_1.IconSize.MINOR } })), !isMobile && getWishlistBtn()] }) }));
};
exports.MiniPDPCTA = MiniPDPCTA;
