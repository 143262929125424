"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickFilterContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const QuickFilterRow_1 = require("../../components/lister/quick-filters/QuickFilterRow");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const redux_1 = require("redux");
const _QuickFilterContainer = ({ quickLinks }) => {
    if (!(!!quickLinks && quickLinks.length > 0)) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)(QuickFilterRow_1.QuickFilterRow, { quickLinks: quickLinks });
};
const makeMapStateToProps = () => {
    const getQuickLinks = (0, ListerSelector_1.makeGetQuickLinks)();
    return (state) => ({
        quickLinks: getQuickLinks(state),
    });
};
const composer = (0, redux_1.compose)((0, react_redux_1.connect)(makeMapStateToProps));
exports.QuickFilterContainer = composer(_QuickFilterContainer);
