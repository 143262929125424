"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPopoverContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const BasketPopover_1 = require("../components/basket/basket-popover/BasketPopover");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const BasketPopoverContainer = ({ hrefViewCart, freeDeliveryMessage, }) => {
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const deliveryInfo = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDeliveryInfoState)());
    const status = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketStatusState)());
    const vouchers = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketVouchersState)());
    return ((0, jsx_runtime_1.jsx)("div", { "data-analytics-bannerid": "header-shopping-cart", children: (0, jsx_runtime_1.jsx)(BasketPopover_1.BasketPopover, { products: products, vouchers: vouchers, pricing: pricing, status: status, hrefViewCart: hrefViewCart, deliveryInfo: deliveryInfo, freeDeliveryMessage: freeDeliveryMessage }) }));
};
exports.BasketPopoverContainer = BasketPopoverContainer;
