"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersReducer = exports.orderOverviewReducerReducerState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.orderOverviewReducerReducerState = {
    announcedReturn: null,
    returnedSales: null,
    orderedItems: null,
    returnItems: null,
    returnReasons: [],
};
const ordersReducer = (state = exports.orderOverviewReducerReducerState, action) => {
    switch (action.type) {
        case ActionEnums_1.OrdersActionsTypes.SET_ORDERED_ITEMS:
            return Object.assign(Object.assign({}, state), { orderedItems: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_RETURN_ITEMS:
            return Object.assign(Object.assign({}, state), { returnItems: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_ANNOUNCED_RETURN:
            return Object.assign(Object.assign({}, state), { announcedReturn: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_RETURN_REASONS:
            return Object.assign(Object.assign({}, state), { returnReasons: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_RETURNED_SALES:
            return Object.assign(Object.assign({}, state), { returnedSales: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_SUBMIT_RETURN_ITEMS_STATE:
            return Object.assign(Object.assign({}, state), { submitReturnItemsState: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_UPDATE_ANNOUNCED_RETURN_STATE:
            return Object.assign(Object.assign({}, state), { updateAnnouncedReturnState: action.payload });
        case ActionEnums_1.OrdersActionsTypes.SET_ORDERED_ITEMS_STATE:
            return Object.assign(Object.assign({}, state), { orderedItemsState: action.payload });
        default:
            return state;
    }
};
exports.ordersReducer = ordersReducer;
