"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductManualDocument = exports.getFilteredProductManualKeys = exports.FALLBACK_LANG = void 0;
exports.FALLBACK_LANG = 'EN';
const getFilteredProductManualKeys = (productManual) => {
    if (!productManual) {
        return [];
    }
    return Object.keys(productManual.productManualByLanguage).filter((key) => productManual.productManualByLanguage[key] &&
        productManual.productManualByLanguage[key].document &&
        productManual.productManualByLanguage[key].showOnWebsite);
};
exports.getFilteredProductManualKeys = getFilteredProductManualKeys;
const getProductManualDocument = (filteredProductManualKeys, lang, productManual, fallBackLang) => {
    if (filteredProductManualKeys.includes(lang.toUpperCase())) {
        return productManual.productManualByLanguage[lang.toUpperCase()].document;
    }
    if (filteredProductManualKeys.includes(fallBackLang)) {
        return productManual.productManualByLanguage[fallBackLang].document;
    }
    return productManual.productManualByLanguage[filteredProductManualKeys[0]].document;
};
exports.getProductManualDocument = getProductManualDocument;
