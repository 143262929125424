"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../html-include/HtmlInclude");
const CookiePrivacy = ({ privacyData, logoUrl, isCookiePrivacyOpen, onCookiePrivacyClose, }) => {
    const { handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({
        close: onCookiePrivacyClose,
    });
    (0, react_1.useEffect)(() => {
        if (isCookiePrivacyOpen) {
            handlePopoverOpen(null, null, true, true);
        }
    }, [handlePopoverOpen, isCookiePrivacyOpen]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { multi: true, disableClickOutside: true, noAnimation: true, anchorEl: isCookiePrivacyOpen, type: cotopaxi_1.PopoverType.MODAL, position: cotopaxi_1.PopoverPosition.BOTTOM, onClose: handlePopoverClose, headerAlign: cotopaxi_1.PopoverHeaderAlign.CENTER, title: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: logoUrl, size: cotopaxi_1.ImageSize.COOKIE_BRAND_LOGO }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                spacing: {
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingBottom: 2,
                },
            }, tablet: {
                spacing: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            }, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: privacyData }) }) }));
};
exports.default = CookiePrivacy;
