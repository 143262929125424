"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.range = exports.round = void 0;
const round = (value, step = 1.0) => {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
};
exports.round = round;
const range = (start, end) => new Array(end + 1 - start).fill('').map((_, i) => i + start);
exports.range = range;
