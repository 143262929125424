"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftVoucherThumbSlider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../util/ImageUtil");
const GiftVoucherThumbSlider = ({ images, activeSlideIndex, imageCdnPrefix, onActiveSlideIndexChange, }) => {
    const [swiper, setSwiper] = (0, react_1.useState)(null);
    const onThumbClick = function (index) {
        if (index !== activeSlideIndex) {
            onActiveSlideIndexChange(index);
        }
    };
    const renderSlide = (0, react_1.useCallback)((image, index) => {
        const imagePath = (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix);
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { role: "button", tabIndex: 0, onClick: () => onThumbClick(index), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.GiftCardThumbSlide, { active: activeSlideIndex === index, imagePath: imagePath }) }, `gift_voucher_thumb_${index}`));
    }, [activeSlideIndex]);
    const giftVoucherSlideConfig = {
        variant: cotopaxi_1.CarouselVariant.GIFT_CARD_THUMB,
        showPagination: false,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.SEMICIRCLE,
        },
        configurationOverrides: {
            getSwiper: setSwiper,
            watchOverflow: true,
            slidesPerView: 'auto',
            spaceBetween: 16,
        },
    };
    (0, react_1.useEffect)(() => {
        if (swiper !== null) {
            const activeSlide = swiper === null || swiper === void 0 ? void 0 : swiper.slides[activeSlideIndex];
            if ((activeSlide === null || activeSlide === void 0 ? void 0 : activeSlide.className.indexOf('swiper-slide-visible')) < 0) {
                swiper.slideTo(activeSlideIndex);
            }
        }
    }, [activeSlideIndex]);
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, giftVoucherSlideConfig, { children: images.map(renderSlide) }));
};
exports.GiftVoucherThumbSlider = GiftVoucherThumbSlider;
