"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectV2 = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SizeRecommendationV2_1 = require("../size-recommendation-v2/SizeRecommendationV2");
const BasketQuantityField_1 = require("../basket-quantity-field/BasketQuantityField");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const SizeDropdown_1 = require("../../size-dropdown/SizeDropdown");
const ProductUtil_1 = require("../../../util/ProductUtil");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const SCROLLABLE_LIST_SELECTOR = '.as-m-scrollable-list__scroll-container';
const SizeSelectV2 = ({ id, basketValidationState, sizes, selectedSku, selectSize, setBasketError, setQuantity, sizeRecommendation, isOneSizeProduct, isOneSizeProductInline, isStickyBar, isHiddenStickyBar, isModal, showQuantityField = false, quantity, openSizeSelectOnError, onClose, children, isSelectedSizeSoldOut, notifyMeError, }) => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const SIZE_SELECT_NAME = isStickyBar ? 'size-select-sticky' : 'size-select';
    const DEFAULT_SIZE_SELECT_CLASS = `.as-a-select__select[name=${SIZE_SELECT_NAME}]`;
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const { filteredSizes, options } = (0, ProductUtil_1.mapSizeSelectOptions)(sizes, true, undefined, !isShoplanding);
    const SHOW_ERROR = isStickyBar
        ? basketValidationState === common_types_1.BasketButtonError.SKU_STICKY_BAR
        : basketValidationState === common_types_1.BasketButtonError.SKU;
    const [shouldShowError, setShouldShowError] = (0, react_1.useState)(false);
    const [isPopoverOpen, setIsPopoverOpen] = (0, react_1.useState)(false);
    const [buttonEl, setButtonEl] = (0, react_1.useState)();
    const [wrapperEl, setWrapperEl] = (0, react_1.useState)();
    const [isDropdownOpen, setIsDropdownOpen] = (0, react_1.useState)();
    const sizeSelectRef = (0, react_1.useRef)(null);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const prevSizes = (0, cotopaxi_1.usePrevious)(sizes);
    const handleSelectedItemChange = (option) => {
        setShouldShowError(false);
        selectSize({ sku: option.value.toString(), isSoldOut: option.isSoldOut });
    };
    const openPopover = () => {
        if (!isPopoverOpen && buttonEl) {
            setIsPopoverOpen(true);
            handlePopoverOpen(null, buttonEl, false, true);
        }
    };
    const closePopover = () => {
        if (isPopoverOpen && buttonEl) {
            setIsPopoverOpen(false);
            handlePopoverClose();
            setButtonEl(undefined);
        }
    };
    const onSizeSelectClose = () => {
        setIsDropdownOpen(false);
        onClose && onClose();
    };
    (0, react_1.useEffect)(() => {
        if (isOneSizeProduct && selectedSku) {
            selectSize({ sku: selectedSku });
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (!(0, GeneralUtil_1.isEqual)(sizes, prevSizes)) {
            setShouldShowError(false);
        }
    }, [sizes]);
    (0, react_1.useEffect)(() => {
        if (selectedSku && shouldShowError) {
            setShouldShowError(false);
        }
    }, [selectedSku]);
    (0, react_1.useEffect)(() => {
        if (isHiddenStickyBar && isPopoverOpen && setBasketError) {
            setBasketError();
        }
    }, [isHiddenStickyBar, isPopoverOpen]);
    (0, react_1.useEffect)(() => {
        const sizeSelectEl = document.querySelector(DEFAULT_SIZE_SELECT_CLASS);
        setButtonEl(sizeSelectEl);
        if (openSizeSelectOnError &&
            (basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT || notifyMeError === common_types_1.NotifyMeError.SELECT_SIZE)) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(sizeSelectEl, isDesktop, () => {
                var _a;
                (_a = sizeSelectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                setIsDropdownOpen(true);
            });
        }
        else {
            setShouldShowError(SHOW_ERROR);
            if (SHOW_ERROR && buttonEl) {
                openPopover();
                if (basketValidationState === common_types_1.BasketButtonError.SKU) {
                    if (isModal) {
                        ScrollUtil_1.ScrollUtil.scrollToElement(buttonEl, true);
                    }
                    else {
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(sizeSelectEl, isDesktop);
                    }
                }
            }
            closePopover();
        }
    }, [basketValidationState, buttonEl, notifyMeError]);
    (0, react_1.useEffect)(() => {
        if (isModal && buttonEl && !wrapperEl) {
            const wrapperElement = buttonEl.closest(SCROLLABLE_LIST_SELECTOR);
            setWrapperEl(wrapperElement);
        }
    }, [buttonEl, isModal, wrapperEl]);
    const setPopoverPosition = () => {
        if (!isStickyBar) {
            return isDesktop ? cotopaxi_1.PopoverPosition.LEFT : cotopaxi_1.PopoverPosition.BOTTOM;
        }
        return isDesktop ? cotopaxi_1.PopoverPosition.BOTTOM : cotopaxi_1.PopoverPosition.TOP;
    };
    if (isOneSizeProduct && showQuantityField) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { basis: '25%' }, children: (0, jsx_runtime_1.jsx)(BasketQuantityField_1.default, { quantity: Number(quantity), setQuantity: setQuantity }) }) }));
    }
    if (isOneSizeProductInline) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationV2_1.SizeRecommendationV2, { sizeRecommendation: sizeRecommendation }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size') }), ' ', (_a = options[0]) === null || _a === void 0 ? void 0 : _a.label] }));
    }
    if (isOneSizeProduct) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!isStickyBar && sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationV2_1.SizeRecommendationV2, { sizeRecommendation: sizeRecommendation }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.ConditionalWrapper, { condition: showQuantityField, wrapper: wrapperChildren => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.BOTTOM, children: wrapperChildren })), children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { flex: showQuantityField ? { grow: 1 } : undefined, mobile: showQuantityField ? { spacing: { marginRight: 1 } } : undefined, children: [!isStickyBar && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { size: cotopaxi_1.LabelSize.MINOR, emphasized: true, children: t('pdp.size') })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: !isStickyBar
                                    ? {
                                        spacing: {
                                            marginBottom: 1,
                                            marginTop: 1,
                                        },
                                    }
                                    : undefined, children: (0, jsx_runtime_1.jsx)(SizeDropdown_1.SizeDropdown, { id: id, toggleElementRef: sizeSelectRef, isOpen: isDropdownOpen, onClose: onSizeSelectClose, onOpen: () => setIsDropdownOpen(true), appendToBody: isStickyBar || isModal, stickyWrapper: isStickyBar || isModal, sizes: filteredSizes, options: options, selectedSku: selectedSku, handleSelectedItemChange: handleSelectedItemChange, toggleElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Select, { dataQA: "size_select", name: SIZE_SELECT_NAME, options: options, defaultOptionValue: options.length === 1 ? (_b = options[0]) === null || _b === void 0 ? void 0 : _b.value : undefined, selectedValue: selectedSku, placeholder: t('pdp.size.choose.default'), fullWidth: true, disabled: true, state: shouldShowError ? cotopaxi_1.SelectState.ERROR : undefined, color: !isShoplanding && isSelectedSizeSoldOut ? 'N300' : undefined, strike: !isShoplanding && isSelectedSizeSoldOut, children: children }) }) })] }), showQuantityField && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { basis: '25%', shrink: 0 }, mobile: { spacing: { marginLeft: 1 } }, children: (0, jsx_runtime_1.jsx)(BasketQuantityField_1.default, { quantity: Number(quantity), setQuantity: setQuantity }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handlePopoverClose, scrollableWrapperEl: wrapperEl, showTitleOnDesktop: false, position: setPopoverPosition(), stickyWrapper: isStickyBar || isModal, type: cotopaxi_1.PopoverType.TOOLTIP, noAnimation: true, children: t(isStickyBar ? 'stickyBar.sizeSelect.label' : 'pdp.selectSize.label') })] }));
};
exports.SizeSelectV2 = SizeSelectV2;
