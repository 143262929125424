"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueChangeHandler = void 0;
const react_1 = require("react");
const formik_1 = require("formik");
const ValueChangeHandler = ({ value, onChange, }) => {
    const context = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        onChange(value, context);
    }, [value, onChange]);
    return null;
};
exports.ValueChangeHandler = ValueChangeHandler;
