"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getListerProductsSaga = exports.updateListerProductsCompareStateSaga = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const effects_1 = require("redux-saga/effects");
const connected_react_router_1 = require("connected-react-router");
const axios_1 = require("axios");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const ListerActions_creators_1 = require("../actions/ListerActions.creators");
const FilterActions_creators_1 = require("../../filter/actions/FilterActions.creators");
const ProductGroupActions_creators_1 = require("../../productGroup/actions/ProductGroupActions.creators");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const logger_1 = require("@as-react/logger");
const ProductListerUtil_1 = require("../../../util/ProductListerUtil");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const getFredhopperSegment = (state) => state.lister.segment;
const getQueryInfo = (state) => state.url;
function* updateListerProductsCompareStateSaga() {
    yield (0, effects_1.takeLatest)(connected_react_router_1.LOCATION_CHANGE, updateListerProductsCompareState);
}
exports.updateListerProductsCompareStateSaga = updateListerProductsCompareStateSaga;
function* updateListerProductsCompareState() {
    yield (0, effects_1.fork)(CompareSagas_1.updateCompareProducts);
}
function* getListerProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ListerActionTypes.GET_LISTER_PRODUCTS, getListerProducts);
}
exports.getListerProductsSaga = getListerProductsSaga;
function* getListerProducts() {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const searchEndpointUrl = yield (0, effects_1.call)(SagaContextSelectors_1.getSearchEndpointUrl, aemConfigurationContext);
    const authenticationConfig = yield (0, effects_1.call)(SagaContextSelectors_1.getAuthenticationConfig, aemConfigurationContext);
    const queryInfo = yield (0, effects_1.select)(getQueryInfo);
    const fredhopperSegment = yield (0, effects_1.select)(getFredhopperSegment);
    const UUID = common_utils_1.CookieUtil.get(common_types_1.Cookie._UUID);
    const aiSearchEnabled = new common_utils_1.HybridCookieUtil().hasABCookieSet(common_types_1.ABTestCookie.AI_SEARCH);
    try {
        const searchResult = yield (0, effects_1.call)(ProductsAPI_1.default.fetchProducts, queryInfo, searchEndpointUrl, authenticationConfig, fredhopperSegment, Boolean(UUID), aiSearchEnabled);
        const productGroupUniqueIds = (0, common_utils_1.getProductGroupUniqueIds)(searchResult.alternativeQueryResults);
        const productGroupObjects = (0, common_utils_1.makeProductGroupObjects)(productGroupUniqueIds, searchResult.alternativeQueryResults);
        searchResult.productGroupUniqueIds = productGroupUniqueIds;
        yield (0, effects_1.put)(ListerActions_creators_1.listerActions.setListerStateAction(searchResult));
        yield (0, effects_1.put)(ProductGroupActions_creators_1.productGroupActions.setProductGroups(productGroupObjects));
        if ((0, ProductListerUtil_1.isPartialSearchResult)(searchResult.executionStatus)) {
            const products = Object.keys(productGroupObjects)
                .map(productGroupId => productGroupObjects[productGroupId].filter(item => item.productId))
                .flat();
            MonetateUtil_1.default.pushListerEvent(products);
        }
        if ((_a = queryInfo === null || queryInfo === void 0 ? void 0 : queryInfo.filter) === null || _a === void 0 ? void 0 : _a.length) {
            const activeFilters = {};
            queryInfo.filter.map((queryFilter) => {
                const activeFilter = (0, common_utils_1.generateActiveFilter)(queryFilter.filterOn, queryFilter.filterValue, searchResult.filters);
                if (activeFilter) {
                    activeFilters[queryFilter.filterOn] = activeFilter;
                }
            });
            yield (0, effects_1.put)(FilterActions_creators_1.filterActions.setActiveFilters(activeFilters));
        }
        const partialRootState = yield (0, effects_1.select)(RouterSagas_1.getStoreData);
        yield (0, effects_1.fork)(RouterSagas_1.pushRouterState, partialRootState.url, partialRootState);
        yield (0, effects_1.put)(ListerActions_creators_1.listerActions.setListerIsLoadedAction());
    }
    catch (error) {
        if (!(0, axios_1.isAxiosError)(error)) {
            logger_1.Logger.logError(error);
        }
    }
}
function* listerSagas() {
    yield (0, effects_1.all)([updateListerProductsCompareStateSaga(), getListerProductsSaga()]);
}
exports.default = listerSagas;
