"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const OnlineAvailability_1 = require("../components/product-detail/online-availability/OnlineAvailability");
const OnlineAvailabilityContainer = ({ hdExcluded, ccExcluded, fulfillmentCopyDescription, fulfillmentBySize: { storeSourceExcluded }, }) => ((0, jsx_runtime_1.jsx)(OnlineAvailability_1.OnlineAvailability, { hdExcluded: hdExcluded, ccExcluded: ccExcluded, storeSourceExcluded: storeSourceExcluded, fulfillmentCopyDescription: fulfillmentCopyDescription }));
const makeMapStateToProps = () => {
    const getProductFulfillment = (0, ProductSelector_1.makeGetProductFulfillment)();
    const getProductFulfillmentBySize = (0, ProductSelector_1.makeGetProductFulfillmentBySize)();
    const mapStateToProps = (state) => ({
        hdExcluded: getProductFulfillment(state).hdExcluded,
        ccExcluded: getProductFulfillment(state).ccExcluded,
        fulfillmentCopyDescription: getProductFulfillment(state).fulfillmentCopyDescription,
        fulfillmentBySize: getProductFulfillmentBySize(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(OnlineAvailabilityContainer);
