"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleProductTileActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.singleProductTileActions = {
    addProductToList: (payload) => ({
        type: ActionEnums_1.SingleProductTileActionTypes.ADD_PRODUCT_TO_LIST,
        payload,
    }),
    addBulkProductsToList: (payload) => ({
        type: ActionEnums_1.SingleProductTileActionTypes.ADD_BULK_PRODUCTS_TO_LIST,
        payload,
    }),
    getDeliveryPromise: (payload) => ({
        type: ActionEnums_1.SingleProductTileActionTypes.GET_DELIVERY_PROMISE,
        payload,
    }),
    setDeliveryPromise: (payload) => ({
        type: ActionEnums_1.SingleProductTileActionTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
};
