"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EticketRatingButtons = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const EticketContainer_1 = require("../../../containers/EticketContainer");
const EticketRatingButtons = ({ rateItem, rating }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const [voted, setVoted] = (0, react_1.useState)();
    const rateDivision = (rate) => {
        rateItem(rate);
        setVoted(rate);
    };
    const getButtonVariant = (ratingValue) => rating === ratingValue && !voted ? cotopaxi_1.ButtonVariant.BRANDED : cotopaxi_1.ButtonVariant.BASE;
    const ETICKET_BUTTONS = [
        {
            ratingValue: EticketContainer_1.ETICKET_CONSTANTS.RATING.GOOD,
            text: t('eticket.rating.good'),
        },
        {
            ratingValue: EticketContainer_1.ETICKET_CONSTANTS.RATING.OK,
            text: t('eticket.rating.okay'),
        },
        {
            ratingValue: EticketContainer_1.ETICKET_CONSTANTS.RATING.BAD,
            text: t('eticket.rating.bad'),
        },
    ];
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.MEDIUM, column: isMobile, children: ETICKET_BUTTONS.map(({ ratingValue, text }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: () => rateDivision(ratingValue), text: text, variant: getButtonVariant(ratingValue), state: voted ? cotopaxi_1.ButtonState.FINISHED : cotopaxi_1.ButtonState.INTERACTIVE, finisher: voted === ratingValue ? { text } : undefined }, ratingValue))) }));
};
exports.EticketRatingButtons = EticketRatingButtons;
