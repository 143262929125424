"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ScreenWidthConstants_1 = require("../../../../constants/ScreenWidthConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const ProductPreviewThumbSlider = ({ activeSlideIndex, mediaCollection, onActiveSlideIndexChange, alt, }) => {
    const [gallerySwiper, setGallerySwiper] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        if (gallerySwiper !== null && !!activeSlideIndex) {
            if (((_a = gallerySwiper.slides[activeSlideIndex]) === null || _a === void 0 ? void 0 : _a.className.indexOf('swiper-slide-visible')) < 0) {
                gallerySwiper.slideTo(activeSlideIndex);
            }
        }
    }, [activeSlideIndex]);
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.update();
    }, [mediaCollection]);
    const onThumbClick = (index) => {
        if (index !== activeSlideIndex) {
            onActiveSlideIndexChange(index);
        }
    };
    const renderSlide = (0, react_1.useCallback)((image, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { onClick: () => onThumbClick(index), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPreviewThumbSlide, { active: activeSlideIndex === index, isVideo: image.isVideo, imagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.thumb], alt: alt }) }, `image_${index}`)), [activeSlideIndex]);
    const productThumbSliderCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.THUMB,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.SEMICIRCLE,
            direction: cotopaxi_1.SliderNavDirection.VERTICAL,
        },
        configurationOverrides: {
            autoHeight: true,
            noSwiping: true,
            getSwiper: setGallerySwiper,
            direction: 'vertical',
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerGroup: 5,
                    slidesPerView: 5,
                    height: 540,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerGroup: 6,
                    slidesPerView: 6,
                    height: 822,
                },
            },
            spaceBetween: 23,
        },
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.DESKTOP], flex: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, productThumbSliderCarouselConfig, { dataQA: "product_thumb_slider", children: mediaCollection.map((image, index) => renderSlide(image, index)) })) }));
};
exports.default = ProductPreviewThumbSlider;
