"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const i18nConstants_1 = require("../../../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../../../util/ConfigurationUtil");
const RegistrationField_1 = require("../../../../../account/registration/RegistrationField");
const TitleChangeHandler_1 = require("../../../../../account/registration/TitleChangeHandler");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const TitleBlock = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general]);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { values, errors, touched } = (0, formik_1.useFormikContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const { getGenders, getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerGenders = [] } = (0, react_query_1.useQuery)(getGenders({ lang }));
    const { data: customerTitlesData } = (0, react_query_1.useQuery)(getTitles({ lang }, {
        select: data => ({
            customerTitles: data,
            optionsTitle: data.map(title => ({ value: title.id, label: title.title })),
            hasGenderField: Boolean(data.some(title => !title.idGender || title.idGender === '-1')),
        }),
    }));
    const { hasGenderField = false, customerTitles = [], optionsTitle = [] } = customerTitlesData || {};
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { clear: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: isMobile, tablet: 6, desktop: 6, children: (0, jsx_runtime_1.jsx)(RegistrationField_1.RegistrationField, { label: t('account.details.title'), placeholder: t('account.noTitle'), name: "title", id: "title", value: values.title, required: true, state: errors.title && touched.title ? cotopaxi_1.InputState.ERROR : undefined, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select, fullWidth: true, options: optionsTitle, dataQA: "title" }) }), hasGenderField && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterRight: true, noGutterLeft: isMobile, tablet: 6, desktop: 6, children: (0, jsx_runtime_1.jsx)(RegistrationField_1.RegistrationField, { required: true, label: t('account.details.gender'), hasLabelBox: true, name: "gender", id: "gender", data: {
                                options: customerGenders,
                                keyLookup: 'peopleCode',
                                valueLookup: 'peopleCode',
                                labelLookup: 'gender',
                            }, value: values.gender, inline: true, state: errors.gender && touched.gender ? cotopaxi_1.InputState.ERROR : undefined, componentType: cotopaxi_1.FormFieldType.RADIO_BUTTON, Component: cotopaxi_1.RadioButtonWithLabel, dataQA: "gender" }) }))] }), (0, jsx_runtime_1.jsx)(TitleChangeHandler_1.TitleChangeHandler, { titles: customerTitles, genders: customerGenders })] }));
};
exports.TitleBlock = TitleBlock;
