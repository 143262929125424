"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleField = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const FormField_1 = require("../FormField");
const react_2 = require("react");
const react_hook_form_1 = require("react-hook-form");
const TitleField = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { lang } = aemConfiguration;
    const { getGenders, getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: genders = [] } = (0, react_query_1.useQuery)(getGenders({ lang }));
    const { data: titlesData } = (0, react_query_1.useQuery)(getTitles({ lang }, {
        select: data => ({
            titles: data,
            titleOptions: data.map(title => ({ id: title.id, text: title.title })),
            hasGenderField: Boolean(data.some(title => !title.idGender || title.idGender === '-1')),
        }),
    }));
    const { hasGenderField = false, titles = [], titleOptions = [] } = titlesData || {};
    const { watch, setValue } = (0, react_hook_form_1.useFormContext)();
    const titleValue = watch('title');
    (0, react_2.useEffect)(() => {
        if (!titleValue)
            return;
        const selectedTitle = titles.find(title => title.id === titleValue);
        const selectedGender = genders.find(gender => String(gender.id) === (selectedTitle === null || selectedTitle === void 0 ? void 0 : selectedTitle.idGender));
        if (selectedGender) {
            setValue('gender', selectedGender.peopleCode);
        }
    }, [titleValue]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapX: 4, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "title", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref, value, onChange } = _b, options = tslib_1.__rest(_b, ["ref", "value", "onChange"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, { input: Object.assign(Object.assign({}, options), { id: options.name, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.title`), placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.personal.details.title.placeholder`), error, required: true }), items: titleOptions, onSelect: value => onChange(value.id), value: titleOptions.length ? value : undefined, dataTestId: options.name }));
                    } }) }), hasGenderField && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "gender", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref, value } = _b, options = tslib_1.__rest(_b, ["ref", "value"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { dataTestId: options.name, gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Label, { children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.gender`) }), (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { gap: "2", children: genders.map(({ gender, peopleCode }) => ((0, react_1.createElement)(andes_react_1.RadioButton, Object.assign({}, options, { id: `field-${peopleCode}`, key: `field-${peopleCode}`, label: gender, value: peopleCode, checked: value === peopleCode })))) }), error && (0, jsx_runtime_1.jsx)(andes_react_1.ErrorMessage, { children: error })] }));
                    } }) }))] }));
};
exports.TitleField = TitleField;
