"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const OpenedStores_1 = require("./OpenedStores");
const StoreNearbyStores = ({ openedStores }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    return ((0, jsx_runtime_1.jsx)(OpenedStores_1.OpenedStores, { openedStores: openedStores, copy: { title: t('store.nearbystores.txt'), button: t('store.details.txt') } }));
};
exports.default = StoreNearbyStores;
