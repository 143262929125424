"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const WishList_module_1 = require("./modules/WishList.module");
const Compare_module_1 = require("./modules/Compare.module");
const ProductGroup_module_1 = require("./modules/ProductGroup.module");
const DeliveryPromise_module_1 = require("./modules/DeliveryPromise.module");
const loadCampaignGroupModule = (initialData) => ({
    hasRouter: false,
    modules: [
        (0, Compare_module_1.default)(),
        (0, ProductGroup_module_1.default)(initialData),
        (0, WishList_module_1.default)(),
        (0, DeliveryPromise_module_1.default)(),
    ],
});
exports.default = loadCampaignGroupModule;
