"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const CheckoutAPI_1 = require("../CheckoutAPI");
const axios_1 = require("axios");
const buyEndpoints = {
    delivery: 'api/buy/delivery',
    deliveryCountry: 'api/buy/delivery/country',
    deliveryOption: 'api/buy/delivery/option',
    namedDayDeliveryDays: 'api/nameddaydelivery/deliverydays',
    searchPointsByPostcode: 'api/pickup/postcode',
    searchPointsByGeoLocation: 'api/pickup/geolocation',
    savePoint: 'api/buy/pickup/v2',
    collectInStore: 'api/buy/delivery/clickandcollect',
    contact: 'api/buy/contact',
    payment: 'api/buy/payment',
    postPayment: 'api/buy/payment/v2',
    paymentProviderInput: 'api/buy/input',
    paymentOption: 'api/buy/payment/option',
    paymentOptions: 'api/buy/payment/options',
    voucherCodeSend: 'api/checkout/voucher',
    paymentSaferpayAlt: 'api/buy/input/saferpay/alternative',
    paymentStatusSaferpayAlt: 'api/buy/status/saferpay/alternative',
    paymentSaferpayToken: 'api/buy/input/saferpay/token',
    paymentProviderPlaceOrder: 'api/buy/submit',
    orderPaidByVoucher: 'api/buy/payment/voucher',
    confirmation: 'api/buy/done',
    analyticsPurchaseStep: 'api/analytics/purchasestep',
    dtmAnalyticsPurchaseStep: 'api/dtm/purchasestep',
    paymentSaferpayAlias: 'api/buy/input/saferpay/alias',
    shoplandingPayment: 'api/buy/landing',
    fastCheckoutOverview: 'api/checkout/fast/overview',
    fastCheckoutValidate: 'api/checkout/fast/validate',
    paymentEnabled: 'api/buy/payment/enabled',
    paymentRequestConfirmation: 'api/checkout/paymentrequest/done',
};
class BuyApi {
    constructor() {
        this.getDelivery = (servicesApi, defaultRequestParams, countryId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.delivery}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { countryId }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.changeDeliveryCountry = (servicesApi, defaultRequestParams, countryId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.deliveryCountry}/${countryId}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postDeliveryOption = (servicesApi, defaultRequestParams, deliveryOption) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.deliveryOption}/${deliveryOption}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getNamedDayDeliveryDates = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.namedDayDeliveryDays}`,
                params: Object.assign({}, defaultRequestParams),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.searchPickupPoints = (servicesApi, postcode, provider, countryCode, deliveryDate, limit) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.searchPointsByPostcode}`,
                params: { postcode, provider, countryCode, deliveryDate, limit },
                withCredentials: true,
            };
            return yield axios_1.default.request(axiosConfig);
        });
        this.searchPickupPointsByGeoLocation = (servicesApi, provider, countryCode, deliveryDate, radius, limit, latitude, longitude) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.searchPointsByGeoLocation}`,
                params: { provider, countryCode, deliveryDate, radius, limit, latitude, longitude },
                withCredentials: true,
            };
            return yield axios_1.default.request(axiosConfig);
        });
        this.savePickupPoint = (servicesApi, defaultRequestParams, pickupPoint, provider, countryCode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.savePoint}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { provider }),
                data: Object.assign(Object.assign({}, pickupPoint), { countryCode }),
                withCredentials: true,
            };
            return yield axios_1.default.request(axiosConfig);
        });
        this.postDelivery = (servicesApi, defaultRequestParams, delivery) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.delivery}`,
                params: defaultRequestParams,
                withCredentials: true,
                data: delivery,
            };
            yield axios_1.default.request(axiosConfig);
        });
        this.getStoresForGeoLocation = (servicesApi_1, defaultRequestParams_1, _a, size_1, onlyInStock_1, ...args_1) => tslib_1.__awaiter(this, [servicesApi_1, defaultRequestParams_1, _a, size_1, onlyInStock_1, ...args_1], void 0, function* (servicesApi, defaultRequestParams, { latitude, longitude }, size, onlyInStock, fasciaFirst = false) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.collectInStore}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { latitude, longitude, size, onlyInStock, fasciaFirst }),
                withCredentials: true,
            };
            const answer = yield axios_1.default.request(axiosConfig);
            return answer.data;
        });
        this.updateCustomerData = (servicesApi, defaultRequestParams, customerDetails) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.contact}`,
                params: defaultRequestParams,
                withCredentials: true,
                data: customerDetails,
            };
            yield axios_1.default.request(axiosConfig);
        });
        this.getPayment = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.payment}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postPayment = (servicesApi, defaultRequestParams, payment) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.postPayment}`,
                params: defaultRequestParams,
                withCredentials: true,
                data: payment,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.paymentProviderInputRequest = (servicesApi, defaultRequestParams, provider, data, params) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.paymentProviderInput}/${provider.toLowerCase()}`,
                params: Object.assign(Object.assign(Object.assign({}, defaultRequestParams), params), { checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW }),
                withCredentials: true,
                data,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updatePaymentId = (servicesApi, defaultRequestParams, paymentOptionId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.paymentOption}/${paymentOptionId}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postVoucher = (apiEndpoint, defaultRequestParams, code, webcode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { voucher: code }),
                url: `${apiEndpoint}${buyEndpoints.voucherCodeSend}`,
                withCredentials: true,
            };
            if (webcode) {
                axiosConfig.params.controlCode = webcode;
            }
            return axios_1.default.request(axiosConfig);
        });
        this.postSaferpayAlt = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.paymentSaferpayAlt}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postSaferpayToken = (servicesApi_2, defaultRequestParams_2, token_1, ...args_2) => tslib_1.__awaiter(this, [servicesApi_2, defaultRequestParams_2, token_1, ...args_2], void 0, function* (servicesApi, defaultRequestParams, token, saveForFutureUse = false) {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.paymentSaferpayToken}`,
                params: defaultRequestParams,
                withCredentials: true,
                data: {
                    saferpayFieldsToken: token,
                    saveForFutureUse: saveForFutureUse,
                    checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getSaferpayAltStatus = (servicesApi, defaultRequestParams, requestId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.paymentStatusSaferpayAlt}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { requestId, checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.paymentProviderPlaceOrder = (apiEndpoint, defaultRequestParams, provider, authorizationToken) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { authorizationToken, checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW }),
                url: `${apiEndpoint}${buyEndpoints.paymentProviderPlaceOrder}/${provider}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.orderPaidByVoucher = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}${buyEndpoints.orderPaidByVoucher}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postSaferpayAlias = (apiEndpoint, defaultRequestParams, aliasId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiEndpoint}${buyEndpoints.paymentSaferpayAlias}`,
                withCredentials: true,
                params: defaultRequestParams,
                data: { aliasId, checkoutVersion: CheckoutAPI_1.CheckoutVersion.NEW },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getConfirmedOrder = (servicesApi, defaultRequestParams, orderRef, customerId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.confirmation}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { orderRef, customerID: customerId }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getAnalyticsPurchaseStepInformation = (servicesApi, defaultRequestParams, orderID) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.analyticsPurchaseStep}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { orderID }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getDtmAnalyticsPurchaseStepInformation = (servicesApi, defaultRequestParams, orderID) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.dtmAnalyticsPurchaseStep}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { orderID }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.shoplandingPayment = (servicesApi, defaultRequestParams, shopNumber) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.shoplandingPayment}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { shopNumber, physicalShop: true }),
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getFastCheckoutOverview = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.fastCheckoutOverview}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.fastCheckoutValidate = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${buyEndpoints.fastCheckoutValidate}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getPaymentOptions = (servicesApi_3, _b, paymentOptionGroup_1) => tslib_1.__awaiter(this, [servicesApi_3, _b, paymentOptionGroup_1], void 0, function* (servicesApi, { shopId, mainWebshop }, paymentOptionGroup) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.paymentOptions}/${paymentOptionGroup}`,
                params: {
                    fictiveWebShopId: shopId,
                    mainWebShop: mainWebshop,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getPaymentOptionEnable = (servicesApi_4, _c, paymentOptionId_1) => tslib_1.__awaiter(this, [servicesApi_4, _c, paymentOptionId_1], void 0, function* (servicesApi, { shopId, mainWebshop }, paymentOptionId) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.paymentEnabled}/${paymentOptionId}`,
                params: {
                    fictiveWebShopId: shopId,
                    mainWebShop: mainWebshop,
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getPaymentRequestConfirmation = (servicesApi, paymentRequestId, anaLang, language, mainWebshop, market, shopId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${buyEndpoints.paymentRequestConfirmation}`,
                params: { paymentRequestId, anaLang, language, mainWebshop, market, shopId },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new BuyApi();
