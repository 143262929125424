"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortControlPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SortControlPopover = ({ anchorEl, onClose, orderItems, currentOrderItem, onOrderChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const buttonListButtons = orderItems.map((item) => ({
        key: item.key,
        active: item.key === currentOrderItem.key,
        onClick: () => onOrderChange(item.key),
        text: t(item.value),
    }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { noPadding: true, anchorEl: anchorEl, onClose: onClose, showTitleOnDesktop: false, headerTheme: cotopaxi_1.PopoverHeaderTheme.SECONDARY, title: t('product.sort'), position: cotopaxi_1.PopoverPosition.BOTTOM, leftElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: onClose, icon: {
                name: cotopaxi_1.IconName.CLOSE,
                size: cotopaxi_1.IconSize.MAJOR,
            } }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { buttons: buttonListButtons }) }));
};
exports.SortControlPopover = SortControlPopover;
