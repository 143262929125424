"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stepUpdateSaga = exports.stepUpdate = void 0;
const common_types_1 = require("@as-react/common-types");
const effects_1 = require("redux-saga/effects");
const AuthActions_creators_1 = require("../../authentication/actions/AuthActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function fromForgottenToLogin(action) {
    return action.payload.from === common_types_1.AuthFormStep.PASSWORD_FORGOTTEN && action.payload.to === common_types_1.AuthFormStep.LOGIN;
}
function fromLoginToForgotten(action) {
    return action.payload.from === common_types_1.AuthFormStep.LOGIN && action.payload.to === common_types_1.AuthFormStep.PASSWORD_FORGOTTEN;
}
function* stepUpdate(action) {
    try {
        if (fromForgottenToLogin(action) || fromLoginToForgotten(action)) {
            yield (0, effects_1.put)(AuthActions_creators_1.authActions.resetError());
        }
    }
    catch (_a) { }
}
exports.stepUpdate = stepUpdate;
function* stepUpdateSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthFormActionsTypes.UPDATE_STEP_TYPE, stepUpdate);
}
exports.stepUpdateSaga = stepUpdateSaga;
function* authFormSaga() {
    yield (0, effects_1.all)([stepUpdateSaga()]);
}
exports.default = authFormSaga;
