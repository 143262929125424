"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedAccounts = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_1 = require("react");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const CustomerActions_creators_1 = require("../../redux/customer/actions/CustomerActions.creators");
const react_redux_1 = require("react-redux");
const CustomerSelector_1 = require("../../redux/customer/selectors/CustomerSelector");
const CustomerUtil_1 = require("../../util/CustomerUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const LoginUtil_1 = require("../../util/LoginUtil");
const AuthSocialLoginFacebookApi_1 = require("../../api/auth/AuthSocialLoginFacebookApi");
const accountIconMap = {
    facebook: cotopaxi_1.IconName.FACEBOOK_BOX,
};
const ConnectedAccounts = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const enabledList = (0, ConfigurationUtil_1.getSocialEnabledListConfig)(aemConfiguration);
    const [platforms, setPlatforms] = (0, react_1.useState)();
    const [removeLinkPaths, setRemoveLinkPaths] = (0, react_1.useState)([]);
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    const [buttonState, setButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const [authErrorMessage, setAuthErrorMessage] = (0, react_1.useState)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const fetchAccounts = (0, react_1.useCallback)(() => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const connectedAccountsResponse = yield CustomerApi_1.default.getSocialMediaConnections(servicesEndpoint, defaultRequestParams, profile.email, profile.customerIdentityId);
            if (isMounted()) {
                setPlatforms(CustomerUtil_1.default.sortSocialMediaConnections(connectedAccountsResponse, enabledList));
                setButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            }
        }
        catch (_a) { }
    }), [profile, isMounted]);
    (0, react_1.useEffect)(() => {
        if (!profile) {
            dispatch(CustomerActions_creators_1.default.getProfile());
            return undefined;
        }
        fetchAccounts();
    }, [profile, fetchAccounts]);
    if (!platforms) {
        return null;
    }
    const handleRemoveConnectionLink = (connectionPath) => () => {
        setRemoveLinkPaths(prev => {
            const pathIndex = prev.indexOf(connectionPath);
            if (pathIndex < 0) {
                return [...prev, connectionPath];
            }
            prev.splice(pathIndex, 1);
            return prev;
        });
    };
    const handleRemoveConnection = (connection) => () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield CustomerApi_1.default.removeSocialMediaConnection(servicesEndpoint, defaultRequestParams, connection);
            fetchAccounts();
        }
        catch (_b) { }
    });
    const handleSocialLoginFacebook = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setAuthErrorMessage(undefined);
        setButtonState(cotopaxi_1.ButtonState.LOADING);
        try {
            yield AuthSocialLoginFacebookApi_1.default.addAccount();
            setButtonState(cotopaxi_1.ButtonState.FINISHED);
            fetchAccounts();
        }
        catch (error) {
            setButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
            setAuthErrorMessage(error.message || error);
        }
    });
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { tablet: 6, desktop: 6, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t('profileDetails.accounts.heading') }) }), platforms.map((platform, index) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: index === 0 ? 0 : 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutterY: cotopaxi_1.GroupGutter.XSMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: accountIconMap[platform.title], size: cotopaxi_1.IconSize.EXTRA_MAJOR }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: t('account.social.media.connections.linked.account', {
                                            platform: platform.title,
                                        }) })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), platform.connections.length === 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1, paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, align: cotopaxi_1.TextAlign.CENTER, children: t('account.social.media.connections.none') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.List, { spacing: cotopaxi_1.ListSpacing.MEDIUM, children: platform.connections.map((connection, connectionIndex) => {
                                const connectionPath = `platforms.${index}.connections.${connectionIndex}`;
                                return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.List.Item, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 1, paddingBottom: 1 } }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutterX: cotopaxi_1.GroupGutter.SMALL, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: connection.name }), connection.email && (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, children: ["(", connection.email, ")"] })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.BOTTOM, column: true, children: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleRemoveConnectionLink(connectionPath), text: t('account.social.media.connections.disconnect.button') }) }) })] }), (0, jsx_runtime_1.jsx)("div", { children: removeLinkPaths.includes(connectionPath) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleRemoveConnection(connection), variant: cotopaxi_1.ButtonVariant.LINK, text: t('account.social.media.connections.disconnect.link', {
                                                            email: connection.email,
                                                        }) })) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }, connectionIndex));
                            }) })), platform.title === common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK &&
                            platform.connections.length === 0 && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: handleSocialLoginFacebook, variant: cotopaxi_1.ButtonVariant.FACEBOOK, state: buttonState, text: t('account.social.media.connections.add.account', {
                                        platform: 'Facebook',
                                    }), iconLeft: { name: cotopaxi_1.IconName.FACEBOOK_BOX } }), authErrorMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.NEGATIVE, children: (0, LoginUtil_1.getLoginError)(authErrorMessage, t) }) }))] }))] }, index)))] }) }));
};
exports.ConnectedAccounts = ConnectedAccounts;
