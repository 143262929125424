"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ProductWizard_1 = require("../../components/product-wizard/ProductWizard");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ProductWizardContainer = ({ path, trigger, loggedIn, userFirstName, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const searchUrl = (0, ConfigurationUtil_1.getFredHopperSearchUrl)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const { mainWebshop: mainWebShop, language: locale, anaLang, shopId: fictiveWebShop, } = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [data, setData] = (0, react_1.useState)(null);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const loadProductWizardData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const axiosConfig = {
            method: 'GET',
            url: path,
            withCredentials: true,
        };
        try {
            const response = yield axios_1.default.request(axiosConfig);
            return response.data;
        }
        catch (_a) {
            return null;
        }
    });
    const fetchSearchResults = (filter) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const axiosConfig = {
            method: 'GET',
            params: {
                anaLang,
                fictiveWebShop,
                locale,
                mainWebShop,
                platform: isShoplanding
                    ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
                    : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site,
            },
            url: `${searchUrl}?filter=${filter}`,
            withCredentials: true,
        };
        try {
            const response = yield axios_1.default.request(axiosConfig);
            return response.data.itemCount;
        }
        catch (_b) {
            return null;
        }
    });
    const openProductWizard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const productWizardData = yield loadProductWizardData();
        if (productWizardData) {
            setData(productWizardData);
        }
    });
    (0, react_1.useEffect)(() => {
        data && handlePopoverOpen(null, null, true);
    }, [data]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductWizardTile, Object.assign({}, trigger, { onClick: openProductWizard })), data && ((0, jsx_runtime_1.jsx)(ProductWizard_1.ProductWizard, { fetchSearchResults: fetchSearchResults, productWizardData: data, anchorEl: anchorEl, handlePopoverClose: handlePopoverClose, userFirstName: userFirstName, loggedIn: loggedIn }))] }));
};
const makeMapStateToProps = () => {
    const getFirstName = (0, SessionSelector_1.makeGetFirstName)();
    const getLoggedInState = (0, SessionSelector_1.makeGetLoggedInState)();
    const mapStateToProps = state => ({
        loggedIn: getLoggedInState(state),
        userFirstName: getFirstName(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(ProductWizardContainer);
