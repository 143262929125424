"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewBuyAndGetMessages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const BasketOverviewBuyAndGetMessages = ({ enrichedMessages, loadProductDataByProductCode, openPromoBasketPopup, messageBoxSpacing, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.checkout, cotopaxi_1.I18N_NAMESPACES.buy]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: enrichedMessages.map(message => {
            const openPopup = (e) => {
                e.preventDefault();
                message.productCode && loadProductDataByProductCode(message.productCode);
                openPromoBasketPopup(message.message);
            };
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MEDIUM, text: message.productCode ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [message.message, ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: "", onClick: openPopup, children: t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:basket.buyandgetbanner.message.link`) })] })) : undefined, html: !message.productCode ? message.message : undefined }) }), message.productCode));
        }) }));
};
exports.BasketOverviewBuyAndGetMessages = BasketOverviewBuyAndGetMessages;
