"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ScreenWidthConstants_1 = require("../../constants/ScreenWidthConstants");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../util/ImageUtil");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const react_redux_1 = require("react-redux");
const MiniPDPContainer_1 = require("../../containers/MiniPDPContainer");
const SliderConstants_1 = require("../../constants/SliderConstants");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const MiniPDPMultiContainer_1 = require("../../containers/MiniPDPMultiContainer");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const ActionEnums_1 = require("../../enums/ActionEnums");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const ProductUtil_1 = require("../../util/ProductUtil");
const CompareActions_creators_1 = require("../../redux/compare/actions/CompareActions.creators");
const CompareSelectors_1 = require("../../redux/compare/selectors/CompareSelectors");
const DeliveryPromiseAPI_1 = require("../../api/DeliveryPromiseAPI");
const CampaignGroupSlideRender = ({ cdnSettings, tile, isMobileSmall, shouldMaintainAspectRatio, setMiniPDPProduct, handleMultiMiniPDPOpen, productTileFormat, compareItems, showCompare, deliveryPromiseExplanations, }) => {
    var _a;
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const authenticationConfig = (0, ConfigurationUtil_1.getAuthenticationConfig)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const wishListProducts = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishListProducts)());
    const [deliveryPromise, setDeliveryPromise] = (0, react_1.useState)();
    const product = ((_a = tile.fallbackProducts) === null || _a === void 0 ? void 0 : _a.length) ? (0, common_utils_1.fixProductColoursFallback)(tile.product) : tile.product;
    const updatedProduct = product
        ? Object.assign(Object.assign({}, product), { colours: product.colours.map(colour => (Object.assign(Object.assign({}, colour), { inWishList: !!wishListProducts.find(wishListProduct => wishListProduct.colorId === colour.colourId && wishListProduct.productId === product.productId) }))) }) : null;
    const handleClick = (e) => {
        if (tile.openAs === common_types_1.CampaignGroupOpenAs.LINK) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (tile.openAs === common_types_1.CampaignGroupOpenAs.MINI_PDP_MULTI) {
            handleMultiMiniPDPOpen(tile);
        }
        else {
            setMiniPDPProduct(updatedProduct);
        }
    };
    const handleKeyUp = (e) => {
        if (tile.openAs === common_types_1.CampaignGroupOpenAs.LINK || e.key !== 'Enter') {
            return;
        }
        setMiniPDPProduct(updatedProduct);
    };
    const handleWishListToggle = () => {
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct({
            colorId: updatedProduct.colourId,
            productCode: updatedProduct.productCode,
            productId: updatedProduct.productId,
        }));
    };
    const handleAddToBasket = (productData) => {
        dispatch(BasketActions_creators_1.basketActions.createAddExtensiveTileProductAction(productData, ActionEnums_1.ProductAddToBasketLocations.CAMPAIGN_GROUP));
    };
    const handleUpdateCompare = (compareProduct) => {
        dispatch(CompareActions_creators_1.default.createUpdateOnProductDetailPageAction(compareProduct));
    };
    const fetchDeliveryPromise = (_b) => tslib_1.__awaiter(void 0, [_b], void 0, function* ({ skuId, productId, }) {
        const fetchedDeliveryPromise = yield DeliveryPromiseAPI_1.default.getDeliveryPromise(servicesEndpoint, skuId, productId, authenticationConfig);
        !!fetchedDeliveryPromise && setDeliveryPromise(fetchedDeliveryPromise);
    });
    switch (tile.type) {
        case common_types_1.CampaignGroupTileType.TEXT:
            return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.CampaignGroupSlide, { shouldMaintainAspectRatio: shouldMaintainAspectRatio, isMobileSmall: isMobileSmall, children: [tile.openAs === common_types_1.CampaignGroupOpenAs.LINK && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: tile.href, variant: cotopaxi_1.LinkVariant.CONTAINER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { html: tile.text }) })), tile.openAs === common_types_1.CampaignGroupOpenAs.MINI_PDP && ((0, jsx_runtime_1.jsx)("div", { role: "button", tabIndex: 0, onClick: handleClick, onKeyUp: handleKeyUp, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { html: tile.text }) })), tile.openAs === common_types_1.CampaignGroupOpenAs.NONE && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { html: tile.text })] }));
        case common_types_1.CampaignGroupTileType.IMAGE: {
            const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
            const image = tile.openAs === common_types_1.CampaignGroupOpenAs.MINI_PDP_MULTI
                ? tile.images.find(item => item.isDefault) || tile.images[0]
                : tile.image;
            const imageUrl = (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix, undefined, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.imageQuality, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.requestPassThrough);
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CampaignGroupSlide, { shouldMaintainAspectRatio: shouldMaintainAspectRatio, isMobileSmall: isMobileSmall, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.BannerTile, { onClick: handleClick, url: tile.href, src: imageUrl, tags: tile.imageLabel && (0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.GENERAL, children: tile.imageLabel }) }) }));
        }
        case common_types_1.CampaignGroupTileType.PRODUCT:
        default:
            return updatedProduct ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.CampaignGroupSlide, { isMobileSmall: isMobileSmall, children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: Object.assign(Object.assign({}, updatedProduct), { deliveryPromise: deliveryPromise }), format: productTileFormat, onTileClick: handleClick, onWishListToggle: handleWishListToggle, addToBasket: handleAddToBasket, showColorOutOfStockOverlay: true, showProductOutOfStockOverlay: true, additionalQueryParameters: tile.queryParameters, inStoreOnly: (0, ProductUtil_1.isInStoreOnly)(updatedProduct), clickAndCollectOnly: (0, ProductUtil_1.isClickAndCollectOnly)(updatedProduct), getDeliveryPromise: fetchDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, dropdownProps: { appendToBody: true }, updateCompareList: handleUpdateCompare, compareItems: compareItems, showComparison: showCompare, showComparisonOnAllPages: true, showCompareForExtensiveTile: showCompare, adjustDescriptionHeight: productTileFormat === 'simple', description: productTileFormat === 'simple' &&
                        tile.shortDescription && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, type: cotopaxi_1.TextType.CONTENT, whiteSpace: "pre-wrap", children: tile.shortDescription })) }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.CampaignGroupSlide, { shouldMaintainAspectRatio: shouldMaintainAspectRatio, isMobileSmall: isMobileSmall }));
    }
};
const CampaignGroup = ({ hideForDesktop, hideOnViewports, innerSpacing, displayArrows, showIndicators, slidesToScroll, slidesToShow, title, subTitle, type, tiles, deliveryPromiseExplanations, cdnSettings, mainWebshopId, productTileFormat, showCompare, }) => {
    const isClient = (0, cotopaxi_1.useIsClient)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isTabletSmallAndBellow = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isMobileLarge = (0, cotopaxi_1.useMediaQuery)(`(min-width: ${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE_LARGE}px) and (max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_MOBILE_LARGE}px)`);
    const isMobile = (0, cotopaxi_1.useMediaQuery)(`(max-width: ${ScreenWidthConstants_1.MAX_SCREEN_WIDTH_MOBILE}px)`);
    const [miniPDPProduct, setMiniPDPProduct] = (0, react_1.useState)(null);
    const [activeMultiTile, setActiveMultiTile] = (0, react_1.useState)(null);
    const [isOpenMultiMiniPDP, setIsOpenMultiMiniPDP] = (0, react_1.useState)(false);
    const compareItems = (0, react_redux_1.useSelector)((0, CompareSelectors_1.makeGetCompareItems)());
    const dispatch = (0, react_redux_1.useDispatch)();
    if (!isClient)
        return null;
    const isMobileOrMobileLarge = isMobile || isMobileLarge;
    if ((hideForDesktop && !isTabletSmallAndBellow) || (hideOnViewports && isTabletSmallAndBellow)) {
        return null;
    }
    const isFloating = type === 'floating';
    const getShowPagination = () => {
        if (!showIndicators) {
            if (!isFloating || (isFloating && isDesktop)) {
                return false;
            }
            // Show indicator for non desktop floating sliders
            return true;
        }
        return showIndicators && isFloating;
    };
    const shouldCenterSlides = () => {
        // It should center slides when its mobile or mobile large,
        // it is a floating carousel and only 1 slide needs to be shown.
        if (!isMobileOrMobileLarge || !isFloating) {
            return false;
        }
        return (isMobileLarge && slidesToShow.mobileLarge === 1) || (isMobile && slidesToShow.mobile === 1);
    };
    const showNavigation = !isFloating || (isDesktop && displayArrows);
    const showPagination = getShowPagination();
    const shouldMaintainAspectRatio = !tiles.some(tile => tile.type === common_types_1.CampaignGroupTileType.PRODUCT && tile.product);
    const campaignGroupSliderCarousel = {
        variant: cotopaxi_1.CarouselVariant.CAMPAIGN_GROUP,
        showPagination,
        arrowsConfig: {
            variant: isMobileOrMobileLarge ? cotopaxi_1.SliderNavVariant.SEMICIRCLE : cotopaxi_1.SliderNavVariant.CIRCLE,
            color: cotopaxi_1.SliderNavColor.PRIMARY,
        },
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: isFloating ? slidesToShow.mobile + 0.3 : slidesToShow.mobile,
            slidesPerGroup: slidesToScroll.mobile,
            spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.MOBILE : 0,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            centeredSlides: shouldCenterSlides(),
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE_LARGE]: {
                    spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.MOBILE : 0,
                    slidesPerView: isFloating ? slidesToShow.mobileLarge + 0.3 : slidesToShow.mobileLarge,
                    slidesPerGroup: slidesToScroll.mobileLarge,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL]: {
                    spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.TABLET : 0,
                    slidesPerView: isFloating ? slidesToShow.tabletSmall + 0.3 : slidesToShow.desktop,
                    slidesPerGroup: slidesToScroll.tabletSmall,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.TABLET : 0,
                    slidesPerView: isFloating ? slidesToShow.tablet + 0.6 : slidesToShow.tablet,
                    slidesPerGroup: slidesToScroll.tablet,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.TABLET : 0,
                    slidesPerView: isFloating ? slidesToShow.desktop + 0.6 : slidesToShow.desktop,
                    slidesPerGroup: slidesToScroll.desktop,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    spaceBetween: innerSpacing ? SliderConstants_1.SLIDE_SPACING.TABLET : 0,
                    slidesPerView: isFloating ? slidesToShow.desktopLarge + 0.6 : slidesToShow.desktopLarge,
                    slidesPerGroup: slidesToScroll.desktopLarge,
                },
            },
        },
    };
    if (!showNavigation) {
        campaignGroupSliderCarousel.configurationOverrides.navigation = false;
    }
    const handleMiniPDPClose = () => {
        setMiniPDPProduct(null);
    };
    const handleMultiMiniPDPOpen = (tile) => {
        const defaultImage = tile.images.find(image => image.isDefault) || tile.images[0];
        const image = defaultImage.imagePath.split('/');
        dispatch(AnalyticsActions_creators_1.analyticsActions.shopTheLookOpened(image[image.length - 1]));
        setActiveMultiTile(tile);
        setIsOpenMultiMiniPDP(true);
    };
    const handleMultiMiniPDPClose = () => {
        setIsOpenMultiMiniPDP(false);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(title || subTitle) && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { alignment: cotopaxi_1.HeadingAlignment.CENTER, size: cotopaxi_1.HeadingSize.L, children: title })), subTitle && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { align: cotopaxi_1.TextAlign.CENTER, children: subTitle })] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, campaignGroupSliderCarousel, { children: tiles.map((tile, index) => ((0, jsx_runtime_1.jsx)(CampaignGroupSlideRender, { tile: tile, isMobileSmall: slidesToShow.mobile > 1, shouldMaintainAspectRatio: shouldMaintainAspectRatio, setMiniPDPProduct: setMiniPDPProduct, handleMultiMiniPDPOpen: handleMultiMiniPDPOpen, cdnSettings: cdnSettings, productTileFormat: productTileFormat, compareItems: compareItems, showCompare: showCompare, deliveryPromiseExplanations: deliveryPromiseExplanations }, `campaign_group_slide_${index}`))) })), (0, jsx_runtime_1.jsx)(MiniPDPContainer_1.MiniPDPContainer, { displayPackShotImagesFirst: true, productCode: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.productCode, colorId: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.colourId, onClose: handleMiniPDPClose, deliveryPromiseExplanations: deliveryPromiseExplanations }), (0, jsx_runtime_1.jsx)(MiniPDPMultiContainer_1.default, { isOpen: isOpenMultiMiniPDP, onClose: handleMultiMiniPDPClose, productIds: activeMultiTile === null || activeMultiTile === void 0 ? void 0 : activeMultiTile.multipleProducts, extensiveCarouselImages: activeMultiTile === null || activeMultiTile === void 0 ? void 0 : activeMultiTile.images, cdnSettings: cdnSettings, popoverTitle: activeMultiTile === null || activeMultiTile === void 0 ? void 0 : activeMultiTile.title, mainWebshopId: mainWebshopId })] }));
};
exports.default = CampaignGroup;
