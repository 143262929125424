"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerSeoTextContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../../components/common/html-include/HtmlInclude");
const _ListerSeoTextContainer = ({ seoTextInclude }) => {
    if (!seoTextInclude || seoTextInclude.trim().length < 1) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: {
                marginBottom: 2,
                marginTop: 2,
            },
        }, tablet: { spacing: { marginBottom: 3, marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: seoTextInclude }) }));
};
exports.ListerSeoTextContainer = _ListerSeoTextContainer;
