"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerReducer = exports.loadCustomerReducer = exports.initialState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialState = {};
const loadCustomerReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        switch (action.type) {
            case ActionEnums_1.CustomerActionTypes.GET_PROFILE:
                return Object.assign(Object.assign({}, state), { profile: {} });
            case ActionEnums_1.CustomerActionTypes.SET_PROFILE:
                return Object.assign(Object.assign({}, state), { profile: action.payload });
            case ActionEnums_1.CustomerActionTypes.GET_DELIVERY_ADDRESSES:
                return Object.assign(Object.assign({}, state), { deliveryAddress: {} });
            case ActionEnums_1.CustomerActionTypes.SET_DELIVERY_ADDRESSES:
                return Object.assign(Object.assign({}, state), { billingAddress: action.payload.customerAddress, deliveryAddresses: action.payload.deliveryAddresses });
            default:
                return state;
        }
    };
};
exports.loadCustomerReducer = loadCustomerReducer;
exports.customerReducer = (0, exports.loadCustomerReducer)();
