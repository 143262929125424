"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageShow = void 0;
const react_1 = require("react");
// https://newbedev.com/prevent-safari-loading-from-cache-when-back-button-is-clicked
const usePageShow = (onPageShow) => {
    (0, react_1.useEffect)(() => {
        const handler = (e) => {
            if (e.persisted) {
                onPageShow ? onPageShow() : window.location.reload();
            }
        };
        window.addEventListener('pageshow', handler);
        return () => {
            window.removeEventListener('pageshow', handler);
        };
    }, []);
};
exports.usePageShow = usePageShow;
