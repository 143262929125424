"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationDeliveryStoreInfo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const StoreUtil_1 = require("../../../util/StoreUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_utils_1 = require("@as-react/common-utils");
const BuyConfirmationDeliveryStoreInfo = ({ store, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.store, i18nConstants_1.I18N_NAMESPACES.general]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { paragraph: true, type: cotopaxi_1.TextType.CONTENT, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, type: cotopaxi_1.TextType.CONTENT, children: StoreUtil_1.default.getStoreName(store, mainWebShop, t) }), (0, jsx_runtime_1.jsx)("br", {}), store.address.street, " ", store.address.number, (0, jsx_runtime_1.jsx)("br", {}), store.address.country === 'GB' ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [store.address.city, (0, jsx_runtime_1.jsx)("br", {}), store.address.zipCode] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [store.address.zipCode, " ", store.address.city] })), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { inline: true, name: cotopaxi_1.IconName.PHONE }), " ", store.phoneNumber] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, children: store.scheduleEntries.map(hour => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, mobile: 5, mobileLarge: 4, tabletSmall: 3, tablet: 2, desktop: 3, desktopLarge: 2, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, type: cotopaxi_1.TextType.CONTENT, children: (0, common_utils_1.capitalizeOnly)(t(`store.day.${hour.day.toLowerCase()}`)) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, mobile: 5, mobileLarge: 4, tabletSmall: 3, tablet: 2, desktop: 3, desktopLarge: 2, children: hour.closed ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, type: cotopaxi_1.TextType.CONTENT, children: t('store.closed.txt') })) : ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, type: cotopaxi_1.TextType.CONTENT, children: [hour.openingHour, " - ", hour.closingHour] })) })] }, hour.day))) }) })] }));
};
exports.BuyConfirmationDeliveryStoreInfo = BuyConfirmationDeliveryStoreInfo;
