"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DEFAULT = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE_LARGE = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET_SMALL = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP = exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP_LARGE = exports.MIN_SCREEN_WIDTH_MOBILE = exports.MAX_SCREEN_WIDTH_MOBILE = exports.MAX_SCREEN_WIDTH_MOBILE_LARGE = exports.MIN_SCREEN_WIDTH_MOBILE_LARGE = exports.MAX_SCREEN_WIDTH_TABLET_SMALL = exports.MIN_SCREEN_WIDTH_TABLET_SMALL = exports.MAX_SCREEN_WIDTH_TABLET = exports.MIN_SCREEN_WIDTH_TABLET = exports.MIN_SCREEN_WIDTH_DESKTOP = exports.MIN_SCREEN_WIDTH_DESKTOP_LARGE = void 0;
exports.MIN_SCREEN_WIDTH_DESKTOP_LARGE = 1400;
exports.MIN_SCREEN_WIDTH_DESKTOP = 1024;
exports.MIN_SCREEN_WIDTH_TABLET = 768;
exports.MAX_SCREEN_WIDTH_TABLET = 1023;
exports.MIN_SCREEN_WIDTH_TABLET_SMALL = 640;
exports.MAX_SCREEN_WIDTH_TABLET_SMALL = 767;
exports.MIN_SCREEN_WIDTH_MOBILE_LARGE = 401;
exports.MAX_SCREEN_WIDTH_MOBILE_LARGE = 639;
exports.MAX_SCREEN_WIDTH_MOBILE = 400;
exports.MIN_SCREEN_WIDTH_MOBILE = 0;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP_LARGE = 4;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DESKTOP = 3;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET = 3;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_TABLET_SMALL = 2;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE_LARGE = 2;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_MOBILE = 2;
exports.NUMBER_OF_ITEMS_IN_LISTER_ROW_DEFAULT = 2;
