"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const CookieMessage_1 = require("./CookieMessage");
const CookiePreference_1 = require("./CookiePreference");
const CookiePreferences = ({ isCookiePreferenceOpen, isCookiePreferencesOpen, checkedPreferences, setIsCookiePreferenceOpen, onCookiePreferenceClose, onCookiePreferencesClose, saveCookiePreferences, changeCookiePreference, showCookiePreference, panel2, logoUrl, preferenceData, }) => {
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close: onCookiePreferencesClose });
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    (0, react_1.useEffect)(() => {
        if (isCookiePreferencesOpen) {
            handlePopoverOpen(null, null, true, true);
        }
    }, [isCookiePreferencesOpen]);
    const closeCookiePreferencesPopup = (group) => {
        handlePopoverClose();
        setIsCookiePreferenceOpen(true);
        showCookiePreference(group);
    };
    const saveCookiePreferencesAndClose = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield saveCookiePreferences();
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { multi: true, disableClickOutside: true, noAnimation: true, anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, position: cotopaxi_1.PopoverPosition.BOTTOM, onClose: handlePopoverClose, headerAlign: cotopaxi_1.PopoverHeaderAlign.CENTER, title: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: logoUrl, size: cotopaxi_1.ImageSize.COOKIE_BRAND_LOGO }), footerElement: isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, text: panel2.btnLabel, onClick: saveCookiePreferencesAndClose, iconRight: {
                        name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } })), children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                paddingLeft: 2,
                                paddingRight: 2,
                            },
                        }, tablet: {
                            spacing: {
                                paddingLeft: 4,
                                paddingRight: 4,
                            },
                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: panel2.title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: {
                                        marginTop: 1.5,
                                        marginBottom: 1.5,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: panel2.description }) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: isMobile && cotopaxi_1.ScrollableListVariant.SHADOW, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    paddingBottom: 2,
                                },
                            }, tablet: {
                                spacing: {
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                },
                            }, children: panel2.cookieGroups.map((group) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: {
                                        marginTop: 2,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: `cookie-group-${group.key}`, name: "cookie-group", value: group.key, readOnly: group.key === CookieMessage_1.FUNCTIONAL_KEY, defaultChecked: checkedPreferences[group.key], onChange: changeCookiePreference, labelChildren: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: group.title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, variant: cotopaxi_1.ButtonVariant.INVERSE_ALT, onClick: () => closeCookiePreferencesPopup(group), size: cotopaxi_1.ButtonSize.SMALL, icon: {
                                                    name: cotopaxi_1.IconName.INFORMATION_OUTLINE,
                                                    color: cotopaxi_1.IconColor.ACTION,
                                                } }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                                    spacing: {
                                                        marginTop: 0.5,
                                                    },
                                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: group.description }) })] }) }) }, group.key))) }) }), !isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                paddingTop: 3,
                                paddingRight: 4,
                                paddingBottom: 4,
                            },
                        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: saveCookiePreferencesAndClose, text: panel2.btnLabel, iconRight: {
                                    name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                                    size: cotopaxi_1.IconSize.MAJOR,
                                } }) }) }))] }), (0, jsx_runtime_1.jsx)(CookiePreference_1.default, { preferenceData: preferenceData, logoUrl: logoUrl, isCookiePreferenceOpen: isCookiePreferenceOpen, onCookiePreferenceClose: onCookiePreferenceClose })] }));
};
exports.default = CookiePreferences;
