"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AMEX_METHOD = exports.AMEX_BRAND = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const BuyAPI_1 = require("../../api/buy/BuyAPI");
const NewCreditCardForm_1 = require("../../components/buy/payment/payment-groups/NewCreditCardForm");
const SavedCreditCardForm_1 = require("../../components/buy/payment/payment-groups/SavedCreditCardForm");
const SaferpayUtil_1 = require("../../util/SaferpayUtil");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const useABTest_1 = require("../../hooks/useABTest");
exports.AMEX_BRAND = 'American Express';
exports.AMEX_METHOD = 'AMEX';
const PaymentGroupCreditCardContainer = ({ group, creditCardSavingLegalMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { fields } = (0, ConfigurationUtil_1.getSaferpayConfig)(aemConfiguration);
    const disableAmexPaymentCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.DISABLE_AMEX_PAYMENT);
    const saferpayFieldsReady = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetSaferpayFieldsReady)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [validation, setValidation] = (0, react_1.useState)();
    const [showError, setShowError] = (0, react_1.useState)(false);
    const [saveCreditCardForFutureUse, setSaveCreditCardForFutureUse] = (0, react_1.useState)(false);
    const paymentMethods = group.paymentOptions.map(option => option.name.toLowerCase());
    let groupOgoneAliases = group.ogoneAliases || [];
    if (disableAmexPaymentCookie) {
        groupOgoneAliases = groupOgoneAliases.filter(card => card.brand !== exports.AMEX_BRAND);
    }
    const [selectedSavedCard, setSelectedSavedCard] = (0, react_1.useState)(groupOgoneAliases[0]);
    const [showSavedCardsForm, setShowSavedCardsForm] = (0, react_1.useState)(groupOgoneAliases.length > 0);
    const onSaveCreditCardChange = () => setSaveCreditCardForFutureUse(prev => !prev);
    const onSelectedCardChange = (selectedCardId) => {
        const card = groupOgoneAliases.find(creditCard => creditCard.id === selectedCardId);
        setSelectedSavedCard(card);
    };
    const toggleSavedCardForm = () => {
        setValidation(undefined);
        setShowSavedCardsForm(prev => !prev);
        showError && setShowError(false);
    };
    const checkSaferpayStatusAndInit = () => {
        try {
            window.SaferpayFields.init({
                accessToken: fields.accessToken,
                url: fields.apiUrl,
                paymentMethods: paymentMethods,
                placeholders: {
                    cardnumber: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CARDNUMBER}.placeholder`),
                    holdername: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.HOLDERNAME}.placeholder`),
                    expiration: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.EXPIRATION}.placeholder`),
                    cvc: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CVC}.placeholder`),
                },
                style: SaferpayUtil_1.saferpayFieldStyles,
                onError: () => setShowError(true),
                onValidated: (e) => setValidation(prev => (Object.assign(Object.assign({}, prev), { [e.fieldType]: e }))),
            });
        }
        catch (e) {
            setShowError(true);
        }
    };
    const submitWithSavedCard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const { redirectUrl } = yield BuyAPI_1.default.postSaferpayAlias(servicesEndpoint, defaultRequestParams, selectedSavedCard.id);
            location.href = redirectUrl;
        }
        catch (err) {
            setIsSubmitting(false);
            setShowError(true);
        }
    });
    const submitWithNewCard = () => {
        window.SaferpayFields.submit({
            onSuccess: (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ token }) {
                try {
                    const { redirectUrl } = yield BuyAPI_1.default.postSaferpayToken(servicesEndpoint, defaultRequestParams, token, saveCreditCardForFutureUse);
                    location.href = redirectUrl;
                }
                catch (err) {
                    setIsSubmitting(false);
                    setShowError(true);
                }
            }),
            onError: ({ message }) => {
                setIsSubmitting(false);
            },
        });
    };
    const onSubmit = () => {
        setIsSubmitting(true);
        setShowError(false);
        dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
            paymentGroup: common_types_1.PaymentGroupName.CREDITCARD,
        }));
        if (showSavedCardsForm) {
            submitWithSavedCard();
        }
        else {
            submitWithNewCard();
        }
    };
    (0, react_1.useEffect)(() => {
        if (saferpayFieldsReady && !showSavedCardsForm) {
            checkSaferpayStatusAndInit();
        }
    }, [saferpayFieldsReady, showSavedCardsForm]);
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: onSubmit, buttonState: isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined, checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: showError ? t('buy.payment.form.error') : undefined, children: showSavedCardsForm ? ((0, jsx_runtime_1.jsx)(SavedCreditCardForm_1.default, { onSelectedCardChange: onSelectedCardChange, savedCards: groupOgoneAliases, selectedSavedCard: selectedSavedCard, toggleSavedCardForm: toggleSavedCardForm })) : ((0, jsx_runtime_1.jsx)(NewCreditCardForm_1.default, { paymentMethods: paymentMethods, onSaveCreditCardChange: onSaveCreditCardChange, saveCreditCard: saveCreditCardForFutureUse, showSaveCardBlock: isLoggedIn && group.supportsTokenization, hasSavedCards: groupOgoneAliases.length > 0, toggleSavedCardForm: toggleSavedCardForm, validation: validation, creditCardSavingLegalMessage: creditCardSavingLegalMessage })) }));
};
exports.default = PaymentGroupCreditCardContainer;
