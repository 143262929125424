"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const iconDefaults = {
    size: cotopaxi_1.IconSize.MEDIUM,
    spacing: { padding: 1 },
};
const alignmentDefaults = {
    align: cotopaxi_1.GroupAlign.CENTER,
    valign: cotopaxi_1.GroupVerticalAlign.CENTER,
};
const SocialLinks = ({ title, emphasizeTitle = false, socialMediaLinks, icon, iconColor, alignment, iconsGutter = cotopaxi_1.GroupGutter.NONE, }) => {
    const filteredLinks = socialMediaLinks && socialMediaLinks.filter(({ socialLink }) => !!socialLink);
    if (!filteredLinks || filteredLinks.length === 0)
        return null;
    const socialIcon = Object.assign(Object.assign({}, iconDefaults), { color: iconColor });
    const groupAlignment = Object.assign(Object.assign({}, alignmentDefaults), alignment);
    const getIconName = (socialMedia) => {
        switch (socialMedia) {
            case common_types_1.SocialMedia.FACEBOOK:
                return cotopaxi_1.IconName.FACEBOOK_BOX;
            case common_types_1.SocialMedia.INSTAGRAM:
                return cotopaxi_1.IconName.INSTAGRAM;
            case common_types_1.SocialMedia.TWITTER:
                return cotopaxi_1.IconName.TWITTER;
            case common_types_1.SocialMedia.LINKEDIN:
                return cotopaxi_1.IconName.LINKEDIN_BOX;
            case common_types_1.SocialMedia.PINTEREST:
                return cotopaxi_1.IconName.PINTEREST_BOX;
            case common_types_1.SocialMedia.YOUTUBE:
                return cotopaxi_1.IconName.YOUTUBE_BOX;
            case common_types_1.SocialMedia.STRAVA:
                return cotopaxi_1.IconName.STRAVA;
            case common_types_1.SocialMedia.TIKTOK:
                return cotopaxi_1.IconName.TIKTOK;
            default:
                return null;
        }
    };
    const renderSocialLink = (socialLink, index) => {
        const name = getIconName(socialLink.socialName);
        if (!name) {
            return null;
        }
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: socialLink.socialLink, target: cotopaxi_1.LinkTarget.BLANK, variant: cotopaxi_1.LinkVariant.SUBTLE_ALT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: socialIcon.spacing }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: name, size: socialIcon.size, color: socialIcon.color === cotopaxi_1.IconColor.DEFAULT ? undefined : socialIcon.color }) }) }, index));
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: groupAlignment.align, valign: groupAlignment.valign, gutter: cotopaxi_1.GroupGutter.SMALL, column: true, children: [title ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: emphasizeTitle, color: cotopaxi_1.TextColor.INHERIT, children: title })) : null, (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, gutter: iconsGutter, children: filteredLinks.map((social, index) => renderSocialLink(social, index)) })] }));
};
exports.default = SocialLinks;
