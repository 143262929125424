"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const AccountMenuContainer_1 = require("./AccountMenuContainer");
const AuthenticationContainer_1 = require("../AuthenticationContainer");
const SecurityLevelsPopup_1 = require("../../components/security-levels-popup/SecurityLevelsPopup");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const AccountMenuItemContainer = ({ accountMenu, login, registration, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [didMount, setDidMount] = (0, react_1.useState)(false);
    const btnEl = (0, react_1.useRef)(null);
    const dispatch = (0, react_redux_1.useDispatch)();
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const open = (e) => {
        setTimeout(() => {
            handlePopoverOpen(e, btnEl.current);
        }, GeneralConstants_1.DELAY.DELAY_0);
    };
    (0, react_1.useEffect)(() => {
        dispatch(SessionActions_creators_1.default.createInitAction());
        setDidMount(true);
    }, []);
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { id: DOMElementsConstants_1.AUTH_POPUP_BUTTON_ID, variant: cotopaxi_1.ButtonVariant.LINK_SUBTLE, size: cotopaxi_1.ButtonSize.SMALL, onClick: handlePopoverOpen, dataQA: "account", text: loggedIn ? t('account.account') : t('account.login.title.login'), iconRight: {
                        name: anchorEl ? cotopaxi_1.IconName.CHEVRON_UP : cotopaxi_1.IconName.CHEVRON_DOWN,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MINOR,
                    } }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: open, innerRef: btnEl, variant: cotopaxi_1.ButtonVariant.NAVIGATION, dataQA: "account", icon: {
                        name: loggedIn ? cotopaxi_1.IconName.USER_CHECK_SOLID : cotopaxi_1.IconName.USER_SOLID,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }) }), didMount && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: loggedIn === true ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(AccountMenuContainer_1.AccountMenuContainer, { items: accountMenu.items, anchorEl: anchorEl, onClose: handlePopoverClose }), (0, jsx_runtime_1.jsx)(SecurityLevelsPopup_1.SecurityLevelsPopup, {})] })) : ((0, jsx_runtime_1.jsx)(AuthenticationContainer_1.AuthenticationContainer, { actionUri: (!!login && login.actionUri) || undefined, anchorEl: anchorEl, onClose: handlePopoverClose, registrationLink: (!!registration && registration.link) || undefined })) }))] }));
};
exports.default = AccountMenuItemContainer;
