"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleProductTileReducer = exports.loadSingleProductTileReducer = exports.initialState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialState = [];
const addProductToList = (products, newProduct) => {
    const product = products.find(({ productCode, colourId }) => newProduct.productCode === productCode && newProduct.colourId === colourId);
    if (product) {
        return products;
    }
    return [...products, newProduct];
};
const addBulkProductsToList = (products, newProducts) => {
    const mergedProducts = [...products, ...newProducts];
    return mergedProducts.filter((product, index, array) => index ===
        array.findIndex(item => item.productId === product.productId && item.productCode === product.productCode));
};
const loadSingleProductTileReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case ActionEnums_1.SingleProductTileActionTypes.ADD_PRODUCT_TO_LIST:
            return addProductToList(state, action.payload);
        case ActionEnums_1.SingleProductTileActionTypes.ADD_BULK_PRODUCTS_TO_LIST:
            return addBulkProductsToList(state, action.payload);
        case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
            return (0, WishListReducer_1.initializeWishListProducts)(state, action);
        case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, true);
        case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, false);
        case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
            return (0, CompareReducer_1.toggleComparedStateForItems)(state, action.payload);
        case ActionEnums_1.SingleProductTileActionTypes.SET_DELIVERY_PROMISE:
            return (0, ProductUtil_1.setDeliveryPromiseToProducts)(state, action.payload);
        default:
            return state;
    }
};
exports.loadSingleProductTileReducer = loadSingleProductTileReducer;
exports.singleProductTileReducer = exports.loadSingleProductTileReducer;
