"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointSearchForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const Yup = require("yup");
const FormField_1 = require("../../../form/FormField");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const PickupPointSearchForm = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [{ info }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { locationSearch, setLocationSearch, getPickupPointsQuery } = (0, CheckoutDeliveryContext_1.usePickupPointSearchContext)();
    const { isLoading, error: getPickupPointsError } = getPickupPointsQuery;
    const keyTranslation = info.country !== 'be'
        ? 'checkout:checkout.delivery.pickuppoint.search.postcode'
        : 'checkout:checkout.delivery.pickuppoint.search.zip.code.or.city';
    const validationSchema = Yup.object({
        location: Yup.string().required(t(common_types_1.ErrorMessage.REQUIRED)),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { location: locationSearch },
        mode: 'all',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const handleSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ location }) {
        setLocationSearch(location.trim());
    });
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), noValidate: true, name: "pickup-point-search-form", children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "6", children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "location", children: (_a) => {
                        var _b = _a.fieldProps, { ref, onChange } = _b, options = tslib_1.__rest(_b, ["ref", "onChange"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.SearchFieldAlpha, Object.assign({}, options, { input: {
                                id: 'pickuppoint-location',
                                autoComplete: 'location',
                                placeholder: t(keyTranslation),
                                error: getPickupPointsError ? getPickupPointsError.message : error,
                                disabled: isLoading,
                            }, onChange: (e, byEnterKey) => {
                                onChange(e);
                                !byEnterKey && formProps.handleSubmit(handleSubmit)();
                            } })));
                    } }) }) }) })));
};
exports.PickupPointSearchForm = PickupPointSearchForm;
