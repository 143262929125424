"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const logger_1 = require("@as-react/logger");
const ScriptLoaderUtil_1 = require("./ScriptLoaderUtil");
class KlarnaUtil {
    static init(_a) {
        return tslib_1.__awaiter(this, arguments, void 0, function* ({ token, url, key }) {
            yield ScriptLoaderUtil_1.default
                .loadScript(url, key, true)
                .then(() => {
                window.Klarna.Payments.init({ client_token: token });
            })
                .catch(logger_1.Logger.logError);
        });
    }
    static load(container, payment_method_categories, instance_id, cb) {
        window.Klarna.Payments.load({ container, payment_method_categories, instance_id }, cb);
    }
    static authorize(payment_method_categories, instance_id, cb) {
        window.Klarna.Payments.authorize({ payment_method_categories, instance_id }, cb);
    }
}
exports.default = KlarnaUtil;
