"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.increaseProductQuantitySaga = exports.increaseProductQuantity = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const BasketSagas_1 = require("./BasketSagas");
const CheckStockReservationSaga_1 = require("./CheckStockReservationSaga");
const common_types_1 = require("@as-react/common-types");
function* increaseProductQuantity(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(null));
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({
            product: action.payload.product,
            location: ActionEnums_1.ProductAddToBasketLocations.BASKET_QUANTITY,
        }));
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) {
        yield (0, effects_1.all)([(0, effects_1.call)(BasketSagas_1.getBasicBasket), (0, effects_1.call)(BasketSagas_1.getBasket), (0, effects_1.call)(CheckStockReservationSaga_1.checkStockReservation)]);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(common_types_1.BasketCartItemError.ADD_FAILED, action.payload.product));
    }
}
exports.increaseProductQuantity = increaseProductQuantity;
function* increaseProductQuantitySaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.INCREASE_QUANTITY, increaseProductQuantity);
}
exports.increaseProductQuantitySaga = increaseProductQuantitySaga;
