"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThrottle = void 0;
const useThrottle = (callback, delay) => {
    let waiting = false;
    return (...args) => {
        if (!waiting) {
            waiting = true;
            setTimeout(() => {
                waiting = false;
                callback(...args);
            }, delay);
        }
    };
};
exports.useThrottle = useThrottle;
