"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductGroup_1 = require("../../components/product-group/ProductGroup");
const ProductGroupActions_creators_1 = require("../../redux/productGroup/actions/ProductGroupActions.creators");
const ProductGroupSelectors_1 = require("../../redux/productGroup/selectors/ProductGroupSelectors");
const useProductTileHelpers_1 = require("../../hooks/useProductTileHelpers");
const ActionEnums_1 = require("../../enums/ActionEnums");
const ProductGroupContainer = ({ productGroupId, title, tileFormat, deliveryPromiseExplanations, collapsed, collapsible, }) => {
    var _a;
    const dispatch = (0, react_redux_1.useDispatch)();
    const { handleWishlistToggle, handleUpdateCompareList, handleAddToBasket, compareItems } = (0, useProductTileHelpers_1.useProductTileHelpers)();
    const productGroup = (0, react_redux_1.useSelector)((0, ProductGroupSelectors_1.makeGetProductGroupState)());
    const getDeliveryPromise = (payload) => dispatch(ProductGroupActions_creators_1.productGroupActions.getDeliveryPromise(Object.assign(Object.assign({}, payload), { productGroupId })));
    (0, react_1.useEffect)(() => {
        var _a;
        if (productGroupId && ((_a = productGroup === null || productGroup === void 0 ? void 0 : productGroup[productGroupId]) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(ProductGroupActions_creators_1.productGroupActions.init());
        }
    }, []);
    if (!productGroupId || !((_a = productGroup === null || productGroup === void 0 ? void 0 : productGroup[productGroupId]) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(ProductGroup_1.ProductGroup, { addToBasket: (...args) => handleAddToBasket(...args, ActionEnums_1.ProductAddToBasketLocations.PRODUCT_GROUP), collapsed: collapsed, collapsible: collapsible, compareItems: compareItems, deliveryPromiseExplanations: deliveryPromiseExplanations, getDeliveryPromise: getDeliveryPromise, onWishListToggle: handleWishlistToggle, products: productGroup[productGroupId], productGroupId: `product-group-${productGroupId}`, productTileFormat: tileFormat, title: title, updateCompareList: handleUpdateCompareList }));
};
exports.default = ProductGroupContainer;
