"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const qs_1 = require("qs");
const common_types_1 = require("@as-react/common-types");
class SearchApi {
    constructor() {
        this.searchSuggestions = (servicesApi, defaultRequestParams, query) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!query) {
                return null;
            }
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/aem/search/suggestion`,
                params: {
                    query,
                    mainWebshop: defaultRequestParams.mainWebshop,
                    language: defaultRequestParams.language,
                },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.searchContentSuggestion = (servicesApi, elasticsearchIndex, q) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (q) {
                try {
                    const axiosConfig = {
                        method: 'GET',
                        url: `${servicesApi}api/aem/search/suggestion/content`,
                        params: {
                            index: elasticsearchIndex,
                            q,
                        },
                        withCredentials: true,
                    };
                    const result = yield axios_1.default.request(axiosConfig);
                    return result.data;
                }
                catch (_a) {
                    return null;
                }
            }
            return null;
        });
    }
    getProductCountPerStore(servicesEndpoint, authenticationConfig, fredhopperSegment, urlState, isShoplanding, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/search/${authenticationConfig.market}/search/productcountperstore`,
                params: {
                    anaLang: authenticationConfig.anaLang,
                    fictiveWebShop: authenticationConfig.fictiveWebShop,
                    locale: authenticationConfig.locale,
                    market: authenticationConfig.market,
                    mainWebShop: authenticationConfig.mainWebshop,
                    segment: fredhopperSegment,
                    query: urlState.q,
                    size: urlState.size,
                    page: urlState.page,
                    platform: isShoplanding
                        ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
                        : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site,
                    filter,
                },
                paramsSerializer: params => (0, qs_1.stringify)(params, { arrayFormat: 'repeat' }),
            };
            try {
                const request = yield axios_1.default.request(axiosConfig);
                return request.data;
            }
            catch (_a) {
                return [];
            }
        });
    }
    searchFredhopperProducts(servicesEndpoint, defaultRequestParams, productId, productColourId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/aem/search/campaign`,
                params: {
                    mainWebShop: defaultRequestParams.mainWebshop,
                    locale: defaultRequestParams.language,
                    anaLang: defaultRequestParams.anaLang,
                    shopId: defaultRequestParams.shopId,
                    productId,
                    productColourId,
                },
            };
            const request = yield axios_1.default.request(axiosConfig);
            return request.data;
        });
    }
}
exports.default = new SearchApi();
