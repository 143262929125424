"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetBrandListerFilters = exports.makeGetBrandListerItems = void 0;
const reselect_1 = require("reselect");
const common_types_1 = require("@as-react/common-types");
const getBrandListerState = state => state.brandLister;
const makeGetBrandListerItems = () => (0, reselect_1.createSelector)(getBrandListerState, (brandLister) => {
    const activeFilter = brandLister.filters.find(item => item.active);
    const mappedItems = {};
    const addToMappedItems = (item) => {
        const startChar = item.description.charAt(0).toUpperCase();
        if (!mappedItems[startChar]) {
            mappedItems[startChar] = [];
        }
        mappedItems[startChar].push(item);
    };
    if (!activeFilter) {
        return mappedItems;
    }
    switch (activeFilter.key) {
        case common_types_1.BrandListerGroupedFilterTypes.ALL: {
            brandLister.items.forEach(addToMappedItems);
            break;
        }
        case common_types_1.BrandListerGroupedFilterTypes.POPULAR: {
            mappedItems[common_types_1.BrandListerGroupedFilterTypes.POPULAR] = brandLister.items
                .filter((item) => Object.hasOwn(brandLister.popularList, item.id))
                .sort((a, b) => {
                const compareA = brandLister.popularList[a.id].toString();
                const compareB = brandLister.popularList[b.id].toString();
                return compareA.localeCompare(compareB, undefined, {
                    sensitivity: 'base',
                    numeric: true,
                });
            })
                .reverse();
            break;
        }
        case common_types_1.BrandListerGroupedFilterTypes.NUMBERS: {
            brandLister.items.filter(item => item.description.charAt(0).match(/([0-9])/g)).forEach(addToMappedItems);
            break;
        }
        default: {
            brandLister.items
                .filter(item => item.description.charAt(0).toUpperCase() === activeFilter.key)
                .forEach(addToMappedItems);
            break;
        }
    }
    return mappedItems;
});
exports.makeGetBrandListerItems = makeGetBrandListerItems;
const makeGetBrandListerFilters = () => (0, reselect_1.createSelector)(getBrandListerState, (brandLister) => {
    return brandLister.filters;
});
exports.makeGetBrandListerFilters = makeGetBrandListerFilters;
