"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileOld = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FiltersMobileOldPopover_1 = require("./FiltersMobileOldPopover");
const react_i18next_1 = require("react-i18next");
const FiltersMobileOld = ({ activeFilters, filters, filterWizardConfig, fredhopperGlossary, isContentTileLister, itemCount, onFilterItemChange, removeAllFilters, storeOptions, urlFilters, }) => {
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.product, cotopaxi_1.I18N_NAMESPACES.general]);
    const getFilterText = () => {
        return isContentTileLister
            ? 'general:content.overview.filter.articles'
            : 'product:product.lister.filter.filter.product';
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handlePopoverOpen, iconLeft: !isContentTileLister
                    ? {
                        name: cotopaxi_1.IconName.FILTER,
                        size: cotopaxi_1.IconSize.MEDIUM,
                    }
                    : undefined, variant: isContentTileLister ? cotopaxi_1.ButtonVariant.BASE : cotopaxi_1.ButtonVariant.OUTLINED, noBubble: true, fill: true, text: t(getFilterText(), {
                    count: itemCount,
                }) }), (0, jsx_runtime_1.jsx)(FiltersMobileOldPopover_1.FiltersMobileOldPopover, { isContentTileLister: isContentTileLister, anchorEl: anchorEl, handlePopoverClose: handlePopoverClose, onFilterItemChange: onFilterItemChange, filters: filters, activeFilters: activeFilters, itemCount: itemCount, removeAllActiveFilters: removeAllFilters, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, urlFilters: urlFilters, storeOptions: storeOptions })] }));
};
exports.FiltersMobileOld = FiltersMobileOld;
