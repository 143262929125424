"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const FilterReducer_1 = require("../../filter/reducers/FilterReducer");
const FilterSagas_1 = require("../../filter/sagas/FilterSagas");
const loadFilterModule = (initialData) => ({
    id: 'Filter',
    reducerMap: {
        filters: (0, FilterReducer_1.loadFilterReducer)(initialData ? initialData.filters : null),
    },
    sagas: [FilterSagas_1.default],
});
exports.default = loadFilterModule;
