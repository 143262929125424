"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productRecentlyViewedReducer = exports.initialProductRecentlyViewedState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialProductRecentlyViewedState = [];
const productRecentlyViewedReducer = (state = exports.initialProductRecentlyViewedState, action) => {
    switch (action.type) {
        case ActionEnums_1.ProductRecentlyViewedActionTypes.FETCH:
            return action.payload;
        case ActionEnums_1.ProductRecentlyViewedActionTypes.ERASE:
            return exports.initialProductRecentlyViewedState;
        case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
            return (0, CompareReducer_1.toggleComparedStateForItems)(state, action.payload);
        case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
            return (0, WishListReducer_1.initializeWishListProducts)(state, action);
        case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, true);
        case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, false);
        case ActionEnums_1.ProductRecentlyViewedActionTypes.SET_DELIVERY_PROMISE:
            return (0, ProductUtil_1.setDeliveryPromiseToProducts)(state, action.payload);
        default:
            return state;
    }
};
exports.productRecentlyViewedReducer = productRecentlyViewedReducer;
