"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable @typescript-eslint/no-explicit-any */
const formik_1 = require("formik");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const FormValidationUtil_1 = require("../../../../util/FormValidationUtil");
const DateField_1 = require("../date-field/DateField");
const Label_1 = require("../label/Label");
const FormMessage_1 = require("../message/FormMessage");
const SelectField_1 = require("../select-field/SelectField");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const FormConstants_1 = require("../../../../constants/FormConstants");
const FormField = props => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const shoplandingEnabledFlag = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const buildClassName = (defaultClass, errorClass, error) => defaultClass + ' ' + FormValidationUtil_1.default.processFieldError(errorClass, error);
    const getName = () => props.name || props.formFieldType.name;
    const renderLabel = (translation, required, className) => ((0, jsx_runtime_1.jsx)(Label_1.Label, { className: className, translation: translation, htmlFor: getName(), required: required || false }));
    const onKeyUp = (e) => {
        if (shoplandingEnabledFlag) {
            const event = document.createEvent('Event');
            event.initEvent('change', true, true);
            e.currentTarget.dispatchEvent(event);
        }
    };
    const renderInternalLabel = () => {
        const { required, formFieldType } = props;
        let finalTranslation = props.translation;
        if (!finalTranslation) {
            finalTranslation = formFieldType.translation;
        }
        return renderLabel(finalTranslation, required);
    };
    const renderField = (field, type, className) => ((0, jsx_runtime_1.jsx)(formik_1.Field, Object.assign({ className: className, type: type, id: field.name, placeholder: props.placeholder, onKeyUp: onKeyUp }, field)));
    const renderInternalField = (field, form, hasActiveError) => {
        const { formFieldType, data } = props;
        switch (formFieldType.type) {
            case FormConstants_1.FormFieldType.SELECT: {
                const className = buildClassName('a-select', 'a-select--error', hasActiveError);
                return (0, jsx_runtime_1.jsx)(SelectField_1.SelectField, { data: data, field: field, className: className });
            }
            case FormConstants_1.FormFieldType.DATE: {
                const className = buildClassName('a-input', 'a-input--error', hasActiveError);
                return (0, jsx_runtime_1.jsx)(DateField_1.default, { data: data, field: field, className: className });
            }
            default:
                const className = buildClassName('a-input', 'a-input--error', hasActiveError);
                return renderField(field, formFieldType.type, className);
        }
    };
    const renderErrorSection = (name, errors) => ((0, jsx_runtime_1.jsx)(FormMessage_1.FormMessage, { message: errors[name] }));
    const renderFieldSection = (field, form) => {
        const { touched, errors } = form;
        const getDynamicObject = (variableKey, baseVariable) => {
            return variableKey.split('.').reduce(function (cur, key) {
                if (!!cur && !!cur[key]) {
                    return cur[key];
                }
                return undefined;
            }, baseVariable);
        };
        const hasActiveError = !!(getDynamicObject(field.name, touched) && errors[field.name]);
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [renderInternalField(field, form, hasActiveError), hasActiveError && renderErrorSection(field.name, errors)] }));
    };
    const renderControl = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [renderInternalLabel(), (0, jsx_runtime_1.jsx)(formik_1.Field, { name: getName(), children: ({ field, form }) => renderFieldSection(field, form) })] }));
    return (0, jsx_runtime_1.jsx)("div", { className: "m-form-control", children: renderControl() });
};
exports.default = FormField;
