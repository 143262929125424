"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerEmptyResultContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const FilterActions_creators_1 = require("../../redux/filter/actions/FilterActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const ListerEmptyResult_1 = require("../../components/lister/empty-result/ListerEmptyResult");
const InStoreFilterEmptyResult_1 = require("../../components/lister/empty-result/InStoreFilterEmptyResult");
const FiltersEmptyResult_1 = require("../../components/lister/empty-result/FiltersEmptyResult");
const ListerEmptyResultContainer = () => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const pageInfo = (0, ConfigurationUtil_1.getPageInfo)(aemConfiguration);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { itemCount } = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetProductSearchInformationState)());
    const isLoading = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetLoadingState)());
    const activeFilters = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetFlattenedActiveFiltersState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const inStoreFilter = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const parentPage = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetParentPage)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const noResults = itemCount === 0;
    const hasActiveFilters = Boolean(activeFilters.length);
    const openInStoreFilterPopup = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterModified());
    };
    const removeAllActiveFilters = () => {
        dispatch(FilterActions_creators_1.filterActions.removeAllFilters());
    };
    const updateStoreId = (storeId) => {
        dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
    };
    if (featureToggles.lister_in_store_filter && noResults && !isLoading && activeStoreId) {
        return ((0, jsx_runtime_1.jsx)(InStoreFilterEmptyResult_1.InStoreFilterEmptyResult, { activeStoreId: activeStoreId, inStoreFilter: inStoreFilter, handleOpenPopup: openInStoreFilterPopup, removeAllActiveFilters: removeAllActiveFilters, updateStoreId: updateStoreId }));
    }
    if (noResults && !isLoading && hasActiveFilters) {
        return (0, jsx_runtime_1.jsx)(FiltersEmptyResult_1.FiltersEmptyResult, { removeAllActiveFilters: removeAllActiveFilters, mainWebShop: mainWebShop });
    }
    return (pageInfo.isCategoryPage || pageInfo.isBrandAsCategoryPage) && noResults && !isLoading && !hasActiveFilters ? ((0, jsx_runtime_1.jsx)(ListerEmptyResult_1.ListerEmptyResult, { parentPageHref: parentPage === null || parentPage === void 0 ? void 0 : parentPage.href, parentPageTitle: parentPage === null || parentPage === void 0 ? void 0 : parentPage.title, mainWebShop: mainWebShop })) : null;
};
exports.ListerEmptyResultContainer = ListerEmptyResultContainer;
