"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptLoaderUtil = void 0;
class ScriptLoaderUtil {
    constructor() {
        this.scripts = {};
    }
    loadScripts(...scriptUrls) {
        const promises = [];
        scriptUrls.forEach(scriptUrl => promises.push(this.loadScript(scriptUrl, this.hashCodeGenerator(scriptUrl))));
        return Promise.all(promises);
    }
    loadScript(scriptUrl, scriptKey, async, scriptAttr, parent) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            const alreadyInvokedScript = this.scripts[scriptKey];
            if (alreadyInvokedScript) {
                resolve(Object.assign(Object.assign({}, alreadyInvokedScript), { status: 'Already Loaded' }));
            }
            else {
                const duplicates = document.querySelectorAll(`script#${scriptKey}`);
                if (duplicates.length > 0) {
                    this.scripts[scriptKey] = {
                        scriptUrl: scriptUrl,
                        key: scriptKey,
                        loaded: true,
                        status: 'Already loaded',
                    };
                    resolve(this.scripts[scriptKey]);
                }
                else {
                    // load script
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = scriptUrl;
                    script.id = scriptKey;
                    script.async = async;
                    !!scriptAttr &&
                        Object.entries(scriptAttr).forEach(([key, value]) => {
                            script.setAttribute(key, value);
                        });
                    this.scripts[scriptKey] = {
                        scriptUrl: scriptUrl,
                        key: scriptKey,
                        loaded: false,
                        status: 'Requesting',
                    };
                    if (script.readyState) {
                        // IE
                        script.onreadystatechange = () => {
                            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                                script.onreadystatechange = null;
                                this.scripts[scriptKey] = Object.assign(Object.assign({}, this.scripts[scriptKey]), { loaded: true, status: 'Loaded' });
                                resolve(this.scripts[scriptKey]);
                            }
                        };
                    }
                    else {
                        // Others
                        script.onload = () => {
                            this.scripts[scriptKey] = Object.assign(Object.assign({}, this.scripts[scriptKey]), { loaded: true, status: 'Loaded' });
                            resolve(this.scripts[scriptKey]);
                        };
                    }
                    script.onerror = (error) => {
                        this.scripts[scriptKey] = Object.assign(Object.assign({}, this.scripts[scriptKey]), { loaded: false, status: error });
                        reject(this.scripts[scriptKey]);
                    };
                    const _parent = parent || document.body || document.head || document;
                    _parent.appendChild(script);
                }
            }
        });
    }
    hashCodeGenerator(s) {
        let h;
        let i;
        for (i = 0, h = 0; i < s.length; i++) {
            h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
        }
        return h.toString();
    }
}
exports.ScriptLoaderUtil = ScriptLoaderUtil;
exports.default = new ScriptLoaderUtil();
