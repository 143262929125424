"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPopup = exports.TRANSLATIONS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const ProductGroupMonetateContainer_1 = require("../../../containers/product/ProductGroupMonetateContainer");
const AddToBasketPopupTile_1 = require("../../product-tile/add-to-basket-popup/AddToBasketPopupTile");
exports.TRANSLATIONS = {
    [common_types_1.BasketUpdateStatus.ERROR]: 'product:pdp.notAddedToBasket',
    [common_types_1.BasketUpdateStatus.OUTOFSTOCK]: 'product:pdp.outOfStock',
    [common_types_1.BasketUpdateStatus.BUNDLE_OUTOFSTOCK]: 'product:pdp.bundle.outOfStock',
    [common_types_1.BasketUpdateStatus.BULK_OUTOFSTOCK]: 'product:pdp.bulk.outOfStock',
};
const TILES_IN_POPUP = 3;
const CONTAINER_ID = 'add-to-basket-data';
const BasketPopup = ({ basketProductData, recommendations, closePopup, errorCode, isOpen, status, isBulk = false, price, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const close = () => setTimeout(() => closePopup(), cotopaxi_1.POPOVER_ANIMATION_DURATION);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close });
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const multipleProducts = basketProductData instanceof Array;
    const mainProductData = multipleProducts ? basketProductData[0] : basketProductData;
    const childProductData = multipleProducts ? basketProductData.slice(1) : null;
    const basketUrl = `${root}checkout/basket-overview.html`;
    const recommendationsTitle = recommendations === null || recommendations === void 0 ? void 0 : recommendations.title;
    const isMonetateConfigured = (recommendations === null || recommendations === void 0 ? void 0 : recommendations.actionId) && recommendationsTitle && !isShoplanding;
    const productPriceTranslations = {
        savings: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.bundles.discount.info`),
    };
    (0, react_1.useEffect)(() => {
        if (basketProductData) {
            ScrollUtil_1.ScrollUtil.scrollToElement(document.getElementById(CONTAINER_ID), true);
        }
    }, [basketProductData]);
    const getProductName = (product) => product.brand ? `${product.brand} ${product.description}` : product.description;
    const getPricePropsFromPriceInformation = (priceInformation) => (Object.assign(Object.assign({ currency, hasDiscount: priceInformation.hasDiscount, productDiscount: priceInformation.hasDiscount
            ? (0, ProductUtil_1.getProductDiscount)(priceInformation.productDiscountAmount, priceInformation.productDiscountPercentage, currency)
            : undefined }, (0, common_utils_1.generateCotopaxiPriceViewModel)(priceInformation, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)), { priceSpacing: isDesktop ? cotopaxi_1.PriceSpacing.SMALL : cotopaxi_1.PriceSpacing.XSMAll, translations: productPriceTranslations }));
    const mapAndGetPriceProps = (productData) => {
        var _a;
        const priceInformation = {
            hasDiscount: productData.productHasDiscount,
            prices: ((_a = productData.prices) === null || _a === void 0 ? void 0 : _a.SELL)
                ? productData.prices
                : {
                    SELL: { original: productData.price, priceState: 'NOW' },
                    STD: { original: productData.previousPrice || productData.price, priceState: 'WAS' },
                },
        };
        return Object.assign(Object.assign({}, (0, common_utils_1.generateCotopaxiPriceViewModel)(priceInformation, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)), { productDiscount: productData.productHasDiscount
                ? (0, ProductUtil_1.getProductDiscount)(productData.productDiscountAmount, productData.productDiscountPercentage, currency)
                : undefined });
    };
    const getDescriptionTranslation = (size, color) => {
        let translation = '';
        if (size && size !== '.' && color) {
            translation = 'navigation:basket.flyout.options';
        }
        else if (size && size !== '.' && !color) {
            translation = 'navigation:basket.flyout.options.size';
        }
        else if ((size === '.' || !size) && color) {
            translation = 'navigation:basket.flyout.options.color';
        }
        return translation ? t(translation, { size, color }) : translation;
    };
    (0, react_1.useEffect)(() => {
        isOpen && handlePopoverOpen(null, null, true);
    }, [isOpen]);
    if (!isOpen) {
        return null;
    }
    const getErrorMessage = () => {
        if (Array.isArray(basketProductData)) {
            if (isBulk && (status === common_types_1.BasketUpdateStatus.BULK_OUTOFSTOCK || status === common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS)) {
                const unsuccessBasketProducts = basketProductData.filter(product => !product.success);
                return unsuccessBasketProducts.length === 1
                    ? t(exports.TRANSLATIONS.BULK_OUTOFSTOCK, { products: getProductName(unsuccessBasketProducts[0]) })
                    : t(exports.TRANSLATIONS.BUNDLE_OUTOFSTOCK, {
                        products: unsuccessBasketProducts.map(product => getProductName(product)).join(', '),
                    });
            }
            if (status === common_types_1.BasketUpdateStatus.BUNDLE_OUTOFSTOCK) {
                const unsuccessBasketProducts = basketProductData.filter(product => !product.success);
                return t(exports.TRANSLATIONS.BUNDLE_OUTOFSTOCK, {
                    products: unsuccessBasketProducts.map(product => getProductName(product)).join(', '),
                });
            }
        }
        const productNames = childProductData
            ? `${getProductName(mainProductData)}, ${childProductData.map(getProductName).join(', ')}`
            : getProductName(mainProductData);
        if (status === common_types_1.BasketUpdateStatus.SUCCESS || status === common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS) {
            return productNames;
        }
        return t(exports.TRANSLATIONS[status], { products: productNames });
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, title: t(status === common_types_1.BasketUpdateStatus.SUCCESS ? 'product:pdp.addedToBasket' : 'product:pdp.somethingWentWrong'), children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { id: CONTAINER_ID, mobile: { spacing: { paddingBottom: isMonetateConfigured ? 0 : 0.5 } }, tablet: { spacing: { marginTop: 1, paddingBottom: 0 } }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginRight: 2 } }, tablet: { spacing: { marginLeft: 4, marginRight: 4 } }, children: [errorCode &&
                            (errorCode === common_types_1.BasketResponseCode.HD_EXCLUDED || errorCode === common_types_1.BasketResponseCode.CC_EXCLUDED) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: t('product:pdp.fulfillment.conflict') }) })), status !== common_types_1.BasketUpdateStatus.SUCCESS && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: getErrorMessage() }) })), !isBulk && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(AddToBasketPopupTile_1.AddToBasketPopupTile, Object.assign({ image: mainProductData.productType === common_types_1.ProductTypes.E_VOUCHER
                                            ? mainProductData.image
                                            : (0, ImageUtil_1.getImageUrlForBasketTile)(mainProductData, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.basket), brand: mainProductData.brand, title: mainProductData.description, description: mainProductData.productType === common_types_1.ProductTypes.E_VOUCHER
                                            ? mainProductData.message
                                            : getDescriptionTranslation(mainProductData.sizeDescription, mainProductData.colorDescription), productType: mainProductData.productType }, (!childProductData ? mapAndGetPriceProps(mainProductData) : {}))) }), childProductData &&
                                    childProductData.map((child, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(AddToBasketPopupTile_1.AddToBasketPopupTile, { image: (0, ImageUtil_1.getImageUrlForBasketTile)(child, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.basket), brand: child.brand, title: child.description, description: getDescriptionTranslation(child.sizeDescription, child.colorDescription), productType: child.productType }) }, index)))] })), !isBulk && childProductData && price && !price.bundleDiscount && ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, Object.assign({}, getPricePropsFromPriceInformation(price), { size: cotopaxi_1.PriceSize.LARGE }))), !isBulk && childProductData && price && Boolean(price.bundleDiscount) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Price, Object.assign({ size: cotopaxi_1.PriceSize.LARGE }, getPricePropsFromPriceInformation(price))), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.DISCOUNT, children: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.bundleDeals.discount.part1`, {
                                                discount: Math.floor(price.bundleDiscount),
                                            }) }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.bundleDeals.discount.part2`, {
                                                count: (childProductData === null || childProductData === void 0 ? void 0 : childProductData.length) || 0,
                                            }) })] })] })), isBulk && ((0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { fullHeight: true, children: Array.isArray(basketProductData) &&
                                (status === common_types_1.BasketUpdateStatus.ERROR
                                    ? basketProductData
                                    : basketProductData.filter(product => product.success)).map((product, index, arr) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: arr.length - 1 === index ? 0 : 2 } }, children: (0, jsx_runtime_1.jsx)(AddToBasketPopupTile_1.AddToBasketPopupTile, Object.assign({ image: (0, ImageUtil_1.getImageUrlForBasketTile)(product, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.basket), brand: product.brand, title: product.description, description: getDescriptionTranslation(product.sizeDescription, product.colorDescription), productType: product.productType }, mapAndGetPriceProps(product))) }, `${product.productId}_${index}`))) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginTop: multipleProducts ? 2 : 3,
                                    marginBottom: isMonetateConfigured ? 3 : 2,
                                },
                            }, tablet: { spacing: { marginTop: 3, marginBottom: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: isMobile, gutter: cotopaxi_1.GroupGutter.NONE, children: [(status === common_types_1.BasketUpdateStatus.SUCCESS || status === common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: {
                                            spacing: {
                                                marginBottom: 0,
                                                marginRight: 2,
                                            },
                                        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: t('product:pdp.createOrder'), onClick: handlePopoverClose, href: basketUrl }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, variant: cotopaxi_1.ButtonVariant.OUTLINED, text: t(status === common_types_1.BasketUpdateStatus.SUCCESS || status === common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS
                                            ? 'product:pdp.continueShopping'
                                            : 'product:pdp.close'), onClick: handlePopoverClose })] }) })] }), isMonetateConfigured &&
                    MonetateUtil_1.default.getActionIds(recommendations.actionId).map(actionId => ((0, jsx_runtime_1.jsx)(ProductGroupMonetateContainer_1.default, { basketPopup: true, containerId: actionId, title: recommendationsTitle, collapsible: false, showComparison: !isMobile, numberOfTiles: { desktop: TILES_IN_POPUP, desktopLarge: TILES_IN_POPUP }, tileFormat: "extensive" }, actionId)))] }) }));
};
exports.BasketPopup = BasketPopup;
