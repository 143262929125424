"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const useCountdownTimer_1 = require("../../../hooks/useCountdownTimer");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useFeatureToggle_1 = require("../../../hooks/useFeatureToggle");
const DeliveryConstants_1 = require("../../../constants/DeliveryConstants");
const DeliveryPromiseMessageV2 = ({ hdDeliveryPromise, unavailable, message, deliveryPromiseExplanation, icon = { name: 'circle-fill', color: undefined }, }) => {
    const hdDeliveryPromiseMessage = Object.values((hdDeliveryPromise === null || hdDeliveryPromise === void 0 ? void 0 : hdDeliveryPromise.messages) || '').at(0) || '';
    const hdDeliveryPromiseName = Object.keys((hdDeliveryPromise === null || hdDeliveryPromise === void 0 ? void 0 : hdDeliveryPromise.messages) || '').at(0);
    const { countdownMessage } = (0, useCountdownTimer_1.useCountdownTimer)(hdDeliveryPromise === null || hdDeliveryPromise === void 0 ? void 0 : hdDeliveryPromise.cutoff, hdDeliveryPromiseMessage);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const standardDeliveryLabel = (0, useFeatureToggle_1.useFeatureToggle)('delivery_promise_standard_delivery_label');
    const isCountdownTimerMessage = () => !!(hdDeliveryPromise === null || hdDeliveryPromise === void 0 ? void 0 : hdDeliveryPromise.cutoff) &&
        (hdDeliveryPromiseMessage === null || hdDeliveryPromiseMessage === void 0 ? void 0 : hdDeliveryPromiseMessage.includes(DeliveryConstants_1.COUNTDOWN)) &&
        DeliveryConstants_1.ENABLED_DELIVERY_PROMISES.includes(hdDeliveryPromiseName || '');
    if (!hdDeliveryPromiseMessage &&
        ((!message && !countdownMessage) || (countdownMessage && !isCountdownTimerMessage()))) {
        return null;
    }
    const renderIcon = () => ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.Icon, { size: "sm", color: icon.color, name: icon.name }) }));
    const renderMessage = () => {
        if (message) {
            return message;
        }
        if (hdDeliveryPromiseMessage || countdownMessage) {
            return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: (hdDeliveryPromise === null || hdDeliveryPromise === void 0 ? void 0 : hdDeliveryPromise.nextDayDeliverable) ? 'success' : undefined, strong: !unavailable, children: [standardDeliveryLabel && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t(hdDeliveryPromiseName
                                ? `pdp.deliverypromise.${hdDeliveryPromiseName.toLowerCase()}.txt`
                                : 'pdp.deliverypromise.home_delivery.txt').toUpperCase(), (0, jsx_runtime_1.jsx)("br", {})] })), isCountdownTimerMessage() ? ((0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: countdownMessage } })) : (hdDeliveryPromiseMessage), deliveryPromiseExplanation && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: deliveryPromiseExplanation, hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(andes_react_1.Icon, { size: "sm", name: "information-outline" }) })] }))] }));
        }
        return null;
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Grid, { templateColumns: "auto 1fr", gap: "s", children: [renderIcon(), renderMessage()] }));
};
exports.default = DeliveryPromiseMessageV2;
