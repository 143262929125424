"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ContentListerBanner = ({ image }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const getBannerUrl = () => (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix, '1680');
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: getBannerUrl() });
};
exports.default = ContentListerBanner;
