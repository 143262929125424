"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populatePreferencesWithSubCategories = void 0;
const populatePreferencesWithSubCategories = (preferenceList) => {
    const preferencesWithSubCategories = {};
    const parentCategories = new Set(preferenceList.filter(item => item.parentPreferenceFlagCode).map(item => item.parentPreferenceFlagCode));
    preferenceList.forEach(item => {
        if (parentCategories.has(item.code))
            return;
        const key = item.parentPreferenceFlagCode ? item.parentPreferenceFlagCode + '#' + item.code : item.code;
        preferencesWithSubCategories[key] = item.isSubscribed;
    });
    return preferencesWithSubCategories;
};
exports.populatePreferencesWithSubCategories = populatePreferencesWithSubCategories;
