"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedSizes = void 0;
const react_1 = require("react");
const ProductUtil_1 = require("../util/ProductUtil");
const useSelectedSizes = (options, filteredSizes, selectedColorId, selectedSku, selectSize) => {
    const [selectedSizeOption, setSelectedSizeOption] = (0, react_1.useState)('');
    const [selectedLengthOption, setSelectedLengthOption] = (0, react_1.useState)('');
    const [sizeOptions, setSizeOptions] = (0, react_1.useState)([]);
    const [lengthOptions, setLengthOptions] = (0, react_1.useState)([]);
    const { isSizeAndLengthSplitted, sizesMap, lengthsMap } = (0, ProductUtil_1.splitSizesBySizeAndLength)(filteredSizes);
    (0, react_1.useEffect)(() => {
        if (!isSizeAndLengthSplitted)
            return;
        if (selectedSizeOption && selectedLengthOption) {
            const sizeIndex = (0, ProductUtil_1.getOptionIndex)(options, selectedSizeOption, selectedLengthOption);
            if (sizeIndex === -1) {
                selectSize({ sku: null });
            }
        }
    }, [isSizeAndLengthSplitted, selectedLengthOption]);
    (0, react_1.useEffect)(() => {
        if (!isSizeAndLengthSplitted)
            return;
        if (selectedSizeOption && selectedLengthOption) {
            const sizeIndex = (0, ProductUtil_1.getOptionIndex)(options, selectedSizeOption, selectedLengthOption);
            if (sizeIndex === -1) {
                selectSize({ sku: null });
            }
        }
    }, [isSizeAndLengthSplitted, selectedSizeOption]);
    (0, react_1.useEffect)(() => {
        if (!isSizeAndLengthSplitted)
            return;
        if (selectedSizeOption && selectedLengthOption) {
            const sizeIndex = (0, ProductUtil_1.getOptionIndex)(options, selectedSizeOption, selectedLengthOption);
            if (sizeIndex !== -1 && options[sizeIndex]) {
                const { size, length } = (0, ProductUtil_1.extractLengthAndSize)(options[sizeIndex].label.toString());
                selectSize({ sku: options[sizeIndex].value.toString(), isSoldOut: options[sizeIndex].isSoldOut });
                setLengthOptions((0, ProductUtil_1.getActiveSizeOptions)(sizesMap.get(size), options, lengthOptions, 'length'));
                setSizeOptions((0, ProductUtil_1.getActiveSizeOptions)(lengthsMap.get(length), options, sizeOptions, 'size'));
            }
            else {
                selectSize({ sku: null, isDisabled: true });
            }
        }
    }, [isSizeAndLengthSplitted, selectedSizeOption, selectedLengthOption]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (!isSizeAndLengthSplitted)
            return;
        if (selectedSku) {
            const size = ((_a = [...sizesMap.entries()].find(([, value]) => value.includes(selectedSku))) === null || _a === void 0 ? void 0 : _a[0]) || null;
            const length = ((_b = [...lengthsMap.entries()].find(([, value]) => value.includes(selectedSku))) === null || _b === void 0 ? void 0 : _b[0]) || null;
            if (size && length) {
                setSelectedSizeOption(size);
                setSelectedLengthOption(length);
            }
        }
    }, [isSizeAndLengthSplitted, selectedSku]);
    (0, react_1.useEffect)(() => {
        if (!isSizeAndLengthSplitted)
            return;
        if (!selectedSku) {
            setSelectedLengthOption('');
            setSelectedSizeOption('');
        }
        const sOptions = (0, ProductUtil_1.generateUniqueSizeOptions)(options);
        const lOptions = (0, ProductUtil_1.generateUniqueLengthOptions)(options, lengthsMap);
        const enabledLengthOptions = lOptions.filter(option => !option.disabled && !option.isSoldOut);
        const enabledSizeOptions = sOptions.filter(option => !option.disabled && !option.isSoldOut);
        if (lOptions.length === 1 && enabledLengthOptions.length === 1 && enabledLengthOptions[0]) {
            setSelectedLengthOption(enabledLengthOptions[0].label.toString());
            setSizeOptions((0, ProductUtil_1.getActiveSizeOptions)(lengthsMap.get(enabledLengthOptions[0].label), options, sOptions, 'size'));
        }
        else {
            setSizeOptions(sOptions);
        }
        if (sOptions.length === 1 && enabledSizeOptions.length === 1 && enabledSizeOptions[0]) {
            setSelectedSizeOption(enabledSizeOptions[0].label.toString());
            setLengthOptions((0, ProductUtil_1.getActiveSizeOptions)(sizesMap.get(enabledSizeOptions[0].label.toString()), options, lOptions, 'length'));
        }
        else {
            setLengthOptions(lOptions);
        }
    }, [isSizeAndLengthSplitted, selectedColorId]);
    return {
        sizeOptions,
        setSizeOptions,
        selectedSizeOption,
        setSelectedSizeOption,
        lengthOptions,
        setLengthOptions,
        selectedLengthOption,
        setSelectedLengthOption,
    };
};
exports.useSelectedSizes = useSelectedSizes;
