"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeProductFromBasketAnalyticsSaga = exports.removeProductFromBasketAnalytics = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* removeProductFromBasketAnalytics(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.removeProductFromBasketAnalytics, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createRemoveGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.removeProductFromBasketDtm, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createRemoveAdobeAnalyticsSuccessAction(analyticsAdobeTagManagerData));
    }
    catch (_a) { }
}
exports.removeProductFromBasketAnalytics = removeProductFromBasketAnalytics;
function* removeProductFromBasketAnalyticsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT_SUCCESS, removeProductFromBasketAnalytics);
}
exports.removeProductFromBasketAnalyticsSaga = removeProductFromBasketAnalyticsSaga;
