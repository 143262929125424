"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingBudgetBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const ShoppingBudgetBlock = ({ backgroundImage, title, description, cta, }) => {
    const backgroundStyle = backgroundImage
        ? {
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
        : {};
    return ((0, jsx_runtime_1.jsx)("div", { style: backgroundStyle, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: { span: 8, push: 2 }, desktop: { span: 6, push: 3 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                        theme: cotopaxi_1.BoxTheme.WHITE,
                        spacing: { padding: 2, marginTop: 12, marginBottom: 12 },
                    }, children: [title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: { paddingTop: 6, paddingLeft: 1, paddingRight: 1, paddingBottom: 2 },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, type: cotopaxi_1.HeadingType.TITLE, alignment: cotopaxi_1.HeadingAlignment.CENTER, children: title }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 3, paddingRight: 3, paddingTop: 2, paddingBottom: 4 } }, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: description }) }), (cta === null || cta === void 0 ? void 0 : cta.link) && (cta === null || cta === void 0 ? void 0 : cta.label) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: cta.label, title: cta.label, href: cta.link, variant: cotopaxi_1.ButtonVariant.BRANDED }) }))] }) }) }) }));
};
exports.ShoppingBudgetBlock = ShoppingBudgetBlock;
