"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authReducer = exports.initialAuthReducerState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialAuthReducerState = {
    authenticated: false,
    loading: false,
    loadingGuest: false,
    loadingReturnGuest: false,
    error: null,
    errorGuest: null,
    errorReturnGuest: null,
    resetPassword: {
        error: null,
        success: null,
    },
    social: {
        initialized: [],
    },
    fastCheckout: false,
    loggedGuest: false,
    loggedInReturnGuest: false,
};
const authReducer = (state = exports.initialAuthReducerState, action) => {
    switch (action.type) {
        case ActionEnums_1.AuthActionTypes.LOGIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { error: null, loading: false, authenticated: true });
        case ActionEnums_1.AuthActionTypes.LOGIN:
        case ActionEnums_1.AuthActionTypes.REGISTERED_LOGIN:
        case ActionEnums_1.AuthActionTypes.LOGOUT:
            return Object.assign(Object.assign({}, state), { loading: true });
        case ActionEnums_1.AuthActionTypes.LOGIN_GUEST:
            return Object.assign(Object.assign({}, state), { loadingGuest: true });
        case ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST:
            return Object.assign(Object.assign({}, state), { loadingReturnGuest: true });
        case ActionEnums_1.AuthActionTypes.LOGIN_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, error: action.payload });
        case ActionEnums_1.AuthActionTypes.RESET_ERROR:
            return Object.assign(Object.assign({}, state), { error: null, errorGuest: null });
        case ActionEnums_1.AuthActionTypes.FAST_CHECKOUT:
            return Object.assign(Object.assign({}, state), { fastCheckout: action.payload });
        case ActionEnums_1.AuthActionTypes.LOGOUT_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, authenticated: false });
        case ActionEnums_1.AuthActionTypes.RESET_PASSWORD:
            return Object.assign(Object.assign({}, state), { resetPassword: Object.assign(Object.assign({}, state.resetPassword), { error: null, success: null }) });
        case ActionEnums_1.AuthActionTypes.RESET_PASSWORD_ERROR:
            return Object.assign(Object.assign({}, state), { resetPassword: Object.assign(Object.assign({}, state.resetPassword), { error: action.payload, success: null }) });
        case ActionEnums_1.AuthActionTypes.RESET_PASSWORD_SUCCESS:
            return Object.assign(Object.assign({}, state), { resetPassword: Object.assign(Object.assign({}, state.resetPassword), { error: null, success: action.payload }) });
        case ActionEnums_1.AuthActionTypes.LOAD_SOCIAL_SUCCESS:
            return Object.assign(Object.assign({}, state), { social: Object.assign(Object.assign({}, state.social), { initialized: [...state.social.initialized, action.payload] }) });
        case ActionEnums_1.AuthActionTypes.LOGIN_GUEST_SUCCESS:
            return Object.assign(Object.assign({}, state), { loggedGuest: true, loadingGuest: false });
        case ActionEnums_1.AuthActionTypes.LOGIN_GUEST_FAIL:
            return Object.assign(Object.assign({}, state), { loadingGuest: false, errorGuest: action.payload });
        case ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST_SUCCESS:
            return Object.assign(Object.assign({}, state), { loggedInReturnGuest: true, loadingReturnGuest: false });
        case ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST_FAIL:
            return Object.assign(Object.assign({}, state), { loadingReturnGuest: false, errorReturnGuest: action.payload });
        case ActionEnums_1.AuthActionTypes.UPDATE_USER_AUTH_STATE:
            return Object.assign(Object.assign({}, state), { authenticated: action.payload.authenticated });
        default:
            return state;
    }
};
exports.authReducer = authReducer;
