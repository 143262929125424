"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetStoreId = exports.makeGetSearchTerm = exports.makeGetUrlFilters = exports.makeGetUrlInformationSearch = exports.makeGetUrlInformationPageSize = exports.makeGetUrlQueryInformationState = exports.getUrlQueryInformationState = void 0;
const reselect_1 = require("reselect");
const getUrlQueryInformation = (state) => state.url;
exports.getUrlQueryInformationState = (0, reselect_1.createSelector)([getUrlQueryInformation], (queryInformation) => queryInformation);
const makeGetUrlQueryInformationState = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => queryInformation);
exports.makeGetUrlQueryInformationState = makeGetUrlQueryInformationState;
const makeGetUrlInformationPageSize = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => queryInformation.size);
exports.makeGetUrlInformationPageSize = makeGetUrlInformationPageSize;
const makeGetUrlInformationSearch = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => queryInformation.q);
exports.makeGetUrlInformationSearch = makeGetUrlInformationSearch;
const makeGetUrlFilters = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => ({
    defaultFilter: queryInformation.defaultFilter,
    filter: queryInformation.filter,
}));
exports.makeGetUrlFilters = makeGetUrlFilters;
const makeGetSearchTerm = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => queryInformation.searchTerm);
exports.makeGetSearchTerm = makeGetSearchTerm;
const makeGetStoreId = () => (0, reselect_1.createSelector)([exports.getUrlQueryInformationState], (queryInformation) => queryInformation.storeId);
exports.makeGetStoreId = makeGetStoreId;
