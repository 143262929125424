"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareStickyBarMobile = exports.MAIN_COMPARE_CAROUSEL_ID = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const SliderConstants_1 = require("../../../constants/SliderConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
exports.MAIN_COMPARE_CAROUSEL_ID = 'compare-carousel';
const CAROUSEL_EL_SELECTOR = '.as-m-carousel--product-tile-normal';
const TOUCH_END_LENGTH = 50;
const CompareStickyBarMobile = ({ comparisonProducts, onCloseClick, addToBasket, setSwiperInstance, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const listerConfiguration = (0, ConfigurationUtil_1.getListerConfiguration)(aemConfiguration);
    const [hidden, setHidden] = (0, react_1.useState)(true);
    const [isCollapsed, setIsCollapsed] = (0, react_1.useState)(false);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)(null);
    const [carouselElement, setCarouselElement] = (0, react_1.useState)(null);
    const [compareCarouselSwiper, setCompareCarouselSwiper] = (0, react_1.useState)(null);
    const [productTileVariant, setProductTileVariant] = (0, react_1.useState)(cotopaxi_1.ProductTileMiniVariant.NORMAL);
    const stickyRef = (0, react_1.useRef)(null);
    const [touchStartY, setTouchStartY] = (0, react_1.useState)(null);
    const [stickyBarHeight, setStickyBarHeight] = (0, react_1.useState)(null);
    const [isTouchInProgress, setIsTouchInProgress] = (0, react_1.useState)(false);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const debouncedSetTileVariant = (0, cotopaxi_1.useDebounce)(setProductTileVariant, GeneralConstants_1.DELAY.DELAY_300);
    const productTileMiniTranslations = {
        outOfStock: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.temporarilyOutOfStock`),
        otherColorAvailable: t(`${i18nConstants_1.I18N_NAMESPACES.product}:wishlist.outOfStock.other.color.available`),
    };
    const toggleStickyBar = () => {
        const nextIsCollapsedState = !isCollapsed;
        setIsCollapsed(nextIsCollapsedState);
        nextIsCollapsedState
            ? debouncedSetTileVariant(cotopaxi_1.ProductTileMiniVariant.COMPACT)
            : setProductTileVariant(cotopaxi_1.ProductTileMiniVariant.NORMAL);
    };
    const setSwiper = (swiper) => {
        setCompareCarouselSwiper(swiper);
        setSwiperInstance(swiper);
    };
    const compareCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.PRODUCT_TILE_NORMAL,
        showPagination: false,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            getSwiper: setSwiper,
        },
    };
    const compareSlide = (0, react_1.useCallback)((product) => {
        const activeColour = product.colours.find((colour) => colour.colourId === product.colourId);
        const activeSku = (0, common_utils_1.determineSelectedSku)(activeColour);
        const activeSize = activeColour.sizes.find(size => size.sku === activeSku);
        const productOutOfStock = (0, ProductUtil_1.isProductOutOfStock)(product);
        const colorOutOfStock = (0, ProductUtil_1.isProductWithColorOutOfStock)(product);
        const getImage = () => (0, ImageUtil_1.getPortraitImageSizeUrlByProductCode)(product.productCode, activeColour.image, listerConfiguration.productCdnDomains, common_types_1.ProductImageSizesPortraitEnum.basket);
        const onAddToBasketClick = () => addToBasket({
            product,
            colorId: activeColour.colourId,
            sku: activeSku,
        });
        const onCloseButtonClick = () => onCloseClick(product.productCode, activeColour.colourId);
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.SPACE_AROUND, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTileMini, { variant: productTileVariant, imageAlt: product.title, imageSrc: getImage(), outOfStock: productOutOfStock, colorOutOfStock: productOutOfStock ? false : colorOutOfStock, onAddToBasketClick: (activeSize === null || activeSize === void 0 ? void 0 : activeSize.storeSourceExcluded) ? null : onAddToBasketClick, onCloseButtonClick: onCloseButtonClick, translations: productTileMiniTranslations }) }, `${product.productCode}${product.colourId}`));
    }, [comparisonProducts, addToBasket, productTileVariant]);
    const onTouchStart = (e) => {
        const touch = e.touches[0];
        setTouchStartY(touch.clientY);
        setStickyBarHeight(stickyRef.current.offsetHeight);
        setIsTouchInProgress(true);
    };
    const onTouchMove = (e) => {
        if (isTouchInProgress) {
            const touch = e.touches[0];
            const touchLength = touchStartY - touch.clientY;
            if ((isCollapsed && touchLength < 0) || (!isCollapsed && touchLength > 0)) {
                const height = stickyBarHeight + touch.clientY - touchStartY;
                stickyRef.current.style.height = `${height}px`;
                stickyRef.current.style.maxHeight = `${height}px`;
                stickyRef.current.style.transitionProperty = 'none';
                if (Math.abs(touchLength) > TOUCH_END_LENGTH) {
                    isCollapsed
                        ? setProductTileVariant(cotopaxi_1.ProductTileMiniVariant.NORMAL)
                        : debouncedSetTileVariant(cotopaxi_1.ProductTileMiniVariant.COMPACT);
                    setIsCollapsed(!isCollapsed);
                    setIsTouchInProgress(false);
                    setTouchStartY(null);
                    stickyRef.current.style.height = '';
                    stickyRef.current.style.maxHeight = '';
                    stickyRef.current.style.transitionProperty = '';
                }
            }
        }
    };
    const onTouchEnd = () => {
        stickyRef.current.style.height = '';
        stickyRef.current.style.maxHeight = '';
        stickyRef.current.style.transitionProperty = '';
        setStickyBarHeight(null);
        setIsTouchInProgress(false);
    };
    (0, react_1.useEffect)(() => {
        if (compareCarouselSwiper !== null) {
            compareCarouselSwiper.update();
        }
    }, [comparisonProducts, compareCarouselSwiper]);
    (0, react_1.useEffect)(() => {
        const navigation = document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL);
        const anchorEl = document.querySelector(CAROUSEL_EL_SELECTOR);
        setNavigationEl(navigation);
        setCarouselElement(anchorEl);
        // This needed because the navigation menu gets hidden after the page loads with a delay
        setTimeout(() => setShouldRender(true), GeneralConstants_1.DELAY.DELAY_100);
        return () => setSwiperInstance(null);
    }, []);
    (0, cotopaxi_1.useScrollPosition)(({ currPos }) => {
        var _a;
        if (!!navigationEl && !!carouselElement) {
            const positionTop = Math.abs(currPos.y);
            const tmpIsNavDisplayed = !navigationEl.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE);
            const $mainCarousel = document.getElementById(exports.MAIN_COMPARE_CAROUSEL_ID);
            const whenToSticky = $mainCarousel.offsetHeight - ((_a = stickyRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) + $mainCarousel.offsetTop;
            setHidden((tmpIsNavDisplayed ? positionTop + DOMElementsConstants_1.NAVIGATION_HEIGHT : positionTop) < whenToSticky);
        }
    }, [navigationEl, carouselElement], null, false, 0);
    if (!shouldRender) {
        return null;
    }
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { innerRef: stickyRef, onSwipe: {
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        }, isHidden: hidden, variant: cotopaxi_1.StickyBarVariant.COMPARE_MOBILE, collapsed: isCollapsed, onToggleClick: toggleStickyBar, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                spacing: {
                    paddingTop: 1.5,
                    paddingBottom: productTileVariant === cotopaxi_1.ProductTileMiniVariant.COMPACT ? 0 : 0.5,
                },
            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, compareCarouselConfig, { children: comparisonProducts.map(compareSlide) })) }) }), navigationEl);
};
exports.CompareStickyBarMobile = CompareStickyBarMobile;
