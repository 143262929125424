"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wishListActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.wishListActions = {
    initWishListLister: () => ({
        type: ActionEnums_1.WishListActionTypes.LISTER_INIT,
    }),
    getWishListProducts: () => ({
        type: ActionEnums_1.WishListActionTypes.GET_PRODUCTS,
    }),
    updateWishListProducts: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS,
        payload,
    }),
    toggleProduct: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.TOGGLE_PRODUCT,
        payload,
    }),
    addProduct: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.ADD_PRODUCT,
        payload,
    }),
    removeProduct: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT,
        payload,
    }),
    getWishListListerProducts: () => ({
        type: ActionEnums_1.WishListActionTypes.LISTER_GET_PRODUCTS,
    }),
    setWishListerListerProducts: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.LISTER_SET_PRODUCTS,
        payload,
    }),
    /** @deprecated Use the useWishlistLogin hook instead */
    login: () => ({
        type: ActionEnums_1.WishListActionTypes.LOGIN,
    }),
    logout: () => ({
        type: ActionEnums_1.WishListActionTypes.LOGOUT,
    }),
    loggedIn: () => ({
        type: ActionEnums_1.WishListActionTypes.LOGGED_IN,
    }),
    loggedOut: () => ({
        type: ActionEnums_1.WishListActionTypes.LOGGED_OUT,
    }),
    setFetchStatus: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.LISTER_SET_FETCH_STATUS,
        payload,
    }),
    updateWishListPage: (payload) => ({
        type: ActionEnums_1.WishListActionTypes.LISTER_UPDATE_PAGE,
        payload,
    }),
    getDeliveryPromise(payload) {
        return { type: ActionEnums_1.WishListActionTypes.LISTER_GET_DELIVERY_PROMISE, payload };
    },
    setDeliveryPromise(payload) {
        return { type: ActionEnums_1.WishListActionTypes.LISTER_SET_DELIVERY_PROMISE, payload };
    },
};
