"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceRouterState = exports.pushRouterState = exports.getWishlistStoreData = exports.getContentStoreData = exports.getStoreData = exports.getURLParams = void 0;
const effects_1 = require("redux-saga/effects");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const connected_react_router_1 = require("connected-react-router");
require("url-search-params-polyfill");
const getURLParams = (urlState) => {
    const params = new URLSearchParams(window.location.search);
    Object.keys(urlState).forEach(urlStateKey => {
        if (!(0, common_utils_1.isNullOrUndefined)(urlState[urlStateKey])) {
            switch (urlStateKey) {
                case 'filter':
                    if (urlState.filter.length > 0) {
                        if (typeof urlState.filter !== 'string') {
                            const flattendFilterValue = urlState.filter.reduce((accumulator, currentValue) => (accumulator += `&filter=${currentValue.filterOn}:${currentValue.filterValue}`), '');
                            params.set('filter', flattendFilterValue);
                        }
                        else {
                            params.set('filter', urlState.filter);
                        }
                    }
                    else {
                        params.delete(urlStateKey);
                    }
                    break;
                case 'defaultFilter':
                    break;
                case common_types_1.ContentFilterQueryParamKey.TYPE:
                    if (urlState.type.length > 0) {
                        const filterValue = urlState.type.reduce((acc, currentValue) => (acc += `&${urlStateKey}=${currentValue}`));
                        params.set(urlStateKey, filterValue);
                    }
                    else {
                        params.delete(urlStateKey);
                    }
                    break;
                default:
                    params.set(urlStateKey, urlState[urlStateKey].toString());
                    break;
            }
        }
        else {
            params.delete(urlStateKey);
        }
    });
    // Necessary encodeURI: prevents weird flicker issues with urls and encoded filter params
    return encodeURI(params.toString().replace(/\+/g, ' '));
};
exports.getURLParams = getURLParams;
const getStoreData = (state) => ({
    lister: state.lister,
    filters: state.filters,
    url: state.url,
});
exports.getStoreData = getStoreData;
const getContentStoreData = (state) => ({
    content: state.content,
    filters: state.filters,
    url: state.url,
});
exports.getContentStoreData = getContentStoreData;
const getWishlistStoreData = (state) => ({
    basket: state.basket,
    compare: state.compare,
    wishList: state.wishList,
});
exports.getWishlistStoreData = getWishlistStoreData;
function* pushRouterState(urlState, locationState) {
    const location = {
        search: (0, exports.getURLParams)(urlState),
        pathname: window.location.pathname,
        state: locationState,
    };
    yield (0, effects_1.put)((0, connected_react_router_1.push)(location));
}
exports.pushRouterState = pushRouterState;
function* replaceRouterState(urlState, locationState) {
    const location = {
        search: (0, exports.getURLParams)(urlState),
        pathname: window.location.pathname,
        state: locationState,
    };
    yield (0, effects_1.put)((0, connected_react_router_1.replace)(location));
}
exports.replaceRouterState = replaceRouterState;
