"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const CheckoutSelector_1 = require("../../../redux/checkout/selectors/CheckoutSelector");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CheckoutActions_creators_1 = require("../../../redux/checkout/actions/CheckoutActions.creators");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const BuyForm_1 = require("../../../components/buy/forms/BuyForm");
const CollectHQContainer = ({ option }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const delivery = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryInfo)());
    const deliverySaving = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliverySaving)());
    const deliveryErrors = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryErrors)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const onSubmit = (values) => {
        dispatch(CheckoutActions_creators_1.default.createSaveDeliveryInfoAction({ values }));
    };
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: onSubmit, initialValues: (0, BuyFormUtil_1.getInitialValues)(delivery, BuyForm_1.initialValuesBuyForm, option.name), buttonState: deliverySaving ? cotopaxi_1.ButtonState.LOADING : undefined, errorFeedback: deliveryErrors[option.name] ? t(deliveryErrors[option.name]) : null, checkoutStep: common_types_1.RoutePathname.DELIVERY }));
};
exports.default = CollectHQContainer;
