"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamicModuleLoaderAnalyticsCriteo = exports.analyticsCriteoMiddleware = void 0;
const ActionEnums_1 = require("./../../enums/ActionEnums");
const logger_1 = require("@redux-beacon/logger");
const redux_beacon_1 = require("redux-beacon");
const redux_beacon_criteo_1 = require("@as-react/redux-beacon-criteo");
const common_utils_1 = require("@as-react/common-utils");
const AnalyticsUtil_1 = require("../../util/AnalyticsUtil");
exports.analyticsCriteoMiddleware = (0, redux_beacon_1.createMiddleware)({
    [ActionEnums_1.ListerActionTypes.LISTER_INIT]: (_action, prevState) => (0, AnalyticsUtil_1.generateCriteoProductIds)(prevState.lister.items),
    [ActionEnums_1.ProductActionTypes.INIT]: (_action, prevState) => (0, AnalyticsUtil_1.generateCriteoProductId)(prevState.product.productId),
    [ActionEnums_1.SessionActionTypes.SET]: (action) => (0, AnalyticsUtil_1.generateCriteoUserData)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.ANALYTICS_GOOGLE_CONFIRMATION_PAGE]: (action) => (0, AnalyticsUtil_1.generateCriteoImpressionEventFromConfirmation)('SITE.data.criteoPageObj', action.payload),
    [ActionEnums_1.BasketActionTypes.SET]: (action) => (0, AnalyticsUtil_1.generateCriteoProductImpressionEventFromBasketProducts)('dataLayer', action.payload.products),
}, (0, redux_beacon_criteo_1.Criteo)(), Object.assign({}, (!common_utils_1.Environment.isProduction() && { logger: logger_1.default })));
const createDynamicModuleLoaderAnalyticsCriteo = () => ({
    id: 'startup-analytics-criteo',
    middlewares: [exports.analyticsCriteoMiddleware],
});
exports.createDynamicModuleLoaderAnalyticsCriteo = createDynamicModuleLoaderAnalyticsCriteo;
