"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ReevooUtil {
}
ReevooUtil.setReevooTracking = (order) => {
    const { products, totalPriceSell, currency } = order;
    if ((products === null || products === void 0 ? void 0 : products.length) > 0) {
        if (typeof window.afterReevooMarkLoaded === 'undefined') {
            window.afterReevooMarkLoaded = [];
        }
        window.afterReevooMarkLoaded.push(function () {
            window.ReevooLib.track.purchase({
                productIds: products.map(item => `${item.sku}`),
                basketValue: `${totalPriceSell} ${currency}`,
            });
        });
    }
    return null;
};
exports.default = ReevooUtil;
