"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LuxUtil {
    static confirmationPage(data) {
        if (this.isEnabled()) {
            window[this.LUX_LAYER].addData('order.confirmed', 1);
            window[this.LUX_LAYER].addData('order.products', data.purchase.products.length);
            window[this.LUX_LAYER].addData('order.revenue', data.purchase.actionField.revenue);
        }
    }
    static isEnabled() {
        return !!window[this.LUX_LAYER];
    }
}
LuxUtil.LUX_LAYER = 'LUX';
exports.default = LuxUtil;
