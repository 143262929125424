"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewAnnouncedReturn = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const ImageUtil_1 = require("../../util/ImageUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const ProductTileCompact_1 = require("../product-tile/compact/ProductTileCompact");
const NewAnnouncedReturn = ({ returnedSale }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImages = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: returnedSale.lines &&
            returnedSale.lines.map(saleLine => {
                const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(saleLine.productCode, saleLine.picture, productImages);
                return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, { asStatic: true, brand: saleLine.brand, title: saleLine.productDescription, quantity: saleLine.quantity, url: saleLine.url, image: imageUrl[common_types_1.ProductImageSizesPortraitEnum.thumb], sizeDescription: saleLine.productSize, colorDescription: saleLine.productColour, withoutInnerSpacing: true, type: "with-status", primaryStatus: saleLine.returnReasonDescription && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: t('returnItems.confirmation.summary.returnReason') }), saleLine.returnReasonDescription] }) })), secondaryStatus: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t('account.order.list.order.status.retour') }) }) }, saleLine.saleLineId), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }));
            }) }));
};
exports.NewAnnouncedReturn = NewAnnouncedReturn;
