"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProductDetailToProductListerAdapter = exports.getSizeButtonsError = exports.getAvailableSizes = exports.getSwatchColors = exports.filterProductCategoryFalseBooleanFeatures = exports.filterProductCategoryEmptyFeatures = exports.getSizeSelectOptions = exports.getOptionIndex = exports.getActiveSizeOptions = exports.generateUniqueLengthOptions = exports.generateUniqueSizeOptions = exports.extractLengthAndSize = exports.splitSizesBySizeAndLength = exports.mapSizeSelectOptions = exports.selectOptionLabel = exports.isSoldOutSize = exports.makeOriginalSize = exports.makeCommercialSize = exports.getProductDetailUrl = exports.getNewProductOverlayTagColorAndTranslationKey = exports.getSizeDescription = exports.isInStoreOnly = exports.isClickAndCollectOnly = exports.hasProductAnotherAvailableColor = exports.isProductWithColorOutOfStock = exports.isProductOutOfStock = exports.isExtensiveProductTile = exports.getProductPriceOverlayTagColorAndContent = exports.setDeliveryPromiseToProducts = exports.getDefaultSize = exports.getProductDiscount = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const asNumeral_1 = require("../vendors/asNumeral");
const ProductConstants_1 = require("../constants/ProductConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const FEATURE_VALUE_YES = {
    nl: 'ja',
    en: 'yes',
    fr: 'oui',
};
const MAX_PRODUCT_DISCOUNT_AMOUNT = 20;
const SIZELENGTH_ORDER = {
    Short: 0,
    Regular: 1,
    Long: 2,
    'Extra Long': 3,
};
const getProductDiscount = (discountAmount, discountPercentage, currency) => !!discountAmount && !!discountPercentage
    ? Number(discountAmount) < MAX_PRODUCT_DISCOUNT_AMOUNT
        ? `${discountPercentage}%`
        : `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(discountAmount)}`
    : '';
exports.getProductDiscount = getProductDiscount;
const getDefaultSize = (colors, colorId, sizeCode) => {
    const defaultColorVariation = (0, common_utils_1.findColor)(colors, colorId);
    return defaultColorVariation ? (0, common_utils_1.findSize)(defaultColorVariation.sizes, sizeCode) : null;
};
exports.getDefaultSize = getDefaultSize;
const setDeliveryPromiseToProducts = (products, payload) => {
    const { productId, deliveryPromise, colourId } = payload;
    return products.map((product) => {
        if (product.productId === Number(productId) && product.colourId === colourId) {
            return Object.assign(Object.assign({}, product), { deliveryPromise });
        }
        return product;
    });
};
exports.setDeliveryPromiseToProducts = setDeliveryPromiseToProducts;
const getProductPriceOverlayTagColorAndContent = (priceOverlay) => {
    let overlayTagColor;
    let overlayTagContent;
    if (priceOverlay) {
        overlayTagContent = priceOverlay.overlayBackgroundCodeDescription || priceOverlay.overlayCode;
        switch (priceOverlay.idOverlayBackground) {
            case 1:
                overlayTagColor = cotopaxi_1.TagColor.PRIMARY;
                break;
            case 2:
                overlayTagColor = cotopaxi_1.TagColor.SECONDARY;
                break;
            case 3:
                overlayTagColor = cotopaxi_1.TagColor.TERTIARY;
                break;
            case 4:
                overlayTagColor = cotopaxi_1.TagColor.QUATERNARY;
                break;
            case 5:
                overlayTagColor = cotopaxi_1.TagColor.QUINARY;
                break;
            case 6:
                overlayTagColor = cotopaxi_1.TagColor.SENARY;
                break;
            case 7:
                overlayTagColor = cotopaxi_1.TagColor.SEPTENARY;
                break;
            default:
                overlayTagColor = cotopaxi_1.TagColor.SECONDARY;
        }
    }
    return {
        overlayTagColor,
        overlayTagContent,
    };
};
exports.getProductPriceOverlayTagColorAndContent = getProductPriceOverlayTagColorAndContent;
const isExtensiveProductTile = (productTileFormat) => productTileFormat === 'extensive';
exports.isExtensiveProductTile = isExtensiveProductTile;
const isProductOutOfStock = (product) => product.availability <= 0;
exports.isProductOutOfStock = isProductOutOfStock;
const isProductWithColorOutOfStock = (product) => { var _a, _b; return Number((_b = (_a = product.colours) === null || _a === void 0 ? void 0 : _a.find(item => item.colourId === product.colourId)) === null || _b === void 0 ? void 0 : _b.availability) <= 0; };
exports.isProductWithColorOutOfStock = isProductWithColorOutOfStock;
const hasProductAnotherAvailableColor = (product) => product.colours.some(item => item.colourId !== product.colourId && item.availability > 0);
exports.hasProductAnotherAvailableColor = hasProductAnotherAvailableColor;
const isClickAndCollectOnly = (product) => Boolean(product.hdExcluded) && !product.ccExcluded;
exports.isClickAndCollectOnly = isClickAndCollectOnly;
const isInStoreOnly = (product) => Boolean(product.hdExcluded) && Boolean(product.ccExcluded);
exports.isInStoreOnly = isInStoreOnly;
const getSizeDescription = (size, useOriginalSize) => useOriginalSize
    ? size.originalSize || size.code
    : size.fullCommercialSize || size.commercialSize || size.code;
exports.getSizeDescription = getSizeDescription;
const getNewProductOverlayTagColorAndTranslationKey = (mainWebShop) => {
    let tagColor;
    let translationKey;
    switch (mainWebShop) {
        case common_types_1.MainWebShop.JUTTU:
            tagColor = cotopaxi_1.TagColor.PRIMARY;
            translationKey = 'product.new.label.2';
            break;
        case common_types_1.MainWebShop.COTSWOLD:
            tagColor = cotopaxi_1.TagColor.TERTIARY;
            translationKey = 'product.new.label.3';
            break;
        case common_types_1.MainWebShop.RUNNERSNEED:
            tagColor = cotopaxi_1.TagColor.TERTIARY;
            translationKey = 'product.new.label.1';
            break;
        case common_types_1.MainWebShop.BEVER:
            tagColor = cotopaxi_1.TagColor.PRIMARY;
            translationKey = 'product.new.label.1';
            break;
        default:
            tagColor = cotopaxi_1.TagColor.SECONDARY;
            translationKey = 'product.new.label.1';
            break;
    }
    return {
        tagColor,
        translationKey,
    };
};
exports.getNewProductOverlayTagColorAndTranslationKey = getNewProductOverlayTagColorAndTranslationKey;
const getProductDetailUrl = ({ seoUrl, colourId, activeSize, isRecommended, additionalQueryParameters, }, root) => root
    .concat(seoUrl.indexOf('/') === 0 ? ProductConstants_1.PRODUCT_URL_PREFIX + seoUrl : ProductConstants_1.PRODUCT_URL_PREFIX + '/' + seoUrl)
    .concat('.html')
    .concat(colourId ? `?colour=${colourId}` : '')
    .concat(activeSize ? `&productSize=${activeSize}` : '')
    .concat(isRecommended ? '&rrec=true' : '')
    .concat(additionalQueryParameters ? additionalQueryParameters.replace('?', '&') : '');
exports.getProductDetailUrl = getProductDetailUrl;
const makeCommercialSize = (size) => [size.commercialSizePrefix, size.commercialSizeDefault, size.commercialSizeSuffix].filter(Boolean).join(' ');
exports.makeCommercialSize = makeCommercialSize;
const makeOriginalSize = (size) => [size.internalSizePrefix, size.originalSize, size.internalSizeSuffix].filter(Boolean).join(' ');
exports.makeOriginalSize = makeOriginalSize;
const isSoldOutSize = (size) => Number(size.availability) <= 0 && size.historicAvailable;
exports.isSoldOutSize = isSoldOutSize;
const selectOptionLabel = (size, showFullCommercialSize = true) => {
    let label = size.fullCommercialSize || size.commercialSize || size.code;
    if (!showFullCommercialSize) {
        label = size.commercialSizeDefault ? (0, exports.makeCommercialSize)(size) : (0, exports.makeOriginalSize)(size);
    }
    return label;
};
exports.selectOptionLabel = selectOptionLabel;
const mapSizeSelectOptions = (sizes, showSoldOutSizes, skuWithoutFiltering, allowSelectSoldOutSizes, showFullCommercialSize = true) => {
    const filteredSizes = sizes.filter(size => {
        if (skuWithoutFiltering === parseInt(size.sku, 10)) {
            return true;
        }
        return !showSoldOutSizes
            ? size.active && Number(size.availability) > 0
            : (size.active && Number(size.availability) > 0) ||
                (size.active && Number(size.availability) <= 0 && size.historicAvailable);
    });
    const options = filteredSizes.map(size => {
        const isSoldOut = (0, exports.isSoldOutSize)(size);
        const isNotifyMe = !size.storeSourceExcluded && isSoldOut;
        return Object.assign({ label: (0, exports.selectOptionLabel)(size, showFullCommercialSize), value: size.sku, disabled: !allowSelectSoldOutSizes && isSoldOut && skuWithoutFiltering !== parseInt(size.sku, 10), isNotifyMe }, (allowSelectSoldOutSizes ? { isSoldOut } : {}));
    });
    return { filteredSizes, options };
};
exports.mapSizeSelectOptions = mapSizeSelectOptions;
const splitSizesBySizeAndLength = (sizes, showFullCommercialSize = false) => {
    const sizesMap = new Map();
    const lengthsMap = new Map();
    sizes.forEach(s => {
        const label = (0, exports.selectOptionLabel)(s, showFullCommercialSize);
        const { size, length } = (0, exports.extractLengthAndSize)(label);
        if (size) {
            if (!sizesMap.has(size)) {
                sizesMap.set(size, []);
            }
            sizesMap.get(size).push(s.sku);
        }
        if (length) {
            if (!lengthsMap.has(length)) {
                lengthsMap.set(length, []);
            }
            lengthsMap.get(length).push(s.sku);
        }
    });
    const sortedLengthsMap = new Map([...lengthsMap.entries()].sort((a, b) => SIZELENGTH_ORDER[a[0]] - SIZELENGTH_ORDER[b[0]]));
    const totalSizesAmount = [...sizesMap.values()].reduce((sum, value) => sum + (value.length !== undefined ? value.length : 0), 0);
    const totalLengthsAmount = [...lengthsMap.values()].reduce((sum, value) => sum + (value.length !== undefined ? value.length : 0), 0);
    const isSizeAndLengthSplitted = sizesMap.size > 0 && sortedLengthsMap.size > 0 && totalSizesAmount === totalLengthsAmount;
    return { isSizeAndLengthSplitted, sizesMap, lengthsMap: sortedLengthsMap };
};
exports.splitSizesBySizeAndLength = splitSizesBySizeAndLength;
const extractLengthAndSize = (originalSize) => {
    const matchSize = originalSize.match(/short|regular|long|extra long/i);
    const length = matchSize ? (0, common_utils_1.capitalizeEach)(matchSize[0]) : '';
    const size = originalSize.replace(new RegExp(length, 'i'), '').trim();
    return { length, size };
};
exports.extractLengthAndSize = extractLengthAndSize;
const generateUniqueSizeOptions = (options) => {
    const uniqueLabelsMap = new Map();
    options
        .map(option => (Object.assign(Object.assign({}, option), { label: (0, exports.extractLengthAndSize)(option.label.toString()).size })))
        .forEach((option, _, allOptions) => {
        const allSoldOut = allOptions
            .filter(singleOption => singleOption.label === option.label)
            .every(opt => opt.isSoldOut);
        if (!uniqueLabelsMap.has(option.label)) {
            uniqueLabelsMap.set(option.label, {
                label: option.label,
                value: '',
                disabled: option.disabled,
                isSoldOut: allSoldOut,
                isNotifyMe: false,
            });
        }
    });
    return Array.from(uniqueLabelsMap.values());
};
exports.generateUniqueSizeOptions = generateUniqueSizeOptions;
const generateUniqueLengthOptions = (options, lengthsMap) => [...lengthsMap.keys()].map(value => {
    var _a;
    const allSoldOut = (_a = lengthsMap.get(value)) === null || _a === void 0 ? void 0 : _a.every(sku => { var _a; return (_a = options.find(opt => opt['value'] === sku)) === null || _a === void 0 ? void 0 : _a.isSoldOut; });
    return {
        label: value,
        value,
        disabled: !lengthsMap.has(value),
        isSoldOut: allSoldOut,
        isNotifyMe: false,
    };
});
exports.generateUniqueLengthOptions = generateUniqueLengthOptions;
const getActiveSizeOptions = (values = [], options, typedOptions, type) => {
    const activeOptions = values.map(value => {
        const opt = options.find(opt => opt['value'] === value);
        return {
            label: (0, exports.extractLengthAndSize)((opt === null || opt === void 0 ? void 0 : opt.label.toString()) || '')[type],
            value,
            disabled: opt === null || opt === void 0 ? void 0 : opt.disabled,
            isSoldOut: opt === null || opt === void 0 ? void 0 : opt.isSoldOut,
            isNotifyMe: opt === null || opt === void 0 ? void 0 : opt.isNotifyMe,
        };
    });
    const updatedOptions = typedOptions.map(typeOpt => {
        const opt = activeOptions.find(opt => opt['label'] === typeOpt.label);
        if (opt && typeOpt.label === opt.label) {
            return opt;
        }
        return Object.assign(Object.assign({}, typeOpt), { disabled: true, isSoldOut: false, isNotifyMe: false });
    });
    return updatedOptions;
};
exports.getActiveSizeOptions = getActiveSizeOptions;
const getOptionIndex = (options, selectedSize, selectedLength) => options.findIndex(option => {
    const { length, size } = (0, exports.extractLengthAndSize)(option.label.toString());
    return `${size} ${length}`.toLowerCase().trim() === `${selectedSize} ${selectedLength}`.toLowerCase().trim();
});
exports.getOptionIndex = getOptionIndex;
const getSizeSelectOptions = (sizes, showSoldOutSizes, skuWithoutFiltering) => (0, exports.mapSizeSelectOptions)(sizes, showSoldOutSizes, skuWithoutFiltering);
exports.getSizeSelectOptions = getSizeSelectOptions;
const filterProductCategoryEmptyFeatures = (category) => (Object.assign(Object.assign({}, category), { features: category.features.filter(feature => !(0, common_utils_1.isNullOrUndefined)(feature.featureValue) && feature.featureValue.length !== 0) }));
exports.filterProductCategoryEmptyFeatures = filterProductCategoryEmptyFeatures;
const filterProductCategoryFalseBooleanFeatures = (category, lang) => (Object.assign(Object.assign({}, category), { features: category.features.filter(feature => feature.featureType !== 'BOOLEAN' ||
        feature.featureValue.toLowerCase() === FEATURE_VALUE_YES[lang]) }));
exports.filterProductCategoryFalseBooleanFeatures = filterProductCategoryFalseBooleanFeatures;
// override hasDiscount because we only need to show label in swatch when it's promo price, not when bundle discount
const getSwatchColors = (colours) => colours.map(colour => {
    var _a;
    return (Object.assign(Object.assign({}, colour), { hasDiscount: (_a = colour.promoDiscountPresent) !== null && _a !== void 0 ? _a : colour.hasDiscount }));
});
exports.getSwatchColors = getSwatchColors;
const getAvailableSizes = (sizes = []) => sizes.filter(({ active, availability = 0 }) => active && availability > 0);
exports.getAvailableSizes = getAvailableSizes;
const getSizeButtonsError = (basketValidationState, type) => {
    switch (basketValidationState) {
        case common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT:
            if (type) {
                if (type === 'size')
                    return 'pdp.size.buttons.size.error';
                if (type === 'length')
                    return 'pdp.size.buttons.length.error';
            }
            return 'pdp.size.buttons.error';
        case common_types_1.BasketButtonError.SKU:
            if (type) {
                if (type === 'size')
                    return 'pdp.size.buttons.storestock.size.error';
                if (type === 'length')
                    return 'pdp.size.buttons.storestock.length.error';
            }
            return 'pdp.size.buttons.storestock.error';
        default:
            return 'pdp.size.buttons.notifyme.error';
    }
};
exports.getSizeButtonsError = getSizeButtonsError;
const createProductDetailToProductListerAdapter = (product) => {
    var _a, _b, _c, _d;
    const { productColorVariations } = product;
    const colours = productColorVariations
        ? productColorVariations.map(productColorVariation => {
            var _a;
            return ({
                allColourImages: productColorVariation.images ? productColorVariation.images.map(image => image.picture) : [],
                alwaysVisible: productColorVariation.alwaysVisible || false,
                availability: productColorVariation.availability || 0,
                colourCode: productColorVariation.colorCode,
                colourId: Number(productColorVariation.colorId),
                ccExcluded: productColorVariation.ccExcluded,
                description: productColorVariation.translations ? productColorVariation.translations.description : '',
                extendedRange: false,
                hasDifferentPrices: productColorVariation.hasDifferentPrices || false,
                hasDiscount: productColorVariation.hasDiscount || false,
                hoverImage: undefined,
                image: productColorVariation.images && productColorVariation.images.length > 0
                    ? ((_a = productColorVariation.images[0]) === null || _a === void 0 ? void 0 : _a.picture) || ''
                    : '',
                isCompareProduct: false,
                markdown: productColorVariation.markdown || false,
                newProduct: productColorVariation.newProduct,
                price: {
                    productDiscountPercentage: productColorVariation.colourDiscountPercentage,
                    productDiscountAmount: productColorVariation.colourDiscountAmount,
                },
                priceOverlay: productColorVariation.priceOverlay,
                prices: productColorVariation.prices,
                sizes: productColorVariation.sizes,
                swatch: productColorVariation.swatch,
            });
        })
        : [];
    return Object.assign(Object.assign({}, product), { availability: product.productAvailability || 0, brand: ((_a = product.brand) === null || _a === void 0 ? void 0 : _a.description) || '', colourId: Number(product.defaultColor), colours, defaultCategoryId: Number(product.defaultCategoryId), productId: Number(product.productId), productRating: (_b = product.productReview) === null || _b === void 0 ? void 0 : _b.avgScore, productReviews: (_c = product.productReview) === null || _c === void 0 ? void 0 : _c.numberOfScores, title: product.productTranslatedProperties && 'title' in product.productTranslatedProperties
            ? ((_d = product.productTranslatedProperties) === null || _d === void 0 ? void 0 : _d.title) || ''
            : '' });
};
exports.createProductDetailToProductListerAdapter = createProductDetailToProductListerAdapter;
