"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutPaymentProvider = exports.usePaymentState = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const defaultState = {
    info: {
        deliveryPromiseCutoff: null,
        hasDeliveryMessage: false,
    },
    optionError: {},
};
const PaymentContext = (0, react_1.createContext)([]);
const usePaymentState = () => (0, react_1.useContext)(PaymentContext);
exports.usePaymentState = usePaymentState;
const reducer = (state, action) => {
    switch (action.type) {
        case 'set_payment_info':
            return Object.assign(Object.assign({}, state), { info: action.payload });
        case 'set_payment_klarna_token':
            return Object.assign(Object.assign({}, state), { klarnaToken: action.payload });
        case 'set_payment_option_error': // clear errors if payload is empty
            return Object.assign(Object.assign({}, state), { optionError: Object.assign({}, (action.payload ? Object.assign(Object.assign({}, state.optionError), action.payload) : {})) });
        default:
            return state;
    }
};
const CheckoutPaymentProvider = ({ children }) => {
    return (0, jsx_runtime_1.jsx)(PaymentContext.Provider, { value: (0, react_1.useReducer)(reducer, Object.assign({}, defaultState)), children: children });
};
exports.CheckoutPaymentProvider = CheckoutPaymentProvider;
