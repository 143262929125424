"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupGenericContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const PaymentGroupGenericContainer = ({ group }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const dispatch = (0, react_redux_1.useDispatch)();
    const paymentOptionError = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetPaymentOptionError)());
    const isSetPaymentOptionSuccess = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetIsSetPaymentOptionSuccess)());
    const onSubmit = (values) => {
        dispatch(CheckoutActions_creators_1.default.createSavePaymentOptionAction({ values, group }));
    };
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: onSubmit, buttonState: !isSetPaymentOptionSuccess ? cotopaxi_1.ButtonState.DISABLED : undefined, checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: paymentOptionError ? t(paymentOptionError) : undefined }));
};
exports.PaymentGroupGenericContainer = PaymentGroupGenericContainer;
