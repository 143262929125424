"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRefundMessageType = void 0;
const common_types_1 = require("@as-react/common-types");
const getRefundMessageType = (saleLines) => {
    var _a;
    if (saleLines && (saleLines === null || saleLines === void 0 ? void 0 : saleLines.length) < 1)
        return common_types_1.RefundInformationMessageType.OTHER;
    const firstSaleLinesOriginalPaymentMethode = (_a = saleLines === null || saleLines === void 0 ? void 0 : saleLines[0]) === null || _a === void 0 ? void 0 : _a.originalPaymentMethod;
    const hasAllSameValue = saleLines === null || saleLines === void 0 ? void 0 : saleLines.map(item => item.originalPaymentMethod).every(item => item === firstSaleLinesOriginalPaymentMethode);
    return hasAllSameValue && !!firstSaleLinesOriginalPaymentMethode
        ? firstSaleLinesOriginalPaymentMethode
        : common_types_1.RefundInformationMessageType.OTHER;
};
exports.getRefundMessageType = getRefundMessageType;
