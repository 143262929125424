"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetOrderedItemsState = exports.makeGetAnnouncedReturn = exports.makeGetUpdateAnnouncedReturnState = exports.makeGetSubmitReturnItemsState = exports.makeGetReturnReasons = exports.makeGetReturnItems = exports.makeGetOrderedItems = exports.makeGetReturnedSales = void 0;
const reselect_1 = require("reselect");
const ordersStateSelector = (state) => state.orders;
const makeGetReturnedSales = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.returnedSales);
exports.makeGetReturnedSales = makeGetReturnedSales;
const makeGetOrderedItems = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.orderedItems);
exports.makeGetOrderedItems = makeGetOrderedItems;
const makeGetReturnItems = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => {
    if (!ordersState.returnItems)
        return null;
    return ordersState.returnItems
        .map(returnItem => (Object.assign(Object.assign({}, returnItem), { saleLines: returnItem.saleLines ? returnItem.saleLines.filter(saleLine => !saleLine.cantBeReturned) : [] })))
        .filter(returnItem => returnItem.saleLines && returnItem.saleLines.length);
});
exports.makeGetReturnItems = makeGetReturnItems;
const makeGetReturnReasons = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.returnReasons);
exports.makeGetReturnReasons = makeGetReturnReasons;
const makeGetSubmitReturnItemsState = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.submitReturnItemsState);
exports.makeGetSubmitReturnItemsState = makeGetSubmitReturnItemsState;
const makeGetUpdateAnnouncedReturnState = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.updateAnnouncedReturnState);
exports.makeGetUpdateAnnouncedReturnState = makeGetUpdateAnnouncedReturnState;
const makeGetAnnouncedReturn = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.announcedReturn);
exports.makeGetAnnouncedReturn = makeGetAnnouncedReturn;
const makeGetOrderedItemsState = () => (0, reselect_1.createSelector)(ordersStateSelector, (ordersState) => ordersState.orderedItemsState);
exports.makeGetOrderedItemsState = makeGetOrderedItemsState;
