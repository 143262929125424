"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWishlistLogin = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const useWishlistLogin = () => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const authenticationConfig = (0, ConfigurationUtil_1.getAuthenticationConfig)(aemConfiguration);
    const { wishlistLoginMutation } = (0, common_queries_1.wishlistMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: wishlistLogin } = (0, react_query_1.useMutation)(wishlistLoginMutation());
    const login = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield wishlistLogin(authenticationConfig);
            LocalStorageUtil_1.default.removeItem(StorageKeysConstants_1.LOCAL_STORAGE_WISH_LIST_KEY);
        }
        catch (e) { }
    });
    return { wishlistLogin: login };
};
exports.useWishlistLogin = useWishlistLogin;
