"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvoiceValidationSchema = exports.InvoiceFieldName = void 0;
const common_types_1 = require("@as-react/common-types");
const jsvat_next_1 = require("jsvat-next");
const Yup = require("yup");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AddressUtil_1 = require("../../../util/AddressUtil");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const addressFields_utils_1 = require("../address-fields/addressFields.utils");
var InvoiceFieldName;
(function (InvoiceFieldName) {
    InvoiceFieldName["VAT_NUMBER"] = "vatNumber";
    InvoiceFieldName["COMPANY_NAME"] = "companyName";
    InvoiceFieldName["COMPANY_DEPARTMENT"] = "companyDepartment";
    InvoiceFieldName["ADDRESS"] = "address";
})(InvoiceFieldName || (exports.InvoiceFieldName = InvoiceFieldName = {}));
const getInvoiceValidationSchema = ({ t, country, prefix = 'invoice.' }) => {
    var _a;
    const objectName = (_a = prefix === null || prefix === void 0 ? void 0 : prefix.split('.')[0]) !== null && _a !== void 0 ? _a : '';
    const [vatNumberField, companyNameField, companyDepartmentField, addressField] = (0, ArrayUtil_1.mapWithAssert)([
        InvoiceFieldName.VAT_NUMBER,
        InvoiceFieldName.COMPANY_NAME,
        InvoiceFieldName.COMPANY_DEPARTMENT,
        InvoiceFieldName.ADDRESS,
    ], name => (0, addressFields_utils_1.generateFieldName)(name));
    return Yup.object({
        needInvoice: Yup.boolean(),
        alternateInvoiceAddress: Yup.boolean(),
        [objectName]: Yup.object().when('needInvoice', {
            is: true,
            then: () => Yup.object({
                [vatNumberField]: Yup.string()
                    .required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`))
                    .test('checkVAT', t(common_types_1.ErrorMessage.INVALID_VAT_NUMBER), value => {
                    const currentCountry = country
                        ? jsvat_next_1.countries.find(item => item.codes.includes(country.toUpperCase()))
                        : undefined;
                    return currentCountry ? (0, jsvat_next_1.checkVAT)(value, [currentCountry]).isValid : false;
                }),
                [companyNameField]: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`)),
                [companyDepartmentField]: Yup.string(),
                // @todo: validate the address based on the value in vatCountry?
                [addressField]: (0, AddressUtil_1.getAddressValidationSchema)({ t, country }),
            }),
        }),
        [objectName]: Yup.object().when('alternateInvoiceAddress', {
            is: true,
            then: () => Yup.object({
                [addressField]: (0, AddressUtil_1.getAddressValidationSchema)({ t, country }),
            }),
        }),
    });
};
exports.getInvoiceValidationSchema = getInvoiceValidationSchema;
