"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const PickupPointUtil_1 = require("../../../util/PickupPointUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyConfirmationOpeningHours = ({ point }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isAlwaysOpen = PickupPointUtil_1.default.isAlwaysOpen(point.sanitizedLocationType || point.locationType);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, children: [isAlwaysOpen && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, inline: true, children: t('buy.delivery.pickuppoint.open.24') })), !isAlwaysOpen && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, children: point.openingHours.map(openingHour => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, mobile: 5, mobileLarge: 4, tabletSmall: 3, tablet: 2, desktop: 3, desktopLarge: 2, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, inline: true, children: t(`buy.pickup.dayofweek.${openingHour.dayOfWeek.toLowerCase()}`) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, mobile: 5, mobileLarge: 4, tabletSmall: 3, tablet: 2, desktop: 3, desktopLarge: 2, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, inline: true, children: [openingHour.openingTime, " - ", openingHour.closingTime] }) })] }, openingHour.dayOfWeek))) }))] }));
};
exports.default = BuyConfirmationOpeningHours;
