"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlossaryList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../../../components/common/html-include/HtmlInclude");
const GlossaryList = ({ glossaries }) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.keys(glossaries)
        .filter(glossaryItemKey => { var _a; return ((_a = glossaries[glossaryItemKey]) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .map(glossaryItemKey => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H2, size: cotopaxi_1.HeadingSize.S, children: glossaryItemKey.toUpperCase() }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: 4 } }, desktop: { spacing: { marginBottom: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { columns: { mobile: 1, tablet: 2 }, gap: { mobile: 's', tablet: 'm' }, children: glossaries[glossaryItemKey].map((glosssaryItem, index) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.EXTRA_CONTENT, emphasized: true, children: glosssaryItem.title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: glosssaryItem.description }) })] }, `${glosssaryItem.title.replace(/\s+/g, '')}-${index}`))) }) })] }, glossaryItemKey))) }));
exports.GlossaryList = GlossaryList;
