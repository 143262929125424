"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ActionEnums_1 = require("../../../enums/ActionEnums");
const customerActions = {
    getProfile() {
        return {
            type: ActionEnums_1.CustomerActionTypes.GET_PROFILE,
        };
    },
    setProfile(payload) {
        return {
            type: ActionEnums_1.CustomerActionTypes.SET_PROFILE,
            payload,
        };
    },
    getDeliveryAddresses() {
        return {
            type: ActionEnums_1.CustomerActionTypes.GET_DELIVERY_ADDRESSES,
        };
    },
    setDeliveryAddresses(payload) {
        return {
            type: ActionEnums_1.CustomerActionTypes.SET_DELIVERY_ADDRESSES,
            payload,
        };
    },
    deleteDeliveryAddress(payload) {
        return {
            type: ActionEnums_1.CustomerActionTypes.DELETE_DELIVERY_ADDRESS,
            payload,
        };
    },
    addDeliveryAddress(payload) {
        return {
            type: ActionEnums_1.CustomerActionTypes.ADD_DELIVERY_ADDRESS,
            payload,
        };
    },
};
exports.default = customerActions;
