"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketProductItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ProductTileCompact_1 = require("../../product-tile/compact/ProductTileCompact");
const _BasketProductItem = ({ basketProductData, tileType, dataQA, asStatic, withOriginalSize, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const rootUrl = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const image = (0, ImageUtil_1.getImageUrlForBasketTile)(basketProductData, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.thumb);
    const seoUrl = `${rootUrl}p${basketProductData.seoUrl}.html?colour=${basketProductData.colorId}`;
    const color = basketProductData.color ? (0, common_utils_1.capitalize)(basketProductData.color) : '';
    const priceActual = {
        formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(basketProductData.totalPrice),
    };
    const pricePrevious = basketProductData.productHasDiscount && !!basketProductData.totalPreviousPrice
        ? {
            formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(basketProductData.totalPreviousPrice),
        }
        : undefined;
    const cotopaxiPrice = ((_a = basketProductData.prices) === null || _a === void 0 ? void 0 : _a.SELL)
        ? (0, common_utils_1.generateCotopaxiPriceViewModel)({
            prices: basketProductData.prices,
            hasDiscount: Boolean(basketProductData.productHasDiscount),
        }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)
        : {
            priceActual,
            pricePrevious,
        };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { children: (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, Object.assign({ type: tileType, brand: basketProductData.brand, quantity: basketProductData.quantity, currency: currency, image: image, url: seoUrl, title: basketProductData.description, dataQA: dataQA, asStatic: asStatic }, cotopaxiPrice, (basketProductData.productType === common_types_1.ProductTypes.PRODUCT
            ? {
                sizeDescription: !withOriginalSize ? basketProductData.sizeDescription : basketProductData.originalSize,
                colorDescription: color,
            }
            : {}))) }));
};
exports.BasketProductItem = (0, react_1.memo)(_BasketProductItem);
