"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productContentVideosAction = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.productContentVideosAction = {
    getProductContentVideos: (payload) => ({
        type: ActionEnums_1.ProductContentVideoActionsTypes.GET_PRODUCT_CONTENT_VIDEOS,
        payload,
    }),
};
