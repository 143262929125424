"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationMessage = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const react_dom_1 = require("react-dom");
const NotificationMessage = ({ children }) => {
    const isClient = (0, cotopaxi_1.useIsClient)();
    if (!isClient)
        return null;
    const messageContainer = document.getElementById('notification__container');
    return messageContainer ? (0, react_dom_1.createPortal)(children, messageContainer) : null;
};
exports.NotificationMessage = NotificationMessage;
