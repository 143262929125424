"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderNavigationDisplayHandler = void 0;
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const FOLDER_NAVIGATION_SELECTOR = 'div[data-hypernova-key=AEMScenes_FolderNavigation]';
const FolderNavigationDisplayHandler = ({ setIsFolderNavigationDisplayed, }) => {
    const [navigationEl, setNavigationEl] = (0, react_1.useState)();
    const [folderNavigationElements, setFolderNavigationElements] = (0, react_1.useState)([]);
    const displayHandler = (folderNavigation, navigation) => {
        if (!!navigation && Boolean(folderNavigation)) {
            const isNavDisplayed = !navigation.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE);
            const isFolderNavigationDisplayed = folderNavigation.some(el => {
                const { top, bottom } = el.getBoundingClientRect();
                return (((isNavDisplayed ? top - DOMElementsConstants_1.NAVIGATION_HEIGHT : top) > 0 && top < window.innerHeight) ||
                    ((isNavDisplayed ? bottom - DOMElementsConstants_1.NAVIGATION_HEIGHT : bottom) > 0 && bottom < window.innerHeight));
            });
            setIsFolderNavigationDisplayed(isFolderNavigationDisplayed);
        }
    };
    (0, react_1.useEffect)(() => {
        const navigation = document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL);
        const folderNavigation = Array.from(document.querySelectorAll(FOLDER_NAVIGATION_SELECTOR));
        setNavigationEl(navigation);
        setFolderNavigationElements(folderNavigation);
        displayHandler(folderNavigation, navigation);
    }, []);
    (0, cotopaxi_1.useScrollPosition)(() => {
        displayHandler(folderNavigationElements, navigationEl);
    }, [navigationEl, folderNavigationElements]);
    return null;
};
exports.FolderNavigationDisplayHandler = FolderNavigationDisplayHandler;
