"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EnumUtil {
    static getItemBasedOnValue(items, searchedItem) {
        const foundedItem = Object.entries(items).find(([_, value]) => value === searchedItem);
        if (foundedItem) {
            return foundedItem[0];
        }
        return undefined;
    }
}
exports.default = EnumUtil;
