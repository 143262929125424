"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const WishListReducer_1 = require("../../wishList/reducers/WishListReducer");
const WishListSaga_1 = require("../../wishList/sagas/WishListSaga");
const loadWishListModule = () => ({
    id: 'WishList',
    reducerMap: {
        wishList: WishListReducer_1.wishListReducer,
    },
    sagas: [WishListSaga_1.default],
});
exports.default = loadWishListModule;
