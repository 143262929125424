"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptLoadingState = void 0;
var ScriptLoadingState;
(function (ScriptLoadingState) {
    ScriptLoadingState["NOT_LOADED"] = "NOT_LOADED";
    ScriptLoadingState["ON_HOLD"] = "ON_HOLD";
    ScriptLoadingState["LOADING"] = "LOADING";
    ScriptLoadingState["LOADED"] = "LOADED";
})(ScriptLoadingState || (exports.ScriptLoadingState = ScriptLoadingState = {}));
