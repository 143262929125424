"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const AEMUtil_1 = require("../../util/AEMUtil");
const StyleToColorMapping = {
    [common_types_1.ReactLineStyle.ACCENT]: cotopaxi_1.ColorsFasciaGroups.DIVIDER_LIST,
    [common_types_1.ReactLineStyle.DEFAULT]: 'N700',
    [common_types_1.ReactLineStyle.MEDIUM]: undefined,
    [common_types_1.ReactLineStyle.LIGHT]: 'N300',
};
const Line = ({ paddings, borders = '', lineType, lineStyle }) => {
    const lineColor = StyleToColorMapping[lineStyle];
    const wrapperClassNames = (0, react_1.useMemo)(() => Object.values(borders).filter(Boolean).join(' '), [borders]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, (0, AEMUtil_1.getBoxPropsFromAEMPaddings)(paddings), { className: wrapperClassNames, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { lineType: lineType, lineColor: lineColor }) })));
};
exports.default = Line;
