"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetMonetateProducts = exports.makeGetMonetateAvailableRecommendationsId = exports.makeGetMonetateRecommendations = exports.makeGetMonetateState = exports.monetateStateSelector = void 0;
const reselect_1 = require("reselect");
const findRecommendations = (state, id) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.recommendations) === null || _a === void 0 ? void 0 : _a.find(({ actionId }) => String(actionId) === id); };
const monetateStateSelector = (state) => state.monetate;
exports.monetateStateSelector = monetateStateSelector;
const makeGetMonetateState = () => (0, reselect_1.createSelector)(exports.monetateStateSelector, monetateState => monetateState);
exports.makeGetMonetateState = makeGetMonetateState;
const makeGetMonetateRecommendations = (id) => (0, reselect_1.createSelector)(exports.monetateStateSelector, state => { var _a; return ((_a = findRecommendations(state, id)) === null || _a === void 0 ? void 0 : _a.recommendations) || null; });
exports.makeGetMonetateRecommendations = makeGetMonetateRecommendations;
const makeGetMonetateAvailableRecommendationsId = (ids) => (0, reselect_1.createSelector)(exports.monetateStateSelector, state => ids.find(id => findRecommendations(state, id)) || null);
exports.makeGetMonetateAvailableRecommendationsId = makeGetMonetateAvailableRecommendationsId;
const makeGetMonetateProducts = (id) => (0, reselect_1.createSelector)(exports.monetateStateSelector, state => { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.products) === null || _a === void 0 ? void 0 : _a[id]) || []; });
exports.makeGetMonetateProducts = makeGetMonetateProducts;
