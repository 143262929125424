"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutOverviewContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutContext_1 = require("../../context/CheckoutContext");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../util/ImageUtil");
const CheckoutOverviewContainer = () => {
    const { footerImage } = (0, CheckoutContext_1.useCheckoutConfig)();
    const { imageCdnPrefix } = (0, common_components_1.useAemConfigurationContextWrapper)();
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { dataTestId: "checkout-overview", children: ["Overview Placeholder", footerImage && ((0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { itemsAlign: "center", children: (0, jsx_runtime_1.jsx)(andes_react_1.Image, { dataTestId: "checkout-overview-image", source: footerImage.imagePath, sourceSet: (0, ImageUtil_1.getImageSrcset)(footerImage, imageCdnPrefix), alt: footerImage.alt || 'checkout-overview-image' }) }))] }));
};
exports.CheckoutOverviewContainer = CheckoutOverviewContainer;
