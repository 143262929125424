"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const StoreUtil_1 = require("../../../util/StoreUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyStoreAddressCard = ({ storeDetails, textSize }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: textSize, children: [StoreUtil_1.default.getStoreName(storeDetails, mainWebShop, t), (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.country === 'GB' ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [storeDetails.address.street, " ", storeDetails.address.number, (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.city, (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.zipCode] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [storeDetails.address.street, " ", storeDetails.address.number, ", ", storeDetails.address.zipCode, ' ', storeDetails.address.city] })), storeDetails.phoneNumber && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.PHONE }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: storeDetails.phoneNumber })] }))] }));
};
exports.default = BuyStoreAddressCard;
