"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAlternatives = exports.fetchAlternativesSaga = exports.handleProductDetailInitSaga = exports.fetchReviewsDataSaga = exports.checkForDeliveryPromiseOnEsiData = exports.updateProductColor = exports.getMainWebshopId = exports.getProductColorVariations = exports.getSelectedColourId = exports.getProductId = exports.getSelectedSku = exports.getProductData = exports.getPartialRootStateData = exports.updateProductColorSaga = void 0;
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const effects_1 = require("redux-saga/effects");
const ProductActions_1 = require("../actions/ProductActions");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const ReviewsApi_1 = require("../../../api/ReviewsApi");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const SearchAPI_1 = require("../../../api/search/SearchAPI");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const ProductSelector_1 = require("../selectors/ProductSelector");
const PromotionAPI_1 = require("../../../api/PromotionAPI");
const DeliveryPromiseAPI_1 = require("../../../api/DeliveryPromiseAPI");
const AnalyticsActions_creators_1 = require("../../analytics/actions/AnalyticsActions.creators");
const MonetateSagas_1 = require("../../monetate/sagas/MonetateSagas");
const ProductBundleUtil_1 = require("../../../util/ProductBundleUtil");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const PRODUCT_SIZE_PARAM_KEY = 'productSize';
function* updateProductColorSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.SET_COLOR, updateProductColor);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.SET_ESI_DATA, checkForDeliveryPromiseOnEsiData);
}
exports.updateProductColorSaga = updateProductColorSaga;
function* loadProductDataSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.LOAD_PRODUCT_DATA, clearProduct);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.LOAD_PRODUCT_DATA, loadNewProduct);
}
function* clearProduct() {
    yield (0, effects_1.put)(ProductActions_1.productActions.createClearProductData());
}
const getPartialRootStateData = (state) => ({
    product: state.product,
});
exports.getPartialRootStateData = getPartialRootStateData;
const getProductData = (state) => state.product;
exports.getProductData = getProductData;
const getSelectedSku = (state) => state.product.selectedSku;
exports.getSelectedSku = getSelectedSku;
const getProductId = (state) => state.product.productId;
exports.getProductId = getProductId;
const getSelectedColourId = (state) => state.product.selectedColorId;
exports.getSelectedColourId = getSelectedColourId;
const getProductColorVariations = (state) => state.product.productColorVariations;
exports.getProductColorVariations = getProductColorVariations;
const getMainWebshopId = (state) => state.product.mainWebshopId;
exports.getMainWebshopId = getMainWebshopId;
const getSelectedProductSize = (state) => {
    var _a, _b, _c;
    if (!((_a = state.router.location) === null || _a === void 0 ? void 0 : _a.search))
        return null;
    return ((_c = (_b = (0, URLParamUtil_1.getQuerystringByName)(state.router.location.search, PRODUCT_SIZE_PARAM_KEY)) === null || _b === void 0 ? void 0 : _b.split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[0]) !== null && _c !== void 0 ? _c : null);
};
function* updateProductColor(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    if (aemConfigurationContext.pageInfo.isPDPPage) {
        const partialRootState = yield (0, effects_1.select)(exports.getPartialRootStateData);
        const replacingRouterStateTask = yield (0, effects_1.fork)(RouterSagas_1.replaceRouterState, { colour: action.payload }, Object.assign({}, partialRootState));
        yield (0, effects_1.join)(replacingRouterStateTask);
    }
}
exports.updateProductColor = updateProductColor;
function* checkForDeliveryPromiseOnEsiData() {
    const selectedSku = yield (0, effects_1.select)(exports.getSelectedSku);
    const deliveryPromise = yield (0, effects_1.select)((0, ProductSelector_1.makeGetDeliveryPromises)());
    const isSelectedSizeSoldOut = yield (0, effects_1.select)((0, ProductSelector_1.makeGetIsSelectedSizeSoldOut)());
    if (selectedSku && !deliveryPromise && !isSelectedSizeSoldOut) {
        yield (0, effects_1.put)(ProductActions_1.productActions.createSelectSizeAction({ sku: selectedSku }));
    }
}
exports.checkForDeliveryPromiseOnEsiData = checkForDeliveryPromiseOnEsiData;
function* loadNewProduct(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const product = yield (0, effects_1.call)(ProductDetailAPI_1.default.fetchProductForPromoBasketPopup, aemConfigurationContext.servicesApi, aemConfigurationContext.servicesApiPrefixAemServices, aemConfigurationContext.mainWebShop, aemConfigurationContext.fictiveWebShop, aemConfigurationContext.lang, aemConfigurationContext.isSingleLanguageSite, aemConfigurationContext.brandImageCdnUrl, action.payload.data.productCode);
    yield (0, effects_1.put)(ProductActions_1.productActions.createSetNewProduct(product));
}
function* fetchReviewsDataSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.FETCH_REVIEWS_DATA, fetchReviewsData);
}
exports.fetchReviewsDataSaga = fetchReviewsDataSaga;
function* fetchReviewsData(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const reviews = yield (0, effects_1.call)(ReviewsApi_1.default.getReviewsById, servicesEndpoint, productId, defaultRequestParams, action.payload);
    yield (0, effects_1.put)(ProductActions_1.productActions.createSetReviewsData({ reviews, lang: action.payload }));
}
function* getTechnicalCategoriesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.GET_TECHNICAL_CATEGORIES, getTechnicalCategories);
}
function* getTechnicalCategories(action) {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const productColorVariations = yield (0, effects_1.select)(exports.getProductColorVariations);
    const mainWebshopId = yield (0, effects_1.select)(exports.getMainWebshopId);
    const productColorId = (_a = productColorVariations === null || productColorVariations === void 0 ? void 0 : productColorVariations.find(colorVariation => colorVariation.colorId === action.payload)) === null || _a === void 0 ? void 0 : _a.productColorId;
    try {
        const technicalCategories = yield (0, effects_1.call)(ProductDetailAPI_1.default.getTechnicalCategories, servicesEndpoint, defaultRequestParams, mainWebshopId, productId, productColorId, true);
        yield (0, effects_1.put)(ProductActions_1.productActions.createSetTechnicalCategories(technicalCategories));
    }
    catch (_b) { }
}
function* getLinkedProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.GET_LINKED_PRODUCTS, getLinkedProducts);
}
function* getLinkedProducts(action) {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const mainWebshopId = yield (0, effects_1.select)(exports.getMainWebshopId);
    const productColorVariations = yield (0, effects_1.select)(exports.getProductColorVariations);
    const productColorId = (_a = productColorVariations === null || productColorVariations === void 0 ? void 0 : productColorVariations.find(colorVariation => colorVariation.colorId === action.payload)) === null || _a === void 0 ? void 0 : _a.productColorId;
    try {
        const linkedProducts = yield (0, effects_1.call)(ProductsAPI_1.default.getLinkedProducts, Number(productId), productColorId, common_types_1.ProductLinkType.STL, mainWebshopId, servicesEndpoint, defaultRequestParams);
        const products = yield (0, effects_1.all)(linkedProducts.map(product => (0, effects_1.call)(ProductsAPI_1.default.getSingleProductData, servicesEndpoint, defaultRequestParams.mainWebshop, defaultRequestParams.anaLang, defaultRequestParams.language, defaultRequestParams.shopId, product.linkedProductCode, product.linkedColourId.toString(), undefined, true)));
        const productsPriceData = yield ProductDetailAPI_1.default.getAllProductDetailPriceByProductId(products.map(product => product.productId), servicesEndpoint, defaultRequestParams);
        const mergedProducts = products.map((product, i) => (0, common_utils_1.mergeProductPricesToProductLister)(product, productsPriceData[i]));
        yield (0, effects_1.put)(ProductActions_1.productActions.createSetLinkedProducts(mergedProducts));
    }
    catch (_b) { }
}
function* fetchBundleProducts() {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const colourId = yield (0, effects_1.select)(exports.getSelectedColourId);
    const bundle = yield (0, effects_1.call)(PromotionAPI_1.default.getProductBundle, serviceEndpoint, defaultRequestParams, productId, colourId);
    return bundle;
}
function* getBundle({ payload: monetateId }) {
    try {
        const colourId = yield (0, effects_1.select)(exports.getSelectedColourId);
        let bundle = null;
        if (monetateId) {
            const parentProduct = yield (0, effects_1.select)(exports.getProductData);
            const monetateProducts = yield (0, effects_1.call)(MonetateSagas_1.fetchMonetateProducts, {
                actionId: monetateId,
            });
            if (monetateProducts.length) {
                bundle = (0, ProductBundleUtil_1.getMonetateProductBundle)(parentProduct, monetateProducts, Number(monetateId));
            }
        }
        else {
            bundle = yield (0, effects_1.call)(fetchBundleProducts);
        }
        if (bundle) {
            yield (0, effects_1.put)(ProductActions_1.productActions.setBundle(bundle));
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.bundleDealsViewed(colourId));
        }
    }
    catch (_a) { }
}
function* getBundleSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.GET_BUNDLE, getBundle);
}
function* calculateBundle() {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const businessUnitId = yield (0, effects_1.call)(SagaContextSelectors_1.getBusinessUnitId, aemConfigurationContext);
    const bundle = yield (0, effects_1.select)((0, ProductSelector_1.makeGetBundle)());
    const selectedSku = yield (0, effects_1.select)((0, ProductSelector_1.makeGetSelectedSku)());
    const selectedBundleProducts = yield (0, effects_1.select)((0, ProductSelector_1.makeGetSelectedBundleProducts)());
    let calculation;
    if (!bundle || selectedBundleProducts.length === 0) {
        yield (0, effects_1.put)(ProductActions_1.productActions.bundleCalculateFailure(new Error('No Bundle or no bundle products selected')));
        return;
    }
    try {
        if (bundle.isMonetateBundle) {
            const parentProductPrice = yield (0, effects_1.select)((0, ProductSelector_1.makeGetPriceInformation)());
            const childrenPrices = (0, ProductBundleUtil_1.getBundleChildrenPrice)(bundle);
            calculation = {
                bundleCalculationPrice: (0, ProductBundleUtil_1.getBundlePrice)(parentProductPrice.prices, childrenPrices),
                bundleCalculationPromise: null,
            };
            if (selectedSku) {
                const selectedProducts = selectedBundleProducts.map(({ productId, colourId, skuId }) => ({
                    productId,
                    colorId: colourId,
                    skuId: Number(skuId),
                    quantity: 1,
                }));
                selectedProducts.push({
                    productId: bundle.productId,
                    colorId: bundle.colourId,
                    skuId: selectedSku,
                    quantity: 1,
                });
                try {
                    const bundleDeliveryPromise = yield (0, effects_1.call)(DeliveryPromiseAPI_1.default.getBundleDeliveryPromise, servicesEndpoint, defaultRequestParams, businessUnitId, selectedProducts);
                    calculation.bundleCalculationPromise = Object.assign(Object.assign({}, bundleDeliveryPromise), { cutoff: ((_a = bundleDeliveryPromise.nextDayResult) === null || _a === void 0 ? void 0 : _a.cutoffAsUTC) || undefined });
                }
                catch (_b) { }
            }
        }
        else {
            calculation = yield (0, effects_1.call)(PromotionAPI_1.default.calculateProductBundle, servicesEndpoint, defaultRequestParams, {
                bundleId: bundle.bundleId,
                productId: bundle.productId,
                colourId: bundle.colourId,
                selectedBundleProducts,
                skuId: selectedSku,
            });
        }
        yield (0, effects_1.put)(ProductActions_1.productActions.bundleCalculateSuccess(calculation));
    }
    catch (error) {
        yield (0, effects_1.put)(ProductActions_1.productActions.bundleCalculateFailure(error));
    }
}
function* calculateBundleSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_START, calculateBundle);
}
function* handleProductDetailInitSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.INIT, handleProductDetailInit);
}
exports.handleProductDetailInitSaga = handleProductDetailInitSaga;
function* handleProductDetailInit() {
    /**
     * Handle product sizes
     */
    const selectedProductSize = yield (0, effects_1.select)(getSelectedProductSize);
    const productInfo = yield (0, effects_1.select)(exports.getProductData);
    if (productInfo.productColorVariations && productInfo.selectedColorId && selectedProductSize) {
        const selectedProductColorVariations = productInfo.productColorVariations.filter(productColorVariation => productColorVariation.colorId === productInfo.selectedColorId);
        if (selectedProductColorVariations && selectedProductColorVariations.length > 0) {
            const selectedProductColorVariation = selectedProductColorVariations[0];
            const availableSizes = selectedProductColorVariation.sizes.filter(s => s.availability && s.availability > 0);
            const matchingOriginalSize = availableSizes.find(s => {
                var _a, _b, _c, _d;
                return ((_a = s.fullCommercialSize) === null || _a === void 0 ? void 0 : _a.startsWith(selectedProductSize)) ||
                    ((_b = s.fullCommercialSize) === null || _b === void 0 ? void 0 : _b.split(' ')[1]) === selectedProductSize ||
                    ((_c = s.fullCommercialSize) === null || _c === void 0 ? void 0 : _c.split(' | ')[1]) === selectedProductSize ||
                    ((_d = s.originalSize) === null || _d === void 0 ? void 0 : _d.split('/').includes(selectedProductSize)) ||
                    s.originalSize === selectedProductSize ||
                    s.code === selectedProductSize;
            });
            if (matchingOriginalSize) {
                yield (0, effects_1.put)(ProductActions_1.productActions.createSelectSizeAction({ sku: matchingOriginalSize.sku }));
            }
        }
    }
    /**
     * Product availability
     */
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const productAvailability = yield (0, effects_1.call)(ProductDetailAPI_1.default.getProductDetailAvailabilityByProductId, aemConfigurationContext.servicesApi, aemConfigurationContext.mainWebShop, productInfo.productId);
    yield (0, effects_1.put)(ProductActions_1.productActions.createSetEsiDataAction({ productAvailability }));
}
function* fetchAlternativesSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.FETCH_ALTERNATIVES, fetchAlternatives);
}
exports.fetchAlternativesSaga = fetchAlternativesSaga;
function* fetchAlternatives(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const colourId = yield (0, effects_1.select)(exports.getSelectedColourId);
    try {
        const searchResults = yield (0, effects_1.call)(SearchAPI_1.default.searchFredhopperProducts, servicesEndpoint, defaultRequestParams, productId, colourId);
        const alternativeProducts = searchResults.find((item) => item.campaignName === action.payload.campaign || item.campaignName === action.payload.campaignFallback);
        yield (0, effects_1.put)(ProductActions_1.productActions.setAlternatives((alternativeProducts === null || alternativeProducts === void 0 ? void 0 : alternativeProducts.items) || []));
    }
    catch (_a) { }
}
exports.fetchAlternatives = fetchAlternatives;
function* productSaga() {
    yield (0, effects_1.all)([
        updateProductColorSaga(),
        handleProductDetailInitSaga(),
        loadProductDataSaga(),
        fetchReviewsDataSaga(),
        getTechnicalCategoriesSaga(),
        getLinkedProductsSaga(),
        getBundleSaga(),
        calculateBundleSaga(),
        fetchAlternativesSaga(),
    ]);
}
exports.default = productSaga;
