"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailLoginRegistrationChangeHandler = void 0;
const formik_1 = require("formik");
const react_1 = require("react");
const LoginRegistrationForm_1 = require("./LoginRegistrationForm");
function EmailLoginRegistrationChangeHandler({ emailFieldKey = 'email', formState, setFormState, }) {
    const { values } = (0, formik_1.useFormikContext)();
    const emailValue = (0, formik_1.getIn)(values, emailFieldKey);
    (0, react_1.useEffect)(() => {
        if (formState === LoginRegistrationForm_1.LoginRegistrationFormState.REGISTER) {
            return;
        }
        setFormState(LoginRegistrationForm_1.LoginRegistrationFormState.REGISTER);
    }, [emailValue]);
    return null;
}
exports.EmailLoginRegistrationChangeHandler = EmailLoginRegistrationChangeHandler;
