"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectStoreContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const CollectStoreSelected_1 = require("./CollectStoreSelected");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliverySubmitForm_1 = require("../DeliverySubmitForm");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const react_redux_1 = require("react-redux");
const StoreFinderPopup_1 = require("../../../product-detail/store-finder-popup/StoreFinderPopup");
const CustomerDetailsFields_1 = require("../CustomerDetailsFields");
const CustomerDetailsPreview_1 = require("../CustomerDetailsPreview");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const CollectStoreContainer = () => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [{ info, errors, activeDeliveryOption, editCustomerDetails }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const [{ collectStore }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(aemConfiguration);
    const fictiveWebshop = (0, ConfigurationUtil_1.getFictiveWebShop)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const productAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const { isOpen, selectedStore } = collectStore;
    const { store: storeId, customerDetails } = info;
    const [locationSearchParams, setLocationSearchParams] = (0, react_1.useState)();
    const showCustomerDetailsForm = editCustomerDetails || !customerDetails;
    const { getClickAndCollectStoresOpeningsHours, getStoreInformation } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const productData = products
        .filter(product => product.productType === common_types_1.ProductTypes.PRODUCT)
        .map(product => ({
        skuId: Number(product.sku),
        productId: Number(product.productId),
        colorId: Number(product.colorId),
        quantity: product.quantity,
    }));
    const isEnabled = isOpen && locationSearchParams && !!((_a = locationSearchParams.locationSearch) === null || _a === void 0 ? void 0 : _a.location) && productData.length > 0;
    const storesQueryResult = (0, react_query_1.useQuery)(getClickAndCollectStoresOpeningsHours({
        businessUnitId,
        fictiveShopId: fictiveWebshop,
        productData,
        location: ((locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch) || {}).location,
        onlyInStock: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.isShowStock) || false,
        onlyInSpecialtyStore: false,
        sortBy: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.sortBy) || 'distance',
        isShopLanding: isShoplanding,
        selectedStoreId: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch.type) === common_types_1.LocationSearchType.CUSTOM_SEARCH
            ? undefined
            : locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.selectedStoreId,
    }, { enabled: Boolean(isEnabled) }));
    const validateStore = activeDeliveryOption && storeId && (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) === common_types_1.DeliveryOptionsEnum.COLLECT_STORE;
    const { data: storeInformation } = (0, react_query_1.useQuery)(getStoreInformation({
        businessUnitId,
        storeId: (selectedStore || {}).storeId || storeId,
        weeks: 2,
    }, {
        enabled: Boolean(validateStore),
    }));
    const { data: storesWithOpeningHours } = (0, react_query_1.useQuery)(getClickAndCollectStoresOpeningsHours({
        businessUnitId,
        fictiveShopId: fictiveWebshop,
        productData,
        location: {
            lat: Number(storeInformation === null || storeInformation === void 0 ? void 0 : storeInformation.geographic.latitude),
            lng: Number(storeInformation === null || storeInformation === void 0 ? void 0 : storeInformation.geographic.longitude),
        },
        onlyInStock: false,
        onlyInSpecialtyStore: false,
        sortBy: 'distance',
        isShopLanding: isShoplanding,
        selectedStoreId: storeId,
    }, {
        enabled: Boolean(storeInformation && storeId),
    }));
    (0, react_1.useEffect)(() => {
        if ((activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) === common_types_1.DeliveryOptionsEnum.COLLECT_STORE && !validateStore) {
            toggleStoreFinder(true);
        }
    }, [activeDeliveryOption, selectedStore]);
    (0, react_1.useEffect)(() => {
        if (!storeInformation || !storesWithOpeningHours) {
            return;
        }
        const preselectedStore = storesWithOpeningHours === null || storesWithOpeningHours === void 0 ? void 0 : storesWithOpeningHours.find(store => store.storeId === storeId);
        if (preselectedStore) {
            dispatch({
                type: 'select_store',
                payload: Object.assign({}, preselectedStore),
            });
            return;
        }
        toggleStoreFinder(true);
    }, [isOpen, validateStore, storeInformation, storesWithOpeningHours]);
    const toggleStoreFinder = (state) => {
        dispatch({ type: 'store_set_is_open', payload: state });
    };
    (0, react_1.useEffect)(() => {
        if (!isOpen && !selectedStore) {
            dispatch({ type: 'set_active_delivery_option', payload: undefined });
        }
    }, [isOpen]);
    const handleSearch = (searchParams) => {
        setLocationSearchParams(searchParams);
    };
    const handleStoreSelect = (store) => {
        if (store.storeId === (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.storeId)) {
            return;
        }
        dispatch({ type: 'select_store', payload: store });
        toggleStoreFinder(false);
    };
    const resetCollectStore = () => {
        toggleStoreFinder(false);
    };
    const handleEditCustomerDetails = () => {
        dispatch({ type: 'edit_customer_details', payload: true });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [selectedStore && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "2", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: showCustomerDetailsForm ? '4' : undefined, children: [(0, jsx_runtime_1.jsx)(CollectStoreSelected_1.CollectStoreSelected, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(DeliverySubmitForm_1.DeliverySubmitForm, { children: showCustomerDetailsForm ? ((0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {})) : ((0, jsx_runtime_1.jsx)(CustomerDetailsPreview_1.CustomerDetailsPreview, { onEdit: handleEditCustomerDetails })) })] }) }) })), activeDeliveryOption && errors[activeDeliveryOption.name] && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(errors[activeDeliveryOption.name]), status: "error" })), (0, jsx_runtime_1.jsx)(StoreFinderPopup_1.StoreFinderPopup, { type: common_types_1.StoreFinderType.CHECKOUT, title: t('checkout:checkout.delivery.store.flyout.title'), isOpen: isOpen, onSearch: handleSearch, textCTA: t('checkout:checkout.delivery.store.flyout.cta', {
                    count: productAmount,
                }), handleClosePopup: resetCollectStore, onStoreSelect: handleStoreSelect, storesQueryResult: storesQueryResult })] }));
};
exports.CollectStoreContainer = CollectStoreContainer;
