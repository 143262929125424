"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const dayjs = require("dayjs");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const AuthorDataContainer = ({ author, dateModified, dateCreated }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.seo);
    const dateLastModified = dateModified !== dateCreated ? dayjs(dateModified).format(common_types_1.DateFormat.DD_MM_YYYY_DASH) : null;
    if (!author)
        return;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.Text, { variant: "bodyS", color: "tertiary", as: "span", children: [t('author.data.by'), " ", author, dateLastModified && ` • ${t('author.data.update')} ${dateLastModified}`] }) }));
};
exports.default = AuthorDataContainer;
