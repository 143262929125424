"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCountryByFictiveWebShop = void 0;
const common_types_1 = require("@as-react/common-types");
const getCountryByFictiveWebShop = (fictiveWebShop) => {
    switch (fictiveWebShop) {
        case common_types_1.FictiveWebShop.BEVER_NL:
        case common_types_1.FictiveWebShop.ASADVENTURE_NL:
            return 'NL';
        case common_types_1.FictiveWebShop.ASADVENTURE_FR:
            return 'FR';
        case common_types_1.FictiveWebShop.ASADVENTURE_LU:
            return 'LU';
        case common_types_1.FictiveWebShop.COTSWOLDOUTDOOR_COM:
        case common_types_1.FictiveWebShop.SNOWANDROCK_COM:
        case common_types_1.FictiveWebShop.CYCLESURGERY_COM:
        case common_types_1.FictiveWebShop.RUNNERSNEED_COM:
        case common_types_1.FictiveWebShop.ASADVENTURE_CO_UK:
            return 'GB';
        case common_types_1.FictiveWebShop.COTSWOLDOUTDOOR_IE:
            return 'IE';
        case common_types_1.FictiveWebShop.COTSWOLDOUTDOOR_US:
            return 'US';
        case common_types_1.FictiveWebShop.COTSWOLDOUTDOOR_COM_AU:
            return 'AU';
        default:
            return 'BE';
    }
};
exports.getCountryByFictiveWebShop = getCountryByFictiveWebShop;
