"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductDetailV2InformationHead_1 = require("../../components/product-detail/product-detail-information-head/ProductDetailV2InformationHead");
const ProductDetailInformationContainer = ({ productDetailTabs, }) => {
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const productDefaults = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const review = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReview)());
    const expertReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)());
    return ((0, jsx_runtime_1.jsx)(ProductDetailV2InformationHead_1.default, { brand: detailInformation.brand, description: detailInformation.description, productCode: productDefaults.productCode, review: review, expertReviews: expertReviews, title: detailInformation.title, productDetailTabs: productDetailTabs }));
};
exports.default = ProductDetailInformationContainer;
