"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTileHelpers = void 0;
const react_redux_1 = require("react-redux");
const CompareSelectors_1 = require("../redux/compare/selectors/CompareSelectors");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const CompareActions_creators_1 = require("../redux/compare/actions/CompareActions.creators");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const useProductTileHelpers = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const compareItems = (0, react_redux_1.useSelector)((0, CompareSelectors_1.makeGetCompareItems)());
    const handleWishlistToggle = (product) => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(product));
    const handleUpdateCompareList = (product) => dispatch(CompareActions_creators_1.default.createUpdateOnListerAction(product));
    const handleAddToBasket = (product, analyticsData, location) => dispatch(BasketActions_creators_1.basketActions.createAddExtensiveTileProductAction(product, location, analyticsData));
    return {
        handleWishlistToggle,
        handleUpdateCompareList,
        handleAddToBasket,
        compareItems,
    };
};
exports.useProductTileHelpers = useProductTileHelpers;
