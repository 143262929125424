"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroups = exports.IMAGE_PREFIX = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const PaymentGroupBancontactContainer_1 = require("../../../containers/buy/PaymentGroupBancontactContainer");
const PaymentGroupKlarnaContainer_1 = require("../../../containers/buy/PaymentGroupKlarnaContainer");
const PaymentGroupCreditCardContainer_1 = require("../../../containers/buy/PaymentGroupCreditCardContainer");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PaymentGroupUtil_1 = require("../../../util/PaymentGroupUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const PaymentGroupGenericContainer_1 = require("../../../containers/buy/PaymentGroupGenericContainer");
const asNumeral_1 = require("../../../vendors/asNumeral");
const EcochequeAvailabilityMessage_1 = require("../../ecocheque/EcochequeAvailabilityMessage");
exports.IMAGE_PREFIX = '/content/dam/general/payment_logos/';
const PaymentGroups = ({ paymentGroups, currency, changePaymentOption, chosenPaymentOption, chosenPaymentOptionId, creditCardSavingLegalMessage, payPalInfoMessage, lockPaymentOptions, onPaymentGroupsInit, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const getSelectedPaymentGroup = () => {
        let selectedPaymentGroup;
        if (chosenPaymentOptionId) {
            selectedPaymentGroup = paymentGroups.find(group => group.paymentOptions.find(option => option.paymentOptionId === chosenPaymentOptionId));
        }
        return selectedPaymentGroup;
    };
    const [selectedPaymentGroupName, setSelectedPaymentGroupName] = (0, react_1.useState)(((_a = getSelectedPaymentGroup()) === null || _a === void 0 ? void 0 : _a.name) || null);
    const [selectedPaymentGroupOrder, setSelectedPaymentGroupOrder] = (0, react_1.useState)();
    const webImagesList = (group) => Array.from(new Set(group.paymentOptions.map(item => item.webImage)));
    const isSingleOption = (group) => group.paymentOptions.length === 1;
    const isAllPaymentOptionsValid = (group) => group.paymentOptions.every(option => { var _a; return !((_a = option.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.length) && !option.exceededMaxBasketCost; });
    const onChange = (option, group) => {
        changePaymentOption(option, group);
        setSelectedPaymentGroupName(group.name);
        setSelectedPaymentGroupOrder(group.sortOrder);
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION, option.replace(/\s+/g, ''));
    };
    const gePaymentGroupContent = (group) => {
        if (group.name === selectedPaymentGroupName) {
            const groupName = selectedPaymentGroupName.toUpperCase().indexOf(common_types_1.PaymentGroupName.KLARNA) !== -1
                ? common_types_1.PaymentGroupName.KLARNA
                : selectedPaymentGroupName;
            switch (groupName) {
                case common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY:
                    return (0, jsx_runtime_1.jsx)(PaymentGroupBancontactContainer_1.default, {});
                case common_types_1.PaymentGroupName.KLARNA:
                    return (0, jsx_runtime_1.jsx)(PaymentGroupKlarnaContainer_1.default, { group: group });
                case common_types_1.PaymentGroupName.CREDITCARD:
                    return ((0, jsx_runtime_1.jsx)(PaymentGroupCreditCardContainer_1.default, { group: group, creditCardSavingLegalMessage: creditCardSavingLegalMessage }));
                case common_types_1.PaymentGroupName.IDEAL:
                case common_types_1.PaymentGroupName.PAYPAL:
                case common_types_1.PaymentGroupName.APPLEPAY_SAFERPAY:
                case common_types_1.PaymentGroupName.GOOGLEPAY_SAFERPAY:
                case common_types_1.PaymentGroupName.ECOCHEQUE:
                    return (0, jsx_runtime_1.jsx)(PaymentGroupGenericContainer_1.PaymentGroupGenericContainer, { group: group });
                default:
                    return null;
            }
        }
        return null;
    };
    const getPaymentGroupSubTitle = (group) => {
        switch (group.name) {
            case common_types_1.PaymentGroupName.PAYPAL:
                return payPalInfoMessage;
            default:
                return;
        }
    };
    const getCaveat = (group) => {
        var _a;
        const isAllOptionsValid = isAllPaymentOptionsValid(group);
        const isEcocheque = group.name === common_types_1.PaymentGroupName.ECOCHEQUE;
        if (!isAllOptionsValid && (isSingleOption(group) || isEcocheque)) {
            const paymentOption = group.paymentOptions[0];
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(_a = paymentOption.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.map(error => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.${error}`, {
                            currency,
                            minAmount: paymentOption.minBasketCost,
                            maxAmount: paymentOption.maxBasketCost,
                        }) }, error))), paymentOption.exceededWarningBasketCost && !paymentOption.exceededMaxBasketCost && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.exceededWarningBasketCost`) }))] }));
        }
        else if (isAllOptionsValid && isEcocheque && group.amountPayableWithOption !== null) {
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2 } }, children: (0, jsx_runtime_1.jsx)(EcochequeAvailabilityMessage_1.EcochequeAvailabilityMessage, { dataQA: "ecocheque_amount_payable_message", message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.ecocheque.amount_payable`, {
                        currency,
                        amount: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(group.amountPayableWithOption),
                    }) }) }));
        }
        return undefined;
    };
    (0, react_1.useEffect)(() => {
        const storageGroupName = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION);
        if (storageGroupName) {
            const group = paymentGroups.find((gr) => gr.name.replace(/\s+/g, '') === storageGroupName);
            if (group === null || group === void 0 ? void 0 : group.name) {
                const radioItem = document.querySelector(`#${storageGroupName}`);
                radioItem === null || radioItem === void 0 ? void 0 : radioItem.click();
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        onPaymentGroupsInit(chosenPaymentOption, getSelectedPaymentGroup());
    }, []);
    (0, react_1.useEffect)(() => {
        const element = document.getElementById(`${selectedPaymentGroupName}_${selectedPaymentGroupOrder}`);
        if (!element)
            return;
        ScrollUtil_1.ScrollUtil.scrollToPosition(element.offsetTop);
    }, [selectedPaymentGroupName]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList, { open: selectedPaymentGroupName ? [selectedPaymentGroupName] : undefined, dataQA: "payment_form", locked: lockPaymentOptions, children: paymentGroups.map(group => ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList.Item, { contentWithoutSpacing: true, id: `${group.name.replace(/\s+/g, '')}_${group.sortOrder}`, disabled: !isAllPaymentOptionsValid(group), title: (0, PaymentGroupUtil_1.getPaymentGroupTitle)(group, t), subtitle: getPaymentGroupSubTitle(group), subtitleColor: cotopaxi_1.TextColor.INHERIT, icon: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: webImagesList(group).map(image => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { size: cotopaxi_1.ImageSize.CHECK_LIST_ITEM_DEFAULT, src: (0, PaymentGroupUtil_1.getPaymentGroupImageUrl)(image, exports.IMAGE_PREFIX) }, image))) }), radio: {
                id: group.name.replace(/\s+/g, ''),
                name: 'payment-items',
                value: group.name,
            }, onChange: option => onChange(option, group), caveat: getCaveat(group), children: selectedPaymentGroupName && gePaymentGroupContent(group) }, group.name.replace(/\s+/g, '')))) }));
};
exports.PaymentGroups = PaymentGroups;
