"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareFeatures = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const CompareFeatureItem_1 = require("./CompareFeatureItem");
const useInitGlossaryTooltips_1 = require("../../../hooks/useInitGlossaryTooltips");
const CompareFeatures = ({ attributes, compareFeaturesSwiperInstances, setSwiperInstances, }) => {
    const { isGlossaryLoaded, shouldInitGlossaryTooltips } = (0, useInitGlossaryTooltips_1.useInitGlossaryTooltips)();
    const setSwiperInstance = (swiper, attribute) => {
        const existedInstanceIndex = compareFeaturesSwiperInstances.findIndex(sw => attribute === sw.attribute);
        if (existedInstanceIndex === -1) {
            compareFeaturesSwiperInstances.push({
                attribute,
                swiper,
            });
        }
        else {
            compareFeaturesSwiperInstances[existedInstanceIndex].swiper = swiper;
        }
        setSwiperInstances(compareFeaturesSwiperInstances);
    };
    (0, react_1.useEffect)(() => {
        const swiperInstances = compareFeaturesSwiperInstances.filter(swiper => !!attributes.find(feature => swiper.attribute === feature.attribute));
        setSwiperInstances(swiperInstances);
    }, [attributes]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: attributes.map((feature) => ((0, jsx_runtime_1.jsx)(CompareFeatureItem_1.CompareFeatureItem, { feature: feature, isGlossaryLoaded: isGlossaryLoaded, shouldInitGlossaryTooltip: shouldInitGlossaryTooltips, setSwiperInstance: setSwiperInstance }, feature.attribute))) }));
};
exports.CompareFeatures = CompareFeatures;
