"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadProductContentVideosSaga = void 0;
const SagaContextSelectors_1 = require("./../../SagaContextSelectors");
const effects_1 = require("redux-saga/effects");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const ProductActions_1 = require("../../product/actions/ProductActions");
const common_types_1 = require("@as-react/common-types");
function* loadProductContentVideosSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductContentVideoActionsTypes.GET_PRODUCT_CONTENT_VIDEOS, getProductContentVideos);
}
exports.loadProductContentVideosSaga = loadProductContentVideosSaga;
function* getProductContentVideos(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const serviceEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const videos = yield (0, effects_1.call)(ProductsAPI_1.default.getProductVideos, action.payload, serviceEndpoint, defaultRequestParams);
        const inspirationalVideos = videos &&
            videos.filter((video) => { var _a; return ((_a = video.productVideoDetail) === null || _a === void 0 ? void 0 : _a.videoMode) === common_types_1.ProductVideoModeTypes.INSPIRATIONAL; });
        if (inspirationalVideos) {
            yield (0, effects_1.put)(ProductActions_1.productActions.setProductContentVideos(inspirationalVideos));
        }
    }
    catch (_a) { }
}
function* productContentVideosSaga() {
    yield (0, effects_1.all)([loadProductContentVideosSaga()]);
}
exports.default = productContentVideosSaga;
