"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSingleOption = exports.isAllPaymentOptionsValid = exports.getPaymentGroupTitle = exports.getGroupName = exports.getPaymentGroupImageUrl = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const getPaymentGroupImageUrl = (groupName, prefix) => {
    switch (groupName) {
        case 'kbc_online':
            return `${prefix}KBC_logo.svg`;
        case 'cbc_online':
            return `${prefix}CBC_logo.svg`;
        case 'maestro':
            return `${prefix}Maestro_logo.svg`;
        case 'paypal':
            return `${prefix}Paypal_logo.svg`;
        case 'ideal':
            return `${prefix}iDeal_logo.svg`;
        case 'bancontact_mistercash':
            return `${prefix}Bancontact_Mister_Cash_logo.svg`;
        case 'visa':
            return `${prefix}VISA_logo.svg`;
        case 'mastercard':
            return `${prefix}mastercard.svg`;
        case 'carte-bancaire-and-mastercard':
            return `${prefix}carte_bancaire_mastercard.png`;
        case 'American_Express_choice':
            return `${prefix}amex_logo.svg`;
        case 'ing_homepay':
            return `${prefix}ING_logo.svg`;
        case 'belfius-direct-net':
            return `${prefix}belfius_logo.svg`;
        case 'vvv':
            return `${prefix}vvv.png`;
        case 'visa_delta':
            return `${prefix}VISA_delta_logo.svg`;
        case 'carte_bleue':
            return `${prefix}carte_bleue.svg`;
        case 'rembours':
            return `${prefix}rembours.svg`;
        case 'afterpay':
            return `${prefix}afterpay.png`;
        case 'closebrothers':
            return `${prefix}closebrothers.png`;
        case 'klarna':
            return `${prefix}klarna.png`;
        case 'sofort':
            return `${prefix}klarna_sofort.png`;
        case 'directtransfer':
            return `${prefix}sepa_lastschrift_logo.png`;
        case 'applepay':
            return `${prefix}applepay.png`;
        case 'googlepay':
            return `${prefix}googlepay.png`;
        case 'ecocheque_monizze':
            return `${prefix}ecocheque_monizze_logo.png`;
        case 'ecocheque_pluxee':
            return `${prefix}ecocheque_pluxee_logo.png`;
        case 'ecocheque_edenred':
            return `${prefix}ecocheque_edenred_logo.png`;
        default:
            return '';
    }
};
exports.getPaymentGroupImageUrl = getPaymentGroupImageUrl;
const getGroupName = (group) => {
    switch (group.name) {
        case common_types_1.PaymentGroupName.CARTE_BLEUE:
            return 'carteblue';
        default:
            return group.name.toLowerCase().replace(/\s/g, '');
    }
};
exports.getGroupName = getGroupName;
const getPaymentGroupTitle = (group, t) => {
    switch (group.name) {
        case common_types_1.PaymentGroupName.ECOCHEQUE:
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.ecocheque`), ' ', (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, inline: true, children: ["(", group.paymentOptions
                                .map(option => {
                                var _a;
                                return t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.ecocheque.payment_option.${(_a = option.paymentMethodCode) === null || _a === void 0 ? void 0 : _a.toLowerCase()}`);
                            })
                                .join(', '), ")"] })] }));
        default:
            return t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.${(0, exports.getGroupName)(group)}`);
    }
};
exports.getPaymentGroupTitle = getPaymentGroupTitle;
const isAllPaymentOptionsValid = (group) => group.paymentOptions.every(option => { var _a; return !((_a = option.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.length) && !option.exceededMaxBasketCost; });
exports.isAllPaymentOptionsValid = isAllPaymentOptionsValid;
const isSingleOption = (group) => group.paymentOptions.length === 1;
exports.isSingleOption = isSingleOption;
