"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentOverviewForm = exports.initialValuesContentOverviewForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
exports.initialValuesContentOverviewForm = {
    contentSearch: '',
};
const SearchChangeHandler = ({ initialValues, contentSearch, onChange, }) => {
    const prevContent = (0, cotopaxi_1.usePrevious)(contentSearch);
    (0, react_1.useEffect)(() => {
        if (prevContent !== contentSearch && initialValues.contentSearch !== contentSearch) {
            onChange(contentSearch);
        }
    }, [contentSearch, onChange, prevContent, initialValues]);
    return null;
};
const ContentOverviewForm = ({ initialValues, onSubmit, onSearchChange, handleResetValues, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { onSubmit: onSubmit, initialValues: initialValues, name: "content-overview-form", children: ({ errors, touched, values, setValues }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SearchChangeHandler, { initialValues: initialValues, onChange: onSearchChange, contentSearch: values.contentSearch }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { name: "contentSearch", placeholder: t('flyout.search.placeholder'), state: errors.contentSearch && touched.contentSearch && cotopaxi_1.InputState.ERROR, adornmentEnd: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [values.contentSearch && !errors.contentSearch && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, onClick: () => {
                                    setValues(exports.initialValuesContentOverviewForm);
                                    handleResetValues();
                                }, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_SUBTLE, icon: { name: cotopaxi_1.IconName.CLOSE, size: cotopaxi_1.IconSize.MEDIUM } })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, variant: values.contentSearch && !errors.contentSearch ? cotopaxi_1.ButtonVariant.BASE : cotopaxi_1.ButtonVariant.TRANSPARENT, icon: {
                                    name: cotopaxi_1.IconName.MAGNIFY,
                                    size: cotopaxi_1.IconSize.MEDIUM,
                                    color: !values.contentSearch || errors.contentSearch ? cotopaxi_1.IconColor.SUBTLE : null,
                                } })] }), Component: cotopaxi_1.Input })] })) }));
};
exports.ContentOverviewForm = ContentOverviewForm;
