"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const SingleProductTileReducer_1 = require("../../single-product-tile/reducers/SingleProductTileReducer");
const loadSingleProductTileDynamicModule = () => ({
    id: common_types_1.AEMScenes.SINGLE_PRODUCT_TILE,
    reducerMap: {
        singleProductsList: SingleProductTileReducer_1.loadSingleProductTileReducer,
    },
});
exports.default = loadSingleProductTileDynamicModule;
