"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupGeneric = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const BuyForm_1 = require("../../forms/BuyForm");
const PaymentGroupGeneric = ({ buttonState = undefined, onSubmit, errorMessage, }) => {
    const handleSubmit = (values) => {
        onSubmit && onSubmit(values);
    };
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: handleSubmit, buttonState: buttonState, checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: errorMessage }));
};
exports.PaymentGroupGeneric = PaymentGroupGeneric;
