"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFields = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_hook_form_1 = require("react-hook-form");
const AddressFields_1 = require("../address-fields/AddressFields");
const CountrySelector_1 = require("./CountrySelector");
const andes_react_1 = require("@yonderland/andes-react");
const AddressFields = ({ country, onCountryChange }) => {
    const invoiceEnabled = (0, react_hook_form_1.useWatch)({ name: 'invoice' });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 2, gapX: 4, gapY: 6, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 2, md: 1 }, children: (0, jsx_runtime_1.jsx)(CountrySelector_1.CountrySelector, { onCountryChange: onCountryChange }) }) }), (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { prefix: "address.", country: country, isRequired: invoiceEnabled, showAddressCard: true })] }));
};
exports.AddressFields = AddressFields;
