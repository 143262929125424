"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductPreview_1 = require("../../components/product-detail/product-preview/ProductPreview");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ProductPreviewSelectors_1 = require("../../redux/productPreview/selectors/ProductPreviewSelectors");
const ProductPreviewActions_creators_1 = require("../../redux/productPreview/actions/ProductPreviewActions.creators");
const ProductPreviewContainer = ({ alt, dynamicSavingsInformation, previewInformation, productDefaults, selectedColorId, videos, showImageTouchZoomHint, ourPlanet, newProduct, onImageTouchZoom, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const productImages = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const savingsPercentage = (dynamicSavingsInformation === null || dynamicSavingsInformation === void 0 ? void 0 : dynamicSavingsInformation.productDiscountPercentage)
        ? {
            savingsPercentage: dynamicSavingsInformation.productDiscountPercentage,
        }
        : null;
    return ((0, jsx_runtime_1.jsx)(ProductPreview_1.default, Object.assign({ genderCode: productDefaults.genderFeatureId, images: previewInformation.images, priceOverlay: previewInformation.priceOverlay, productImages: productImages, productCode: productDefaults.productCode, showGenderIcon: featureToggles.product_gender_icon, showOnlineExclusiveLabel: featureToggles.product_online_exclusive && previewInformation.webOnly, alt: alt, videos: videos, selectedColorId: selectedColorId, showImageTouchZoomHint: showImageTouchZoomHint, showSustainabilityLabel: ourPlanet, showNewProductLabel: featureToggles.product_new_label && newProduct, onImageTouchZoom: onImageTouchZoom }, savingsPercentage)));
};
const makeMapStateToProps = () => {
    const getPreviewInformation = (0, ProductSelector_1.makeGetPreviewInformation)();
    const getProductImageAlt = (0, ProductSelector_1.makeGetProductImageAlt)();
    const getProductDefaults = (0, ProductSelector_1.makeGetProductDefaults)();
    const getDynamicSavingsInformation = (0, ProductSelector_1.makeGetDynamicSavingsInformation)();
    const getSelectedColorId = (0, ProductSelector_1.makeGetSelectedColorId)();
    const getShowImageTouchZoomHint = (0, ProductPreviewSelectors_1.makeGetShowImageTouchZoomHint)();
    const getProductOurPlanet = (0, ProductSelector_1.makeGetProductOurPlanet)();
    const getNewProduct = (0, ProductSelector_1.makeGetNewProduct)();
    return (state) => ({
        previewInformation: getPreviewInformation(state),
        productDefaults: getProductDefaults(state),
        alt: getProductImageAlt(state),
        dynamicSavingsInformation: getDynamicSavingsInformation(state),
        selectedColorId: getSelectedColorId(state),
        showImageTouchZoomHint: getShowImageTouchZoomHint(state),
        ourPlanet: getProductOurPlanet(state),
        newProduct: getNewProduct(state),
    });
};
const mapDispatchToProps = {
    onImageTouchZoom: ProductPreviewActions_creators_1.productPreviewActions.disableImageTouchZoomHint,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(ProductPreviewContainer);
