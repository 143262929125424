"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureToggle = void 0;
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const useFeatureToggle = (flagName) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    return !!(0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration)[flagName];
};
exports.useFeatureToggle = useFeatureToggle;
