"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileOldPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const HtmlInclude_1 = require("../../../common/html-include/HtmlInclude");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const FilterItem_1 = require("../FilterItem");
const FilterButtonListItemPopover_1 = require("../FilterButtonListItemPopover");
const FilterWizard_1 = require("../filter-wizard/FilterWizard");
const InStoreFilter_1 = require("../in-store-filter/InStoreFilter");
const FiltersEmptyResult_1 = require("../../empty-result/FiltersEmptyResult");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const common_utils_1 = require("@as-react/common-utils");
const FiltersMobileOldPopover = ({ anchorEl, handlePopoverClose, filters, activeFilters, itemCount, onFilterItemChange, removeAllActiveFilters, fredhopperGlossary, filterWizardConfig, urlFilters, isContentTileLister, storeOptions, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.general]);
    const [selectedFilter, setSelectedFilter] = (0, react_1.useState)(null);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const getFilterRangeLabel = (activeFilter) => {
        const { filterPrefix, filterSuffix, rangeMin, rangeMax } = activeFilter;
        let filterRangeLabel = '';
        if (filterPrefix) {
            filterRangeLabel = `${filterPrefix}${rangeMin} - ${filterPrefix}${rangeMax}`;
        }
        else if (filterSuffix) {
            filterRangeLabel = `${rangeMin}${filterSuffix} - ${rangeMax}${filterSuffix}`;
        }
        else {
            filterRangeLabel = `${rangeMin} - ${rangeMax}`;
        }
        return filterRangeLabel;
    };
    const getSelectedFilterOptions = (filter, currentFilters) => {
        if (!currentFilters || !currentFilters[filter.filterOn]) {
            return null;
        }
        return filter.type === common_types_1.RANGE_FILTER
            ? getFilterRangeLabel(filter)
            : filter.values
                .filter(({ active }) => active)
                .map(({ name }) => (0, common_utils_1.capitalizeOnly)(name.split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[0]))
                .join(', ');
    };
    const closePopoverAndResetSelectedFilter = () => {
        setSelectedFilter(null);
        handlePopoverClose();
    };
    const buttonList = (0, react_1.useMemo)(() => filters.map(filter => {
        var _a, _b;
        const glossary = fredhopperGlossary &&
            fredhopperGlossary[`fh_${filter.filterOn}`] &&
            ((_a = fredhopperGlossary[`fh_${filter.filterOn}`]) === null || _a === void 0 ? void 0 : _a.description);
        const selectedFiltersLabel = getSelectedFilterOptions(filter, activeFilters);
        const showFilterWizard = !!urlFilters && ((_b = filterWizardConfig === null || filterWizardConfig === void 0 ? void 0 : filterWizardConfig.filters) === null || _b === void 0 ? void 0 : _b.includes(filter.filterOn)) && filter.type !== common_types_1.RANGE_FILTER;
        return {
            key: `${filter.filterOn}-${filter.name.replace(/ /g, '')}`,
            noBubble: true,
            fake: true,
            iconRight: {
                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                size: cotopaxi_1.IconSize.MAJOR,
                color: cotopaxi_1.IconColor.ACTION,
            },
            text: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: filter.name }), glossary && !showFilterWizard && ((0, jsx_runtime_1.jsx)(FilterButtonListItemPopover_1.FilterButtonListItemPopover, { description: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: glossary }), title: filter.name })), showFilterWizard && ((0, jsx_runtime_1.jsx)(FilterWizard_1.FilterWizard, { filterPopover: true, filter: filter, filterWizardPath: filterWizardConfig.path, initialItemsCount: itemCount, updateFilters: onFilterItemChange, urlFilters: urlFilters }))] }), selectedFiltersLabel && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.SUBTLE, size: cotopaxi_1.TextSize.SMALL, children: selectedFiltersLabel }))] })),
            onClick: () => {
                setSelectedFilter(filter.name);
            },
        };
    }), [filters, activeFilters, itemCount, urlFilters]);
    const filtersContent = (0, react_1.useMemo)(() => filters.map((filter) => ({
        name: filter.name,
        type: filter.type,
        element: (0, jsx_runtime_1.jsx)(FilterItem_1.FilterItem, { filter: filter, onFilterItemChange: onFilterItemChange }),
    })), [filters, activeFilters]);
    const activeFilter = filtersContent.find(filter => filter.name === selectedFilter);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { noPadding: true, mobileOnly: isClient, onClose: handlePopoverClose, anchorEl: anchorEl, headerTheme: cotopaxi_1.PopoverHeaderTheme.SECONDARY, title: activeFilter ? activeFilter.name : t('product:product.filter'), hasScrollLock: true, leftElement: activeFilter ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: () => setSelectedFilter(null), iconLeft: {
                name: cotopaxi_1.IconName.ARROW_LEFT,
                size: cotopaxi_1.IconSize.MAJOR,
            }, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, inline: true, children: (0, common_utils_1.capitalizeOnly)(t('product:product.filters')) }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                name: cotopaxi_1.IconName.CLOSE,
                size: cotopaxi_1.IconSize.MAJOR,
            } })), rightElement: !activeFilter && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: removeAllActiveFilters, noBubble: true, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('product:product.lister.filter.clear') }) })), footerElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: closePopoverAndResetSelectedFilter, text: t(isContentTileLister
                ? 'general:content.overview.show.articles'
                : 'product:product.lister.filter.show.product', {
                count: itemCount,
            }) }), children: activeFilter ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { fullHeight: true, mobile: { spacing: { paddingTop: 4, paddingBottom: 3, paddingLeft: 2, paddingRight: 2 } }, children: activeFilter.element })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!isContentTileLister && (storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.showInStoreFilter) && ((0, jsx_runtime_1.jsx)(InStoreFilter_1.default, { inFilterPopover: true, inStoreFilter: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.inStoreFilter, activeStoreId: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.activeStoreId, handleOpenPopup: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.openInStoreFilterPopup, updateInStoreFilter: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.updateInStoreFilter, updateStoreId: storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.updateStoreId })), itemCount <= 0 ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            marginLeft: 2,
                            marginRight: 2,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(FiltersEmptyResult_1.FiltersEmptyResult, { removeAllActiveFilters: removeAllActiveFilters, mainWebShop: mainWebShop, showChangeFilterStep: false }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            marginTop: (storeOptions === null || storeOptions === void 0 ? void 0 : storeOptions.showInStoreFilter) ? 0 : 1,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { stateLabel: true, buttons: buttonList, size: cotopaxi_1.ButtonListItemSize.MEDIUM }) }))] })) }));
};
exports.FiltersMobileOldPopover = FiltersMobileOldPopover;
