"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
class SessionAPI {
    constructor() {
        this.infoRequest = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/me`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new SessionAPI();
