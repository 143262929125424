"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const usePageShow_1 = require("../hooks/usePageShow");
const WishListMenuItemContainer = ({ link }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const itemCount = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishlistCounter)());
    const hasItems = !!itemCount && itemCount > 0;
    (0, usePageShow_1.usePageShow)(() => {
        dispatch(WishListActions_creators_1.wishListActions.getWishListProducts());
    });
    (0, react_1.useEffect)(() => {
        dispatch(WishListActions_creators_1.wishListActions.getWishListProducts());
    }, []);
    const handleButtonClick = () => {
        window.location.href = link;
    };
    return ((0, jsx_runtime_1.jsx)("div", { "data-analytics-bannerid": "header-wishlist", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleButtonClick, variant: cotopaxi_1.ButtonVariant.NAVIGATION, badge: hasItems ? itemCount : undefined, dataQA: "wishlist", dataBadgeQA: "wishlist_counter", icon: {
                name: cotopaxi_1.IconName.HEART,
                size: cotopaxi_1.IconSize.MAJOR,
            }, title: "wishlist icon" }) }));
};
exports.default = WishListMenuItemContainer;
