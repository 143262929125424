"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cookieConsentReducer = exports.initialCookieConsentState = void 0;
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialCookieConsentState = {
    cookieConsentSettingAvailable: common_types_1.CookieConsentSettingState.OLD_COMPONENT,
};
const cookieConsentReducer = (initialData) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialCookieConsentState), initialData);
    return (state = initialReducerState, action) => {
        switch (action.type) {
            case ActionEnums_1.CookieConsentActionTypes.CLEAR:
                return {
                    cookieConsentSettingAvailable: common_types_1.CookieConsentSettingState.NO_CONSENTS_AVAILABLE,
                };
            case ActionEnums_1.CookieConsentActionTypes.SET:
                return Object.assign(Object.assign({}, state), {
                    cookieConsentSettingAvailable: action.payload
                        ? common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE
                        : common_types_1.CookieConsentSettingState.NO_CONSENTS_AVAILABLE,
                    cookieConsentSettings: action.payload,
                });
            default:
                return state;
        }
    };
};
exports.cookieConsentReducer = cookieConsentReducer;
