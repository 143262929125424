"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsInViewport = void 0;
const react_1 = require("react");
const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = (0, react_1.useState)(false);
    const observer = (0, react_1.useMemo)(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);
    (0, react_1.useEffect)(() => {
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return isIntersecting;
};
exports.useIsInViewport = useIsInViewport;
