"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamicModuleLoaderRouterMiddleWare = exports.buildRouterMiddleware = void 0;
const connected_react_router_1 = require("connected-react-router");
const buildRouterMiddleware = (history) => {
    return (0, connected_react_router_1.routerMiddleware)(history);
};
exports.buildRouterMiddleware = buildRouterMiddleware;
const createDynamicModuleLoaderRouterMiddleWare = (history) => ({
    id: 'startup-connected-react-router',
    middlewares: [(0, exports.buildRouterMiddleware)(history)],
});
exports.createDynamicModuleLoaderRouterMiddleWare = createDynamicModuleLoaderRouterMiddleWare;
