"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Monetate_module_1 = require("./modules/Monetate.module");
const WishList_module_1 = require("./modules/WishList.module");
const Compare_module_1 = require("./modules/Compare.module");
const CookieConsent_module_1 = require("./modules/CookieConsent.module");
const Product_module_1 = require("./modules/Product.module");
const loadProductGroupPdpComparisonMonetateRootModule = () => {
    return {
        hasRouter: false,
        modules: [
            (0, Monetate_module_1.default)(),
            (0, CookieConsent_module_1.default)(),
            (0, Compare_module_1.default)(),
            (0, WishList_module_1.default)(),
            (0, Product_module_1.default)(),
        ],
    };
};
exports.default = loadProductGroupPdpComparisonMonetateRootModule;
