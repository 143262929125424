"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const customerEndpoints = {
    customer: {
        social: {
            add: 'api/customer/social/insert',
        },
    },
};
class CustomerSocialMediaApi {
    addSocialMediaConnection(apiPrefix, params, data) {
        return new Promise((resolve, reject) => {
            const axiosConfig = {
                method: 'POST',
                url: `${apiPrefix}${customerEndpoints.customer.social.add}`,
                params,
                withCredentials: true,
                data,
            };
            axios_1.default.request(axiosConfig).then(responseFromBackEnd => resolve(responseFromBackEnd.data), error => reject(error));
        });
    }
}
exports.default = new CustomerSocialMediaApi();
