"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductWizardIntro = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductWizardIntro = ({ loggedIn, userFirstName, title, subTitle, buttonLabel, startProductWizard, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const headingSize = isMobile ? cotopaxi_1.HeadingSize.XS : cotopaxi_1.HeadingSize.L;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
            spacing: {
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 2,
            },
        }, tablet: {
            spacing: {
                paddingTop: 0,
                paddingLeft: 4,
                paddingRight: 4,
                paddingBottom: 4,
            },
        }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 9 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: headingSize, children: `${t('productWizard.greeting')}${loggedIn ? ` ${userFirstName}` : ''}!` }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: headingSize, children: title }), subTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: subTitle }) }))] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_AROUND, gutter: cotopaxi_1.GroupGutter.NONE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, fill: isMobile, text: buttonLabel, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, onClick: startProductWizard }) })] }));
};
exports.ProductWizardIntro = ProductWizardIntro;
