"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logger_1 = require("@as-react/logger");
class SessionStorageUtil {
    static getJsonItem(key) {
        if (!this._isClientSide()) {
            return null;
        }
        if (sessionStorage.getItem(key) !== null) {
            try {
                return JSON.parse(sessionStorage.getItem(key));
            }
            catch (error) {
                return null;
            }
        }
        else {
            return null;
        }
    }
    static getItem(key) {
        if (!this._isClientSide()) {
            return null;
        }
        try {
            return sessionStorage.getItem(key);
        }
        catch (error) {
            return null;
        }
    }
    static setItem(key, data) {
        try {
            sessionStorage.setItem(key, JSON.stringify(data));
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
    static removeItem(key) {
        try {
            sessionStorage.removeItem(key);
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
    static _isClientSide() {
        return typeof window !== 'undefined';
    }
}
exports.default = SessionStorageUtil;
