"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeralLocaleConfigEnUs = exports.numeralLocaleConfigEnGb = void 0;
exports.numeralLocaleConfigEnGb = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (num) => {
        /* tslint:disable:no-bitwise */
        const b = num % 10;
        return ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        /* tslint:enable:no-bitwise */
    },
    currency: {
        symbol: '£',
    },
};
exports.numeralLocaleConfigEnUs = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (num) => {
        /* tslint:disable:no-bitwise */
        const b = num % 10;
        return ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        /* tslint:enable:no-bitwise */
    },
    currency: {
        symbol: '$',
    },
};
