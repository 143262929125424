"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SingleProductTile_module_1 = require("./modules/SingleProductTile.module");
const WishList_module_1 = require("./modules/WishList.module");
const Basket_module_1 = require("./modules/Basket.module");
const Compare_module_1 = require("./modules/Compare.module");
const DeliveryPromise_module_1 = require("./modules/DeliveryPromise.module");
const loadSingleProductTileModule = () => ({
    hasRouter: false,
    modules: [
        (0, WishList_module_1.default)(),
        (0, SingleProductTile_module_1.default)(),
        (0, Basket_module_1.default)(),
        (0, DeliveryPromise_module_1.default)(),
        (0, Compare_module_1.default)(),
    ],
});
exports.default = loadSingleProductTileModule;
