"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRequestGroups = exports.IMAGE_PREFIX = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PaymentGroupUtil_1 = require("../../../util/PaymentGroupUtil");
const PaymentRequestGroupGenericContainer_1 = require("../../../containers/buy/payment-request/PaymentRequestGroupGenericContainer");
const PaymentRequestGroupCreditCardContainer_1 = require("../../../containers/buy/payment-request/PaymentRequestGroupCreditCardContainer");
const PaymentRequestGroupBancontactContainer_1 = require("../../../containers/buy/payment-request/PaymentRequestGroupBancontactContainer");
exports.IMAGE_PREFIX = '/content/dam/general/payment_logos/';
const webImagesList = (group) => [...new Set(group.paymentOptions.map(item => item.webImage))];
const isSingleOption = (group) => group.paymentOptions.length === 1;
const getGroupName = (group) => group.name.toLowerCase().replace(/\s/g, '');
const isAllPaymentOptionsValid = (group) => group.paymentOptions.every(option => { var _a; return !((_a = option.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.length) && !option.exceededMaxBasketCost; });
const PaymentRequestGroups = ({ paymentGroups, currency, changePaymentOption, chosenPaymentOption, lockPaymentOptions, onPaymentGroupsInit, paymentRequestId, saferpayReady, totalPrice, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const getSelectedPaymentGroup = () => {
        let selectedPaymentGroup;
        if (chosenPaymentOption === null || chosenPaymentOption === void 0 ? void 0 : chosenPaymentOption.id) {
            selectedPaymentGroup = paymentGroups.find(group => group.paymentOptions.find(option => option.paymentOptionId === chosenPaymentOption.id));
        }
        return selectedPaymentGroup;
    };
    const [selectedPaymentGroupName, setSelectedPaymentGroupName] = (0, react_1.useState)(((_a = getSelectedPaymentGroup()) === null || _a === void 0 ? void 0 : _a.name) || null);
    const handleChange = (option, group) => {
        changePaymentOption(option, group);
        setSelectedPaymentGroupName(group.name);
    };
    const getPaymentGroupContent = (group) => {
        if (group.name !== selectedPaymentGroupName) {
            return null;
        }
        switch (selectedPaymentGroupName) {
            case common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY:
                return ((0, jsx_runtime_1.jsx)(PaymentRequestGroupBancontactContainer_1.PaymentRequestGroupBancontactContainer, { paymentRequestId: paymentRequestId, saferpayReady: saferpayReady, totalPrice: totalPrice }));
            case common_types_1.PaymentGroupName.CREDITCARD:
                return ((0, jsx_runtime_1.jsx)(PaymentRequestGroupCreditCardContainer_1.PaymentRequestGroupCreditCardContainer, { paymentRequestId: paymentRequestId, saferpayReady: saferpayReady, group: group }));
            case common_types_1.PaymentGroupName.IDEAL:
            case common_types_1.PaymentGroupName.PAYPAL:
            case common_types_1.PaymentGroupName.APPLEPAY_SAFERPAY:
            case common_types_1.PaymentGroupName.GOOGLEPAY_SAFERPAY:
                return ((0, jsx_runtime_1.jsx)(PaymentRequestGroupGenericContainer_1.PaymentRequestGroupGenericContainer, { paymentRequestId: paymentRequestId, selectedPaymentOption: chosenPaymentOption }));
            // Klarna and ecocheques not supported
            default:
                return null;
        }
    };
    const getCaveat = (group) => {
        var _a;
        const isAllOptionsValid = isAllPaymentOptionsValid(group);
        if (!isAllOptionsValid && isSingleOption(group)) {
            const firstPaymentOption = group.paymentOptions[0];
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(_a = firstPaymentOption.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.map(error => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.${error}`, {
                            currency,
                            minAmount: firstPaymentOption.minBasketCost,
                            maxAmount: firstPaymentOption.maxBasketCost,
                        }) }, error))), firstPaymentOption.exceededWarningBasketCost && !firstPaymentOption.exceededMaxBasketCost && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.exceededWarningBasketCost`) }))] }));
        }
        return undefined;
    };
    (0, react_1.useEffect)(() => {
        var _a;
        onPaymentGroupsInit((_a = chosenPaymentOption === null || chosenPaymentOption === void 0 ? void 0 : chosenPaymentOption.name) !== null && _a !== void 0 ? _a : '', getSelectedPaymentGroup());
    }, []);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList, { open: selectedPaymentGroupName ? [selectedPaymentGroupName] : undefined, dataQA: "payment_form", locked: lockPaymentOptions, children: paymentGroups.map(group => ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList.Item, { contentWithoutSpacing: true, id: `${group.name.replace(/\s+/g, '')}_${group.sortOrder}`, disabled: !isAllPaymentOptionsValid(group), title: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.${getGroupName(group)}`), icon: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.NONE, children: webImagesList(group).map(image => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { size: cotopaxi_1.ImageSize.CHECK_LIST_ITEM_DEFAULT, src: (0, PaymentGroupUtil_1.getPaymentGroupImageUrl)(image, exports.IMAGE_PREFIX) }, image))) }), radio: {
                id: group.name.replace(/\s+/g, ''),
                name: 'payment-items',
                value: group.name,
            }, onChange: option => handleChange(option, group), caveat: getCaveat(group), children: selectedPaymentGroupName && getPaymentGroupContent(group) }, group.name.replace(/\s+/g, '')))) }));
};
exports.PaymentRequestGroups = PaymentRequestGroups;
