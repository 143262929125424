"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedFilterContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const AppliedFilterRow_1 = require("../../components/lister/applied-filters/AppliedFilterRow");
const FilterActions_creators_1 = require("../../redux/filter/actions/FilterActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const redux_1 = require("redux");
const _AppliedFilterContainer = ({ flattenedActiveFilters, toggleFilter, removeAllFilters, }) => ((0, jsx_runtime_1.jsx)(AppliedFilterRow_1.AppliedFilterRow, { flattenedActiveFilters: flattenedActiveFilters, onToggleFilter: toggleFilter, onRemoveAllFilters: removeAllFilters }));
const makeMapStateToProps = () => {
    const GetFlattendActiveFiltersState = (0, ListerSelector_1.makeGetFlattenedActiveFiltersState)();
    const mapStateToProps = (state) => ({
        flattenedActiveFilters: GetFlattendActiveFiltersState(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch) => (0, redux_1.bindActionCreators)({
    toggleFilter: FilterActions_creators_1.filterActions.updateFilters,
    removeAllFilters: FilterActions_creators_1.filterActions.removeAllFilters,
}, dispatch);
const composer = (0, redux_1.compose)((0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps));
exports.AppliedFilterContainer = composer(_AppliedFilterContainer);
