"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductComparisonReducer = exports.initialProductComparisonState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const WishListReducer_1 = require("../../wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../compare/reducers/CompareReducer");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialProductComparisonState = [];
const ProductComparisonReducer = (state = exports.initialProductComparisonState, action) => {
    switch (action.type) {
        case ActionEnums_1.ProductComparisonActionTypes.FETCH:
            return action.payload;
        case ActionEnums_1.ProductComparisonActionTypes.COMPARE_ITEM_SELECT_SIZE:
            const { productId, colourId, skuId } = action.payload;
            const newItems = [...state];
            const itemIndex = state.findIndex(item => item.productId === productId && item.colourId === colourId);
            newItems[itemIndex] = Object.assign(Object.assign({}, state[itemIndex]), { selectedSku: skuId });
            return newItems;
        case ActionEnums_1.ProductComparisonActionTypes.REMOVE:
            return [...state.slice(0, action.payload.index), ...state.slice(action.payload.index + 1)];
        case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
            return (0, CompareReducer_1.toggleComparedStateForItems)(state, action.payload);
        case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
            return (0, WishListReducer_1.initializeWishListProducts)(state, action);
        case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, true);
        case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
            return (0, WishListReducer_1.updateWishListProducts)(state, action, false);
        case ActionEnums_1.ProductComparisonActionTypes.SET_DELIVERY_PROMISE:
            return (0, ProductUtil_1.setDeliveryPromiseToProducts)(state, action.payload);
        default:
            return state;
    }
};
exports.ProductComparisonReducer = ProductComparisonReducer;
