"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_utils_1 = require("@as-react/common-utils");
class DeliveryPromiseAPI {
    constructor() {
        this.getDeliveryPromise = (servicesEndpointUrl, skuId, productId, authenticationConfig) => {
            return new Promise((resolve, reject) => {
                const axiosConfig = {
                    url: `${servicesEndpointUrl}api/aem/product/deliverypromise/pdp/v3/${authenticationConfig.market}/${authenticationConfig.fictiveWebShop}/${productId}`,
                    method: 'GET',
                    params: {
                        mainWebshop: authenticationConfig.mainWebshop,
                        anaLang: authenticationConfig.anaLang,
                        language: authenticationConfig.locale,
                        skuId,
                    },
                };
                axios_1.default.request(axiosConfig).then(response => {
                    if (!(0, common_utils_1.isNullOrUndefined)(response.data) && !(0, common_utils_1.isNullOrUndefined)(response.data.deliveryPromises)) {
                        resolve(response.data);
                    }
                    reject();
                }, reject);
            });
        };
        this.getDeliveryPromiseExtended = (servicesEndpointUrl, authenticationConfig, productId, skuId, selectedStoreId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let url = `${servicesEndpointUrl}api/aem/product/deliverypromise/pdp/extended/${authenticationConfig.market}/${authenticationConfig.mainWebshop}/${productId}`;
            if (skuId) {
                url += `/${authenticationConfig.locale}/${skuId}`;
                if (selectedStoreId) {
                    url += `/${selectedStoreId}`;
                }
            }
            const result = yield axios_1.default.get(url);
            return result.data;
        });
        this.getBulkDeliveryPromise = (data_1, apiEndpoint_1, _a) => tslib_1.__awaiter(this, [data_1, apiEndpoint_1, _a], void 0, function* (data, apiEndpoint, { market, fictiveWebShop, mainWebshop, anaLang }) {
            const axiosConfig = {
                data,
                params: {
                    mainWebshop,
                    language: anaLang,
                },
                method: 'POST',
                url: `${apiEndpoint}api/aem/product/deliverypromise/pdp/bulk/v3/${market}/${fictiveWebShop}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getBundleDeliveryPromise = (apiEndpoint, defaultRequestParams, businessUnitId, products) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: {
                    products,
                    businessUnitId,
                    market: defaultRequestParams.market,
                    mainWebShopId: defaultRequestParams.shopId,
                    fictiveShopId: defaultRequestParams.shopId,
                    language: defaultRequestParams.language,
                    type: 'WEB',
                },
                method: 'POST',
                url: `${apiEndpoint}api/deliverypromise/basket`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new DeliveryPromiseAPI();
