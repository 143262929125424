"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const InStoreFilter_1 = require("../../components/lister/filters/in-store-filter/InStoreFilter");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const cotopaxi_1 = require("@as/cotopaxi");
const ZendeskWidgetUtil_1 = require("../../util/ZendeskWidgetUtil");
const InStoreFilterContainer = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const inStoreFilter = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const isClient = (0, cotopaxi_1.useIsClient)();
    const openInStoreFilterPopup = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(inStoreFilter ? AnalyticsActions_creators_1.analyticsActions.storeFilterModified() : AnalyticsActions_creators_1.analyticsActions.storeFilterStarted());
        ZendeskWidgetUtil_1.default.updateZIndex(1);
    };
    const handleUpdateStoreId = (storeId) => {
        dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
        if (!activeStoreId) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterApplied());
        }
    };
    if (!isClient)
        return null;
    return ((0, jsx_runtime_1.jsx)(InStoreFilter_1.default, { inStoreFilter: inStoreFilter, activeStoreId: activeStoreId, handleOpenPopup: openInStoreFilterPopup, updateStoreId: handleUpdateStoreId, updateInStoreFilter: store => dispatch(ListerActions_creators_1.listerActions.updateInStoreFilter(store)) }));
};
exports.default = InStoreFilterContainer;
