"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logger_1 = require("@as-react/logger");
class LocalStorageUtil {
    static getJsonItem(key) {
        if (!this._isClientSide()) {
            return null;
        }
        if (localStorage.getItem(key) !== null) {
            try {
                return JSON.parse(localStorage.getItem(key));
            }
            catch (error) {
                return null;
            }
        }
        else {
            return null;
        }
    }
    static getItem(key) {
        if (!this._isClientSide()) {
            return null;
        }
        try {
            return localStorage.getItem(key);
        }
        catch (error) {
            return null;
        }
    }
    static setItem(key, data) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
    static removeItem(key) {
        try {
            localStorage.removeItem(key);
        }
        catch (error) {
            logger_1.Logger.logError(error);
        }
    }
    static setItemWithExpiry(key, data, ttl) {
        const item = {
            value: data,
            expiry: new Date().getTime() + ttl,
        };
        LocalStorageUtil.setItem(key, item);
    }
    static getItemWithExpiry(key) {
        const item = LocalStorageUtil.getJsonItem(key);
        if (!item) {
            return null;
        }
        if (!item.expiry || new Date().getTime() > item.expiry) {
            LocalStorageUtil.removeItem(key);
            return null;
        }
        return item.value;
    }
    static _isClientSide() {
        return typeof window !== 'undefined';
    }
}
exports.default = LocalStorageUtil;
