"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPDeliveryPromise = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const DeliveryPromiseUtil_1 = require("../../../util/DeliveryPromiseUtil");
const DeliveryPromiseMessage_1 = require("../delivery-promise/DeliveryPromiseMessage");
const tooltipOptionsOverride = {
    placement: 'top',
    popperOptions: {
        positionFixed: true,
    },
};
const MiniPDPDeliveryPromise = ({ aemConfiguration, deliveryPromise, deliveryPromiseExplanations, boxProps = {
    mobile: { spacing: { marginBottom: 0.5 } },
    tablet: { spacing: { marginBottom: 1 } },
}, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const deliveryPromises = deliveryPromise ? deliveryPromise.deliveryPromises : [];
    const deliveryPromiseExplanation = (0, DeliveryPromiseUtil_1.getDeliveryExplanation)(deliveryPromise, deliveryPromiseExplanations);
    const setTranslationKey = (name) => name ? `pdp.deliverypromise.${name.toLowerCase()}.txt` : 'pdp.deliverypromise.home_delivery.txt';
    const setTooltipElement = () => {
        if (deliveryPromiseExplanation && deliveryPromises.length > 0) {
            deliveryPromises.map((_, index) => (0, common_utils_1.setTooltip)({
                element: `#mini-pdp-delivery-item-explanation-${index}`,
                content: deliveryPromiseExplanation,
                showCloseButton: false,
                optionsOverride: tooltipOptionsOverride,
            }));
        }
    };
    (0, react_1.useEffect)(() => {
        setTooltipElement();
    }, [deliveryPromiseExplanation]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({}, boxProps, { dataAttributes: { 'data-qa': 'delivery_promise' }, children: [featureToggles.delivery_promise_select_size_label && !deliveryPromises.length && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, emphasized: true, inline: true, children: [t('pdp.selectsize.prefix.txt'), ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('pdp.selectsize.txt') })] })), deliveryPromises.map((deliveryPromiseItem, i) => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: deliveryPromises.length > 1 && i === 0 ? { spacing: { marginBottom: 1 } } : undefined, children: [featureToggles.delivery_promise_standard_delivery_label && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, isUppercase: true, color: cotopaxi_1.TextColor.POSITIVE, children: t(setTranslationKey(deliveryPromiseItem.name)) })), (0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: deliveryPromiseItem }), deliveryPromiseExplanation && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { id: `mini-pdp-delivery-item-explanation-${i}`, size: cotopaxi_1.IconSize.MINOR, color: cotopaxi_1.IconColor.POSITIVE, name: cotopaxi_1.IconName.INFORMATION_OUTLINE, dynamicContent: deliveryPromiseExplanation, inline: true })] }))] }, deliveryPromiseItem.name)))] })));
};
exports.MiniPDPDeliveryPromise = MiniPDPDeliveryPromise;
