"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const FilterItem_1 = require("./FilterItem");
const CategoryHierarchyTree_1 = require("../category-hierarchy-tree/CategoryHierarchyTree");
const FilterContent = ({ desktop, filters, itemCount, onFilterItemChange, fredhopperGlossary, filterWizardConfig, urlFilters, }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(urlFilters === null || urlFilters === void 0 ? void 0 : urlFilters.defaultFilter) && (0, jsx_runtime_1.jsx)(CategoryHierarchyTree_1.CategoryHierarchyTree, {}), filters.map((filter, index) => ((0, jsx_runtime_1.jsx)(FilterItem_1.FilterItem, { desktop: desktop, filter: filter, totalItemsCount: itemCount, filterWizardConfig: filterWizardConfig, onFilterItemChange: onFilterItemChange, isCollapsedByDefault: index > 2, tooltip: fredhopperGlossary &&
                fredhopperGlossary[`fh_${filter.filterOn}`] &&
                fredhopperGlossary[`fh_${filter.filterOn}`].description, urlFilters: urlFilters }, `${filter.filterOn}-${filter.name.replace(/ /g, '')}`)))] }));
exports.FilterContent = FilterContent;
