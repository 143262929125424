"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wishListReducer = exports.updateWishListProducts = exports.initializeWishListProducts = exports.initialWishListReducerState = void 0;
const common_utils_1 = require("@as-react/common-utils");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const connected_react_router_1 = require("connected-react-router");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ProductUtil_1 = require("../../../util/ProductUtil");
exports.initialWishListReducerState = {
    products: [],
};
const initWishListProducts = (state, action) => [...action.payload];
const addWishListProduct = (state, action) => [
    ...state.products,
    action.payload,
];
const removeWishListProduct = (state, action) => {
    const productToSet = action.payload;
    return state.products.filter((wishListProduct) => {
        return !(wishListProduct.colorId === productToSet.colorId && wishListProduct.productId === productToSet.productId);
    });
};
const removeWishListProductItem = (state, action) => {
    const productToSet = action.payload;
    return state.items.filter((product) => {
        return !(product.colourId === productToSet.colorId && product.productId === productToSet.productId);
    });
};
const getWishlistItemsByProduct = (wishlistProducts) => wishlistProducts.reduce((acc, wishlistProduct) => {
    const wishlistItem = acc.find(resItem => resItem.productId === wishlistProduct.productId);
    if (wishlistItem) {
        wishlistItem.colors.push(wishlistProduct.colorId);
    }
    else {
        acc.push({ productId: wishlistProduct.productId, colors: [wishlistProduct.colorId] });
    }
    return acc;
}, []);
const initializeWishListProducts = (state, action) => {
    const wishlistItemsByProduct = getWishlistItemsByProduct(action.payload);
    if ((0, common_utils_1.isNullOrUndefined)(state)) {
        state = [];
    }
    return state.map((productItem) => {
        var _a, _b;
        if (!(0, common_utils_1.isNullOrUndefined)(productItem.productId)) {
            const wishListProduct = wishlistItemsByProduct.find(wishListItem => productItem.productId === wishListItem.productId);
            const productItemActiveWishListColors = productItem.colours.find((productColor) => productColor.inWishList);
            if (!(0, common_utils_1.isNullOrUndefined)(wishListProduct)) {
                return Object.assign(Object.assign({}, productItem), { colours: (_a = productItem.colours) === null || _a === void 0 ? void 0 : _a.map((productColor) => wishListProduct.colors.includes(productColor.colourId)
                        ? Object.assign(Object.assign({}, productColor), { inWishList: true }) : productColor) });
            }
            else if (!(0, common_utils_1.isNullOrUndefined)(productItemActiveWishListColors)) {
                return Object.assign(Object.assign({}, productItem), { colours: (_b = productItem.colours) === null || _b === void 0 ? void 0 : _b.map((productColor) => {
                        if (!(0, common_utils_1.isNullOrUndefined)(productColor.inWishList)) {
                            return Object.assign(Object.assign({}, productColor), { inWishList: false });
                        }
                        return productColor;
                    }) });
            }
        }
        return productItem;
    });
};
exports.initializeWishListProducts = initializeWishListProducts;
const updateWishListProducts = (state, action, inWishList) => {
    return state.map((productItem) => {
        if (productItem.productId === action.payload.productId) {
            return Object.assign(Object.assign({}, productItem), { colours: productItem.colours.map((productColor) => {
                    if (productColor.colourId === action.payload.colorId) {
                        return Object.assign(Object.assign({}, productColor), { inWishList: inWishList });
                    }
                    return productColor;
                }) });
        }
        return productItem;
    });
};
exports.updateWishListProducts = updateWishListProducts;
const wishListReducer = (state = exports.initialWishListReducerState, action) => {
    var _a, _b, _c, _d, _e, _f;
    switch (action.type) {
        case connected_react_router_1.LOCATION_CHANGE:
            return ((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.wishList) &&
                ((_f = (_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.wishList.itemCount) === state.itemCount
                ? Object.assign({}, action.payload.location.state.wishList) : state;
        case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
            return Object.assign(Object.assign({}, state), { products: initWishListProducts(state, action) });
        case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
            return Object.assign(Object.assign({}, state), { products: addWishListProduct(state, action) });
        case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
            let newState = Object.assign(Object.assign({}, state), { products: removeWishListProduct(state, action) });
            if (state.items) {
                newState = Object.assign(Object.assign({}, newState), { items: removeWishListProductItem(state, action) });
            }
            return newState;
        case ActionEnums_1.WishListActionTypes.LISTER_SET_PRODUCTS:
            return Object.assign(Object.assign({}, state), { items: action.payload.items, itemCount: action.payload.itemCount, totalPages: action.payload.totalPages });
        case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
            return Object.assign(Object.assign({}, state), { items: (0, CompareReducer_1.toggleComparedStateForItems)(state.items, action.payload) });
        case ActionEnums_1.WishListActionTypes.LISTER_SET_FETCH_STATUS:
            return Object.assign(Object.assign({}, state), { fetchStatus: action.payload });
        case ActionEnums_1.WishListActionTypes.LISTER_SET_DELIVERY_PROMISE:
            return Object.assign(Object.assign({}, state), { items: (0, ProductUtil_1.setDeliveryPromiseToProducts)(state.items, action.payload) });
        default:
            return state;
    }
};
exports.wishListReducer = wishListReducer;
