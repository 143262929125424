"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPMultiProductTile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_utils_1 = require("@as-react/common-utils");
const WishListUtil_1 = require("../../../util/WishListUtil");
const ProductTileLook_1 = require("../../product-tile/look/ProductTileLook");
const MiniPDPMultiProductTile = ({ handleSetError, index, isShopLanding, onProductChange, onWishListToggle, productData: { product, isSelected = false, sku, colorId }, sizeSelectError, tileIdSuffix = '', wishlistProducts, }) => {
    const selectedColor = (0, common_utils_1.findColor)(product.colours, colorId);
    const [selectedSize, setSelectedSize] = (0, react_1.useState)((0, common_utils_1.findSizeBySku)(selectedColor.sizes, sku));
    const isSelectedColorOutOfStock = selectedColor.availability <= 0;
    const isProductOutOfStock = product.colours.filter(color => color.availability > 0).length === 0;
    const resetSelectError = () => {
        sizeSelectError && handleSetError(false, index);
    };
    const setProductColor = (id) => {
        const activeColor = (0, common_utils_1.findColor)(product.colours, id);
        const activeSku = (0, common_utils_1.determineSelectedSku)(activeColor);
        resetSelectError();
        setSelectedSize((0, common_utils_1.findSizeBySku)(activeColor.sizes, activeSku));
        onProductChange({
            product,
            colorId: activeColor.colourId,
            sku: activeSku,
            quantity: 1,
            isSelected,
        });
    };
    const handleSizeChange = (activeSku) => {
        resetSelectError();
        const currentSize = (0, common_utils_1.findSizeBySku)(selectedColor.sizes, activeSku);
        setSelectedSize(currentSize);
        onProductChange({
            product,
            colorId: selectedColor.colourId,
            sku: activeSku,
            quantity: 1,
            isSelected: (currentSize === null || currentSize === void 0 ? void 0 : currentSize.storeSourceExcluded) ? false : isSelected,
        });
    };
    const toggleWishList = () => onWishListToggle({
        productId: product.productId,
        productCode: product.productCode,
        colorId: selectedColor.colourId,
        sku,
        colorCode: selectedColor.colourCode,
        color: selectedColor.description,
    });
    const handleProductSelect = (e) => {
        resetSelectError();
        onProductChange({ product, colorId: selectedColor.colourId, sku, quantity: 1, isSelected: e.target.checked });
    };
    (0, react_1.useEffect)(() => {
        if (isSelectedColorOutOfStock && isSelected) {
            onProductChange({ product, colorId, sku, isSelected: false, quantity: 1 });
        }
    }, [isSelectedColorOutOfStock]);
    return ((0, jsx_runtime_1.jsx)(ProductTileLook_1.ProductTileLook, { product: product, selectedSize: selectedSize, selectedColor: selectedColor, isColourOutOfStock: isProductOutOfStock ? false : isSelectedColorOutOfStock, isProductOutOfStock: isProductOutOfStock, inWishList: (0, WishListUtil_1.isProductInWishList)(wishlistProducts, product.productId, selectedColor.colourId), isSelected: isSelected, onWishListClick: isShopLanding ? undefined : toggleWishList, onProductSelect: handleProductSelect, analyticsAreaName: "pdp-link-shop-the-look", idSuffix: `${tileIdSuffix}-${index}`, sizeSelectError: sizeSelectError, onSizeChange: handleSizeChange, onColorChange: setProductColor }));
};
exports.MiniPDPMultiProductTile = MiniPDPMultiProductTile;
