"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileItemHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const HtmlInclude_1 = require("../../../common/html-include/HtmlInclude");
const ListerSelector_1 = require("../../../../redux/lister/selectors/ListerSelector");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const FilterWizard_1 = require("../filter-wizard/FilterWizard");
const FilterActions_creators_1 = require("../../../../redux/filter/actions/FilterActions.creators");
const FilterUtil_1 = require("../../../../util/FilterUtil");
const FiltersMobileItemHeader = ({ filter, tooltip, urlFilters, itemCount, filterWizardConfig, }) => {
    var _a;
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const activeFilters = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetActiveFiltersState)());
    const { filterOn, name, type } = filter;
    const dispatch = (0, react_redux_1.useDispatch)();
    const selectedFiltersAmount = (0, FilterUtil_1.getSelectedFilterAmount)(filter, activeFilters);
    const showFilterWizard = !!urlFilters && ((_a = filterWizardConfig === null || filterWizardConfig === void 0 ? void 0 : filterWizardConfig.filters) === null || _a === void 0 ? void 0 : _a.includes(filterOn)) && type !== common_types_1.RANGE_FILTER;
    const onFilterItemChange = (toggledFilter) => dispatch(FilterActions_creators_1.filterActions.updateFilters(toggledFilter));
    const onPopoverOpen = (event) => {
        event.stopPropagation();
        handlePopoverOpen(event);
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", overflow: "hidden", flex: { grow: 1 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { overflow: "hidden", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { wrap: cotopaxi_1.GroupWrap.NOWRAP, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, lineClamp: 1, align: cotopaxi_1.TextAlign.LEFT, children: name }), showFilterWizard && ((0, jsx_runtime_1.jsx)(FilterWizard_1.FilterWizard, { filterPopover: true, filter: filter, filterWizardPath: filterWizardConfig.path, initialItemsCount: itemCount, updateFilters: onFilterItemChange, urlFilters: urlFilters })), Boolean(tooltip) && !showFilterWizard && ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { padding: "1", children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { onClick: onPopoverOpen, name: cotopaxi_1.IconName.INFORMATION_OUTLINE, color: cotopaxi_1.IconColor.ACTION, size: cotopaxi_1.IconSize.MINOR }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handlePopoverClose, size: cotopaxi_1.PopoverSize.MEDIUM, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                                                name: cotopaxi_1.IconName.CLOSE,
                                                color: cotopaxi_1.IconColor.ACTION,
                                                size: cotopaxi_1.IconSize.MAJOR,
                                            } }), type: cotopaxi_1.PopoverType.FILTERS, position: cotopaxi_1.PopoverPosition.RIGHT, title: name, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: tooltip }) })] }))] }) }), selectedFiltersAmount && (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { color: cotopaxi_1.BadgeColor.SECONDARY, count: selectedFiltersAmount })] }) }));
};
exports.FiltersMobileItemHeader = FiltersMobileItemHeader;
