"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItemContentColorFilter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const FILTER_ITEM_COLOR_DIMENSION = 32;
const FilterItemContentColorFilter = ({ filter, onFilterItemChange, desktop, isCollapsedByDefault, }) => {
    const toggleFilterValue = (e) => {
        const filteredItem = filter.values.find((value) => value.filterKey === e.target.value);
        if (filteredItem) {
            onFilterItemChange({
                filterOn: filter.filterOn,
                filterOnName: filter.name,
                filteredItems: [
                    {
                        filterKeyName: filteredItem.name,
                        filterKey: filteredItem.filterKey,
                        filterOn: filter.filterOn,
                        filterOnName: filter.name,
                        filterType: common_types_1.MULTISELECT_FILTER,
                    },
                ],
                filterType: common_types_1.MULTISELECT_FILTER,
                filterDisplayHint: filter.displayHint,
                filterContentType: filter.filterContentType,
            });
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, parent: true, children: filter.values.map((value, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 6, gutter: false, gutterBottom: true, gutterTop: index > 1, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ImageCheckbox, { id: desktop ? `color_${filter.name}_${value.filterKey}-desktop` : `color_${filter.name}_${value.filterKey}`, name: `color_${filter.name}_${value.filterKey}`, imageUrl: `/content/dam/general/lister/color_filters/${value.filterKey}.svg`, value: value.filterKey, label: (0, common_utils_1.capitalize)(value.name), itemCount: value.itemCount, checked: value.active, onChange: toggleFilterValue, imageLazyLoad: isCollapsedByDefault === false, imageHeight: FILTER_ITEM_COLOR_DIMENSION, imageWidth: FILTER_ITEM_COLOR_DIMENSION, size: cotopaxi_1.TextSize.SMALL }) }, `color_${filter.name}_${value.filterKey}`))) }));
};
exports.FilterItemContentColorFilter = FilterItemContentColorFilter;
