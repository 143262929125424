"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareReducer = exports.toggleComparedStateForItems = exports.initialCompareState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const common_types_1 = require("@as-react/common-types");
exports.initialCompareState = {
    initialized: false,
    collapsed: true,
    showError: false,
    items: [],
};
const filterRemovedCompareProducts = (items = [], removedCompareProduct) => items.filter((compareProduct) => {
    const equalColorId = compareProduct.color === removedCompareProduct.color;
    const equalProductCode = compareProduct.productCode === removedCompareProduct.productCode;
    return !(equalColorId && equalProductCode);
});
const toggleComparedStateForItems = (items = [], compareItems) => items.map((item) => {
    if ((0, common_types_1.isBanner)(item)) {
        return item;
    }
    const compareItem = compareItems.find(cItem => cItem.productCode === item.productCode);
    return Object.assign(Object.assign({}, item), { colours: item.colours.map((productColor) => (Object.assign(Object.assign({}, productColor), { isCompareProduct: !!compareItem &&
                !!compareItems.find(cItem => cItem.productCode === item.productCode && cItem.colorId === productColor.colourId) }))) });
});
exports.toggleComparedStateForItems = toggleComparedStateForItems;
const compareReducer = (state = exports.initialCompareState, action) => {
    switch (action.type) {
        case ActionEnums_1.CompareActionTypes.COMPARE_INIT:
            return Object.assign(Object.assign({}, state), { initialized: true, items: [...action.payload] });
        case ActionEnums_1.CompareActionTypes.COMPARE_ADD:
            return Object.assign(Object.assign({}, state), { items: [...state.items, action.payload.product] });
        case ActionEnums_1.CompareActionTypes.COMPARE_TOGGLE_LIMIT_REACHED:
            return Object.assign(Object.assign({}, state), { showError: action.payload });
        case ActionEnums_1.CompareActionTypes.COMPARE_REMOVE:
            return Object.assign(Object.assign({}, state), { items: filterRemovedCompareProducts(state.items, action.payload.product) });
        case ActionEnums_1.CompareActionTypes.COMPARE_POPUP_CLOSE:
            return Object.assign(Object.assign({}, state), { collapsed: true });
        case ActionEnums_1.CompareActionTypes.COMPARE_POPUP_OPEN:
            return Object.assign(Object.assign({}, state), { collapsed: false });
        case ActionEnums_1.CompareActionTypes.COMPARE_POPUP_TOGGLE:
            return Object.assign(Object.assign({}, state), { collapsed: !state.collapsed });
        default:
            return state;
    }
};
exports.compareReducer = compareReducer;
