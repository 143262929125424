"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productGroupReducer = exports.loadProductGroupReducer = exports.initialProductGroupState = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ProductGroupUtil_1 = require("../../../util/ProductGroupUtil");
exports.initialProductGroupState = {};
const loadProductGroupReducer = (initialData = null) => {
    if (initialData === null || initialData === void 0 ? void 0 : initialData.productGroupId) {
        exports.initialProductGroupState[initialData.productGroupId] = initialData.products;
    }
    return (state = exports.initialProductGroupState, action) => {
        (0, ProductGroupUtil_1.normalizeProductGroupState)(state, exports.initialProductGroupState);
        switch (action.type) {
            case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
                const newProductGroupItemsList = {};
                Object.keys(state).map(key => {
                    newProductGroupItemsList[key] = (0, CompareReducer_1.toggleComparedStateForItems)(state[key], action.payload);
                });
                return Object.assign(Object.assign({}, state), newProductGroupItemsList);
            case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
                const newProductGroupItemsForUpdateWishList = {};
                Object.keys(state).map(key => {
                    newProductGroupItemsForUpdateWishList[key] = (0, WishListReducer_1.initializeWishListProducts)(state[key], action);
                });
                return Object.assign(Object.assign({}, state), newProductGroupItemsForUpdateWishList);
            case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
                const newProductGroupItemsForAddWishList = {};
                Object.keys(state).map(key => {
                    newProductGroupItemsForAddWishList[key] = (0, WishListReducer_1.updateWishListProducts)(state[key], action, true);
                });
                return Object.assign(Object.assign({}, state), newProductGroupItemsForAddWishList);
            case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
                const newProductGroupItemsForRemoveWishList = {};
                Object.keys(state).map(key => {
                    newProductGroupItemsForRemoveWishList[key] = (0, WishListReducer_1.updateWishListProducts)(state[key], action, false);
                });
                return Object.assign(Object.assign({}, state), newProductGroupItemsForRemoveWishList);
            case ActionEnums_1.ProductGroupActionTypes.SET_DELIVERY_PROMISE:
                return Object.assign(Object.assign({}, state), { [action.payload.productGroupId]: (0, ProductUtil_1.setDeliveryPromiseToProducts)(state[action.payload.productGroupId], action.payload) });
            case ActionEnums_1.ProductGroupActionTypes.SET_PRODUCT_GROUPS:
                return Object.assign(Object.assign({}, state), action.payload);
            default:
                return state;
        }
    };
};
exports.loadProductGroupReducer = loadProductGroupReducer;
exports.productGroupReducer = (0, exports.loadProductGroupReducer)();
