"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FiltersMobileButton = ({ onClick, isContentTileLister, text, }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: onClick, iconRight: !isContentTileLister
        ? {
            name: cotopaxi_1.IconName.FILTER_NEW,
            size: cotopaxi_1.IconSize.MEDIUM,
        }
        : undefined, variant: isContentTileLister ? cotopaxi_1.ButtonVariant.BASE : cotopaxi_1.ButtonVariant.OUTLINED, noBubble: true, fill: true, text: text }));
exports.FiltersMobileButton = FiltersMobileButton;
