"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBoxPropsFromAEMPaddings = void 0;
const common_utils_1 = require("@as-react/common-utils");
const defaultPaddingsMap = {
    none: 0,
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    gutter: 2,
};
const getBoxPropsFromAEMPaddings = (paddings = {}, map = defaultPaddingsMap) => {
    const paddingDirections = Object.keys(paddings);
    return Object.values(paddings).reduce((prevValue, curValue, index) => {
        if (!curValue) {
            return prevValue;
        }
        const direction = paddingDirections[index];
        return {
            mobile: Object.assign(Object.assign({}, prevValue.mobile), { spacing: Object.assign(Object.assign({}, prevValue.mobile.spacing), { [`padding${(0, common_utils_1.capitalize)(direction)}`]: map[curValue] }) }),
        };
    }, { mobile: { spacing: {} } });
};
exports.getBoxPropsFromAEMPaddings = getBoxPropsFromAEMPaddings;
