"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_utils_1 = require("@as-react/common-utils");
class SizeGuideAPI {
    fetchSizeGuide(baseUrl, defaultRequestParams, sizeGuideRequestParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${baseUrl}api/aem/product/sizeguide`,
                params: Object.assign(Object.assign({}, defaultRequestParams), sizeGuideRequestParams),
            };
            const sizeGuideEndpointRequest = yield axios_1.default.request(axiosConfig);
            return sizeGuideEndpointRequest.data;
        });
    }
    fetchSizeGuideHTML(sizeGuideUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let sizeGuideHTMLEndpoint = `${sizeGuideUrl}.partial.html`;
            if (common_utils_1.Environment.isDevelopment()) {
                sizeGuideHTMLEndpoint = `/content/platform-blueprints/asadventure${sizeGuideHTMLEndpoint}`;
            }
            const sizeGuideHTMLRequest = yield axios_1.default.get(sizeGuideHTMLEndpoint);
            return sizeGuideHTMLRequest.data;
        });
    }
}
exports.default = new SizeGuideAPI();
