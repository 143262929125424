"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const AemComponent_1 = require("../common/aem-component/AemComponent");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const CollapsibleListLD_1 = require("./collapsible-list-ld/CollapsibleListLD");
const CollapsibleList = props => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleCollapsibleItemClick = (id, isOpen) => {
        const items = props[':items'];
        const itemId = Object.entries(items).find(([_, values]) => values.id === id);
        if (isOpen && itemId) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.faqQuestionExpand(items[itemId[0]].title));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [props.structuredData === 'faq' && (0, jsx_runtime_1.jsx)(CollapsibleListLD_1.CollapsibleListLD, Object.assign({}, props)), (0, jsx_runtime_1.jsx)(cotopaxi_1.Accordion, { onItemClick: handleCollapsibleItemClick, children: props[':itemsOrder'].map((collapsibleListItem) => {
                    const listItem = props[':items'][collapsibleListItem];
                    if (!Object.values(common_types_1.AemComponentType).includes(listItem[':type'])) {
                        return null;
                    }
                    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: listItem.id, title: listItem.title, withHover: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                },
                            }, children: listItem[':itemsOrder'].map((collapsibleListItemComponent) => ((0, jsx_runtime_1.jsx)(AemComponent_1.AemComponent, Object.assign({}, listItem[':items'][collapsibleListItemComponent]), `${listItem.id}-${collapsibleListItemComponent}`))) }) }, listItem.id));
                }) })] }));
};
exports.default = CollapsibleList;
