"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetProductFulfillmentBySize = exports.makeGetSizeGuideData = exports.makeGetProductCareData = exports.makeGetLinkedProducts = exports.makeGetProductReviewsLang = exports.makeGetProductContentVideos = exports.makeGetProductVideos = exports.makeGetExpertReviews = exports.makeGetProductReviews = exports.makeGetSelectedProductTab = exports.makeGetProductFeatures = exports.makeGetProductTechnicalCategories = exports.makeGetNewProduct = exports.makeGetProductOurPlanet = exports.makeGetProductSustainable = exports.makeGetProductReview = exports.makeGetProductDescription = exports.makeGetProductBrand = exports.makeGetSizeRecommendation = exports.makeGetIsCompareProduct = exports.makeGetProductReviewLandingInformation = exports.makeGetProductCompareInformation = exports.makeGetFirstImageForSelectedProductColor = exports.makeGetProductImageAlt = exports.makeGetPPCForSelectedColorWithId = exports.makeGetProductVariants = exports.makeGetSizeGuideInformation = exports.makeGetBrandId = exports.makeGetSkuAvailability = exports.makeGetSizeCombinationNotExist = exports.makeGetDeliveryPromisesExtended = exports.makeGetDeliveryPromises = exports.makeGetPreviewInformation = exports.makeGetColors = exports.makeGetAvailableColors = exports.makeGetSpecialtyType = exports.makeGetProduct = exports.makeGetSelectedSku = exports.makeGetSelectedColorId = exports.makeGetProductToggles = exports.makeGetProductDefaults = exports.makeGetDynamicSavingsInformation = exports.makeGetPriceInformation = exports.makeGetProductSizes = exports.makeGetProductInfo = exports.makeGetProductFulfillment = exports.makeGetProductState = exports.makeGetDetailInformation = exports.getProductCode = exports.getSelectedColorId = void 0;
exports.makeGetNotifyMeError = exports.makeGetIsSelectedSizeSoldOut = exports.makeGetBuyingGroup = exports.makeGetMainWebshopId = exports.makeGetSelectedBundleProducts = exports.makeGetSelectedBundleAlternativeTab = exports.makeGetBundleSelectedSlots = exports.makeGetBundleParentProduct = exports.makeGetBundle = exports.makeGetProductCategory = exports.makeGetAlternativeProducts = void 0;
const reselect_1 = require("reselect");
const common_utils_1 = require("@as-react/common-utils");
const ProductCustomReSelector_1 = require("./ProductCustomReSelector");
const SelectorUtil_1 = require("./SelectorUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const getProduct = (state) => state.product;
const getCompare = (state) => state.compare;
const getSelectedColorId = (state) => state.product.selectedColorId;
exports.getSelectedColorId = getSelectedColorId;
const getProductCode = (state) => state.product.productCode;
exports.getProductCode = getProductCode;
const getSelectedSku = (state) => state.product.selectedSku;
const getProductBrand = (state) => state.product.brand;
const getProductTranslatedProperties = (state) => state.product.productTranslatedProperties;
const getProductColors = (state) => state.product.productColorVariations;
const getProductSizes = (state) => {
    return state.product.productColorVariations.find(productColorVariation => productColorVariation.colorId === state.product.selectedColorId).sizes;
};
const makeGetDetailInformation = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)([getProductBrand, getProductTranslatedProperties], (productBrand, productTranslatedProperties) => ({
    brand: productBrand,
    description: productTranslatedProperties.description,
    title: productTranslatedProperties.title,
    information: productTranslatedProperties.information,
    features: productTranslatedProperties.features,
    technicalDescription: productTranslatedProperties.technicalDescription,
}));
exports.makeGetDetailInformation = makeGetDetailInformation;
const makeGetProductState = () => (0, reselect_1.createSelector)(getProduct, (product) => ({
    available: Boolean(product.active && product.visible),
    outOfStock: !!product.productColorVariations &&
        !product.productColorVariations.find((color) => Number(color.availability) > 0),
    selectedColorOutOfstock: !!product.productColorVariations &&
        !product.productColorVariations.find((color) => color.colorId === product.selectedColorId &&
            Number(color.availability) > 0 &&
            color.sizes.filter(size => size.active && Number(size.availability) > 0).length > 0),
}));
exports.makeGetProductState = makeGetProductState;
const makeGetProductFulfillment = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, product => product.fulfillment);
exports.makeGetProductFulfillment = makeGetProductFulfillment;
const makeGetProductInfo = () => (0, reselect_1.createSelector)(getProduct, (product) => {
    if ((0, common_utils_1.isNullOrUndefined)(product))
        return undefined;
    return {
        defaultColor: product.defaultColor,
        productCode: product.productCode,
        productId: product.productId,
        selectedColorId: product.selectedColorId,
    };
});
exports.makeGetProductInfo = makeGetProductInfo;
const makeGetProductSizes = () => (0, ProductCustomReSelector_1.productSelectorCreatorByActiveSizedForSelectedColor)(getProductSizes, (productSizes) => productSizes.filter((size) => size.active || size.historicAvailable));
exports.makeGetProductSizes = makeGetProductSizes;
const makeGetPriceInformation = () => (0, ProductCustomReSelector_1.productSelectorCreatorBySelectedColorId)(getProduct, (product) => {
    const colorVariation = SelectorUtil_1.default.getSelectedColor(product);
    return SelectorUtil_1.default.getPriceInformation(product, colorVariation);
});
exports.makeGetPriceInformation = makeGetPriceInformation;
const makeGetDynamicSavingsInformation = () => (0, ProductCustomReSelector_1.productSelectorCreatorByColorAndSku)(getProduct, (product) => {
    const colorVariation = SelectorUtil_1.default.getSelectedColor(product);
    return SelectorUtil_1.default.getDynamicSavingsInformation(product, colorVariation);
});
exports.makeGetDynamicSavingsInformation = makeGetDynamicSavingsInformation;
const makeGetProductDefaults = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => ({
    defaultColor: product.defaultColor,
    genderFeatureId: product.genderFeatureId,
    productCode: product.productCode,
    productId: product.productId,
    unavailableOnline: product.unavailableOnline,
}));
exports.makeGetProductDefaults = makeGetProductDefaults;
const makeGetProductToggles = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => ({
    showQuantity: product.showQuantity,
}));
exports.makeGetProductToggles = makeGetProductToggles;
const makeGetSelectedColorId = () => (0, reselect_1.createSelector)([exports.getSelectedColorId], (colorId) => colorId);
exports.makeGetSelectedColorId = makeGetSelectedColorId;
const makeGetSelectedSku = () => (0, reselect_1.createSelector)(getSelectedSku, (selectedSku) => selectedSku);
exports.makeGetSelectedSku = makeGetSelectedSku;
const makeGetProduct = () => (0, reselect_1.createSelector)(getProduct, (product) => product);
exports.makeGetProduct = makeGetProduct;
const makeGetSpecialtyType = () => (0, reselect_1.createSelector)(getProduct, (product) => { var _a; return (_a = product === null || product === void 0 ? void 0 : product.specialtyType) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
exports.makeGetSpecialtyType = makeGetSpecialtyType;
const makeGetAvailableColors = () => (0, ProductCustomReSelector_1.makeGetAvailableColorsMemoizeSelectorCreator)(getProduct, (product) => SelectorUtil_1.default.getAvailableColors(product));
exports.makeGetAvailableColors = makeGetAvailableColors;
const makeGetColors = () => (0, reselect_1.createSelector)(getProductColors, (productColors) => productColors);
exports.makeGetColors = makeGetColors;
const makeGetPreviewInformation = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => {
    const colorVariation = SelectorUtil_1.default.getSelectedColor(product);
    return {
        images: colorVariation.images,
        webOnly: colorVariation.webOnly,
        priceOverlay: colorVariation.priceOverlay,
    };
});
exports.makeGetPreviewInformation = makeGetPreviewInformation;
const makeGetDeliveryPromises = () => (0, ProductCustomReSelector_1.customSelectedSkuDeliveryPromiseSelectorCreator)(getProduct, (product) => product.deliveryPromise);
exports.makeGetDeliveryPromises = makeGetDeliveryPromises;
const makeGetDeliveryPromisesExtended = () => (0, ProductCustomReSelector_1.customSelectedSkuDeliveryPromiseExtendedSelectorCreator)(getProduct, (product) => product.deliveryPromiseExtended);
exports.makeGetDeliveryPromisesExtended = makeGetDeliveryPromisesExtended;
const makeGetSizeCombinationNotExist = () => (0, ProductCustomReSelector_1.customSelectedSkuDeliveryPromiseSelectorCreator)(getProduct, (product) => product.sizeCombinationNotExist);
exports.makeGetSizeCombinationNotExist = makeGetSizeCombinationNotExist;
const makeGetSkuAvailability = () => (0, ProductCustomReSelector_1.customSelectedSkuSelectorCreator)(getProduct, (product) => {
    const selectedColor = SelectorUtil_1.default.getSelectedColor(product);
    if (selectedColor) {
        const selectedSku = SelectorUtil_1.default.getSelectedSku(product, selectedColor);
        if (selectedSku) {
            return {
                lastPieces: selectedSku.lastPieces,
                exactAvailability: selectedSku.exactAvailability,
                isSoldOut: (0, ProductUtil_1.isSoldOutSize)(selectedSku) && !selectedSku.storeSourceExcluded,
            };
        }
    }
    return {
        lastPieces: false,
        exactAvailability: null,
    };
});
exports.makeGetSkuAvailability = makeGetSkuAvailability;
const makeGetBrandId = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product && product.brandId);
exports.makeGetBrandId = makeGetBrandId;
const makeGetSizeGuideInformation = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => ({
    brandId: product.brandId,
    genderFeatureId: product.genderFeatureId,
    productTypeFeatureId: product.productTypeFeatureId,
}));
exports.makeGetSizeGuideInformation = makeGetSizeGuideInformation;
const makeGetProductVariants = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product && product.productVariants);
exports.makeGetProductVariants = makeGetProductVariants;
const getSelectedColorVariation = (state) => SelectorUtil_1.default.getSelectedColor(state.product);
const getPPCTypeArg = (_, ppcType) => ppcType;
const makeGetPPCForSelectedColorWithId = () => (0, reselect_1.createSelector)([getSelectedColorVariation, getPPCTypeArg], (colorVariation, ppcType) => {
    var _a;
    if ((0, common_utils_1.isNullOrUndefined)(colorVariation))
        return null;
    return (_a = colorVariation.productPlacementCopyPositions) === null || _a === void 0 ? void 0 : _a.filter((ppc) => {
        return ppc.placementPositionId === ppcType;
    });
});
exports.makeGetPPCForSelectedColorWithId = makeGetPPCForSelectedColorWithId;
const makeGetProductImageAlt = () => (0, reselect_1.createSelector)([getProduct, getSelectedColorVariation], (product, colorVariation) => {
    return `${product.brand.description} ${product.productTranslatedProperties.title} ${colorVariation.translations.description}`;
});
exports.makeGetProductImageAlt = makeGetProductImageAlt;
const makeGetFirstImageForSelectedProductColor = () => (0, reselect_1.createSelector)([getSelectedColorVariation], (colorVariation) => {
    return colorVariation.images[0].picture;
});
exports.makeGetFirstImageForSelectedProductColor = makeGetFirstImageForSelectedProductColor;
const makeGetProductCompareInformation = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => {
    const colorVariation = SelectorUtil_1.default.getSelectedColor(product);
    return {
        brand: product.brand.description,
        productId: parseInt(product.productId, 10),
        productCode: product.productCode,
        title: product.productTranslatedProperties && 'title' in product.productTranslatedProperties
            ? product.productTranslatedProperties.title
            : '',
        seoUrl: product.seoUrl,
        colorId: colorVariation.colorId,
        image: colorVariation.images[0].picture,
        prices: colorVariation.prices,
        hasDiscount: colorVariation.hasDiscount,
        discountPercentage: colorVariation.colourDiscountPercentage,
        colorDescription: colorVariation.translations.description,
        sizes: colorVariation.sizes,
        gender: product.gender,
        buyingGroup: product.buyingGroup,
        productQualifier: product.productQualifier,
        brandValue: product.brandQualifier,
    };
});
exports.makeGetProductCompareInformation = makeGetProductCompareInformation;
const makeGetProductReviewLandingInformation = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, product => product);
exports.makeGetProductReviewLandingInformation = makeGetProductReviewLandingInformation;
const makeGetIsCompareProduct = () => (0, reselect_1.createSelector)([getProduct, getCompare], (product, compare) => {
    const foundProduct = compare.items.find((item) => product.productCode === item.productCode && product.selectedColorId === item.color);
    return !!foundProduct;
});
exports.makeGetIsCompareProduct = makeGetIsCompareProduct;
const makeGetSizeRecommendation = () => (0, reselect_1.createSelector)([getProductTranslatedProperties, getSelectedColorVariation], (translatedProperty, colorVariation) => ({
    productBased: translatedProperty
        ? {
            modelSize: translatedProperty.modelSize,
            modelHeight: translatedProperty.modelHeight,
            sizingAdvice: translatedProperty.sizingAdvice,
        }
        : null,
    colorBased: (colorVariation === null || colorVariation === void 0 ? void 0 : colorVariation.translations)
        ? {
            modelSize: colorVariation.translations.modelSize,
            modelHeight: colorVariation.translations.modelHeight,
            sizingAdvice: colorVariation.translations.sizingAdvice,
        }
        : null,
}));
exports.makeGetSizeRecommendation = makeGetSizeRecommendation;
const makeGetProductBrand = () => (0, reselect_1.createSelector)(getProductBrand, (productBrand) => productBrand);
exports.makeGetProductBrand = makeGetProductBrand;
const makeGetProductDescription = () => (0, reselect_1.createSelector)([getProductBrand, getProductTranslatedProperties], (productBrand, productTranslatedProperties) => ({
    brand: productBrand,
    description: productTranslatedProperties.description,
    title: productTranslatedProperties.title,
}));
exports.makeGetProductDescription = makeGetProductDescription;
const makeGetProductReview = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productReview);
exports.makeGetProductReview = makeGetProductReview;
const makeGetProductSustainable = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.sustainable);
exports.makeGetProductSustainable = makeGetProductSustainable;
const makeGetProductOurPlanet = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.ourPlanet);
exports.makeGetProductOurPlanet = makeGetProductOurPlanet;
const makeGetNewProduct = () => (0, reselect_1.createSelector)([getSelectedColorVariation], (colorVariation) => {
    return colorVariation.newProduct;
});
exports.makeGetNewProduct = makeGetNewProduct;
const makeGetProductTechnicalCategories = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productTechnicalCategories);
exports.makeGetProductTechnicalCategories = makeGetProductTechnicalCategories;
const makeGetProductFeatures = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productFeatures);
exports.makeGetProductFeatures = makeGetProductFeatures;
const makeGetSelectedProductTab = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.selectedProductTab);
exports.makeGetSelectedProductTab = makeGetSelectedProductTab;
const makeGetProductReviews = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productReviews);
exports.makeGetProductReviews = makeGetProductReviews;
const makeGetExpertReviews = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, ({ expertReviews, selectedColorId }) => {
    if (!(expertReviews === null || expertReviews === void 0 ? void 0 : expertReviews.length)) {
        return [];
    }
    return expertReviews
        .filter(({ colorId }) => Number(colorId) === selectedColorId || !colorId)
        .sort((a, b) => Number(b.colorId) - Number(a.colorId));
});
exports.makeGetExpertReviews = makeGetExpertReviews;
const makeGetProductVideos = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productVideos);
exports.makeGetProductVideos = makeGetProductVideos;
const makeGetProductContentVideos = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productContentVideos);
exports.makeGetProductContentVideos = makeGetProductContentVideos;
const makeGetProductReviewsLang = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productReviewsLang);
exports.makeGetProductReviewsLang = makeGetProductReviewsLang;
const makeGetLinkedProducts = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.linkedProducts);
exports.makeGetLinkedProducts = makeGetLinkedProducts;
const makeGetProductCareData = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.productCare);
exports.makeGetProductCareData = makeGetProductCareData;
const makeGetSizeGuideData = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => ({
    sizeGuideContent: product.sizeGuideContent || null,
    sizeGuideContentUrl: product.sizeGuideContentUrl || null,
}));
exports.makeGetSizeGuideData = makeGetSizeGuideData;
const makeGetProductFulfillmentBySize = () => (0, reselect_1.createSelector)([getSelectedColorVariation, getSelectedSku], (selectedColor, selectedSku) => {
    if ((0, common_utils_1.isNullOrUndefined)(selectedColor) || (0, common_utils_1.isEmptyObject)(selectedColor) || !selectedColor.sizes)
        return null;
    const selectedSize = selectedColor.sizes.find(size => size.sku === selectedSku);
    return {
        storeSourceExcluded: selectedSize ? selectedSize.storeSourceExcluded : false,
        fulfillmentCopy: selectedSize ? selectedSize.fulfillmentCopy : null,
    };
});
exports.makeGetProductFulfillmentBySize = makeGetProductFulfillmentBySize;
const makeGetAlternativeProducts = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.alternativeProducts);
exports.makeGetAlternativeProducts = makeGetAlternativeProducts;
const makeGetProductCategory = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.category);
exports.makeGetProductCategory = makeGetProductCategory;
const makeGetBundle = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => { var _a; return (_a = product.bundles) === null || _a === void 0 ? void 0 : _a[product.selectedColorId]; });
exports.makeGetBundle = makeGetBundle;
const makeGetBundleParentProduct = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)([getProduct, getSelectedColorVariation, getProductSizes], (product, colorVariation, productSizes) => {
    var _a;
    const bundle = (_a = product.bundles) === null || _a === void 0 ? void 0 : _a[product.selectedColorId];
    const title = product.productTranslatedProperties && 'title' in product.productTranslatedProperties
        ? product.productTranslatedProperties.title
        : '';
    return {
        activeColor: colorVariation,
        brand: product.brand.description,
        bundle,
        imageAlt: `${product.brand.description} ${title} ${colorVariation.translations.description}`,
        priceInformation: SelectorUtil_1.default.getPriceInformation(product, colorVariation),
        dynamicSavingsInformation: SelectorUtil_1.default.getDynamicSavingsInformation(product, colorVariation),
        selectedSku: product.selectedSku,
        seoUrl: product.seoUrl,
        sizes: productSizes.filter((size) => size.active || size.historicAvailable),
        title,
        review: product.productReview,
    };
});
exports.makeGetBundleParentProduct = makeGetBundleParentProduct;
const makeGetBundleSelectedSlots = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => {
    var _a;
    const bundle = (_a = product.bundles) === null || _a === void 0 ? void 0 : _a[product.selectedColorId];
    return bundle === null || bundle === void 0 ? void 0 : bundle.bundleSlots.filter(slot => slot.isSelected).map(slot => `bundle-${bundle.bundleId}-${slot.slotNumber}`);
});
exports.makeGetBundleSelectedSlots = makeGetBundleSelectedSlots;
const makeGetSelectedBundleAlternativeTab = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => { var _a, _b; return (_b = (_a = product.bundles) === null || _a === void 0 ? void 0 : _a[product.selectedColorId]) === null || _b === void 0 ? void 0 : _b.selectedTab; });
exports.makeGetSelectedBundleAlternativeTab = makeGetSelectedBundleAlternativeTab;
const makeGetSelectedBundleProducts = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => {
    var _a;
    const bundle = (_a = product.bundles) === null || _a === void 0 ? void 0 : _a[product.selectedColorId];
    return (bundle === null || bundle === void 0 ? void 0 : bundle.bundleSlots.filter(slot => slot.isSelected).map(slot => slot.displayedProduct)) || [];
});
exports.makeGetSelectedBundleProducts = makeGetSelectedBundleProducts;
const makeGetMainWebshopId = () => (0, ProductCustomReSelector_1.customSelectedColorSelectorCreator)(getProduct, (product) => product.mainWebshopId);
exports.makeGetMainWebshopId = makeGetMainWebshopId;
const makeGetBuyingGroup = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.buyingGroup);
exports.makeGetBuyingGroup = makeGetBuyingGroup;
const makeGetIsSelectedSizeSoldOut = () => (0, reselect_1.createSelector)([getSelectedColorVariation, getSelectedSku], (selectedColor, selectedSku) => {
    const selectedSize = selectedColor.sizes.find(size => size.sku === selectedSku);
    return Boolean(selectedSize && (0, ProductUtil_1.isSoldOutSize)(selectedSize) && !selectedSize.storeSourceExcluded);
});
exports.makeGetIsSelectedSizeSoldOut = makeGetIsSelectedSizeSoldOut;
const makeGetNotifyMeError = () => (0, ProductCustomReSelector_1.productSelectorCreatorByProductCode)(getProduct, (product) => product.notifyMeError);
exports.makeGetNotifyMeError = makeGetNotifyMeError;
