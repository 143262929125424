"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightBoxMainSlider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const Video_1 = require("../../../video/Video");
const VideoUtil_1 = require("../../../../util/VideoUtil");
const useSlidesDisplayState_1 = require("../../../../hooks/useSlidesDisplayState");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const LightBoxMainSlider = ({ activeSlideIndex, alt, mediaCollection, onActiveSlideIndexChange, }) => {
    const [gallerySwiper, setGallerySwiper] = (0, react_1.useState)(null);
    const [activeSlide, setActiveSlide] = (0, react_1.useState)(activeSlideIndex);
    const [isSwiping, setIsSwiping] = (0, react_1.useState)(false);
    const slidesDisplayState = (0, useSlidesDisplayState_1.useSlidesDisplayState)(activeSlideIndex, mediaCollection.length);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const lightBoxMainPaginationTranslations = {
        of: t(`${i18nConstants_1.I18N_NAMESPACES.general}:of`),
    };
    const onGallerySlideChange = function () {
        if (this.activeIndex === 0 || this.activeIndex !== activeSlideIndex) {
            onActiveSlideIndexChange(this.activeIndex);
            setActiveSlide(this.activeIndex);
        }
    };
    const onGallerySlideChangeTransitionEnd = function () {
        setActiveSlide(this.activeIndex);
    };
    const renderSlide = (0, react_1.useCallback)((image, index, _alt) => ((0, jsx_runtime_1.jsx)("div", { children: slidesDisplayState[index] && ((0, jsx_runtime_1.jsx)(cotopaxi_1.LightBoxMainSlide, { smallImagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.detail], largeImagePath: image.sourceSet[common_types_1.ProductImageSizesPortraitEnum.zoom], videoComponent: image.video && ((0, jsx_runtime_1.jsx)(Video_1.Video, { iframe: true, idPostfix: "light_box", url: image.video.productVideoDetail.url, playerType: (0, VideoUtil_1.getVideoPlayerType)(image.video.productVideoDetail.url), stop: index !== activeSlideIndex })), alt: _alt, active: activeSlideIndex === index, isSwiping: isSwiping })) }, `image_${index}`)), [activeSlideIndex, isSwiping, slidesDisplayState]);
    const renderPagination = () => {
        if (!gallerySwiper) {
            return null;
        }
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.LightBoxMainPagination, { swiper: gallerySwiper, translations: lightBoxMainPaginationTranslations, activeSlide: activeSlide }));
    };
    const lightBoxMainSliderCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.LIGHT_BOX_MAIN,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
            color: cotopaxi_1.SliderNavColor.BASE,
        },
        showPagination: true,
        paginationVariant: cotopaxi_1.CarouselPaginationVariant.LIGHT_BOX,
        configurationOverrides: {
            initialSlide: activeSlideIndex,
            shouldSwiperUpdate: true,
            getSwiper: setGallerySwiper,
            spaceBetween: 0,
            centeredSlides: true,
            watchOverflow: true,
            renderPagination,
            on: {
                slideChange: onGallerySlideChange,
                slideChangeTransitionEnd: onGallerySlideChangeTransitionEnd,
                touchStart: () => {
                    setIsSwiping(true);
                },
                touchEnd: () => {
                    setIsSwiping(false);
                },
            },
        },
    };
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.slideTo(activeSlideIndex);
    }, [activeSlideIndex]);
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.update();
    }, [mediaCollection]);
    (0, react_1.useEffect)(() => {
        gallerySwiper === null || gallerySwiper === void 0 ? void 0 : gallerySwiper.update();
    }, [gallerySwiper]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, lightBoxMainSliderCarouselConfig, { dataQA: "light_box_main_slider", children: mediaCollection.map((image, index) => renderSlide(image, index, alt)) })));
};
exports.LightBoxMainSlider = LightBoxMainSlider;
