"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const AuthReducer_1 = require("../../authentication/reducers/AuthReducer");
const AuthSaga_1 = require("../../authentication/sagas/AuthSaga");
const AuthFormReducer_1 = require("../../authForm/reducers/AuthFormReducer");
const AuthFormSaga_1 = require("../../authForm/sagas/AuthFormSaga");
const BasketReducer_1 = require("../../basket/reducers/BasketReducer");
const BasketSagas_1 = require("../../basket/sagas/BasketSagas");
const CompareReducer_1 = require("../../compare/reducers/CompareReducer");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const ProductPreviewReducer_1 = require("../../productPreview/reducers/ProductPreviewReducer");
const ProductPreviewSaga_1 = require("../../productPreview/sagas/ProductPreviewSaga");
const UrlSagas_1 = require("../../url/sagas/UrlSagas");
const WishListReducer_1 = require("../../wishList/reducers/WishListReducer");
const WishListSaga_1 = require("../../wishList/sagas/WishListSaga");
const ProductRecentlyViewedSaga_1 = require("../../productRecentlyViewed/sagas/ProductRecentlyViewedSaga");
const loadProductDetailDynamicModule = () => ({
    id: common_types_1.AEMScenes.PRODUCT_DETAIL_V2,
    reducerMap: {
        authentication: AuthReducer_1.authReducer,
        authForm: AuthFormReducer_1.authFormReducer,
        basket: BasketReducer_1.basketReducer,
        compare: CompareReducer_1.compareReducer,
        productPreview: ProductPreviewReducer_1.productPreviewReducer,
        wishList: WishListReducer_1.wishListReducer,
    },
    sagas: [
        AuthSaga_1.default,
        AuthFormSaga_1.default,
        BasketSagas_1.default,
        CompareSagas_1.default,
        DeliveryPromiseSaga_1.default,
        ProductPreviewSaga_1.default,
        UrlSagas_1.default,
        WishListSaga_1.default,
        ProductRecentlyViewedSaga_1.default,
    ],
});
exports.default = loadProductDetailDynamicModule;
