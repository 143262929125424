"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsFields = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const FormField_1 = require("../../form/FormField");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AddressFieldsNL_1 = require("../../form/address-fields/address-fields-nl/AddressFieldsNL");
const CustomerDetailsFields = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const [{ activeDeliveryOption }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const isProviderPostNL = (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.provider) === common_types_1.Provider.POSTNL;
    const { getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerTitles } = (0, react_query_1.useQuery)(getTitles({ lang }));
    const optionsTitle = customerTitles === null || customerTitles === void 0 ? void 0 : customerTitles.options.map(title => ({ id: title.value, text: title.label }));
    const showMiddleName = defaultRequestParams.market === 'nl';
    const fieldSpan = showMiddleName ? { xs: 12, lg: 4 } : { xs: 6 };
    const fieldRowOffset = showMiddleName ? { lg: 2 } : 2;
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, fullWidth: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: {
                            xs: 8,
                            sm: 6,
                            md: 4,
                        }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "idTitle", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref, onChange, disabled } = _b, options = tslib_1.__rest(_b, ["ref", "onChange", "disabled"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, Object.assign({}, options, { input: {
                                        id: 'idTitle',
                                        label: t('checkout:checkout.customer.title.label'),
                                        placeholder: t('checkout:checkout.customer.title.placeholder'),
                                        required: true,
                                        disabled,
                                        error,
                                    }, onChange: onChange, items: optionsTitle || [] })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: fieldSpan, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "firstName", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "firstName", maxLength: 50, required: true, label: t('checkout:checkout.customer.firstname.label') })));
                            } }) }), showMiddleName && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: fieldSpan, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "middleName", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "middleName", maxLength: 50, optionalText: t('checkout:checkout.customer.optional'), label: t('checkout:checkout.customer.middlename.label') })));
                            } }) })), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: fieldSpan, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "lastName", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "lastName", maxLength: 50, required: true, label: t('checkout:checkout.customer.lastname.label') })));
                            } }) })] }), isProviderPostNL && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(AddressFieldsNL_1.AddressFieldsNL, { prefix: "address." })] }))] }));
};
exports.CustomerDetailsFields = CustomerDetailsFields;
