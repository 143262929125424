"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnPortalGuestLogin = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const ReturnPortalGuestLogin = ({ onSubmit, errorFeedback, loading, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const initialValues = {
        guestEmail: '',
        ordernumber: '',
    };
    const handleValidation = (values) => {
        const errors = {};
        if (!values.guestEmail) {
            errors.guestEmail = t('account:delivery.required');
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(values.guestEmail)) {
            errors.guestEmail = t('navigation:flyout.account.error.email');
        }
        if (!values.ordernumber) {
            errors.ordernumber = t('account:delivery.required');
        }
        return errors;
    };
    const handleError = (errors) => {
        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[for="${errors[0]}"]`));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t('account:account.return.login.guest.title') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { initialValues: initialValues, validate: handleValidation, onSubmit: onSubmit, onSubmitError: handleError, errorFeedback: errorFeedback, name: "return-portal-guest-login", children: ({ errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "guestEmail", size: cotopaxi_1.LabelSize.MINOR, children: t('account:account.flyout.email.placeholder') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.EMAIL, name: "guestEmail", placeholder: t('account:account.flyout.email.placeholder'), required: true, state: errors.guestEmail && touched.guestEmail && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.guestEmail &&
                                        touched.guestEmail && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "guestEmail" })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "ordernumber", size: cotopaxi_1.LabelSize.MINOR, children: t('account:account.return.login.guest.ordernumber') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.TEXT, name: "ordernumber", placeholder: t('account:account.return.login.guest.ordernumber'), required: true, state: errors.ordernumber && touched.ordernumber && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.ordernumber &&
                                        touched.ordernumber && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input, id: "ordernumber" })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, text: t('account:account.return.login.guest.submit'), state: loading ? cotopaxi_1.ButtonState.LOADING : null }) })] })) })] }));
};
exports.ReturnPortalGuestLogin = ReturnPortalGuestLogin;
