"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordResetForm = exports.PasswordResetFormView = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const FormConstants_1 = require("../../constants/FormConstants");
var PasswordResetFormView;
(function (PasswordResetFormView) {
    PasswordResetFormView[PasswordResetFormView["DEFAULT"] = 1] = "DEFAULT";
    PasswordResetFormView[PasswordResetFormView["EMAIL"] = 2] = "EMAIL";
})(PasswordResetFormView || (exports.PasswordResetFormView = PasswordResetFormView = {}));
const initialValues = {
    password: '',
    repeatPassword: '',
    email: '',
};
const PasswordResetForm = ({ buttonState, view = PasswordResetFormView.DEFAULT, errorFeedback, buttonText, buttonFinisherText, onSubmit, validate, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.form]);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [isPasswordVisible, setIsPasswordVisible] = (0, react_1.useState)(false);
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = (0, react_1.useState)(false);
    const togglePasswordVisibility = () => setIsPasswordVisible(prev => !prev);
    const toggleRepeatPasswordVisibility = () => setIsRepeatPasswordVisible(prev => !prev);
    const passwordIndicatorTranslations = {
        label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:password.indicator.strength.label`),
        text: value => t(`${i18nConstants_1.I18N_NAMESPACES.account}:${value}`),
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "password-reset-form", initialValues: initialValues, validate: validate, onSubmit: onSubmit, children: ({ errors, touched, values }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [view === PasswordResetFormView.DEFAULT && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "password", size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.login.password`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "password", name: "password", type: isPasswordVisible ? cotopaxi_1.InputType.TEXT : cotopaxi_1.InputType.PASSWORD, placeholder: t(isDesktop
                                ? `${i18nConstants_1.I18N_NAMESPACES.account}:account.reset.password.placeholder`
                                : `${i18nConstants_1.I18N_NAMESPACES.account}:account.login.password`), required: true, state: errors.password && touched.password && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input, adornmentEnd: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: togglePasswordVisibility, icon: {
                                    name: isPasswordVisible ? cotopaxi_1.IconName.EYE_OUTLINE : cotopaxi_1.IconName.EYE_OFF,
                                    color: cotopaxi_1.IconColor.ACTION,
                                    size: cotopaxi_1.IconSize.MAJOR,
                                } }) }), values.password.length >= FormConstants_1.PASSWORD_MIN_LENGTH && ((0, jsx_runtime_1.jsx)(cotopaxi_1.PasswordIndicator, { password: values.password, translations: passwordIndicatorTranslations })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, tablet: { spacing: { marginTop: 3, marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "repeatPassword", size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.password.retype.label`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "repeatPassword", name: "repeatPassword", type: isRepeatPasswordVisible ? cotopaxi_1.InputType.TEXT : cotopaxi_1.InputType.PASSWORD, placeholder: t(isDesktop
                                        ? `${i18nConstants_1.I18N_NAMESPACES.account}:account.reset.password.retype.placeholder`
                                        : `${i18nConstants_1.I18N_NAMESPACES.account}:account.login.password`), required: true, state: errors.repeatPassword && touched.repeatPassword && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input, adornmentEnd: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: toggleRepeatPasswordVisibility, icon: {
                                            name: isRepeatPasswordVisible ? cotopaxi_1.IconName.EYE_OUTLINE : cotopaxi_1.IconName.EYE_OFF,
                                            color: cotopaxi_1.IconColor.ACTION,
                                            size: cotopaxi_1.IconSize.MAJOR,
                                        } }) })] })] })), view === PasswordResetFormView.EMAIL && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "email", size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.email`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "email", name: "email", type: cotopaxi_1.InputType.EMAIL, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.email`).toLowerCase(), required: true, state: errors.email && touched.email && cotopaxi_1.InputState.ERROR, Component: cotopaxi_1.Input })] })), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, finisher: {
                        icon: cotopaxi_1.IconName.CHECK,
                        text: buttonFinisherText ||
                            t(view === PasswordResetFormView.DEFAULT
                                ? `${i18nConstants_1.I18N_NAMESPACES.account}:account.reset.password.sent`
                                : `${i18nConstants_1.I18N_NAMESPACES.account}:account.reset.email.sent`),
                    }, state: buttonState, type: cotopaxi_1.ButtonType.SUBMIT, text: buttonText ||
                        t(view === PasswordResetFormView.DEFAULT
                            ? `${i18nConstants_1.I18N_NAMESPACES.account}:account.details.updatepassword`
                            : `${i18nConstants_1.I18N_NAMESPACES.form}:form.send`) })] })) }));
};
exports.PasswordResetForm = PasswordResetForm;
