"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
class ProductPlacementCopyAPI {
    fetchPPC(text, root) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let ppcHTMLEndpoint = text;
            if (ppcHTMLEndpoint.indexOf('.partial') === -1) {
                ppcHTMLEndpoint = ppcHTMLEndpoint.replace('.html', '.partial.html');
            }
            ppcHTMLEndpoint = ppcHTMLEndpoint.replace('/', root);
            const sizeGuideHTMLRequest = yield axios_1.default.get(ppcHTMLEndpoint);
            return sizeGuideHTMLRequest.data;
        });
    }
}
exports.default = new ProductPlacementCopyAPI();
