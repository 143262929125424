"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const Yup = require("yup");
const react_query_1 = require("@tanstack/react-query");
const FormField_1 = require("../../../form/FormField");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const FormValidationUtil_1 = require("../../../../util/FormValidationUtil");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const ForgotPasswordForm = ({ onBackButtonClick }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)('');
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = (0, react_1.useState)(false);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { mainWebShop } = aemConfiguration;
    const [state, dispatch] = (0, CheckoutContext_1.useCheckoutState)();
    const { email } = state.login;
    const validationSchema = Yup.object({
        email: Yup.string()
            .required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`))
            .matches(FormValidationUtil_1.default.emailPatternRegex, t(`${i18nConstants_1.I18N_NAMESPACES.general}:email.error.invalid`)),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { email },
        mode: 'all',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const { sendPasswordResetEmailMutation } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: sendPasswordResetEmail } = (0, react_query_1.useMutation)(sendPasswordResetEmailMutation());
    const handleSubmit = ({ email }) => {
        sendPasswordResetEmail({ email }, {
            onSuccess: () => {
                setErrorFeedback('');
                setResetPasswordEmailSent(true);
                dispatch({ type: 'update_login_data', payload: { email } });
            },
            onError: error => {
                var _a, _b;
                const errorMessage = ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_code) === '404'
                    ? `${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.account.error.email.unknown.user`
                    : `${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.account.error.operation.mail`;
                setErrorFeedback(t(errorMessage));
            },
        });
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), name: "forgot-password-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", color: "neutral", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.title`) }), !resetPasswordEmailSent && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t([
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.intro.${mainWebShop}`,
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.intro`,
                                    ]) }) }))] }), errorFeedback && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: errorFeedback, status: "error" }), resetPasswordEmailSent && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t([
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.success.${mainWebShop}`,
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.success`,
                        ]), status: "success" })), !resetPasswordEmailSent && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "email", children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.email.label`), name: "email", id: "email", autoComplete: "email", error: error }))) }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "branded", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.action`), fullWidth: true }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { alignment: "center", color: "subdued", children: [t([
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.return_back.label.${mainWebShop}`,
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.return_back.label`,
                                    ]), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { onClick: onBackButtonClick, text: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { alignment: "center", color: "subdued", as: "span", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.password_forgotten.return_back.action`) }) })] })] }))] }) }) })));
};
exports.ForgotPasswordForm = ForgotPasswordForm;
