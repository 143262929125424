"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewRating = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const useReevoo_1 = require("../../../hooks/useReevoo");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ReviewRating = ({ review, productCode, reviewOverviewTarget, onClick }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const reevooShowBrand = (0, ConfigurationUtil_1.getReevooShowBrand)(aemConfiguration);
    const reevooStarsEnabled = (0, ConfigurationUtil_1.getReevooStarsEnabled)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const amountTranslation = review.numberOfScores > 1
        ? t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.review.add.amount.of.multiple.review.react`, {
            amountOfReviews: review.numberOfScores,
        })
        : t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.review.add.amount.of.one.review.react`, {
            amountOfReviews: review.numberOfScores,
        });
    const ctaText = amountTranslation.substring(1, amountTranslation.length - 1);
    const showReevooConversationsBadge = (review === null || review === void 0 ? void 0 : review.provider) === common_types_1.ReviewProviderTypes.REEVOO && !!productCode;
    (0, useReevoo_1.useReevoo)(showReevooConversationsBadge);
    if (!review) {
        return null;
    }
    const conversationsBadgeInnerHtml = `<reevoo-badge type="aao" sku="${productCode}" show-brand="${reevooShowBrand}" stars-enabled="${reevooStarsEnabled}" />`;
    const reevooConversationsBadge = ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: conversationsBadgeInnerHtml, className: "a-reevoo__conversations" }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Reevoo, { review: review, reevooConversationsBadge: showReevooConversationsBadge ? reevooConversationsBadge : null, reviewOverviewTarget: reviewOverviewTarget, onClick: onClick, ctaText: ctaText }));
};
exports.ReviewRating = ReviewRating;
