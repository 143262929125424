"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstErrorPath = void 0;
const isObject = obj => obj && typeof obj === 'object';
const getFirstErrorPath = (object) => {
    if (!isObject(object)) {
        return '';
    }
    const currentKey = Object.keys(object)[0];
    if (!(0, exports.getFirstErrorPath)(object[currentKey])) {
        return currentKey;
    }
    return currentKey + '.' + (0, exports.getFirstErrorPath)(object[currentKey]);
};
exports.getFirstErrorPath = getFirstErrorPath;
