"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPMultiProductTileList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MiniPDPMultiProductTile_1 = require("./MiniPDPMultiProductTile");
const MiniPDPMultiProductTileList = ({ products, selectedProductsData, wishlistProducts, sizeSelectErrors, isShopLanding, isPDP, tileIdSuffix, handleSetError, onWishListToggle, onProductChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: products.map((product, i) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [product.isFallbackProduct && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 1,
                            paddingBottom: 1,
                            marginTop: 1,
                            marginBottom: 1,
                        },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: { spacing: { paddingLeft: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: t('product.original.out.of.stock') }) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2 } }, tablet: { spacing: { paddingLeft: isPDP ? 0 : 4, paddingRight: isPDP ? 0 : 4 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingTop: product.isFallbackProduct || i === 0 ? 0 : 2,
                                    paddingBottom: 2,
                                },
                            }, tablet: {
                                spacing: {
                                    paddingTop: product.isFallbackProduct || i === 0 ? 0 : isPDP ? 2 : 3,
                                    paddingBottom: isPDP ? 2 : 3,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(MiniPDPMultiProductTile_1.MiniPDPMultiProductTile, { tileIdSuffix: tileIdSuffix, productData: selectedProductsData[i], wishlistProducts: wishlistProducts, sizeSelectError: sizeSelectErrors[i], index: i, isShopLanding: isShopLanding, handleSetError: handleSetError, onWishListToggle: onWishListToggle, onProductChange: onProductChange.bind(null, i) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] })] }, `${product.productId}_${i}`))) }));
};
exports.MiniPDPMultiProductTileList = MiniPDPMultiProductTileList;
