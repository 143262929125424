"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promoBasketPopupActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.promoBasketPopupActions = {
    createInitializeAction: () => ({
        type: ActionEnums_1.PromoBasketPopupActionTypes.INIT,
    }),
    createPopupCloseAction: () => ({
        type: ActionEnums_1.PromoBasketPopupActionTypes.POPUP_CLOSE,
    }),
    createPopupOpenAction: (message) => ({
        type: ActionEnums_1.PromoBasketPopupActionTypes.POPUP_OPEN,
        payload: message,
    }),
};
