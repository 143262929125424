"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupGeneric = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const PaymentGroupContent_1 = require("./PaymentGroupContent");
const useSavePaymentOption_1 = require("../../../../hooks/useSavePaymentOption");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const CheckoutPaymentContext_1 = require("../../../../context/CheckoutPaymentContext");
const PaymentGroupGeneric = ({ disabled, group, selected }) => {
    const { savePaymentOptions, errorMessage, isLoading } = (0, useSavePaymentOption_1.useSavePaymentOption)();
    const { payPalInfoMessage } = (0, CheckoutContext_1.useCheckoutConfig)();
    const [{ optionError }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const optionSelectError = optionError[group.name];
    const hint = group.name === common_types_1.PaymentGroupName.PAYPAL ? payPalInfoMessage : undefined;
    return ((0, jsx_runtime_1.jsx)(PaymentGroupContent_1.PaymentGroupContent, { onSubmit: () => savePaymentOptions({ group }), disabled: disabled || isLoading, error: errorMessage || optionSelectError, selected: selected, hint: hint, group: group }));
};
exports.PaymentGroupGeneric = PaymentGroupGeneric;
