"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
class BasketApi {
    constructor() {
        this.addProductToBasket = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/add`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addBundleToBasket = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/add/bundle`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addProductToBasketAnalytics = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/analytics/product/add`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addBundleToBasketAnalytics = (bundle, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: bundle,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/analytics/product/add/bundle`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addProductToBasketDtm = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/dtm/product/add`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addBundleToBasketDtm = (bundle, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: bundle,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/dtm/product/add/bundle`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.deleteVoucherCode = (apiEndpoint, defaultRequestParams, code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'DELETE',
                params: Object.assign(Object.assign({}, defaultRequestParams), { voucher: code }),
                url: `${apiEndpoint}api/basket/voucher`,
                withCredentials: true,
            };
            return axios_1.default.request(axiosConfig);
        });
        this.getBasket = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/token`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getBasicBasket = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/basic`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postVoucherCode = (apiEndpoint, defaultRequestParams, code, webcode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { voucher: code }),
                url: `${apiEndpoint}api/basket/voucher`,
                withCredentials: true,
            };
            if (webcode) {
                axiosConfig.params.controlCode = webcode;
            }
            return axios_1.default.request(axiosConfig);
        });
        this.initiateBasketForShopLanding = (apiEndpoint, defaultRequestParams, shopNumber) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { shopNumber }),
                url: `${apiEndpoint}api/basket/landing`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.resetBasketForShopLanding = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/landing/clear`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.refreshCustomerVouchers = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/refresh/customer/vouchers`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.shopAndFictionalIdCheck = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/fictive/switch`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateLanguage = (language, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/lang?lang=${language}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateQuantity = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/update`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.reduceQuantity = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/reduce-amount`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.removeProductFromBasket = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/delete`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.removeProductFromBasketAnalytics = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/analytics/product/delete`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.removeProductFromBasketDtm = (product, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/dtm/product/delete`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateBasketProductAnalytics = (product, quantity, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { quantity }),
                url: `${apiEndpoint}api/analytics/product/update`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateBasketProductDtm = (product, quantity, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: product,
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { quantity }),
                url: `${apiEndpoint}api/dtm/product/update`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.basketOverviewDtm = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/dtm/basket/overview`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        // let the backend check if the basket-items needs to be reserved again
        this.checkStockReservation = (apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/stock/reserve`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.merge = (abandonedBasketToken, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { abandonedBasketToken }),
                url: `${apiEndpoint}api/basket/merge`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.personalShoppingAdviceMerge = (personalShoppingAdviceBasketToken, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'PUT',
                params: Object.assign(Object.assign({}, defaultRequestParams), { personalShoppingAdviceBasketToken }),
                url: `${apiEndpoint}api/personalshoppingadvice/merge`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.personalShoppingAdviceSendMail = (email, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                params: Object.assign(Object.assign({}, defaultRequestParams), { email }),
                url: `${apiEndpoint}api/personalshoppingadvice/sendmail`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.postSavePoint = (apiEndpoint) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${apiEndpoint}api/basket/savepoint`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.addBulkProductsToBasket = (products, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                data: products,
                method: 'POST',
                params: defaultRequestParams,
                url: `${apiEndpoint}api/basket/product/add/bulk`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new BasketApi();
