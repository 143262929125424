"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaidByVoucher = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PaidByVoucher = ({ onSubmit }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: 4 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.EXTRA_CONTENT, children: t('buy.payment.paid.by.voucher.message') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, onClick: onSubmit, type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, iconRight: {
                    name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                    size: cotopaxi_1.IconSize.MAJOR,
                }, text: t('buy.payment.paid.by.voucher.submit') })] }));
};
exports.PaidByVoucher = PaidByVoucher;
