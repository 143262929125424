"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalDataSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PersonalDataSummary = ({ customerDetails, customerTitles, requiresDelivery, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const title = (_a = customerTitles.find(customerTitle => customerTitle.id === customerDetails.idTitle)) === null || _a === void 0 ? void 0 : _a.title;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t('buy.personal.data.summary.title') }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [title ? `${title} ` : '', customerDetails.firstName, " ", customerDetails.middleName ? `${customerDetails.middleName} ` : '', customerDetails.lastName] }), !requiresDelivery && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.POSITIVE, dataQA: "delivery_promise", children: t('buy.payment.delivery.information.evoucher') }) }))] }));
};
exports.PersonalDataSummary = PersonalDataSummary;
