"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const NewCreditCardForm = ({ onSaveCreditCardChange, saveCreditCard, showSaveCardBlock = false, paymentMethods, hasSavedCards, creditCardSavingLegalMessage, toggleSavedCardForm, validation, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const glossary = [
        {
            field: cotopaxi_1.SaferpayFieldTypeEnum.CVC,
            text: t(`buy.payment.saferpay.field.${cotopaxi_1.SaferpayFieldTypeEnum.CVC}.description`),
        },
    ];
    const saferpayFieldsFieldTranslations = {
        cardNumber: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.label.${cotopaxi_1.SaferpayFieldTypeEnum.CARDNUMBER}`),
        holderName: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.label.${cotopaxi_1.SaferpayFieldTypeEnum.HOLDERNAME}`),
        expiration: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.label.${cotopaxi_1.SaferpayFieldTypeEnum.EXPIRATION}`),
        or: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.or`),
        cvc: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.label.${cotopaxi_1.SaferpayFieldTypeEnum.CVC}`),
        cvcDescription: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${cotopaxi_1.SaferpayFieldTypeEnum.CVC}.description`),
        description: (type) => t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${type}.description`),
        error: (type, reason) => t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${type}.error.${reason}`),
    };
    (0, react_1.useEffect)(() => {
        glossary.forEach(glossaryItem => (0, common_utils_1.setTooltip)({
            element: `#saferpay-glossary-${glossaryItem.field}`,
            content: glossaryItem.text,
            showCloseButton: false,
            optionsOverride: {
                trigger: 'click',
            },
        }));
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showSaveCardBlock && hasSavedCards && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t('buy.payment.saferpay.creditcard.title.new') }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.SaferpayFieldsCreditCard, { paymentMethods: paymentMethods, validation: validation, glossary: glossary, translations: saferpayFieldsFieldTranslations }), showSaveCardBlock && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: "save-credit-card", name: "save-credit-card", value: "save-credit-card", labelChildren: t('buy.payment.saferpay.field.save.label'), checked: saveCreditCard, onChange: onSaveCreditCardChange, size: cotopaxi_1.LabelSize.MINOR }), creditCardSavingLegalMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, mobile: 12, mobileLarge: 12, tabletSmall: 12, tablet: 10, desktop: 10, desktopLarge: 10, grid: 0, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.XSMALL, color: cotopaxi_1.TextColor.SUBTLE, children: creditCardSavingLegalMessage }) }) })), hasSavedCards && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.ARROW_LEFT, size: cotopaxi_1.IconSize.MAJOR }, onClick: toggleSavedCardForm, text: t('buy.payment.saferpay.creditcard.goto.saved') }) }))] }))] }));
};
exports.default = NewCreditCardForm;
