"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePassword = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const UpdatePasswordForm_1 = require("../update-password-form/UpdatePasswordForm");
const ChangePassword = ({ onSubmit, buttonState, errorFeedback, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy]);
    const validate = (values) => {
        const errors = {};
        if (!values.oldPassword) {
            errors.oldPassword = t(common_types_1.ErrorMessage.REQUIRED);
        }
        else if (values.oldPassword.length < common_types_1.DefaultSettings.MIN_LENGTH_PASSWORD) {
            errors.oldPassword = t(common_types_1.ErrorMessage.ACCOUNT_MIN_LENGTH);
        }
        if (!values.newPassword) {
            errors.newPassword = t(common_types_1.ErrorMessage.REQUIRED);
        }
        else if (values.newPassword.length < common_types_1.DefaultSettings.MIN_LENGTH_PASSWORD) {
            errors.newPassword = t(common_types_1.ErrorMessage.ACCOUNT_MIN_LENGTH);
        }
        if (!values.repeatPassword) {
            errors.repeatPassword = t(common_types_1.ErrorMessage.REQUIRED);
        }
        else if (values.newPassword !== values.repeatPassword) {
            errors.repeatPassword = t(common_types_1.ErrorMessage.PASSWORD_MATCH);
        }
        return errors;
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [title.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: title.value || t('account.updatePassword') }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 5, children: (0, jsx_runtime_1.jsx)(UpdatePasswordForm_1.UpdatePasswordForm, { onSubmit: onSubmit, buttonState: buttonState, validate: validate, errorFeedback: errorFeedback }) }) }) })] }));
};
exports.ChangePassword = ChangePassword;
