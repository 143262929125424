"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const formik_1 = require("formik");
const DeliveryEVoucherChangeHandler = ({ setFormValid }) => {
    const { touched, isValid, initialValues: { firstName, lastName, idTitle }, } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        if (Object.keys(touched).length === 0) {
            setFormValid(Boolean(firstName && lastName && idTitle));
        }
        else {
            setFormValid(isValid);
        }
    }, [isValid, touched, firstName, lastName, idTitle]);
    return null;
};
exports.default = DeliveryEVoucherChangeHandler;
