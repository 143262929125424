"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyNamedDay = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BuyNamedDay = ({ errors, touched, days }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 6, tabletSmall: 6, grid: 4, gutter: false, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "buy-named-day-dates", size: cotopaxi_1.LabelSize.MINOR, children: t('buy:buy.delivery.named.day.label') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { required: true, id: "buy-named-day-dates", name: "deliveryDate", fullWidth: true, options: days, state: errors.deliveryDate && touched.deliveryDate && cotopaxi_1.SelectState.ERROR, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select })] }) }));
};
exports.BuyNamedDay = BuyNamedDay;
