"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlideCropped = exports.SLIDES_AMOUNT_DESKTOP_LARGE = exports.SLIDES_AMOUNT_DESKTOP = exports.SLIDE_SPACING = void 0;
exports.SLIDE_SPACING = {
    MOBILE: 16,
    TABLET: 24,
    DESKTOP: 32,
};
exports.SLIDES_AMOUNT_DESKTOP = 4;
exports.SLIDES_AMOUNT_DESKTOP_LARGE = 5;
var SlideCropped;
(function (SlideCropped) {
    SlideCropped[SlideCropped["LARGE"] = 0.6] = "LARGE";
    SlideCropped[SlideCropped["MEDIUM"] = 0.25] = "MEDIUM";
    SlideCropped[SlideCropped["SMALL"] = 0.15] = "SMALL";
})(SlideCropped || (exports.SlideCropped = SlideCropped = {}));
