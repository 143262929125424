"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const Yup = require("yup");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const EmailChangeHandler_1 = require("../../../../../components/email/EmailChangeHandler");
const FormValidationUtil_1 = require("../../../../../util/FormValidationUtil");
const ForgotPasswordForm = ({ onSubmit, onEmailChange, onShowLoginFormClick, successFeedback, errorFeedback, initialEmail = '', }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general]);
    const validationSchema = Yup.object({
        email: Yup.string()
            .required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`))
            .matches(FormValidationUtil_1.default.emailPatternRegex, t(`${i18nConstants_1.I18N_NAMESPACES.general}:email.error.invalid`)),
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.M, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.login.title.password.forgotten`) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.passwordForgottenText`) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "login-flows-forgot-password-form", initialValues: { email: initialEmail }, validationSchema: validationSchema, onSubmit: onSubmit, children: ({ values, errors, touched }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [onEmailChange && ((0, jsx_runtime_1.jsx)(EmailChangeHandler_1.EmailChangeHandler, { emailFieldKey: "email", onEmailChange: onEmailChange, emailValue: values.email, skipEmailValidation: false })), successFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: successFeedback, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }) })), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { type: cotopaxi_1.InputType.EMAIL, id: "email", name: "email", value: values.email, required: true, state: errors.email && touched.email ? cotopaxi_1.InputState.ERROR : undefined, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.flyout.email.placeholder`), adornmentEnd: errors.email &&
                                touched.email && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE }), Component: cotopaxi_1.Input }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.password.request`) }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, emphasized: true, children: [t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.account.password.remember`), ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, noBubble: true, onClick: onShowLoginFormClick, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.login.title.login`) })] })) })] }));
};
exports.ForgotPasswordForm = ForgotPasswordForm;
