"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listerReducer = exports.loadListerReducer = exports.initialState = void 0;
const common_utils_1 = require("@as-react/common-utils");
const connected_react_router_1 = require("connected-react-router");
const WishListReducer_1 = require("../../../redux/wishList/reducers/WishListReducer");
const CompareReducer_1 = require("../../../redux/compare/reducers/CompareReducer");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialState = {};
const appendAdditionalBoundaryDataIfNeeded = (destination, source) => {
    var _a, _b, _c, _d;
    if (!!destination.boundaryMin || destination.boundaryMin === 0) {
        return destination;
    }
    if (!destination.isActive) {
        return Object.assign(Object.assign({}, destination), { boundaryMin: Math.floor((_a = destination.rangeMin) !== null && _a !== void 0 ? _a : 0), boundaryMax: Math.ceil((_b = destination.rangeMax) !== null && _b !== void 0 ? _b : 0) });
    }
    return Object.assign(Object.assign({}, destination), { boundaryMin: !!source.boundaryMin || source.boundaryMin === 0 ? source.boundaryMin : Math.floor((_c = source.rangeMin) !== null && _c !== void 0 ? _c : 0), boundaryMax: !!source.boundaryMax || source.boundaryMax === 0 ? source.boundaryMax : Math.ceil((_d = source.rangeMax) !== null && _d !== void 0 ? _d : 0) });
};
const updateExistingFilters = (state, action) => {
    var _a;
    const newFilterOnKeys = action.payload.map((filter) => filter.filterOn);
    let newFilters = [...action.payload];
    (_a = state.filters) === null || _a === void 0 ? void 0 : _a.forEach((filter, index) => {
        const indexInSearchArray = newFilterOnKeys.indexOf(filter.filterOn);
        if (indexInSearchArray === -1) {
            newFilters = [...newFilters.slice(0, index), Object.assign(Object.assign({}, filter), { disabled: true }), ...newFilters.slice(index)];
        }
        else if (filter.type === ActionEnums_1.FilterActionTypes.RANGE_FILTER) {
            const newFilterIndex = newFilters.findIndex(({ filterOn }) => filterOn === filter.filterOn);
            if (newFilterIndex !== -1) {
                newFilters[newFilterIndex] = appendAdditionalBoundaryDataIfNeeded(newFilters[newFilterIndex], filter);
            }
        }
    });
    return newFilters;
};
const updateRangeFilterWithBoundaries = (filter) => {
    if (filter.type === ActionEnums_1.FilterActionTypes.RANGE_FILTER) {
        return appendAdditionalBoundaryDataIfNeeded(filter, filter);
    }
    return filter;
};
const loadListerReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        var _a, _b, _c, _d;
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return ((_a = action.payload.location) === null || _a === void 0 ? void 0 : _a.state)
                    ? Object.assign(Object.assign({}, action.payload.location.state.lister), { isLoaded: true }) : state;
            case ActionEnums_1.UrlActionTypes.UPDATE_PAGE:
                return Object.assign(Object.assign({}, state), { isLoading: true });
            case ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS:
                return Object.assign(Object.assign({}, state), { items: action.payload, isLoading: false });
            case ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS_AND_TOTAL_PAGES:
                return Object.assign(Object.assign({}, state), { items: action.payload.products, totalPages: action.payload.totalPages, isLoading: false });
            case ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS_AND_TOTAL_PAGES_AND_ITEM_COUNT:
                return Object.assign(Object.assign({}, state), { items: action.payload.products, totalPages: action.payload.totalPages, itemCount: action.payload.itemCount, isLoading: false });
            case ActionEnums_1.ListerActionTypes.SHOW_PLACEHOLDER_TILES:
                return Object.assign(Object.assign({}, state), { isLoading: true });
            case ActionEnums_1.UrlActionTypes.UPDATE_FILTERS: {
                const { multiUpdate } = action.payload;
                const filterKeys = action.payload.filteredItems.map(item => item.filterKey);
                return Object.assign(Object.assign({}, state), { filters: (_b = state.filters) === null || _b === void 0 ? void 0 : _b.map(filter => {
                        if (filter.filterOn === action.payload.filterOn) {
                            if ((0, common_utils_1.isMultiSelectFilter)(action.payload) || (0, common_utils_1.isBucketFilter)(action.payload)) {
                                const values = filter.values.map((filterValue) => {
                                    const filterIndex = filterKeys.indexOf(filterValue.filterKey);
                                    if (filterIndex !== -1) {
                                        return Object.assign(Object.assign({}, filterValue), { active: multiUpdate
                                                ? Boolean(action.payload.filteredItems[filterIndex].isActive)
                                                : !filterValue.active });
                                    }
                                    return Object.assign({}, filterValue);
                                });
                                return Object.assign(Object.assign({}, filter), { values });
                            }
                            else if ((0, common_utils_1.isRangeFilter)(action.payload)) {
                                const [rangeMinRawValue, rangeMaxRawValue] = filterKeys;
                                const rangeMin = Number(rangeMinRawValue);
                                const rangeMax = Number(rangeMaxRawValue);
                                return Object.assign({}, updateRangeFilterWithBoundaries(Object.assign(Object.assign({}, filter), { rangeMin,
                                    rangeMax })));
                            }
                        }
                        return Object.assign({}, updateRangeFilterWithBoundaries(filter));
                    }) });
            }
            case ActionEnums_1.ListerActionTypes.UPDATE_EXISTING_FILTERS:
                return Object.assign(Object.assign({}, state), { filters: updateExistingFilters(state, action) });
            case ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS:
                return Object.assign(Object.assign({}, state), { filters: (_c = state.filters) === null || _c === void 0 ? void 0 : _c.map(filter => {
                        if (filter.type === ActionEnums_1.FilterActionTypes.MULTISELECT_FILTER) {
                            filter.values = filter.values.map(filterValueObject => (Object.assign(Object.assign({}, filterValueObject), { active: false })));
                        }
                        return Object.assign({}, filter);
                    }) });
            case ActionEnums_1.CompareActionTypes.COMPARE_UPDATE_PRODUCTS:
                return Object.assign(Object.assign({}, state), { items: (0, CompareReducer_1.toggleComparedStateForItems)(state.items || [], action.payload) });
            case ActionEnums_1.WishListActionTypes.UPDATE_PRODUCTS:
                return Object.assign(Object.assign({}, state), { items: (0, WishListReducer_1.initializeWishListProducts)(state.items || [], action) });
            case ActionEnums_1.WishListActionTypes.ADD_PRODUCT:
                return Object.assign(Object.assign({}, state), { items: (0, WishListReducer_1.updateWishListProducts)(state.items || [], action, true) });
            case ActionEnums_1.WishListActionTypes.REMOVE_PRODUCT:
                return Object.assign(Object.assign({}, state), { items: (0, WishListReducer_1.updateWishListProducts)(state.items || [], action, false) });
            case ActionEnums_1.ListerActionTypes.UPDATE_IN_STORE_FILTER:
                return Object.assign(Object.assign({}, state), { inStoreFilter: action.payload });
            case ActionEnums_1.ListerActionTypes.SET_LISTER_STATE:
                return Object.assign(Object.assign(Object.assign({}, state), action.payload), { filters: (_d = action.payload.filters) === null || _d === void 0 ? void 0 : _d.map(filter => updateRangeFilterWithBoundaries(filter)) });
            case ActionEnums_1.ListerActionTypes.SET_LISTER_ISLOADED:
                return Object.assign(Object.assign({}, state), { isLoaded: true });
            default:
                return state;
        }
    };
};
exports.loadListerReducer = loadListerReducer;
exports.listerReducer = (0, exports.loadListerReducer)();
