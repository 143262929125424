"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFetchWithoutProductsSaga = exports.fetchWithoutProductsSearch = void 0;
const effects_1 = require("redux-saga/effects");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const SearchAPI_1 = require("../../../api/search/SearchAPI");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const SearchActions_creators_1 = require("../actions/SearchActions.creators");
function* fetchWithoutProductsSearch(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const searchSuggestions = yield (0, effects_1.call)(SearchAPI_1.default.searchSuggestions, servicesEndpoint, defaultRequestParams, action.payload);
        const allSuggestions = {
            searchContent: null,
            searchSuggestions: searchSuggestions,
        };
        const contentSearch = yield (0, effects_1.call)(SearchAPI_1.default.searchContentSuggestion, servicesEndpoint, `${aemConfigurationContext.activeEnvironment}_${aemConfigurationContext.elasticsearchIndex}`, action.payload);
        if (contentSearch && contentSearch.content) {
            allSuggestions.searchContent = contentSearch.content;
        }
        yield (0, effects_1.put)(SearchActions_creators_1.searchActions.createFindAction(allSuggestions));
    }
    catch (_a) { }
}
exports.fetchWithoutProductsSearch = fetchWithoutProductsSearch;
function* loadFetchWithoutProductsSaga() {
    yield (0, effects_1.throttle)(500, ActionEnums_1.SearchActionTypes.FETCH_WITHOUT_PRODUCTS, fetchWithoutProductsSearch);
}
exports.loadFetchWithoutProductsSaga = loadFetchWithoutProductsSaga;
function* SearchSaga() {
    yield (0, effects_1.all)([loadFetchWithoutProductsSaga()]);
}
exports.default = SearchSaga;
