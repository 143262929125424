"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDeliveryMessageCollectStoreOrHQ = exports.getDeliveryExplanation = void 0;
const common_types_1 = require("@as-react/common-types");
const getDeliveryExplanation = (deliveryPromise, deliveryPromiseExplanations) => {
    if (!!deliveryPromiseExplanations && !!deliveryPromise && deliveryPromise.stockLocation) {
        switch (deliveryPromise.stockLocation) {
            case common_types_1.StockLocation.STORE:
                return deliveryPromiseExplanations.storeStock;
            case common_types_1.StockLocation.WAREHOUSE:
                return deliveryPromiseExplanations.warehouseStock;
            case common_types_1.StockLocation.SUPPLIER:
                return deliveryPromiseExplanations.supplierStock;
            case common_types_1.StockLocation.PREORDER:
                return deliveryPromiseExplanations.preOrder;
            case common_types_1.StockLocation.POSTOUT:
                return deliveryPromiseExplanations.postOut;
            default:
                return;
        }
    }
    else {
        return;
    }
};
exports.getDeliveryExplanation = getDeliveryExplanation;
const isDeliveryMessageCollectStoreOrHQ = (deliveryMessages) => !!(deliveryMessages === null || deliveryMessages === void 0 ? void 0 : deliveryMessages[common_types_1.DeliveryOptionsEnum.COLLECT_STORE]) || !!(deliveryMessages === null || deliveryMessages === void 0 ? void 0 : deliveryMessages[common_types_1.DeliveryOptionsEnum.COLLECT_HQ]);
exports.isDeliveryMessageCollectStoreOrHQ = isDeliveryMessageCollectStoreOrHQ;
