"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YonderlandMediatorGroup = exports.YonderlandMediatorAction = void 0;
var YonderlandMediatorAction;
(function (YonderlandMediatorAction) {
    YonderlandMediatorAction["PUBLISH"] = "publish";
    YonderlandMediatorAction["SUBSCRIBE"] = "subscribe";
    YonderlandMediatorAction["UNSUBSCRIBE"] = "unsubscribe";
})(YonderlandMediatorAction || (exports.YonderlandMediatorAction = YonderlandMediatorAction = {}));
var YonderlandMediatorGroup;
(function (YonderlandMediatorGroup) {
    YonderlandMediatorGroup["BASKET"] = "basket";
})(YonderlandMediatorGroup || (exports.YonderlandMediatorGroup = YonderlandMediatorGroup = {}));
