"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.searchActions = {
    createFetchWithoutProductsAction(value) {
        return {
            type: ActionEnums_1.SearchActionTypes.FETCH_WITHOUT_PRODUCTS,
            payload: value,
        };
    },
    createFindAction(payload) {
        return {
            type: ActionEnums_1.SearchActionTypes.FIND,
            payload,
        };
    },
    createAdobeAnalyticsSuccessProductAction() {
        return {
            type: ActionEnums_1.SearchActionTypes.ADOBE_ANALYTICS_SUCCESSFULL_SEARCH_EVENT_PRODUCT,
        };
    },
    createAdobeAnalyticsSetSearchTermAction(payload) {
        return {
            type: ActionEnums_1.SearchActionTypes.ADOBE_ANALYTICS_SET_SEARCH_TERM,
            payload,
        };
    },
};
