"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetCustomerBillingAddress = exports.makeGetCustomerDeliveryAddresses = exports.makeGetCustomerProfile = exports.makeGetIsExploreMoreCustomer = void 0;
const reselect_1 = require("reselect");
const customerStateSelector = (state) => state.customer;
const makeGetIsExploreMoreCustomer = () => (0, reselect_1.createSelector)(customerStateSelector, (customerState) => { var _a; return !!((_a = customerState.profile) === null || _a === void 0 ? void 0 : _a.isExploreMore); });
exports.makeGetIsExploreMoreCustomer = makeGetIsExploreMoreCustomer;
const makeGetCustomerProfile = () => (0, reselect_1.createSelector)(customerStateSelector, (customerState) => customerState.profile);
exports.makeGetCustomerProfile = makeGetCustomerProfile;
const makeGetCustomerDeliveryAddresses = () => (0, reselect_1.createSelector)(customerStateSelector, (customerState) => customerState.deliveryAddresses);
exports.makeGetCustomerDeliveryAddresses = makeGetCustomerDeliveryAddresses;
const makeGetCustomerBillingAddress = () => (0, reselect_1.createSelector)(customerStateSelector, (customerState) => customerState.billingAddress);
exports.makeGetCustomerBillingAddress = makeGetCustomerBillingAddress;
