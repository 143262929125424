"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerTile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const EmbeddedContent_1 = require("../../embedded-content/EmbeddedContent");
const useABTest_1 = require("../../../hooks/useABTest");
const UrlUtil_1 = require("../../../util/UrlUtil");
const FRAMELESS_PARAM_NAME = 'frameless';
const BannerTile = ({ lazy, url, image, alt }) => {
    const [embeddedContentLink, setEmbeddedContentLink] = (0, react_1.useState)('');
    const showEmbeddedContent = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.EMBEDDED_CONTENT);
    const handleBannerClick = (e) => {
        if (showEmbeddedContent && url.includes(FRAMELESS_PARAM_NAME)) {
            e.preventDefault();
            setEmbeddedContentLink((0, UrlUtil_1.removeURLParameter)(url, FRAMELESS_PARAM_NAME));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.BannerTile, { lazy: lazy, url: (0, UrlUtil_1.removeURLParameter)(url, FRAMELESS_PARAM_NAME), src: image, title: alt, alt: alt, onClick: handleBannerClick }), (0, jsx_runtime_1.jsx)(EmbeddedContent_1.EmbeddedContent, { isOpen: Boolean(embeddedContentLink), href: embeddedContentLink, onClose: () => setEmbeddedContentLink('') })] }));
};
exports.BannerTile = BannerTile;
