"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyInvoice = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BuyVatFields_1 = require("../buy-vat-fields/BuyVatFields");
const BuyForm_1 = require("../BuyForm");
const BuyInvoice = ({ errors, touched, values, fullVatFormEnabled, placeholderVat, hideCompanyName, disableVatNumberField, companyNameFieldLocked, invoiceId = '', children, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const fieldName = fullVatFormEnabled ? 'needInvoice' : 'alternateInvoiceAddress';
    const showInvoiceFields = (fullVatFormEnabled && values.needInvoice) || (!fullVatFormEnabled && values.alternateInvoiceAddress);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: `buy-invoice-${invoiceId}`, name: fieldName, labelChildren: t('buy.invoice.invoiceneeded'), componentType: cotopaxi_1.FormFieldType.CHECKBOX, Component: cotopaxi_1.CheckboxWithLabel }), showInvoiceFields && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: [fullVatFormEnabled && ((0, jsx_runtime_1.jsx)(BuyVatFields_1.BuyVatFields, { errors: errors, touched: touched, hideCompanyName: hideCompanyName, disableVatNumberField: disableVatNumberField, companyNameFieldLocked: companyNameFieldLocked, placeholderVat: placeholderVat })), react_1.Children.map(children, (child) => {
                        var _a;
                        if (!child)
                            return null;
                        const props = {
                            errors,
                            touched,
                        };
                        return (0, react_1.cloneElement)(child, ((_a = child.props) === null || _a === void 0 ? void 0 : _a.parent) === BuyForm_1.BUY_FORM_NAME ? props : undefined);
                    })] }))] }));
};
exports.BuyInvoice = BuyInvoice;
