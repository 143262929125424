"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPostCode = exports.isValidAddress = exports.getHouseNumberNLPattern = exports.generateFieldName = exports.emptyAddressFields = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
exports.emptyAddressFields = {
    country: '',
    postCode: '',
    city: '',
    cityFrench: undefined,
    county: '',
    houseNumber: '',
    houseNumberAddition: '',
    address: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
};
const generateFieldName = (name, prefix) => prefix ? `${prefix}${prefix.endsWith('.') ? name : (0, common_utils_1.capitalizeOnly)(name)}` : name;
exports.generateFieldName = generateFieldName;
const getHouseNumberNLPattern = (value) => new RegExp('^[A-Z]{1}', 'i').test(value) ? common_types_1.DefaultSettings.HOUSE_NUMBER_NL_ALPHA : common_types_1.DefaultSettings.HOUSE_NUMBER_NL;
exports.getHouseNumberNLPattern = getHouseNumberNLPattern;
const isValidAddress = (postCode, houseNumber, countryId) => {
    switch (countryId) {
        case common_types_1.CountryId.NL:
            return (!!postCode &&
                !!houseNumber &&
                new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i').test(postCode) &&
                new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber));
        case common_types_1.CountryId.BE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case common_types_1.CountryId.GB:
        case common_types_1.CountryId.GG:
        case common_types_1.CountryId.JE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK).test(postCode);
        default:
            return true;
    }
};
exports.isValidAddress = isValidAddress;
const isValidPostCode = (postCode, countryId) => {
    switch (countryId) {
        case common_types_1.CountryId.BE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case common_types_1.CountryId.FR:
        case common_types_1.CountryId.DE:
            return !!postCode && postCode.length === 5;
        default:
            return true;
    }
};
exports.isValidPostCode = isValidPostCode;
