"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareStickyBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../../constants/SliderConstants");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const CompareUtil_1 = require("../../../util/CompareUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const COMPARE_STICKY_POSITION_DESKTOP = 700;
const CompareStickyBar = ({ comparisonProducts, setSwiperInstance, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const listerConfiguration = (0, ConfigurationUtil_1.getListerConfiguration)(aemConfiguration);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [hidden, setHidden] = (0, react_1.useState)(true);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)(null);
    const [isNavDisplayed, setIsNavDisplayed] = (0, react_1.useState)(false);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const [compareStickyCarouselSwiper, setCompareStickyCarouselSwiper] = (0, react_1.useState)(null);
    const setSwiper = (swiper) => {
        setCompareStickyCarouselSwiper(swiper);
        setSwiperInstance(swiper);
    };
    (0, react_1.useEffect)(() => {
        if (compareStickyCarouselSwiper !== null) {
            compareStickyCarouselSwiper.update();
        }
    }, [comparisonProducts, compareStickyCarouselSwiper]);
    (0, react_1.useEffect)(() => {
        const navigation = document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL);
        setNavigationEl(navigation);
        // This needed because the navigation menu gets hidden after the page loads with a delay
        setTimeout(() => setShouldRender(true), GeneralConstants_1.DELAY.DELAY_100);
        return () => setSwiperInstance(null);
    }, []);
    const compareStickyCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.PRODUCT_TILE_NORMAL,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: false,
        fullWidth: true,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            getSwiper: setSwiper,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView: 'auto',
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.TABLET,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP_LARGE,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
            },
        },
    };
    const compareSlide = (0, react_1.useCallback)((product) => {
        const getActiveColour = () => product.colours.find((colour) => colour.colourId === product.colourId);
        const getImage = () => {
            var _a;
            return (0, ImageUtil_1.getPortraitImageSizeUrlByProductCode)(product.productCode, (_a = getActiveColour()) === null || _a === void 0 ? void 0 : _a.image, listerConfiguration.productCdnDomains, common_types_1.ProductImageSizesPortraitEnum.thumb);
        };
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 4, desktop: 3, tablet: 4, tabletSmall: 4, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { opacity: (0, ProductUtil_1.isProductOutOfStock)(product) || (0, ProductUtil_1.isProductWithColorOutOfStock)(product) ? 0.5 : null, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { size: cotopaxi_1.ImageSize.DPE_LOGO_TITLE, src: getImage(), alt: product.title }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2 } }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { isClipped: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: product.brand }), ' ', product.title] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, Object.assign({ size: cotopaxi_1.PriceSize.MEDIUM }, (0, CompareUtil_1.getCompareProductPrice)(product, currency, t)))] })] }) }) }, `${product.productCode}${product.colourId}`));
    }, [comparisonProducts]);
    (0, cotopaxi_1.useScrollPosition)(({ currPos }) => {
        if (navigationEl) {
            if (!isDesktop) {
                setIsNavDisplayed(!navigationEl.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE));
            }
            else {
                if (isNavDisplayed) {
                    setIsNavDisplayed(false);
                }
            }
        }
        setHidden(Math.abs(currPos.y) < COMPARE_STICKY_POSITION_DESKTOP);
    }, [navigationEl, isNavDisplayed, isDesktop]);
    if (!shouldRender) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { isHidden: hidden, variant: cotopaxi_1.StickyBarVariant.COMPARE, withNavBar: isNavDisplayed, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { gutter: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, desktop: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, compareStickyCarouselConfig, { children: comparisonProducts.map(compareSlide) })) }) }) }));
};
exports.CompareStickyBar = CompareStickyBar;
