"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HtmlInclude = exports.ScriptTypes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
var ScriptTypes;
(function (ScriptTypes) {
    ScriptTypes["LD_JSON"] = "application/ld+json";
})(ScriptTypes || (exports.ScriptTypes = ScriptTypes = {}));
const _HtmlInclude = ({ htmlFragment, scriptFragment, styleFragment, className, scriptType, }) => {
    if (htmlFragment) {
        return (0, jsx_runtime_1.jsx)("div", { className: className, dangerouslySetInnerHTML: { __html: htmlFragment } });
    }
    else if (scriptFragment) {
        return (0, jsx_runtime_1.jsx)("script", { dangerouslySetInnerHTML: { __html: scriptFragment }, type: scriptType });
    }
    else if (styleFragment) {
        return (0, jsx_runtime_1.jsx)("style", { className: className, dangerouslySetInnerHTML: { __html: styleFragment } });
    }
    return null;
};
const HtmlInclude = (0, react_1.memo)(_HtmlInclude);
exports.HtmlInclude = HtmlInclude;
