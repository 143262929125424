"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentReducer = exports.loadContentReducer = exports.initialContentState = void 0;
const common_utils_1 = require("@as-react/common-utils");
const connected_react_router_1 = require("connected-react-router");
const ContentActionTypes_1 = require("../types/ContentActionTypes");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialContentState = {};
const updateFiltersState = (filters, action) => {
    const filterKeys = action.payload.filteredItems.map((item) => item.filterKey);
    return filters.map((filter) => {
        if (filter.filterOn === action.payload.filterOn) {
            return Object.assign(Object.assign({}, filter), { values: filter.values.map((filterValue) => {
                    let isActive = filterValue.active;
                    const filterIndex = filterKeys.indexOf(filterValue.filterKey);
                    if ((0, common_utils_1.isMultiSelectFilter)(action.payload)) {
                        isActive = filterIndex !== -1 ? !isActive : isActive;
                    }
                    else if ((0, common_utils_1.isLinkFilter)(action.payload)) {
                        isActive = filterIndex !== -1 ? !isActive : false;
                    }
                    return Object.assign(Object.assign({}, filterValue), { active: isActive });
                }) });
        }
        return Object.assign({}, filter);
    });
};
const removeAllFilters = (filters) => filters.map((filter) => (Object.assign(Object.assign({}, filter), { values: filter.values.map((filterValueObject) => (Object.assign(Object.assign({}, filterValueObject), { active: false }))) })));
const loadContentReducer = (initialData = null) => {
    const initialState = Object.assign(Object.assign({}, exports.initialContentState), initialData);
    return (state = initialState, action) => {
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return action.payload.location && action.payload.location.state
                    ? Object.assign({}, action.payload.location.state.content) : state;
            case ActionEnums_1.UrlActionTypes.UPDATE_FILTERS:
                return Object.assign(Object.assign({}, state), { filters: updateFiltersState(state.filters, action) });
            case ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS:
                return Object.assign(Object.assign({}, state), { filters: removeAllFilters(state.filters) });
            case ActionEnums_1.UrlActionTypes.UPDATE_PAGE:
                return Object.assign(Object.assign({}, state), { isLoading: true });
            case ContentActionTypes_1.ContentOverviewActionTypes.SHOW_PLACEHOLDER_TILES:
                return Object.assign(Object.assign({}, state), { isLoading: true });
            case ContentActionTypes_1.ContentOverviewActionTypes.UPDATE_CONTENT:
                return Object.assign(Object.assign({}, state), { tiles: Object.assign({}, action.payload), isLoading: false });
            case ContentActionTypes_1.ContentOverviewActionTypes.UPDATE_FILTERS:
                return Object.assign(Object.assign({}, state), { filters: [...action.payload] });
            default:
                return state;
        }
    };
};
exports.loadContentReducer = loadContentReducer;
exports.ContentReducer = (0, exports.loadContentReducer)();
