"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductNames = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const ProductNames = ({ headerPriority, brand, title, showGenderIcon, genderFeatureId, headingSize, }) => {
    const isHeadingExist = (priority => !(0, common_utils_1.isNullOrUndefined)(priority) && priority >= 1 && priority <= 6)(headerPriority);
    const iconName = (0, cotopaxi_1.getIconCode)(genderFeatureId);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showGenderIcon && genderFeatureId && genderFeatureId !== '' && iconName && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: iconName }), isHeadingExist && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority[`H${headerPriority}`], size: headingSize, type: !headingSize && cotopaxi_1.HeadingType.TITLE, dataQA: "product_name", children: [`${brand} `, title] })), !isHeadingExist && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: `${brand} ` + title })] }));
};
exports.ProductNames = ProductNames;
