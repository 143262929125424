"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Url_module_1 = require("./modules/Url.module");
const Authentication_module_1 = require("./modules/Authentication.module");
const Session_module_1 = require("./modules/Session.module");
const CookieConsent_module_1 = require("./modules/CookieConsent.module");
const loadMobileNavigationModule = () => ({
    hasRouter: false,
    modules: [(0, Url_module_1.default)(), (0, Session_module_1.default)(), (0, Authentication_module_1.default)(), (0, CookieConsent_module_1.default)()],
});
exports.default = loadMobileNavigationModule;
