"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const EmptyCartPage = ({ root }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_AROUND, valign: cotopaxi_1.GroupVerticalAlign.CENTER, column: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, inline: true, dataQA: "empty_basket_message", children: t('basket.emptyBasket') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { href: root, text: t('basket.startShopping') }) })] }));
};
exports.default = EmptyCartPage;
