"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BasketReducer_1 = require("../../basket/reducers/BasketReducer");
const BasketSagas_1 = require("../../basket/sagas/BasketSagas");
const CompareReducer_1 = require("../../compare/reducers/CompareReducer");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const loadEssentialsModule = () => ({
    id: 'Essentials',
    reducerMap: {
        basket: BasketReducer_1.basketReducer,
        compare: CompareReducer_1.compareReducer,
    },
    sagas: [BasketSagas_1.default, CompareSagas_1.default, DeliveryPromiseSaga_1.default],
});
exports.default = loadEssentialsModule;
