"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterSizeItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_types_1 = require("@as-react/common-types");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const FilterSizeItem = ({ item, type, filter, toggleFilterValue, desktop, search, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const pageInfo = (0, ConfigurationUtil_1.getPageInfo)(aemConfiguration);
    const isBrandFilter = filter.filterOn === 'brand' && (pageInfo.isBrandPage || pageInfo.isCategoryPage);
    const canonicalUrl = (0, ConfigurationUtil_1.getCanonicalUrl)(aemConfiguration);
    const baseUrl = canonicalUrl.slice(0, canonicalUrl.indexOf('.html'));
    const filterName = item.name.split(GeneralConstants_1.FILTER_SIZE_SEPARATOR)[0];
    let url = '';
    let filterItemType;
    switch (type) {
        case common_types_1.BUCKET_FILTER:
            filterItemType = cotopaxi_1.FilterItemType.SINGLE;
            break;
        case common_types_1.LINK_FILTER:
            filterItemType = cotopaxi_1.FilterItemType.LINK;
            break;
        case common_types_1.MULTISELECT_FILTER:
        default:
            filterItemType = cotopaxi_1.FilterItemType.MULTI;
    }
    if (isBrandFilter && !!item.normalizedName) {
        url = `${baseUrl}/${item.normalizedName}.html`;
    }
    else if (type === common_types_1.LINK_FILTER) {
        const urlObj = new URL(canonicalUrl);
        urlObj.searchParams.set(filter.queryParamKey, `${filter.filterOn}:${item.filterKey}`);
        url = urlObj.toString();
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FilterItem, { type: filterItemType, filterOn: desktop ? `${filter.filterOn}-desktop` : filter.filterOn, filterKey: item.filterKey, linkTitle: filterName, text: search ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.INHERIT, html: filterName.replace(new RegExp(`(${search})`, 'i'), '<strong>$1</strong>') })) : (filterName), normalizedName: item.normalizedName, itemCount: item.itemCount, active: item.active, onChange: (e) => toggleFilterValue(e, item), onClick: e => toggleFilterValue(e, item), url: url, size: cotopaxi_1.TextSize.SMALL }, item.filterKey));
};
exports.FilterSizeItem = FilterSizeItem;
