"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDistanceOSM = exports.getLocationFromBrowser = void 0;
const getLocationFromBrowser = () => {
    return new Promise((resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(position => {
            resolve({
                lat: position.coords.latitude.toString(),
                lng: position.coords.longitude.toString(),
            });
        }, error => {
            reject(error);
        });
    });
};
exports.getLocationFromBrowser = getLocationFromBrowser;
const rad = (x) => (x * Math.PI) / 180;
const calculateDistanceOSM = (p1, p2) => {
    const R = 6378137;
    const dLat = rad(p2.latitude - p1.latitude);
    const dLong = rad(p2.longitude - p1.longitude);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
};
exports.calculateDistanceOSM = calculateDistanceOSM;
