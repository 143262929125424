"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const ScreenWidthConstants_1 = require("../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../constants/SliderConstants");
const ProductUtil_1 = require("../../util/ProductUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const useProductGroupAnalytics_1 = require("../../hooks/useProductGroupAnalytics");
const ProductGroup = ({ addToBasket, analyticsAreaName, basketPopup, basketStatus, collapsed, collapsible, compareItems, deliveryPromiseExplanations, getDeliveryPromise, hideBasketPopup, productGroupId, inBasketOverview, numberOfTiles, onAddToBasketCallback, onTileClick, onWishListToggle, products, productTileFormat = 'normal', recommendedProduct, showComparison, title = '', updateCompareList, analyticsProductGroupName = '', monetateId, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const [currentMobileState, setCurrentMobileState] = (0, react_1.useState)(false);
    const productUniqueKey = (0, react_1.useId)();
    const productGroupRef = (0, react_1.useRef)(null);
    const isExtensiveTile = (0, ProductUtil_1.isExtensiveProductTile)(productTileFormat);
    const { handleItemClickAnalytics, getProductGroupAnalyticsData } = (0, useProductGroupAnalytics_1.useProductGroupAnalytics)(Boolean(analyticsProductGroupName), productGroupRef, analyticsProductGroupName, title, products);
    (0, react_1.useEffect)(() => {
        setCurrentMobileState(isMobile);
    }, [isMobile]);
    const slides = (0, react_1.useMemo)(() => products.map((product, index) => {
        const handleAddToBasket = addToBasket
            ? (productData) => {
                addToBasket(productData, getProductGroupAnalyticsData(index + 1));
            }
            : undefined;
        const handleTileClick = (e, productData, color) => {
            onTileClick === null || onTileClick === void 0 ? void 0 : onTileClick(e, productData, color);
            handleItemClickAnalytics(index + 1, product);
        };
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 0, gutter: false, desktop: SliderConstants_1.SLIDES_AMOUNT_DESKTOP - 1, children: (0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { addToBasket: handleAddToBasket, alwaysShowSwatches: isShoplanding, clickAndCollectOnly: (0, ProductUtil_1.isClickAndCollectOnly)(product), compareItems: compareItems, deliveryPromiseExplanations: deliveryPromiseExplanations, dropdownProps: { appendToBody: true }, getDeliveryPromise: getDeliveryPromise, hideBasketPopup: hideBasketPopup, inStoreOnly: (0, ProductUtil_1.isInStoreOnly)(product), onAddToBasketCallback: onAddToBasketCallback, onWishListToggle: onWishListToggle, parentId: productGroupId, product: product, format: productTileFormat, recommendedProduct: recommendedProduct, scrollableWrapperSelector: basketPopup ? cotopaxi_1.POPOVER_BODY_SELECTOR : undefined, shouldLazyLoadImage: common_types_1.LazyLoadOptions.LAZY_LOAD, showComparison: showComparison, showProductOutOfStockOverlay: isExtensiveTile, showSwatchesOnHover: !isShoplanding, updateCompareList: updateCompareList, onTileClick: handleTileClick, monetateProduct: Boolean(monetateId) }) }, `${productUniqueKey}-${product.productCode}_${product.colourId}`));
    }), [products]);
    if (!(products === null || products === void 0 ? void 0 : products.length)) {
        return null;
    }
    const ITEMS_AMOUNT_TABLET = (numberOfTiles === null || numberOfTiles === void 0 ? void 0 : numberOfTiles.tablet) || 'auto';
    const ITEMS_AMOUNT_DESKTOP = (numberOfTiles === null || numberOfTiles === void 0 ? void 0 : numberOfTiles.desktop) || SliderConstants_1.SLIDES_AMOUNT_DESKTOP;
    const ITEMS_AMOUNT_DESKTOP_LARGE = (numberOfTiles === null || numberOfTiles === void 0 ? void 0 : numberOfTiles.desktopLarge) || SliderConstants_1.SLIDES_AMOUNT_DESKTOP_LARGE;
    const getCarouselVariant = () => {
        if ((inBasketOverview && basketStatus !== common_types_1.BasketStatus.EMPTY) || basketPopup) {
            return isExtensiveTile ? cotopaxi_1.CarouselVariant.PRODUCT_TILE_AUTO_EXTENSIVE : cotopaxi_1.CarouselVariant.PRODUCT_TILE_AUTO;
        }
        else {
            return isExtensiveTile ? cotopaxi_1.CarouselVariant.PRODUCT_TILE_EXTENSIVE : cotopaxi_1.CarouselVariant.PRODUCT_TILE_NORMAL;
        }
    };
    const carouselConfig = {
        variant: getCarouselVariant(),
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: false,
        fullWidth: !collapsible && !basketPopup,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView: ITEMS_AMOUNT_TABLET,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.TABLET,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView: inBasketOverview && basketStatus !== common_types_1.BasketStatus.EMPTY ? 'auto' : ITEMS_AMOUNT_DESKTOP,
                    spaceBetween: inBasketOverview && basketStatus !== common_types_1.BasketStatus.EMPTY ? SliderConstants_1.SLIDE_SPACING.TABLET : SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerView: inBasketOverview && basketStatus !== common_types_1.BasketStatus.EMPTY ? 'auto' : ITEMS_AMOUNT_DESKTOP_LARGE,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
            },
        },
    };
    const mainBoxParams = {
        mobile: {
            spacing: {
                paddingBottom: basketPopup ? 4 : 0,
                paddingTop: inBasketOverview ? 1.5 : collapsible ? 0 : 2,
            },
        },
        tablet: {
            spacing: {
                paddingBottom: basketPopup ? 4 : 6,
                paddingTop: 0,
                marginTop: basketPopup ? 2 : 0,
            },
        },
        desktop: { spacing: { paddingBottom: basketPopup ? 4 : 7 } },
        dataAttributes: {
            'data-analytics-bannerid': analyticsAreaName,
            'data-monetate-action-id': monetateId,
        },
    };
    const innerBoxParams = {
        mobile: {
            spacing: {
                paddingBottom: inBasketOverview ? 5 : 3,
                paddingTop: inBasketOverview ? 3 : collapsible ? 0 : 2,
            },
        },
        tablet: {
            spacing: {
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: inBasketOverview ? 4 : 3,
            },
        },
        desktop: { spacing: { marginTop: inBasketOverview ? 5 : 3 } },
    };
    const getTitle = () => currentMobileState && !inBasketOverview ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: title })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: inBasketOverview ? cotopaxi_1.HeadingType.TITLE : cotopaxi_1.HeadingType.SUBTITLE, children: title }));
    if (collapsible) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({ innerRef: productGroupRef }, mainBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: `${productGroupId}-collapse${title ? `-${title.replace(/ /g, '')}` : ''}`, title: title, open: !collapsed, query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], mobileFullwidth: true, tabletFullwidth: true, desktopFullwidth: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, innerBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: slides })) })) }) })));
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({ innerRef: productGroupRef }, mainBoxParams, { children: [title &&
                (basketPopup ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingRight: 2, paddingLeft: 2 } }, tablet: { spacing: { paddingRight: 4, paddingLeft: 4 } }, children: getTitle() })) : (getTitle())), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, innerBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: slides })) }))] })));
};
exports.ProductGroup = ProductGroup;
