"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvantagesSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const AdvantagesSection = ({ items, textSize = cotopaxi_1.TextSize.SMALL }) => {
    const renderContent = (item) => item.link ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: item.link, target: item.linkOpensInNewTab ? cotopaxi_1.LinkTarget.BLANK : null, children: item.text })) : (item.text);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.List, { icon: {
            name: cotopaxi_1.IconName.CHECK,
            color: cotopaxi_1.IconColor.POSITIVE,
            size: cotopaxi_1.IconSize.MAJOR,
        }, children: items.map((item) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: textSize, children: renderContent(item) }) }, item.text.replace(/ /g, '')))) }));
};
exports.AdvantagesSection = AdvantagesSection;
