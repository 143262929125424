"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetBasketLockState = exports.makeGetBasketId = exports.makeGetBasketDifferentDeliveryPromisesState = exports.makeGetBasketUnavailableProductsState = exports.makeGetBasketPaybackVoucherMessagesState = exports.makeGetBasketPopupMessagesEnrichedState = exports.makeGetBasketPopupMessagesState = exports.makeGetBasketDeliveryInfoState = exports.makeGetBasketValidateRegexState = exports.makeGetBasketProductQuantityState = exports.makeGetBasketPopoverVouchersState = exports.makeGetBasketPromotionProductsState = exports.makeGetBasketTotalVoucherAmountState = exports.makeGetBasketClickAndCollectStoreIdState = exports.makeGetBasketVouchersState = exports.makeGetBasketTotalQuantityState = exports.makeGetBasketStatusState = exports.makeGetBasketPricingState = exports.makeGetBasketMappedProductsState = exports.makeGetBasketValidationState = exports.makeGetBasketCCPopupState = exports.makeGetBasketPopupCollapseState = exports.makeGetBasketPopupState = void 0;
const reselect_1 = require("reselect");
const basketStateSelector = (state) => state.basket;
const makeGetBasketPopupState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.popup);
exports.makeGetBasketPopupState = makeGetBasketPopupState;
const makeGetBasketPopupCollapseState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.popup.collapsed);
exports.makeGetBasketPopupCollapseState = makeGetBasketPopupCollapseState;
const makeGetBasketCCPopupState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.ccPopup);
exports.makeGetBasketCCPopupState = makeGetBasketCCPopupState;
const makeGetBasketValidationState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.validationState);
exports.makeGetBasketValidationState = makeGetBasketValidationState;
const makeGetBasketMappedProductsState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.mappedProducts);
exports.makeGetBasketMappedProductsState = makeGetBasketMappedProductsState;
const makeGetBasketPricingState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.pricing);
exports.makeGetBasketPricingState = makeGetBasketPricingState;
const makeGetBasketStatusState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.status);
exports.makeGetBasketStatusState = makeGetBasketStatusState;
const makeGetBasketTotalQuantityState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.totalQuantity);
exports.makeGetBasketTotalQuantityState = makeGetBasketTotalQuantityState;
const makeGetBasketVouchersState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.vouchers);
exports.makeGetBasketVouchersState = makeGetBasketVouchersState;
const makeGetBasketClickAndCollectStoreIdState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.clickAndCollectStoreId);
exports.makeGetBasketClickAndCollectStoreIdState = makeGetBasketClickAndCollectStoreIdState;
const makeGetBasketTotalVoucherAmountState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.totalVoucherAmount);
exports.makeGetBasketTotalVoucherAmountState = makeGetBasketTotalVoucherAmountState;
const makeGetBasketPromotionProductsState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.promotionProducts || []);
exports.makeGetBasketPromotionProductsState = makeGetBasketPromotionProductsState;
const makeGetBasketPopoverVouchersState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.vouchers.map(voucher => ({
    displayCode: voucher.displayCode,
    amount: voucher.amountUsedForThisBasket,
})));
exports.makeGetBasketPopoverVouchersState = makeGetBasketPopoverVouchersState;
const makeGetBasketProductQuantityState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.productQuantity || {});
exports.makeGetBasketProductQuantityState = makeGetBasketProductQuantityState;
const makeGetBasketValidateRegexState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.validateRegex);
exports.makeGetBasketValidateRegexState = makeGetBasketValidateRegexState;
const makeGetBasketDeliveryInfoState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.deliveryInfo);
exports.makeGetBasketDeliveryInfoState = makeGetBasketDeliveryInfoState;
const makeGetBasketPopupMessagesState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.popupMessages);
exports.makeGetBasketPopupMessagesState = makeGetBasketPopupMessagesState;
const makeGetBasketPopupMessagesEnrichedState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.popupMessagesEnriched);
exports.makeGetBasketPopupMessagesEnrichedState = makeGetBasketPopupMessagesEnrichedState;
const makeGetBasketPaybackVoucherMessagesState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.paybackVoucherMessages);
exports.makeGetBasketPaybackVoucherMessagesState = makeGetBasketPaybackVoucherMessagesState;
const makeGetBasketUnavailableProductsState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.hasUnavailableProducts);
exports.makeGetBasketUnavailableProductsState = makeGetBasketUnavailableProductsState;
const makeGetBasketDifferentDeliveryPromisesState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.orderLinesHaveDifferentDeliveryPromises);
exports.makeGetBasketDifferentDeliveryPromisesState = makeGetBasketDifferentDeliveryPromisesState;
const makeGetBasketId = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.basketId);
exports.makeGetBasketId = makeGetBasketId;
const makeGetBasketLockState = () => (0, reselect_1.createSelector)(basketStateSelector, (basketState) => basketState.isBasketLocked);
exports.makeGetBasketLockState = makeGetBasketLockState;
