"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const FormConstants_1 = require("../../../constants/FormConstants");
const FormField_1 = require("../../common/form/form-field/FormField");
class RentalPersonItem extends react_1.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { index, person, proficiencies, onDeleteClick, t } = this.props;
        const proficiencyData = {
            options: proficiencies,
            keyLookup: 'id',
            valueLookup: 'id',
            labelLookup: 'translation',
        };
        const namePrefix = `${FormConstants_1.RENTAL_FORM_PEOPLE_FIELD_ARRAY_NAME}.${index}`;
        return ((0, jsx_runtime_1.jsxs)("div", { className: "m-rental-person", children: [(0, jsx_runtime_1.jsxs)("div", { className: "m-rental-person__fields", children: [(0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.firstName`, required: true, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.firstName }), (0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.birthYear`, required: true, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.birthYear }), (0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.height`, required: true, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.height, placeholder: person.placeholderHeight }), (0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.weight`, required: true, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.weight, placeholder: person.placeholderWeight }), (0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.shoeSize`, required: true, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.shoeSize }), (0, jsx_runtime_1.jsx)(FormField_1.default, { name: `${namePrefix}.proficiencyId`, formFieldType: FormConstants_1.RENTAL_FORM_FIELD_KIND.proficiencyId, data: proficiencyData })] }), !person.primary && ((0, jsx_runtime_1.jsx)("div", { className: "m-rental-person__actions", children: (0, jsx_runtime_1.jsx)("div", { className: "m-form-control", children: (0, jsx_runtime_1.jsx)("button", { className: "a-button a-button--secondary a-button--icon-delete", type: "button", onClick: onDeleteClick, children: t('rental:rental.person.delete') }) }) }))] }));
    }
}
exports.default = (0, react_i18next_1.withTranslation)(['rental'])(RentalPersonItem);
