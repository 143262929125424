"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitGlossaryTooltips = void 0;
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const react_1 = require("react");
const GLOSSARY_POLL_INTERVAL = 500;
const useInitGlossaryTooltips = () => {
    const [isGlossaryLoaded, setIsGlossaryLoaded] = (0, react_1.useState)(false);
    const [shouldInitGlossaryTooltips, setShouldInitGlossaryTooltips] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const glossaryPoll = new common_utils_1.Poller(() => {
            if (window === null || window === void 0 ? void 0 : window[GeneralConstants_1.GLOSSARY_KEY]) {
                return true;
            }
            return false;
        }, GLOSSARY_POLL_INTERVAL);
        if (window === null || window === void 0 ? void 0 : window[GeneralConstants_1.GLOSSARY_KEY]) {
            setIsGlossaryLoaded(true);
            setShouldInitGlossaryTooltips(true); // need to init glossary tooltips, because this component was initialized after the glossary initialization
        }
        else {
            glossaryPoll.run().then(() => setIsGlossaryLoaded(true));
        }
        return () => glossaryPoll.end();
    }, []);
    return {
        isGlossaryLoaded,
        shouldInitGlossaryTooltips,
    };
};
exports.useInitGlossaryTooltips = useInitGlossaryTooltips;
