"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvantagesSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const AdvantagesSection = ({ items, textSize = cotopaxi_1.TextSize.SMALL }) => {
    const renderContent = (item) => item.link ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: item.link, target: item.linkOpensInNewTab ? cotopaxi_1.LinkTarget.BLANK : null, children: item.text })) : (item.text);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
            spacing: { paddingTop: 1.5, paddingBottom: 1.5, paddingLeft: 2, paddingRight: 2, marginTop: 2 },
            theme: cotopaxi_1.BoxTheme.PRIMARY,
        }, tablet: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.List, { children: items.map((item, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: index === 0 ? 0 : 0.5 } }, tablet: { spacing: { marginTop: index === 0 ? 0 : 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.SMALL, children: [item.icon && (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: item.icon, size: cotopaxi_1.IconSize.MINOR }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: textSize, children: renderContent(item) })] }) }) }, item.text.replace(/ /g, '')))) }) }));
};
exports.AdvantagesSection = AdvantagesSection;
