"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const BasketProductItem_1 = require("../basket-product-item/BasketProductItem");
const ClosenessQualifier_1 = require("../../cart/closeness-qualifier/ClosenessQualifier");
const VoucherUtil_1 = require("../../../util/VoucherUtil");
const common_types_1 = require("@as-react/common-types");
const BasketPopover = ({ products, vouchers, pricing, status, hrefViewCart, deliveryInfo, freeDeliveryMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.product]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const hasProducts = products && products.length > 0;
    const showFreeLabel = pricing && (pricing.priceDelivery === 0 || pricing.priceDelivery === null);
    const isLastItemInList = (index) => index === products.length - 1;
    const messageBoxParams = {
        mobile: {
            spacing: {
                paddingLeft: 4,
                paddingRight: 4,
                paddingBottom: 2,
            },
        },
    };
    const compactShoppingCartInfoTranslations = {
        subTotal: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.subTotal`),
        totalDiscount: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.totalDiscount`),
        delivery: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.delivery`),
        deliveryFree: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.delivery.free`),
        totalAmount: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.totalAmount`),
        giftVoucher: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.type.giftvoucher`),
        voucher: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.type.voucher`),
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: [status === common_types_1.BasketStatus.LOADING && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('checkout:basket.loadingBasket') }) }))), status === common_types_1.BasketStatus.READY && hasProducts && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [freeDeliveryMessage && deliveryInfo.amountUntilFreeDelivery && pricing.priceDelivery > 0 && ((0, jsx_runtime_1.jsx)(ClosenessQualifier_1.default, { showProgressBar: true, freeDeliveryMessage: freeDeliveryMessage, totalPriceSell: pricing.totalPriceSell, amountUntilFreeDelivery: deliveryInfo.amountUntilFreeDelivery, currency: currency })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { direction: cotopaxi_1.ScrollDirection.VERTICAL, variant: cotopaxi_1.ScrollableListVariant.SHADOW, height: "420px", children: products.map((product, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BasketProductItem_1.BasketProductItem, { basketProductData: product }), !isLastItemInList(index) && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { size: "calc(100% - 4rem)" })] }, `${product.productCode}_${index}`))) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                paddingBottom: 2,
                                paddingLeft: 4,
                                paddingRight: 4,
                            },
                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.CompactShoppingCartInfo, { currency: currency, priceDelivery: showFreeLabel ? '0' : asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.priceDelivery), priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.priceTotalBasket), totalPriceSell: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.totalPriceSellBeforeDiscount || pricing.totalPriceSell), totalBasketDiscountAmount: pricing.totalBasketDiscountAmount && pricing.totalPriceSellBeforeDiscount
                                    ? asNumeral_1.AsNumeral.getInstance().formatAsCurrency(pricing.totalBasketDiscountAmount)
                                    : '0', vouchers: VoucherUtil_1.default.mapBasketAppliedVouchersToAppliedVouchers(vouchers, currency, t), translations: compactShoppingCartInfoTranslations }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { dataAttributes: { 'data-analytics-bannerid': 'header-shopping-cart-button-cart' }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, href: hrefViewCart, variant: cotopaxi_1.ButtonVariant.BRANDED, dataQA: "go_to_basket", text: t('navigation:basket.flyout.gotobasket') }) })] })] })), status === common_types_1.BasketStatus.EMPTY && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('checkout:basket.emptyBasket') }) }))), status === common_types_1.BasketStatus.ERROR && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: t('checkout:basket.error') }) })))] }));
};
exports.BasketPopover = BasketPopover;
