"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersEmptyResult = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const FiltersEmptyResult = ({ removeAllActiveFilters, mainWebShop, showChangeFilterStep = true, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const handleRemoveAllActiveFilters = (event) => {
        event.preventDefault();
        event.stopPropagation();
        removeAllActiveFilters();
    };
    const getContent = (isDesktop = false) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, alignment: isDesktop ? cotopaxi_1.HeadingAlignment.CENTER : cotopaxi_1.HeadingAlignment.LEFT, children: t('product.lister.empty.filter.result.heading') }), mainWebShop !== common_types_1.MainWebShop.BEVER && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1.5 } }, desktop: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, type: cotopaxi_1.TextType.CONTENT, align: isDesktop ? cotopaxi_1.TextAlign.CENTER : null, children: t('product.lister.empty.filter.result.subheading') }) }), showChangeFilterStep && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, align: isDesktop ? cotopaxi_1.TextAlign.CENTER : null, children: ["\u2022 ", t('product.lister.empty.filter.result.copy')] }) }))] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 1.5 } }, children: isDesktop ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, align: cotopaxi_1.TextAlign.CENTER, children: ["\u2022 ", t('product.lister.empty.filter.result.action')] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: ["\u2022", ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: "", size: cotopaxi_1.LinkSize.SMALL, onClick: handleRemoveAllActiveFilters, children: t('product.lister.empty.filter.result.action') })] })) })] }));
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, desktop: { spacing: { marginTop: 8, marginBottom: 8 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: getContent(true) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: getContent() })] }));
};
exports.FiltersEmptyResult = FiltersEmptyResult;
