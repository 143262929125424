"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const PaymentGroupGeneric_1 = require("./PaymentGroupGeneric");
const PaymentGroupBancontact_1 = require("./PaymentGroupBancontact");
const PaymentGroupKlarna_1 = require("./PaymentGroupKlarna");
const PaymentGroupCreditCard_1 = require("./PaymentGroupCreditCard");
const PaymentGroup = props => {
    const { group } = props;
    const groupName = group.name.toUpperCase().indexOf(common_types_1.PaymentGroupName.KLARNA) !== -1 ? common_types_1.PaymentGroupName.KLARNA : group.name;
    switch (groupName) {
        case common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY:
            return (0, jsx_runtime_1.jsx)(PaymentGroupBancontact_1.PaymentGroupBancontact, Object.assign({}, props));
        case common_types_1.PaymentGroupName.KLARNA:
            return (0, jsx_runtime_1.jsx)(PaymentGroupKlarna_1.PaymentGroupKlarna, Object.assign({}, props));
        case common_types_1.PaymentGroupName.CREDITCARD:
            return (0, jsx_runtime_1.jsx)(PaymentGroupCreditCard_1.PaymentGroupCreditCard, Object.assign({}, props));
        case common_types_1.PaymentGroupName.IDEAL:
        case common_types_1.PaymentGroupName.PAYPAL:
        case common_types_1.PaymentGroupName.APPLEPAY_SAFERPAY:
        case common_types_1.PaymentGroupName.GOOGLEPAY_SAFERPAY:
        case common_types_1.PaymentGroupName.ECOCHEQUE:
            return (0, jsx_runtime_1.jsx)(PaymentGroupGeneric_1.PaymentGroupGeneric, Object.assign({}, props));
        default:
            return null;
    }
};
exports.PaymentGroup = PaymentGroup;
