"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterButtonListItemPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterButtonListItemPopover = ({ title, description, }) => {
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverOpen, size: cotopaxi_1.ButtonSize.SMALL, icon: {
                    name: cotopaxi_1.IconName.INFORMATION_OUTLINE,
                    color: cotopaxi_1.IconColor.ACTION,
                } }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handlePopoverClose, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                        name: cotopaxi_1.IconName.CLOSE,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), title: title, children: description })] }));
};
exports.FilterButtonListItemPopover = FilterButtonListItemPopover;
