"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductRecentlyViewedReducer_1 = require("../../productRecentlyViewed/reducers/ProductRecentlyViewedReducer");
const ProductRecentlyViewedSaga_1 = require("../../productRecentlyViewed/sagas/ProductRecentlyViewedSaga");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const loadProductRecentlyViewedModule = () => ({
    id: 'ProductRecentlyViewed',
    reducerMap: {
        productRecentlyViewed: ProductRecentlyViewedReducer_1.productRecentlyViewedReducer,
    },
    sagas: [ProductRecentlyViewedSaga_1.default, DeliveryPromiseSaga_1.default],
});
exports.default = loadProductRecentlyViewedModule;
