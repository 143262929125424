"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const DpePopup_1 = require("../components/dpe/dpe-popup/DpePopup");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const AuthFormActions_creators_1 = require("../redux/authForm/actions/AuthFormActions.creators");
const AuthFormSelectors_1 = require("../redux/authForm/selectors/AuthFormSelectors");
const DpePopupContainer = ({ authFormStep, onAnalyticsStart, data, digitalPartner, onAuthFormStepUpdate, onAnalyticsStepAuthChange, onAnalyticsStepEligibilityCheck, onAnalyticsStepConfirmation, onAnalyticsStepLeave, onCloseClick, isAuthenticated, loginState, onLogin, onAnalyticsFormsError, }) => {
    const [digitalPartnerEnrolmentStep, setDigitalPartnerEnrolmentStep] = (0, react_1.useState)(common_types_1.DpeTileStep.INTRO);
    const onAuthFormStepUpdateLocal = (step) => {
        onAuthFormStepUpdate(step);
        onAnalyticsStepAuthChange(step, {
            partner: digitalPartner,
        });
        setDigitalPartnerEnrolmentStep(null);
    };
    (0, react_1.useEffect)(() => {
        if (authFormStep === common_types_1.AuthFormStep.EMAIL) {
            onAnalyticsStart({ partner: digitalPartner });
            onAuthFormStepUpdateLocal({
                to: common_types_1.AuthFormStep.EMAIL,
                from: common_types_1.AuthFormStep.EMAIL,
            });
        }
    }, []);
    const onEnrolmentStepUpdate = (step) => {
        if (step.to === common_types_1.DpeTileStep.VALIDATION) {
            onAnalyticsStepEligibilityCheck({
                partner: digitalPartner,
            });
        }
        else if (step.to === common_types_1.DpeTileStep.CONFIRMATION) {
            onAnalyticsStepConfirmation({
                partner: digitalPartner,
            });
        }
        setDigitalPartnerEnrolmentStep(step.to);
    };
    const handleCloseClick = () => {
        if (authFormStep !== common_types_1.AuthFormStep.EMAIL) {
            onAuthFormStepUpdate({
                to: common_types_1.AuthFormStep.EMAIL,
                from: authFormStep,
            });
        }
        if (!(!!digitalPartnerEnrolmentStep && digitalPartnerEnrolmentStep === common_types_1.DpeTileStep.CONFIRMATION)) {
            onAnalyticsStepLeave({
                partner: digitalPartner,
            });
        }
        onCloseClick();
    };
    return ((0, jsx_runtime_1.jsx)(DpePopup_1.DpePopup, { data: data, digitalPartner: digitalPartner, onConfirmationClick: handleCloseClick, isAuthenticated: isAuthenticated, authFormStep: authFormStep, onAuthFormStepUpdate: onAuthFormStepUpdate, onEnrolmentStepUpdate: onEnrolmentStepUpdate, loginState: loginState, onLogin: onLogin, onFormValidationError: onAnalyticsFormsError }));
};
const makeMapStateToProps = () => {
    const getAuthFormStep = (0, AuthFormSelectors_1.makeGetAuthFormStep)();
    const getAuthStatus = (0, AuthSelectors_1.makeGetAuthStatus)();
    const getLoginLoading = (0, AuthSelectors_1.makeGetLoginLoading)();
    const getLoginError = (0, AuthSelectors_1.makeGetLoginError)();
    return (state) => ({
        loginState: {
            success: getAuthStatus(state),
            loading: getLoginLoading(state),
            error: getLoginError(state),
        },
        authFormStep: getAuthFormStep(state),
    });
};
const mapDispatchToProps = {
    onLogin: AuthActions_creators_1.authActions.login,
    onAuthFormStepUpdate: AuthFormActions_creators_1.authFormActions.updateStep,
    onAnalyticsStepAuthChange: AnalyticsActions_creators_1.analyticsActions.digitalPartnerEnrolmentStepAuth,
    onAnalyticsStepEligibilityCheck: AnalyticsActions_creators_1.analyticsActions.digitalPartnerEnrolmentStepEligibilityCheck,
    onAnalyticsStepConfirmation: AnalyticsActions_creators_1.analyticsActions.digitalPartnerEnrolmentStepConfirmation,
    onAnalyticsStepLeave: AnalyticsActions_creators_1.analyticsActions.digitalPartnerEnrolmentLeave,
    onAnalyticsFormsError: AnalyticsActions_creators_1.analyticsActions.formsErrorMessage,
    onAnalyticsStart: AnalyticsActions_creators_1.analyticsActions.digitalPartnerEnrolmentStart,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(DpePopupContainer);
