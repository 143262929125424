"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.productActions = {
    createInitAction: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.INIT,
        payload,
    }),
    createSelectSizeAction: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SELECT_SIZE,
        payload,
    }),
    createSetEsiDataAction: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_ESI_DATA,
        payload,
    }),
    createSetColorAction: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_COLOR,
        payload,
    }),
    createSetSizeCombinationNotExistAction: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_SIZE_COMBINATION_NOT_EXIST,
        payload,
    }),
    createGetDeliveryPromise: () => ({
        type: ActionEnums_1.ProductActionTypes.GET_DELIVERY_PROMISE,
    }),
    createGetDeliveryPromiseExtended: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.GET_DELIVERY_PROMISE_EXTENDED,
        payload,
    }),
    createSetDeliveryPromiseExtended: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_DELIVERY_PROMISE_EXTENDED,
        payload,
    }),
    createSetDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_DELIVERY_PROMISE,
        payload,
    }),
    createSetNewProduct: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_NEW_PRODUCT,
        payload,
    }),
    createLoadProductData: (productCode) => ({
        type: ActionEnums_1.ProductActionTypes.LOAD_PRODUCT_DATA,
        payload: { data: { productCode } },
    }),
    createClearProductData: () => ({
        type: ActionEnums_1.ProductActionTypes.CLEAR_PRODUCT_DATA,
    }),
    createSetSelectedProductTab: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_SELECTED_PRODUCT_TAB,
        payload,
    }),
    createFetchReviewsData: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.FETCH_REVIEWS_DATA,
        payload,
    }),
    createSetReviewsData: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_REVIEWS_DATA,
        payload,
    }),
    setProductVideos: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_PRODUCT_VIDEOS,
        payload,
    }),
    setProductContentVideos: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_PRODUCT_CONTENT_VIDEOS,
        payload,
    }),
    createGetTechnicalCategories: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.GET_TECHNICAL_CATEGORIES,
        payload,
    }),
    createSetTechnicalCategories: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_TECHNICAL_CATEGORIES,
        payload,
    }),
    createGetLinkedProducts: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.GET_LINKED_PRODUCTS,
        payload,
    }),
    createSetLinkedProducts: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_LINKED_PRODUCTS,
        payload,
    }),
    getProductCareDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.GET_PRODUCT_CARE_DELIVERY_PROMISE,
        payload,
    }),
    setProductCareDeliveryPromise: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_PRODUCT_CARE_DELIVERY_PROMISE,
        payload,
    }),
    sizeGuideOpen: () => ({
        type: ActionEnums_1.ProductActionTypes.SIZE_GUIDE_OPEN,
    }),
    getBundle: (monetateId) => ({
        type: ActionEnums_1.ProductActionTypes.GET_BUNDLE,
        payload: monetateId,
    }),
    setBundle: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_BUNDLE,
        payload,
    }),
    bundleSetSelectedSlotState: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.BUNDLE_SET_SELECTED_SLOT_STATE,
        payload,
    }),
    bundleSetDisplayedProduct: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.BUNDLE_SET_DISPLAYED_PRODUCT,
        payload,
    }),
    bundleCalculateStart: () => ({
        type: ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_START,
    }),
    bundleCalculateSuccess: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_SUCCESS,
        payload,
    }),
    bundleCalculateFailure: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.BUNDLE_CALCULATE_FAILURE,
        payload,
    }),
    bundleSetSelectedAlternativeTab: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_SELECTED_BUNDLE_TAB,
        payload,
    }),
    fetchAlternatives: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.FETCH_ALTERNATIVES,
        payload,
    }),
    setAlternatives: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_ALTERNATIVES,
        payload,
    }),
    setNotifyMeError: (payload) => ({
        type: ActionEnums_1.ProductActionTypes.SET_NOTIFY_ME_ERROR,
        payload,
    }),
};
