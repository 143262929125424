"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterReducer = exports.loadFilterReducer = exports.initialState = void 0;
const connected_react_router_1 = require("connected-react-router");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const common_utils_1 = require("@as-react/common-utils");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialState = {};
const mapSetFilterObjectToFilteredItemWithFilterType = (setFilterObject) => {
    let data = {
        filterType: setFilterObject.filterType,
        filterOnName: setFilterObject.filterOnName,
        filterContentType: setFilterObject.filterContentType,
        queryParamKey: setFilterObject.queryParamKey,
    };
    if ((0, common_utils_1.isMultiSelectFilter)(setFilterObject)) {
        data = Object.assign(Object.assign({}, data), { values: setFilterObject.filteredItems.map(filterItem => ({
                filterType: setFilterObject.filterType,
                filterOnName: setFilterObject.filterOnName,
                filterOn: setFilterObject.filterOn,
                filterKey: filterItem.filterKey,
                filterKeyName: filterItem.filterKeyName,
            })) });
    }
    else if ((0, common_utils_1.isRangeFilter)(setFilterObject)) {
        data = Object.assign(Object.assign({}, data), { rangeMin: Number(setFilterObject.filteredItems[0].filterKey), rangeMax: Number(setFilterObject.filteredItems[1].filterKey), filterPrefix: setFilterObject.filterPrefix, filterSuffix: setFilterObject.filterSuffix });
    }
    else if ((0, common_utils_1.isBucketFilter)(setFilterObject) || (0, common_utils_1.isLinkFilter)(setFilterObject)) {
        data = Object.assign(Object.assign({}, data), { values: [...[setFilterObject.filteredItems[setFilterObject.filteredItems.length - 1]]] });
    }
    return data;
};
const updateActiveFilters = (state = exports.initialState, action) => {
    const newFilteredItemWithFilterType = mapSetFilterObjectToFilteredItemWithFilterType(action.payload);
    return Object.assign(Object.assign({}, state.activeFilters), {
        [action.payload.filterOn]: Object.assign({}, newFilteredItemWithFilterType),
    });
};
const removeFilterOnFromActiveFilters = (state = exports.initialState, action) => {
    let newActiveFilters;
    if (state.activeFilters && state.activeFilters[action.payload.filterOn]) {
        newActiveFilters = (0, GeneralUtil_1.removeAttributeFromObject)(state.activeFilters, action.payload.filterOn);
        if (Object.keys(newActiveFilters).length === 0) {
            newActiveFilters = null;
        }
    }
    else {
        newActiveFilters = Object.assign({}, state.activeFilters);
    }
    return newActiveFilters;
};
const loadFilterReducer = (initialData = {}) => {
    const initialReducerState = Object.assign(Object.assign({}, exports.initialState), initialData);
    return (state = initialReducerState, action) => {
        switch (action.type) {
            case connected_react_router_1.LOCATION_CHANGE:
                return action.payload.location && action.payload.location.state
                    ? Object.assign({}, action.payload.location.state.filters) : state;
            case ActionEnums_1.FilterActionTypes.SET_FILTERS:
                return Object.assign(Object.assign({}, state), { activeFilters: updateActiveFilters(state, action) });
            case ActionEnums_1.FilterActionTypes.REMOVE_FILTER:
                return Object.assign(Object.assign({}, state), { activeFilters: removeFilterOnFromActiveFilters(state, action) });
            case ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS:
                return Object.assign(Object.assign({}, state), { activeFilters: null });
            case ActionEnums_1.FilterActionTypes.SET_ACTIVE_FILTERS:
                return Object.assign(Object.assign({}, state), { activeFilters: action.payload });
            default:
                return state;
        }
    };
};
exports.loadFilterReducer = loadFilterReducer;
exports.filterReducer = (0, exports.loadFilterReducer)();
