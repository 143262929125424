"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductTopFiveTile_1 = require("./ProductTopFiveTile");
const ProductTopFive = ({ title, products }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    if (!(products === null || products === void 0 ? void 0 : products.length))
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: title || t('product.top.default') }), products.map((product, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(ProductTopFiveTile_1.ProductTopFiveTile, { product: product, ranking: index + 1 }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }, `${product.productId}_${product.colourId}`)))] }));
};
exports.default = ProductTopFive;
