"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Authentication_module_1 = require("./modules/Authentication.module");
const Session_module_1 = require("./modules/Session.module");
const Basket_module_1 = require("./modules/Basket.module");
const Checkout_module_1 = require("./modules/Checkout.module");
const loadBuyConfirmationModule = () => ({
    hasRouter: true,
    modules: [(0, Session_module_1.default)(), (0, Authentication_module_1.default)(), (0, Basket_module_1.default)(), (0, Checkout_module_1.default)()],
});
exports.default = loadBuyConfirmationModule;
