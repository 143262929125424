"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const AccountCreditCard_1 = require("../../components/account/account-credit-card/AccountCreditCard");
const AccountCreditCardContainer = ({ title }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [creditCards, setCreditCards] = (0, react_1.useState)(null);
    const fetchCreditCards = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield CustomerApi_1.default.getCardInfo(servicesEndpoint, defaultRequestParameters);
            setCreditCards(data);
        }
        catch (_a) { }
    });
    const removeCard = (cardId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield CustomerApi_1.default.deleteCard(servicesEndpoint, defaultRequestParameters, cardId);
            fetchCreditCards();
        }
        catch (_b) { }
    });
    (0, react_1.useEffect)(() => {
        fetchCreditCards();
    }, []);
    return (0, jsx_runtime_1.jsx)(AccountCreditCard_1.AccountCreditCard, { ogoneAliases: creditCards, removeCard: removeCard, title: title });
};
exports.default = AccountCreditCardContainer;
