"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailThirdPartyReviewsContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const HtmlInclude_1 = require("../../components/common/html-include/HtmlInclude");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const cotopaxi_1 = require("@as/cotopaxi");
const ReviewUtil_1 = require("../../util/ReviewUtil");
const ProductDetailThirdPartyReviewsContainer = () => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    // reevoo
    const reviewConfig = (0, ConfigurationUtil_1.getReviewConfig)(aemConfiguration);
    const { productCode } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    // feefo
    const { show_feefo_widget } = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const feefoIdentifier = (0, ConfigurationUtil_1.getFeefoIdentifier)(aemConfiguration);
    const isClient = (0, cotopaxi_1.useIsClient)();
    if (show_feefo_widget && isClient) {
        (0, ReviewUtil_1.loadFeefoScript)(feefoIdentifier);
    }
    if (show_feefo_widget) {
        return ((0, jsx_runtime_1.jsx)("div", { id: "feefo-product-review-widgetId", className: "feefo-review-widget-product", "data-parent-product-sku": productCode }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: `<reevoo-embeddable
                type="product"
                show-brand="${reviewConfig.reevooShowBrand}"
                stars-enabled="${reviewConfig.reevooStarsEnabled}"
                product-id="${productCode}"
                per-page="${reviewConfig.amount}"/>` }));
    }
};
exports.ProductDetailThirdPartyReviewsContainer = ProductDetailThirdPartyReviewsContainer;
