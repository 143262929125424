"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPersonalShoppingAdvice = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const BasketSaveForm_1 = require("../basket-save-form/BasketSaveForm");
const BasketPersonalShoppingAdvice = ({ root, defaultRequestParams, servicesEndpoint, isHorizontal, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.form]);
    const [formState, setFormState] = (0, react_1.useState)(BasketSaveForm_1.BasketSaveFormState.INPUT);
    const handleSubmit = (_a, _b) => tslib_1.__awaiter(void 0, [_a, _b], void 0, function* ({ email }, { setFieldError }) {
        var _c;
        if (formState !== BasketSaveForm_1.BasketSaveFormState.INPUT)
            return;
        setFormState(BasketSaveForm_1.BasketSaveFormState.SUBMITTING);
        try {
            yield BasketAPI_1.default.personalShoppingAdviceSendMail(email, servicesEndpoint, defaultRequestParams);
            setFormState(BasketSaveForm_1.BasketSaveFormState.COMPLETE);
            setTimeout(() => (window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root)), GeneralConstants_1.DELAY.DELAY_3000);
        }
        catch (error) {
            const { response } = error;
            setFieldError('email', (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message);
            setFormState(BasketSaveForm_1.BasketSaveFormState.INPUT);
        }
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.psa.title`) }) }), (0, jsx_runtime_1.jsx)(BasketSaveForm_1.BasketSaveForm, { onSubmit: handleSubmit, state: formState, isHorizontal: isHorizontal })] }));
};
exports.BasketPersonalShoppingAdvice = BasketPersonalShoppingAdvice;
