"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileImageIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const ProductTileImageIcon = ({ onClick, icon, compact }) => {
    const [turnOnIconAnimation, setTurnOnIconAnimation] = (0, react_1.useState)(false);
    const handleClick = (e) => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        setTurnOnIconAnimation(icon === cotopaxi_1.IconName.HEART_EMPTY); // when added to wishlist
        e && e.preventDefault();
        e && e.stopPropagation();
    };
    if (!icon)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 1 } }, tablet: { spacing: { padding: compact ? 1 : 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.INVERSE_ALT, shape: cotopaxi_1.ButtonShape.PILL, onClick: handleClick, icon: {
                name: icon,
                size: cotopaxi_1.IconSize.MAJOR,
                animation: turnOnIconAnimation ? cotopaxi_1.IconAnimation.HEART_PULSE : undefined,
            } }) }));
};
exports.ProductTileImageIcon = ProductTileImageIcon;
