"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.urlActions = {
    updatePage: (page) => ({
        type: ActionEnums_1.UrlActionTypes.UPDATE_PAGE,
        payload: page,
    }),
    updateSize: (size) => ({
        type: ActionEnums_1.UrlActionTypes.UPDATE_SIZE,
        payload: size,
    }),
    updateSort: (sortOption) => ({
        type: ActionEnums_1.UrlActionTypes.UPDATE_SORT,
        payload: sortOption,
    }),
    updateStoreId: (storeId) => ({
        type: ActionEnums_1.UrlActionTypes.UPDATE_STORE_ID,
        payload: storeId,
    }),
    setUrlParams: (urlState) => ({
        type: ActionEnums_1.UrlActionTypes.SET_URL_PARAMS,
        payload: urlState,
    }),
};
