"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SessionReducer_1 = require("../../session/reducers/SessionReducer");
const SessionSagas_1 = require("../../session/sagas/SessionSagas");
const loadSessionModule = () => ({
    id: 'Session',
    reducerMap: {
        session: SessionReducer_1.sessionReducer,
    },
    sagas: [SessionSagas_1.default],
});
exports.default = loadSessionModule;
