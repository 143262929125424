"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutVersion = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
var CheckoutVersion;
(function (CheckoutVersion) {
    CheckoutVersion[CheckoutVersion["OLD"] = 1] = "OLD";
    CheckoutVersion[CheckoutVersion["NEW"] = 2] = "NEW";
})(CheckoutVersion || (exports.CheckoutVersion = CheckoutVersion = {}));
const checkoutEndpoints = {
    initiate: 'api/checkout/initiate',
    contact: 'api/checkout/contact',
    dtmCheckoutStep: 'api/dtm/checkoutstep',
    deliveryCountries: 'api/deliverycharges/countries',
};
class CheckoutAPI {
    constructor() {
        this.initRequest = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${checkoutEndpoints.initiate}`,
                params: Object.assign(Object.assign({}, defaultRequestParams), { checkoutVersion: CheckoutVersion.NEW }),
                withCredentials: true,
                data: {
                    adobeUuid: common_utils_1.CookieUtil.get(common_types_1.Cookie._UUID),
                    visitorId: common_utils_1.CookieUtil.getByRegexp(/^AMCV_.+AdobeOrg/i),
                    userAgent: navigator.userAgent,
                    fredhopperSegment: common_utils_1.CookieUtil.get(common_types_1.Cookie.FREDHOPPER_SEGMENT),
                    consentSetting: common_utils_1.CookieUtil.get(common_types_1.Cookie.CONSENT_SETTING),
                },
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.contact = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}${checkoutEndpoints.contact}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.checkoutStepDtm = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${servicesApi}${checkoutEndpoints.dtmCheckoutStep}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getDeliveryCountries = (servicesApi, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                params: defaultRequestParams,
                url: `${servicesApi}${checkoutEndpoints.deliveryCountries}/${defaultRequestParams.mainWebshop}/${defaultRequestParams.shopId}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new CheckoutAPI();
