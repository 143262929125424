"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.filterActions = {
    updateFilters: (payload) => ({
        type: ActionEnums_1.UrlActionTypes.UPDATE_FILTERS,
        payload,
    }),
    setFilterObject: (payload) => ({
        type: ActionEnums_1.FilterActionTypes.SET_FILTERS,
        payload,
    }),
    removeFilterObject: (payload) => ({
        type: ActionEnums_1.FilterActionTypes.REMOVE_FILTER,
        payload,
    }),
    removeAllFilters: () => ({
        type: ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS,
    }),
    setActiveFilters: (payload) => ({
        type: ActionEnums_1.FilterActionTypes.SET_ACTIVE_FILTERS,
        payload,
    }),
};
