"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeralLocaleConfigDE = void 0;
exports.numeralLocaleConfigDE = {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (num) => {
        return '.';
    },
    currency: {
        symbol: '€',
    },
};
