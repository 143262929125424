"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeralLocaleConfigNlNl = void 0;
exports.numeralLocaleConfigNlNl = {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'mln',
        billion: 'mrd',
        trillion: 'bln',
    },
    ordinal: (num) => {
        const remainder = num % 100;
        return (num !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20 ? 'ste' : 'de';
    },
    currency: {
        symbol: '€ ',
    },
};
