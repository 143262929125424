"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductComparisonReducer_1 = require("../../compare-page/reducers/ProductComparisonReducer");
const DeliveryPromiseSaga_1 = require("../../deliveryPromise/sagas/DeliveryPromiseSaga");
const common_types_1 = require("@as-react/common-types");
const loadProductComparisonModule = () => ({
    id: common_types_1.AEMScenes.PRODUCT_COMPARISON,
    reducerMap: {
        productComparison: ProductComparisonReducer_1.ProductComparisonReducer,
    },
    sagas: [DeliveryPromiseSaga_1.default],
});
exports.default = loadProductComparisonModule;
