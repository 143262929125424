"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authFormReducer = exports.initialAuthReducerState = void 0;
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.initialAuthReducerState = {
    step: common_types_1.AuthFormStep.EMAIL,
};
const authFormReducer = (state = exports.initialAuthReducerState, action) => {
    switch (action.type) {
        case ActionEnums_1.AuthFormActionsTypes.UPDATE_STEP_TYPE:
            return Object.assign(Object.assign({}, state), { step: action.payload.to });
        default:
            return state;
    }
};
exports.authFormReducer = authFormReducer;
