"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const DpeActivator_1 = require("../components/dpe/dpe-activator/DpeActivator");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const AuthFormSelectors_1 = require("../redux/authForm/selectors/AuthFormSelectors");
const DpeActivatorContainer = ({ getUserAuthState, authFormStep, digitalPartner, logoUrl, displayAs, notAuthLabel, authLabel, isAuthenticated, }) => {
    (0, react_1.useEffect)(() => {
        getUserAuthState();
    }, []);
    return ((0, jsx_runtime_1.jsx)(DpeActivator_1.default, { authFormStep: authFormStep, digitalPartner: digitalPartner, logoUrl: logoUrl, displayAs: displayAs, notAuthLabel: notAuthLabel, authLabel: authLabel, isAuthenticated: isAuthenticated }));
};
const makeMapStateToProps = () => {
    const getAuthStatus = (0, AuthSelectors_1.makeGetAuthStatus)();
    const getAuthFormStep = (0, AuthFormSelectors_1.makeGetAuthFormStep)();
    return (state) => ({
        isAuthenticated: getAuthStatus(state),
        authFormStep: getAuthFormStep(state),
    });
};
const mapDispatchToProps = { getUserAuthState: AuthActions_creators_1.authActions.getUserAuthState };
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(DpeActivatorContainer);
