"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlossaryHighlightInPopover = void 0;
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const GLOSSARY_HIGHLIGHT_CLASS = 'glossary-highlight';
const useGlossaryHighlightInPopover = (scrollContainer, isPopoverOpen) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isHighlighted = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        const highlightGlossaryItems = () => {
            let scrollBarSize;
            const isScrollable = scrollContainer.current && scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight;
            if (isScrollable) {
                scrollBarSize = (0, cotopaxi_1.getScrollbarSize)();
            }
            const glossaryItems = Array.from(scrollContainer.current.querySelectorAll(`.${GLOSSARY_HIGHLIGHT_CLASS}`));
            const highlightedItems = scrollContainer.current.querySelectorAll(`a.${GLOSSARY_HIGHLIGHT_CLASS}`);
            /*
                      If glossary items have been already highlighted (aem-platform-asadventure/app-package/jcr_root/apps/platform-asadventure/components/vendors/customlibs/glossaryHighlighter.ts)
                      we have to destroy them and reinit with the correct configuration
                  */
            highlightedItems.forEach(item => {
                // @ts-ignore
                if (item._tippy) {
                    // @ts-ignore
                    item._tippy.destroy();
                }
            });
            (0, common_utils_1.glossaryHighlighter)(null, glossaryItems, {
                appendTo: () => scrollContainer.current.closest(`.${cotopaxi_1.PopoverSelector.MODAL}`),
                placement: 'bottom-start',
                maxWidth: '100%',
                arrow: false,
                theme: 'glossary-light',
                onCreate(instance) {
                    if (isScrollable && scrollBarSize > 0) {
                        instance.popper.style.right = `${scrollBarSize}px`;
                    }
                    if (isMobile) {
                        instance.popper.style.maxHeight = '100vh';
                        instance.popperChildren.tooltip.style.maxHeight = '100vh';
                        instance.popperChildren.tooltip.style.overflowY = 'auto';
                    }
                },
            });
        };
        if ((scrollContainer === null || scrollContainer === void 0 ? void 0 : scrollContainer.current) && isPopoverOpen && !isHighlighted.current) {
            isHighlighted.current = true;
            highlightGlossaryItems();
        }
    }, [isPopoverOpen]);
};
exports.useGlossaryHighlightInPopover = useGlossaryHighlightInPopover;
