"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHomepageUrl = void 0;
const getHomepageUrl = (root) => {
    let url = root;
    if (url.charAt(url.length - 1) === '/') {
        url = url.substring(0, url.length - 1);
    }
    if (url.length > 0 && url.indexOf('.html') === -1) {
        // no page extention found.
        return url + '.html';
    }
    return url === '' ? '/' : url;
};
exports.getHomepageUrl = getHomepageUrl;
