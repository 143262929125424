"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const OrdersReducer_1 = require("../../orders/reducers/OrdersReducer");
const OrdersSaga_1 = require("../../orders/sagas/OrdersSaga");
const loadOrdersModule = () => ({
    id: 'Orders',
    reducerMap: {
        orders: OrdersReducer_1.ordersReducer,
    },
    sagas: [OrdersSaga_1.default],
});
exports.default = loadOrdersModule;
