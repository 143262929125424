"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComparisonProducts = exports.getAttributesForProducts = exports.getCompareProductPrice = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const asNumeral_1 = require("../vendors/asNumeral");
const i18nConstants_1 = require("../constants/i18nConstants");
const getCompareProductPrice = (product, currency, t) => {
    var _a, _b, _c;
    let priceInformation = null;
    const activeColour = product.colours.find(colour => colour.colourId === product.colourId);
    const selectedSku = ((_a = product.deliveryPromise) === null || _a === void 0 ? void 0 : _a.skuId.toString()) || product.selectedSku;
    const productPriceTranslations = {
        savings: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.bundles.discount.info`),
    };
    if (selectedSku) {
        const selectedSize = activeColour === null || activeColour === void 0 ? void 0 : activeColour.sizes.find(size => size.sku === selectedSku);
        if ((_b = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.prices) === null || _b === void 0 ? void 0 : _b.SELL) {
            priceInformation = {
                prices: selectedSize.prices,
                hasDiscount: activeColour === null || activeColour === void 0 ? void 0 : activeColour.hasDiscount,
            };
        }
    }
    else if ((_c = activeColour === null || activeColour === void 0 ? void 0 : activeColour.prices) === null || _c === void 0 ? void 0 : _c.SELL) {
        priceInformation = {
            prices: activeColour.prices,
            hasDiscount: activeColour.hasDiscount,
        };
    }
    return priceInformation
        ? Object.assign({ currency, hasDiscount: activeColour === null || activeColour === void 0 ? void 0 : activeColour.hasDiscount, translations: productPriceTranslations }, (0, common_utils_1.generateCotopaxiPriceViewModel)(priceInformation, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)) : null;
};
exports.getCompareProductPrice = getCompareProductPrice;
const getAttributesForProducts = (productsWithAttributes, productsOutOfStock) => !productsWithAttributes.titles
    ? []
    : productsWithAttributes.titles.map((item) => {
        var _a;
        const resultObj = {
            attribute: item.attribute,
            title: item.title,
            valuesByProducts: [],
        };
        (_a = productsWithAttributes.items) === null || _a === void 0 ? void 0 : _a.forEach((product, index) => {
            var _a, _b;
            const attributeValue = product.features[item.attribute];
            if (productsOutOfStock[index]) {
                resultObj.valuesByProducts.push(cotopaxi_1.FeatureMarker.OUT_OF_STOCK);
            }
            else if (!(0, common_utils_1.isNullOrUndefined)(attributeValue)) {
                const attributeType = (_b = (_a = product.featureList) === null || _a === void 0 ? void 0 : _a.find(feature => feature.featureName === item.attribute)) === null || _b === void 0 ? void 0 : _b.featureType;
                resultObj.valuesByProducts.push(attributeType === 'BOOLEAN' && attributeValue === '1'
                    ? Boolean(attributeValue).toString()
                    : attributeValue);
            }
            else {
                resultObj.valuesByProducts.push(null);
            }
        });
        return resultObj;
    });
exports.getAttributesForProducts = getAttributesForProducts;
const getComparisonProducts = (products, productsPrices) => products.map((product) => {
    const productPrice = productsPrices.find(tmpProductPrice => product.productId === tmpProductPrice.productId);
    return (0, common_utils_1.mergeProductPricesToProductLister)(product, productPrice);
});
exports.getComparisonProducts = getComparisonProducts;
